import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";
import { MAKE_PAYMENT } from "../actionsTypes";

export const payment = (payload, callback) => {
  const url = apiUrl.makePay.payUrl;
  return (dispatch) => {
    return axiosInstance.post(url, payload, {
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : localStorage.getItem("tokenUser")),
      },
    })
      .then((response) => {
        callback(response.data);
      })
  }
}

export const confirmPayment = (payload, callback) => {
  const url = apiUrl.makePay.confirmPay;
  return (dispatch) => {
    return axiosInstance.post(url, payload, {
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : localStorage.getItem("tokenUser")),
      },
    })
      .then((response) => {
        callback(response.data)
      })
  }
}

export const UPDATE_STATUS = "UPDATE_STATUS";

export const updateStatus = (status) => {
  return {
    type: UPDATE_STATUS,
    payload: status,
  };
};