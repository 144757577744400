import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  getorderDetail,
  addReviewOnProduct,
  editReviewOnProduct,
  deleteReviewOnProduct,
  getReasonList,
  cancelThisOrder,
  returnThisOrder,
  changeOrderStatus,
  sendInvoice,
  addUserReview,
} from "../../store/actions/profile";
import { connect, useSelector } from "react-redux";
import { Rating } from "../Profile/SellerProfile/RatingPopup";
import Toast from "light-toast";
import { priceWithSymbol, SpinnerLoader } from "../../utils/constants";
import moment from "moment";
import DownloadBtn from "./downloadBtn";
import MoreAction from "./moreAction";
// import ListingProduct from "../Profile/MainProfile/MyOrder/listingProduct";
import DeliveryAddress from "./deliveryAddress";
import Item from "./item";
import DeliveryStep from "./deliveryStep";
import InputReason from "../Profile/MainProfile/MyOrder/inputReason";
import { useToasts } from "react-toast-notifications";
import PriceDetails from "./priceDetails";
import { useLocation } from "react-router-dom";
import PendingOrderDetail from "./PendingOrderDetail";
import { get } from "lodash";
import Loader from "../Loader";
import { useDispatch } from "react-redux";
import { RatingStar } from "../ProductDetail/productReview";

const OrderDetails = (props) => {
  const [orderdetail, setOrderdetail] = React.useState("");
  const [orderdetailMain, setOrderdetailMain] = React.useState("");
  const [completeOrderdetail, setCompleteOrderdetail] = React.useState("");
  const [returnDay, setreturnDay] = React.useState(null);
  const [reviewStatus, setReviewStatus] = React.useState(false);
  const [reviewData, setReviewData] = React.useState(false);
  const [payment, setPayment] = React.useState({});
  const [downloadLink, setDownloadLink] = React.useState("");
  const [orderStatus, setOrderStatus] = React.useState("");
  const [reviewPopup, setReviewPopup] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const [comment, setComment] = React.useState("");
  const [heading, setHeading] = React.useState("");
  const [deletePopup, setdeletePopup] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState("");
  const [showInputForm, setInpuForm] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  const [userId] = React.useState(localStorage.getItem("userId"));
  const [confermPopup, setConfermPopup] = React.useState(false);
  const [dropDValue, setDropDvalue] = React.useState("Select"); // controller state for dorpdown
  const [allSteps, setAllSteps] = React.useState([]);
  const [type, setType] = React.useState("");
  const locations = useLocation();
  const history = useHistory();
  const { addToast } = useToasts();
  let { prodId, prodType } = useParams();
  const userData = useSelector(
    (store) => store.userProfile.userProfileBanner.userdata
  );
  let location = {
    ...locations,
    state: { payload: { orderId: prodId, type: prodType } },
  };
  React.useEffect(() => {
    if (
      history.location.state &&
      history.location.state.path == "/profile/notification"
    ) {
      // setType("Pending");
    }

    // Toast.loading("Loading...");
    loadDetails();

    // setTimeout(() => Toast.hide(), 1500);
  }, []);
  console.log("orderdetailMain", orderdetailMain);

  const loadDetails = () => {
    props.getorderDetail(
      location && location.state && location.state.payload
        ? location.state.payload
        : { orderId: prodId, type: prodType },
      (data) => {
        if (data.data && data.data.length === 0) {
          history.goBack();
        } else if (data.type == "Pending") {
          setType("Pending");
          //setOrderdetail(data.data[0])
          setOrderdetail(data.Data);
          setCompleteOrderdetail(data);
        } else if (data.type == "Processed") {
          setType("Processed");
          setOrderdetail(data.Data);
          setCompleteOrderdetail(data);
        } else {
          setPayment({
            method: data.payment_method,
            status: data.payment_status,
          });
          setOrderdetailMain(data);
          setOrderdetail(data.Data);
          setreturnDay(
            data.returnPolcyData && data.returnPolcyData.returnPolicyDay
          );
          setAllSteps(data.all_order_status);
          setReviewStatus(data.done_review);
          setReviewData(data.review_data);
          setOrderStatus(data.Data.order_status);
          // setRating(data.review_data.rating ? data.review_data.rating : 0);
          // setComment(
          //   data.review_data.review_comment
          //     ? data.review_data.review_comment
          //     : ""
          // );
          // setHeading(data.review_data.heading ? data.review_data.heading : "");
        }
      },
      (downloadLink) => setDownloadLink(downloadLink)
    );
  };

  const returnProduct = (paylaod, data) => {
    setShowLoader(true);
    setLoaderMessage("Please wait...");
    props.returnThisOrder(paylaod, data, (status) => {
      if (status) {
        addToast("Return request submitted", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        setShowLoader(false);
        return props.history.push("/profile/myorder"); // { tab: "My_order" }
      } else {
        addToast("Return request failed", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
        setShowLoader(false);
      }
    });
  };

  const cancelProduct = (paylaod, data) => {
    Toast.loading("Processing...");
    props.cancelThisOrder(paylaod, data, (status) => {
      if (status) {
        addToast("Cancel request submitted", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        Toast.success("Success", 1000);
        return props.history.push("/profile/myorder"); //, { tab: "My_order" }
      } else {
        addToast("Cancel request failed", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
        Toast.fail("Failed", 1000);
      }
    });
  };

  const submitReview = (editId) => {
    if (
      comment.trim().length < 1 ||
      rating === 0 ||
      heading.trim().length < 1
    ) {
      return Toast.fail("Please fill all the fields and select rating", 2000);
    } else {
      setShowLoader(true);
      setLoaderMessage("Please wait...");
      const payload = {
        product_id: orderdetail.product_detail._id,
        seller_id: orderdetail.seller_id,
        user_id: orderdetail.customer_details.customer_id,
        rating,
        comment,
        heading,
        review_from: "Web",
      };

      editId
        ? props.editReview({ ...payload, id: editId }, (status) => {
          if (status.data && status.data._id) {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            loadDetails();
            setReviewPopup(false);
          } else {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
          }
        })
        : props.addReview(payload, (status) => {
          if (status.status === "Success") {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            loadDetails();
            setReviewPopup(false);
          } else {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
          }
        });
    }
  };
  const deleteReview = () => {
    Toast.loading("Deleting...");
    props.deleteReview({ id: reviewData._id }, (status) => {
      if (status)
        Toast.success("Delete ", 1000, () => {
          loadDetails();
          setdeletePopup(false);
        });
      else Toast.fail("Failed", 1000, () => setdeletePopup(false));
    });
  };

  const changeStatus = () => {
    setConfermPopup(false);
    setDropDvalue("Packed");
    props.changeOrderStatus(
      {
        status: "Packed",
        orderId: orderdetail._id,
      },
      (status) => {
        if (status) {
          addToast("Status changed", {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          loadDetails();
        }
      }
    );
  };

  const getClass = (data) => {
    if (data === "Completed") return "confirm";
    else if (data === "Processed") return "process";
    else if (data === "Cancelled") return "cancel";
    else return "";
  };

  const sendInvoice = () => {
    props.sendInvoice({ orderId: orderdetail._id }, (status) => {
      if (status) {
        addToast("Invoice has been sent to your email", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
      } else {
        addToast("failed", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    });
  };


  return (
    <div>
      {showLoader ? <Loader message={loaderMessage} /> : null}
      <div className="order-detail-wrapper"></div>

      <div>
        {orderdetail !== "" ? (
          <div className="order-details order-detail-page">
            {
              <>
                <PendingOrderDetail
                  orderdetail={orderdetail}
                  completeOrderdetail={completeOrderdetail}
                  loadDetails={loadDetails}
                  location={location}
                  getClassName={getClass}
                  payment={payment}
                  sendInvoice={sendInvoice}
                  downloadLink={downloadLink}
                  reviewStatus={reviewStatus}
                  setReviewPopup={setReviewPopup}
                  setdeletePopup={setdeletePopup}
                  reviewData={reviewData}
                  returnDay={returnDay}
                  setOrderId={setOrderId}
                  history={history}
                  type={type}
                />
              </>
            }

            {type === "Pending" || type === "Processed" ? null : (
              <div className="order-detail-top">
                <div className="container">
                  <div className="order-top" style={{borderRadius:"7px"}}>
                    {type === "Pending" || type === "Processed" ? null : (
                      <DeliveryAddress Address orderdetail={orderdetail} />
                    )}

                    {location &&
                      location.state &&
                      location.state.payload &&
                      location.state.payload.view === "seller" ? (
                      type === "Pending" || type === "Processed" ? null : (
                        <>
                          <div className="order-right">
                            <h4>Order Details</h4>
                            <ul>
                              <li>
                                <span>Order ID:</span>{" "}
                                <strong> {orderdetail.odid}</strong>
                              </li>
                              <li>
                                <span>Order On:</span>{" "}
                                <strong>
                                  {moment(orderdetail.createdAt).calendar({
                                    sameDay: "DD/MM/YYYY h:mm a",
                                    nextDay: "DD/MM/YYYY h:mm a",
                                    nextWeek: "DD/MM/YYYY h:mm a",
                                    lastDay: "DD/MM/YYYY h:mm a",
                                    lastWeek: "DD/MM/YYYY h:mm a",
                                    sameElse: "DD/MM/YYYY h:mm a",
                                  })}
                                </strong>
                              </li>
                              <li className="awaiting">
                                <span>Order Status:</span>{" "}
                                <strong
                                  className={getClass(orderdetail.order_status)}
                                >
                                  {" "}
                                  {orderdetail.order_status}
                                </strong>
                              </li>
                              {payment.method === "COD" ? (
                                payment.status === "Completed" ? (
                                  <li>
                                    <span>Payment Status: </span>{" "}
                                    <strong className="confirm">
                                      {" "}
                                      {payment.status}
                                    </strong>
                                  </li>
                                ) : null
                              ) : (
                                <li>
                                  <span>Payment Status: </span>
                                  <strong className={getClass(payment.status)}>
                                    {payment.status}
                                  </strong>
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="down-btn d-none">
                            <ul>
                              <li>
                                <a href="/contactUs" className="need-help">
                                  <i className="fas fa-question-circle" />
                                  &nbsp;need help
                                </a>
                              </li>
                              {orderdetail.order_status === "Completed" && (
                                <li>
                                  <span
                                    className="send-inv"
                                    onClick={() => sendInvoice()}
                                  >
                                    {" "}
                                    Send Invoice{" "}
                                  </span>
                                </li>
                              )}
                            </ul>
                            <Link
                              className="shop-now msg-btn"
                              to={{
                                pathname: "/chats/all",
                                state: {
                                  chatPayload: {
                                    sender_id:
                                      orderdetail.customer_details.customer_id,
                                    product_id: orderdetail.product_detail._id,
                                    orderId: orderdetail.order_id,
                                    orderdetail:orderdetail
                                  },
                                },
                              }}
                            >
                              <i className="fas fa-envelope icon-chat-new" />
                            </Link>
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        {orderdetail.order_status === "Completed" && orderdetailMain &&

                          (userData && userData.userType === "Buyer" ? !orderdetailMain.isReviwed_buyer : !orderdetailMain.isReviwed_seller) && (
                            <>
                              <MoreAction
                                orderdetail={orderdetail}
                                downloadLink={downloadLink}
                                reviewStatus={reviewStatus}
                                setReviewPopup={setReviewPopup}
                                setdeletePopup={setdeletePopup}
                                reviewData={reviewData}
                                orderdetailMain={orderdetailMain}
                              />
                            </>
                          )}
                        <div className="btns-download">
                          {/* {downloadLink && (
                            <DownloadBtn downloadLink={downloadLink} />
                          )} */}
                          <div className="ret-ref">
                            {orderdetail.order_status === "Completed" &&
                              orderdetail.current_status_time &&
                              moment().isBefore(
                                moment(orderdetail.current_status_time).add(
                                  returnDay,
                                  "days"
                                )
                              ) ? (
                              <div
                                className="switch-wrapper action-btn"
                                onClick={() => {
                                  setInpuForm(true);
                                  setOrderId(orderdetail._id);
                                }}
                              >
                                {/* <button className="header-sell">Return</button> */}
                              </div>
                            ) : orderdetail.order_status === "Cancelled" ||
                              orderdetail.order_status === "Returned" ? (
                              <div
                                className="switch-wrapper action-btn"
                                onClick={() => history.goBack()}
                              >
                                <button className="header-sell">Back</button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            {(userData && userData.userType==="Buyer" ? orderdetailMain.isReviwed_buyer : orderdetailMain.isReviwed_seller) ? <div className="order-detail-top" style={{marginTop:"20px"}}>
              <div className="container">
                <div className="order-top" style={{borderRadius:"7px"}}>
                    <div className="order-right" style={{width:"100%"}}>
                      <h4 style={{marginBottom:"11px"}}>Your rating to {userData.userType==="Buyer"?"Seller":"Buyer"}</h4>
                      <ul>
                        <li>
                          <span>Heading:</span>{" "}
                          <strong>{userData.userType==="Buyer" ? 
                          (orderdetailMain.buyer_rating_list && orderdetailMain.buyer_rating_list[0] && orderdetailMain.buyer_rating_list[0].heading) : 
                          orderdetailMain.seller_rating_list && orderdetailMain.seller_rating_list[0] && orderdetailMain.seller_rating_list[0].heading}</strong>
                        </li>
                        <li>
                          <span>Comment:</span>{" "}
                          <strong>{userData.userType==="Buyer" ? 
                          (orderdetailMain.buyer_rating_list && orderdetailMain.buyer_rating_list[0] && orderdetailMain.buyer_rating_list[0].comment) : 
                          orderdetailMain.seller_rating_list && orderdetailMain.seller_rating_list[0] && orderdetailMain.seller_rating_list[0].comment}</strong>
                        </li>
                        <li className="awaiting">
                          {userData.userType==="Buyer" ? 
                          (orderdetailMain.buyer_rating_list && orderdetailMain.buyer_rating_list[0] && orderdetailMain.buyer_rating_list[0].rating && <RatingStar value={orderdetailMain.buyer_rating_list[0].rating}/>) : 
                          orderdetailMain.seller_rating_list && orderdetailMain.seller_rating_list[0] && orderdetailMain.seller_rating_list[0].rating && <RatingStar value={orderdetailMain.seller_rating_list[0].rating}/>}
                        </li>
                      </ul>
                    </div>
                </div>
              </div>
            </div>:null}


            {reviewPopup && (
              <ReviewPoup
                {...reviewData}
                onClose={() => setReviewPopup(false)}
                submit={submitReview}
                newRating={rating}
                setRating={setRating}
                newComment={comment}
                setComment={setComment}
                heading={heading}
                setHeading={setHeading}
                orderId={orderdetail._id}
                sellerId={orderdetail.seller_id}
                buyerId={orderdetail.customer_id}
                userType={userData ? userData.userType : null}
              />
            )}

            {deletePopup && (
              <DeletePopup
                onClose={() => setdeletePopup(false)}
                delete={deleteReview}
              />
            )}

            {confermPopup && (
              <Confermation
                onClose={() => setConfermPopup(false)}
                onSubmit={changeStatus}
              />
            )}
            {showInputForm && (
              <InputReason
                closePopup={() => setInpuForm(false)}
                reasonList={props.getReasonList}
                submitFun={(reason, array) => {
                  if (orderdetail.order_status === "Completed") {
                    returnProduct({
                      orderId,
                      reason: reason,
                      validReasons: array,
                    });
                  } else if (orderdetail.order_status === "Processed") {
                    cancelProduct({
                      orderId,
                      reason: reason,
                      validReasons: array,
                    });
                  }
                  setTimeout(() => setInpuForm(false), 500);
                }}
                content={`Please select the reason for ${orderdetail.order_status === "completed"
                    ? " return"
                    : " cancel"
                  }`}
                formAction={
                  orderdetail.order_status === "Completed"
                    ? "return"
                    : orderdetail.order_status === "Processed"
                      ? "cancel"
                      : null
                }
              />
            )}
          </div>
        ) : null}
      </div>
      {/* <DeliveryStep
        allSteps={allSteps}
        completed={orderdetail.order_status_code}
        currentOrderStatus={orderStatus}
      /> */}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    userContactInfo: state.userProfile.userProfileBanner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getorderDetail: (payload, data, downloadLink) =>
      dispatch(getorderDetail(payload, data, downloadLink)),
    addReview: (payload, status) =>
      dispatch(addReviewOnProduct(payload, status)),
    editReview: (payload, status) =>
      dispatch(editReviewOnProduct(payload, status)),
    deleteReview: (payload, status) =>
      dispatch(deleteReviewOnProduct(payload, status)),
    getReasonList: (payload, callback) => getReasonList(payload, callback),
    cancelThisOrder: (paylaod, data, status) =>
      dispatch(cancelThisOrder(paylaod, data, status)),
    returnThisOrder: (paylaod, data, status) =>
      dispatch(returnThisOrder(paylaod, data, status)),
    changeOrderStatus: (paylaod, status) => changeOrderStatus(paylaod, status),
    sendInvoice: (payload, status) => sendInvoice(payload, status),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);

export const ReviewPoup = (props) => {
  const dispatch = useDispatch();
  const clickHandler = (
    sellerid,
    buyerid,
    heading,
    rating,
    comment,
    orderid
  ) => {
    const payload = {
      sellerId: props.sellerId,
      buyerId: props.buyerId,
      heading: heading,
      rating: rating,
      orderId: props.orderId,
      comment: comment,
      ratingBy: props.userType
    };
    dispatch(addUserReview(payload, props.onClose));
  };
  return (
    <>
      <div className="make-offer-popup review-popup">
        <div className="offer-content">
          <button
            className="modal-closeBtn"
            onClick={() => props.onClose()}
            type="button"
          >
            {/* <span className="closeBtn-crossLeft"></span>
        <span className="closeBtn-crossRight"></span> */}
            <i className="fal fa-times-circle"></i>
          </button>
          <div className="offer-title">
            {/* {props._id ? `Edit Review` : `Add Review`}{" "} */}
            Add Review
          </div>
          <form>
            <div className="detail-from wh-fld">
              <div className="form-input">
                {/* <span for="heading heading-wrap">Heading</span> */}
                <div className="required-wrapper">
                  <span for="heading" className="heading-wrap">
                    Heading <span className="required-field">*</span>
                  </span>
                </div>
                <input
                  type="text"
                  id="heading"
                  placeholder="Heading"
                  value={props.heading}
                  onChange={(e) => props.setHeading(e.currentTarget.value)}
                />
              </div>
              <div>
                <Rating
                  min={1}
                  max={5}
                  onChange={(rating) => props.setRating(rating)}
                  value={props.newRating}
                />
              </div>
              <div className="form-input">
                <div className="required-wrapper">
                  <span for="heading" className="heading-wrap">
                    Comment <span className="required-field">*</span>
                  </span>
                </div>
                <textarea
                  rows="4"
                  cols="30"
                  maxLength={200}
                  name="comment"
                  placeholder="Write a review..."
                  onChange={(e) => props.setComment(e.currentTarget.value)}
                  value={props.newComment}
                />
              </div>
            </div>
          </form>
          <div className="add-links">
            <span className="btn-purple" onClick={() => props.onClose()}>
              Cancel
            </span>
            <span
              className="btn-purple"
              // onClick={() => props.submit(props._id)}
              onClick={() => {
                clickHandler(
                  props.SellerId,
                  props.UserId,
                  props.heading,
                  props.newRating,
                  props.newComment,
                  props._id
                );
              }}
            >
              Submit
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export const DeletePopup = (props) => (
  <div className="make-offer-popup">
    <div className="offer-content">
      <button
        className="modal-closeBtn"
        onClick={() => props.onClose()}
        type="button"
      >
        <i className="fal fa-times-circle"></i>
      </button>
      <div className="offer-title">
        Do you really want to delete this review
      </div>
      <div className="add-links">
        <span className="btn-purple" onClick={() => props.onClose()}>
          Cancel
        </span>
        <span className="btn-purple" onClick={props.delete}>
          Submit
        </span>
      </div>
    </div>
  </div>
);

export const Confermation = (props) => (
  <div className="make-offer-popup">
    <div className="offer-content">
      <button
        className="modal-closeBtn"
        onClick={() => props.onClose()}
        type="button"
      >
        <i className="fal fa-times-circle"></i>
      </button>
      <div className="offer-title">
        Do you realy want to change the status to Packed ?
      </div>
      <div className="add-links">
        <span className="btn-purple" onClick={props.onClose}>
          Cancel
        </span>
        <span className="btn-purple" onClick={props.onSubmit}>
          Submit
        </span>
      </div>
    </div>
  </div>
);
