import React, { useState } from 'react'
import { axiosInstance } from '../../../utils/Service'

const RolePermissionPopup = ({ setPopup, userData, setLoading, handleListing, addToast }) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [phone, setPhone] = useState({ value: '' })
    const [designation, setDesignation] = useState("")
    const [permission, setPermission] = useState("")


    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [emailValidError, setEmailValidError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [passwordLengthError, setPasswordLengthError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [designationError, setDesignationError] = useState(false)
    const [permissionError, setPermissionError] = useState(false)

    const handleEmail = (e) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        console.log("filter.test(e.target.value", filter.test(e.target.value))
        if (filter.test(e.target.value)) {
            setEmailValidError(false)
        }
        else {
            setEmailValidError(true)
        }
        setEmail(e.target.value)
        setEmailError(false)
    }


    const handlePhone = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPhone(phone => value.length <= 10 && !isNaN(Number(value)) && { value } || phone)
        setPhoneError(false)
    }

    const handleForm = () => {
        if (firstName.length === 0) {
            setFirstNameError(true);
            return;
        }
        else if (lastName.length === 0) {
            setLastNameError(true);
            return;
        }
        else if (email.length === 0) {
            setEmailError(true);
            return;
        }
        else if (emailValidError) {
            return;
        }
        else if (password.length === 0) {
            setPasswordError(true);
            return;
        }
        else if (password.length < 8) {
            setPasswordLengthError(true);
            return;
        }
        else if (phone.value.length === 0) {
            setPhoneError(true);
            return;
        }
        else if (designation.length === 0) {
            setDesignationError(true);
            return;
        }
        else if (permission.length === 0) {
            setPermissionError(true);
            return;
        }
        let payload = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "password": password,
            "mobile": phone.value,
            countryCode: "+91",
            "userType": userData.userType,
            "designation": designation,
            "premission": permission
        }
        console.log("firspayloadt", payload)

        let token = localStorage.getItem("token");
        setLoading(true)
        axiosInstance.post(`/role_permission/add_sub_user`, payload, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setLoading(false)
            if (res.data.status === "error") {
                addToast(res.data.message, {
                    appearance: "error",
                    autoDismissTimeout: 2500,
                });
            }
            else {
                handleListing()
                setPopup(false)
            }
        }).catch((e) => {
            setLoading(false)
            addToast(e.response.data.message, {
                appearance: "error",
                autoDismissTimeout: 2500,
            });
        });
    }


    return (
        <div className='make-offer-popup-new role-add-popup'>
            <div className='offer-popup-content'>
                <div className='offer-popup-content-header'>
                    <h3>Edit role and permission</h3>
                    <button
                        className=""
                        type="button"
                        onClick={() => setPopup(false)}
                    >
                        <i className="fal fa-times-circle"></i>
                    </button>
                </div>
                <div className="slct-optns select-wrapper">
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            First name{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                value={firstName}
                                onChange={(e) => { setFirstName(e.target.value); setFirstNameError(false) }}
                                placeholder="Enter first name"
                            />
                        </div>
                        {firstNameError && <div className='error'>Please enter first name</div>}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Last name{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                value={lastName}
                                onChange={(e) => { setLastName(e.target.value); setLastNameError(false) }}
                                placeholder="Enter last name"
                            />
                        </div>
                        {lastNameError && <div className='error'>Please enter last name</div>}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Email{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                value={email}
                                onChange={handleEmail}
                                placeholder="Enter email"
                            />
                        </div>
                        {emailError && <div className='error'>Please enter email</div>}
                        {emailValidError && <div className='error'>Please enter valid email</div>}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Password{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="password"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value); setPasswordError(false); setPasswordLengthError(false) }}
                                placeholder="Enter password"
                            />
                        </div>
                        {passwordError && <div className='error'>Please enter password</div>}
                        {passwordLengthError && <div className='error'>Please enter atleast 8 letters</div>}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Mobile{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap input-with-code">
                            <input
                                className={"input-url"}
                                type="text"
                                value={phone.value}
                                placeholder="Enter mobile number"
                                onChange={handlePhone}
                            />
                            <div className='country-code'>+91</div>
                        </div>
                        {phoneError && <div className='error'>Please enter mobile number</div>}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Designation{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                value={designation}
                                onChange={(e) => { setDesignation(e.target.value); setDesignationError(false) }}
                                placeholder="Enter designation"
                            />
                        </div>
                        {designationError && <div className='error'>Please enter designation</div>}
                    </div>

                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Permission{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="select-main-wrapper">
                            <select
                                className='make-offer-select-tag'
                                defaultValue={permission}
                                onChange={(e) => { setPermission(e.target.value); setPermissionError(false) }}
                            >
                                <option hidden>Select permission</option>
                                <option value={"0"}>Order Tracking</option>
                                <option value={"1"}>All</option>
                            </select>
                        </div>
                        {permissionError && <div className='error'>Please select permission</div>}
                    </div>
                    <div className="navigation-back" style={{ flexDirection: "column" }}>
                        <button onClick={handleForm} className="next-btn">Add</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RolePermissionPopup