import { UPDATE_STATUS } from "../actions/makePayment";

const initialState = {
  paymentStatus: "",  // initial status
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STATUS:
      return {
        ...state,
        paymentStatus: action.payload,  // update the status in the store
      };
    default:
      return state;
  }
};

export default paymentReducer;