import React from 'react';



class License extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {

        return (
            <div className="privacy-details full-width-view">
                <div className="container">
                    <div className="general-terms">
                        <h2>License</h2>
                        <div className="desc">
                            <p>License</p>
                        </div>
                        <div className="desc">
                            <p><strong>License</strong></p>
                            <p>Terms and Conditions governing the provision of Vestiaire Collective’s Concierge Services</p>
                            <p>Buying Products</p>
                            <p>Provisions regarding all Users</p>
                            <p>Terms and Conditions governing subscription to Premium Services</p>
                            <p>Provisions regarding all MangoPay Users</p>
                            <p>License</p>
                        </div>
                        <div className="desc">
                            <p>The purpose of the General T&amp;Cs is to define the terms and conditions on which Vestiaire Collective shall directly or via a Partner’s website: provide access to and allow Users to browse the Website, provide Users with services allowing them to sell or buy Products to or from each other, and list Products for sale that it owns directly to Users.</p>
                        </div>
                        <div className="desc">
                            <p>Users will be agreeing to the General T&amp;Cs as soon as they browse the Website including when they create an account and regardless of whether or not they complete any Transactions through the Website. If a User does not want to be bound by the General T&amp;Cs, he/she should not access, register, use or list any Products or place any Orders with Vestiaire Collective.</p>
                        </div>
                        <div className="desc">
                            <p><strong>Personal data protection and privacy policy statement:</strong></p>
                            <p>Personal data protection / Privacy policy / Cookies</p>
                            <p>Vestiaire Collective examines the Products before dispatching them, and uses its best efforts to combat counterfeiting or the sale of banned Products (e.g. Products infringing applicable laws or regulations or third-party rights; prototypes (clothes or accessories made before being launched for mass production) or uniforms (clothes or accessories intended for the employees of certain brands) or some products from “staff sales” or “press sales” which are not authorised for resale. If, however, a Buyer receives a counterfeit Product or a Product that has been banned from sale, a Buyer may return the Product to Vestiaire Collective and receive a refund. The charter is available on this website:</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default License;