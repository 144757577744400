import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { priceWithSymbol } from "../../../../utils/constants";



const ProductCard = ({ item, type, name }) => {

    let history = useHistory();
    let location = useLocation()

    const goTodetailPage = (e, payload) => {
        e.preventDefault();
        history.push({ pathname: `/profile/my-offer/offer-detail/${payload.id}/Pending`, state: { ...location.state, payload } });
    }

    const goTodetailPage1 = (e, payload) => {
        e.preventDefault();
        history.push({ pathname: `/profile/offered-product/offer-detail`, state: { ...location.state, payload } });
    }



    return (
        <div className="list-content listing-content">
            <Link
                to={`/product/${item.productData[0].Title}/${item.productData[0]._id}/?cat=${item.productData[0].Title}`} className="image-value">
                <img src={item.productData[0].default_image} alt={item.title} />
            </Link>

            <Link to={`/product/${item.productData[0].Title}/${item.productData[0]._id}/?cat=${item.productData[0].Title}`} className="price-value">
                <div className="product-naming">{item.productData[0].Title}</div>
                <div className="product-maker">{item.brandData[0].name}</div>

                {item.productData[0].Price.sell_price &&
                    (item.productData[0].Price.sell_price.$numberDecimal !==
                        item.productData[0].Price.current_store_price.$numberDecimal
                    ) ? (
                    <p>
                        <span className="buy-info product_buy_info">
                            {priceWithSymbol(item.productData[0].Price.sell_price.$numberDecimal)}
                        </span>
                        <span className="old-price product_old_price">
                            {priceWithSymbol(item.productData[0].Price.sell_price.$numberDecimal) ===
                                priceWithSymbol(item.productData[0].Price.current_store_price.$numberDecimal) ? null :
                                priceWithSymbol(item.productData[0].Price.current_store_price.$numberDecimal)
                            }
                        </span>
                    </p>
                ) : (
                    <span className="buy-info">
                        {priceWithSymbol(
                            item.productData[0].Price.current_store_price.$numberDecimal
                        )}
                    </span>
                )}
            </Link>

            <div className={"qty-value"}>
                <div className="">{item.totalCount ? item.totalCount : 1}</div>
            </div>

            <div className="list-nav">
                {
                    type === "offered" ?
                        <Link
                            to="#"
                            onClick={(e) => goTodetailPage1(e, { id: item._id, name })}
                        >View Detail</Link>
                        : <Link
                            to="#"
                            onClick={(e) => goTodetailPage(e, { id: item._id, name })}
                        >View Detail</Link>

                }

            </div>
        </div>
    )
}

export default ProductCard;