import {
    SAVE_OFFER_LINK
} from "../actionsTypes";

const initialState = {};

const linkInfo = (state = initialState, action) => {

    switch (action.type) {
        case SAVE_OFFER_LINK:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export default linkInfo;
