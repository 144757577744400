import React from "react";



const Alert = ({ removeCartAndSaveProdCart, closeAlert, isLocalCart, errorMsg }) => {


    return (
        <div className="make-offer-popup alert same-cart add-to-cart-wrapper">
            <div className="offer-content alert">
                <button
                    className="modal-closeBtn"
                    type="button"
                    Normal order onClick={closeAlert}
                >
                    {/* <span className="closeBtn-crossLeft"></span>
                    <span className="closeBtn-crossRight"></span> */}
                    <i className="fal fa-times-circle"></i>
                </button>
                <div className="offer-title">Cart Warning</div>
                {
                    isLocalCart ?
                        errorMsg === "Local order exist in your cart. First remove them" ?
                            <p className="offer-desc">
                                Are you sure to replace this product in your cart because you have already added local product.
                            </p>
                            : errorMsg === "Another product is available in cart with different zip code" ?
                                <p className="offer-desc">
                                    Are you sure to replace this product in your cart because you have already added different zip code product.
                                </p> :
                                <p className="offer-desc">
                                    Are you sure to replace this product in your cart because you have already added normal product.
                                </p>
                        : errorMsg === "different seller" ?
                            <p className="offer-desc">
                                Are you sure to replace this product in your cart because you have already added different seller product.
                            </p>
                            : null
                }

                <button
                    className="header-sell"
                    onClick={closeAlert}
                >Cancel</button>
                <button onClick={removeCartAndSaveProdCart} className="yes-btn header-sell cart-warning-popup">Yes</button>
            </div>
        </div>
    );
};
export default Alert
