import React from 'react';


const Coupon = ({ updateLocalOrderTypeHandler }) => {

    const inputHandler = (e) => {

        updateLocalOrderTypeHandler(e.target.value)
    }

    return (
        <div className="shipping-itemwrap shipping-remade">
            <div className="head-wrap">
                <span className="checkout-headings"> Local Shipping</span>
            </div>
            <div className="desc-txt coupon-block">
                <div className='radio_Wrapper'>
                    <input onChange={inputHandler} defaultChecked type="radio" name="shipping" value="freePickup" className='radio-btn' />
                    <span className='radio-text'>Pickup</span>
                </div>
                <div className='radio_Wrapper'>
                    <input onChange={inputHandler} type="radio" name="shipping" value="freeShipping" className='radio-btn' />
                    <span className='radio-text'>Free Shipping</span>
                </div>

            </div>
        </div>
    )
}

export default Coupon;