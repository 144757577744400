import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import Toast from "light-toast";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "../Loader";
import { axiosInstance, axiosInstanceAuth } from "../../utils/Service";

import {
  joinWaitList,
  socialLogin,
  showSignupErr,
  OTPVerify,
  loginRequest,
  resendSignOTP,
  resendEmailLink,
  OTPVerifyEmail,
} from "./../../store/actions/common";
import { detect } from "detect-browser";
import { SET_SIGNUP_USER_ERROR } from "./../../store/actionsTypes";
import { useToasts } from "react-toast-notifications";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OTPForm from "../Auth/OTPform";
import { getProfileBannerInfo } from "../../store/actions/profile";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import Select from 'react-select';
import "react-phone-number-input/style.css";
//import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";
import { createRef } from "react";

class NewPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      firstName: "",
      lastName: "",
      password: "",
      password2: "",
      showPass1: false,
      showPass2: true,
      email: "",
      mobile: "",
      dob: "",
      checkbox: false,
      showInputForm: false,
      error: false,
      ip: "",
      errorMsg: "",
      status: "",
      refferal_code: "",
      passNotMatch: false,
      OTPForm: false,
      showOTPform: false,
      OTP: "",
      emailOTP: "",
      token: "", // for otp user
      time: 50, //timer for resend otp
      showCalendar: false,
      gender: "",
      showLoader: false,
      showMessage: "",
      invalidEmail: false,
      fullName: "",
      invalidPassword: false,
      verifiedMobileOTP: false,
      verifiedEmailOTP: false,
      value: "",
      userType: "Buyer",
      dialCode: "",
      mobileApi: "",
      secondStep: false,
      firstStep: true,
      addressOne: "",
      addressOneError: false,
      state: "",
      stateError: false,
      city: "",
      cityError: false,
      zipCode: "",
      zipCodeError: false,
      PAN: "",
      panError: false,
      cin: "",
      cinError: false,
      gstNumber: "",
      gstError: false,
      selectedImagePan: null,
      panImageError: false,
      selectedCINImage: null,
      selectedImageGst: null,
      gstImageError: false,
      stateList: [],
    };
    this.companyNameRef = createRef();
    this.firstNameRef = createRef();
    this.lastNameRef = createRef();
    this.emailRef = createRef();
    this.passwordRef = createRef();
  }

  componentDidMount() {
    // fetch("https://api.ipify.org?format=json")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     this.setState({ ip: json.ip });
    //   });
    axiosInstance.get("category/country").then((res) => {
      this.setState({ stateList: res.data.data[0].stateData });
    });
  }

  changeInput = (e) => {
    if (e.target.name === "companyName") {
      const re = /^[A-Za-z/\W|_/g ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "firstName") {
      const re = /^[A-Za-z ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "lastName") {
      const re = /^[A-Za-z ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "password") {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let checkSpecial = specialChars.test(e.target.value);

      function hasUpperCase(str) {
        return /[A-Z]/.test(str);
      }

      function hasLowerCase(str) {
        return /[a-z]/.test(str);
      }

      // const upperCase = /^[A-Z]*$/;
      // let checkUpperCase = upperCase.test(e.target.value);

      const number = /\d/;
      let checkNumber = number.test(e.target.value);

      if (
        checkSpecial &&
        hasUpperCase(e.target.value) &&
        hasLowerCase(e.target.value) &&
        checkNumber
      ) {
        this.setState({
          [e.target.name]: e.target.value,
          invalidPassword: false,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          invalidPassword: true,
        });
      }
    } else this.setState({ [e.target.name]: e.target.value, status: false });
  };

  changeInputEmail = (e) => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(e.target.value)) {
      this.setState(
        { [e.target.name]: e.target.value, status: false, invalidEmail: false },
        () =>
          this.props.showSignupErr({
            type: SET_SIGNUP_USER_ERROR,
            payload: { errData: "", status: false },
          })
      );
      this.props.showSignupErr({
        type: SET_SIGNUP_USER_ERROR,
        payload: { errData: "", status: false },
      });
    } else {
      if (e.target.value.length > 0) {
        this.setState({ [e.target.name]: e.target.value, invalidEmail: true });
      } else {
        this.setState({ [e.target.name]: e.target.value, invalidEmail: false });
      }
    }
  };

  checkboxState = () => {
    this.setState({ checkbox: !this.state.checkbox, error: false });
  };

  responseFacebook = (response) => {
    if (response.status !== "unknown") {
      const name = response.name.split(" ");
      const first_name = name[0];
      const last_name = name[name.length - 1];
      const email = response.email;
      const socialId = response.id;
      const payload = {
        first_name,
        last_name,
        email,
        socialId,
        provider: "facebook",
      };
      this.props.socialLogin(payload, (status) => {
        if (status) this.setState({ showLoader: false });
        this.props.addToast("Signed with Facebook", { appearance: "success" });
        this.props.closePopup(true);
      });
    }
  };

  responseGoogle = (response) => {
    const data = response.profileObj;
    const first_name = data.givenName;
    const last_name = data.familyName;
    const email = data.email;
    const socialId = data.googleId;
    const payload = {
      first_name,
      last_name,
      email,
      socialId,
      provider: "google",
    };
    this.props.socialLogin(payload, (status) => {
      if (status) this.setState({ showLoader: false });
      this.props.addToast("Signed with Google", { appearance: "success" });
      this.props.closePopup(true);
    });
  };

  enterPhone = (e, code) => {
    let temp = e;
    this.setState({ mobile: temp });
    // let temp = e;
    // let checkDigit = new RegExp(/^\d*$/);
    // if (checkDigit.test(temp)) {
    //   this.setState({ mobile: temp });
    // }
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  submitOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const params = {
      otp: this.state.OTP,
      token: this.state.token,
    };
    this.props.OTPVerify(params, (callback) => {
      if (callback.data.status) {
        this.setState({ showLoader: false });
        this.setState({ showOTPform: true, verifiedMobileOTP: true });

        this.props.addToast(
          callback.data.message,
          { appearance: "success" },
          () => {
            this.setState({ showOTPform: false });
          }
        );
        setTimeout(() => {
          localStorage.setItem(
            "approved",
            callback.data && callback.data.userData.Approval
          );
          localStorage.setItem("pendinModal", true);
          //localStorage.setItem("isProfileCompleted", callback.data && callback.data.userData.isProfileCompleted);
          window.location.reload();
        }, 1000);
      } else {
        this.setState({ showLoader: false });
        this.props.addToast(callback.data.message, { appearance: "error" });
      }
    });
  };

  submitOTPEmail = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const params = {
      otp: this.state.emailOTP,
      token: this.state.token,
    };
    this.props.OTPVerifyEmail(params, (callback) => {
      if (callback.status) {
        this.setState({ showLoader: false });
        this.setState({ showOTPform: true, verifiedEmailOTP: true });

        this.props.addToast(
          callback.data.message,
          { appearance: "success" },
          () => {
            this.setState({ showOTPform: false });
            //this.props.closePopup(false);
          }
        );
        // this.loginFunction();

        setTimeout(() => {
          if (this.state.verifiedEmailOTP && this.state.verifiedMobileOTP) {
            localStorage.setItem(
              "isProfileCompleted",
              callback.data && callback.data.userData.isProfileCompleted
            );
            // this.props.history.push("/sellerRegistration/" + "2wefrt433f257354f34");
            // this.props.closePopup(true);
            window.location.reload();
          }
        }, 1000);
      } else {
        this.setState({ showLoader: false });
        this.props.addToast(callback.data.message, { appearance: "error" });
      }
    });
  };

  resendMobOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });

    this.props.resendSignOTP({ token: this.state.token }, (callback) => {
      if (callback) {
        this.setState({ showLoader: false });
        this.props.addToast(callback.message, { appearance: "success" });
        this.setState({ time: 50 });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  resendEmailOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });

    this.props.resendSignOTP({ token: this.state.token }, (callback) => {
      if (callback) {
        this.setState({ showLoader: false });
        this.props.addToast(callback.message, { appearance: "success" });
        this.setState({ time: 50 });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  resendEmail = () => {
    this.props.resendEmailLink({ token: this.state.token }, (callback) => {
      this.props.addToast(callback.message, { appearance: "success" });
      this.setState({ time: 50 });
    });
  };

  loginFunction = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const payload = {
      email: this.state.email,
      password: this.state.password,
    };
    localStorage.setItem("rememberMe", true);
    localStorage.setItem("bazaarEmail", payload.email);
    localStorage.setItem("bazaarPass", payload.password);

    this.props.loginRequest(payload, (status) => {
      if (status.status) {
        this.props.getProfileBannerInfo();
        window.location.reload();
        this.setState({ showLoader: false });
        this.props.addToast("Login Successfully", { appearance: "success" });
        this.props.closePopup(false);
        this.props.addToast(status.data.errData, { appearance: "error" });
      }
    });
  };

  ChangeFormateDate = () => {
    const [dd, mm, yy] = this.state.dob.split(/-/g);
    return `${mm}-${dd}-${yy}`;
  };
  setDateOfBirth = (props) => {
    const newDOB =
      props.getDate() +
      "-" +
      (props.getMonth() + 1) +
      "-" +
      props.getFullYear();
    this.setState({ showCalendar: !this.state.showCalendar, dob: newDOB });
  };

  backHandler = () => {
    this.setState({ OTPForm: false });
  };

  getCode = (item) => {};

  buyerHandle = (e) => {
    this.setState({ userType: e.target.value });
  };

  sellerHandle = (e) => {
    this.setState({ userType: e.target.value });
  };

  nextStep = () => {};

  addressOnehandler = (e) => {
    this.setState({ addressOne: e.target.value });
    this.setState({ addressOneError: false });
  };
  cityhandler = (e) => {
    this.setState({ city: e.target.value });
    this.setState({ cityError: false });
  };
  statehandler = (e) => {
    this.setState({ state: e.target.value });
    this.setState({ stateError: false });
  };
  zipcodehandler = (e) => {
    this.setState({ zipCode: e.target.value });
    this.setState({ zipCodeError: false });
  };
  panChangeHandler = (e) => {
    this.setState({ PAN: e.target.value });
    this.setState({ panError: false });
  };
  cinChangeHandler = (e) => {
    this.setState({ cin: e.target.value });
    this.setState({ cinError: false });
  };
  gstNumberChangeHandler = (e) => {
    this.setState({ gstNumber: e.target.value });
    this.setState({ gstError: false });
  };
  panImageHandler = (e) => {
    this.setState({ selectedImagePan: e.target.files[0] });
    this.setState({ panImageError: false });
  };
  cinImageHandler = (e) => {
    this.setState({ selectedCINImage: e.target.files[0] });
  };
  gstImageHandler = (e) => {
    this.setState({ selectedImageGst: e.target.files[0] });
    this.setState({ gstImageError: false });
  };

  handleNextClick = () => {
    if (this.state.companyName.length === 0) {
      this.companyNameRef.current.focus();
    } else if (this.state.firstName.length === 0) {
      this.firstNameRef.current.focus();
    } else if (this.state.lastName.length === 0) {
      this.lastNameRef.current.focus();
    } else if (this.state.email.length === 0) {
      this.emailRef.current.focus();
    } else if (this.state.password.length === 0) {
      this.passwordRef.current.focus();
    } else if (this.state.invalidEmail) {
      return null;
    } else if (this.state.invalidPassword) {
      return true;
    } else {
      this.setState({ firstStep: false, secondStep: true });
    }
  };

  // formSubmit = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append('companyName', this.state.companyName);
  //   formData.append('first_name', this.state.firstName);
  //   formData.append('last_name', this.state.lastName);
  //   formData.append('password', this.state.password);
  //   formData.append('mobile', this.state.mobile);
  //   formData.append('email', this.state.email);
  //   formData.append('countryCode', '+91');
  //   formData.append('userType', this.state.userType);
  //   formData.append('gstNumber', this.state.gstNumber);
  //   formData.append('PAN', this.state.PAN);
  //   formData.append('cin', this.state.cin);
  //   formData.append('addressLine1', this.state.addressOne);
  //   formData.append('zipCode', this.state.zipCode);
  //   formData.append('state', this.state.state);
  //   formData.append('city', this.state.city);
  //   formData.append('PANImages', this.state.selectedImagePan);
  //   formData.append('gstFile', this.state.selectedImageGst);
  //   formData.append('cinImage', this.state.selectedCINImage);

  //   // need function for api
  //   axiosInstanceAuth.post('newSignupApi', formData).then((res) => {

  //     // uncomment below code to close popup on successful signup
  //     // this.props.closePopup(true);
  //   });

  //   // e.preventDefault();
  //   // if (this.state.invalidEmail) {
  //   //   return null
  //   // }
  //   // else if (this.state.invalidPassword) {
  //   //   return true
  //   // }
  //   // //  else if (this.state.password2 !== this.state.password) {
  //   // //   this.setState({ passNotMatch: true });
  //   // // }
  //   // else {
  //   //   // this.setState({ firstStep: false, secondStep: true })
  //   //   //this.setState({ error: true });
  //   // }
  // };

  formSubmit = (e) => {
    e.preventDefault();
    if (this.state.invalidEmail) {
      return null;
    } else if (this.state.invalidPassword) {
      return true;
    } else if (this.state.checkbox) {
      this.setState({ passNotMatch: false });
      this.setState({ showLoader: true, showMessage: "Please wait..." });
      const browser = detect();
      const payload = {
        companyName: this.state.companyName,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        mobile: this.state.mobileApi,
      };

      this.props.joinWaitList(payload, (status) => {
        if (status.status === "success") {
          this.setState({ showLoader: false });
          this.props.addToast(status.message, { appearance: "success" });
          setTimeout(() => {
            this.props.closePopup(true);
          }, 2000);
        } else {
          this.setState({ showLoader: false });
          this.props.addToast(status.message, { appearance: "error" });
          // Toast.fail("SignUp Fail..", 1000);
        }
      });
    } else {
      this.setState({ error: true });
    }
  };

  responseFacebook = (response) => {
    if (response.status !== "unknown") {
      const name = response.name.split(" ");
      const first_name = name[0];
      const last_name = name[name.length - 1];
      const email = response.email;
      const socialId = response.id;
      const payload = {
        first_name,
        last_name,
        email,
        socialId,
        provider: "facebook",
      };
      this.props.socialLogin(payload, (status) => {
        if (status) this.setState({ showLoader: false });
        this.props.addToast("Signed with Facebook", { appearance: "success" });
        this.props.closePopup(true);
      });
    }
  };

  back = () => {
    this.setState({ firstStep: true, secondStep: false });
  };

  render() {
    const {
      firstName,
      lastName,
      password,
      showPass1,
      password2,
      showPass2,
      email,
      error,
      status,
      errorMsg,
      passNotMatch,
      mobile,
      showOTPform,
      OTP,
      time,
      dob,
      showCalendar,
      gender,
    } = this.state;

    return (
      <>
        {this.state.showLoader ? (
          <Loader message={this.state.showMessage} />
        ) : null}
        <div
          className="outside-layer"
          onClick={() => this.props.closePopup(true)}
        ></div>

        <div className="popup-wrap signup-popup-wrapper new-banner-popup">
          <div
            className={
              "signup-form signup-frm-wrp signup-page-wrapp signup-new-wrap" +
              (showOTPform ? " otp-open" : "") +
              (this.state.OTPForm ? " otp-open" : "")
            }
          >
            <Helmet>
              <style>
                {`
           .facebook-button-holder, .google-button-holder {
            position: relative;
           }
            .facebook-button-holder span button, .google-button-holder button:first-child {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 15;
              width: 100%;
              height: 100%;
             opacity:0 !important;
            }
            `}
              </style>
            </Helmet>
            <button
              className="close-btn"
              onClick={() => this.props.closePopup()}
            >
              <i className="fal fa-times-circle"></i>
            </button>

            <div
              className="inner-sign signup-without-img"
              style={showOTPform ? { justifyContent: "space-around" } : {}}
            >
              <div className="sign-form">
                {this.state.OTPForm ? (
                  <OTPForm
                    OTP={OTP}
                    EmailOTP={this.state.emailOTP}
                    setState={(key, val) => this.setState({ [key]: val })}
                    submitOTP={this.submitOTP}
                    submitOTPEMAIL={this.submitOTPEmail}
                    time={time}
                    resendMobOTP={this.resendMobOTP}
                    resendEmail={this.resendEmail}
                    backHandler={this.backHandler}
                    mobileNumber={this.state.mobile}
                    verifyMobileOTP={this.state.verifiedMobileOTP}
                    verifyEmailOTP={this.state.verifiedEmailOTP}
                  />
                ) : null}
                <form autoComplete="off" onSubmit={(e) => this.formSubmit(e)}>
                  {this.state.firstStep ? (
                    <div className="detail-from signup-detail-form sign-up-form-wrap">
                      <div className="user-detail-edit signup-head-wrap">
                        <div className="logo-wrapper">
                          <Link to="/" className="logo-wrap">
                            <img
                              src="/assets/images/metal-buy-logoo.png"
                              alt="logo"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="form-block-wrap">
                        <div className="enter-mobile-label">Company Name*</div>
                        <div className="form-input">
                          <TextField
                            variant="outlined"
                            required
                            type="text"
                            name="companyName"
                            placeholder="Enter company name here"
                            value={this.state.companyName}
                            onChange={(e) => this.changeInput(e)}
                            onKeyDown={this.handleEnter}
                            InputProps={{
                              inputProps: {
                                ref: this.companyNameRef,
                                maxLength: 30,
                                minLength: 2,
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="names-wrapper">
                        <div className="form-block-wrap">
                          <div className="enter-mobile-label">First Name*</div>
                          <div className="form-input">
                            <TextField
                              required
                              variant="outlined"
                              type="text"
                              name="firstName"
                              placeholder="Enter first name"
                              value={this.state.firstName}
                              onChange={(e) => this.changeInput(e)}
                              onKeyDown={this.handleEnter}
                              InputProps={{
                                inputProps: {
                                  ref: this.firstNameRef,
                                  maxLength: 30,
                                  minLength: 2,
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-block-wrap">
                          <div className="enter-mobile-label">Last Name*</div>
                          <div className="form-input">
                            <TextField
                              required
                              variant="outlined"
                              type="text"
                              name="lastName"
                              placeholder="Enter last name"
                              value={this.state.lastName}
                              onChange={(e) => this.changeInput(e)}
                              onKeyDown={this.handleEnter}
                              InputProps={{
                                inputProps: {
                                  ref: this.lastNameRef,
                                  maxLength: 30,
                                  minLength: 2,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-block-wrap">
                        <div className="enter-mobile-label">Email*</div>
                        <div className="form-input">
                          <TextField
                            variant="outlined"
                            required
                            type="email"
                            name="email"
                            placeholder="Enter email address"
                            value={email}
                            onChange={(e) => this.changeInputEmail(e)}
                            onKeyDown={this.handleEnter}
                            inputProps={{
                              ref: this.emailRef,
                            }}
                          />
                          {this.state.invalidEmail ? (
                            <span className="error">
                              Enter a valid email address
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="enter-mobile-label">Mobile</div>
                      <div className="form-input number-code-wrapper signup-phone-input signup-code">
                        <PhoneInput
                          country={"in"}
                          disableDropdown={true}
                          placeholder="Enter mobile no."
                          className={this.state.mobile && "arrow-hide"}
                          enableSearch={true}
                          value={this.state.mobile}
                          onChange={(phone, country) => {
                            const reducedPhone = phone.replace(
                              country.dialCode,
                              ""
                            );
                            this.setState({
                              mobile: phone,
                              mobileApi: reducedPhone,
                              dialCode: country.dialCode,
                            });
                          }}
                          isValid={(value, country) => {
                            if (value.length === 0) {
                              return false;
                            } else {
                              return true;
                            }
                          }}
                          inputProps={{
                            required: true,
                          }}
                        />
                      </div>
                      <div className="term-condition">
                        <div className="term-condition-wrapper-box">
                          <input
                            type="checkbox"
                            className="signup-checkbox"
                            checked={this.state.checkbox}
                            onChange={this.checkboxState}
                            onKeyDown={this.handleEnter}
                          />
                          <label htmlFor="vehicle1">
                            {" "}
                            <p>
                              By clicking on submit you accept our{" "}
                              <Link
                                to="/policy"
                                className="condition-link"
                                target="_blank"
                              >
                                terms & conditions
                              </Link>
                            </p>
                          </label>
                          <br />
                        </div>
                        <div className="privacy-error-msz">
                          {error && (
                            <div className="error">
                              Please accept Terms & Conditions and Privacy
                              Policy{" "}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="login-btn-wrapper">
                        <button className="shop-now">Submit</button>
                      </div>
                    </div>
                  ) : this.state.secondStep ? (
                    <>
                      <div className="login-description login-description-form-wrap address-field-wrap upload-document-wrapper">
                        <button
                          type="button"
                          className="btn-bck"
                          onClick={() => this.back()}
                        >
                          <i class="fas fa-long-arrow-left"></i>
                        </button>
                        <h3>Mailing Address</h3>
                        <div className="address-field-new-wrap">
                          <div className="addres-header-wrap"></div>
                          <div className="form-block-wrap">
                            <label className="enter-mobile-label">
                              Address line*
                            </label>
                            <div className="form-input">
                              <input
                                type="text"
                                placeholder="Type here"
                                onChange={(e) => this.addressOnehandler(e)}
                                id="addressOne"
                                value={this.state.addressOne}
                                maxLength="50"
                              />
                            </div>
                            {this.state.addressOneError ? (
                              <span className="error">
                                Please enter address line 1
                              </span>
                            ) : null}
                          </div>

                          <div className="form-block-wrap">
                            <div className="form-intput">
                              <label className="enter-mobile-label">
                                City*
                              </label>
                              <input
                                type="text"
                                placeholder="Enter city name here"
                                onChange={(e) => this.cityhandler(e)}
                                id="city"
                                value={this.state.city}
                                maxLength="100"
                              />
                            </div>
                            {this.state.cityError ? (
                              <span className="error">Please enter city</span>
                            ) : null}
                          </div>

                          <div className="names-wrapper">
                            <div className="input-form-wrap select-affilation-profile address-name-wrap">
                              <div className="form-intput select-affilation-wrap">
                                <label className="enter-mobile-label">
                                  State*
                                </label>

                                <div className="select-main-wrapper">
                                  <select
                                    onChange={(e) => this.statehandler(e)}
                                    id="state"
                                  >
                                    <option value={""}>Select</option>
                                    {this.state.stateList.length
                                      ? this.state.stateList.map(
                                          (item, index) => (
                                            <option value={item._id}>
                                              {item.name}
                                            </option>
                                          )
                                        )
                                      : null}
                                  </select>
                                  <i className="icon-arrow-down"></i>
                                </div>
                                {this.state.stateError ? (
                                  <span className="error">
                                    Please select state
                                  </span>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-block-wrap">
                              <div className="form-intput">
                                <label className="enter-mobile-label">
                                  Pin code*
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter pincode here"
                                  onChange={(e) => this.zipcodehandler(e)}
                                  id="zip"
                                  value={this.state.zipcode}
                                  maxLength="6"
                                />
                              </div>
                              {this.state.zipCodeError ? (
                                <span className="error">
                                  "Please enter pin code
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="form-block-wrap">
                          <div className="form-intput">
                            <label className="enter-mobile-label">
                              PAN number*
                            </label>
                            <input
                              type="text"
                              placeholder="Enter PAN number here"
                              onChange={(e) => this.panChangeHandler(e)}
                              id="pan"
                              value={this.state.PAN}
                              maxLength="10"
                            />
                          </div>
                          {this.state.panError ? (
                            <span className="error">
                              Please enter PAN number
                            </span>
                          ) : null}
                        </div>
                        <div className="form-block-wrap">
                          <div className="form-intput">
                            <label className="enter-mobile-label">
                              CIN number
                            </label>
                            <input
                              type="text"
                              placeholder="Enter CIN number here"
                              onChange={(e) => this.cinChangeHandler(e)}
                              id="cin"
                              value={this.state.cin}
                              maxLength="21"
                            />
                          </div>
                          {this.state.cinError ? (
                            <span className="error">
                              Please enter CIN number
                            </span>
                          ) : null}
                        </div>
                        <div className="form-block-wrap">
                          <div className="form-intput">
                            <label className="enter-mobile-label">
                              GST number*
                            </label>
                            <input
                              type="text"
                              placeholder="Enter GST number here"
                              onChange={(e) => this.gstNumberChangeHandler(e)}
                              id="gstNumber"
                              value={this.state.gstNumber}
                              maxLength="15"
                            />
                          </div>
                          {this.state.gstError ? (
                            <span className="error">
                              Please enter GST number
                            </span>
                          ) : null}
                        </div>
                        <div className="login-description description-new-wrap upload-desc-wrapper">
                          <h2>Upload document</h2>
                          <div className="main-img-wrap">
                            <div className="form-intput input-upload-form">
                              {this.state.selectedImagePan ? (
                                <button
                                  onClick={() =>
                                    this.setState({ selectedImagePan: null })
                                  }
                                  className="close-img-btn"
                                >
                                  <i className="close-img-icon"></i>
                                </button>
                              ) : null}
                              <div className="select-edit-wrap">
                                {this.state.selectedImagePan && (
                                  <div className="select-img-upload">
                                    <img
                                      alt="not found"
                                      src={URL.createObjectURL(
                                        this.state.selectedImagePan
                                      )}
                                    />
                                  </div>
                                )}

                                {!this.state.selectedImagePan && (
                                  <div className="input-file-upload-wrap">
                                    <div className="input-upload">
                                      <div className="file-image">
                                        <img
                                          src="/assets/images/choose-img-icon.svg"
                                          alt="icon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <input
                                  type="file"
                                  className="file-upload-input"
                                  name="myImage"
                                  onChange={(event) => {
                                    this.panImageHandler(event);
                                  }}
                                />
                              </div>
                              <div className="upload-label-wrap">
                                <label class="upload-label">Pan Card*</label>
                              </div>
                              {this.state.panImageError ? (
                                <span className="error">
                                  Please select pan card image
                                </span>
                              ) : null}
                            </div>

                            <div className="form-intput input-upload-form">
                              {this.state.selectedImageGst ? (
                                <button
                                  onClick={() =>
                                    this.setState({ selectedImageGst: null })
                                  }
                                  className="close-img-btn"
                                >
                                  <i className="close-img-icon"></i>
                                </button>
                              ) : null}
                              <div className="select-edit-wrap">
                                {this.state.selectedImageGst && (
                                  <div className="select-img-upload">
                                    <img
                                      alt="not fount"
                                      width={"250px"}
                                      src={URL.createObjectURL(
                                        this.state.selectedImageGst
                                      )}
                                    />
                                  </div>
                                )}

                                {!this.state.selectedImageGst && (
                                  <div className="input-file-upload-wrap">
                                    <div className="input-upload">
                                      <div className="file-image">
                                        <img
                                          src="/assets/images/choose-img-icon.svg"
                                          alt="icon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <input
                                  type="file"
                                  name="myImage"
                                  className="file-upload-input"
                                  onChange={(event) => {
                                    this.gstImageHandler(event);
                                  }}
                                />
                              </div>
                              <div className="upload-label-wrap">
                                <label className="upload-label">
                                  GST Registration Certificate*
                                </label>
                              </div>

                              {this.state.gstImageError ? (
                                <span className="error">
                                  Please select adhaar card image
                                </span>
                              ) : null}
                            </div>
                            <div className="form-intput input-upload-form">
                              {this.state.selectedCINImage ? (
                                <button
                                  onClick={() =>
                                    this.setState({ selectedCINImage: null })
                                  }
                                  className="close-img-btn"
                                >
                                  <i className="close-img-icon"></i>
                                </button>
                              ) : null}
                              <div className="select-edit-wrap">
                                {this.state.selectedCINImage && (
                                  <div className="select-img-upload">
                                    <img
                                      alt="not fount"
                                      width={"250px"}
                                      src={URL.createObjectURL(
                                        this.state.selectedCINImage
                                      )}
                                    />
                                  </div>
                                )}

                                {!this.state.selectedCINImage && (
                                  <div className="input-file-upload-wrap">
                                    <div className="input-upload">
                                      <div className="file-image">
                                        <img
                                          src="/assets/images/choose-img-icon.svg"
                                          alt="icon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <input
                                  type="file"
                                  name="myImage"
                                  className="file-upload-input"
                                  onChange={(event) => {
                                    this.cinImageHandler(event);
                                  }}
                                />
                              </div>
                              <div className="upload-label-wrap">
                                <label className="upload-label">CIN</label>
                              </div>
                            </div>
                          </div>
                          <span className="img-warning">
                            *file should be in png, jpg or jpeg format and
                            maximum 2 mb in size
                          </span>
                        </div>
                        <></>
                      </div>

                      <div className="login-btn-wrapper">
                        <button className="shop-now">Submit</button>
                      </div>
                    </>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    joinWaitList: (payload, completion) =>
      dispatch(joinWaitList(payload, completion)),

    OTPVerify: (payload, completion) =>
      dispatch(OTPVerify(payload, completion)),
    OTPVerifyEmail: (payload, completion) =>
      dispatch(OTPVerifyEmail(payload, completion)),
    resendSignOTP: (payload, compilation) =>
      resendSignOTP(payload, compilation),
    resendEmailLink: (payload, compilation) =>
      resendEmailLink(payload, compilation),
    loginRequest: (payload, completion) =>
      dispatch(loginRequest(payload, completion)),
    socialLogin: (payload, completion) =>
      dispatch(socialLogin(payload, completion)),
    showSignupErr: (payload) => dispatch(showSignupErr(payload)),
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  showLoginPopup: state.generalInfo.showLoginPopup,
  status: state.userInfo.SignupError.status,
  errData: state.userInfo.SignupError.errData,
});
export default withRouter(
  withToast(connect(mapStateToProps, mapDispatchToProps)(NewPopup))
);

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}
