import React from "react";
import { Link, withRouter } from "react-router-dom";
import { getFooterSection } from "../store/actions/cms_data";
import { connect } from "react-redux";
import { isEmpty, get } from "lodash";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ""
    };
  }


  componentDidMount() {
    this.props.getFooterSection((status) => {
      this.setState({ data: status.content[0] })
    });
  }


  render() {
    const { userData } = this.props;

    return (
      <div dangerouslySetInnerHTML={{ __html: this.state.data }} >
      </div>
    );
  }
}



const mapStateToProps = (state, ownProps) => ({
  sections: state.getCMS_data.section.section_data
    ? state.getCMS_data.section.section_data
    : null,
  subSection: state.getCMS_data.section.subSection_data
    ? state.getCMS_data.section.subSection_data
    : null,
  address: state.getCMS_data.address,
  userData: state.userProfile.userProfileBanner,
  siteData: state.getCMS_data.siteData,

});

const mapDispatchToProps = dispatch => {
  return {
    getFooterSection: (payload) => dispatch(getFooterSection(payload)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
