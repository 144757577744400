import React, { useState, useEffect } from "react";
import { Authorization } from "../../utils/Authorization";
import { setLoginPopupStatus } from "../../store/actions/common";
import { connect } from "react-redux";
import swal from 'sweetalert';



const Question = (props) => {
  const [showquespopup, setShowquespopup] = useState(false);
  const [searchval, setSearchval] = useState("");
  const [current, setCurrent] = useState(4);

  const [qalist, setQalist] = useState([]);
  const [qandaQues, setQandaQues] = useState("");



  useEffect(() => {
    if (props.popupState) {
      setShowquespopup(true);
    }
  }, [props.popupState]);


  const quesHandler = (e) => {
    e.preventDefault();

    const checkLoginStatus = Authorization();
    const userId = localStorage.getItem("userId");
    if (checkLoginStatus.status) {
      if (qandaQues !== "") {
        let payload = {
          qna_question: qandaQues.toLowerCase(),
          userId: userId,
          productId: props.pid,
          sellerId: props.details.data[0].userData[0]._id,
        };
        setQandaQues("");
        props.postques(payload, (completion) => {

          if (completion) {
            completion.answers = [];
            let qalistData = [...qalist];

            qalistData.unshift(completion);
            setQalist(qalistData);
            setShowquespopup(false);
          } else {
            swal("Something wrong.");
          }
        });
      } else {
        swal("This field required");
      }
    } else {
      props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    }
  };



  return (
    <div>
      {" "}
      {showquespopup && (
        <div className="popup-wrap" id="post-ques-popup">
          <div className="popup-sec">
            <button
              className="close-btn"
              onClick={() => {
                setShowquespopup(false);
                props.setQuestionPopupState(false);
              }}
            >
              <i className="fal fa-times-circle"></i>
            </button>
            <div className="popup-block">
              <h3>Post Your Question</h3>
              <div className="field-wrap">
                <textarea
                  className="field-input"
                  placeholder="Type your question here..."
                  onChange={(e) => setQandaQues(e.target.value)}
                  value={qandaQues}
                  required
                ></textarea>
              </div>
              <button onClick={quesHandler} className="shop-now">
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
  };
};

export default connect(null, mapDispatchToProps)(Question);
