import React, { useEffect, useState } from "react";
import "./popup.css";
import { useToasts } from "react-toast-notifications";
import { useLocation } from "react-router-dom";
import { parse, stringify } from "query-string";
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css';
import Loader from "../components/Loader";
import { mapApi } from "../utils/commonFunctions";


const LocationEditPopup = (props) => {

  const location = useLocation();
  let query = parse(location.search);
  const { addToast } = useToasts();
  const [distance, setDistance] = React.useState(query.radius || 5);
  const [deliveryType, setDeliveryType] = React.useState({ value: 'Any', label: 'Any' });
  const [zip, setZip] = React.useState(query.category === "Local" && query.zip ? query.zip : "");
  const [latLang, setLatLang] = React.useState("");
  const [currentLocationAction, setCurrentLocationAction] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [exactLocation, setExactLocation] = useState("");




  const zipHandler = (e) => {
    let checkPrice = new RegExp(/^\d*$/);
    const value = e.target.value
    if (checkPrice.test(value)) {
      setZip(value)
    }
  }


  const submit = () => {
    if (zip === "") {
      addToast("Please Enter Pin code", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
      return false;
    }
    if (zip.length < 6) {
      addToast("Please Enter Valid Pin code", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
      return false;
    }

    let payload = {
      zip,
      shipping: deliveryType.value,
      category: "Local"
    }
    props.contShopping(stringify(payload))

  }


  const getCurrentLocation = () => {
    setShowLoader(true);
    setShowMessage("Please wait...")
    setCurrentLocationAction(true);
    props.useCurrentLocation(callback => {
      setTimeout(() => {
        setShowLoader(false);

        let payload = {
          lat: callback.latitude,
          long: callback.longitude,
          radius: distance
        }
        props.contShopping(stringify(payload));

        setLatitude(callback.latitude);
        setLongitude(callback.longitude);

        // addToast("Location fetched", {
        //   appearance: "success",
        //   autoDismissTimeout: 2000,
        // });
      }, 1500);

    })

  }



  const useCurrentLocationField = () => {
    setCurrentLocationAction(!currentLocationAction);
  }

  const usePinCodeField = () => {
    props.useCurrentLocation(callback => {
      fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + callback.latitude + ',' + callback.longitude + '&key=' + mapApi)
        .then((response) => response.json())
        .then((responseJson) => {
          var locationData = responseJson;
          setExactLocation(locationData.results[0].formatted_address);

        })


    })

    setCurrentLocationAction(!currentLocationAction);
  }

  return (
    <div className="make-offer-popup location-content-wrap">

      {showLoader ? <Loader message={showMessage} /> : null}
      <div className="offer-content local-location-wrapper">
        <button
          className="modal-closeBtn"
          onClick={() => props.closePopup(null)}
          type="button"
        >
          <i className="fal fa-times-circle"></i>
        </button>
        <div className="popup-body location local-location">
          <div className="loc-img location-img-wrap">
            <img src="/assets/images/img-01.png" alt="" />
          </div>
          {currentLocationAction ? <a onClick={useCurrentLocationField} className="useLocation uselocation-btn get-current-location">Change to pin code</a> : <a onClick={usePinCodeField} className="useLocation uselocation-btn">Change to current location</a>}
          {currentLocationAction ? <span className="exact-location-wrap">{exactLocation}</span> : null}

          {currentLocationAction ?
            <>
              <div className="distance distance-wrapper main-distance-wrapper">
                <h4>Distance</h4>
                <div className="distance-apply-wrapper">
                  <div className="distance-input-range">
                    <InputRange
                      disabled={currentLocationAction === false ? true : false}
                      minValue={1}
                      maxValue={25}
                      onChange={(e) => setDistance(e)}
                      value={distance}
                    />
                  </div>
                  <button className="apply-current-location" onClick={getCurrentLocation}
                    disabled={currentLocationAction === false ? true : false}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </> :
            <div className="zip distance-wrapper">
              <h4>Pin code </h4>
              <div className="distance-apply-wrapper">
                <div className="distance-input-range enter-pin-range">
                  <input
                    type="text"
                    className="zip-input"
                    placeholder="Enter pin code"
                    value={zip}
                    minLength={6}
                    maxLength={6}
                    onChange={zipHandler}
                    disabled={currentLocationAction}
                  />
                </div>
                <button className="apply-current-location" onClick={() => submit()}
                >
                  Apply
                </button>
              </div>
            </div>
          }



          {/* <button type="submit" className="cont-shopping continue-shopping location-continue-shop" onClick={() => submit()}>
            Continue shopping
          </button> */}
        </div>
      </div>
    </div >
  );
};

export default LocationEditPopup;

