import React, { useState } from "react";
import ListingProduct from "./ListingProduct";
import { SpinnerLoader } from "../../../../utils/constants";
import MyLiker from "./myLiker";
import { connect } from "react-redux";
import {
  getUserAddedProduct,
  getProductLiker,
  sendCouponCode,
  updateCouponCode,
  reducePrice,
} from "../../../../store/actions/profile";
import "../../Profile.css";
import {
  deleteProduct,
  changeProdStatus,
} from "../../../../store/actions/sellItem";
import Popup from "../../../SellAnItem/Popup";
import { useToasts } from "react-toast-notifications";
import { myListingRoutes } from "../profileRoutes";
import { Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import { get } from "lodash";
import swal from "sweetalert";
import Loader from "../../../Loader";
import { useSelector } from "react-redux";

const YourShop = (props) => {
  const [deleteProductType, setDeleteProductType] = React.useState("");
  const [deleteProductPopup, setDeleteProductPopup] = React.useState(false);
  const [deleteProductId, setDeleteProductId] = React.useState("");
  const [surePopup, setSurePopup] = React.useState(false);
  const [innerLoader, setInnerLoader] = React.useState(false);
  const location = useLocation();
  const { addToast } = useToasts();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const selector = useSelector((state) => state);

  React.useEffect(() => {
    setShowLoader(true);
    setShowMessage("Please wait...");
    setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    window.scrollTo(0, 0);
  }, [location.state]);

  const editProduct = (pid, type, categoryName) => {
    let baseUrl = "/sell-product";
    if (
      [
        "Defective Coil",
        "Straightening",
        "Coil",
      ].includes(categoryName)
    ) {
      baseUrl = "/wire" + baseUrl;
    }
    console.log('baseUrl ',baseUrl, categoryName);

    if (type === "draft") {
      props.history.push(`${baseUrl}/${pid}?type=draft`, { pid });
    } else if (type === "unApproved") {
      props.history.push(`${baseUrl}t/${pid}?type=edit`, { pid });
    } else {
      props.history.push(`${baseUrl}/${pid}?type=edit`, { pid });
    }
  };

  const deleteProdPopup = (productId, type) => {
    deleteProdFun(productId, type);
  };

  const changeProdStatusPopup = (data, type) => {
    setDeleteProductType(type);
    setSurePopup(!surePopup);
    setDeleteProductId(data);
  };

  const deleteProdFun = (productId, type) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      className: "delete-swal",
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteProduct(
          productId,
          { type: type, action: "delete" },
          (status) => {
            if (status) {
              setDeleteProductId("");
              addToast("Deleted", {
                appearance: "success",
                autoDismissTimeout: 2500,
              });
            } else {
            }
          }
        );
      }
    });
  };

  const changeProdStatus = () => {
    props.changeProdStatus(deleteProductId, deleteProductType, (status) => {
      if (status) {
        setSurePopup(!surePopup);
        setDeleteProductId("");
        setDeleteProductType("");
        addToast("Status changed", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
      }
    });
  };

  const resetPrice = (e, storeP, descountP, status) => {
    e.preventDefault();
    const prodId = get(
      props.likerData,
      ["data", [0], "productData", [0], "_id"],
      ""
    );
    const params = {
      productId: prodId,
      currentStorePrice: storeP,
      sellPrice: descountP,
    };
    reducePrice(params, status); // api call
  };

  const tabRender = () => (
    <Switch>
      <Route
        path="/profile/my-listing/active/likes"
        render={(prev) => (
          <MyLiker
            likerData={props.likerData}
            sendCouponCode={props.sendCouponCode}
            updateCouponCode={props.updateCouponCode}
            openLikerPage={openLikerPage}
            resetPrice={resetPrice}
            {...prev}
          />
        )}
      />
      <Route
        path="/profile/my-listing/active"
        render={(prev) => (
          <ListingProduct
            myProduct={props.myProduct.active}
            editProduct={editProduct}
            deleteProduct={(e) => deleteProdPopup(e, "active")}
            addToInactive={(e) => changeProdStatusPopup(e, "active")}
            type="active"
            userId={props.userId}
            loadMore={props.getUserAddedProduct}
            openLikerPage={openLikerPage}
            {...prev}
          />
        )}
      />
      <Route
        path="/profile/my-listing/inactive"
        render={(prev) => (
          <ListingProduct
            myProduct={props.myProduct.inactive}
            editProduct={editProduct}
            deleteProduct={(e) => deleteProdPopup(e, "inactive")}
            addToActive={(e) => changeProdStatusPopup(e, "inactive")}
            type="inactive"
            userId={props.userId}
            loadMore={props.getUserAddedProduct}
            {...prev}
            // openLikerPage={openLikerPage}
          />
        )}
      />
      <Route
        path="/profile/my-listing/draft"
        render={(prev) => (
          <ListingProduct
            myProduct={props.myProduct.draft}
            editProduct={editProduct}
            deleteProduct={(e) => deleteProdPopup(e, "draft")}
            type="draft"
            userId={props.userId}
            loadMore={props.getUserAddedProduct}
            {...prev}
          />
        )}
      />
      <Route
        path="/profile/my-listing/unApproved"
        render={(prev) => (
          <ListingProduct
            myProduct={props.myProduct.unApproved}
            editProduct={editProduct}
            deleteProduct={(e) => deleteProdPopup(e, "unApproved")}
            type="unApproved"
            userId={props.userId}
            loadMore={props.getUserAddedProduct}
            {...prev}
          />
        )}
      />

      <Route
        path="/profile/my-listing/rejected"
        render={(prev) => (
          <ListingProduct
            myProduct={props.myProduct.rejected}
            editProduct={editProduct}
            deleteProduct={(e) => deleteProdPopup(e, "rejected")}
            type="rejected"
            userId={props.userId}
            loadMore={props.getUserAddedProduct}
            {...prev}
          />
        )}
      />
      <Redirect from="/profile/my-listing" to="/profile/my-listing/active" />
    </Switch>
  );

  const openLikerPage = (prodId) => {
    props.getProductLiker({
      productId: prodId,
    });
  };

  return (
    <div style={{ position: "relative" }}>
      {showLoader ? <Loader message={showMessage} /> : null}
      <div
        className={"blogtags-sec" + (innerLoader ? " spinner-time-div" : "")}
      >
        {!get(location, ["state", "p_id"], false) && (
          <div className="tags-wrap">
            <div className="container">
              <div className="tags 34">
                {myListingRoutes.map((data, indx) => (
                  <Link
                    key={indx}
                    to={{
                      pathname: data.prefix + data.pathname,
                      state: data.state,
                    }}
                    className={
                      data.pathname ===
                      location.pathname.replace("/profile/my-listing/", "/")
                        ? "active-tab"
                        : ""
                    }
                  >
                    {data.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="container">{tabRender()}</div>

        {deleteProductPopup && (
          <Popup
            closePopup={deleteProdPopup}
            submitFun={deleteProdFun}
            content={"Do you really want to permanently delete this Product  "}
          />
        )}
        {surePopup && (
          <Popup
            closePopup={changeProdStatusPopup}
            submitFun={changeProdStatus}
            content={"Are you sure ? "}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.userInfo.loggedUserInfo.userId,
    myProduct: state.userProfile.userAddedProduct,
    likerData: state.userProfile.likerData,
    product: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserAddedProduct: (payload) => dispatch(getUserAddedProduct(payload)),
    getProductLiker: (payload) => dispatch(getProductLiker(payload)),
    sendCouponCode: (payload, callback) => sendCouponCode(payload, callback),
    updateCouponCode: (payload, callback) =>
      updateCouponCode(payload, callback),
    deleteProduct: (payload, type, status) =>
      dispatch(deleteProduct(payload, type, status)),
    changeProdStatus: (payload, type, status) =>
      dispatch(changeProdStatus(payload, type, status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(YourShop);
// export default YourShop;
