import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css';
import { Link } from "react-router-dom";

const BuyerSeller = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
		  live: true, // Enables the live mode to detect changes dynamically
		});
		wow.init();
	  }, []);

  return (
	<div className='buyer-seller'>
		<div className="container">
			<div className="buyer-seller-wrap">
				<Link to='/buyer' className="buyer-show wow animate__animated animate__fadeInUp ">
					<img src="../../../assets/images/sellerbuyer-img.png" alt="buyerseller-img" />
					<strong>BUYERS</strong>
				</Link>
				<Link to='/seller' className="seller-show buyer-show wow animate__animated animate__fadeInUp " data-wow-delay="0.1s">
					<img src="../../../assets/images/buyerseller-img.png" alt="sellerbuyer-img" />
					<strong>SELLERS</strong>
				</Link>
			</div>
		</div>
	</div>
  )
}

export default BuyerSeller