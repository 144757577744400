import React, { Component } from "react";
import { connect } from "react-redux";
// import NavigationButton from "./NavigationButtons";
// tested
import { Error, customStyles, PriceError } from "./index11";
import { RupeesIcon, priceWithSymbol } from "../../utils/constants";
import Select from "react-select";

import {
  setUserSelectedAttr,
  setShippingInfo,
  updateProduct,
  draftAnItem,
  // getEstimateCost,
} from "./../../store/actions/sellItem";

import Popup from "./Popup";
import { setLoginPopupStatus } from "../../store/actions/common";
import { sellAnItem } from "../../store/actions/sellItem";
import Toast from "light-toast";
import { Authorization } from "./../../utils/Authorization";
import { useToasts } from "react-toast-notifications";
import NavigationButton from "./NavigationButtons";
import { parse } from "query-string";
import { isEmpty, isEqual, get } from "lodash";
import AddressSearchBox from './AddressSearch';



const DeliveryOption = [
  { value: 'Any', label: 'Any' },
  { value: 'Nationwide', label: 'Nationwide' },
  { value: 'Local', label: 'Local' }
];

const initialState = {
  getAttributeList: [], //props.attributes || [],
  // EDITMODE: props.selectedAttr.EDITMODE || false,
  required_attr: {},
  showCalendar: false,
  // productSubmitted: false,
};

const SuggestPriceError = ({ min, max }) => {
  return (
    <span className="error-sell-text" style={{ display: "block !important" }}>
      <span><sup>**&nbsp;</sup>Please enter price between {min} to {max}</span>
    </span>
  );
};

class Attributes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      productSubmitted: false,
      is_default: "",
      attributClass: "attr-card-button",
      isShipping: false,
      msg: '',
      title: ''

    }
  }

  // componentDidMount(){


  // }

  static getDerivedStateFromProps = (props, state) => {
    let query = parse(props.searchParam);
    // var isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));
    if (isEqual(props.attributes, state.getAttributeList)) return state;
    const attributes = get(props, ['attributes'], []);
    // attributes == 'LIST OF ALL ATTRUBUTE FROM SERVER '
    if (attributes.length !== 0) {
      let obj = {}; // OBJ IS ALL USER SELECTED ATTRIBUTE
      let required_attr = {};
      attributes.map((item) => {
        const options = item.default_value.map((props) => ({
          value: props.store_view[0].name,
          label: props.store_view[0].name,
          active: false,
        }));
        // const defaultValue =
        //   item.is_default.length !== 0
        //     ? item.is_default.map((index) => options[index])
        //     : [];
        if (query.mode === "edit" && query.pid) {//state.EDITMODE
          // if edit mode .
          if (item.catlog_input_type === "text") {
            const nameIndex = props.name.indexOf(item.attribute_label[0]);
            obj[item.attribute_label[0]] = props.value[nameIndex];
          } else if (item.catlog_input_type === "select") {
            const nameIndex = props.name.indexOf(item.attribute_label[0]);
            obj[item.attribute_label[0]] = {
              label: props.value[nameIndex],
              value: props.value[nameIndex],
            };
          } else if (item.catlog_input_type === "multiselect") {
            let array = [];
            props.name.filter((data, index) => {
              if (data === item.attribute_label[0]) {
                array.push({
                  label: props.value[index],
                  value: props.value[index],
                });
              }
            });
            obj[item.attribute_label[0]] = array;
          }
        } else {
          // if user add fresh prouct,not in edit mode
          obj[item.attribute_label[0]] = props.selectedAttr[
            item.attribute_label[0]
          ]
            ? props.selectedAttr[item.attribute_label[0]]
            : // : defaultValue.length !== 0
            // ? defaultValue
            "";
        }
        required_attr[item.attribute_label[0]] = item.required;
      });
      return {
        ...state,
        getAttributeList: attributes,
        ...obj,
        required_attr: required_attr,
      };
    }
    else return {
      ...initialState, //reset state if there is no attribute
    }
  };

  uploadData = () => {
    this.setState({ msg: "Congratulation, your product is successfully Updated" })
    this.setState({ title: "Go to Home page" })
    // if(this.props.shippingCost === undefined){

    //   //this.props.shippingCost=0;       
    // }

    let query = parse(this.props.searchParam);
    const { selectedCategory, selectedSubCategory, selectedChildSubCategory, selectedBrand,
      title, quantity, files, description, selectedCondition, shippingCost, isShippingFree } = this.props;
    const { storePrice, priceWillReceive, latLong, smartPrice, suggestedSmartPrice, smartPriceValue } = this.props;
    const { product_zip, product_tag, fileValues, deliveryType, hsn, selected_gst } = this.props;

    // load the atributes in attr_name and attr_value###############
    const { required_attr } = this.state;

    let attr_req_validate = true; // verify that required attributs have value?
    Object.keys(required_attr).map((key) => {
      if (required_attr[key]) {
        if (this.state[key]) {
          attr_req_validate *= true;
        } else {
          attr_req_validate *= false;
        }
      }
    });

    const check = (selectedCategory && selectedSubCategory && selectedBrand && title && quantity && files[0] && description && selectedCondition && hsn && selected_gst)

    if (attr_req_validate && product_zip && latLong && storePrice && check) {
      if (priceWillReceive && (parseFloat(storePrice) <= parseFloat(priceWillReceive))) {
        return this.props.enterText(true, "showErr");
      }
      else if (smartPrice && smartPriceValidate(suggestedSmartPrice, smartPriceValue ? parseInt(smartPriceValue) : 0)) {
        return this.props.enterText(true, "showErr");
      }
      else {
        let payload = new FormData();

        let Attrs = []; //[{name:'size',value:"30"}]
        const keys = Object.keys(required_attr); //get all dynamic attribute name
        keys.map((data) => {
          //#### load all attributes in Attrs variablel ###########
          if (
            typeof this.state[data] === "string" &&
            this.state[data].length &&
            this.state[data].length !== 0
          ) {
            //## for string
            Attrs.push({ name: data, value: this.state[data] });
          } else if (this.state[data] && this.state[data].length > 0) {
            //## for multi-select ####
            Object.keys(this.state[data]).map((key) => {
              Attrs.push({ name: data, value: this.state[data][key].label });
            });
          } else if (
            this.state[data] !== null &&
            this.state[data] !== undefined &&
            Object.keys(this.state[data]).length !== 0
          ) {
            Attrs.push({ name: data, value: this.state[data].label, att_id: this.state[data].att_id }); //## for single select
          }
        });

        const dataObject = {
          userId: localStorage.getItem("userId"),
          title: title,
          product_quantity: quantity,
          shippingCost: shippingCost
            ? shippingCost
            : 0,
          isShippingFree: isShippingFree === true ? isShippingFree : false,
          category: selectedCategory.value._id,
          subcategory: selectedSubCategory
            ? selectedSubCategory.value._id
            : "",
          subchildcategory: selectedChildSubCategory
            ? selectedChildSubCategory.value._id
            : "",

          brand: selectedBrand.value._id,
          // brand: selectedBrand.map(data => data.value._id), // multi select

          condition: selectedCondition.head,
          product_description: description,
          product_tag: product_tag,

          is_auction: smartPrice === true ? "Yes" : "No",
          recommendPrice: smartPrice ? parseInt(smartPriceValue) : "",
          current_store_price: storePrice,
          sell_price: priceWillReceive,
          zip_code: product_zip,
          default_image_name: fileValues[0], //uploadImageData.defaultImg,  // need to fix this default image in case of edit mode
          delievery_type: deliveryType.value,
          lat: latLong.lat,
          long: latLong.long,
          hsn_code: hsn,
          gst: selected_gst.value._id
        };

        // ########## FOR LOAD THE ABOVE LISTED OBJECT INTO FORMDATA ######
        Object.keys(dataObject).forEach((key) =>
          payload.append(key, dataObject[key])
        );
        //#################  END  #########################################

        //########### FOR LOAD IMG INTO FORM DATA ##########################
        for (var i = 0; i < fileValues.length; i++) {
          if (fileValues[i] !== 0) {
            payload.append("product_image", fileValues[i]);
          }
        }

        //#####################################
        payload.append("Attrs", JSON.stringify(Attrs));

        //####################################
        //################## END ING LOAD  ################################
        const loginInfo = Authorization(); //verify userlogin before submitting product

        if (loginInfo.status) {
          Toast.loading("In Progress...", 2500);
          if (query.mode === "edit" && query.pid) {
            //#### if edit product  ##########
            const productId = this.props.productId;
            this.props.updateProduct(payload, productId, (status) => {
              if (status) {
                Toast.success(
                  "Product Submit Successfully",
                  1000,
                  // () => (window.location.href = "/")
                );
              } else {
                Toast.fail("Failed to submit,please review all fields", 3000);
              }
            });
          } else {
            // ####### if save new product #######
            // for (var pair of payload.entries()) {

            // }
            this.props.sellAnItem(payload, (status) => {
              if (status) {

                Toast.hide();
                this.props.addToast("Success", { appearance: "success" });
                this.setState({ productSubmitted: true });
              } else {
                Toast.hide();
                this.props.addToast("Failed to submit", { appearance: "warning" });
              }
            });
          }
        } else {
          Toast.fail("Please login first", 1500, () =>
            this.props.setLoginPopupStatus({
              status: true,
              screenToShow: "login",
            })
          );
        }
      }
    } else {
      this.props.addToast("Please fill all the required fields", { appearance: "warning" });
      Toast.fail("Failed to submit", 1000, () =>
        this.props.enterText(true, "showErr")
      );
    }
  };

  draftProduct = () => {

    this.setState({ msg: "Congratulation, your product is successfully Drafted" })
    this.setState({ title: "Go to Draft page" })
    let query = parse(this.props.searchParam);
    let payload = new FormData();

    let Attrs = []; //[{name:'size',value:"30"}]
    const keys = Object.keys(this.state.required_attr); //get all dynamic attribute name
    keys.map((data) => {
      //#### load all attributes in Attrs variablel ###########
      if (
        typeof this.state[data] === "string" &&
        this.state[data].length &&
        this.state[data].length !== 0
      ) {
        //## for string
        Attrs.push({ name: data, value: this.state[data] });
      } else if (this.state[data] && this.state[data].length > 0) {
        //## for multi-select ####
        Object.keys(this.state[data]).map((key) => {
          Attrs.push({ name: data, value: this.state[data][key].label });
        });
      } else if (
        this.state[data] !== null &&
        this.state[data] !== undefined &&
        Object.keys(this.state[data]).length !== 0
      ) {
        Attrs.push({ name: data, value: this.state[data].label }); //## for single select
      }
    });

    const dataObject = {
      userId: localStorage.getItem("userId"),

      title: this.props.title,
      product_quantity: this.props.quantity,
      category: this.props.selectedCategory ? this.props.selectedCategory.value._id : "",
      subcategory: this.props.selectedSubCategory
        ? this.props.selectedSubCategory.value._id
        : "",
      subchildcategory: this.props.selectedChildSubCategory
        ? this.props.selectedChildSubCategory.value._id
        : "",
      // brand: this.props.selectedBrand ? this.props.selectedBrand.map(data => data.value._id) : "",
      brand: this.props.selectedBrand ? this.props.selectedBrand.value._id : "",
      condition: this.props.selectedCondition.head ? this.props.selectedCondition.head : "",
      product_description: this.props.description,
      product_tag: this.props.product_tag,
      is_auction: this.props.smartPrice === true ? "Yes" : "No",
      recommendPrice: this.props.smartPrice ? parseInt(this.props.smartPriceValue) : "",

      current_store_price: this.props.storePrice,
      sell_price: this.props.priceWillReceive,
      zip_code: this.props.product_zip,
      default_image_name: this.props.fileValues[0] ? this.props.fileValues[0] : "", //uploadImageData.defaultImg,  // need to fix this default image in case of edit mode
      delievery_type: this.props.deliveryType.value,
      lat: this.props.latLong.lat ? this.props.latLong.lat : "",
      long: this.props.latLong.long ? this.props.latLong.long : "",
      hsn_code: this.props.hsn,
      // gst: this.props.selected_gst.value._id
    };
    Object.keys(dataObject).forEach((key) =>
      payload.append(key, dataObject[key])
    );

    //########### FOR LOAD IMG INTO FORMDATA ##########################
    if (this.props.fileValues.length > 0) {
      for (var i = 0; i < this.props.fileValues.length; i++) {
        if (this.props.fileValues[i] !== 0) {
          payload.append("product_image", this.props.fileValues[i]);
        }
      }
    }
    //########### END  LOAD IMG INTO FORMDATA #####################
    payload.append("Attrs", JSON.stringify(Attrs));

    // for (var pair of payload.entries()) {

    // }
    const loginInfo = Authorization(); //verify userlogin before submitting product
    if (loginInfo.status) {
      Toast.loading("Product Uploading...");
      if (query.mode === "draft" && query.pid) {
        //#### if edit draft product  ##########
        payload.append("draftId", this.props.productId);
        this.props.draftAnItem(payload, (status) => {
          if (status.status === "Success") {
            Toast.success(
              "Product Saved Successfully",
              1000,
            );
          } else {
            Toast.fail("Failed to save", 1000);
          }
        });
      } else {
        // ####### if draft fresh product #######
        this.props.draftAnItem(payload, (status) => {
          if (status.status === "Success") {
            Toast.hide();
            this.setState({ productSubmitted: true });
          } else {
            Toast.fail("Failed to save", 1000);
          }
        });
      }
    } else {
      Toast.fail("Please login first", 1500, () =>
        this.props.setLoginPopupStatus({
          status: true,
          screenToShow: "login",
        })
      );
    }

  }

  selectAttributeWithDropdown = (attrName, attrval) => {


    this.setState({ [attrName]: attrval });
  };

  selectAttributeWithText = (e, attribute) => {

    this.setState({ [attribute]: e.target.value });
  };

  renderSelectBox = (data, state, onChangeHandler, showErr, index) => {


    const options = data.default_value.map((props) => ({
      value: props.store_view[0].name,
      label: props.store_view[0].name,
      att_id: data._id,
      isDefault: props.is_default,
      colorCode: props.colorcode[0].color
    }));
    //   const defaultValue = (data.is_default.length !==0 && data.is_default.map(index=> options[index])) || []
    return (
      <>

        <label className="label-txt">
          {data.attribute_label[0].split("-").join(" ")} :-
          <span className="sell-popup-opener" onClick={() => this.props.openAttributePopup(true, data.attribute_label)}>
            Can't See Your Option?
          </span>
          {data.required &&
            showErr &&
            (state[data.attribute_label[0]] === "" ||
              state[data.attribute_label[0]] === null) && <Error />}

        </label>

        <div className="slct-optns" key={index}>

          {options.map((item, j) => (
            <div className="attr-optns" key={j}>
              {/* <label className="label-txt" for={data.attribute_label[0] + j}>

                <div
                  className={
                    "attr-card-button " +
                    (item.isDefault === this.state.is_default
                      ? "selected"
                      : "")
                  }
                >
                  <span className="desc">{item.label}</span>
                </div>
              </label> */}

              <label className="label-txt" for={data.attribute_label[0] + j}>
                <div
                  className={
                    "attr-card-button " +
                    (state[data.attribute_label[0]].value === item.value
                      ? "selected"
                      : "")
                  }
                >
                  <span className="desc">{item.label}</span>
                </div>
              </label>
              <input
                id={data.attribute_label[0] + j}
                type="radio"
                checked={
                  state[data.attribute_label[0]].value === item.value
                    ? true
                    : false
                }
                onChange={() => onChangeHandler(data.attribute_label[0], item)}
                style={{ display: "none" }}
              />
            </div>
          ))}
        </div>
      </>
    );
  };

  renderInputBox = (data, state, onChangeHandler, showErr) => {
    return (
      <React.Fragment>
        <input
          className="input-url"
          type="text"
          placeholder={data.attribute_label[0]}
          value={state[data.attribute_label[0]]}
          onChange={(e, v) => onChangeHandler(e, data.attribute_label[0])}
        />
        <br />
        {data.required &&
          showErr &&
          state[data.attribute_label[0]] === "" && <Error />}
      </React.Fragment>
    );
  };

  componentDidUpdate(prev) {


    const { selectedCategory, priceWillReceive } = this.props
    const earning = document.getElementById("earning"); //.children[1].innerHTML;
    const html = earning ? earning.children[1].innerHTML : null;
    if (html && html.includes("-")) {
      earning.classList.add("red");
    } else if (html) {
      earning.classList.remove("red");
    }


    ////////////// For Bydefault Selection ......

    var resultPrev = prev.attributes.filter(word => word.catlog_input_type == "select");
    if (resultPrev.length > 0) {
      var IsDefaultPrev = resultPrev[0].is_default[0];
    }


    if (this.props.attributes.length > 0) {

      var result = this.props.attributes.filter(word => word.catlog_input_type == "select");

      if (result.length > 0) {
        var IsDefault = result[0].is_default[0];

        if (IsDefaultPrev != IsDefault) {

          this.setState({ is_default: parseInt(IsDefault) })
        }
      }



    }
  }

  checkSelectedShipping = (e) => {
    this.props.enterText(e.target.checked, "isShippingFree")
    this.setState({ isShipping: e.target.checked })
  };

  onlyDigit = (e, key) => {

    let value = e.target.value;
    if (key === "product_zip") {
      let checkPrice = new RegExp(/^\d*$/);
      if (checkPrice.test(value)) {
        this.props.enterText(value, key);
      }
    }
    else {
      let checkPrice = new RegExp(/^\d*\.?\d*$/);
      if (checkPrice.test(value)) {
        this.props.enterText(value, key);
      }
    }

  };



  render() {
    //  if(this.props.attributes.length > 0){

    //       var result = this.props.attributes.filter(word => word.catlog_input_type == "select");

    //       var IsDefault = result[0].is_default[0];

    //     //  for(var x = 0; x < result[0].default_value; x++ ){
    //     //        if(result[0].default_value[x].store_view[0])
    //     //    }

    //  } 
    const { productSubmitted } = this.state;
    const {
      showErr,
      product_zip,
      storePrice,
      priceWillReceive,
      smartPrice,
      smartPriceValue,
      suggestedSmartPrice,
      otherPrice,
      quantity
    } = this.props;
    return (
      <div className="attr">
        {productSubmitted ? (
          <Popup

            successTxt={this.state.title}
            content={this.state.msg}
            class="sebmit-prod"
          />
        ) : null}

        <br />
        {this.state.getAttributeList.length !== 0 &&
          <div className="attr-elements">
            <label className="label-txt">Select Attributes </label>
            {this.state.getAttributeList.map((data, index) =>
              data.catlog_input_type === "text" ? (
                <div className="slct-optns" key={index}>
                  <div className="slt-url">
                    {this.renderInputBox(
                      data,
                      this.state,
                      (e, v) => this.selectAttributeWithText(e, v),
                      showErr,
                      index
                    )}
                  </div>
                </div>
              ) : (
                <div className="select-attr">
                  {this.renderSelectBox(
                    data,
                    this.state,
                    (attrName, attrVal) =>
                      this.selectAttributeWithDropdown(attrName, attrVal),
                    showErr,
                    index
                  )}
                </div>
              )
            )}
          </div>
        }

        <div>
          {/* <h5>Shipping</h5> */}
          <div className="shipping-details">
            <div className="slct-optns">
              <div className="optns">
                <label className="label-txt">Shipping From <span className="highlighted">*</span></label>
                <input
                  type="text"
                  placeholder={"Enter pin code"}
                  name="product_zip"
                  value={product_zip}
                  maxLength={6}
                  onChange={(e) => this.onlyDigit(e, "product_zip")}
                  className={
                    showErr && product_zip === ""
                      ? "input-url error-sell-input"
                      : "input-url"
                  }
                />
                {showErr && product_zip === "" && <Error />}
              </div>
              <div className="optns">
                <div className="label-txt">
                  Select Delivery Type
                </div>
                <Select
                  options={DeliveryOption}
                  placeholder="Delivery Type"
                  value={this.props.deliveryType}
                  onChange={(e) => this.props.enterText(e, "deliveryType")}
                  classNamePrefix={"select-opt"}
                />
              </div>

              <div className="optns">
                <div className="cond">
                  <input
                    type="checkbox"
                    onChange={this.checkSelectedShipping}
                  />{" "}
                  Is shipping free or not ?{" "}
                </div>
                <br />
                {showErr && quantity === "" && <Error />}
              </div>


              {this.state.isShipping == false ?
                <div className="optns">
                  <div className="label-txt">Enter Shipping Cost </div>
                  <input
                    // className={
                    //   showErr && quantity === ""
                    //     ? "input-url error-sell-input"
                    //     : "input-url"
                    // }
                    className="input-url"
                    placeholder="Shipping Cost"
                    // value={shippingCost}
                    onChange={(e) => this.props.enterText(e.target.value, "shippingCost")}
                    maxLength={3}
                  />
                  <br />
                  {showErr && quantity === "" && <Error />}
                </div>
                :
                null
              }





            </div>

          </div>
        </div>
        {/* ############################### */}

        <div className="slct-optns price-row">
          <div className="optns">
            <div>
              <label className="label-txt">Price <span className="highlighted">*</span></label>
              <div className="price-input-wrap">
                <div className="rupees-icon">{RupeesIcon}</div>
                <input
                  className={
                    showErr && storePrice === ""
                      ? "input-url error-sell-input"
                      : "input-url"
                  }
                  type="text"
                  // placeholder={RupeesIcon}
                  value={storePrice}
                  onChange={(e) => this.onlyDigit(e, "storePrice")}
                  maxLength={7}
                />
              </div>
              {showErr && storePrice === "" && <Error />}
            </div>

            {!isEmpty(otherPrice) && otherPrice.productPrice ? (
              <>
                <br />
                <div className="price-brief">
                  <span className="row">
                    <p>Product Price</p>
                    <p>{priceWithSymbol(storePrice)}</p>
                  </span>
                  {
                    priceWillReceive &&
                    <>
                      <span className="row">
                        <p>Selling Price</p>
                        <p>{priceWithSymbol(priceWillReceive)}</p>
                      </span>
                      <span className="row">
                        <p>Discount Amount</p>
                        <p>
                          {priceWithSymbol(storePrice - priceWillReceive)}
                        </p>
                      </span>
                      <span className="row">
                        <p>Discount percentage</p>
                        <p>
                          {Number.isInteger(
                            ((storePrice - priceWillReceive) / storePrice) *
                            100
                          )
                            ? ((storePrice - priceWillReceive) / storePrice) *
                            100
                            : (
                              ((storePrice - priceWillReceive) /
                                storePrice) *
                              100
                            ).toFixed(2)}
                          {" %"}
                        </p>
                      </span>
                    </>
                  }
                  <span className="row">
                    <p>Shipping Charge</p>
                    <p>{priceWithSymbol(-otherPrice.shippingCost)}</p>
                  </span>
                  <span className="row ">
                    <p>Commission Cost</p>
                    <p>{priceWithSymbol(-otherPrice.commissionCost)}</p>
                  </span>
                  <span className="row ">
                    <p>Payment Gateway Fee</p>
                    <p>{priceWithSymbol(-otherPrice.paymentGatewayCost)}</p>
                  </span>
                  <span className="row " id="earning">
                    <p>You Earn</p>
                    <p>{priceWithSymbol(otherPrice.receivedAmount)}</p>
                  </span>
                </div>
                <div className="smart-price">
                  <span>

                    {/* <p>Smart Pricing</p> */}
                    {/* Already Commented -- Start */}
                    {/* <div className="smart-para">Set it and forget it. Increase your chances of a sale.</div> */}
                    {/* Already Commented -- End */}
                    {/* <div className="btns-status">
                      <button
                        className={"ship-btn " + (smartPrice === true ? "active" : "inactive")}
                        onClick={() => this.props.enterText(!smartPrice, "smartPrice")}
                      >
                        {smartPrice ? "On" : "Off"}
                      </button>
                    </div> */}
                    {
                      smartPrice &&
                      <div className="rec-input">
                        {/* Already Commented -- Start */}
                        {/* <h6>Set a floor price</h6> */}
                        {/* <div className="floor-price">We’ll gradually adjust your price based on demand. But no lower than your floor. Hands free selling.</div> */}
                        {/* Already Commented -- End */}
                        <input
                          className="smart-input"
                          type="text"
                          value={smartPriceValue}
                          onChange={(e) => {
                            this.onlyDigit(e, "smartPriceValue")
                          }}
                        />
                        {/* Already Commented -- Start */}
                        {/* <SuggestPriceError
                            min={suggestedSmartPrice - (suggestedSmartPrice * 50 / 100)}
                            max={suggestedSmartPrice + (suggestedSmartPrice * 50 / 100)}
                          /> */}
                        {/* Already Commented -- End */}
                        {
                          smartPriceValidate(suggestedSmartPrice, smartPriceValue ? parseInt(smartPriceValue) : 0) &&
                          // <div>Please enter price between {suggestedSmartPrice - (suggestedSmartPrice * 50 / 100)} to {suggestedSmartPrice + (suggestedSmartPrice * 50 / 100)}</div>
                          <SuggestPriceError
                            min={suggestedSmartPrice - (suggestedSmartPrice * 50 / 100)}
                            max={suggestedSmartPrice + (suggestedSmartPrice * 50 / 100)}
                          />
                        }
                        <div className="recommended-value">
                          Recommended:&nbsp;
                          {priceWithSymbol(suggestedSmartPrice)}</div>
                        {/* Already Commented -- Start */}
                        {/* <p>Selling fee and shipping fee will be deducted from this floor price when item is sold.</p> */}
                        {/* Already Commented -- End */}
                      </div>
                    }
                  </span>
                </div>
              </>
            )
              : null}
          </div>
          <div className="optns">
            <div>
              <label className="label-txt">Selling Price</label>
              <div className="price-input-wrap">
                <div className="rupees-icon">{RupeesIcon}</div>
                <input
                  // className={
                  //   showErr && priceWillReceive === ""
                  //     ? "input-url error-sell-input"
                  //     : "input-url"
                  // }
                  className="input-url"
                  type="text"
                  // placeholder={RupeesIcon}
                  value={priceWillReceive}
                  maxLength={7}
                  onChange={(e) => {
                    if (!storePrice) {
                      this.props.addToast("Please enter full price first", { appearance: "info" });
                    }
                    else {
                      this.onlyDigit(e, "priceWillReceive")
                    }
                  }}
                />
              </div>
              <br />
              {
                // (showErr && priceWillReceive === "" && <Error />) ||
                parseFloat(priceWillReceive) &&
                  parseFloat(storePrice) <=
                  parseFloat(priceWillReceive) ? <PriceError /> : null
              }
            </div>
          </div>
        </div>
        <div>
          <AddressSearchBox
            enterText={this.props.enterText} showErr={showErr} latLong={this.props.latLong} />
        </div>

        <NavigationButton {...this.props}
          submitFun={this.uploadData}
          draftItem={this.draftProduct}
          query={parse(this.props.searchParam)}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    selectedAttr: state.sellItemInfo.attributes,
    attributes: state.sellItemInfo.attributeList,
    name: state.sellItemInfo.attributes.name, // for edit mode
    value: state.sellItemInfo.attributes.value, // for edit mode

    itemShippingInfo: state.sellItemInfo.itemShippingInfo,
    allDetails: state.sellItemInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserSelectedAttr: (itemAttributes) =>
      dispatch(setUserSelectedAttr(itemAttributes)),
    setShippingInfo: (shippingDetail) =>
      dispatch(setShippingInfo(shippingDetail)),
    sellAnItem: (aboutProductData, status) =>
      dispatch(sellAnItem(aboutProductData, status)),
    draftAnItem: (aboutProductData, status) =>
      draftAnItem(aboutProductData, status),
    updateProduct: (payload, prodId, status) =>
      dispatch(updateProduct(payload, prodId, status)),
    // getEstimateCost: (payload, status) => getEstimateCost(payload, status),
  };
};

export default withToast(connect(mapStateToProps, mapDispatchToProps)(Attributes));
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

const smartPriceValidate = (sugges, val) => {
  const min = sugges - (sugges * 50 / 100)
  const max = sugges + (sugges * 50 / 100)
  return !((min <= val) && (val <= max))
}
