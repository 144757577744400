import {
  SET_HEADER_COUNTS,
  SET_HEADER_NOTIFICATION,
  INCREASE_WISHLIST_COUNT,
  DECREASE_WISHLIST_COUNT
} from "../actionsTypes";

const initialState = {};

const generalInfo = (state = initialState, action) => {

  switch (action.type) {
    case SET_HEADER_COUNTS:
      return { ...state, ...action.payload };
    case SET_HEADER_NOTIFICATION:

      return { ...state, totalNotificationCount: (action.payload.total_notification_data > 0 ? action.payload.total_notification_data : null) };
    case INCREASE_WISHLIST_COUNT:
      return { ...state, totalItemInWishlist: state.totalItemInWishlist > 0 ? state.totalItemInWishlist + 1 : null };
    case DECREASE_WISHLIST_COUNT:
      return { ...state, totalItemInWishlist: state.totalItemInWishlist > 0 ? state.totalItemInWishlist - 1 : null };
    default:
      return state;
  }
};
export default generalInfo;
