import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import Toast from "light-toast";
import OTPFormMobileLogin from "./OtpFormMobileLogin";
import axios from "axios";
import { API_URL, API_PREFIX } from "../../config2";

export default function LoginUsingOtp() {
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobileWithCountryCode, setMobileWithCountryCode] = useState("");
  const [OTP, setOTP] = useState("");
  const [time, setTime] = useState(60);
  const [otpSent, setOtpSent] = useState(false);

  const updateState = (key, value) => {
    if (key === "OTP") setOTP(value);
    else if (key === "time") setTime(value);
  };

  const sendOtp = async () => {
    const url = `${API_URL}${API_PREFIX}auth/send-whatsapp-otp`;
    try {
      const response = await axios.post(url, { mobile: mobileWithCountryCode });
      Toast.info(response.data.message || "OTP sent on WhatsApp", 1000);
      setTime(60); // Reset timer for OTP expiration
      setOtpSent(true); // Set OTP sent to true after sending OTP
    } catch (error) {
      Toast.fail("Failed to send OTP", 1000);
    }
  };

  const verifyOtp = async () => {
    const url = `${API_URL}${API_PREFIX}auth/verify-whatsapp-otp`;
    try {
      const response = await axios.post(url, { mobile: mobileWithCountryCode, otp: OTP });
      if (response.data.status === "success") {
        Toast.success("OTP verified successfully", 1000);
        loginUser();
      } else {
        Toast.fail(response.data.message || "OTP verification failed", 1000);
      }
    } catch (error) {
      Toast.fail("Wrong OTP. Please enter correct OTP.", 1000);
    }
  };

  const loginUser = async () => {
    const url = `${API_URL}${API_PREFIX}auth/login-with-otp`;
    try {
      const response = await axios.post(url, { mobile, mobileWithCountryCode }); // send `mobile` without country code for login
      if (response.data.token) {
        Toast.success("Login successful!", 1000);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userType", response.data.userType);
        localStorage.setItem("approved", response.data.approved);
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("userEmail", response.data.userEmail);

        window.location.href = "/";
      } else {
        Toast.fail("Login failed", 3000);
      }
    } catch (error) {
      Toast.fail("Error logging in", 3000);
    }
  };

  const handleSignIn = () => {
    if (!otpSent) {
      // Send OTP if it hasn't been sent yet
      if (mobile) {
        sendOtp();
      } else {
        Toast.info("Please enter a valid mobile number", 3000);
      }
    } else if (OTP.length === 6) {
      // Verify OTP if OTP field is filled
      verifyOtp();
    } else {
      Toast.info("Enter a valid OTP", 3000);
    }
  };

  return (
    <>
      <div className="enter-mobile-label">Mobile no.</div>
      <div className="detail-from signup-detail-form sign-up-form-wrap">
        <div className="form-input number-code-wrapper signup-phone-input signup-code">
          <PhoneInput
            country={"in"}
            placeholder="Enter mobile no."
            value={mobileWithCountryCode}
            onChange={(phone, country) => {
              setMobileWithCountryCode(phone);
              setCountryCode(country.dialCode);
              setMobile(phone.replace(`${country.dialCode}`, "")); // Store `mobile` without country code
            }}
            isValid={(value) => value.length > 0}
            inputProps={{
              required: true,
            }}
          />
        </div>
        {otpSent && (
          <OTPFormMobileLogin
            OTP={OTP}
            time={time}
            setOTP={updateState}
            resendOTP={sendOtp}
          />
        )}
        <div
          className="sign-btn login-btn-wrapper"
          onClick={handleSignIn}
          style={{ marginTop: "20px" }}
        >
          <a type="submit" className="sign-in-btn">
            {otpSent ? "Sign In" : "Send OTP"}
          </a>
          <br />
        </div>
      </div>
    </>
  );
}
