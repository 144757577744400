import { apiUrl } from '../../utils/urlEndpoints';
import { axiosInstance } from './../../utils/Service'
import { SET_SEARCH_ITEM } from './../actionsTypes'

const userId = localStorage.getItem("userId");
const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const searchItem = (payload) => {
    return dispatch => {
        return axiosInstance.post(apiUrl.homepage.productList, { searchValue: payload, userId })
            .then(response => {
                if (response.data.data.length === 0) {
                    dispatch(setSearchItem(["not"]));
                } else {
                    dispatch(setSearchItem(response.data.data));
                }
            })
    }
}

export const resetSearchItem = (payload) => {
    return dispatch => {
        dispatch(setSearchItem(["not"]));
    }
}

const setSearchItem = (payload) => {
    return {
        type: SET_SEARCH_ITEM,
        payload: payload,
    }
}
