import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { getJobDetail } from "../../../store/actions/common";




const JobDescription = (props) => {
    const param = useParams();
    const { position, experience, location } = param;
    const [expData, setExpData] = useState([]);
    const [posData, setPosData] = useState([]);
    const [locData, setLOcData] = useState([]);


    useEffect(() => {
        const payload = {
            position: position,
            experience: experience,
            location: location
        }

        props.getJobDetail(payload, (status) => {
            if (status) {
                setExpData(status.res.data.experienceData);
                setPosData(status.res.data.positionData);
                setLOcData(status.res.data.locationData);
            }
        });
    }, [])



    return (
        <>
            <h4>Jobs</h4>
            <div>
                {expData && expData.map((item) => {
                    return (
                        <span>{item.experience}</span>
                    )
                })}

                {posData && posData.map((item) => {
                    return (
                        <>
                            <span>{item.name}</span>
                            <span>{item.description}</span>
                            <span>{item.vacancy}</span>
                        </>
                    )
                })}
            </div>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => {
    return {
        getJobDetail: (payload, completion) => dispatch(getJobDetail(payload, completion)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobDescription));