export const SET_USERNAME = "SET_USERNAME";
export const SET_INFO = "SET_INFO";
export const SET_SHOW_LOGIN_POPUP_VALUE = "SET_SHOW_LOGIN_POPUP_VALUE";
export const SET_LOGGED_USER_INFO = "SET_LOGGED_USER_INFO";
export const SET_LOGIN_USER_ERROR = "SET_LOGIN_USER_ERROR";
export const SET_SIGNUP_USER_ERROR = "SET_SIGNUP_USER_ERROR";

export const SET_ABOUT_PRODUCT_DATA = "SET_ABOUT_PRODUCT_DATA";
export const CLEAR_SELL_ITEM_INFO = "CLEAR_SELL_ITEM_INFO";
export const SET_UPLOAD_IMAGE_DATA = "SET_UPLOAD_IMAGE_DATA";
export const SET_ITEM_DESC = "SET_ITEM_DESC";
export const SET_ITEM_SHIPPING_INFO = "SET_ITEM_SHIPPING_INFO";
export const SET_SUBCATEGORY_BRANDS = "SET_SUBCATEGORY_BRANDS";
export const SET_ITEM_DETAIL_FOR_EDIT = "SET_ITEM_DETAIL_FOR_EDIT";
// ######  Edit Seller Product  ##########

export const GET_PRODUCT_WITH_COUPON = "GET_PRODUCT_WITH_COUPON";
export const CREATE_COUPON = "CREATE_COUPON";
export const GET_COUPON = "GET_COUPON";
export const REMOVE_COUPON = "REMOVE_COUPON";
export const SET_PRODUCT_LIST_WISHLIST_SELLER_PROD = "SET_PRODUCT_LIST_WISHLIST_SELLER_PROD";
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_CART = "GET_CART";
export const REMOVE_CART_FOR_WISHLIST = "REMOVE_CART_FOR_WISHLIST"
export const SAVE_STATIC_BANNER_IN_CART_PAGE =
  "SAVE_STATIC_BANNER_IN_CART_PAGE";

export const GET_BID_PRODUCT = "GET_BID_PRODUCT";
export const GET_SELLER_BID_PROD = "GET_SELLER_BID_PROD";
export const REMOVE_FROM_BID = "REMOVE_FROM_BID";

export const SET_REDIRECT_TO_PRIVATE_ROUTE = "SET_REDIRECT_TO_PRIVATE_ROUTE";
export const SET_REDIRECT_TO_NEXTPAGE = "SET_REDIRECT_TO_NEXTPAGE";
export const SET_DISCOVER_TREND_DATA = "SET_DISCOVER_TREND_DATA";

export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SUBCATEGORY = "SET_SUBCATEGORY";
export const SET_BRAND = "SET_BRAND";
export const SET_COUNTRY_DATA = "SET_COUNTRY_DATA";
export const SET_ATTRIBUTE_DATA = "SET_ATTRIBUTE_DATA";
export const SET_USER_SELECTED_ATTRIBUTES = "SET_USER_SELECTED_ATTRIBUTES";
export const SET_REVIEW_ON_PRODUCT = "SET_REVIEW_ON_PRODUCT";



// for Dashboard
export const SET_DASHBOARD_BLOCK_DATA = "SET_DASHBOARD_BLOCK_DATA";
export const SET_BANNER_IMAGE_DATA = "SET_BANNER_IMAGE_DATA";
export const SET_DASHBOARD_PRODUCT_DATA = "SET_DASHBOARD_PRODUCT_DATA";
export const SET_BLOG_DETAILED_DATA = "SET_BLOG_DETAILED_DATA";
export const SAVE_WISHLIST_DATA = "SAVE_WISHLIST_DATA";
export const SAVE_TESTIMONIAL = "SAVE_TESTIMONIAL";

//For search box
export const SET_SEARCH_ITEM = "SET_SEARCH_ITEM";

//For Product Listing
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_ATTRIBUTE_FOR_PRODUCT_LISTING =
  "SET_ATTRIBUTE_FOR_PRODUCT_LISTING";

//For Wish List
export const SET_NEW_WISHLIST = "SET_NEW_WISHLIST";
export const REMOVE_WISHLIST = "REMOVE_WISHLIST";
export const SET_WISHLIST = "SET_WISHLIST";
export const SET_SELLER_WISHLIST = "SET_SELLER_WISHLIST";
export const SET_PRODUCT_LIST_WISHLIST = "SET_PRODUCT_LIST_WISHLIST";


//For Product Detail
export const SET_PRODUCT_DETAIL = "SET_PRODUCT_DETAIL";
export const SET_RELATED_PRODUCTS = "SET_RELATED_PRODUCTS";
export const SET_QA_LIST = "SET_QA_LIST";
export const SET_QUES = "SET_QUES";
export const SET_ANS = "SET_ANS";
export const SET_PRODUCT_OWNER = "SET_PRODUCT_OWNER";
export const SET_PRODUCT_REVIEW_LIST = "SET_PRODUCT_REVIEW_LIST";
export const GET_FOLLOWER = "GET_FOLLOWER";
export const GET_FOLLOWING = "GET_FOLLOWING";
export const SET_MORE_PRODUCT_FROM_THIS_SELLER = "SET_MORE_PRODUCT_FROM_THIS_SELLER";

// for profile
export const SET_USER_CONTACT_INFO = "SET_USER_CONTACT_INFO";
export const SET_USER_ADDRESS_INFO = "SET_USER_ADDRESS_INFO";
export const SAVE_MY_ADDED_PRODUCT = "SAVE_MY_ADDED_PRODUCT";
export const SET_VERIFY_PRODUCT = "SET_VERIFY_PRODUCT";
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";
export const SET_PROFILE_BANNER_INFO = "SET_PROFILE_BANNER_INFO";
export const SAVE_SELLER_ADDED_PRODUCT = "SAVE_SELLER_ADDED_PRODUCT";
export const SET_USER_VERIFY_MSG = "SET_USER_VERIFY_MSG";
export const SAVE_SALES_REPORT_TABLE = "SAVE_SALES_REPORT_TABLE";
export const SAVE_USER_PERFORMANCE_REPORT = "SAVE_USER_PERFORMANCE_REPORT";
export const SET_SOLD_BY_ME = "SET_SOLD_BY_ME";
export const SET_RATING_GIVEN_BY_ME = "SET_RATING_GIVEN_BY_ME";
export const SAVE_NOTIFICATION_IN_STORE = "SAVE_NOTIFICATION_IN_STORE";
export const UPDATE_NOTIFICATION_LIST = "UPDATE_NOTIFICATION_LIST";
export const SET_PRODUCT_LIKER_DATA = "SET_PRODUCT_LIKER_DATA";
export const SET_RATING_REVIEW_ON_MY_PROD = "SET_RATING_REVIEW_ON_MY_PROD";
export const SET_ADDED_ADDRESS_INFO = "SET_ADDED_ADDRESS_INFO";
export const SET_UPDATED_ADDRESS_INFO = "SET_UPDATED_ADDRESS_INFO";



//Chat module
export const SAVE_MESSAGE_LIST = "SAVE_MESSAGE_LIST";
export const SOCKET_CONNECTED = "SOCKET_CONNECTED";
export const SAVE_CONVERSATION_MESSAGE = "SAVE_CONVERSATION_MESSAGE";
export const SAVE_OFFER_LINK = "SAVE_OFFER_LINK";



// for blog
export const SET_BLOG_LIST = "SET_BLOG_LIST";
export const SET_BLOG_CATEGORY_LIST = "SET_BLOG_CATEGORY_LIST";
export const SET_RELATED_BLOG_LIST = "SET_RELATED_BLOG_LIST";
export const SET_BLOG_ARCHIVES_LIST = 'SET_BLOG_ARCHIVES_LIST';
export const SET_BLOG_CREATE_NEW_COMMENT = 'SET_BLOG_CREATE_NEW_COMMENT';
//SELLER PROFILE
export const SET_SELLER_PROFILE_INFO = "SET_SELLER_PROFILE_INFO";
export const SET_FOLLOW_FOLLOWER = "SET_FOLLOW_FOLLOWER";


// ### cms data############
export const SAVE_CMS_DATA = "SAVE_CMS_DATA";

// ######### footer  ##########
export const SAVE_FOOTER_SECTION = "SAVE_FOOTER_SECTION";
export const SET_IP_ADDRESS = "SET_IP_ADDRESS";
export const SET_USER_LOC = "SET_USER_LOC";
export const SET_HEADER_COUNTS = "SET_HEADER_COUNTS";
export const SET_HEADER_NOTIFICATION = "SET_HEADER_NOTIFICATION";

export const INCREASE_WISHLIST_COUNT = "INCREASE_WISHLIST_COUNT";
export const DECREASE_WISHLIST_COUNT = "DECREASE_WISHLIST_COUNT";
export const SET_NETWORK_STATUS = "SET_NETWORK_STATUS";
export const GET_JOB_DETAIL = "GET_JOB_DETAIL";

export const OFFERLIST_BUYER = "OFFERLIST_BUYER";
export const OFFERLIST_SELLER = "OFFERLIST_SELLER";

export const SET_HOME_PAGE_DATA = "SET_HOME_PAGE_DATA";
export const SET_USER_DATA = "SET_USER_DATA";


///////////// Make Payment /////// 
export const MAKE_PAYMENT = "MAKE_PAYMENT";