import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Ticker from "react-ticker";
import {
  setLoginPopupStatus,
  logout,
  getIpAddress,
  totalUnreadNotification,
  getUseLocation,
  getCityByZip,
  getTotalUnreadMsgAndWishlistItem,
} from "../../store/actions/common";
import {
  saveSearch,
  getCategory,
  getBrands,
} from "../../store/actions/productListing";
import {
  getUserAddress,
  getProfileBannerInfo,
} from "../../store/actions/profile";
import { searchItem, resetSearchItem } from "../../store/actions/searchItem";
import { getFooterSection } from "../../store/actions/cms_data";
import { getWishList } from "../../store/actions/Wishlist";
import { Authorization } from "../../utils/Authorization";
import { parse, stringify } from "query-string";
import LocationPopup from "../../PopupFolder/locationPopup";
import LocationEditPopup from "../../PopupFolder/locationEditpopup";
import { getCart } from "../../store/actions/cartAction";
import SellHeader from "./addProductHeader";
import MainHeader from "./MainHeader";
import HeaderSearch from "./MainHeader/headerSearch";
import HeaderCategory from "./HeaderCategory";
import ScrollToTop from "../../ScrollToTop";
import { get } from "lodash";
import { Badge } from "antd";
import { mapApi } from "../../utils/commonFunctions";
import swal from "sweetalert";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      suggestions: [],
      addClass: false,
      selectedVal: "",
      more: false,
      selectedBrand: false,
      categories: [],
      isBrandOpen: false,
      locationPopup: false,
      locationPopup2: false,
      messageMenu: false,
      notificationCount: 0,
      editLocation: false,
      getProfileStatus: "",
      activeClass: false,
      menuActive: false,
      activeId: "",
    };
    this.focusOut = React.createRef();
  }

  componentDidMount() {
    let status = localStorage.getItem("approved");
    this.setState({ getProfileStatus: status });

    window.addEventListener("scroll", this.handleScroll);
    let checkLoginStatus = Authorization();
    let queryString = parse(this.props.location.search);
    this.props.getIpAddress();
    this.props.getCart(checkLoginStatus.userId);
    if (!checkLoginStatus.status && queryString.tab) {
      this.props.setLoginPopupStatus({
        status: true,
        screenToShow: queryString.item,
      });
    }

    if (queryString.searchValue) {
      let selectedVal = {
        Title: queryString.searchValue,
        categorydata: { slug: queryString.category },
        subcategory: { slug: queryString.subcategory },
      };
      this.setState({ value: queryString.searchValue, selectedVal });
    }

    if (checkLoginStatus.status) {
      this.props.getProfileBannerInfo();
      this.props.getUserAddress({}, (status) => {});
      this.props.getWishList();
      this.props.notificationCount({}, (callback) => {});
      setTimeout(() => {
        if (this.props.notification <= 99) {
          this.setState({ notificationCount: this.props.notification });
        } else {
          this.setState({ notificationCount: "99+" });
        }
      }, 2000);
    }
    this.props.getCategory(parse(this.props.location.search));
    this.props.getBrands(parse(this.props.location.search));
    this.props.getFooterSection((das) => {});
  }

  handleScroll = (event) => {
    let button = document.getElementById("scroll-to-top");
    let position = document.documentElement.scrollTop;
    if (button) {
      if (position < 100) {
        button.classList.add("display-none");
      } else {
        button.classList.remove("display-none");
      }
    }
  };

  enableScrollBar = () => {
    return document
      .getElementsByTagName("html")[0]
      .classList.remove("remove-scroll-bar");
  };
  hideCategoryPopup = () => {
    this.setState({ more: false });
  };

  desableScrollBar = () => {
    return document
      .getElementsByTagName("html")[0]
      .classList.add("remove-scroll-bar");
  };

  openLocationPopup = (state) => {
    this.setState(state, () => {
      this.desableScrollBar();
    });
  };

  componentDidUpdate(PrevProps) {
    if (
      this.props.history.location.search &&
      parse(this.props.history.location.search).zip &&
      parse(this.props.history.location.search).zip !==
        get(this.props, ["userLocation", "userInputZip"], false)
    ) {
      this.props.getCityByZip(
        parse(this.props.history.location.search).zip,
        (status) => {}
      );
    }

    if (
      this.props.browser_location.coords !==
        PrevProps.browser_location.coords && // for getting location city
      PrevProps.browser_location.coords
    ) {
      let myLat = PrevProps.browser_location.coords.latitude;
      let myLon = PrevProps.browser_location.coords.longitude;
      this.props.getUseLocation(myLat, myLon, mapApi);
    }

    if (PrevProps.searchValue !== this.props.searchValue) {
      this.setState({
        suggestions: this.props.searchValue.searchItem,
        selectedVal: this.state.selectedVal,
      });
    }
    if (
      this.state.categories.length === 0 &&
      this.state.categories !== this.props.categories
    )
      this.setState({ categories: this.props.categories });
  }

  redirectHomePage = () => {
    this.props.history.push("/");
    this.setState({ value: "", selectedVal: "" });
  };

  registerOpen = (screenToShow) => {
    let query = { ...parse(this.props.location.search) };
    query.tab = screenToShow;
    this.props.setLoginPopupStatus({
      status: true,
      screenToShow: screenToShow,
      type: "user",
    });
  };

  toggle = () => {
    this.setState({ addClass: !this.state.addClass });
  };

  onChange = (event, { newValue, method }) => {
    this.props.searchItem(newValue);
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => {
    if (suggestion.productName) {
      return this.state.value;
    }
    return suggestion.productName;
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.history.push({
      pathname: `/listing/${suggestion.category}`,
      state: {
        categorie: suggestion.category,
      },
    });

    // this.setState({ value: suggestion.Title, selectedVal: suggestion });
    // this.props.resetSearchItem();
    // this.searchProduct(suggestion);
  };

  onPresssEnter = (val) => {
    // let title = this.state.value.replace(/ /g, "+");
    // this.props.history.push(
    //   `/product-listing-page/search/?searchValue=${title}`
    // );
  };

  searchProduct = (suggestion) => {
    let checkLoginStatus = Authorization();
    if (this.state.value && suggestion !== "not") {
      let title = "";
      if (suggestion !== "") {
        title = suggestion.productName.replace(/ /g, "+");
        const url = `/product/${suggestion.categoryData.name}/${suggestion._id}/?cat=${suggestion.categoryData.slug}`;

        if (!checkLoginStatus.status) {
          this.props.saveSearch({
            data: { url, text: suggestion.Title },
            browser_ip: this.props.ipAddress.ip,
          });
        } else {
          this.props.saveSearch({
            data: { url, text: suggestion.Title },
            userId: localStorage.getItem("userId"),
          });
        }
        this.props.history.push(
          `/product/${suggestion.categoryData.name}/${suggestion._id}/?cat=${suggestion.categoryData.slug}`
        );
      } else {
        title = this.state.value.replace(/ /g, "+");
        const url = `${window.location.host}/product-listing-page/search/?searchValue=${title}`;
        if (!checkLoginStatus.status) {
          this.props.saveSearch({
            data: { url, text: suggestion.Title },
            browser_ip: this.props.ipAddress.ip,
          });
        } else {
          this.props.saveSearch({
            data: { url, text: suggestion.Title },
            userId: localStorage.getItem("userId"),
          });
        }
        this.props.history.push(
          `/product-listing-page/search/?searchValue=${title}`
        );
      }
      setTimeout(() => {
        this.props.getCategory(parse(this.props.location.search));
        this.props.getBrands(parse(this.props.location.search));
      }, 50);
    } else {
      this.setState({
        value: "",
        suggestions: [],
        addClass: false,
        selectedVal: "",
        more: false,
      });
    }
  };

  showLimitedCategory = () => {
    let data = this.props.categories.filter((item, i) => i < 9);
    return data;
  };

  showOtherCategory = () => {
    let data = this.props.categories.filter((item, i) => i >= 9);
    return data;
  };

  showAlert = (callBack) => {
    if (!this.props.browser_location.isGeolocationAvailable) {
      swal("Your browser does not support Geolocation");
    } else if (!this.props.browser_location.isGeolocationEnabled) {
      swal("Location is disabled, Please enable manually");
    }
    if (callBack) return callBack();
  };

  useCurrentLocation = (callback) => {
    this.enableScrollBar();
    if (this.props.browser_location.coords) {
      callback(this.props.browser_location.coords);
    } else {
      this.showAlert(() => {
        this.setState({ locationPopup: false });
      });
    }
  };

  applyCurrentLocation = (radius) => {
    if (this.props.browser_location.coords) {
      this.setState({ locationPopup: false }, () =>
        this.props.history.push(
          `/product-listing-page/${"Local"}?category=${"Local"}&lat=${
            this.props.browser_location.coords.latitude
          }&long=${
            this.props.browser_location.coords.longitude
          }&radius=${radius}`
        )
      );
      this.setState({ editLocation: false });
    } else {
      swal("Location is disabled, Please enable manually");
    }
  };

  useCurrentLoc = () => {
    this.enableScrollBar();
    if (this.props.browser_location.coords) {
      let bazaarZip = JSON.parse(localStorage.getItem("bazaarZip"));

      if (bazaarZip) {
        bazaarZip.isSearch = false;
        localStorage.setItem("bazaarZip", JSON.stringify(bazaarZip));
      }
      this.setState({ locationPopup: false }, () =>
        this.props.history.push(
          `/product-listing-page/${"Local"}?category=${"Local"}&lat=${
            this.props.browser_location.coords.latitude
          }&long=${this.props.browser_location.coords.longitude}`
        )
      );
      this.setState({ editLocation: false });
    } else {
      this.showAlert(() => {
        this.setState({ locationPopup: false });
      });
    }
  };

  contShopping = (zip) => {
    this.enableScrollBar();
    localStorage.setItem(
      "bazaarZip",
      JSON.stringify({
        zip: zip,
        isSearch: true,
      })
    );
    this.setState({ locationPopup: false }, () =>
      this.props.history.push(
        `/product-listing-page/${"Deliver-to"}?type=${"delivery"}&deliveryZipCode=${zip}`
      )
    );
  };

  messageMenuPopup = () => {
    this.setState(
      {
        messageMenu: !this.state.messageMenu,
      },
      () =>
        this.state.messageMenu
          ? this.desableScrollBar()
          : this.enableScrollBar()
    );
  };

  closeMessageMenu = () => {
    this.setState({ messageMenu: false }, () => this.enableScrollBar());
  };

  resetValueState = () => {
    this.setState({ value: "" });
  };

  editLocationPopup = () => {
    this.toggle();
    this.setState({ editLocation: true });
  };

  localSearchEdit = (searchQ) => {
    this.setState({ editLocation: false });
    this.props.history.push(`/product-listing-page/${"Local"}?${searchQ}`);
  };

  handleActive = (e, item) => {
    this.setState({ activeClass: true, activeId: item._id, menuActive: false });
  };

  render() {
    let queryString = parse(this.props.location.search);
    const { pathname } = this.props.location;
    const isLoggedIn = this.props.loggedUserInfo.isLoggedIn;
    const userName = this.props.loggedUserInfo.userName;

    let boxClass = ["menu-strip"];
    let activeMenu = [""];
    if (this.state.addClass) {
      boxClass.push("active");
      activeMenu.push("activeMenu");
    }
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "Search for Product",
      value,
      onChange: this.onChange,
      ref: this.focusOut,
    };

    return (
      <>
        <header className="header header-after-login" id="header">
          <div className="top-btn" id="scroll-to-top">
            <ScrollToTop />
            <button
              className="top-link"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <i className="fal fa-angle-up" />
            </button>
          </div>
          {pathname !== "/sell-item" ? (
            <React.Fragment>
              {this.state.locationPopup ? (
                <LocationPopup
                  closePopup={() =>
                    this.setState({ locationPopup: false }, () =>
                      this.enableScrollBar()
                    )
                  }
                  useCurrentLoc={this.useCurrentLoc}
                  contShopping={(zip) => this.contShopping(zip)}
                  browser_location={this.props.browser_location}
                />
              ) : null}

              {this.state.editLocation ? (
                <LocationEditPopup
                  closePopup={() => this.setState({ editLocation: false })}
                  contShopping={(searchQuery) =>
                    this.localSearchEdit(searchQuery)
                  }
                  useCurrentLoc={this.useCurrentLoc}
                  useCurrentLocation={this.useCurrentLocation}
                  applyCurrentLocation={this.applyCurrentLocation}
                />
              ) : null}
              <Badge notificationCount={100}>
                <MainHeader
                  goToHome={this.redirectHomePage}
                  isLoggedIn={isLoggedIn}
                  userName={userName}
                  setState={this.setState}
                  notificationCount={this.state.notificationCount}
                  messageMenu={this.state.messageMenu}
                  setMessageMenu={this.messageMenuPopup}
                  setValue={this.resetValueState}
                  logout={this.props.logout}
                  registerOpen={this.registerOpen}
                  cartLength={
                    this.props.cartData ? this.props.cartData.length : 0
                  }
                  userProfileBanner={this.props.userProfileBanner}
                  browser_location={this.props.browser_location}
                  openLocationPopup={this.openLocationPopup}
                  logo={this.props.siteLogo}
                >
                  <div className="primary-header primary-metal-header">
                    <div className="navbar-wrapper">
                      <nav className="navbar">
                        <ul className="nav-menu">
                          <li
                            onClick={(e) =>
                              this.setState({
                                menuActive: true,
                                activeClass: false,
                              })
                            }
                            className={
                              this.state.menuActive ? "list_active" : ""
                            }
                          >
                            <Link to="/page/aboutUs">About Us</Link>
                          </li>
                          {this.props.categories.map((item) => {
                            return (
                              <li
                                onClick={(e) => this.handleActive(e, item)}
                                className={
                                  this.state.activeClass &&
                                  this.state.activeId == item._id
                                    ? "list_active"
                                    : ""
                                }
                              >
                                <Link
                                  to={{
                                    pathname: [
                                      "Fines",
                                      "Chips",
                                      "Lumps",
                                      "Coil",
                                      "Straightening",
                                      "Defective Coil"
                                    ].includes(item.name)
                                      ? `/listing/${item._id}`
                                      : "/",
                                    state: {
                                      categorie: [
                                        "Fines",
                                        "Chips",
                                        "Lumps",
                                        "Coil",
                                        "Straightening",
                                        "Defective Coil"
                                      ].includes(item.name)
                                        ? item._id
                                        : "/",
                                    },
                                  }}
                                >
                                  {item.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <HeaderSearch
                    inputProps={inputProps}
                    getSuggestionValue={this.getSuggestionValue}
                    onSuggestionSelected={this.onSuggestionSelected}
                    clear={() =>
                      this.setState({
                        suggestions: [],
                      })
                    }
                    value={value}
                    suggestions={suggestions}
                    searchProduct={this.searchProduct}
                    selectedVal={this.state.selectedVal}
                    onPresssEnter={this.onPresssEnter}
                  />
                </MainHeader>
              </Badge>
              {/* <HeaderCategory
                  userData={this.props.userData.userdata}
                  messageMenu={this.state.messageMenu}
                  closeMessageMenu={this.closeMessageMenu}
                  setBrandState={(data) => this.setState({ isBrandOpen: data })}
                  isBrandOpen={this.state.isBrandOpen}
                  activeMenu={activeMenu}
                  brands={this.props.brands}
                  queryString={queryString}
                  categories={this.state.categories}
                  showLimitedCategory={this.showLimitedCategory}
                  showOtherCategory={this.showOtherCategory}
                  setCategory={(data) => this.setState({ categories: data })}
                  more={this.state.more}
                  hideCategoryPopup={this.hideCategoryPopup}
                  setMore={(data) => this.setState({ more: data })}
                  boxClass={boxClass}
                  toggle={this.toggle}
                  openLocationPopup={this.openLocationPopup}
                  registerOpen={this.registerOpen}
                  isLoggedIn={isLoggedIn}

                  userName={userName}
                  setValue={this.resetValueState}
                  logout={this.props.logout}
                  userProfileBanner={this.props.userProfileBanner}
                  setMessageMenu={this.messageMenuPopup}
                  editPopupFunction={this.editLocationPopup}
                  setLoginPopupStatus={this.props.setLoginPopupStatus}
                /> */}
            </React.Fragment>
          ) : (
            <SellHeader goToHome={this.redirectHomePage} />
          )}
        </header>
        {/* <div className='header-msg'>
          <div className='news-header'>
            <div className='latest-news'>
              <div className='ltl-news'>
                <div className='news-content-wrap'>
                  <div className='news-logo'>
                    <img src="/assets/images/news-icon.svg" alt="news-logo" />
                  </div>
                  <h3>Latest news :</h3>
                </div>
              </div>
            </div>
            <div className='container-main'>
              <div className='ticker-wrapper'>
                <Ticker>
                  {({ index }) => (
                    <>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Egestas odio nisl, diam, a pellentesque faucibus egestas.Habitant varius a sit tincidunt fames congue elementum et.
                      </p>
                    </>
                  )}
                </Ticker>
              </div>
            </div>
          </div>
        </div> */}
        {this.state.getProfileStatus == "pending" ? (
          <div className="wait-for-approve header-text">
            <span className="approve-text  normal-text">
              Your account is currently under review and has limited access.
              Please allow us up to 48 hours to complete the review process.
            </span>
          </div>
        ) : null}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
    logout: () => dispatch(logout()),
    searchItem: (payload) => dispatch(searchItem(payload)),
    resetSearchItem: (payload) => dispatch(resetSearchItem(payload)),
    getUserAddress: (payload, completion) =>
      dispatch(getUserAddress(payload, completion)),
    getWishList: () => dispatch(getWishList()),
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
    getCategory: (payload) => dispatch(getCategory(payload)),
    getBrands: (payload) => dispatch(getBrands(payload)),
    getFooterSection: (aa) => dispatch(getFooterSection(aa)),
    saveSearch: (param) => saveSearch(param),
    getIpAddress: () => dispatch(getIpAddress()),
    getUseLocation: (lat, long, apiKey) =>
      dispatch(getUseLocation(lat, long, apiKey)),
    getCityByZip: (zip, apiKey) => dispatch(getCityByZip(zip, apiKey)),
    getCart: (payload) => dispatch(getCart(payload)),
    notificationCount: (payload, completion) =>
      dispatch(totalUnreadNotification(payload, completion)),
    notificationMsgWishCount: (payload, count) =>
      getTotalUnreadMsgAndWishlistItem(payload, count),
  };
};
const mapStateToProps = (state, ownProps) => ({
  userData: state.userProfile.userProfileBanner,
  notification: state.headerRedu.total_notification_data,
  showLoginPopup: state.generalInfo.showLoginPopup,
  ipAddress: state.generalInfo.ip_address,
  loggedUserInfo: state.userInfo.loggedUserInfo,
  searchValue: state.searchItemInfo,
  categories: state.productListingInfo.categories,
  brands: state.productListingInfo.brands,
  userProfileBanner: state.userProfile.userProfileBanner,
  cartData: state.cart.getcart,
  userLocation: state.generalInfo.userLocation,
  siteLogo: state.getCMS_data.logo,
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
