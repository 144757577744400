import React, { useState } from "react";
import { isEmpty } from "lodash";
import NotificationCard from "./notificationCard";
import { ButtonLoader, SpinnerLoader } from "../../../../utils/constants";
import "../../Profile.css";
import { useToasts } from "react-toast-notifications";
import { connect } from "react-redux";
import {
  getnotification,
  readnotification,
  deleteNotification,
  updateNotificationList,
  totalUnreadNotification,
} from "../../../../store/actions/common";
import { remove } from "lodash";
import Loader from "../../../Loader";

const NotificationPage = (props) => {
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [innerLoader, setInnerLoader] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  const { addToast } = useToasts();

  const loadMore = () => {
    setButtonLoader(true);
    props.getnotification({
      userId: props.userId,
      page: props.notification.page + 1,
    });
    setTimeout(() => {
      setButtonLoader(false);
    }, 700);
  };

  React.useEffect(() => {
    setShowLoader(true);
    setShowMessage("Please wait...");
    props.getnotification({ userId: props.userId, page: 1 });
    setTimeout(() => setShowLoader(false), 1500);
  }, []);

  const readNotification = (id, index) => {
    const { notificationList } = props.notification;
    notificationList[index].read_recipt = true;
    props.readnotification({ notificationId: id }, (status) => {
      if (status) {
        props.updateList(notificationList);
        props.notificationCount({}, (status) => {});
      }
    });
  };

  const deleteNotification = (id, index) => {
    const { notificationList } = props.notification;
    remove(notificationList, function (n, m) {
      return m === index;
    });
    props.deleteNotification({ notificationId: id }, (status) => {
      if (status) {
        props.updateList(notificationList);
        addToast("Notification Delete", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
      } else {
        addToast("fail to delete notification", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    });
  };

  return (
    <div style={{ position: "relative" }} className="notification-wrapper-main">
      {showLoader ? <Loader message={showMessage} /> : null}
      <div
        className={
          "notifi-page message" + (innerLoader ? " spinner-time-div" : "")
        }
      >
        {!isEmpty(props.notification)
          ? props.notification.notificationList.length > 0 && (
              <>
                <h2>Notifications</h2>
              </>
            )
          : null}
        <div className="notifi-container contact-list">
          {!isEmpty(props.notification) ? (
            props.notification.notificationList.length > 0 ? (
              props.notification.notificationList.map((data, index) => (
                <NotificationCard
                  data={data}
                  key={index}
                  deleteNotifi={deleteNotification}
                  index={index}
                  read={readNotification}
                />
              ))
            ) : (
              <div className="product-head">
                <h6> No New Notification </h6>
              </div>
            )
          ) : null}
          <div>
            {!isEmpty(props.notification.notificationList) &&
            // props.notification.notificationList.length === 15 &&
            props.notification.notificationList.length <
              props.notification.totalNotification ? (
              <div className="plp-loadMore-div">
                <button className="shop-now" onClick={loadMore}>
                  {buttonLoader ? (
                    <ButtonLoader content={"Loading"} />
                  ) : (
                    "Load more"
                  )}
                </button>
              </div>
            ) : !isEmpty(props.notification) &&
              props.notification.totalNotification ? (
              <div></div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    getCount: state,
    notification: state.userProfile.notification,
    userId: state.userInfo.loggedUserInfo.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getnotification: (payload) => dispatch(getnotification(payload)),
    readnotification: (payload, completion) =>
      readnotification(payload, completion),
    deleteNotification: (payload, completion) =>
      deleteNotification(payload, completion),
    updateList: (data) => dispatch(updateNotificationList(data)),
    notificationCount: (payload, completion) =>
      dispatch(totalUnreadNotification(payload, completion)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
