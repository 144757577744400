import React from "react";
import { useHistory } from "react-router-dom";


const ActionItem = (props) => {
  let history = useHistory()


  const { totalUnreadMessage, totalSellItemsforAction } = props.profileInfo;
  return (
    <div className="action-item">
      <h4>Action items</h4>
      <div className="actions-items">
        <button onClick={() => history.push('/chats/all')}><i className="fal fa-comment-alt"></i> {totalUnreadMessage} Unread message <i className="far fa-angle-right"></i></button>
        <button onClick={() => history.push('/profile/my-offer/pending')}><i className="fas fa-info-circle"></i> {totalSellItemsforAction} Offers pending <i className="far fa-angle-right"></i></button>
        <button onClick={() => history.push('/profile/seller-order/pending')}><i className="fas fa-info-circle"></i> {totalSellItemsforAction} Orders pending <i className="far fa-angle-right"></i></button>
      </div>
    </div>
  );
};
export default ActionItem;
