import React, { useState, useEffect } from "react";
import Toast from "light-toast";
import { useToasts } from "react-toast-notifications";
import Loader from "../Loader";
import TextField from "@material-ui/core/TextField";
import { forgetPassword } from "../../store/actions/forgetPassword";
import { otpVerify } from "../../store/actions/otpVerify";
import { updatePassword } from "../../store/actions/updatePassword";



const ForgotPass = (props) => {
  const { addToast } = useToasts();
  const [forgotNumber, setforgotNumber] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [otp, setotp] = React.useState(false);
  const [GetOtp, setGetOtp] = React.useState("");
  const [getToken, setgetToken] = React.useState("");
  const [resetPass, setresetPass] = React.useState(false);
  const [otpField, setotpField] = React.useState(true);
  const [Error, setError] = React.useState(false);
  const [password, setpassword] = React.useState("");
  const [passwordError, setpasswordError] = React.useState(false);
  const [verifyDis, setverifyDis] = React.useState("true");
  const [resent, setresent] = React.useState(false);
  const [confirmpassword, setconfirmpassword] = React.useState("");
  const [disableButton, setDisableButton] = React.useState(true);
  const [chnagePasswordDisable, setchnagePasswordDisable] = React.useState(true);
  const [passDigits, getPassDigits] = React.useState(false);
  const [conPassDigits, getConPassDigits] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [enterMobileScreen, setEnterMobileScreen] = useState(true);
  const [forgotEmailError, setForgotEmailError] = useState(false);
  const [headToken, setHeadToken] = useState("");
  const [newPassword, setNewPassword] = useState(false)


  const timer = () => props.setState("time", props.time - 1);

  useEffect(() => {
    if (props.time <= 0) { return; }
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [props.time])



  const forgotpassword = (e) => {
    setresetPass(false);
    e.preventDefault();
    // if (forgotNumber.length < 10) {
    //   return addToast("Please enter a valid number", {
    //     appearance: "error",
    //     autoDismissTimeout: 2500,
    //   });
    // }
    setShowLoader(true);
    setShowMessage("Please wait...");
    forgetPassword({email:forgotNumber}, setShowLoader, addToast, setEnterMobileScreen, setotp)
    // props.forgotPassword(forgotNumber, (status) => {

    //   if (status.status === "success") {
    //     setresent(true);
    //     setgetToken(status.token)
    //     setHeadToken(status.headerToken)
    //     setShowLoader(false);
    //     // addToast("A Reset password Link has been sent on email adress.", {
    //     //   appearance: "success",
    //     //   autoDismissTimeout: 2500,
    //     // });
    //     //setotpField(true);
    //     // setShowLoader(false);
    //     // setresetPass(true);
    //     // setotpField(false);
    //     setotp(true);
    //     setEnterMobileScreen(false);
    //     // setTimeout(() => {
    //     // props.closePopup(false);
    //     // }, 1000);

    //   } else {
    //     setShowSuccess(false)
    //     setShowLoader(false);
    //     addToast(status.message, {
    //       appearance: "error",
    //       autoDismissTimeout: 2500,
    //     });
    //     setotp(false)
    //     setotpField(false);
    //   }
    // });
  };


  const hide = () => {
    setotp(false);
  }

  const getOTP = (value) => {
    if (value) {
      setverifyDis(false);
    }
    else {
      setverifyDis(true);
    }
    setGetOtp(value);
  }

  const otpVerification = (e) => {
    e.preventDefault();
    otpVerify({  "email": forgotNumber,"otp": GetOtp}, addToast, setShowLoader, setNewPassword, setotp)
    // setShowMessage("Please wait...");
    // const payload = {
    //   otp: GetOtp,
    //   type: "forgetPassword",
    //   token: getToken
    // }
    // props.forgotpasswordotp(payload, (status) => {
    //   if (status.status) {
    //     addToast(status.message, {
    //       appearance: "success",
    //       autoDismissTimeout: 2500,
    //     });
    //     setShowLoader(false);
    //     setresetPass(true);
    //     setotpField(false);
    //   } else {
    //     addToast(status.message, {
    //       appearance: "error",
    //       autoDismissTimeout: 2500,
    //     });
    //     setShowLoader(false);
    //     setresetPass(false);
    //     setotpField(true);
    //   }
    // });
  }

  const resetPassword = () => {
    console.log("password",password,confirmpassword,confirmpassword != password)
    if (password.length == 0) {
      return addToast("Please enter password", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (confirmpassword.length == 0) {
      return addToast("Please enter confirm password", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (confirmpassword != password) {
      return addToast("Password and confirm password should match", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    }
    setShowLoader(true);
    setShowMessage("Please wait...");
    const payload = {
      email: forgotNumber,
      password: password,
      otp:GetOtp
    }
    updatePassword(payload, addToast, setShowLoader, props.closePopup)
    // props.setPassword(payload, headToken, (status) => {
    //   if (status.status) {
    //     addToast(status.message, {
    //       appearance: "success",
    //       autoDismissTimeout: 2500,
    //     });
    //     setShowLoader(false);
    //     setresetPass(false)
    //     setotpField(false)
    //     setotp(false)
    //     props.closePopup(false);
    //   } else {
    //     addToast(status.message, {
    //       appearance: "error",
    //       autoDismissTimeout: 2500,
    //     });
    //     setShowLoader(false);
    //   }
    // });
  }


  const getConfirm = (e) => {
    if (password == e) {
      setchnagePasswordDisable(false);
      setconfirmpassword(e)
      setError(false)
    }
    else {
      setchnagePasswordDisable(true);
      setError(true)
      setconfirmpassword(e)
    }

  }



  const resendOTP = () => {
    setShowLoader(true);
    setShowMessage("Please wait...");
    const payload = {
      token: getToken
    }

    props.forgotpasswordresend(payload, (status) => {
      if (status.status) {
        addToast(status.message, {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        setShowLoader(false);
        setotpField(true)
        props.setState("time", 50)
      } else {
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
        setShowLoader(false);
        setotpField(false);
      }

    });
  }

  const getOtpField = (e) => {
    const checkDigit = /^[0-9\b]+$/;

    if (checkDigit.test(e.target.value)) {
      if (e.target.value.length === 6) {
        getOTP(e.target.value)
        setDisableButton(false);
      } else {
        getOTP(e.target.value)
        setDisableButton(true);
      }
    }



  }

  const getPhonenumber = (e) => {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setforgotNumber(e.target.value);
      setForgotEmailError(false);
    } else {
      setforgotNumber(e.target.value);
      setForgotEmailError(true);

    }

  }

  const backHandler = () => {
    setEnterMobileScreen(true);
    setotp(false)
    props.setState("time", 50)
  }

  const backHandlerAfterOtp = () => {
    setresetPass(false);
    setotpField(true)
    props.setState("time", 50)

  }

  const showDigits = (e) => {
    if (e == "password") {
      getPassDigits(!passDigits);
    } else if (e == "confirmpassword") {
      getConPassDigits(!conPassDigits);
    } else {

    }
  }

  const isStrongPassword = (password) => {
    // Check for at least one lowercase letter
    const lowercaseRegex = /[a-z]/;
    
    // Check for at least one uppercase letter
    const uppercaseRegex = /[A-Z]/;
  
    // Check for at least one special character (non-alphanumeric)
    const specialRegex = /[!@#$%^&*()\-_=+{}[\]|;:'",.<>?`~]/;
  
    // Check for at least one number
    const numberRegex = /\d/;
  
    // Check if the length is greater than or equal to 8
    const lengthRegex = /.{8,}/;
  
    // Combine all regex checks
    const isValid =
      lowercaseRegex.test(password) &&
      uppercaseRegex.test(password) &&
      specialRegex.test(password) &&
      numberRegex.test(password) &&
      lengthRegex.test(password);
  
    return isValid;
  }

  const handleNewPassword = (e) =>{
    setpassword(e.target.value)
    if(isStrongPassword(e.target.value)){
      setpasswordError(false)
    }
    else{
      setpasswordError(true)
    }
  }
  return (
    <>
      {showLoader ? <Loader message={showMessage} /> : null}
      {enterMobileScreen ?
        <form onSubmit={(e) => forgotpassword(e)} className="forget-block forget-block-wrapper forget-passwrd-new">
          <div className="detail-from">
            <div className="user-detail-edit">
              <h4 className="form-title">Forgot Password</h4>
            </div>
            <div>{errorMsg}</div>
            <div className='input-form-wrap'>
              <div className='form-intput'>
                <label>Email</label>
                <input
                  required
                  type="text"
                  name="forgotNumber"
                  className="form-control mail-input"
                  placeholder="Enter email"
                  value={forgotNumber}
                  onChange={(e) => getPhonenumber(e)}
                />
                {forgotEmailError ? <span className="error">Enter a valid email address</span> : null}
              </div>

              {showSuccess && <span className="send-msg-green" style={{ color: "green" }}>{showSuccess}</span>}
            </div>
          </div>
          <button
            className="btn-forget forget-btn-wrap"
            type="submit"
          >
            <span className="forget-send-btn">Send</span>
          </button>
        </form> : null
      }
      {/* <div className='input-form-wrap'>
        <div className='form-intput'>
          <label>Facebook</label>
          <input type="text" placeholder='Enter link here' onChange={(e) => facebookhandler(e)} id="facebook" disabled={profileComponent} value={facebook} maxlength="50" />
        </div>
        {validFacebook ? <span className='error'>Please enter url with http or https</span> : null}
      </div> */}

      {
        otp ?
          <>
            <form onSubmit={(e) => otpVerification(e)}>
              <div className="detail-from">
                <div className="input-form">
                  {otpField ?

                    <>
                      <div className="detail-from otp-screen">
                        <h4 className="verify-mobile"> Verify OTP</h4>
                        <p className="check-otp">Provide the OTP sent to</p>
                        <div className="otp-timer otp-timer-wrap">
                          <span>{forgotNumber}
                            <span className="form-title go-back">
                              <span className='change-number-text' onClick={backHandler}>change</span>
                            </span>
                          </span>
                        </div>
                        <div className="otp-wrap">
                          <label className="enter-otp-screen">Enter OTP </label>
                          <div className="form-input form-input-otp otp-page-signin forget-otp-page">
                            <input type="text" maxlength="6"
                              onChange={(e) => getOtpField(e)} placeholder="OTP" className="get-otp-screen"
                              value={GetOtp}
                            />
                          </div>
                          <div className="otp-btns">
                            <div className="btns-wrap">
                              <button className="otp-btn submit" disabled={disableButton}>Submit</button>
                              {/* <div className="otp-timer">
                                <span>{`00:${props.time}`}</span>
                              </div>
                              <button className="otp-btn resend-otp-wrap" type="button" onClick={resendOTP}
                                disabled={props.time === 0 ? false : true}
                              >Resend OTP</button> */}
                            </div>
                          </div>
                        </div>
                      </div >


                    </> : null
                  }


                  {resetPass ?
                    <div className="pass-wrap forget-password-wrapper">

                      <label class="forget-passwrd-icon-wrap">password</label>
                      <div className="pass-input-wrap forget-password-wrapper pass-one">
                        <input type={passDigits ? "text" : "password"} id="pwd" name="pwd" onChange={(e) => { setpassword(e.target.value) }} placeholder="password" required />
                        {passDigits ? <i className="far fa-solid fa-eye" onClick={() => showDigits("password")}></i> :
                          <i className="far fa-eye fal fa-eye-slash" onClick={() => showDigits("password")} />
                        }

                      </div>
                      <label class="forget-passwrd-icon-wrap">confirm password</label>
                      <div className="pass-input-wrap forget-password-wrapper pass-two">
                        <input type={conPassDigits ? "text" : "password"} id="pwd" name="pwd" onChange={(e) => getConfirm(e.target.value)} placeholder="Confirm password" required />
                        {Error && password !== confirmpassword ? <span className="error">Password and Confirm Password should match</span> : null}
                        {conPassDigits ? <i className="far fa-solid fa-eye" onClick={() => showDigits("confirmpassword")}></i> :
                          <i className="far fa-eye fal fa-eye-slash" onClick={() => showDigits("confirmpassword")} />
                        }
                      </div>
                      <button className="shop-now" type="button" onClick={resetPassword} >Submit</button>
                    </div> : null
                  }

                </div>
              </div>
            </form>
          </>
          : null
      }
      {
        newPassword ?
          <>
            <form>
              <div className="detail-from">
                <div className="input-form">
                  <div className="pass-wrap forget-password-wrapper">
                    <label class="forget-passwrd-icon-wrap">New password</label>
                    <div className="pass-input-wrap forget-password-wrapper pass-one">
                      <input type={passDigits ? "text" : "password"} id="pwd" name="pwd" onChange={handleNewPassword} placeholder="password" required />
                      {passDigits ? <i className="far fa-solid fa-eye" onClick={() => showDigits("password")}></i> :
                        <i className="far fa-eye fal fa-eye-slash" onClick={() => showDigits("password")} />
                      }

                    </div>
                    <label class="forget-passwrd-icon-wrap">Confirm password</label>
                    <div className="pass-input-wrap forget-password-wrapper pass-two">
                      <input type={conPassDigits ? "text" : "password"} id="pwd" name="pwd" onChange={(e) => getConfirm(e.target.value)} placeholder="Confirm password" required />
                      {Error && password !== confirmpassword ? <span className="error">Password and Confirm Password should match</span> : null}
                      {passwordError ? <span className="error"> Password should contain at least one Upper case, one
                            lowercase, one special character and one numeric
                            character and password length should be minimum 8
                            character</span> : null}
                      {conPassDigits ? <i className="far fa-solid fa-eye" onClick={() => showDigits("confirmpassword")}></i> :
                        <i className="far fa-eye fal fa-eye-slash" onClick={() => showDigits("confirmpassword")} />
                      }
                    </div>
                    <button className="shop-now" type="button" onClick={resetPassword} >Submit</button>
                  </div>

                </div>
              </div>
            </form>
          </>
          : null
      }
    </>

  );
}
export default ForgotPass;
