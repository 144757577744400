import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { axiosInstance } from "../../../utils/Service";
import moment from "moment";
import { useSelector } from "react-redux";
import DeliveryAddress from "../../OrderDetails/deliveryAddress";
import CounterRfq from "../../ProductDetail/CounterRfq";
import Loader from "../../Loader";
import Countdown from "react-countdown";
import RfqCounterOffersDetail from "./RfqCounterOfferDetails";
import Toast from 'light-toast'

const RfqDetail = () => {
  const userData = useSelector(
    (store) => store.userProfile.userProfileBanner.userdata
  );
  const [loader, setLoader] = useState(false);
  const [productsDetail, setProductDetail] = useState(null);
  const [offerPopUp, setOfferPopup] = useState(false);
  const [sellerData, setSellerData] = useState([]);
  const { prodId, prodType } = useParams();
  let history = useHistory();
  let location = useLocation();
  const sellerCounterButtonRef = useRef(null);

  useEffect(() => {
    handleRfqDetail();
  }, [prodId, userData]);

  const handleRfqDetail = () => {
    let token = localStorage.getItem("token");
    setLoader(true);
    if (userData && userData.userType) {
      axiosInstance
        .post(
          `/requestForQuote/request_details`,
          {
            userType: userData.userType,
            requestId: prodId,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setProductDetail(res.data);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSellerAccept = () => {
    let token = localStorage.getItem("token");
    setLoader(true);
    if (userData && userData.userType) {
      axiosInstance
        .post(
          `/requestForQuote/seller_accept_rquest`,
          {
            requestId: productsDetail.data._id,
            // seller_request_id: productsDetail.data.sellerData.seller_request_id,
            sellerId: userData._id,
            buyerId: productsDetail.data.buyerId,
            isAccepted: true,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setLoader(false);
          Toast.success(
            "Offer has been accepted! "
          );
          window.location.reload();
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSellerCounter = () => {
    if (sellerCounterButtonRef.current) {
      sellerCounterButtonRef.current.click();
    }
  };

  const handleBuyerAccept = (item) => {
    let token = localStorage.getItem("token");
    setLoader(true);
    if (userData && userData.userType) {
      axiosInstance
        .post(
          `/requestForQuote/buyer_accept`,
          {
            requestId: productsDetail.data._id,
            seller_request_id: item.seller_request_id,
            seller_Id: item.sellerId,

            quantity:
              item.counterOffer && item.counterOffer.length
                ? item.counterOffer[item.counterOffer.length - 1].quantity
                : productsDetail.data.quantity,
            price:
              item.counterOffer && item.counterOffer.length
                ? item.counterOffer[item.counterOffer.length - 1].price
                : productsDetail.data.price,
            delivery_window_from: productsDetail.data.delivery_window_from,
            delivery_window_to: productsDetail.data.delivery_window_to,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setLoader(false);
          window.location = "/profile/myorder/processed";
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleBuyerCounter = (item) => {
    setOfferPopup(true);
    setSellerData(item);
    let token = localStorage.getItem("token");
    setLoader(true);
    if (userData && userData.userType) {
      axiosInstance
        .post(
          `/requestForQuote/buyer_counter_offer`,
          {
            requestId: productsDetail.data._id,
            seller_request_id: item.seller_request_id,
            seller_Id: item.sellerId,

            quantity:
              item.counterOffer && item.counterOffer.length
                ? item.counterOffer[0].quantity
                : productsDetail.data.quantity,
            price:
              item.counterOffer && item.counterOffer.length
                ? item.counterOffer[0].price
                : productsDetail.data.price,
            delivery_window_from: productsDetail.data.delivery_window_from,
            delivery_window_to: productsDetail.data.delivery_window_to,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setLoader(false);
          window.location = "/profile/myorder/processed";
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  console.log("productsDetail", productsDetail);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return completed ? (
      <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
        <p>Expires in:</p>
        <span style={{ color: "#ed9829" }}>
          <span>RFQ window is closed</span>
        </span>
      </div>
    ) : (
      <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
        <p>Expires in:</p>
        <span style={{ color: "#ed9829" }}>
          <span>
            {days}:{hours}:{minutes}:{seconds}
          </span>
          &nbsp; mins
        </span>
      </div>
    );
  };

  // Function to convert the relevant data into CSV
  const convertToCSV = (data) => {
    const headers = "Company Name,Quantity,Price,Payment Terms,Delivery,Comments\n";
    
    const rows = data.map(item => {
      const latestSellerOffer = item.counterOffer && item.counterOffer.length > 0
        ? item.counterOffer[item.counterOffer.length - 1]
        : {};

      const companyName = item.companyName || '';
      const quantity = latestSellerOffer.quantity || productsDetail.data.quantity || '';
      const price = latestSellerOffer.price || productsDetail.data.price || '';
      const paymentTerms = latestSellerOffer.paymentTerms || productsDetail.data.paymentTerms || '';
      const delivery = latestSellerOffer.delivery || productsDetail.data.delivery || '';
      const comments = latestSellerOffer.comments || '';

      return `${companyName},${quantity},₹${price},${paymentTerms},${delivery},${comments}`;
    }).join("\n");
    
    return headers + rows;
  };

  // Function to download the CSV file
  const downloadCSV = () => {
    if (!productsDetail || !productsDetail.data || !productsDetail.data.sellerData) return;

    const sellerData = productsDetail.data.sellerData.filter(
      (item) => item.isCounter || item.isSellerAccepted
    );

    const csv = convertToCSV(sellerData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "offers_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <div className="order-details order-detail-page">
      {loader ? <Loader message={"Please wait..."} /> : null}
      {productsDetail && productsDetail.data ? (
        <div className="multiple-items multiple-items-wrapper new-multiple-wrap">
          <div className="container">
            <div className="ordered-products order-product-wrp">
              <div className="order-left order-delivery-location">
                <div className="order-left-top">
                  <span className="order-title">Request for Quotation</span>
                </div>
              </div>
              <div className="offer-column">
                {/* <h5>Your offer</h5> */}
                <div
                  className="products-images products-images-wrapper pending-order"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div className="">
                    <div className="cart-item-detail">
                      <div className="value">
                        <div className="current-value">Name:</div>
                        <div className="new-value">
                          {productsDetail.data.productName}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Product type:</div>
                        <div className="new-value">
                          {productsDetail.data.type}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Date and time:</div>
                        <div className="new-value">
                          {moment(productsDetail.data.createdAt).calendar({
                            sameDay: "DD/MM/YYYY h:mm a",
                            nextDay: "DD/MM/YYYY h:mm a",
                            nextWeek: "DD/MM/YYYY h:mm a",
                            lastDay: "DD/MM/YYYY h:mm a",
                            lastWeek: "DD/MM/YYYY h:mm a",
                            sameElse: "DD/MM/YYYY h:mm a",
                          })}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Quantity:</div>
                        <div className="new-value">
                          {productsDetail.data.quantity}
                        </div>
                      </div>
                      {productsDetail.data.price ? (
                        <div className="value">
                          <div className="current-value">Offer price:</div>
                          <div className="new-value">
                            ₹{productsDetail.data.price}/mt
                          </div>
                        </div>
                      ) : null}

                      <div className="value">
                        <div className="current-value">Size:</div>
                        <div className="new-value">
                          {productsDetail.data.size}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Grade:</div>
                        <div className="new-value">
                          {productsDetail.data.grade}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Payment terms:</div>
                        <div className="new-value">
                          {productsDetail.data.paymentTerms}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Packaging:</div>
                        {productsDetail.data.packaging.map((ele, index) => {
                          return (
                            <div className="new-value" key={index}>
                              {ele}
                            </div>
                          );
                        })}
                      </div>
                      <div className="value">
                        <div className="current-value">Delivery:</div>
                        <div className="new-value">
                          {productsDetail.data.delivery}
                        </div>
                      </div>
                      <div className="value">
                        <div className="current-value">Delivery Window:</div>
                        <div className="new-value">
                          {moment(
                            productsDetail.data.delivery_window_from
                          ).format("Do MMM")}{" "}
                          -{" "}
                          {moment(
                            productsDetail.data.delivery_window_to
                          ).format("Do MMM")}
                        </div>
                      </div>
                    </div>
                    {productsDetail.data.commentBox ? (
                      <div className="value">
                        <div className="current-value">Additional Comment:</div>
                        <div className="new-value">
                          {productsDetail.data.commentBox}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className="action-value value-tab"
                    style={{
                      width: "100%",
                      maxWidth: "220px",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  >                   
                      <Countdown
                        date={
                          new Date(productsDetail.data.validUntil).getTime()
                        }
                        renderer={renderer}
                        zeroPadTime={2}
                      />
                      {productsDetail && productsDetail.data && productsDetail.data.productSpecs && <div
                        className="product-tble-wrp product-table-seller"
                        style={{ flexDirection: "column" }}
                      >                      
                        <table style={{marginTop: "20px"}}>
                          {Object.keys(productsDetail.data.productSpecs).map(function (key, index) {
                            let filteredLength = []
                            const value = productsDetail.data.productSpecs[key];

                            // Skip if the value contains "N/A"
                            // if (value.includes("N/A")) {
                            //   return null;
                            // }
                            let displayValue=0

                            // Determine how to handle ranges and single values
                            if (value.startsWith("N/A")) {
                              // Remove the key from the object
                              delete productsDetail.data.productSpecs[key];
                            } else {
                            
                              displayValue= value;
                            if (value.includes("-")) {
                              // If it's a range (like "14-16%"), don't show "max" or "min"
                              displayValue = value.replace(/(max|min|N\/A)/i, "").trim();
                            } else if (value.match(/^\d+(\.\d+)?%$/)) {
                              // If it's a single percentage, add "max" or "min" based on the key
                              displayValue = `${value} ${key.toLowerCase().includes("min") ? "min" : "max"}`;
                            }else {
                              displayValue = value.replace(/(N\/A)/i, "").trim();
                            }
                            filteredLength.push(value)
                          }

                            return Object.keys(filteredLength).length % 2 === 1 ? (
                              Object.keys(filteredLength).length - 1 === index &&
                                Object.keys(filteredLength).length > 2 ? (
                                <>
                                  {productsDetail.data.productSpecs[key] !== "% " && (
                                    <>
                                      <tr key={index}>
                                        <th>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                        <th>{displayValue}</th>
                                      </tr>
                                      <tr>
                                        <th
                                          style={{
                                            color: "transparent",
                                            borderRightColor: "transparent",
                                          }}
                                        >
                                          T
                                        </th>
                                        <th
                                          style={{
                                            color: "transparent",
                                            borderLeftColor: "transparent",
                                          }}
                                        >
                                          0
                                        </th>
                                      </tr>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {productsDetail.data.productSpecs[key] !== "% " && (
                                    <tr key={index}>
                                      <th>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                      <th>{displayValue}</th>
                                    </tr>
                                  )}
                                </>
                              )
                            ) : (
                              <>
                                {productsDetail.data.productSpecs[key] !== "% " && (
                                  <tr key={index}>
                                    <th>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                    <th>{displayValue}</th>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                        </table>
                      </div>}
                      
                  </div>

                  
                </div>
                {userData &&
                productsDetail &&
                productsDetail.data &&
                productsDetail.data.sellerData ? (
                  <div className="value" style={{ marginTop: "10px" }}>
                    {userData.userType === "Buyer" ? (
                      <div className="current-value">
                        Sellers received Request For Quote:
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="flex-list">
                      {productsDetail.data.sellerData.map((item, index) => {
                        return (
                          <div>
                            {userData.userType === "Buyer" ? (
                              <span key={item.sellerId}>
                                {item.companyName}
                                {/* {(item && item.is_primary  ? <h5>(preferred seller)</h5> : " ")} */}
                              </span>
                            ) : (
                              ""
                            )}

                            <div key={index} style={{display: "none"}}>
                              {userData.userType === "Seller" && item.sellerId === userData._id && (
                                <button
                                  className="date-wrp make-new-payment-btn"
                                  onClick={() => {
                                    setOfferPopup(true);
                                    setSellerData(item);
                                  }}
                                  ref={sellerCounterButtonRef}
                                >
                                  Counter
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {userData &&
            userData.userType === "Seller" &&
            productsDetail &&
            productsDetail.data &&
            productsDetail.data.sellerData &&
            !productsDetail.data.isAccepted ? (
              <div className="rfq-actions">
                {/* <button
                  className="date-wrp make-new-payment-btn pay-btn"
                  onClick={() => setOfferPopup(true)}
                >
                  Counter
                </button> */}
                {productsDetail.data && productsDetail.data.price ? (
                  <button
                    className="date-wrp make-new-payment-btn pay-btn"
                    onClick={handleSellerAccept}
                  >
                    Accept
                  </button>
                ) : null}
              </div>
            ) : null}
            {userData.userType === "Seller" ? (
              <RfqCounterOffersDetail
                prodId={productsDetail.data._id}
                sellerId={userData._id}
                userData={userData}
                handleSellerCounter={handleSellerCounter}
                handleSellerAccept={handleSellerAccept}
              />
            ) : (
              ""
            )}
            {userData &&
            userData.userType === "Buyer" &&
            productsDetail &&
            productsDetail.data &&
            (productsDetail.data.isCounter ||
              productsDetail.data.isAccepted) ? (
              <div className="ordered-products order-product-wrp">
                <div className="order-left order-delivery-location" style={{width: "100%", paddingRight: "0"}}>
                  <div className="order-left-top" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span className="order-title" style={{margin: "0"}}>Offers received</span>
                    <button onClick={downloadCSV} className="date-wrp make-new-payment-btn pay-btn" style={{ width: "150px" }}>
                      Download CSV
                    </button>
                  </div>

                </div>
                <div className="offer-column" style={{display: "flex", flexDirection: "column"}}> 
                  <div className="offers-got" style={{display: "contents"}}>
                    <ul className="offers-rfq-listing">
                      <li>
                        <div className="rfq-offers-details">
                          <div className="rfq-detail naming">
                            <h5>Seller Company Name</h5>
                          </div>
                          <div className="rfq-detail">
                            <h5>Quantity</h5>
                          </div>
                          <div className="rfq-detail">
                            <h5>Price</h5>
                          </div>
                          <div className="rfq-detail">
                            <h5>Payment Term</h5>
                          </div>
                          <div className="rfq-detail">
                            <h5>Delivery</h5>
                          </div>
                          <div className="rfq-detail">
                            <h5 style={{ width: "86px" }}>Action</h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul className="offers-rfq-listing">
                      {productsDetail &&
                      productsDetail.data &&
                      productsDetail.data.sellerData ? (
                        productsDetail.data.sellerData.map((item) => {
                          if (!item.isCounter && !item.isSellerAccepted)
                            return null;

                          // Get the latest offer (regardless of who it's from)
                          const latestOffer =
                            item.counterOffer && item.counterOffer.length > 0
                              ? item.counterOffer[item.counterOffer.length - 1]
                              : null;

                          // Filter the counter offers to get the ones from the seller
                          const sellerCounterOffers = item.counterOffer
                            ? item.counterOffer.filter(
                                (offer) => offer.offerFrom === "Seller"
                              )
                            : [];

                          // Get the latest seller offer (the last one in the filtered array)
                          const latestSellerOffer =
                            sellerCounterOffers.length > 0
                              ? sellerCounterOffers[
                                  sellerCounterOffers.length - 1
                                ]
                              : null;

                          return (
                            <li
                              key={item.sellerId}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  `/profile/rfq-offers/offer-detail/counter-offers-details/${prodId}/${item.sellerId}`
                                )
                              }
                            >
                              <div className="rfq-offers-details">
                                <div className="rfq-detail naming">
                                  <Link
                                    to={`/user-profile/${item.sellerId}`}
                                    className="user-prof-link"
                                  >
                                    <h6>{item.companyName}</h6>
                                    <p style={{ color: "gray" }}>
                                    <span style={{ color: "black" }}>Offered at:</span>{" "}
                                    {(() => {
                                      // Get the timestamp either from latestSellerOffer or productsDetail
                                      const timestamp = latestSellerOffer
                                        ? latestSellerOffer.offeredAt
                                        : productsDetail.data.offeredAt;

                                      // Check if timestamp exists, if not show "No date available"
                                      if (!timestamp) {
                                        return "No date available";
                                      }

                                      // Convert the timestamp to a readable date and time with AM/PM
                                      const dateObject = new Date(timestamp);
                                      const formattedDate = dateObject.toLocaleDateString(); // e.g., "10/24/2024"
                                      const formattedTime = dateObject.toLocaleTimeString([], { 
                                        hour: '2-digit', 
                                        minute: '2-digit', 
                                        hour12: true // Ensure AM/PM is shown
                                      });

                                      return `${formattedDate} ${formattedTime}`;
                                    })()}
                                  </p>
                                  </Link>
                                </div>

                                <div className="rfq-detail">
                                  <h6>
                                    {latestSellerOffer
                                      ? latestSellerOffer.quantity
                                      : productsDetail.data.quantity}
                                  </h6>
                                </div>

                                <div className="rfq-detail">
                                  <h6>
                                    ₹
                                    {latestSellerOffer
                                      ? latestSellerOffer.price
                                      : productsDetail.data.price}
                                  </h6>
                                </div>

                                <div className="rfq-detail">
                                  <h6>
                                    {latestSellerOffer
                                      ? latestSellerOffer.paymentTerms
                                      : productsDetail.data.paymentTerms}
                                  </h6>
                                </div>

                                <div className="rfq-detail">
                                  <h6>
                                    {latestSellerOffer
                                      ? latestSellerOffer.delivery
                                      : productsDetail.data.delivery}
                                  </h6>
                                </div>

                                <div className="rfq-detail">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleBuyerAccept(item);
                                    }}
                                    className="date-wrp make-new-payment-btn pay-btn"
                                    style={{}}
                                  >
                                    {item.isSellerAccepted ? 'Confirm' : "Accept"}
                                  </button>

                                  {/* Conditionally render the Counter button only if the latest offer is not from the buyer */}
                                  {latestOffer &&
                                    (
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleBuyerCounter(item);
                                        }}
                                        className="date-wrp make-new-payment-btn pay-btn"
                                      >
                                        Counter
                                      </button>
                                    )}
                                </div>
                              </div>

                              {latestSellerOffer &&
                                latestSellerOffer.comments && (
                                  <div className="rfq-comment" style={{display: "flex", justifyContent: "space-between"}}>
                                    <div>
                                      <h5>Additional comment:</h5>
                                      <p>{latestSellerOffer.comments}</p>
                                    </div>
                                    {item.isSellerAccepted ? <span style={{color: "green"}}>Seller has accepted your offer!</span> : ''}
                                  </div>
                                )}
                            </li>
                          );
                        })
                      ) : (
                        <h5>No offer received</h5>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ) : productsDetail &&
              productsDetail.data &&
              productsDetail.data.sellerData &&
              productsDetail.data.sellerData.counterOffer &&
              productsDetail.data.sellerData.counterOffer.length > 0 ? (
              <div className="ordered-products order-product-wrp">
                <div className="order-left order-delivery-location">
                  <div className="order-left-top">
                    <span className="order-title">Your offer</span>
                  </div>
                </div>
                <div className="offer-column">
                  {/* <h5>Your offer</h5> */}
                  <div className="offers-got">
                    <ul className="offers-rfq-listing">
                      <li>
                        <div className="rfq-offers-details">
                          <div className="rfq-detail naming">
                            <h5>Seller Name</h5>

                            <Link
                              to={`/user-profile/${productsDetail.data.sellerData._id}`}
                              className="user-prof-link"
                            >
                              <h6>
                                {productsDetail.data.sellerData.companyName}
                              </h6>
                            </Link>
                          </div>
                          <div className="rfq-detail">
                            <h5>Quantity</h5>
                            <h6>
                              {
                                productsDetail.data.sellerData.counterOffer[0]
                                  .quantity
                              }
                            </h6>
                          </div>
                          <div className="rfq-detail">
                            <h5>Price</h5>
                            {productsDetail.data.sellerData.counterOffer &&
                            productsDetail.data.sellerData.counterOffer[0] &&
                            productsDetail.data.sellerData.counterOffer[0]
                              .price ? (
                              <h6>
                                ₹
                                {
                                  productsDetail.data.sellerData.counterOffer[0]
                                    .price
                                }
                              </h6>
                            ) : null}
                          </div>
                          {/* <div className='rfq-detail'>
                                                                  <button className='date-wrp make-new-payment-btn pay-btn'>Accept</button>
                                                              </div> */}
                        </div>
                        <div className="rfq-comment">
                          <h5>Additional comment:</h5>
                          <p>
                            {productsDetail.data.sellerData.counterOffer &&
                              productsDetail.data.sellerData.counterOffer[0] &&
                              productsDetail.data.sellerData.counterOffer[0]
                                .comments}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}

            {userData &&
            userData.userType === "Buyer" &&
            !(
              (productsDetail &&
                productsDetail.data &&
                productsDetail.data.isCounter) ||
              productsDetail.data.isAccepted
            ) ? (
              <div className="ordered-products order-product-wrp">
                <div className="order-left order-delivery-location">
                  <div className="order-left-top">
                    <span className="order-title">Offers received</span>
                  </div>
                </div>
                <h5 style={{ textTransform: "none" }}>No offer received</h5>
              </div>
            ) : null}
            <div
              className="ordered-products order-product-wrp"
              style={{ marginTop: "20px", borderRadius: "10px" }}
            >
              <DeliveryAddress
                userData={userData}
                orderdetail={{
                  product_detail: {
                    user_address_details: productsDetail.data.address_data[0],
                    offerId: productsDetail.data._id,
                    createdDate: productsDetail.data.createdAt,
                    // seller_details: detailData.data[0].seller_data[0],
                    // buyer_details: detailData.data[0].buyerData,
                    // offerStatus: detailData.data[0].offerStatus
                  },
                  customer_name: productsDetail.data.address_data[0].full_name,
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {offerPopUp && (
        <CounterRfq
          setLoader={setLoader}
          setOfferPopup={setOfferPopup}
          productsDetail={productsDetail}
          sellerData={sellerData}
          userType={userData.userType}
        />
      )}
    </div>
  );
};

export default RfqDetail;
