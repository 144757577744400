import React, { useEffect, useState } from "react";
import Loader from "../Loader/index";
import { updateTrackState } from "../../store/actions/productDetails";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import DeliveryStep from "./deliveryStep";
import { withRouter } from "react-router-dom";

const ChangeTracking = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [trackDataError, setTrackDataError] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [trackData, setTrackData] = useState("");
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const selector = useSelector((state) => state);

  const [listOrderStatus, setListOrderStatus] = useState([
    "ProformaInvoice",
    "ReadyToShip",
    "GoodsDispatched",
    "GoodsReceived",
    "OrderComplete"
  ])
  const [numberOrderStatus, setNumberOrderStatus] = useState([])
  useEffect(()=>{
    let temp = []
    console.log("props.orderData.order_status_code",props.orderData.order_status_code)
    props.orderData.order_status_code.forEach((item)=>{
      temp=[...temp, item.order]
    })
    setNumberOrderStatus(temp)
  },[props.orderData.order_status_code])

  const handleOptions = (e) => {
    setTrackData(e.target.value);
    props.load();
  };


  const handleSubmit = () => {
    if (trackData == "") {
      setTrackDataError(true);
      return;
    } else {
      setShowLoader(true);
      setShowMessage("");
      const payload = {
        orderId: props.orderData._id,
        status: trackData,
      };
      dispatch(
        updateTrackState(payload, (status) => {
          if (status.status == "success") {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2000,
            });
            props.load();
            if (status.isCompleted == true) {
              setTimeout(() => {
                props.history.push("/profile/seller-order/completed");
              }, 1000);
            }
          } else {
            setShowLoader(false);
          }
        })
      );
    }
  };

  return (
    <>
      <div className="shipping-module-wrp">
        {showLoader ? <Loader message={showMessage} /> : null}
        {props.userType === "Seller" ? (
          <>
            {props.completeOrderdetail.orderStatusArr &&
              props.completeOrderdetail.orderStatusArr.length > 0 ? (
              <>
                <h2>Change Tracking Status</h2>
                <div className="form-wrapper-new submit-track-status">
                  <select
                    id="track"
                    onChange={(e) => handleOptions(e)}
                    className="submit-select-wrap"
                  >
                    <option value="">Select</option>
                    {props.completeOrderdetail.orderStatusArr &&
                      props.completeOrderdetail.orderStatusArr[0].name ==
                      "ProformaInvoice" ? (
                      <option value="ProformaInvoice">Proforma invoice</option>
                    ) : null}
                    {props.completeOrderdetail.orderStatusArr &&
                      props.completeOrderdetail.orderStatusArr[0].name ==
                      "ReadyToShip" ? (
                      <option value="ReadyToShip">Ready to ship</option>
                    ) : null}
                    {props.completeOrderdetail.orderStatusArr &&
                      props.completeOrderdetail.orderStatusArr[0].name ==
                      "GoodsDispatched" ? (
                      <option value="GoodsDispatched">Goods dispatched</option>
                    ) : null}
                    {props.completeOrderdetail.orderStatusArr &&
                      props.completeOrderdetail.orderStatusArr[0].name ==
                      "GoodsReceived" ? (
                      <option value="GoodsReceived">Goods received</option>
                    ) : null}
                    {props.completeOrderdetail.orderStatusArr &&
                      props.completeOrderdetail.orderStatusArr[0].name ==
                      "OrderComplete" ? (
                      <option value="OrderComplete">Order complete</option>
                    ) : null}
                  </select>
                  {trackDataError ? (
                    <span>Please select a track option</span>
                  ) : null}

                  <button
                    onClick={() => handleSubmit()}
                    className="tracking-status-btn"
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : null}
          </>
        ) : null}

        <h2>Track Status</h2>
        {Object.keys(props.completeOrderdetail).length !== 0 ? (
          <DeliveryStep
            allSteps={props.completeOrderdetail.all_order_status}
            completed={props.completeOrderdetail.Data.order_status_code}
            currentOrderStatus={props.completeOrderdetail.current_status}
            numberOrderStatus={numberOrderStatus}
            oldProps={props.completeOrderdetail}
          />
        ) : null}
      </div>
    </>
  );
};

export default withRouter(ChangeTracking);
