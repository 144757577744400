import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
import {Link} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const BuyerTestimonials = () => {
	let settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
			 breakpoint: 992,
			 settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			 },
			},
			
		 ]
	  };
	  useEffect(() => {
		const wow = new WOW.WOW({
		  live: true, // Enables the live mode to detect changes dynamically
		});
		wow.init();
	  }, []);

  return (
	<div className='show-testimonials'>
		<div className="container">
			<h4>Buyer Testimonials</h4>
			<div className="wow animate__animated animate__fadeInUp">
			<Slider {...settings}>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/basant.png" alt="Buyer-testimonials1" />
					</div>
					<div className="testimonial-details">
						<p>Metalsbuy changed the game for us. Their platform not only offers a wide range of metal raw material sellers but also gives us the power to negotiate prices in real-time. It's a game-changer!</p>
							<span className='buyer-name'><strong>Mr. Basant Agrawal</strong>Real Group</span>
					</div>
			</div>
          </div>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/pranaw.png" alt="Buyer-testimonials1" />
					</div>
					<div className="testimonial-details">
						<p>Metalsbuy is the real deal. It's not just a marketplace; it's a platform that prioritizes the needs of buyers like me. Negotiating prices has never been this easy.</p>
							<span className='buyer-name'><strong>Mr. Pranaw </strong>Shree Rupanadham Steels</span>
					</div>
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/basant.png" alt="Buyer-testimonials1" />
					</div>
					<div className="testimonial-details">
						<p>Metalsbuy changed the game for us. Their platform not only offers a wide range of metal raw material sellers but also gives us the power to negotiate prices in real-time. It's a game-changer!</p>
							<span className='buyer-name'><strong>Mr. Basant Agrawal</strong>Real Group</span>
					</div>
			</div>
          </div>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/pranaw.png" alt="Buyer-testimonials1" />
					</div>
					<div className="testimonial-details">
						<p>Metalsbuy is the real deal. It's not just a marketplace; it's a platform that prioritizes the needs of buyers like me. Negotiating prices has never been this easy.</p>
							<span className='buyer-name'><strong>Mr. Pranaw</strong> Shree Rupanadham Steels</span>
					</div>
			</div>
          </div>
        </Slider>
		</div>
		</div>
	</div>
  )
}

export default BuyerTestimonials