import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
import SupplierTestimonials from '../../Dashboard/SupplierTestimonials'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const Seller = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
          live: true, // Enables the live mode to detect changes dynamically
        });
        wow.init();
      }, []);

    return (
        <div className="seller-advantages">
            <div className="main-buyer">
            <div className="advantages-buyer">
                <img src="../../../assets/images/seller-advantage.jpg" alt="advantages-img" />
                <div className="container-main">
                <h3>ADVANTAGES TO SELLERS</h3>
                </div>
            </div>
            <div className="insights-block">
                <div className="container-main">
                    <Accordion>
                        <div className="accordion-left">
                        <AccordionItem className="wow animate__animated animate__fadeInUp">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/sellers1.png" alt="insights-img" />
                                <strong>GLOBAL REACH</strong>
                            </div>

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Join our extensive network of verified buyers, establishing
trust and credibility in the market. Expand your market
reach and connect with potential customers nationwide.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/sellers4.png" alt="insights-img" />
                                <strong>BOOST SALES</strong>
                            </div>

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Increase the visibility of your products nationwide through
our platform. This heightened exposure leads to a surge in
orders and faster sales turnovers for your business.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/sellers5.png" alt="insights-img" />
                                <strong>SUPPORT AT EVERY STEP</strong>
                            </div>

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Enjoy end-to-end customer support that covers every
aspect of your transactions. From the initial transaction to
post-delivery assistance, our support team is dedicated
to helping your business thrive and succeed.</p>
<p>Whether you are a buyer or a seller, our platform is
designed to provide you with a transparent, efcient, and
reliable trading experience, backed by real-time data,
quality assurance, and dedicated support.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                        </div>
                        <div className="accordion-right">
                        <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/sellers2.png" alt="insights-img" />
                                <strong>ON-TIME PAYMENTS</strong>
                            </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            We prioritize your business by ensuring guaranteed,
on-time payments. Receive comprehensive payment
notifications, providing financial peace of mind and
stability.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/sellers3.png" alt="insights-img" />
                                <strong>EFFICIENCY & SPEED</strong>
                            </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Streamline your negotiation process by easily viewing
and responding to multiple offers. Save valuable time and
effort, making your business operations more efcient.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                        </div>
                    </Accordion>
                </div>
            </div>
            <SupplierTestimonials />
        </div>
        </div>
    )
}

export default Seller