import React, { useState } from "react";
import "../../PrivacyPolicy/PrivacyPolicy.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import swal from 'sweetalert';

const JobDesc = () => {
  const [formsubmit, setFormsubmit] = useState(0);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      location: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("First Name is required"),
      lastname: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("A valid email address is required"),
      phone: Yup.string().required("Phone number is required"),
      location: Yup.string().required("Please enter your location "),
    }),

    onSubmit: async (values) => {
      setFormsubmit("submiting");
      swal("ok");
    }
  });

  return (
    <>
      <div className="current-opening">
        <div className="container">
          <div className="current-wrap">
            <h3>Current Openings</h3>
            <div className="opening-detail">
              <h4>Government Relations Director</h4>
              <p>Palo Alto, CA</p>
            </div>
            <div className="apply-form-wrap">
              <form onSubmit={formik.handleSubmit}>
                <div className="apply-form">
                  <div className="career-selectors">
                    <div className="career-select">
                      <div className="label-texts">Department</div>
                      <div className="slct-optns">
                        <div className="optns">
                          <Select
                            placeholder="All Department"
                            value="Dep"
                            //   onChange={this.selectDelivery}
                            //   styles={showErr && deliveryType === "" && customStyles}
                            classNamePrefix={"select-opt"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="career-select">
                      <div className="label-texts">Office</div>
                      <div className="slct-optns">
                        <div className="optns">
                          <Select
                            placeholder="All Office"
                            value="Dep"
                            //   onChange={this.selectDelivery}
                            //   styles={showErr && deliveryType === "" && customStyles}
                            classNamePrefix={"select-opt"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="linked-block">
                    <div className="apply-job">
                      <h4>Apply for this Job</h4>
                      <span className="req-text">
                        <span className="highlighted">*</span> Required
                      </span>
                    </div>
                    <p>Please sign in to apply with LinkedIn</p>
                    <a href="https://in.linkedin.com/" className="sign-linked" target="_blank">
                      Sign in with Linkedin
                    </a>
                  </div>
                  <div className="inputs-career">
                    <div className="dual-career">
                      <div className="career-input">
                        <label className="label-career">
                          First Name <span className="highlighted">*</span>
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          placeholder="First Name"
                          className="input-career"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstname}
                        />
                        {formik.touched.firstname && formik.errors.firstname ? (
                          <div className="text-danger">{formik.errors.firstname}</div>
                        ) : null}
                      </div>
                      <div className="career-input">
                        <label className="label-career">
                          Last Name <span className="highlighted">*</span>
                        </label>
                        <input
                          type="text"
                          id="lastname"
                          className="input-career"
                          placeholder="Last Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastname}
                        />
                        {formik.touched.lastname && formik.errors.lastname ? (
                          <div className="text-danger">{formik.errors.lastname}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="dual-career">
                      <div className="career-input">
                        <label className="label-career">
                          Email <span className="highlighted">*</span>
                        </label>
                        <input
                          type="text"
                          id="email"
                          className="input-career"
                          placeholder="Email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div className="career-input">
                        <label className="label-career">
                          Phone <span className="highlighted">*</span>
                        </label>
                        <input
                          type="text"
                          id="phone"
                          className="input-career"
                          placeholder="Phone"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="text-danger">{formik.errors.phone}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="career-input">
                      <label className="label-career">
                        Location (City) <span className="highlighted">*</span>
                      </label>
                      <input
                        type="text"
                        id="location"
                        className="input-career"
                        placeholder="Location"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.location}
                      />
                      {formik.touched.location && formik.errors.location ? (
                        <div className="text-danger">{formik.errors.location}</div>
                      ) : null}
                    </div>
                    <div className="career-files">
                      <div className="file-career">
                        <label className="label-career">
                          Resume/CV <span className="highlighted">*</span>
                        </label>
                        <button className="file-btn">Attach</button>
                      </div>
                      <div className="file-career">
                        <label className="label-career">
                          Cover Letter <span className="highlighted">*</span>
                        </label>
                        <button className="file-btn">Attach</button>
                      </div>
                    </div>
                    <div className="career-input">
                      <label className="label-career">LinkedIn Profile</label>
                      <input
                        type="text"
                        className="input-career"
                        placeholder="LinkedIn Profile"
                      />
                    </div>
                    <div className="career-input">
                      <label className="label-career">Website</label>
                      <input
                        type="text"
                        className="input-career"
                        placeholder="Website"
                      />
                    </div>
                    <div className="career-select full">
                      <div className="label-texts">
                        Are you authorized to work in the United States for any
                        employer?
                      </div>
                      <div className="slct-optns">
                        <div className="optns">
                          <Select
                            placeholder="--"
                            value="Dep"
                            //   onChange={this.selectDelivery}
                            //   styles={showErr && deliveryType === "" && customStyles}
                            classNamePrefix={"select-opt"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="career-select full">
                      <div className="label-texts">
                        Will you now or in the future require visa sponsorship?
                      </div>
                      <div className="slct-optns">
                        <div className="optns">
                          <Select
                            placeholder="--"
                            value="Dep"
                            //   onChange={this.selectDelivery}
                            //   styles={showErr && deliveryType === "" && customStyles}
                            classNamePrefix={"select-opt"}
                          />
                        </div>
                      </div>
                    </div>
                    <button className="submit-career">
                      Submit Application
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default JobDesc;
