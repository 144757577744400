import React from "react";
import { RupeesIcon } from "../../../../utils/constants";
import { isEmpty } from "lodash";

let d = new Date(); // for now
d.getHours(); // => 9
d.getMinutes(); // =>  30
d.getSeconds(); // => 51
const Statistics = (props) => {
  const [selectFilter, setSelectedFilter] = React.useState("All_time");
  React.useEffect(() => {
    if (!isEmpty(props.profileInfo && props.profileInfo.sortBy)) {
      setSelectedFilter(props.profileInfo.sortBy);
    }
  }, [props.profileInfo]);

  const { soldPrice, boughtPrice, productBought, productSold } = props.profileInfo;
  return (
    <div className="ac-statics">
      <div className="ac-head-wrap">
        <h4>Account statistics <span>Last updated {d.getHours() - 12}hr:{d.getMinutes()}min:{d.getSeconds()}sec</span></h4>
        <div className="sort-drop">
          <select
            className="my_class dashboard-my-class-dropdown"
            onChange={(e) => {
              setSelectedFilter(e.target.value);
              props.statisticsFilter(e.target.value);
            }}
          >
            {props.dropdown.map((i, j) => (
              <option
                value={i.value}
                key={j}
                selected={i.value === selectFilter ? true : false}
              >
                {i.key}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="plp-filters">

      </div>
      <div className="acc-info">
        <div className="info-wrap">
          <div className="account-desc info-block">
            <p className="total-txt">Product sold</p>
            <strong className="total-amount">
              {productSold ? productSold : 0}
            </strong>
          </div>
          <div className="account-desc info-block">
            <p className="total-txt">Product bought</p>
            <strong className="total-amount">
              {productBought ? productBought : 0}
            </strong>
          </div>
          <div className="account-desc info-block">
            <p className="total-txt">Sold price</p>
            <strong className="total-amount">
              {RupeesIcon}
              {soldPrice ? soldPrice : 0}
            </strong>
          </div>
          <div className="account-desc info-block">
            <p className="total-txt">Bought price</p>
            <strong className="total-amount">
              {RupeesIcon}
              {boughtPrice ? boughtPrice : 0}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Statistics;
