import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams, useLocation  } from 'react-router-dom'
import { axiosInstance } from '../../utils/Service'
import OrderDetails from '../CheckOut/OrderDetails'
import moment from 'moment'
import AddressAdd from '../CheckOut/Shipping/AddressAdd'
import ShippingAddressMakeOffer from '../CheckOut/Shipping/ShippingAddressMakeOffer'
import Loader from '../Loader'
import { useSelector } from 'react-redux'
import Multiselect from 'multiselect-react-dropdown'
import { useToasts } from 'react-toast-notifications'
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const MakeRfq = (props) => {
    const options = [
        { label: "All", value: "*" },
        { label: "Seller 1", value: "Seller 1" },
        { label: "Seller 2", value: "Seller 2" },
        { label: "Seller 3", value: "Seller 3" },
    ];


    const [selectedSeller, setSelectedSeller] = useState([]);

    const location = useLocation();
    const userData = useSelector(store => store.userProfile.userProfileBanner.userdata);
    const userDataMain = useSelector(store => store.userProfile.userProfileBanner);
    const [payOptions, setPayOptions] = useState([
        { id: 1, name: "Advance Payment" },
        { id: 2, name: "Next Day" },
        { id: 3, name: "7 Day Credit" },
        { id: 4, name: "Other" },
    ])
    let dFList = [
        { id: 1, name: "FOR" },
        { id: 2, name: "EXW" },
    ];
    const [addressList, setAddressList] = useState(null)
    const [loader, setLoader] = useState(false)


    const [qty, setQty] = useState({ value: "" })
    const [validUntil, setValidUntil] = useState("")
    const [price, setPrice] = useState({ value: "" })
    const [payTerms, setPayTerms] = useState("")
    const [commentBox, setCommentBox] = useState("")
    const [addressId, setAddressId] = useState("")
    const [size, setSize] = useState("")
    const [delivery, setDelivery] = useState("")
    const [deliveryFrom, setDeliveryFrom] = useState("")
    const [deliveryTo, setDeliveryTo] = useState("")
    const [packaging, setPackaging] = useState([])

    const [grade, setGrade] = useState("")
    const [name, setName] = useState("")
    const [type, setType] = useState("")

    const [qtyError, setQtyError] = useState(false)
    const [validUntilError, setValidUntilError] = useState(false)
    const [qtyMoqError, setQtyMoqError] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [payTermsError, setPayTermsError] = useState(false)
    const [sizeError, setSizeError] = useState(false)
    const [deliveryError, setDeliveryError] = useState(false)
    const [deliveryFromError, setDeliveryFromError] = useState(false)
    const [deliveryToError, setDeliveryToError] = useState(false)
    const [packagingError, setPackagingError] = useState(false)

    const [sellerError, setSellerError] = useState(false)
    const [gradeError, setGradeError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [typeError, setTypeError] = useState(false)
    const [productSpecificationError, setProductSpecificationError] =
    useState(false);

    const [productList, setProductList] = useState(null)

    const [optionsSeller, setOptionsSeller] = useState([])

    /////////////////// Specification //////////////////
    const [siOne, setSiOne] = useState("");
    const [siTwo, setSiTwo] = useState("");
    const [pOne, setPOne] = useState("");
    const [pTwo, setPTwo] = useState("");
    const [moOne, setMoOne] = useState("");
    const [moTwo, setMoTwo] = useState("");

    const [mnOne, setMnOne] = useState("");
    const [mnTwo, setMnTwo] = useState("");
    const [sOne, setSOne] = useState("");
    const [sTwo, setSTwo] = useState("");
    const [aiOne, setAiOne] = useState("");
    const [aiTwo, setAiTwo] = useState("");

    const [cOne, setCOne] = useState("");
    const [cTwo, setCTwo] = useState("");
    const [crOne, setCrOne] = useState("");
    const [crTwo, setCrTwo] = useState("");
    const [mgOne, setMgOne] = useState("");
    const [mgTwo, setMgTwo] = useState("");
    const [feOne, setFeOne] = useState("");
    const [feTwo, setFeTwo] = useState("");
    const [caOne, setCaOne] = useState("");
    const [caTwo, setCaTwo] = useState("");

    const { propsId } = location.state || {};

    const { addToast } = useToasts();

    const siOneHandle = (e) => {
        setSiOne(e.target.value);
        setProductSpecificationError(false);
      };
      const siTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setSiTwo(e.target.value);
      };
    
      const pOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        const value = e.target.value;
    
        if (regex.test(value) || value === "" || pTwo === "N/A") {
          setProductSpecificationError(false);
          setPOne(value);
        }
      };
    
      const pTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setPTwo(e.target.value);
      };
      const moOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || moTwo == "N/A") {
          setProductSpecificationError(false);
          setMoOne(e.target.value);
        }
      };
      const moTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setMoTwo(e.target.value);
      };
      const mnOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || mnTwo == "N/A") {
          setProductSpecificationError(false);
          setMnOne(e.target.value);
        }
      };
      const mnTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setMnTwo(e.target.value);
      };
      const sOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || sTwo == "N/A") {
          setProductSpecificationError(false);
          setSOne(e.target.value);
        }
      };
      const sTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setSTwo(e.target.value);
      };
      const aiOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || aiTwo == "N/A") {
          setProductSpecificationError(false);
          setAiOne(e.target.value);
        }
      };
      const aiTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setAiTwo(e.target.value);
      };
      const cOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || cTwo == "N/A") {
          setProductSpecificationError(false);
          setCOne(e.target.value);
        }
      };
      const cTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setCTwo(e.target.value);
      };
      const crOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || crTwo == "N/A") {
          setProductSpecificationError(false);
          setCrOne(e.target.value);
        }
      };
      const crTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setCrTwo(e.target.value);
      };
      const mgOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || mgTwo == "N/A") {
          setProductSpecificationError(false);
          setMgOne(e.target.value);
        }
      };
    
      const mgTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setMgTwo(e.target.value);
      };
    
      const feOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || feTwo == "N/A") {
          setProductSpecificationError(false);
          setFeOne(e.target.value);
        }
      };
    
      const feTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setFeTwo(e.target.value);
      };
    
      const caOneHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        if (regex.test(e.target.value) || caTwo == "N/A") {
          setProductSpecificationError(false);
          setCaOne(e.target.value);
        }
      };
    
      const caTwoHandle = (e) => {
        var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
        setProductSpecificationError(false);
        setCaTwo(e.target.value);
      };

    useEffect(()=>{
        if(userDataMain && false){
            window.location = "/"
        }
    },[userDataMain])

    useEffect(() => {
        getAddressHandle()
        getProductNames()
        getSellerNames()
    }, [])

    useEffect(() => {
        if (location.state) {
          const deliveryDateFrom = location.state.deliveryDateFrom;
          const deliveryDateTo = location.state.deliveryDateTo;
      
          const formattedDateFrom = deliveryDateFrom ? new Date(deliveryDateFrom).toISOString().split('T')[0] : '';
          const formattedDateTo = deliveryDateTo ? new Date(deliveryDateTo).toISOString().split('T')[0] : '';
      
          setSize(location.state.productSize);
          setName(location.state.productName);
          setType(location.state.categoryData.meta_tag);
          setDelivery(location.state.deliveryType);
          setDeliveryFrom(formattedDateFrom);
          setDeliveryTo(formattedDateTo);
          setPrice({ value: location.state.productPrice.$numberDecimal || 0 });
          setGrade(location.state.productGrade);
          setPayTerms(location.state.paymentType);
        }
      }, [location.state]);
      
      const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        if (value && value.some((o) => o.value === "*")) {
          return `${placeholderButtonLabel}: All`;
        } else if (value.length > 0) {
          return value.map((option) => option.label).join(", ");
        } else {
          return placeholderButtonLabel;
        }
      };
      

    const onChange = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            setSelectedSeller(optionsSeller);
        }
        else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setSelectedSeller([]);
        } else if (event.action === "deselect-option") {
            setSelectedSeller(value.filter((o) => o.value !== "*"));
        } else if (value.length === optionsSeller.length - 1) {
            setSelectedSeller(optionsSeller);
        } else {
            setSelectedSeller(value);
        }
    }

    const getProductNames = () => {
        setLoader(true)
        let token = localStorage.getItem("token");
        axiosInstance.get('/product/allProductNames', {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setProductList(res.data)
            setLoader(false)
        }).catch(() => {
            setLoader(false)
        });
    }

    const getSellerNames = () => {
        setLoader(true)
        let token = localStorage.getItem("token");
        axiosInstance.get('/requestForQuote/seller_list', {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            let data = []
            res.data.data.forEach((item) => {
                data = [...data, { ...item, label: item.companyName, sellerId: item._id, value: item.companyName }]
            })
            setOptionsSeller([{ label: "All", value: "*" }, ...data])
            setLoader(false)
        }).catch(() => {
            setLoader(false)
        });
    }

    const getAddressHandle = () => {
        setLoader(true)
        let token = localStorage.getItem("token");
        axiosInstance.post('/auth/getuserAddress', {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setAddressList(res.data)
            setLoader(false)
        }).catch(() => {
            setLoader(false)
        });
    }

    const handleQty = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setQty(qty => value.length <= 10 && !isNaN(Number(value)) && { value } || qty)
        setQtyError(false);
        setQtyMoqError(false)
    }

    const handleValidUntil = (event) => {
        let value = event.target.value
        console.log(value)
        setValidUntil(value)
        setValidUntilError(false);
    }

    const handlePrice = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPrice(price => value.length <= 10 && !isNaN(Number(value)) && { value } || price)
        setPriceError(false)
    }

    const handleMakeOffer = () => {
        if (name.length === 0) {
            setNameError(true)
            return
        }
        else if (type.length === 0) {
            setTypeError(true)
            return
        }
        else if (qty.value.length === 0) {
            setQtyError(true)
            return
        }
        else if (validUntil.length === 0 && location.pathname==="/make-rfq-offer") {
            setValidUntilError(true)
            return
        }
        else if (size.length === 0) {
            setSizeError(true)
            return
        }
        else if (delivery.length === 0) {
            setDeliveryError(true)
            return
        }
        else if (grade.length === 0) {
            setGradeError(true)
            return
        }
        else if (selectedSeller.length === 0 && location.pathname==="/make-rfq-offer") {
            setSellerError(true)
            return
        }
        else if (deliveryFrom.length === 0) {
            setDeliveryFromError(true)
            return
        }
        else if (deliveryTo.length === 0) {
            setDeliveryToError(true)
            return
        }
        else if (packaging.length === 0) {
            setPackagingError(true)
            return
        }
        else if (payTerms.length === 0) {
            setPayTermsError(true)
            return
        }
        else if (
            siTwo == "" &&
            pTwo == "" &&
            moTwo == "" &&
            mnTwo == "" &&
            sTwo == "" &&
            aiTwo == "" &&
            cTwo == "" &&
            crTwo == "" &&
            mgTwo == "" &&
            feTwo == "" &&
            caTwo == ""
          ) {
            setProductSpecificationError(true);
            return
        } else if (siTwo !== "" && siOne == "" && siTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (pTwo !== "" && pOne == "" && pTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (moTwo !== "" && moOne == "" && moTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (mnTwo !== "" && mnOne == "" && mnTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (sTwo !== "" && sOne == "" && sTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (aiTwo !== "" && aiOne == "" && aiTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (cTwo !== "" && cOne == "" && cTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (crTwo !== "" && crOne == "" && crTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (mgTwo !== "" && mgOne == "" && mgTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (feTwo !== "" && feOne == "" && feTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        } else if (caTwo !== "" && caOne == "" && caTwo !== "N/A") {
            setProductSpecificationError(true);
            return
        }

        let token = localStorage.getItem("token");
        setLoader(true)
        let dummyPackaging = []
        packaging.forEach((item) => {
            dummyPackaging.push(item.name)
        })
        const selectedSellerData = [...selectedSeller]
        if(selectedSeller.length === 0 && location.pathname==="/make-rfq-offer"){
         if (selectedSellerData[0].label === "All") {
            selectedSellerData.splice(0, 1)
         }
        }
        let productNameById = ""
        productList && productList.data.forEach((item) => {
            if (name === item.name) {
                productNameById = item._id
            }
        })

        
        axiosInstance.post('/requestForQuote/request_quote',
            {
                "productName": name,
                companyData: props.location.state ? props.location.state.companyData: {companyName: "companyName"},
                productNameById: productNameById,
                commentBox: commentBox,
                "type": type,
                "quantity": Number(qty.value),
                validUntil: validUntil,
                "grade": grade,
                "paymentTerms": payTerms,
                "delivery": delivery,
                "size": size,
                "delivery_window_from": deliveryFrom,
                "delivery_window_to": deliveryTo,
                "addressId": addressId,
                "packaging": dummyPackaging,
                "sellerData": selectedSellerData,
                // "sellerData": [{
                //     "sellerId": "595a3999a9d815d56a9d3580",
                //     "companyName": "Seller company pvt. ltd.",
                //     "full_name": "Seller Seller"
                // }],
                price: Number(price.value),
                si: siOne ? siOne + "% " + siTwo : "N/A",
                p: pOne ? pOne + "% " + pTwo : "N/A",
                mo: moOne ? moOne + "% " + moTwo : "N/A",
                mn: mnOne ? mnOne + "% " + mnTwo : "N/A",
                s: sOne ? sOne + "% " + sTwo : "N/A",
                al: aiOne ? aiOne + "% " + aiTwo : "N/A",
                c: cOne ? cOne + "% " + cTwo : "N/A",
                cr: crOne ? crOne + "% " + crTwo : "N/A",
                mg: mgOne ? mgOne + "% " + mgTwo : "N/A",
                fe: feOne ? feOne + "% " + feTwo : "N/A",
                ca: caOne ? caOne + "% " + caTwo : "N/A",
            },
            // {
            //     "productName": "new pro 66sssssssssssss",
            //     "type": "test type",
            //     "quantity": 55,
            //     "grade": "new grade",
            //     "paymentTerms": "new paymentTerms",
            //     "delivery": "new delivery",
            //     "size": "new size",
            //     "delivery_window_from": "new delivery",
            //     "delivery_window_to": "dd",
            //     "addressId": "64772989ca55cd7aa69371c9",
            //     "packaging": ["new packaging"],
            //     "sellerData": [{
            //             "sellerId": "595a3999a9d815d56a9d3580",
            //             "companyName": "Seller company pvt. ltd.",
            //             "full_name": "Seller Seller"
            //         }]
            // },
            {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            }).then((response) => {
                setLoader(false)
                window.location = "/profile/rfq-offers/active"
            }).catch(() => {
                setLoader(false)
            });
    }

    let cList = [
        { id: "6304d4d848bf984c711e22b1", name: "lumps" },
        { id: "6304d69b48bf984c711e22d0", name: "chips" },
        { id: "6304d6ef48bf984c711e22ef", name: "fines" },
    ];



    return (
        <div className="whitebg">
            {loader ? (
                <Loader message={"Please wait..."} />
            ) : null}
            <div className="container">
                <div className="main-page">
                    <div className="nav-content">
                        <Link to="/" className="home-link">
                            Home
                        </Link>
                        <span className="page-name">/ Request For Quote (RFQ)</span>
                    </div>
                    <div className="checkout-caption">
                        <h2>Request For Quote (RFQ)</h2>
                    </div>
                    <div className="checkout-wrap new-checkout-wrapper">
                        <div className="checkout-right">
                            <div className='summary-section checkout-summary'>
                                <div className='checkout-cardwrap'>
                                    <div className='cart-product-main-wrap'>
                                        <div className='cart-wrp-main'>
                                            <div className='cart-page-address' style={{ padding: "0" }}>
                                                <div className="login-description w-100 login-description-form-wrap address-field-wrap upload-document-wrapper place-order-address">
                                                    <form>
                                                        <div className="address-field-new-wrap">

                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className="input-form-wrap select-affilation-profile address-name-wrap">
                                                                    <label className="enter-mobile-label">
                                                                        Product Name*
                                                                    </label>
                                                                    <div className="select-main-wrapper">
                                                                        <select className='make-offer-select-tag' placeholder='Select name' value={name} onChange={(e) => { setName(e.target.value); setNameError(false) }}>
                                                                            <option hidden>Select name</option>
                                                                            {productList && productList.data && productList.data.map((item, index) => {
                                                                                return <option key={index} value={item.name}>{item.name}</option>
                                                                            })}
                                                                        </select>
                                                                        {/* <i className="icon-arrow-down"></i> */}
                                                                    </div>
                                                                    {nameError ? <p className='error'>Please select product name</p> : null}
                                                                </div>
                                                                <div className="input-form-wrap select-affilation-profile address-name-wrap">
                                                                    <label className="enter-mobile-label">
                                                                        Product Type*
                                                                    </label>
                                                                    <div className="select-main-wrapper">
                                                                        <select className='make-offer-select-tag' value={type} onChange={(e) => { setType(e.target.value); setTypeError(false) }}>
                                                                            <option hidden>Select Product Type</option>
                                                                            {cList.map((item, index) => {
                                                                                return <option key={index} value={item.name}>{item.name}</option>
                                                                            })}
                                                                        </select>
                                                                        {/* <i className="icon-arrow-down"></i> */}
                                                                    </div>
                                                                    {typeError ? <p className='error'>Please select product type</p> : null}
                                                                </div>
                                                            </div>

                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Quantity (mt)*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Enter Quantity"
                                                                            value={qty.value}
                                                                            onChange={handleQty}
                                                                        />
                                                                    </div>
                                                                    {qtyError ? <p className='error'>Please enter quantity</p> : null}
                                                                    {qtyMoqError ? <p className='error'>You cannot enter quantity less than minimum order quantity</p> : null}
                                                                </div>
                                                                {location.pathname!=="/make-rfq-offer"? 
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Price (INR)
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Enter Price"
                                                                            value={price.value}
                                                                            onChange={handlePrice}
                                                                        />
                                                                    </div>
                                                                    {priceError ? <p className='error'>Please enter price</p> : null}
                                                                </div>: ''}
                                                                
                                                            </div>


                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Size (mm)*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Enter Size"
                                                                            value={size}
                                                                            onChange={(e) => { setSize(e.target.value); setSizeError(false) }}
                                                                        />
                                                                    </div>
                                                                    {sizeError ? <p className='error'>Please enter size</p> : null}
                                                                </div>
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Delivery*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <select
                                                                            className='make-offer-select-tag'
                                                                            value={delivery}
                                                                            onChange={(e) => { setDelivery(e.target.value); setDeliveryError(false) }}
                                                                        >
                                                                            <option hidden>Select Delivery</option>
                                                                            {dFList.map((item, index) => {
                                                                                return <option key={index} value={item.name}>{item.name}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    {deliveryError ? <p className='error'>Please select delivery</p> : null}
                                                                </div>
                                                            </div>


                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Product Grade*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="e.g. 60/14, 30-60"
                                                                            value={grade}
                                                                            onChange={(e) => { setGrade(e.target.value); setGradeError(false) }}
                                                                        />
                                                                    </div>
                                                                    {gradeError ? <p className='error'>Please enter grade</p> : null}
                                                                </div>
                                                                {(location.pathname=="/make-rfq-offer")?
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Select Seller*
                                                                    </label>
                                                                    <div className="form-input multi-seller-select">
                                                                        {optionsSeller && optionsSeller.length > 1 ? <ReactMultiSelectCheckboxes
                                                                            options={optionsSeller}
                                                                            placeholderButtonLabel="Sellers"
                                                                            value={selectedSeller}
                                                                            onChange={onChange}
                                                                            setState={setSelectedSeller}
                                                                            getDropdownButtonLabel={getDropdownButtonLabel}

                                                                        /> : null}
                                                                    </div>
                                                                    {sellerError ? <p className='error'>Please select seller</p> : null}
                                                                </div>:null}
                                                            
                                                            </div>
                                                            {location.pathname === '/make-rfq-offer' ? 
                                                            <div className="product-spec-wrapper">
                                                            <label className="enter-mobile-label">
                                                                Product Specification (%){" "}
                                                                <span className="highlighted">*</span>
                                                            </label>
                                                            <div className="metal-table-structure">
                                                                <div className="metal-table-products">
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">Si (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => siOneHandle(e)}
                                                                        value={siOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => siTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={siTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={siTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => siTwoHandle(e)} value={siTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">P (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => pOneHandle(e)}
                                                                        value={pOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => pTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={pTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={pTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => pTwoHandle(e)} value={pTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">Mo (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => moOneHandle(e)}
                                                                        value={moOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => moTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={moTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={moTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => moTwoHandle(e)} value={moTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>

                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">Fe (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => feOneHandle(e)}
                                                                        value={feOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => feTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={feTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={feTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => mgTwoHandle(e)} value={mgTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div className="metal-table-products">
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">Mn (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => mnOneHandle(e)}
                                                                        value={mnOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => mnTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={mnTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={mnTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => mnTwoHandle(e)} value={mnTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">S (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => sOneHandle(e)}
                                                                        value={sOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => sTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={sTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={sTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => sTwoHandle(e)} value={sTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">AI (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => aiOneHandle(e)}
                                                                        value={aiOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => aiTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={aiTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={aiTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => aiTwoHandle(e)} value={aiTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">Ca (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => caOneHandle(e)}
                                                                        value={caOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => caTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={caTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={caTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => mgTwoHandle(e)} value={mgTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div className="metal-table-products">
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">C (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => cOneHandle(e)}
                                                                        value={cOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => cTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={cTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={cTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => cTwoHandle(e)} value={cTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">Cr (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => crOneHandle(e)}
                                                                        value={crOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => crTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={crTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={crTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => crTwoHandle(e)} value={crTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product">Mg (%)</div>
                                                                    <div className="metal-table-inputs">
                                                                        <input
                                                                        className="metal-input-table"
                                                                        placeholder="0"
                                                                        type="text"
                                                                        onChange={(e) => mgOneHandle(e)}
                                                                        value={mgOne}
                                                                        />
                                                                        <select
                                                                        className="metal-input-table select-m"
                                                                        onChange={(e) => mgTwoHandle(e)}
                                                                        >
                                                                        <option disabled={true} selected={true}>
                                                                            Select
                                                                        </option>
                                                                        <option value="N/A">N/A </option>
                                                                        <option
                                                                            selected={mgTwo == "max" ? true : false}
                                                                            value="max"
                                                                        >
                                                                            Max
                                                                        </option>
                                                                        <option
                                                                            selected={mgTwo == "min" ? true : false}
                                                                            value="min"
                                                                        >
                                                                            Min
                                                                        </option>
                                                                        </select>
                                                                        {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => mgTwoHandle(e)} value={mgTwo} /> */}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metal-table-panel">
                                                                    <div className="metal-table-line">
                                                                    <div className="metal-heading-product"></div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                                {productSpecificationError ? (
                                                                    <span className="error">
                                                                    Please enter atleast one specification
                                                                    </span>
                                                                ) : null}
                                                            </div>: ''}
                                                            
                        
                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Delivery Window*
                                                                    </label>
                                                                    <div className="form-input" style={{ position: "relative" }}>
                                                                        <input
                                                                            type="date"
                                                                            // placeholder="Enter Size"
                                                                            value={deliveryFrom}
                                                                            onChange={(e) => { setDeliveryFrom(e.target.value); setDeliveryFromError(false) }}
                                                                        />
                                                                    </div>
                                                                    {deliveryFromError ? <p className='error'>Please select delivery date</p> : null}
                                                                </div>
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap' style={{ display: "flex", alignItems: "flex-end" }}>
                                                                    <div className="form-input" style={{ width: "100%", position: "relative" }}>
                                                                        <input
                                                                            type="date"
                                                                            // placeholder="Enter Size"
                                                                            value={deliveryTo}
                                                                            onChange={(e) => { setDeliveryTo(e.target.value); setDeliveryToError(false) }}
                                                                        />
                                                                    </div>
                                                                    {deliveryToError ? <p className='error'>Please select delivery date</p> : null}
                                                                </div>
                                                            </div>



                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className="input-form-wrap select-affilation-profile address-name-wrap">
                                                                    <label className="enter-mobile-label">
                                                                        Select payment terms*
                                                                    </label>
                                                                    <div className="select-main-wrapper">
                                                                        <select className='make-offer-select-tag' value={payTerms} onChange={(e) => { setPayTerms(e.target.value); setPayTermsError(false) }}>
                                                                            <option hidden>Select payment terms</option>
                                                                            {payOptions.map((item, index) => {
                                                                                return <option key={index} value={item.name}>{item.name}</option>
                                                                            })}
                                                                        </select>
                                                                        {/* <i className="icon-arrow-down"></i> */}
                                                                    </div>
                                                                    {payTermsError ? <p className='error'>Please select payment terms</p> : null}
                                                                </div>
                                                                <div className="form-block-wrap input-form-wrap select-affilation-profile address-name-wrap" style={{ marginBottom: "0" }}>
                                                                    <label className="enter-mobile-label">
                                                                        Packaging*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <div className="input-form-wrap select-affilation-profile form-block-wrap" style={{ marginBottom: "0" }}>
                                                                            <div className="form-intput select-affilation-wrap complete-profile-select" style={{ marginBottom: "0" }}>
                                                                                <div className="select-main-wrapper make-offer-multi-select">
                                                                                    <Multiselect
                                                                                        id="state"
                                                                                        options={[
                                                                                            {
                                                                                                name: "40 kg bag"
                                                                                            },
                                                                                            {
                                                                                                name: "50 kg bag"
                                                                                            },
                                                                                            {
                                                                                                name: "1 MT Jumbo bag"
                                                                                            }
                                                                                        ]} // Options to display in the dropdown
                                                                                        selectedValues={packaging} // Preselected value to persist in dropdown
                                                                                        onSelect={(e) => { setPackaging(e); setPackagingError(false) }} // Function will trigger on select event
                                                                                        onRemove={(e) => { setPackaging(e); setPackagingError(false) }} // Function will trigger on remove event
                                                                                        displayValue="name" // Property name to display in the dropdown options
                                                                                    />
                                                                                    <i className="icon-arrow-down"></i>
                                                                                </div>
                                                                                {packagingError ? <p className='error'>Please select packaging</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {location.pathname=="/make-rfq-offer"? <div className="names-wrapper form-block-wrap">
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Valid until*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="datetime-local"
                                                                            value={validUntil}
                                                                            onChange={handleValidUntil}
                                                                            min={new Date().toISOString().slice(0, 16)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    {validUntilError ? <p className='error'>Please select a valid date and time</p> : null}
                                                                </div>

                                                                
                                                            </div>: ''}
                                                            

                                                            <div className="form-block-wrap">
                                                                <label className="enter-mobile-label">
                                                                    Comment Box
                                                                </label>
                                                                <div className="form-input">
                                                                    <textarea maxLength={"350"} onChange={(e) => { setCommentBox(e.target.value) }} placeholder='Enter comment'></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-btn-wrap" style={{ marginTop: "0" }}>
                                <div className="payment-btn">
                                    <button className="proceed-payment" type='button' onClick={handleMakeOffer}>
                                        Create Request For Quote
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-left ">
                            <div className="head-wrap checkout-wrap">
                                <span className="checkout-headings">Delivery Location</span>
                            </div>
                            {addressList && addressList.data && <ShippingAddressMakeOffer
                                addressList={addressList.data}
                                cartData={
                                    []
                                }
                                updateShippingCost={(a, b, val) => { setAddressId(val) }}
                                checkLocalOrNot={() => { return true }}
                                getAddressHandle={getAddressHandle}
                            />}

                            <AddressAdd getAddressHandle={getAddressHandle} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MakeRfq