import { act } from "react-dom/test-utils";
import { SET_USER_DATA } from "../actionsTypes";

const InitialState = {};

const userDataDEtail = (state = InitialState, action)=>{
 switch (action.type){
    case SET_USER_DATA:
    return {...state,userDataDEtail: action.payload}
 }
 return state
}
export default userDataDEtail;

