import { SAVE_CMS_DATA, SAVE_FOOTER_SECTION } from "./../actionsTypes";
const initialState = {
  cms_list_array: {},
  section: {},
  address: [],
  siteData:""
};

const save_array = (state, payload) => {
  let new_state = { ...state };
  new_state = {
    ...state,
    cms_list_array: {
      ...state.cms_list_array,
      [Object.keys(payload)]: Object.values(payload),
    },
  };
  return { ...new_state };
};

const cms_data = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CMS_DATA:
      return save_array(state, action.payload);
    case SAVE_FOOTER_SECTION:
      return {
        ...state,
        address: action.payload.address,
        section: {
          section_data: action.payload.section_data,
          subSection_data: action.payload.subSection_data,
        },
        siteData: action.payload.siteData,
        ...action.payload.siteData
      };
    default:
      return state;
  }
};

export default cms_data;
