import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

function Partner(props) {
  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="brand-partner-section">
        <div className="container-main">
          <h3>Our Partners</h3>

          <div className="partner-wrapper">
            {Object.keys(props.data).length !== 0
              ? props.data.partners.map((item) => {
                  return (
                    <div className="brand-img-wrap">
                      <img
                        src={
                          item.shop_image == ""
                            ? "http://45.65.41.90:6089/public/images/default.jpg"
                            : item.shop_image
                        }
                        alt="brand-img"
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Partner;
