import { axiosInstance } from './../../utils/Service'
import { apiUrl } from './../../utils/urlEndpoints'
import {
    SET_BLOG_LIST,
    SET_BLOG_DETAILED_DATA,
    SET_BLOG_CATEGORY_LIST,
    SET_RELATED_BLOG_LIST,
    SET_BLOG_ARCHIVES_LIST,
    SET_BLOG_CREATE_NEW_COMMENT,
} from './../actionsTypes'

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
export const getBlogList = (payload, status) => {
    return dispatch => {
        return axiosInstance.post(apiUrl.blog.getAllBlog, payload)
            .then(response => {
                dispatch(setBlogList(response.data))
                if (status) {
                    return status(false)
                }
            })
            .catch(err => {
                dispatch(setBlogList({ data: [], filePath: '' }));
                if (status) { status(false) }
            })
    }
}

export const setBlogList = (data) => {
    return {
        type: SET_BLOG_LIST,
        payload: data,
    }
}

export const likeBlog = async (payload, status) => {
    try {
        const response = await axiosInstance.post(apiUrl.blog.likeBlog, payload)
        if (status) {
            return status(response.data.message)
        }
    } catch (err) {
        if (status) { status(false) }
    }
}
export const getBlogArchives = () => {
    return dispatch => {
        return axiosInstance.get(apiUrl.blog.blogArchive)
            .then(response => {
                dispatch(setArchiveList(response.data.data));
            })
            .catch(err => {

                dispatch(setArchiveList([]));
            })
    }
}

const setArchiveList = (data) => {
    return {
        type: SET_BLOG_ARCHIVES_LIST,
        payload: data,
    }
}


export const getBlogCategoryList = () => {
    return dispatch => {
        return axiosInstance.get(apiUrl.listBlog.blogCategoryName)
            .then(response => {
                dispatch(setCategoryList(response.data.data));
            })
            .catch(err => {
                dispatch(setCategoryList([]));
            })
    }
}

export const setCategoryList = (data) => {
    return {
        type: SET_BLOG_CATEGORY_LIST,
        payload: data,
    }
}

export const getDetailedBlog = (payload) => {
    return dispatch => {
        return axiosInstance
            .get(`${apiUrl.blog.blogDetail}/${payload.blogId}`)
            .then(response => {
                if (response.data.data[0]) {
                    dispatch(recentBlog({ blogCategoryId: response.data.data[0].blogCategoryData[0]._id }))
                }
                dispatch(setBlogDetailedContent({ data: response.data }));
            })

    }
}

const setBlogDetailedContent = (data) => {
    return {
        type: SET_BLOG_DETAILED_DATA,
        payload: data,
    }
}

export const recentBlog = (payload) => {
    return dispatch => {
        return axiosInstance.post(apiUrl.blog.getRecentBlog, payload)
            .then(response => {
                dispatch(setRelatedBog(response.data))
            })
            .catch(_ => dispatch(setRelatedBog({})))
    }
}
const setRelatedBog = (data) => {
    return {
        type: SET_RELATED_BLOG_LIST,
        payload: data,
    }
}

export const postComment = (payload, data) => {
    return dispatch => {
        return axiosInstance.post('brands/comments', payload)
            .then(response => {
                data(response.data)
            })
            .catch(err => {
                data({ data: [] })
            })
    }
}

export const getComment = (payload, data) => {
    return dispatch => {
        return axiosInstance.post('brands/getcomments', payload)
            .then(response => {
                data(response.data)
            })
            .catch(err => {
                data({ data: [], user_image_path: '' })
            })
    }
}

export const filterBlog = (payload) => {
    return dispatch => {

        // return axiosInstance.post('brands/comments',payload)
        //     .then(response => {
        //         data(response.data)
        //     })
        //     .catch(err => {
        //         data({data:[]})
        //     })
    }
}

export const createNewComment = (payload, completion) => {

    return (dispatch) => {
        return axiosInstance.post(apiUrl.blog.blogcreateNewComment, payload, {
            headers: {
                "Authorization": "Bearer " + token,
            },

        }).then((response) => {

            completion(response.data);
        }).catch((err) => {
        })
    }
}
export const setCreateNewComment = (data) => {
    return {
        type: SET_BLOG_CREATE_NEW_COMMENT,
        payload: data,
    }
}

