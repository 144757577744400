import React from "react";
import { priceWithSymbol } from "../../../../utils/constants";
import { isEmpty } from "lodash";

let d = new Date(); // for now
d.getHours(); // => 9
d.getMinutes(); // =>  30
d.getSeconds(); // => 51
const Performance = (props) => {
  const [selectFilter, setSelectedFilter] = React.useState("All_time");
  React.useEffect(() => {
    if (!isEmpty(props.userPerformance && props.userPerformance.sortBy)) {
      setSelectedFilter(props.userPerformance.sortBy);
    }
  }, [props.userPerformance]);
  return (
    <div className="perf-statics">
      <div className="ac-head-wrap">
        <h4>
          Performance <span>Last updated {d.getHours() - 12}hr:{d.getMinutes()}min:{d.getSeconds()}sec</span>{" "}
        </h4>
        <div className="sort-drop">
          <select
            className="my_class dashboard-my-class-dropdown"
            onChange={(e) => {
              setSelectedFilter(e.target.value);
              props.performanceFilter(e.target.value);
            }}
          >
            {props.dropdown.map((i, j) => (
              <option
                value={i.value}
                key={j}
                selected={i.value === selectFilter ? true : false}
              >
                {i.key}
              </option>
            ))}
          </select>
        </div>
      </div>
      <>

      </>
      <div className="matrix-block info-wrap">
        {props.userPerformance
          ? Object.keys(props.userPerformance).map((key) => {
            let temp = props.data.filter((item) => item.key === key);
            if (temp.length > 0) {
              return (
                <div className="info-block">
                  <span className="matrix-key">
                    {key.split("_").join(" ")}
                    <span className="matrix-info">
                      <span className="matrix-pop">
                        <span className="matrix-info-popup">{temp[0].note}</span>
                      </span>
                    </span>
                  </span>
                  <span className="matrix-val">
                    {["AOV", "ALV"].includes(key)
                      ? priceWithSymbol(props.userPerformance[key])
                      : props.userPerformance[key]}
                    {temp[0].unit ? (
                      <span className="matrix-unit"> {temp[0].unit}</span>
                    ) : null}
                  </span>
                </div>
              );
            }
          })
          : null}
      </div>
      {/* <span>Account statistics and performance are updated every 24 hours</span> */}
    </div>
  );
};
export default Performance;
