import React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getDashboardCategoryProd } from "../../store/actions/dashboard";


class ReviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewData: [],
      id: this.props.data.id || "",
    };
  }

  componentDidMount() {
    const { id, block_name } = this.props.data;
    this.props.getProduct({ block_name, id }, (status) => {

    });
  }
  componentDidUpdate(PrevProps) {
    if (this.props.reviewData !== PrevProps.reviewData) {
      this.setState({
        reviewData: this.props.reviewData.data,
      });
    }
  }

  render() {
    const { reviewData } = this.state;
    const { id, name, product_count } = this.props.data;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1299,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
      </>
      // <div className="popular-arrival slider-blocks" id={id}>
      //   <div className="container">
      //     <div className="arrival-costumes arrival-caption-wrapper">
      //       <div className="arrival-caption">
      //         <h2>{name}</h2>
      //       </div>
      //       <div className="" style={{ width: "100%" }}>
      //         {reviewData.length > 0 && (
      //           <Slider {...settings} ref={(c) => (this.slider = c)}>
      //             {reviewData.slice(0, product_count).map((data, i) => (
      //               <ReviewCard key={i} {...data} />
      //             ))}
      //           </Slider>
      //         )}
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let id = ownProps.data.id;
  return {
    reviewData: state.dashboardInfo[id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: (payload, completion) => dispatch(getDashboardCategoryProd(payload, completion)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPage);


const ReviewCard = (props) => (
  <div className="review-card-wrap review-wrapper">
    <div className="review-card">
      <div className="prof-img">
        <span>{<img src={props.image[0] ? props.image[0] : "http://45.65.41.90:6060/public/images/default-avatar.jpg"} alt={props.name[0]} />}</span>
        <span className="prof-name review-profile-name">{props.name[0]}</span>
      </div>
      <div className="review-content">{props.review_comment}</div>
      <div className="test-ico">
        <img src="assets/images/testi.png" alt="" />
      </div>
      {props.rating ? (
        <ul className="ratings">
          {[1, 2, 3, 4, 5].map((i) =>
            i <= props.rating ? (
              <li className="fill">
                <i className="fas fa-star"></i>
              </li>
            ) : (
              <li>
                <i className="far fa-star"></i>
              </li>
            )
          )}
        </ul>
      ) : null}
    </div>
  </div>
);
