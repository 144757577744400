import React, { useState, useEffect } from "react";
import { priceWithSymbol } from "../../utils/constants";
import { addWishListProdList } from "../../store/actions/Wishlist";
import { Authorization } from "../../utils/Authorization";
import { openLoginScreen } from "../../store/actions/common";
import { increaseWishListCount } from "../../store/actions/common";
import {
  getCart,
  deleteCart,
  updateCartProdQty,
  removeCartWhenClickWishlist,
} from "../../store/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";

const ProductCard = (props) => {
  console.log("PROPS",props)
  let dispatch = useDispatch();
  const details = useSelector(state => state.productDetails.details)
  const { addToast } = useToasts();
  const [prodQty, setProdQty] = useState(0);
  const [minimumValue, setMinimumValue] = useState("");
  const [maximumValue, setMaximumValue] = useState("");
  const [getProductQuantity, setProductQuantity] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [productIdByName, setProductIdByName] = useState(null);
  // const [show, setShow] = useState(false);
  const [quantError, setQuantError] = useState(
    "You cannot buy more than 100mt in one time"
  );
  const [quantityLessError, setQuantityLessError] = useState("You cannot buy less than minimum order quantity. ")
  const [quantityAvailableError, setQuantityAvailableError] = useState("You cannot buy more than quantity available. ")

  useEffect(() => {
    if (props.shippingDetail != undefined) {
      if (Object.keys(props.shippingDetail).length > 0) {
        var splitedValue1 =
          props.shippingDetail && props.shippingDetail.minimumDays.split(" ");
        var splitedValue2 =
          props.shippingDetail && props.shippingDetail.maximumDays.split(" ");
        setMinimumValue(splitedValue1[0]);
        setMaximumValue(splitedValue2[0]);
      }
    } else {
      setMinimumValue("");
      setMaximumValue("");
    }
  }, [props.shippingDetail]);

  const getSellPrice = () => {
    let price = 0;
    if (props.data.Price) {
      if (props.data.Price.$numberDecimal) {
        price = props.data.Price.$numberDecimal;
      } else {
        price = props.data.Price.$numberDecimal;
      }
    }
    return price;
  };

  const getCurrentPrice = () => {
    let price = 0;
    if (props.data.Price) {
      if (props.data.Price.$numberDecimal) {
        price = props.data.Price.$numberDecimal;
      } else {
        price = props.data.Price.numberDecimal;
      }
    }

    return price;
  };

  const addToWishList = (item) => {
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      deleteCart([item.cartId])(dispatch);
      addWishListProdList({ productId: item.productId })();
      setTimeout(() => removeCartWhenClickWishlist(item.cartId)(dispatch), 10);
      setTimeout(() => increaseWishListCount()(dispatch), 1000);
    } else {
      openLoginScreen({ status: true, screenToShow: "login" })(dispatch);
    }
  };

  const removeCartProduct = (item) => {
    let checkLoginStatus = Authorization();
    let cart = JSON.parse(localStorage.getItem("bazar_cart"));
    if (!checkLoginStatus.status) {
      cart = cart.filter((newitem, i) => newitem.productId !== item.productId);
      localStorage.setItem("bazar_cart", JSON.stringify(cart));
      setTimeout(() => getCart()(dispatch), 10);
    } else {
      deleteCart([item.cartId])(dispatch);
      setTimeout(() => getCart()(dispatch), 100);
    }
  };

  const updateQty = (qty) => {
    // if(qty<props.productData.minOrderQty){
    //   setQuantError("Please enter quantity more than minimum order")
    // }
    // else if(qty>100){
    //   setQuantError("You cannot buy more than 100mt in one time")
    // }
    // else{
    //   setQuantError("")
    // }
    if (qty > 0) {
      props.getOnlyQty(qty);
      setQuantity(qty);
      setProductQuantity(qty);
      if (props.query.pid) {
        props.updateQtyForBuyNowAndMakeOffer(qty);
      } else {
        let payload = {
          cartId: props.data.cartId,
          quantity: qty,
        };
        updateCartProdQty(payload, (callback) => {
          if (callback.status === "error") {
            addToast(callback.message, { appearance: "error" });
            setProdQty(props.data.Quantity);
          } else {
            setProdQty(qty);
          }
        })(dispatch);
      }
      props.appliedCouponHandler(0, "");
    } else {
      if (Math.sign(qty) == -1) {
      } else {
        setQuantity(qty);
      }
      setProdQty(1);
      props.getOnlyQty(1);
    }
  };

  // const deleteProduct = () => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "You want to remove this product from cart",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       removeCartProduct(props.data);
  //     } else {
  //       swal({
  //         title: "Your product is still in cart!",
  //         className: "active-cart-dlte-wrap",
  //       });
  //     }
  //   });
  // };
  console.log("props.productData", props.productData)

  return (
    <div
      className={
        props.children ? "check-out-prod-card" : "cart-product-main-wrap"
      }
    >

      {props.productData !== undefined && (
        <div className="cart-wrp-main" key={props.cartId}>
          <div className={`cart-product cart-product-wrapper`}>
            <div className="img-cart-product">
              <Link to="/" className="product-img">
                <img src={props.productData.default_image} alt="product" />
              </Link>
            </div>

            <div className="cart-seller-name">
              <Link to={`/user-profile/${props.productData.userData && props.productData.userData[0] ?
                props.productData.userData[0]._id : props.productData.userdata._id
                }`} className='user-prof-link'>
              {props.productData.userData && props.productData.userData[0] ? (
                <h2>{props.productData.userData[0].companyName}</h2>
              ) : (
                <h2>{props.productData.userdata.companyName}</h2>
              )}
              </Link>
              <h3>{props.productData.productName}</h3>
              <div className="qty-cart-wrp">
                <input
                  type="number"
                  onChange={(e) => updateQty(e.target.value)}
                  value={quantity}
                  className="form-control"
                  placeholder="Enter Quantity"
                />
                <span className="unit-qty">mt</span>
              </div>
              {

                <span style={{ color: "red" }}>
                  {props.quantityError > 100 ? quantError : props.quantityError > props.productData.productWeight ? <span style={{ color: "red" }}>{quantityAvailableError}</span> : ""}
                  {props.quantityError < props.productData.minOrderQty ? quantityLessError : ""}
                </span>
              }
            </div>
          </div>




          <div className="current-price" style={{ marginTop: "40px" }}>Product Specifications</div>
          <div className="product-tble-wrp product-table-seller" style={{ marginTop: "10px" }}>
            <table>
              {details.data[0].spec && Object.keys(details.data[0].spec).map(function (
                key,
                index
              ) {
                let filteredLength = []
                const value = details.data[0].spec[key];

                // Skip if the value contains "N/A"
                // if (value.includes("N/A")) {
                //   return null;
                // }

                // Determine how to handle ranges and single values
                let displayValue = value;
                if (value.startsWith("N/A")) {
                  // Remove the key from the object
                  delete details.data[0].spec[key];
                } else {
                if (value.includes("-")) {
                  // If it's a range (like "14-16%"), don't show "max" or "min"
                  displayValue = value.replace(/(max|min|N\/A)/i, "").trim();
                } else if (value.match(/^\d+(\.\d+)?%$/)) {
                  // If it's a single percentage, add "max" or "min" based on the key
                  displayValue = `${value} ${key.toLowerCase().includes("min") ? "min" : "max"}`;
                } else {
                  displayValue = value.replace(/(N\/A)/i, "").trim();
                }
                filteredLength.push(value)
              }
                return Object.keys(filteredLength).length %
                  2 ===
                  1 ? (
                  Object.keys(filteredLength).length - 1 ===
                    index &&
                    Object.keys(filteredLength).length > 2 ? (
                    <>
                      {details.data[0].spec[key] !== "% " ? (
                        <>
                          <tr>
                            <th>
                              {key.charAt(0).toUpperCase() +
                                key.slice(1)}
                            </th>
                            <th>{displayValue} </th>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "transparent",
                                borderRightColor: "transparent",
                              }}
                            >
                              T
                            </th>
                            <th
                              style={{
                                color: "transparent",
                                borderLeftColor: "transparent",
                              }}
                            >
                              0
                            </th>
                          </tr>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {details.data[0].spec[key] !== "% " ? (
                        <tr>
                          <th>
                            {key.charAt(0).toUpperCase() +
                              key.slice(1)}
                          </th>
                          <th>{displayValue} </th>
                        </tr>
                      ) : null}
                    </>
                  )
                ) : (
                  <>
                    {details.data[0].spec[key] !== "% " ? (
                      <tr>
                        <th>
                          {key.charAt(0).toUpperCase() +
                            key.slice(1)}
                        </th>
                        <th>{displayValue} </th>
                      </tr>
                    ) : null}
                  </>
                );
              })}
            </table>
          </div>





          <div className="cart-product-text brand-text-wrp">
            <div className="cart-item-detail">
              <div className="price">
                <div className="current-price">Name:</div>
                <div className="new-price">{props.productData.productName}</div>
              </div>
              <div className="price">
                <div className="current-price">Price:</div>
                {props.productData.productPrice && <div className="new-price">
                  ₹{props.productData.productPrice.$numberDecimal}/mt
                </div>}
              </div>
              <div className="price">
                <div className="current-price">Type:</div>
                {props.productData.categoryData[0] ? (
                  <div className="new-price">
                    {props.productData.categoryData[0].name}
                  </div>
                ) : (
                  <div className="new-price">
                    {props.productData.categoryData.name}
                  </div>
                )}
              </div>
              <div className="price">
                <div className="current-price">Size:</div>
                <div className="new-price">
                  {props.productData.productSize} mm{" "}
                  {props.productData.process !== undefined
                    ? props.productData.process
                    : ""}
                </div>
              </div>
              <div className="price">
                <div className="current-price">Available Qty (in mt):</div>
                <div className="new-price">
                  {props.productData.productWeight
                    ? props.productData.productWeight
                    : ""}
                </div>
              </div>
              <div className="price">
                <div className="current-price">M.O.Q (in mt):</div>
                <div className="new-price">{props.productData.minOrderQty}</div>
              </div>
              {/* <div className="price">
                <div className="current-price">Size Tolerance:</div>
                <div className="new-price">
                  {props.productData.productSizeTolerance}%
                </div>
              </div> */}
              {props.productData.specificationTolerance ? (
                <div className="price">
                  <div className="current-price">Specification Tolerance:</div>
                  <div className="new-price">
                    {props.productData.specificationTolerance}%
                  </div>
                </div>
              ) : null}

              <div className="price">
                <div className="current-price delivery-window">
                  Delivery Window:
                </div>
                <div className="new-price">
                  {moment(props.productData.deliveryDateFrom).format("Do MMM")}{" "}
                  - {moment(props.productData.deliveryDateTo).format("Do MMM")}
                </div>
              </div>
              <div className="price">
                <div className="current-price">Delivery:</div>
                <div className="new-price">
                  {props.productData.deliveryType
                    ? props.productData.cityData &&
                    props.productData.deliveryType +
                    " " +
                    props.productData.cityData[0].cityName
                    : props.productData.cityData &&
                    props.productData.cityData[0].cityName}
                </div>
              </div>
              <div className="price">
                <div className="current-price">Payment Terms:</div>
                <div className="new-price">{props.productData.paymentType}</div>
              </div>
              {props.productData.gst && <div className="price">
                <div className="current-price">Gst:</div>
                <div className="new-price">
                  {props.productData.gst.$numberDecimal + "%"}
                </div>
              </div>}
              <>
                {props&&props.productData&&props.productData.loadingCharge&&props.productData.loadingCharge.$numberDecimal>0   ? (
                  <div className="price">
                    <div className="current-price">Loading Charges</div>
                    <div className="new-price">
                      ₹{props.productData.loadingCharge.$numberDecimal}/mt
                    </div>
                  </div>
                ) : null}
              </>
              <>
                {props.productData.packaging && props.productData.packaging.length > 0 ? (
                  <div className="price">
                    {/* <div className="current-price">Packaging</div> */}
                    {(details.data[0].coilCount && details.data[0].coilWeight) ?
                    <span className="current-price">Bundle Weight :</span>:<span className="current-price">Packaging :</span>}
                    {(details.data[0].coilCount && details.data[0].coilWeight) ?
                      <span className="new-price">
                        {`${details.data[0].coilCount} Coils (${details.data[0].coilWeight} Kgs approx.)`}
                      </span> : null}
                    {
                      props.productData.packaging.map((item, index) => {
                        return <div className="new-price" key={index}>
                          {item}
                        </div>
                      })
                    }

                  </div>
                ) : null}
              </>
              <>
                {props&&props.productData&&props.productData.insuranceCharge&&props.productData.insuranceCharge.$numberDecimal>0  ? (
                  <div className="price">
                    <div className="current-price">Insurance charges</div>
                    <div className="new-price">
                      ₹{props.productData.insuranceCharge.$numberDecimal}/mt
                    </div>
                  </div>
                ) : null}
              </>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductCard;
