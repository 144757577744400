import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getCountry,
  getBazaarList,
  sendOTPHandler,
  verifyGSTNumber,
  getSeller,
  verifyAccountNumber,
  verifyPanNumber,
  verifyOTP,
  sendOtpAadhar,
  verifyAadharOTP,
  submitSellerRegistration,
  updateSellerRegistration,
  getBankDetail,
  getAffiliations,
  sellerRegistrationDraft,
  draftData,
  sellerDEtails,
  getProductData,
  getProductDataAffilationNames,
} from "../../../store/actions/sellerRegisterationAction";
import { useToasts } from "react-toast-notifications";
import ReactCodeInput from "react-verification-code-input";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import Toast from "light-toast";
import Loader from "../../Loader";
import { getProfileBannerInfo } from "../../../store/actions/profile";
import CreatableSelect from "react-select/creatable";
import "../../global_modal.css";
import ProgressBar from "@ramonak/react-progress-bar";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Multiselect from "multiselect-react-dropdown";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import swal from "sweetalert";
import { logout } from "../../../store/actions/common";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function CompleteProfile(props) {
  const params = useParams();
  let dispatch = useDispatch();
  const [countrydata, setCountryData] = useState([]);
  const [stateDataArr, setStateData] = useState({});
  const [shopImg, setShopImg] = useState(null);
  const [isshopError, setIsshopError] = useState(false);

  const [chequeImg, setChequeImg] = useState(null);
  const [ischequeError, setIschequeError] = useState(false);
  const [gstPdf, setGstPdf] = useState(null);
  const [isgstpdf, setIsgstpdf] = useState(false);

  const [adharNo, setAdharNo] = useState("");
  const [mob, setMob] = useState("");
  const [checkMob, setCheckMob] = useState("");
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [MobileValidation, setMobileValidation] = useState(false);
  const [emptyMobileValid, setemptyMobileValid] = useState(false);

  const [firstName, setfirstName] = useState("");
  const [firstNameError, setfirstNameError] = useState(false);

  const [lastName, setlastName] = useState("");
  const [lastNameError, setlastNameError] = useState(false);

  const [number, setnumber] = useState("");
  const [numberError, setnumberError] = useState(false);

  const [streetnumber, setstreetnumber] = useState("");
  const [streetnumberError, setstreetnumberError] = useState(false);

  // const [landmark, setlandmark] = useState("");
  const [manufactur, setManufactur] = useState([]);
  // const [landmarkError, setlandmarkError] = useState(false);
  const [manufacturError, setManufacturError] = useState(false);

  const [zipcodeError, setzipcodeError] = useState(false);
  const [validZipcodeError, setValidZipcodeError] = useState(false);

  const [country, setcountry] = useState("");
  const [countryError, setcountryError] = useState(false);

  const [stateError, setstateError] = useState(false);

  const [address, setaddress] = useState("");
  const [addressError, setaddressError] = useState(false);

  const [gstNo, setGstNo] = useState("");
  const [gstNoError, setgstNoError] = useState(false);
  const [YOBusiness, setYOBusiness] = useState("");
  const [affiliation, setAffiliation] = useState([]);
  const [agregate, setAgregate] = useState("");
  const [interestBuy, setInterestBuy] = useState([]);

  const [contactPerson, setContactPerson] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zipcode, setzipcode] = useState("");

  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [google, setGoogle] = useState("");
  const [linkedIn, setLinkedIn] = useState("");

  const [selectedImagePan, setSelectedImagePan] = useState(null);
  const [panCardError, setPanCardError] = useState(false);
  const [selectedImageAdhaar, setSelectedImageAdhaar] = useState(null);
  const [AdhaarCardError, setAdhaarCardError] = useState(false);
  const [selectedImageGst, setSelectedImageGst] = useState(null);
  const [registrationCertificateError, setRegistrationCertificateError] =
    useState(false);

  const [processBar, setProcessBar] = useState(0);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [designationError, setDesignationError] = useState(false);

  const [panNo, setPanNo] = useState("");
  const [panNoError, setpanNoError] = useState(false);

  const [shopName, setshopName] = useState("");
  const [shopNameError, setshopNameError] = useState(false);

  const [adhaar, setadhaar] = useState("");
  const [adhaarError, setadhaarError] = useState(false);

  const [gstfile, setgstfile] = useState("");
  const [gstfileError, setgstfileError] = useState(false);

  const [accountNumber, setaccountNumber] = useState("");
  const [accountNumberError, setaccountNumberError] = useState(false);
  const [validaccountNumberError, setvalidAccountNumberError] = useState(false);

  const [confirmaccountNumber, setconfirmaccountNumber] = useState("");
  const [confirmaccountNumberError, setconfirmaccountNumberError] =
    useState(false);

  const [ifsc, setifsc] = useState("");
  const [ifscError, setifscError] = useState(false);
  const [ifscValidError, setifscValidError] = useState(false);

  const [accountHolder, setaccountHolder] = useState("");
  const [accountHolderError, setaccountHolderError] = useState(false);

  const [password, setpassword] = useState("");
  const [passwordError, setpasswordError] = useState(false);

  const [confirmPassword, setconfirmPassword] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);

  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const [MatchAccountNumber, setMatchAccountNumber] = useState(false);
  const [MatchPassword, setMatchPassword] = useState(false);
  const [ResendOtp, setResendOtp] = useState(false);
  const [ClientId, setClientId] = useState("");
  const [AadharVerify, setAadharVerify] = useState(false);
  const [adharValidation, setadharValidation] = useState(false);
  const [profileComponent, setProfileComponent] = useState(false);
  const { addToast } = useToasts();

  const [selectedState, setSelectedState] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [seletectedShopImage, setSeletectedShopImage] = useState("");
  const [selectedGstPdf, setSelectedGstPdf] = useState("");
  const [selectedCheqeImage, setSelectedCheqeImage] = useState("");
  const [validGstNumber, setValidGstNumber] = useState(false);
  const [panValidError, setPanValidError] = useState("");
  const [validAdhar, setValidAdhar] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [verifiedMob, setVerifiedMob] = useState("");

  const [phoneVerified, setPhoneVerified] = useState(false);
  const [aadhaarVerified, setAadhaarVerify] = useState(false);

  const [panVerified, setPanVerified] = useState(false);

  const [isGstVerified, setIsGstVerified] = useState(false);
  const [isPanVerified, setIsPanVerified] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [isVerifyGst, setIsVerifyGst] = useState(false);
  const [isVerifyPan, setIsVerifyPan] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [passDigits, setPassDigits] = useState(false);
  const [confPassDigits, setConfPassDigits] = useState(false);
  const [selectedValueBazar, setSelectedValueBazar] = useState(null);
  const [searchedDex, setSearchDex] = useState([]);
  const [options, setOptions] = useState([]);
  const [BazarList, setBazarList] = useState([]);

  const [addressSection, setAddressSection] = useState(true);
  const [businessSection, setBusinessSection] = useState(false);
  const [bankSection, setBankSection] = useState(false);
  const [urlSection, setUrlSection] = useState(false);
  const [documentSection, setDocumentSection] = useState(false);
  const [bazarError, setBazarError] = useState(false);
  const [previousNext, setPreviousNext] = useState(true);
  const [getMarketId, setMarketId] = useState("");

  const [optionsSelect, setOptionsSelect] = useState([]);

  const [optionsSelectManufacture, setOptionsSelectoptionsSelect] = useState(
    []
  );
  const [optionsSelectInterest, setOptionsSelectInterest] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueManufacture, setSelectedValueManufacture] = useState("");
  const [selectedValueInterest, setSelectedValueInterest] = useState("");
  const [gstValid, setGstValid] = useState(false);
  const [cPersonError, setCPersonError] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [erroeAddressOne, setErroeAddressOne] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [pinError, setErrorPin] = useState(false);
  const [validPin, setValidPin] = useState(false);
  const [validWebsite, setValidWebsite] = useState(false);
  const [validFacebook, setValidFacebook] = useState(false);
  const [validGoogle, setValidGoogle] = useState(false);
  const [validLinkedin, setValidLinkedin] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [optionsAutocomplete, setOptionsAutocomplete] = useState([
    "alok",
    "lokesh",
    "vinit",
    "vatan",
  ]);
  const [setUserType, setSetUserType] = useState("");
  const [bankName, setbankName] = useState("");
  const [bankNameError, setbankNameError] = useState(false);
  const [mobile, setMobile] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [getPan, setPan] = useState("");
  const [getAdhaar, setAdhaar] = useState("");
  const [getGst, setGst] = useState("");

  const [allOptions, setAllOptions] = useState([])
  const [selectOptions, setSelectOptions] = useState([])

  const [interestSelectOptions, setInterestSelectOptions] = useState([])

  useEffect(() => {
    props.getBankDetail({}, (status) => {
      setBankList(status);
    });
    //setProfileComponent(null);
    const getToken = localStorage.getItem("token");
    if (props.location.pathname === props.path) {
      setAddressSection(true);
      setBusinessSection(true);
      setBankSection(true);
      //setProfileComponent(true);
      setDisableEmail(true);
      setPreviousNext(false);
      // getSeller(callback => {
      //     setCheckMob(callback[0].mobile);
      //     setnumber(callback[0].addressData.flat_no);
      //     setstreetnumber(callback[0].addressData.street);

      //     setzipcode(callback[0].addressData.postcode);
      //     setcity(callback[0].addressData.city);
      //     setshopName(callback[0].companyName);
      //     setadhaar(callback[0].aadhar_number);

      //     setbankName(callback[0] && callback[0].bankData && callback[0].bankData.bankName);
      //     setaccountNumber(callback[0] && callback[0].bankData && callback[0].bankData.accountNo);
      //     setconfirmaccountNumber(callback[0] && callback[0].bankData && callback[0].bankData.accountNo);
      //     setifsc(callback[0] && callback[0].bankData && callback[0].bankData.ifsc);
      //     setaccountHolder(callback[0] && callback[0].bankData && callback[0].bankData.accountHolderName);
      //     setpassword(callback[0].Password);
      //     if (options.length > 0) {
      //         setSelectedValueBazar(options.filter(option => option.value === callback[0].marketId));
      //     }
      //     setSelectedAddress(callback[0].type);
      //     setSelectedState(callback[0] && callback[0].addressData && callback[0].addressData.selectedStateData && callback[0].addressData.selectedStateData._id);
      //     setSeletectedShopImage(callback[0].shop_image);
      //     setShopImg(callback[0].shop_image);
      //     setSelectedGstPdf(callback[0].gstFile);
      //     setgstfile(callback[0].gstFile);
      //     setSelectedCheqeImage(callback[0].cheque);
      //     setChequeImg(callback[0].cheque)
      //     setPanNo(callback[0].pan);
      //     setstate(callback[0] && callback[0].addressData && callback[0].addressData.selectedStateData && callback[0].addressData.selectedStateData._id);
      //     setconfirmPassword("confirmPassword");
      //     setpassword("password");

      //     setTerms(true);

      // })(dispatch)
    }
    getCountry((callback) => {
      let newState = [...callback];
      setCountryData(newState);
      setcountry(newState[0]._id);
      setStateData(newState[0]);
      setaddress("Warehouse");
    })(dispatch);
  }, [props.path, options]);

  useEffect(() => {
    if (props.location.state && props.location.state.state == "login") {
    }
    if (props.location.pathname == "/profile/businessProfile") {
    }
    if (
      props.location.pathname == "/sellerRegistration/footer" &&
      props.adminStatus &&
      props.adminStatus.isSeller
    ) {
      props.history.push("/sell-product");
      return;
    }
    if (props.adminStatus && props.adminStatus.sellerCreatedByAdmin) {
      localStorage.setItem(
        "incomplete",
        props.adminStatus.sellerProfileCreatedByAdminCompleted
      );
    }
  }, [props.adminStatus]);

  const checkValidNumber = (e) => {
    if (e.target.value.length === 15) {
      setShowMessage("GST Verifying...");
      const payload = {
        gstId: gstNo,
      };
      props.verifyGSTNumber(payload, (status) => {
        if (status.code == 500) {
          setIsVerifyGst(false);
          setIsGstVerified(false);
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        } else if (status.code == 200) {
          setIsVerifyGst(true);
          setIsGstVerified(true);
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        }
      });
    }
  };

  const checkValidPanNumber = (e) => {
    if (e.target.value.length === 10) {
      setShowMessage("Pan Veryfing...");
      const payload = {
        panNumber: panNo,
      };
      props.verifyPanNumber(payload, (status) => {
        if (status.code === 200) {
          setIsVerifyPan(true);
          setIsPanVerified(true);

          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        } else if (status.code === 500) {
          setIsVerifyPan(false);
          setIsPanVerified(false);
          if (status.message == "Internal server error") {
            var message = "Invalid PAN Number";
          }
          addToast(message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      });
    }
  };

  useEffect(() => {
    setShowLoader(true);
    setSetUserType(localStorage.getItem("userType"));

    // dispatch(
    //   getProductData({}, (status) => {
    //     let arra = [];
    //     for (let a = 0; a < status.length; a++) {
    //       arra.push({ id: status[a]._id, name: status[a].name });
    //       setAllOptions(arra)
    //       setOptionsSelectoptionsSelect(arra);
    //       setOptionsSelectInterest(arra);
    //     }
    //   })
    // );

    draftData({}, (status) => {
      if (status.length > 0) {
        draftInformation(status[0]);
      }
    })(dispatch);

    sellerDEtails({}, (data) => {
      if (data.length > 0) {
        draftInformation(data[0]);
        dispatch(
          getProductDataAffilationNames({}, (status) => {
            let arra = [];
            for (let a = 0; a < status.length; a++) {
              arra.push({ id: status[a]._id, name: status[a].name });
              setOptionsSelectoptionsSelect(arra);
              setOptionsSelectInterest(arra);
            }
            setAllOptions(arra)
            handleSelectedProducts(data, arra)
          })
        );
      }
    })(dispatch);

    getAffiliations({}, (status) => {
      setShowLoader(false);
      let array = [];
      for (let a = 0; a < status.length; a++) {
        array.push({ name: status[a].name, id: status[a]._id });
      }
      if (!isEqual(array, optionsSelect)) {
        setOptionsSelect(array);
      }
    })(dispatch);

    // getBazaarList({}, (status) => {
    //     if (status.status == "success") {

    //         for (let i = 0; i < status.data.length; i++) {

    //             BazarList.push({ label: status.data[i].name, value: status.data[i]._id })
    //         }
    //         setOptions(BazarList);
    //     } else {
    //     }

    // })(dispatch);
  }, [optionsSelect]);

  useEffect(() => {
    if (props.location.query == "edit") {
      setProfileComponent(false);
    }
  }, [props.location]);

  function isEqual(a, b) {
    return a.join() == b.join();
  }

  const handleSelectedProducts = (data, array) =>{
    let temp = []
    let tempInterest = []
    array.forEach((element)=>{
      if(data[0].productManufacturing.includes(element.name)){
        temp = [...temp, element]
      }
      if(data[0].interstInBuying.includes(element.name)){
        tempInterest= [...tempInterest, element]
      }
    })
    setSelectOptions(temp)
    setInterestSelectOptions(tempInterest)
  }


  const draftInformation = (data) => {
    setGstNo(data.gst == "null" ? "" : data.gst);
    setYOBusiness(data.businessYears == "null" ? "" : data.businessYears);

    let arr1 = [];
    for (let a = 0; a < data.Affiliations.length; a++) {
      let getSelValReal =
        optionsSelect.length > 0
          ? optionsSelect.filter((word) => word.id === data.Affiliations[a])
          : [{ name: null, id: null }];
      arr1.push(getSelValReal[0]);
    }

    setSelectedValue(arr1);
    setAffiliation(data.Affiliations == "null" ? "" : data.Affiliations);
    setAgregate(data.monthlyNeed == "null" ? "" : data.monthlyNeed);
    setInterestBuy(data.interstInBuying == "null" ? "" : data.interstInBuying);

    let arr2 = [];
    for (let b = 0; b < data.interstInBuying.length; b++) {
      let getSelValRealInst =
        optionsSelectInterest && optionsSelectInterest.length > 0
          ? optionsSelectInterest.filter(
              (word) => word.name === data.interstInBuying[b]
            )
          : [{ name: null, id: null }];

      arr2.push(getSelValRealInst[0]);
    }
    setSelectedValueInterest(arr2);
    setManufactur(
      data.productManufacturing == "null" ? "" : data.productManufacturing
    );

    let arr3 = [];
    for (let c = 0; c < data.productManufacturing.length; c++) {
      let getSelValRealMan =
        optionsSelectManufacture.length > 0
          ? optionsSelectManufacture.filter(
              (word) => word.name === data.productManufacturing[c]
            )
          : [{ name: null, id: null }];

      arr3.push(getSelValRealMan[0]);
    }
    setSelectedValueManufacture(arr3);

    if (data.designation == null) {
      setProfileComponent(false);
    } else {
      //setProfileComponent(true)
    }

    setContactPerson(data.personName == "null" ? "" : data.personName);
    setDesignation(data.designation == "null" ? "" : data.designation);
    setEmail(data.personEmail == "null" ? "" : data.personEmail);
    setPhone(data.personMobile == "null" ? "" : data.personMobile);
    setWhatsapp(data.whatsappNumber == "null" ? "" : data.whatsappNumber);

    setAddressOne(data.addressLine1 == "null" ? "" : data.addressLine1);
    setAddressTwo(data.addressLine2 == "null" ? "" : data.addressLine2);
    setcity(data.city == "null" ? "" : data.city);
    setstate(data.state == "null" ? "" : data.state);
    setzipcode(data.zipCode == "null" ? "" : data.zipCode);

    setWebsite(data.websiteLink == "null" ? "" : data.websiteLink);
    setFacebook(data.facebookLink == "null" ? "" : data.facebookLink);
    setGoogle(data.otherLink == "null" ? "" : data.otherLink);
    setLinkedIn(data.linkedinLink == "null" ? "" : data.linkedinLink);

    setSelectedImagePan(data.pan);
    setSelectedImageGst(data.aadharFile);
    setSelectedImageAdhaar(data.aadharFile);

    setaccountHolder(
      data.accountHolderName == "null" ? "" : data.accountHolderName
    );
    setaccountNumber(data.accountNumber == "null" ? "" : data.accountNumber);
    setconfirmaccountNumber(
      data.accountNumber == "null" ? "" : data.accountNumber
    );
    setbankName(data.bankName);
    setifsc(data.ifsc == "null" ? "" : data.ifsc);
  };

  const getSelectedValue = (newValue) => {
    setSelectedValueBazar(newValue);
    setBazarError(false);
    //setProfileComponent(null);
  };

  const handleInputChange = (e) => {
    if (e) {
      // getBazaarList({ search: e }, (status) => {
      //     if (status.status == "success") {
      //         setSearchDex([])
      //         for (let i = 0; i < status.data.length; i++) {
      //             searchedDex.push({ label: status.data[i].name, value: status.data[i]._id })
      //         }
      //         setOptions(searchedDex);
      //     } else {
      //     }
      // })(dispatch);
    }
  };

  const showDigits = (value) => {
    if (value === "password") {
      setPassDigits(!passDigits);
    } else if (value === "confirmpassword") {
      setConfPassDigits(!confPassDigits);
    }
  };

  const checkValidAccountNumber = (e) => {
    if (e.target.value.length > 1) {
      const payload = {
        accountNumber: accountNumber,
        ifsc: ifsc,
      };
      props.verifyAccountNumber(payload, (status) => {
        if (status.code === 200) {
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        } else if (status.code === 500) {
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      });
    }
  };

  const sendOTP = () => {
    if (mob.length === 10) {
      setShowLoader(true);
      setShowMessage("Please wait...");
      sendOTPHandler({ mobile: mob }, (callback) => {
        if (callback.status == "success") {
          setShowLoader(false);
          setIsSendOtp(true);
          addToast(callback.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        } else {
          setShowLoader(false);
          addToast(callback.Message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })(dispatch);
    }
  };

  const verifyOtp = (val) => {
    setShowLoader(true);
    setShowMessage("Please wait...");
    verifyOTP({ otp: val }, (callback) => {
      if (callback) {
        setShowLoader(false);
        setIsSendOtp(false);
        setIsVerify(true);
        setPhoneVerified(false);
        addToast("Phone number verified", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        setVerifiedMob(mob);
      } else {
        setShowLoader(false);
        addToast("This OTP not valid.", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    })(dispatch);
  };

  const verifyOtpAadhar = (val) => {
    setShowLoader(true);
    setShowMessage("Verifying...");
    const payload = {
      clientId: ClientId,
      otp: val,
      mobile: 7895154317,
      aadharNumber: adhaar,
    };
    props.verifyAadharOTP(payload, (status) => {
      if (status.status === "success") {
        setShowLoader(false);
        setResendOtp(false);
        setAadharVerify(true);
        setAadhaarVerify(false);
        addToast(status.message, {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
      } else {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    });
  };

  const sendOtpAadhar = () => {
    setShowLoader(true);
    setShowMessage("Sending...");

    const payload = {
      aadharNumber: adhaar,
    };
    props.sendOtpAadhar(payload, (status) => {
      if (status.type == "Timeout, Please try again") {
        setShowLoader(false);
        addToast("Please try again", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      } else {
        if (status.data && status.data.message_code === "success") {
          setShowLoader(false);
          setResendOtp(true);
          addToast(status.data.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          setClientId(status.data.data.client_id);
          setadharValidation(false);
        } else if (
          (status.data &&
            status.data.message === "Request failed with status code 422") ||
          status.status === "invalid"
        ) {
          setShowLoader(false);
          addToast("please try again or Check your aadhaar number", {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        } else {
          setShowLoader(false);
          addToast(status.data[0].msg, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      }
    });
  };

  const flatnumberhandler = (e) => {
    setnumber(e.target.value);
    setnumberError(false);
  };

  const streetnumberhandler = (e) => {
    setstreetnumber(e.target.value);
    setstreetnumberError(false);
  };

  const countryhandler = (e) => {
    setcountry(e.target.value);
    setcountryError(false);
  };

  const addresshandler = (e) => {
    setaddress(e.target.value);
    setaddressError(false);
  };

  const fileHandler = (e) => {
    setSeletectedShopImage("");
    setShopImg(e.target.files[0]);
    setIschequeError(false);
  };

  const chequeHandler = (e) => {
    setSelectedCheqeImage("");
    setChequeImg(e.target.files[0]);
    setIsshopError(false);
  };

  const filegstHandler = (e) => {
    setSelectedGstPdf("");
    setgstfile(e.target.files[0]);
    setgstfileError(false);
  };

  const shopnamehandler = (e) => {
    if (!/[^A-Za-z\d\s]/.test(e.target.value)) {
      setshopName(e.target.value);
      setshopNameError(false);
    }
  };

  //////////////////// onchange Handlers ////////////////////////

  const getGstNumber = (e) => {
    if (e.target.value.length < 15) {
      setGstNo(e.target.value);
      setGstValid(true);
      setgstNoError(false);
    } else {
      setGstNo(e.target.value);
      setgstNoError(false);
      setGstValid(false);
    }
  };

  const Businesshandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setYOBusiness(e.target.value);
    }
  };

  const agregateHandler = (e) => {
    let checkDigit = new RegExp(/^\d*$/);
    if (checkDigit.test(e.target.value)) {
      setAgregate(e.target.value);
      //setMonthError(false);
    }
  };

  const CPersonHandler = (e) => {
    setContactPerson(e.target.value);
    setCPersonError(false);
  };

  const designationhandler = (e) => {
    setDesignation(e.target.value);
    setDesignationError(false);
  };

  const emailhandler = (e) => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(e.target.value)) {
      setEmail(e.target.value);
      setInvalidEmail(false);
      setEmailError(false);
    } else {
      setEmail(e.target.value);
      setEmailError(false);
      setInvalidEmail(true);
    }
  };

  const phonehandler = (e) => {
    let checkDigit = new RegExp(/^\d*$/);
    if (checkDigit.test(e.target.value) && e.target.value.length == 10) {
      setPhone(e.target.value);
      setPhoneError(false);
      setNumberValid(false);
    } else {
      setNumberValid(true);
      setPhone(e.target.value);
      setPhoneError(false);
    }
  };

  const whatsapphandler = (e) => {
    setWhatsapp(e.target.value);
  };

  const addressOnehandler = (e) => {
    setAddressOne(e.target.value);
    setErroeAddressOne(false);
  };

  const addressTwohandler = (e) => {
    setAddressTwo(e.target.value);
  };

  const cityhandler = (e) => {
    setCityError(false);
    setcity(e.target.value);
  };

  const statehandler = (e) => {
    setstate(e.target.value);
    setstateError(false);
  };

  const zipcodehandler = (e) => {
    let checkDigit = new RegExp(/^\d*$/);
    if (checkDigit.test(e.target.value) && e.target.value.length == 6) {
      setValidPin(false);
      setzipcode(e.target.value);
      setErrorPin(false);
    } else {
      setValidPin(true);
      setzipcode(e.target.value);
      setErrorPin(false);
    }
  };

  const Websitehandler = (e) => {
    if (isValidHttpUrl(e.target.value)) {
      setWebsite(e.target.value);
      setValidWebsite(false);
    } else {
      setWebsite(e.target.value);
      setValidWebsite(true);
    }
  };

  const facebookhandler = (e) => {
    if (isValidHttpUrl(e.target.value)) {
      setFacebook(e.target.value);
      setValidFacebook(false);
    } else {
      setFacebook(e.target.value);
      setValidFacebook(true);
    }
  };

  const googlehandler = (e) => {
    if (isValidHttpUrl(e.target.value)) {
      setGoogle(e.target.value);
      setValidGoogle(false);
    } else {
      setGoogle(e.target.value);
      setValidGoogle(true);
    }
  };

  const linkdinhandler = (e) => {
    if (isValidHttpUrl(e.target.value)) {
      setLinkedIn(e.target.value);
      setValidLinkedin(false);
    } else {
      setLinkedIn(e.target.value);
      setValidLinkedin(true);
    }
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const panhandler = (e) => {
    setSelectedImagePan(e.target.files[0]);
    setPanCardError(false);
    setPan("");
  };

  const adhaarhandler = (e) => {
    setSelectedImageAdhaar(e.target.files[0]);
    setAdhaarCardError(false);
    setAdhaar("");
  };

  const gstHandler = (e) => {
    setSelectedImageGst(e.target.files[0]);
    setRegistrationCertificateError(false);
    setGst("");
  };

  const handleWatsappCheck = (e) => {
    if (e.target.checked) {
      setWhatsapp(phone);
    } else {
      setWhatsapp("");
    }
  };

  const pannumberhandler = (e) => {
    if (e.target.value.length < 10) {
      if (!/[^A-Za-z\d]/.test(e.target.value)) {
        setPanValidError(true);
        setPanNo(e.target.value);
        setpanNoError(false);
      }
    } else {
      if (!/[^A-Za-z\d]/.test(e.target.value)) {
        setPanValidError(false);
        setPanNo(e.target.value);
        setpanNoError(false);
      }
    }
  };

  const handleTerms = (e) => {
    setTerms(e.target.checked);
    setTermsError(false);
  };

  const banknamehandler = (e) => {
    setbankName(e.target.value);
    setbankNameError(false);
  };

  const accountnumberhandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value.length < 9 || e.target.value.length > 18) {
        setvalidAccountNumberError(true);
        setaccountNumber(e.target.value);
        setaccountNumberError(false);
      } else {
        setvalidAccountNumberError(false);
        setaccountNumber(e.target.value);
        setaccountNumberError(false);
      }
    }
  };

  const confirmaccounthandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value == accountNumber) {
        setconfirmaccountNumber(e.target.value);
        setconfirmaccountNumberError(false);
        setMatchAccountNumber(false);
      } else {
        setconfirmaccountNumberError(false);
        setMatchAccountNumber(true);
        setconfirmaccountNumber(e.target.value);
      }
    }
  };

  const ifschandler = (e) => {
    if (e.target.value.length < 11) {
      if (!/[^A-Za-z\d]/.test(e.target.value)) {
        setifscValidError(true);
        setifsc(e.target.value);
        setifscError(false);
      }
    } else {
      if (!/[^A-Za-z\d]/.test(e.target.value)) {
        setifscValidError(false);
        setifsc(e.target.value);
        setifscError(false);
      }
    }
  };

  const accountholdernamehandler = (e) => {
    const re = /^[A-Za-z\s]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setaccountHolder(e.target.value);
      setaccountHolderError(false);
    }
  };

  function editMode() {
    setAadharVerify(true);
    setIsVerify(true);
    setProfileComponent(!profileComponent);
    setIsGstVerified(true);
    setIsPanVerified(true);
    setDisableEmail(true);
    setIsVerifyPan(true);
    setIsVerifyGst(true);

    getSeller((callback) => {
      setCheckMob(callback[0].mobile);
      setnumber(callback[0].addressData.flat_no);
      setstreetnumber(callback[0].addressData.street);
      setzipcode(callback[0].addressData.postcode);
      setcity(callback[0].addressData.city);
      setshopName(callback[0].companyName);
      setadhaar(callback[0].aadhar_number);
      setbankName(
        callback[0] && callback[0].bankData && callback[0].bankData.bankName
      );
      setaccountNumber(
        callback[0] && callback[0].bankData && callback[0].bankData.accountNo
      );
      setconfirmaccountNumber(
        callback[0] && callback[0].bankData && callback[0].bankData.accountNo
      );
      setifsc(callback[0] && callback[0].bankData && callback[0].bankData.ifsc);
      setaccountHolder(
        callback[0] &&
          callback[0].bankData &&
          callback[0].bankData.accountHolderName
      );
      setpassword(callback[0].Password);
      setSelectedAddress(callback[0].type);
      setSelectedState(
        callback &&
          callback[0].addressData &&
          callback[0].addressData.selectedStateData &&
          callback[0].addressData.selectedStateData._id
      );
      setSeletectedShopImage(callback[0].shop_image);
      setShopImg(callback[0].shop_image);
      setSelectedGstPdf(callback[0].gstFile);
      setgstfile(callback[0].gstFile);
      setSelectedCheqeImage(callback[0].cheque);
      setChequeImg(callback[0].cheque);
      setPanNo(callback[0].pan);
      setstate(
        callback &&
          callback[0].addressData &&
          callback[0].addressData.selectedStateData &&
          callback[0].addressData.selectedStateData._id
      );
      setTerms(true);
    })(dispatch);
  }

  function isAddressValid() {
    // if (gstNo == "") {
    //     setgstNoError(true);
    //     document.getElementById("gst").focus();
    // } else if (gstValid) {
    //     setGstValid(true);
    //     document.getElementById("gst").focus();
    // }
    // else {
    //     return true
    // }
    if (designation === "" || designation == null) {
      setDesignationError(true);
      document.getElementById("designation").focus();
    } else {
      return true;
    }
  }

  function isBusinessValid() {
    if (addressOne == "" || addressOne==null) {
      setErroeAddressOne(true);
      document.getElementById("addressOne").focus();
    } else if (city == "" || city==null) {
      setCityError(true);
      document.getElementById("city").focus();
    } else if (state == "" || state==null) {
      setstateError(true);
      document.getElementById("state").focus();
    } else if (zipcode == "" || zipcode==null) {
      setErrorPin(true);
      document.getElementById("zip").focus();
    } else if (validPin == true) {
      document.getElementById("zip").focus();
    } else {
      return true;
    }
  }

  function isBankValid() {
    if (validWebsite) {
      document.getElementById("website").focus();
    } else if (validFacebook) {
      document.getElementById("facebook").focus();
    } else if (validGoogle) {
      document.getElementById("google").focus();
    } else if (validLinkedin) {
      document.getElementById("linkedin").focus();
    } else {
      return true;
    }
  }

  function isUrlValid() {
    if (bankName == "" || bankName==null) {
      setbankNameError(true);
      document.getElementById("bankname").focus();
    } else if (accountNumber == "" || accountNumber == null) {
      setaccountNumberError(true);
      setvalidAccountNumberError(false);
      document.getElementById("accountnumber").focus();
    } else if (confirmaccountNumber == "" || confirmaccountNumber == null) {
      setconfirmaccountNumberError(true);
      setMatchAccountNumber(false);
      document.getElementById("confirmac").focus();
    } else if (ifsc == "" || ifsc == null) {
      setifscError(true);
      setifscValidError(false);
      document.getElementById("ifsc").focus();
    } else if (ifsc && ifsc.length < 11) {
      setifscError(false);
      setifscValidError(true);
      document.getElementById("ifsc").focus();
    } else if (accountHolder == "" || accountHolder == null) {
      setaccountHolderError(true);
      document.getElementById("acholder").focus();
    } else {
      return true;
    }
  }

  function isImageValid() {
    if (bankName == "") {
      setbankNameError(true);
      document.getElementById("bankname").focus();
    } else if (accountNumber == "" || accountNumber == null) {
      setaccountNumberError(true);
      setvalidAccountNumberError(false);
      document.getElementById("accountnumber").focus();
    } else if (confirmaccountNumber == "" || confirmaccountNumber == null) {
      setconfirmaccountNumberError(true);
      setMatchAccountNumber(false);
      document.getElementById("confirmac").focus();
    } else if (ifsc == "" || ifsc == null) {
      setifscError(true);
      setifscValidError(false);
      document.getElementById("ifsc").focus();
    } else if (ifsc && ifsc.length < 11) {
      setifscError(false);
      setifscValidError(true);
      document.getElementById("ifsc").focus();
    } else if (accountHolder == "" || accountHolder == null) {
      setaccountHolderError(true);
      document.getElementById("acholder").focus();
    } else if (!selectedImagePan) {
      setPanCardError(true);
    } else if (!selectedImageAdhaar) {
      setAdhaarCardError(true);
    } else if (!selectedImageGst) {
      setRegistrationCertificateError(true);
    } else {
      return true;
    }
    return true;
  }

  const submit = (e) => {
    e.preventDefault();
    if (isUrlValid()) {
      setShowLoader(true);
      setShowMessage("Please wait...");
      const payload = {
        MailingAddressSameAsPermanentAddress: true,
        addressLine2: addressTwo,
        addressLine1: addressOne,
        interstInBuying: interestBuy,
        productManufacturing: manufactur,
        gstNumber: gstNo,
        businessYears: YOBusiness,
        zipCode: zipcode,
        monthlyNeed: agregate,
        state: state,
        city: city,
        Affiliations: affiliation,
        designation: designation,
        personName: contactPerson,
        personEmail: email,
        personMobile: phone,
        bankName: bankName,
        isWhatsappNumberSameAsMobile: false,
        accountNumber: accountNumber,
        ifsc: ifsc,
        accountHolderName: accountHolder,
        whatsappNumber: whatsapp,
        websiteLink: website,
        otherLink: google,
        facebookLink: facebook,
        linkedinLink: linkedIn,
        shopImage:
          "/home/spxlpt189/Pictures/Screenshots/Screenshot from 2022-10-04 15-22-01.png",
      };
      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item]);
      }
      formdata.append("PANImages", selectedImagePan);
      formdata.append("seller_image", shopImg);
      formdata.append("aadharImages", selectedImageAdhaar);
      formdata.append("gstFile", selectedImageGst);

      if (profileComponent === null || profileComponent === true) {
        props.submitSellerRegistration(formdata, (status) => {
          if (status.status === "success") {
            setProcessBar(100);
            setShowLoader(false);

            setAddressSection(true);
            setBusinessSection(false);
            setBankSection(false);
            setUrlSection(false);
            setDocumentSection(false);
            // localStorage.setItem("isProfileCompleted", true);
            // localStorage.setItem("isProfileCompleted", true);
            // localStorage.setItem("approved", "pending");
            // swal({
            //     title: "Thanks for your interest in MetalsBuy. We have captured your interest.Your profile is under review and we will update you on this soon!",
            //     className: "thanks-msg-wrap",
            // }).then((value) => {
            //     props.history.push("/main")
            //     //props.logout();
            // });
            sellerDEtails({}, (status) => {
              if (status.length > 0) {
                draftInformation(status[0]);
              }
            })(dispatch);
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            setTimeout(() => {
              props.history.push("/profile/complete_profile");
            }, 1000);
            setProfileComponent(true);
          } else {
            addToast(status.message, {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
            setShowLoader(false);
          }
        });
      } else if (profileComponent === false) {
        props.updateSellerRegistration(formdata, (status) => {
          if (status.status == "success") {
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            setShowLoader(false);
            setProfileComponent(true);
            window.scrollTo(0, 0);
            setAddressSection(true);
            setBusinessSection(false);
            setBankSection(false);
            setUrlSection(false);
            setDocumentSection(false);
            setProcessBar(100);
            props.history.push("/profile");
          } else {
            setShowLoader(false);
          }
        });
      } else {
      }
    }
  };

  const nextValidation = () => {
    if (addressSection) {
      if (isAddressValid()) {
        const payload = {
          gstNumber: gstNo,
          businessYears: YOBusiness,
          Affiliations: affiliation,
          monthlyNeed: agregate,
          productManufacturing: manufactur,
          interstInBuying: interestBuy,
        };
        handleDraft(payload);
        setProcessBar(20);
        setAddressSection(false);
        setBusinessSection(true);
        setBankSection(false);
        setUrlSection(false);
        setDocumentSection(false);
      }
    } else if (businessSection) {
      if (isBusinessValid()) {
        const payload = {
          personName: contactPerson,
          designation: designation,
          personEmail: email,
          personMobile: phone,
          whatsappNumber: whatsapp,
        };
        handleDraft(payload);
        setProcessBar(40);
        setAddressSection(false);
        setBusinessSection(false);
        setBankSection(true);
        setUrlSection(false);
        setDocumentSection(false);
      }
    } else if (bankSection) {
      if (isBankValid()) {
        const payload = {
          addressLine2: addressTwo,
          addressLine1: addressOne,
          state: state,
          city: city,
          zipCode: zipcode,
        };
        handleDraft(payload);
        setProcessBar(60);
        setAddressSection(false);
        setBusinessSection(false);
        setBankSection(false);
        setUrlSection(true);
        setDocumentSection(false);
      }
    } else if (urlSection) {
      if (isUrlValid()) {
        const payload = {
          websiteLink: website,
          otherLink: google,
          facebookLink: facebook,
          linkedinLink: linkedIn,
        };
        handleDraft(payload);
        setProcessBar(80);
        setAddressSection(false);
        setBusinessSection(false);
        setBankSection(false);
        setUrlSection(false);
        setDocumentSection(true);
      }
    }
  };

  const handleDraft = (payload) => {
    let formdata = new FormData();
    for (let item in payload) {
      formdata.append(item, payload[item]);
    }
    props.sellerRegistrationDraft(formdata, (status) => {
      if (status.status == "success") {
      } else {
        // addToast(status.message, {
        //     appearance: "error",
        //     autoDismissTimeout: 2500,
        // });
        // return false
      }
    });
    return "true";
  };

  const handlePrevious = () => {
    if (businessSection) {
      setAddressSection(true);
      setBusinessSection(false);
      setBankSection(false);
      setUrlSection(false);
      setDocumentSection(false);
    } else if (bankSection) {
      setAddressSection(false);
      setBusinessSection(true);
      setBankSection(false);
      setUrlSection(false);
      setDocumentSection(false);
    } else if (urlSection) {
      setAddressSection(false);
      setBusinessSection(false);
      setBankSection(true);
      setUrlSection(false);
      setDocumentSection(false);
    } else if (documentSection) {
      setAddressSection(false);
      setBusinessSection(false);
      setBankSection(false);
      setUrlSection(true);
      setDocumentSection(false);
    }
  };

  const onSelect = (e) => {
    setSelectedValue(e);
    let array = [];
    for (let a = 0; a < e.length; a++) {
      array.push(e[a].id);
    }
    setAffiliation(array);
  };

  const onSelectManufacture = (e) => {
    setSelectedValueManufacture(e);
    let array = [];
    for (let a = 0; a < e.length; a++) {
      array.push(e[a].name);
    }
    setManufactur(array);
  };

  const onSelectInterest = (e) => {
    setSelectedValueInterest(e);
    let array = [];
    for (let a = 0; a < e.length; a++) {
      array.push(e[a].name);
    }
    setInterestBuy(array);
  };

  const onRemove = (e) => {
    setSelectedValue(e);
    let array = [];
    for (let a = 0; a < e.length; a++) {
      array.push(e[a].id);
    }
    setAffiliation(array);
  };

  const onRemoveManufacture = (e) => {
    setSelectedValueManufacture(e);
    let array = [];
    for (let a = 0; a < e.length; a++) {
      array.push(e[a].name);
    }
    setManufactur(array);
  };

  const onRemoveInterest = (e) => {
    setSelectedValueInterest(e);
    let array = [];
    for (let a = 0; a < e.length; a++) {
      array.push(e[a].name);
    }
    setInterestBuy(array);
  };

  const handleRequestOptions = (e) => {
  };

  const closeModal = () => {
    localStorage.setItem("isProfileCompleted", true);
    props.history.push("/");
  };

  return (
    <>
      <div className="seller-only without-login with-login business-profile after-signup-registration-form profile-outside-form">
        {showLoader ? <Loader message={showMessage} /> : null}
        <div className="container">
          {/* <span onClick={closeModal} class="skip-btn skip-btn-top">Skip</span> */}
          <form onSubmit={(e) => submit(e)}>
            {addressSection ? (
              <div className="login-details seller-login-details-wrap">
                {/* {profileComponent === null ? null : <><h3>Personal details</h3> <span className="edit-form header-sell" onClick={editMode}>{profileComponent === true ? <i class="fas fa-user-edit"></i> : null}{profileComponent === true ? "EDIT" : "BACK"}</span></>} */}
                <div className="registration-form-one-header">
                  <div className="registration-form-head">
                    {/* <span className='welcome-msz'>Welcome to the </span> */}
                    {/* <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link> */}
                  </div>
                </div>
                <div className="complete-profile-wrap">
                  <span className="login-msz-wrap">
                    Let’s complete your profile now
                  </span>
                  <div className="step-count-registration">
                    <span className="step-number">Step 1</span>
                    <span className="total-step">Out of 4</span>
                  </div>
                </div>
                <div className="process-bar-wrapper">
                  <div className="progress-bar-wrapper">
                    <ProgressBar
                      completed={processBar}
                      className="process-bar"
                    />
                  </div>
                  <span className="completed-process">
                    {processBar}% complete
                  </span>
                </div>
                <div className="login-description login-description-form-wrap">
                  {/* <div className='input-form-wrap'>
                                        <div className='form-intput'>
                                            <label>GST No.<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                                            <div className='input_wrapper'>
                                                <input
                                                    type="text"
                                                    name="gstNumber"
                                                    placeholder='Enter your GST number'
                                                    onChange={(e) => getGstNumber(e)}
                                                    id="gst"
                                                    disabled={profileComponent}
                                                    value={gstNo}
                                                    maxLength="15"
                                                />
                                                {gstNoError ?
                                                    <span className="error">
                                                        Please enter GST no.
                                                    </span> : null
                                                }
                                                {gstValid ?
                                                    <span className="error">
                                                        Please enter valid GST no.
                                                    </span> : null
                                                }
                                            </div>

                                        </div>

                                    </div> */}
                  <div className="input-form-wrap">
                    <div className="form-intput">
                      <label>Year Established</label>
                      <input
                        type="text"
                        placeholder="Enter year"
                        onChange={(e) => Businesshandler(e)}
                        id="fnumber"
                        disabled={profileComponent}
                        value={YOBusiness}
                        maxlength="100"
                      />
                    </div>
                  </div>

                  <div className="input-form-wrap select-affilation-profile">
                    <div className="form-intput select-affilation-wrap complete-profile-select multi-dropdown-fix">
                      <label>Affiliations & Assocations</label>
                      <div className="select-main-wrapper">

                        {selectedValue && optionsSelect && (
                          // optionsSelect.length > 0 &&
                          // selectedValue.length > 0 &&
                          <Multiselect
                            options={optionsSelect} // Options to display in the dropdown
                            selectedValues={selectedValue} // Preselected value to persist in dropdown
                            onSelect={(e) => onSelect(e)} // Function will trigger on select event
                            onRemove={(e) => onRemove(e)} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            disable={profileComponent}
                            closeMenuOnSelect={true}
                          />
                        )}
                        <i className="icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>

                  <div className="input-form-wrap">
                    <div className="form-intput">
                      <label>Aggregate Monthly Need (in mt)</label>
                      <input
                        type="text"
                        placeholder="Enter aggregate"
                        onChange={(e) => agregateHandler(e)}
                        value={agregate}
                        id="aggregate"
                        disabled={profileComponent}
                        maxlength="100"
                      />
                    </div>
                  </div>

                  <div className="input-form-wrap select-affilation-profile">
                    <div className="form-intput select-affilation-wrap complete-profile-select multi-dropdown-fix">
                      <label>Products Manufacturing</label>
                      <div className="select-main-wrapper">
                        {(
                            allOptions && allOptions.length>0 && <Multiselect
                              options={allOptions} // Options to display in the dropdown
                              selectedValues={selectOptions && selectOptions.length ?selectOptions:[]} // Preselected value to persist in dropdown
                              onSelect={(e) => onSelectManufacture(e)} // Function will trigger on select event
                              onRemove={(e) => onRemoveManufacture(e)} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                              disable={profileComponent}
                            />
                          )}
                        <i className="icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>

                  <div className="input-form-wrap select-affilation-profile">
                    <div className="form-intput select-affilation-wrap complete-profile-select multi-dropdown-fix">
                      <label>
                        Interested in{" "}
                        {setUserType == "Buyer" ? "Buying" : "Selling"}
                      </label>
                      <div className="select-main-wrapper">
                        {(
                          // optionsSelectInterest.length > 0 &&
                          // selectedValueInterest.length > 0 &&
                          allOptions && allOptions.length>0 && <Multiselect
                            options={allOptions} // Options to display in the dropdown
                            selectedValues={interestSelectOptions && interestSelectOptions.length>0 ? interestSelectOptions:[]} // Preselected value to persist in dropdown
                            onSelect={(e) => onSelectInterest(e)} // Function will trigger on select event
                            onRemove={(e) => onRemoveInterest(e)} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            disable={profileComponent}
                          />
                        )}
                        <i className="icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>

                  <div className="input-form-wrap">
                    <div className="form-intput">
                      <label>
                        Designation<sup className="highlighted">*</sup>
                      </label>
                      <div className="phone-number-field adhar-field">
                        <input
                          type="text"
                          placeholder="Enter Designation here"
                          name="aadharNumber"
                          onChange={(e) => designationhandler(e)}
                          id="designation"
                          value={designation}
                          disabled={profileComponent}
                        />
                        {designationError ? (
                          <span className="error">
                            Please enter designation
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {previousNext ? (
                    <div className="registration-btn registration">
                      <input
                        className="btn shop-now"
                        type="button"
                        value={"Next"}
                        onClick={nextValidation}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {businessSection ? (
              // <div className='login-details seller-login-details-wrap'>
              //     <div className='registration-form-one-header'>
              //         <div className='registration-form-head'>
              //             {/* <span className='welcome-msz'>Welcome to the </span> */}
              //             <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link>
              //         </div>

              //     </div>
              //     <div className='complete-profile-wrap'>
              //         <span className='login-msz-wrap'>Fill your Contact Details</span>
              //         <div className='step-count-registration'>
              //             <span className='step-number'>Step 2</span>
              //             <span className='total-step'>Out of 5</span>
              //         </div>
              //     </div>
              //     <div className='process-bar-wrapper'>
              //         <div className='progress-bar-wrapper'>
              //             <ProgressBar completed={processBar} className="process-bar" />
              //         </div>
              //         <span className='completed-process'>{processBar}% complete</span>
              //     </div>
              //     <div className='login-description login-description-form-wrap'>
              //         <div className='input-form-wrap'>
              //             <div className='form-intput'>
              //                 <label>Contact Person<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
              //                 <input type="text" placeholder='Enter name' onChange={(e) => CPersonHandler(e)} id="contactPerson" disabled={profileComponent} value={contactPerson} maxlength="50" />
              //             </div>
              //             {cPersonError ?
              //                 <span className="error">
              //                     Please enter contact person
              //                 </span> : null
              //             }
              //         </div>
              //         <div className='input-form-wrap'>
              //             <div className='form-intput'>
              //                 <label>Designation<sup className='highlighted'>*</sup></label>
              //                 <div className="phone-number-field adhar-field">
              //                     <input
              //                         type="text"
              //                         placeholder='Enter Designation here'
              //                         name="aadharNumber"
              //                         onChange={(e) => designationhandler(e)}
              //                         id="designation"
              //                         maxlength="12"
              //                         value={designation}
              //                         disabled={profileComponent}
              //                     />
              //                     {designationError ? <span className='error'>Please enter designation</span> : null}
              //                 </div>
              //             </div>
              //         </div>
              //         <div className='input-form-wrap'>
              //             <div className='form-intput'>
              //                 <label>Email<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
              //                 <input type="text" placeholder='enter email' onChange={(e) => emailhandler(e)} id="emailFocus" disabled={profileComponent} value={email} maxlength="50" />
              //                 {invalidEmail ? <span className='error'>Please enter a valid email</span> : null}
              //                 {emailError ? <span className='error'>Please enter email</span> : null}

              //             </div>
              //         </div>
              //         <div className='input-form-wrap complete-profile-phone'>
              //             <div className='form-intput'>
              //                 <label>Phone No.<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>

              //                 <input type="text" placeholder='Enter phone no.' onChange={(e) => phonehandler(e)} id="shopname" disabled={profileComponent} value={phone} maxLength="10" />
              //             </div>
              //             {numberValid ? <span className='error'>Please enter a valid phone no.</span> : null}
              //             {phoneError ? <span className='error'>Please enter phone no.</span> : null}
              //         </div>

              //         {previousNext ?
              //             <div className="registration-btn registration-btn-wrpper">
              //                 <input className="btn shop-now go-back" type="button" value={"Go Back"} onClick={handlePrevious} />
              //                 <input className="btn shop-now next-btn" type="button" value={"Next"} onClick={nextValidation} />
              //             </div> : null

              //         }
              //     </div>

              // </div>
              <div className="login-details seller-login-details-wrap">
                <div className="registration-form-one-header">
                  <div className="registration-form-head">
                    {/* <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link> */}
                  </div>
                </div>
                <div className="complete-profile-wrap">
                  <span className="login-msz-wrap">Fill your Address</span>
                  <div className="step-count-registration">
                    <span className="step-number">Step 2</span>
                    <span className="total-step">Out of 4</span>
                  </div>
                </div>
                <div className="process-bar-wrapper">
                  <div className="progress-bar-wrapper">
                    <ProgressBar
                      completed={processBar}
                      className="process-bar"
                    />
                  </div>
                  <span className="completed-process">
                    {processBar}% complete
                  </span>
                </div>
                <div className="login-description login-description-form-wrap ">
                  <div className="address-field-wrapper">
                    <div className="addres-head-wrap">
                      <h4>Manufacturing Plant Address</h4>
                    </div>
                    <div className="input-form-wrap">
                      <div className="form-intput">
                        <label>
                          Address line 1
                          <sup className="highlighted">
                            {!profileComponent ? "*" : null}
                          </sup>
                        </label>
                        <input
                          type="text"
                          placeholder="Type here"
                          onChange={(e) => addressOnehandler(e)}
                          id="addressOne"
                          disabled={profileComponent}
                          value={addressOne}
                          maxlength="50"
                        />
                      </div>
                      {erroeAddressOne ? (
                        <span className="error">
                          Please enter address line 1
                        </span>
                      ) : null}
                    </div>
                    <div className="input-form-wrap">
                      <div className="form-intput">
                        <label>Address line 2</label>
                        <input
                          type="text"
                          placeholder="Type here"
                          onChange={(e) => addressTwohandler(e)}
                          id="shopname"
                          disabled={profileComponent}
                          value={addressTwo}
                          maxlength="50"
                        />
                      </div>
                    </div>

                    <div className="input-form-wrap">
                      <div className="form-intput">
                        <label>
                          city
                          <sup className="highlighted">
                            {!profileComponent ? "*" : null}
                          </sup>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter city name here"
                          onChange={(e) => cityhandler(e)}
                          id="city"
                          disabled={profileComponent}
                          value={city}
                          maxlength="100"
                        />
                      </div>
                      {cityError ? (
                        <span className="error">Please enter city</span>
                      ) : null}
                    </div>

                    <div className="input-form-wrap select-affilation-profile">
                      <div className="form-intput select-affilation-wrap">
                        <label>
                          state
                          <sup className="highlighted">
                            {!profileComponent ? "*" : null}
                          </sup>
                        </label>

                        <div className="select-main-wrapper">
                          <select
                            onChange={(e) => statehandler(e)}
                            id="state"
                            disabled={profileComponent}
                          >
                            <option value={""}>Select</option>
                            {stateDataArr &&
                              stateDataArr.stateData &&
                              stateDataArr.stateData.map((item) => (
                                <option
                                  value={item._id}
                                  selected={state == item._id ? true : false}
                                >
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          <i className="icon-arrow-down"></i>
                        </div>
                        {stateError ? (
                          <span className="error">
                            {profileComponent ? null : "Please select state"}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="input-form-wrap">
                      <div className="form-intput">
                        <label>
                          Pin code
                          <sup className="highlighted">
                            {!profileComponent ? "*" : null}
                          </sup>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter pincode here"
                          onChange={(e) => zipcodehandler(e)}
                          id="zip"
                          value={zipcode}
                          disabled={profileComponent}
                          maxlength="6"
                        />
                      </div>
                      {pinError ? (
                        <span className="error">
                          {profileComponent ? null : "Please enter pin code"}
                        </span>
                      ) : null}

                      {validPin ? (
                        <span className="error">
                          {profileComponent
                            ? null
                            : "Please enter valid pin code"}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <>
                    {previousNext ? (
                      <div className="registration-btn registration-btn-wrpper">
                        <input
                          className="btn shop-now go-back"
                          type="button"
                          value={"Go Back"}
                          onClick={handlePrevious}
                        />
                        <input
                          className="btn shop-now next-btn"
                          type="button"
                          value={"Next"}
                          onClick={nextValidation}
                        />
                      </div>
                    ) : null}
                  </>
                </div>
              </div>
            ) : null}

            {bankSection ? (
              <div className="login-details seller-login-details-wrap">
                <div className="registration-form-one-header">
                  <div className="registration-form-head">
                    {/* <span className='welcome-msz'>Welcome to the </span> */}
                    {/* <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link> */}
                  </div>
                </div>
                <div className="complete-profile-wrap">
                  <span className="login-msz-wrap">Fill your Address</span>
                  <div className="step-count-registration">
                    <span className="step-number">Step 3</span>
                    <span className="total-step">Out of 4</span>
                  </div>
                </div>
                <div className="process-bar-wrapper">
                  <div className="progress-bar-wrapper">
                    <ProgressBar
                      completed={processBar}
                      className="process-bar"
                    />
                  </div>
                  <span className="completed-process">
                    {processBar}% complete
                  </span>
                </div>
                <div className="login-description login-description-form-wrap ">
                  <div className="input-form-wrap">
                    <div className="form-intput">
                      <label>Website</label>
                      <input
                        type="text"
                        placeholder="Enter website link here"
                        onChange={(e) => Websitehandler(e)}
                        id="website"
                        disabled={profileComponent}
                        value={website}
                        maxlength="50"
                      />
                    </div>
                    {validWebsite ? (
                      <span className="error">
                        Please enter url with http or https
                      </span>
                    ) : null}
                  </div>
                  <div className="input-form-wrap">
                    <div className="form-intput">
                      <label>Facebook</label>
                      <input
                        type="text"
                        placeholder="Enter link here"
                        onChange={(e) => facebookhandler(e)}
                        id="facebook"
                        disabled={profileComponent}
                        value={facebook}
                        maxlength="50"
                      />
                    </div>
                    {validFacebook ? (
                      <span className="error">
                        Please enter url with http or https
                      </span>
                    ) : null}
                  </div>
                  <div className="input-form-wrap">
                    <div className="form-intput">
                      <label>Google</label>
                      <input
                        type="text"
                        placeholder="Enter link here"
                        onChange={(e) => googlehandler(e)}
                        id="google"
                        disabled={profileComponent}
                        value={google}
                        maxlength="100"
                      />
                    </div>
                    {validGoogle ? (
                      <span className="error">
                        Please enter url with http or https
                      </span>
                    ) : null}
                  </div>
                  <div className="input-form-wrap">
                    <div className="form-intput">
                      <label>Linkedin</label>
                      <input
                        type="text"
                        placeholder="Enter link here"
                        onChange={(e) => linkdinhandler(e)}
                        id="linkedin"
                        disabled={profileComponent}
                        value={linkedIn}
                        maxlength="100"
                      />
                    </div>
                    {validLinkedin ? (
                      <span className="error">
                        Please enter url with http or https
                      </span>
                    ) : null}
                  </div>

                  <>
                    {previousNext ? (
                      <div className="registration-btn registration-btn-wrpper">
                        <input
                          className="btn shop-now go-back"
                          type="button"
                          value={"Go Back"}
                          onClick={handlePrevious}
                        />
                        <input
                          className="btn shop-now next-btn"
                          type="button"
                          value={"Next"}
                          onClick={nextValidation}
                        />
                      </div>
                    ) : null}
                  </>
                </div>
              </div>
            ) : null}

            {urlSection ? (
              <div className="login-details upload-document-wrapper seller-login-details-wrap">
                <div className="registration-form-one-header">
                  <div className="registration-form-head">
                    {/* <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link> */}
                  </div>
                </div>
                <div className="complete-profile-wrap">
                  <span className="login-msz-wrap">Upload your Documents</span>
                  <div className="step-count-registration">
                    <span className="step-number">Step 4</span>
                    <span className="total-step">Out of 4</span>
                  </div>
                </div>
                <div className="process-bar-wrapper">
                  <div className="progress-bar-wrapper">
                    <ProgressBar
                      completed={processBar}
                      className="process-bar"
                    />
                  </div>
                  <span className="completed-process">
                    {processBar}% complete
                  </span>
                </div>
                <div class="addres-head-wrap">
                  <h4>Enter Bank Details</h4>
                </div>
                <div className="input-form-wrap select-affilation-profile">
                  <div className="form-intput select-affilation-wrap complete-profile-select">
                    <label>
                      Bank Name
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <div className="select-main-wrapper">
                      <select
                        onChange={(e) => banknamehandler(e)}
                        id="bankname"
                        disabled={profileComponent}
                      >
                        <option value={""}>Please select bank</option>
                        {bankList.map((item) => (
                          <option
                            key={Math.random()}
                            value={item.bankName}
                            selected={bankName == item.bankName ? true : false}
                          >
                            {item.bankName}
                          </option>
                        ))}
                      </select>
                      <i className="icon-arrow-down"></i>
                    </div>
                  </div>
                  {bankNameError ? (
                    <span className="error">
                      {profileComponent ? null : "Please select bank name"}
                    </span>
                  ) : null}
                  {erroeAddressOne ? (
                    <span className="error">Please enter address line 1</span>
                  ) : null}
                </div>
                <div className="input-form-wrap bank-detail-field">
                  <div className="form-intput">
                    <label>
                      {" "}
                      Account number
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <input
                      type={profileComponent ? "text" : "password"}
                      placeholder="Enter your account number"
                      onChange={(e) => accountnumberhandler(e)}
                      id="accountnumber"
                      disabled={profileComponent}
                      value={accountNumber}
                      maxlength="18"
                      minLength="9"
                    />
                  </div>
                  {accountNumberError ? (
                    <span className="error">
                      {profileComponent ? null : "Please enter account number"}
                    </span>
                  ) : null}
                  {validaccountNumberError ? (
                    <span className="error">
                      {profileComponent
                        ? null
                        : "Please enter valid account number"}
                    </span>
                  ) : null}
                </div>
                <div className="input-form-wrap bank-detail-field">
                  <div className="form-intput">
                    <label>
                      Confirm account number
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <input
                      type="text"
                      placeholder="Confirm account number"
                      onChange={(e) => confirmaccounthandler(e)}
                      id="confirmac"
                      disabled={profileComponent}
                      value={confirmaccountNumber}
                      maxlength="18"
                      minLength="9"
                    />
                    {confirmaccountNumberError ? (
                      <span className="error">
                        {profileComponent
                          ? null
                          : "Please enter confirm account number"}
                      </span>
                    ) : null}
                    {MatchAccountNumber ? (
                      <span className="error">
                        {profileComponent
                          ? null
                          : "Account number and confirm account number does not match"}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="input-form-wrap bank-detail-field">
                  <div className="form-intput">
                    <label>
                      {" "}
                      IFSC
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your IFSC"
                      onChange={(e) => ifschandler(e)}
                      id="ifsc"
                      onBlur={checkValidAccountNumber}
                      maxlength="11"
                      value={ifsc}
                      disabled={profileComponent}
                    />
                  </div>
                  {ifscError ? (
                    <span className="error">
                      {profileComponent ? null : "Please enter IFSC code"}
                    </span>
                  ) : null}
                  {ifscValidError ? (
                    <span className="error">
                      {profileComponent
                        ? null
                        : "Please enter 11 digit valid IFSC code"}
                    </span>
                  ) : null}
                </div>
                <div className="input-form-wrap bank-detail-field">
                  <div className="form-intput">
                    <label>
                      account holder name
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter account holder name"
                      onChange={(e) => accountholdernamehandler(e)}
                      id="acholder"
                      disabled={profileComponent}
                      value={accountHolder}
                      maxlength="100"
                    />
                  </div>
                  {accountHolderError ? (
                    <span className="error">
                      {profileComponent
                        ? null
                        : "Please enter account holder name"}
                    </span>
                  ) : null}
                </div>
                <div className="login-description description-new-wrap upload-desc-wrapper">
                  <div>
                    <div className="registration-btn registration-btn-wrpper">
                      {previousNext ? (
                        <input
                          className="btn shop-now go-back"
                          type="button"
                          value={"Go Back"}
                          onClick={handlePrevious}
                        />
                      ) : null}
                      {!profileComponent ? (
                        <input
                          className="btn shop-now next-btn"
                          type="submit"
                          value={"Submit"}
                        />
                      ) : (
                        <input
                          className="btn shop-now next-btn"
                          type="submit"
                          value={"Update"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {documentSection ? (
              <div className="login-details upload-document-wrapper seller-login-details-wrap">
                <div className="registration-form-one-header">
                  <div className="registration-form-head">
                    {/* <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link> */}
                  </div>
                </div>
                <div className="complete-profile-wrap">
                  <span className="login-msz-wrap">Upload your Documents</span>
                  <div className="step-count-registration">
                    <span className="step-number">Step 5</span>
                    <span className="total-step">Out of 5</span>
                  </div>
                </div>
                <div className="process-bar-wrapper">
                  <div className="progress-bar-wrapper">
                    <ProgressBar
                      completed={processBar}
                      className="process-bar"
                    />
                  </div>
                  <span className="completed-process">
                    {processBar}% complete
                  </span>
                </div>
                <div class="addres-head-wrap">
                  <h4>Enter Bank Details</h4>
                </div>
                <div className="input-form-wrap select-affilation-profile">
                  <div className="form-intput select-affilation-wrap complete-profile-select">
                    <label>
                      Bank Name
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <div className="select-main-wrapper">
                      <select
                        onChange={(e) => banknamehandler(e)}
                        id="bankname"
                        disabled={profileComponent}
                      >
                        <option value={""}>Please select bank</option>
                        {bankList.map((item) => (
                          <option
                            key={Math.random()}
                            value={item.bankName}
                            selected={bankName == item.bankName ? true : false}
                          >
                            {item.bankName}
                          </option>
                        ))}
                      </select>
                      <i className="icon-arrow-down"></i>
                    </div>
                  </div>
                  {bankNameError ? (
                    <span className="error">
                      {profileComponent ? null : "Please select bank name"}
                    </span>
                  ) : null}
                  {erroeAddressOne ? (
                    <span className="error">Please enter address line 1</span>
                  ) : null}
                </div>
                <div className="input-form-wrap bank-detail-field">
                  <div className="form-intput">
                    <label>
                      {" "}
                      Account number
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <input
                      type={profileComponent ? "text" : "password"}
                      placeholder="Enter your account number"
                      onChange={(e) => accountnumberhandler(e)}
                      id="accountnumber"
                      disabled={profileComponent}
                      value={accountNumber}
                      maxlength="18"
                      minLength="9"
                    />
                  </div>
                  {accountNumberError ? (
                    <span className="error">
                      {profileComponent ? null : "Please enter account number"}
                    </span>
                  ) : null}
                  {validaccountNumberError ? (
                    <span className="error">
                      {profileComponent
                        ? null
                        : "Please enter valid account number"}
                    </span>
                  ) : null}
                </div>
                <div className="input-form-wrap bank-detail-field">
                  <div className="form-intput">
                    <label>
                      Confirm account number
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <input
                      type="text"
                      placeholder="Confirm account number"
                      onChange={(e) => confirmaccounthandler(e)}
                      id="confirmac"
                      disabled={profileComponent}
                      value={confirmaccountNumber}
                      maxlength="18"
                      minLength="9"
                    />
                    {confirmaccountNumberError ? (
                      <span className="error">
                        {profileComponent
                          ? null
                          : "Please enter confirm account number"}
                      </span>
                    ) : null}
                    {MatchAccountNumber ? (
                      <span className="error">
                        {profileComponent
                          ? null
                          : "Account number and confirm account number does not match"}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="input-form-wrap bank-detail-field">
                  <div className="form-intput">
                    <label>
                      {" "}
                      IFSC
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your IFSC"
                      onChange={(e) => ifschandler(e)}
                      id="ifsc"
                      onBlur={checkValidAccountNumber}
                      maxlength="11"
                      value={ifsc}
                      disabled={profileComponent}
                    />
                  </div>
                  {ifscError ? (
                    <span className="error">
                      {profileComponent ? null : "Please enter IFSC code"}
                    </span>
                  ) : null}
                  {ifscValidError ? (
                    <span className="error">
                      {profileComponent
                        ? null
                        : "Please enter 11 digit valid IFSC code"}
                    </span>
                  ) : null}
                </div>
                <div className="input-form-wrap bank-detail-field">
                  <div className="form-intput">
                    <label>
                      account holder name
                      <sup className="highlighted">
                        {!profileComponent ? "*" : null}
                      </sup>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter account holder name"
                      onChange={(e) => accountholdernamehandler(e)}
                      id="acholder"
                      disabled={profileComponent}
                      value={accountHolder}
                      maxlength="100"
                    />
                  </div>
                  {accountHolderError ? (
                    <span className="error">
                      {profileComponent
                        ? null
                        : "Please enter account holder name"}
                    </span>
                  ) : null}
                </div>
                <div className="login-description description-new-wrap upload-desc-wrapper">
                  {/* <div className='main-img-wrap'>
                                        <div className='form-intput input-upload-form'>
                                            {typeof selectedImagePan == "string" ? null : <button onClick={() => setSelectedImagePan(null)} className="close-img-btn"><i className='close-img-icon'></i></button>}
                                            <div className='select-edit-wrap'>
                                                {selectedImagePan && (
                                                    <div className='select-img-upload'>
                                                        <img alt="not fount" src={typeof selectedImagePan == "string" ? selectedImagePan : URL.createObjectURL(selectedImagePan)} />
                                                    </div>
                                                )}

                                                {!selectedImagePan &&
                                                    <div className='input-file-upload-wrap'>
                                                        <div className='input-upload'>
                                                            <div className='file-image'>
                                                                <img src="/assets/images/choose-img-icon.svg" alt='icon' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <input
                                                    type="file"
                                                    className='file-upload-input'
                                                    name="myImage"
                                                    onChange={(event) => {
                                                        panhandler(event)

                                                    }}
                                                    disabled={profileComponent}
                                                />
                                            </div>
                                            <div className='upload-label-wrap'>
                                                <label class="upload-label">Pan Card<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                                            </div>
                                            {panCardError ? <span className='error'>Please select pan card image</span> : null}
                                        </div>

                                        <div className='form-intput input-upload-form'>
                                            {typeof selectedImageAdhaar == "string" ? null : <button onClick={() => setSelectedImageAdhaar(null)} className="close-img-btn"><i className='close-img-icon'></i></button>}
                                            <div className='select-edit-wrap'>
                                                {selectedImageAdhaar && (
                                                    <div className='select-img-upload'>
                                                        <img alt="not fount" width={"250px"} src={typeof selectedImageAdhaar == "string" ? selectedImageAdhaar : URL.createObjectURL(selectedImageAdhaar)} />
                                                    </div>
                                                )}

                                                {getAdhaar && (
                                                    <div className='select-img-upload'>
                                                        <img alt="not fount" src={getAdhaar} />
                                                    </div>
                                                )}

                                                {!selectedImageAdhaar &&
                                                    <div className='input-file-upload-wrap'>
                                                        <div className='input-upload'>
                                                            <div className='file-image'>
                                                                <img src="/assets/images/choose-img-icon.svg" alt='icon' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <input
                                                    type="file"
                                                    name="myImage"
                                                    onChange={(event) => {
                                                        adhaarhandler(event)
                                                    }}
                                                    disabled={profileComponent}
                                                />
                                            </div>
                                            <div className='upload-label-wrap'>
                                                <label className='upload-label'>Aadhaar <sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                                            </div>

                                            {AdhaarCardError ? <span className='error'>Please select adhaar card image</span> : null}
                                        </div>

                                        <div className='form-intput input-upload-form'>
                                            {typeof selectedImageGst == "string" ? null : <button onClick={() => setSelectedImageGst(null)} className="close-img-btn"><i className='close-img-icon'></i></button>}
                                            <div className='select-edit-wrap'>
                                                {selectedImageGst && (
                                                    <div className='select-img-upload'>
                                                        <img alt="not fount" width={"250px"} src={typeof selectedImageGst == "string" ? selectedImageGst : URL.createObjectURL(selectedImageGst)} />
                                                    </div>
                                                )}

                                                {getGst && (
                                                    <div className='select-img-upload'>
                                                        <img alt="not fount" src={getGst} />
                                                    </div>
                                                )}

                                                {!selectedImageGst &&
                                                    <div className='input-file-upload-wrap'>
                                                        <div className='input-upload'>
                                                            <div className='file-image'>
                                                                <img src="/assets/images/choose-img-icon.svg" alt='icon' />
                                                            </div>
                                                        </div>

                                                    </div>
                                                }
                                                <input
                                                    type="file"
                                                    name="myImage"
                                                    onChange={(event) => {
                                                        gstHandler(event)
                                                    }}
                                                    disabled={profileComponent}
                                                />
                                            </div>
                                            <div className='upload-label-wrap'>
                                                <label className='upload-label'>
                                                    GST Registration Certificate<sup className="highlighted">{!profileComponent ? "*" : null}</sup>
                                                </label>
                                            </div>

                                            {registrationCertificateError ? <span className='error'>Please select adhaar card image</span> : null}
                                        </div>
                                    </div>
                                    <span className='img-warning'>
                                        *file should be in png, jpg or pdf format and maximum 5 mb in size
                                    </span> */}
                  <div>
                    <div className="registration-btn registration-btn-wrpper">
                      {previousNext ? (
                        <input
                          className="btn shop-now go-back"
                          type="button"
                          value={"Go Back"}
                          onClick={handlePrevious}
                        />
                      ) : null}

                      <input
                        className="btn shop-now next-btn"
                        type="submit"
                        value={"Submit"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  adminStatus: state.userProfile.userProfileBanner.userdata,
});

const mapDispatchToProps = (dispatch) => {
  return {
    sendOtpAadhar: (payload, completion) =>
      dispatch(sendOtpAadhar(payload, completion)),
    verifyAadharOTP: (payload, completion) =>
      dispatch(verifyAadharOTP(payload, completion)),
    logout: () => dispatch(logout()),
    submitSellerRegistration: (payload, completion) =>
      dispatch(submitSellerRegistration(payload, completion)),
    sellerRegistrationDraft: (payload, completion) =>
      dispatch(sellerRegistrationDraft(payload, completion)),
    verifyPanNumber: (payload, completion) =>
      dispatch(verifyPanNumber(payload, completion)),
    verifyAccountNumber: (payload, completion) =>
      dispatch(verifyAccountNumber(payload, completion)),
    verifyGSTNumber: (payload, completion) =>
      dispatch(verifyGSTNumber(payload, completion)),
    updateSellerRegistration: (payload, completion) =>
      dispatch(updateSellerRegistration(payload, completion)),
    getBankDetail: (payload, completion) =>
      dispatch(getBankDetail(payload, completion)),
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompleteProfile)
);
