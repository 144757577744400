import React, { useState } from "react";
import "../ProductDetail/ProductDetails.css";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import Toast from "light-toast";
import { addBrandRequest } from "./../../store/actions/sellItem";
import { useSelector, useDispatch } from 'react-redux';

const AddBrandPopup = (props) => {

  let dispatch = useDispatch();

  const { addToast } = useToasts();
  let categoriesList = useSelector(store => store.productListingInfo.categories);

  const [state, setState] = useState({
    name: "",
    category: "",
    image: null,
    file: null,
    isName: false,
    isImg: false,
    isCat: false
  })



  const handlerOnChange = (e, name) => {
    if (name === "name") {
      setState({ ...state, [name]: e.target.value, isName: false })
    } else if (name === "category") {
      setState({ ...state, [name]: e, isCat: false })
    } else {
      let src_ = URL.createObjectURL(e.target.files[0]);
      setState({ ...state, [name]: e.target.files[0], file: src_, isImg: false })
    }
  }


  const submitHandler = (e) => {
    e.preventDefault();

    let isName = false;
    let isImg = false;
    let isCat = false;

    if (state.name === "") {
      isName = true
    }
    if (state.category === "") {
      isCat = true
    }
    if (!state.file) {
      isImg = true
    }

    if (isName || isImg || isCat) {
      setState({ ...state, isName, isImg, isCat })
    } else {
      let formData = new FormData();

      formData.append('name', state.name)
      formData.append('category', state.category.value._id)
      formData.append('image', state.image)

      Toast.loading("Loading....")
      addBrandRequest(formData, callback => {
        if (callback) {
          Toast.hide();
          addToast(callback.Message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          setTimeout(() => props.setShowbrandpopup(false), 2000)
        } else {
          Toast.hide();
          addToast(callback.Message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })
    }
  }

  return (
    <div className="make-offer-popup">
      <div className="offer-content register-new-product-wrapper">
        <button className="modal-closeBtn" onClick={() => props.closePopup(false)} type="button">
          <i className="fal fa-times-circle"></i>
        </button>
        <div className="register-popup add-brand seller-add-brand">
          <div className="offer-title">Add new brand</div>
          <form>
            <div className="label-txt">Brand Name</div>
            <input
              type="text"
              placeholder="Enter Brand Name"
              value={state.name}
              maxLength={30}
              required
              onChange={(event) => handlerOnChange(event, 'name')}
            />
            {state.isName && <span className="error-sell-text">Please enter brand name</span>}
            <div className="optns">
              <div className="label-txt">Select Category</div>
              <Select
                options={categoriesList.map(item => ({ value: item, label: item.name }))}
                placeholder={"Select Category"}
                value={state.category}
                onChange={(event) => handlerOnChange(event, 'category')}
                classNamePrefix={"select-opt"}
              />
              {state.isCat && <span className="error-sell-text">Please select categories</span>}
            </div>
            <div className="label-txt">Select Image</div>
            <div className='image_valid select-img-wrapper'><span className='note-text'>Note:</span> Please select only image file (eg: .png, .jpeg, .jpg)</div>
            <div className="file-inputs img-input-wrapper-block">
              <input
                type="file"
                className="file-in"
                name="upload"
                accept=".jpg,.png,.jpeg"
                required
                onChange={(event) => handlerOnChange(event, 'image')}

              />

              {state.file ? (
                <img src={state.file} alt="logo" />
              ) : (
                <i className="fas fa-image"></i>
              )}
            </div>
            {state.isImg && <span className="error-sell-text">Please choose image</span>}
            <div className="add-links">
              <button className="btn-purple" onClick={submitHandler}>
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddBrandPopup;
