import {
    SET_WISHLIST,
    SET_NEW_WISHLIST,
    REMOVE_WISHLIST,
    SET_SELLER_WISHLIST
} from './../actionsTypes'

const initialState = {
    wishlist: {},
    sellerWishlist: [],
}

const addnewwishlist = (state, payload) => {
    let newData = [...state.wishlist.data];
    newData.push(payload);
    return { ...state, wishlist: { ...state.wishlist, data: newData } }
}

const removewishlist = (state, payload) => {
    const newData = state.wishlist.data.filter(item => item.wishlist_id !== payload.wishlistId)
    return {
        ...state, wishlist: { ...state.wishlist, data: newData }
    }
}

const setWishList = (state, payload) => {
    if (payload.page === 1) {
        return {
            ...state,
            wishlist: {
                ...state.wishlist,
                data: payload.data,
                totalLenght: payload.totalLenght,
                page: payload.page,
            },
        };
    } else {
        return {
            ...state,
            wishlist: {
                ...state.wishlist,
                data: [
                    ...state.wishlist.data,
                    ...payload.data,
                ],
                totalLenght: payload.totalLenght,
                page: payload.page,
            },
        };
    }

};

const productListingInfo = (state = initialState, action) => {
    switch (action.type) {
        case SET_WISHLIST:
            return setWishList(state, action.payload)
        case SET_SELLER_WISHLIST:
            return { ...state, sellerWishlist: action.payload };
        case SET_NEW_WISHLIST:
            return addnewwishlist(state, action.payload);
        case REMOVE_WISHLIST:
            return removewishlist(state, action.payload);
        default:
            return state;
    }
}
export default productListingInfo;