import React, { useState } from "react";

const SearchFilter = (props) => {
    const [searchValue, setSearchValue] = useState("");


    const getSearched = (e) => {
        setSearchValue(e.target.value);
        props.loadSearchedData(e.target.value);
    }

    const refereshSearch = () => {
        props.loadSearchedData("");
    }
    return (
        <>

            <div className="filter-bar">
                <input type="text" className="form-control" onChange={(e) => getSearched(e)} value={props.getDefaultValue} placeholder="Search product" />
                {props.getDefaultValue ?
                    <i className="fa fa-times" aria-hidden="true" onClick={refereshSearch} style={{ cursor: "pointer" }}></i> :
                    null
                }

            </div>
        </>
    )
}

export default SearchFilter;