import {
  REMOVE_CART_FOR_WISHLIST,
  GET_CART,
  SAVE_STATIC_BANNER_IN_CART_PAGE,
} from "../actionsTypes";

const initialState = {
  getcart: {},
  static_block_data: [],
};

function removedCartWIshlist(state, payload) {
  let newState = [...state.getcart];
  newState = newState.filter(item => item.cartId !== payload)
  return { ...state, getcart: newState };
}

const Cart = (state = initialState, action) => {
  switch (action.type) {
    case GET_CART:
      return { ...state, getcart: action.payload };
    case SAVE_STATIC_BANNER_IN_CART_PAGE:
      return { ...state, static_block_data: action.payload };
    case REMOVE_CART_FOR_WISHLIST:
      return removedCartWIshlist(state, action.payload);
    default:
      return state;
  }
};
export default Cart;
