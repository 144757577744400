import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

const BrandMenu = (props) => {

  const [matches, setMatches] = React.useState(window.matchMedia("(min-width: 768px)").matches);
  const [visible, setVisible] = React.useState(8);



  const showMore = () => {
    setVisible(props.brands.length);
  }
  return (

    <>
      {matches ?
        <li
          className={`sublist-menu brand-sub-menu ${props.isBrandOpen ? "active" : ""}`}
          onMouseEnter={() => props.setBrandState(true)}
          onMouseLeave={() => props.setBrandState(false)}
        // onClick={() => props.setBrandState(!props.isBrandOpen)}
        >
          <span onClick={() => props.hideCategory()}>Brands</span>
          <ul className="sub-menu ">
            {props.isBrandOpen ? (
              <>

                <li className="cat-head-in-card">{"Shop by top brands"}</li>
                {
                  props.brands.slice(0, visible).map((i, j) => (
                    <li className="sub-cat-item" onClick={() => props.setBrandState(!props.isBrandOpen)} key={j}>
                      <Link
                        to={`/product-listing-page/${i.value.slug}?tagId=${i.value._id}`}
                        className={
                          props.queryString.brand === i.value_id ? `active` : ""
                        }
                        onClick={() => props.hideCategory()}
                      >
                        {i.label}
                        {/* <i className="fal fa-angle-right"></i> */}
                      </Link>

                      {/* <ul className="last-sub">
                  <li><a href="#">branded pen</a></li>
                  <li><a href="#">pen</a></li>
                  <li><a href="#">pen</a></li>
                  <li><a href="#">pen</a></li>
                </ul> */}
                    </li>

                  ))}

                {
                  props.brands.length > visible && <li className="view-btn brand-view-btn" onClick={showMore}>View all</li>
                }

              </>
            ) : null}
          </ul>
          <div className="arrow-outer-wrap">
            <div className="arrow-wrap"></div>
          </div>
        </li>
        :
        <>
          <li
            className={`sublist-menu brand-sub-menu ${props.isBrandOpen ? "active" : ""}`}
            // onMouseEnter={() => props.setBrandState(true)}
            // onMouseLeave={() => props.setBrandState(false)}
            onClick={() => props.setBrandState(!props.isBrandOpen)}
          >
            <span onClick={() => props.hideCategory()}>Brands</span>
            <ul className="sub-menu ">
              {props.isBrandOpen ? (
                <>

                  <li className="cat-head-in-card">{"Shop by top brands"}</li>
                  {props.brands.slice(0, visible).map((i, j) => (
                    <li className="sub-cat-item" key={j}>
                      <Link
                        to={`/product-listing-page/${i.value.slug}?tagId=${i.value._id}`}
                        className={
                          props.queryString.brand === i.value_id ? `active` : ""
                        }
                        onClick={() => props.hideCategory()}
                      >
                        {i.label}
                        {/* <i className="fal fa-angle-right"></i> */}
                      </Link>

                      {/* <ul className="last-sub">
                  <li><a href="#">branded pen</a></li>
                  <li><a href="#">pen</a></li>
                  <li><a href="#">pen</a></li>
                  <li><a href="#">pen</a></li>
                </ul> */}
                    </li>

                  ))}

                </>
              ) : null}
            </ul>
            <div className="arrow-outer-wrap">
              <div className="arrow-wrap"></div>
            </div>
          </li>
          {
            props.brands.length > visible && <li className="view-btn brand-view-btn" onClick={showMore}>View all</li>
          }
        </>

      }
    </>

  );
};


const mapStateToProps = (state, ownProps) => ({

  dashboardBlockData: state.dashboardInfo.dashboardBlockData,

});

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandMenu)
);

