import React from "react";

function Lumps(){
    return(
        <>
         <h1>Lumps Category</h1>
        </>
    )
}

export default Lumps;