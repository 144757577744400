import React, { useEffect, useState } from 'react'
import PendingOrderDetailRole from '../OrderDetails/PendingOrderDetailRole'
import { useParams } from 'react-router-dom'
import { axiosInstance } from '../../utils/Service'
import Loader from '../Loader'

const OrderModuleDetails = () => {
  const [completeOrderdetail, setCompleteOrderdetail] = useState(null)
  const [loading,setLoading] = useState(false)
  const { id } = useParams()
  useEffect(() => {
    let token = localStorage.getItem("tokenUser");
    setLoading(true)
    axiosInstance.post(`/order/orderDetails`, {
      orderId: id,
      type: "Processed"
    }, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    }).then((res) => {
      setLoading(false)
      setCompleteOrderdetail(res.data)
    }).catch(() => {
      setLoading(false)
    });
  }, [])
  return (
    <div className='blogtags-sec'>
      {loading && <Loader message={"...please wait"} />}
      <div className='container-main'>
        <div className='order-details order-detail-page'>
          {completeOrderdetail && <PendingOrderDetailRole setLoading={setLoading} completeOrderdetail={completeOrderdetail} orderdetail={{ ...completeOrderdetail.orderData, ...completeOrderdetail.Data }} />}
        </div>
      </div>
    </div>
  )
}

export default OrderModuleDetails