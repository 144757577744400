import React, { useState, useEffect, useRef, memo } from "react";
import Popup from "../Popup";
import { useToasts } from "react-toast-notifications";
import { uploadMultiImgs } from "./../../../store/actions/sellItem";

import { uploadFile } from "react-s3";

const S3_BUCKET = "inszn-ecom",
  REGION = "us-east-1",
  accessKeyId = "AKIARPELQCLSFEHBNS7K",
  secretAccessKey = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId,
  secretAccessKey,
};

const UploadImg = ({ setSelectImgHandler, prodImgs, setProdImgs }) => {
  const { addToast } = useToasts();
  const [selectedFile, setSelectedFile] = useState(null);

  const [drogImg, setDrogImg] = useState([]);
  const [imgIsDelete, setImgIsDelete] = useState(false);
  const [makeDefault, setMakeDefault] = useState(false);
  const [indexing, setIndex] = useState(null);
  const [defaultImage, setDefaultImage] = useState(true);

  const [deletedImages, setDeletedImages] = useState([]);

  let dropRef = useRef();

  const closePopup = () => {
    setImgIsDelete(false);
    setMakeDefault(false);
    setIndex(null);
  };

  const handleDraggedImage = (e) => {
    if (e.dataTransfer.files.length <= 8) {
      let newState = [...prodImgs];

      if (Object.keys(newState).length > 0) {
        const anotherState = [...newState, ...e.dataTransfer.files];
        setProdImgs(anotherState);
      } else {
        setProdImgs(e.dataTransfer.files);
      }
    } else {
      addToast("You can only select upto 8 Images", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    }
  };

  useEffect(() => {
    let div = dropRef.current;
    if (div !== null) {
      div.addEventListener("dragenter", handleDragIn);
      div.addEventListener("dragleave", handleDragOut);
      div.addEventListener("dragover", handleDrag);
      div.addEventListener("drop", handleDrop);
    }
  }, [prodImgs]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleDraggedImage(e);
    // e.dataTransfer.clearData();
  };

  const handlerImgChange = async (e) => {
    setDefaultImage(false);
    if (e.target.files.length > 8) {
      addToast("You can only select upto 8 Images", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else {
      let newState = [...prodImgs];
      if (Object.keys(newState).length > 0) {
        const anotherState = [...newState, ...e.target.files];
        setProdImgs(anotherState);
      } else {
        const anotherState = [...newState, ...e.target.files];
        setProdImgs(anotherState);
      }
    }
  };

  const removeImgHandler = () => {
    let newState = [...prodImgs];
    newState = newState.filter((item, i) => i !== indexing);
    setTimeout(() => {
      closePopup();
      setProdImgs(newState);
    }, 200);
  };

  const setDefault = () => {
    let newState = [...prodImgs];
    let newVal = newState[indexing];
    newState = newState.filter((item, i) => i !== indexing);
    newState.unshift(newVal);
    setProdImgs(newState);
    setTimeout(() => closePopup(), 200);
  };


  return (
    <>
      {imgIsDelete && (
        <Popup
          closePopup={closePopup}
          submitFun={removeImgHandler}
          content={"Do you really want to delete this Image"}
        />
      )}
      {makeDefault && (
        <Popup
          closePopup={closePopup}
          submitFun={setDefault}
          content={"Set this image as default Image ?"}
        />
      )}
      <div className="image-head">
        <h5> Upload images</h5>
        <div>
          <div className="uploadimg-desc">
            In your first photo set your item against a white background so it
            is clearly visible. One photo must be from the official website.
          </div>
          <div className="image_valid img-valiation-wrap">
            <span className="note-text">Note:</span> Please select only image
            file (eg: .png, .jpeg, .jpg)
          </div>
        </div>

        <div className="uploading-file-wrap upload-img-wrap add-product-upload-img">
          {prodImgs.length > 0 ? null : (
            <>
              {/* {defaultImage ? 
                <div className="rend-box" key={Math.random()}>
                <label className="custom-file-render">
                    <div className="image-text">Photo</div>
                    <img
                        className="display-image"
                        // src={item.original}
                        src="/assets/images/metal-2.jpg"
                        alt="product"
                    />
                </label>
            </div> : null    
                } */}
            </>
          )}

          {prodImgs.map((item, index) => (
            <div className="rend-box" key={Math.random()}>
              <label className="custom-file-render">
                <div className="image-text">
                  Photo {index + 1}
                  {/* {index === 0 && "Default Image"} */}
                </div>
                <img
                  className="display-image"
                  // src={item.original}
                  src={
                    item.original
                      ? item.original
                      : URL.createObjectURL(item)
                  }
                  alt="product"
                />
              </label>
              <div>
                {/* {index !== 0 && (
                                        <button
                                            className="default_btn default-maker"
                                            type='button'
                                            onClick={() => { setMakeDefault(true); setIndex(index) }}
                                        >
                                            Make Default
                                        </button>
                                    )} */}
                <button
                  type="button"
                  className="remove-img_btn remove-image"
                  onClick={(e) => {
                    setImgIsDelete(true);
                    setIndex(index);
                    setDeletedImages([...deletedImages, item._id]);
                  }}
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
              </div>
            </div>
          ))}
          {prodImgs.length !== 8 ? (
            <label
              ref={dropRef}
              className={
                prodImgs.length === 0
                  ? "custom-file-upload error-sell-input"
                  : "rend-box"
              }
            >
              <input
                type="file"
                accept=".jpg,.png,.jpeg"
                multiple
                onChange={handlerImgChange}
                capture="environment"
              />

              {prodImgs.length > 0 && prodImgs.length < 8 ? (
                <div className="rend-box input-boxes">
                  <i className="fal fa-plus"></i>
                  <div className="box-texts">Upload Image</div>
                </div>
              ) : null}
              {prodImgs.length === 0 && (
                <span className="upload-icn">
                  <img src="/assets/images/upload-icn.svg" alt="upload" />
                  <span className="browse">Browse to choose a file</span>
                  {/* <span className="drag">
                                            Or Drag and Drop upto 8 photos
                                        </span> */}
                </span>
              )}
            </label>
          ) : null}
          <br />
        </div>
      </div>
    </>
  );
};

export default memo(UploadImg);
