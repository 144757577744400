import {
  SET_USER_ADDRESS_INFO,
  SAVE_MY_ADDED_PRODUCT,
  GET_ORDER_LIST,
  SET_PROFILE_BANNER_INFO,
  SET_SELLER_PROFILE_INFO,
  SET_ORDER_DETAIL,
  SET_FOLLOW_FOLLOWER,
  SAVE_SELLER_ADDED_PRODUCT,
  SET_USER_VERIFY_MSG,
  SAVE_SALES_REPORT_TABLE,
  SAVE_USER_PERFORMANCE_REPORT,
  SET_SOLD_BY_ME,
  SET_RATING_GIVEN_BY_ME,
  SAVE_NOTIFICATION_IN_STORE,
  UPDATE_NOTIFICATION_LIST,
  SET_PRODUCT_LIKER_DATA,
  SET_RATING_REVIEW_ON_MY_PROD,
  SET_ADDED_ADDRESS_INFO,
  SET_UPDATED_ADDRESS_INFO,
  SET_PRODUCT_LIST_WISHLIST_SELLER_PROD
} from "./../actionsTypes";

const initialState = {
  userAddress: [],
  userAddedProduct: {},
  userProfileBanner: {},
  sellerDetails: [],
  sellerAddedProduct: [],
  userVerifyMsg: "",
  salesReport: [],
  userPerformanceStats: {},
  soldByMe: {},
  reviewAndRatingByMe: {},
  sellerReview: {},
  notification: {},
  likerData: {}
  // orderlist:[]
};

const saveSold = (state, payload) => {
  const temp = ['Completed', 'Offered', 'Processed', 'Returned', 'Cancelled', 'Pending']
  if (temp.includes(payload.type)) {
    return returnMyOrderSallerOrder(state, payload, "soldByMe");
  }
  else {
    return { ...state }
  }
};

const saveMyOrder = (state, payload) => {
  const temp = ['Pending', 'Offered', 'Completed', 'Processed', 'Returned', 'Cancelled']
  if (temp.includes(payload.type)) {
    return returnMyOrderSallerOrder(state, payload, "orderlist");
  }
  else {
    return { ...state }
  }
};
///////////////////////////////////////////////////////////////////

const returnMyOrderSallerOrder = (state, payload, key) => {
  if (payload.page === 1) {
    return {
      ...state, [key]: {
        ...state[key], [payload.type]: {
          product: payload.data, //payload.data,
          productCount: payload.all_records,//payload.TotalProducts,
          IMAGEPATH: payload.PRODUCTIMAGEPATH,
          page: payload.page,
          csv:payload.csv_file ? payload.csv_file : null
        },
      },
    };
  } else {
    return {
      ...state,
      [key]: {
        ...state[key],
        [payload.type]: {
          product: [...state[key][payload.type].product, ...payload.data],
          productCount: payload.all_records,//payload.TotalProducts,
          IMAGEPATH: payload.PRODUCTIMAGEPATH,
          page: payload.page,
          csv:payload.csv_file ? payload.csv_file : null
        },
      },
    };
  }
};

// ///////////////////////////////////////////////////////////////////////
const saveMyLising = (state, payload) => {

  if (payload.type === "active") {
    return returnList(state, payload, "active");
  } else if (payload.type === "inactive") {
    return returnList(state, payload, "inactive");
  } else if (payload.type === "draft") {
    return returnList(state, payload, "draft");
  } else if (payload.type === "unApproved") {
    return returnList(state, payload, "unApproved");
  } else if (payload.type === "rejected") {
    return returnList(state, payload, "rejected");
  }
};
const returnList = (state, payload, type) => {

  const data = payload.data.map((prod, i) => {
    // let priceObj = {}
    // if (prod.Price !== null) {
    //   Object.keys(prod.Price).map(i => typeof prod.Price[i] === 'object' && prod.Price[i] !== null
    //     ? priceObj[i] = prod.Price[i].$numberDecimal
    //     : priceObj[i] = prod.Price[i]
    //   );
    // }

    return {
      // ...prod.Price,
      //...priceObj,
      brandSlug: prod.brandData ? prod.brandData.slug : "",
      brandName: prod.brandData ? prod.brandData.name : "",
      categoryName: prod.categoryData ? prod.categoryData.name : "",
      categorySlug: prod.categoryData ? prod.categoryData.slug : "",
      title: prod.productName,
      deletedAt: prod.deletedAt,
      Moderate: prod.Moderate,
      mainImage: `${prod.default_image}`,
      //extra_img: (prod.Image[1] && prod.Image[1].original) || null,
      id: prod._id,
      is_Auction: prod.is_Auction,
      auctions_data: prod.auctions_data,
      delievery_charge: prod.delievery_charge,
      qty: prod.productWeight,
      statusTime: prod.change_status_time,
      like: prod.like,
      product_size: prod.productSize,
      watch_count: prod.watch_count,
      updatedAt: prod.updatedAt,
      Product_status: prod.Product_status ? prod.Product_status : false,
      product_base_price:prod.productPrice.$numberDecimal
    };
  });
  if (payload.page === 1) {
    return {
      ...state,
      userAddedProduct: {
        ...state.userAddedProduct,
        [type]: {
          product: data, //payload.data,
          productCount: payload.all_records,
          IMAGEPATH: payload.PRODUCTIMAGEPATH,
          page: payload.page,
          csv:payload.csv_file ? payload.csv_file : null
        },
      },
    };
  } else {
    return {
      ...state,
      userAddedProduct: {
        ...state.userAddedProduct,
        [type]: {
          product: [...state.userAddedProduct[type].product, ...data],
          productCount: payload.all_records,
          IMAGEPATH: payload.PRODUCTIMAGEPATH,
          page: payload.page,
          csv:payload.csv_file ? payload.csv_file : null
        },
      },
    };
  }
};
//////////////////////////////////////////////////////////////////////////////
const setReviewAndRating = (state, payload, type) => {
  if (!payload.page) return state
  else if (payload.page === 1) {
    return {
      ...state,
      [type]: {
        ...state[type],
        reviewList: payload.data, //payload.data,
        totalReview: payload.TotalProducts,
        page: payload.page,
        AverageRating: payload.AverageRating,
        sellerData: payload.sellerData ? payload.sellerData : {}
      },
    };
  } else {
    return {
      ...state,
      [type]: {
        ...state[type],
        reviewList: [...state[type].reviewList, ...payload.data],
        totalReview: payload.TotalProducts,
        page: payload.page,
        AverageRating: payload.AverageRating,
        sellerData: payload.sellerData ? payload.sellerData : {}
      },
    };
  }
};
//#######################
const saveNotification = (state, payload) => {
  if (payload.page === 1) {
    return {
      ...state,
      notification: {
        ...state.notification,
        notificationList: payload.notification_data, //payload.data,
        totalNotification: payload.TotalProducts,
        page: payload.page,
      },
    };
  } else {
    return {
      ...state,
      notification: {
        ...state.notification,
        notificationList: [
          ...state.notification.notificationList,
          ...payload.notification_data,
        ],
        totalNotification: payload.TotalProducts,
        page: payload.page,
      },
    };
  }
};
///######################
const saveSellerProduct = (state, payload) => {
  // 

  // const data = payload.data.map((prod, i) => {
  //   let priceObj = {}
  //   Object.keys(prod.Price).map(i => typeof prod.Price[i] === 'object' && prod.Price[i] !== null
  //     ? priceObj[i] = prod.Price[i].$numberDecimal
  //     : priceObj[i] = prod.Price[i]
  //   );
  //   return {
  //     // ...prod.Price,
  //     ...priceObj,
  //     brandSlug: prod.brandData ? prod.brandData.slug : "",
  //     brandName: prod.brandData ? prod.brandData.name : "",
  //     categoryName: prod.categoryData ? prod.categoryData.name : "",
  //     categorySlug: prod.categoryData ? prod.categoryData.slug : "",
  //     title: prod.Title,
  //     deletedAt: prod.deletedAt,
  //     Moderate: prod.Moderate,
  //     mainImage: `${prod.default_image}`,
  //     extra_img: (prod.Image[1] && prod.Image[1].original) || null,
  //     id: prod._id,
  //     is_Auction: prod.is_Auction,
  //     auctions_data: prod.auctions_data,
  //     delievery_charge: prod.delievery_charge,
  //     qty: prod.Quantity,
  //     statusTime: prod.change_status_time,
  //     like: prod.like,
  //     watch_count: prod.watch_count,
  //     updatedAt: prod.updatedAt,
  //     wishlistIcon: true,
  //     wishlist_id: prod.wishlist_id,
  //   };
  // });
  // // 

  // if (payload.page === 1) {
  //   return {
  //     ...state,
  //     sellerAddedProduct: {
  //       ...state.sellerAddedProduct,
  //       product: data, //payload.data,
  //       productCount: payload.all_records,
  //       IMAGEPATH: payload.PRODUCTIMAGEPATH,
  //       page: payload.page,
  //       filterData: payload.FilterData
  //     },
  //   };
  // } else {
  //   // 
  //   return {
  //     ...state,
  //     sellerAddedProduct: {
  //       ...state.sellerAddedProduct,
  //       product: [...state.sellerAddedProduct.product, ...data],
  //       productCount: payload.all_records,
  //       IMAGEPATH: payload.PRODUCTIMAGEPATH,
  //       page: payload.page,
  //     },
  //   };
  // }


  if (payload.page === 1) {
    return {
      ...state,
      sellerAddedProduct: {
        ...state.sellerAddedProduct,
        product: payload.data, //payload.data,
        productCount: payload.all_records,
        IMAGEPATH: payload.PRODUCTIMAGEPATH,
        page: payload.page,
        filterData: payload.FilterData,
        csv:payload.csv_file ? payload.csv_file : null
      },
    };
  } else {
    return {
      ...state,
      sellerAddedProduct: {
        ...state.sellerAddedProduct,
        product: [...state.sellerAddedProduct.product, ...payload.data],
        productCount: payload.all_records,
        IMAGEPATH: payload.PRODUCTIMAGEPATH,
        page: payload.page,
        csv:payload.csv_file ? payload.csv_file : null
      },
    };
  }
}

const setWishlist = (state, payload) => {

  let sState = { ...state };
  let newState = { ...state.sellerAddedProduct };

  newState = newState.product.map(item => {
    if (item._id === payload.productId) {
      return { ...item, wishlist: !item.wishlist }
    } else {
      return item;
    }
  })
  sState.sellerAddedProduct.product = newState;
  return {
    ...state, sellerAddedProduct: { ...state.sellerAddedProduct, product: newState }
  }
}



const setNewAddress = (state, payload) => {




  let newState = [...state.userAddress];
  newState.push(payload)
  return { ...state, userAddress: newState }
}

const setUpdatedAddress = (state, payload) => {
  let newState = [...state.userAddress];
  newState = newState.map(item => {
    if (item._id === payload._id) {
      return { ...item, ...payload };
    } else {
      return item;
    }
  })
  return { ...state, userAddress: newState }
}

const userProfile = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ADDRESS_INFO:
      return { ...state, userAddress: action.payload };
    case SET_ADDED_ADDRESS_INFO:
      return setNewAddress(state, action.payload);
    case SET_UPDATED_ADDRESS_INFO:
      return setUpdatedAddress(state, action.payload);
    case SET_PRODUCT_LIST_WISHLIST_SELLER_PROD:
      return setWishlist(state, action.payload);
    case SAVE_MY_ADDED_PRODUCT:
      return saveMyLising(state, action.payload);
    case SAVE_SELLER_ADDED_PRODUCT: //for seller profile
      return saveSellerProduct(state, action.payload)
    case SET_SOLD_BY_ME:
      return saveSold(state, action.payload);
    case GET_ORDER_LIST:
      return saveMyOrder(state, action.payload);
    case SET_ORDER_DETAIL:
      return { ...state, orderDetail: action.payload };
    case SET_PROFILE_BANNER_INFO:
      return { ...state, userProfileBanner: action.payload };
    case SET_SELLER_PROFILE_INFO:
      return { ...state, sellerDetails: action.payload };
    case SET_FOLLOW_FOLLOWER:
      return {
        ...state,
        sellerDetails: {
          ...state.sellerDetails,
          userdata: {
            ...state.sellerDetails.userdata,
            followers: action.payload.followers,
            following: action.payload.following,
          },
        },
      };
    case SET_USER_VERIFY_MSG:
      return { ...state, userVerifyMsg: action.payload };
    case SAVE_SALES_REPORT_TABLE:
      return { ...state, salesReport: action.payload };
    case SAVE_USER_PERFORMANCE_REPORT:
      return { ...state, userPerformanceStats: action.payload };
    case SET_RATING_GIVEN_BY_ME:
      return setReviewAndRating(state, action.payload, "reviewAndRatingByMe");
    case SET_RATING_REVIEW_ON_MY_PROD:
      return setReviewAndRating(state, action.payload, "sellerReview");
    case SAVE_NOTIFICATION_IN_STORE:
      return saveNotification(state, action.payload);
    case UPDATE_NOTIFICATION_LIST:
      return {
        ...state,
        notification: {
          ...state.notification,
          notificationList: action.payload,
        },
      };
    case SET_PRODUCT_LIKER_DATA:
      return { ...state, likerData: action.payload };
    default:
      return { ...state };
  }
};
export default userProfile;

