import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { offerDetail_seller, acceptOfferBySeller, rejectOfferBySeller, counterOffer_seller } from '../../../../store/actions/makeofferAction';
import Index from '../Myoffer';
import Toast from "light-toast";
import { priceWithSymbol } from "../../../../utils/constants";
import { parse, stringify } from 'query-string';
import { SpinnerLoader } from "../../../../utils/constants";
import { isEmpty } from '../../../../utils/commonFunctions';
import swal from 'sweetalert';
import moment from 'moment';
import Loader from '../../../Loader';
import { useToasts } from "react-toast-notifications";
import MakeOfferPopup from '../../../ProductDetail/MakeOfferPopup';



const OfferedDetails = ({ item,  setLoaderState }) => {
    const ref = useRef();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const history = useHistory()
    const getAddress = useSelector(store => store.userProfile.userAddress)
    const { addToast } = useToasts();

    const [showcounteroffer, setShowCounter] = useState(false);
    const [offerdetail, setOfferdetail] = useState({});
    const [price, setPrice] = useState("");
    const [offerId, setOfferId] = useState("");
    const [spinnerLoader, setSpinnerLoader] = useState(true);
    const [showReject, setShowReject] = useState(false);
    const [reason, setReason] = useState("")
    const [showLoader, setShowLoader] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [offerPopUp, setOfferPopup] = useState();


    const getOfferDetails = (type) => {

        offerDetail_seller({ productId: "7578658758", offerStatus: type, offerReqId: "87687586" }, callback => {
            // if (!isEmpty(callback)) {
            //     setOfferdetail(callback)
            //     setTimeout(() => setSpinnerLoader(false), 1000)
            // } else {
            //     setOfferdetail([])
            //     swal("This offer not exists.")
            //     setTimeout(() => setSpinnerLoader(false), 1000)
            // }
            setLoaderState()
        })(dispatch)
    }


    useEffect(() => {
        setShowLoader(true);
        setShowMessage("Please wait...")
        getOfferDetails("pending")
        setTimeout(() => {
            setShowLoader(false);
        }, 1000);
    }, [])

    let prodDetail = offerdetail[0] ? offerdetail[0].productData[0] : {};




    // const acceptOffer = (id) => {
    //     setShowLoader(true);
    //     setShowMessage("Please wait...");


    //     let payload = {
    //         offerRequestId: id,
    //     }
    //     acceptOfferBySeller(payload, callback => {
    //         if (callback.status === "success") {

    //             if (offerdetail[0].offerDetails.length === 1) {
    //                 history.push({
    //                     pathname: location.pathname,
    //                     state: { payload: { id: location.state.payload.id, offerStatus: "Accepted", offerReqId: location.state.payload.oid } }
    //                 })
    //                 setTimeout(() => getOfferDetails("Accepted"), 500)
    //             } else {
    //                 getOfferDetails(location.state.payload.name)
    //             }
    //             setShowLoader(false);
    //             addToast("Offer has been accepted successfully", {
    //                 appearance: "success",
    //                 autoDismissTimeout: 2500,
    //             });
    //         } else {
    //             setShowLoader(false);
    //             addToast(callback.message, {
    //                 appearance: "error",
    //                 autoDismissTimeout: 2500,
    //             });
    //         }
    //     })(dispatch);
    // }

    // const rejectClickHandler = (id) => {
    //     setShowReject(!showReject)
    //     setOfferId(id)
    // }


    // const rejectOffer = (e) => {
    //     e.preventDefault()
    //     setShowLoader(true);
    //     setShowMessage("Please wait...");

    //     let payload = {
    //         offerRequestId: offerId,
    //         rejectReason: reason
    //     }
    //     rejectOfferBySeller(payload, callback => {
    //         if (offerdetail[0].offerDetails.length === 1) {
    //             history.push({
    //                 pathname: location.pathname,
    //                 state: { payload: { id: location.state.payload.id, offerStatus: "Rejected", offerReqId: location.state.payload.oid } }
    //             })
    //             setTimeout(() => getOfferDetails("Rejected"), 500)
    //         } else {
    //             getOfferDetails(parse(location.search).type)
    //         }
    //         rejectClickHandler(null);
    //         setTimeout(() => {
    //             // getOfferDetails("Rejected")
    //             setShowLoader(false);
    //             addToast("Your offer has been rejected.", {
    //                 appearance: "success",
    //                 autoDismissTimeout: 2500,
    //             });
    //         }, 1000)
    //     })(dispatch);
    // }


    // const counterOffer = (e) => {
    //     e.preventDefault();
    //     let payload = {
    //         offerId,
    //         counterPrice: price
    //     }
    //     setShowLoader(true);
    //     setShowMessage("Please wait...");
    //     counterOffer_seller(payload, callback => {
    //         if (callback.status === "success") {
    //             getOfferDetails(parse(location.search).type)
    //             setOfferdetail(callback)
    //             setShowCounter(false);
    //             setOfferId("")
    //             setShowLoader(false);
    //             addToast("Price updated successfully", {
    //                 appearance: "success",
    //                 autoDismissTimeout: 2500,
    //             });

    //         } else {
    //             addToast(callback.message, {
    //                 appearance: "error",
    //                 autoDismissTimeout: 2500,
    //             });
    //         }
    //     })(dispatch)
    // }


    // const getDate = (date) => {
    //     let month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    //     let d = new Date(date);
    //     return month_names_short[d.getMonth()] + " " + d.getDate();
    // }

    // const getPaymentDate = (date) => {
    //     var currentdate = new Date();
    //     var datetime = currentdate.getDate() + "/"
    //         + (currentdate.getMonth() + 1) + "/"
    //         + currentdate.getFullYear() + " "
    //         + currentdate.getHours() + ":"
    //         + currentdate.getMinutes() + ":"
    //         + currentdate.getSeconds();

    //     return datetime
    // }


    return (
        <div className='payment-wrp-type'>
            <div className='table-offer-wrap'>
                <div className='table-scroll-new'>
                    <div className='table-offer-new'>
                        <div className='table-headings head-row'>
                            <div className='price-heading head-tble'>Price</div>
                            <div className='qty-heading head-tble'>Quantity</div>
                            <div className='payment-heading head-tble'>Payment Terms</div>
                            <div className='time-heading head-tble'>Time Left</div>
                            <div className='action-heading head-tble'>Action</div>
                        </div>
                        <div className='table-value-new values-row'>
                            <div className='price-value value-tab'>
                                <div className='mob-head'>Price</div>
                                <div className='price-type'>Original Price: &#x20b9;400/mt</div>
                                <div className='date'>22-05-2023</div>
                            </div>

                            <div className='qty-value value-tab'><div className='mob-head'>Quantity</div>1</div>
                            <div className='payment-value value-tab'><div className='mob-head'>Payment Terms</div>Next Day</div>
                            <div className='time-value value-tab'>
                                <div className='mob-head'>Time Left</div>
                                30 min</div>
                            <div className='action-value value-tab'>
                                <div className='mob-head'>Action</div>
                                <button className='btn'>Accept</button>
                                <button className='btn' onClick={() => setOfferPopup(true)}>Counter</button>
                                <button className='btn'>Reject</button>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
            </div>
            <div className='table-offer-wrap'>
                <div className='table-scroll-new'>
                    <div className='table-offer-new'>
                        <div className='table-headings head-row'>
                            <div className='price-heading head-tble'>Price</div>
                            <div className='qty-heading head-tble'>Quantity</div>
                            <div className='payment-heading head-tble'>Payment Terms</div>
                            <div className='time-heading head-tble'>Time Left</div>
                            <div className='action-heading head-tble'>Action</div>
                        </div>
                        <div className='table-value-new values-row'>
                            <div className='price-value value-tab'>
                                <div className='mob-head'>Price</div>
                                <div className='price-type'>Seller Counter Price: &#x20b9;400/mt</div>
                                <div className='date'>22-05-2023</div>
                            </div>

                            <div className='qty-value value-tab'><div className='mob-head'>Quantity</div>1</div>
                            <div className='payment-value value-tab'><div className='mob-head'>Payment Terms</div>Next Day</div>
                            <div className='time-value value-tab'>
                                <div className='mob-head'>Time Left</div>
                                30 min</div>
                            <div className='action-value value-tab'>
                                <div className='mob-head'>Action</div>
                                <button className='btn'>Accept</button>
                                <button className='btn' onClick={() => setOfferPopup(true)}>Counter</button>
                                <button className='btn'>Reject</button>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
            </div>
            <div className='table-offer-wrap'>
                <div className='table-scroll-new'>
                    <div className='table-offer-new'>
                        <div className='table-headings head-row'>
                            <div className='price-heading head-tble'>Price</div>
                            <div className='qty-heading head-tble'>Quantity</div>
                            <div className='payment-heading head-tble'>Payment Terms</div>
                            <div className='time-heading head-tble'>Time Left</div>
                            <div className='action-heading head-tble'>Action</div>
                        </div>
                        <div className='table-value-new values-row'>
                            <div className='price-value value-tab'>
                                <div className='mob-head'>Price</div>
                                <div className='price-type'>Buyer Counter Price: &#x20b9;400/mt</div>
                                <div className='date'>22-05-2023</div>
                            </div>

                            <div className='qty-value value-tab'><div className='mob-head'>Quantity</div>1</div>
                            <div className='payment-value value-tab'><div className='mob-head'>Payment Terms</div>Next Day</div>
                            <div className='time-value value-tab'>
                                <div className='mob-head'>Time Left</div>
                                30 min</div>
                            <div className='action-value value-tab'>
                                <div className='mob-head'>Action</div>
                                <button className='btn'>Accept</button>
                                <button className='btn' onClick={() => setOfferPopup(true)}>Counter</button>
                                <button className='btn'>Reject</button>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
            </div>
            {offerPopUp && <MakeOfferPopup setOfferPopup={setOfferPopup} />}
        </div>
    )
}
export default OfferedDetails;
