import {
  SET_USERNAME,
  SET_LOGGED_USER_INFO,
  SET_LOGIN_USER_ERROR,
  SET_SIGNUP_USER_ERROR,
  SET_USER_CONTACT_INFO,
  SET_NETWORK_STATUS
} from "../actionsTypes";

const initialState = {
  userData: {},
  mobileNo: "",
  error: {
    status: false,
    errData: "",
  },
  SignupError: {
    status: false,
    errData: "",
  },
  loggedUserInfo: {
    isLoggedIn: false,
    email: "",
    userId: "",
    // userId: '',
  },
  userContactInfo: {
    // IMAGEPATH:"/assets/images",
    // image:"blog-img02.png"
  },
  forgot: "",
  networkStatus: ""
};
const userInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERNAME:
      return { ...state, userName: action.payload };
    case SET_LOGGED_USER_INFO:
      return {
        ...state,
        loggedUserInfo: { ...state.loggedUserInfo, ...action.payload },
      };
    case SET_LOGIN_USER_ERROR:
      return { ...state, error: { ...state.error, ...action.payload } };
    case SET_SIGNUP_USER_ERROR:
      return { ...state, SignupError: { ...state.error, ...action.payload } };
    case SET_USER_CONTACT_INFO:
      return { ...state, userContactInfo: action.payload };
    case SET_NETWORK_STATUS:
      return { ...state, network: action.payload };
    default:
      return state;
  }
};
export default userInfo;
