import React from "react";
import "../ProductDetail/ProductDetails.css";
import { Link } from "react-router-dom";



const OrderSuccessPopup = (props) => {

  return (
    <div className="make-offer-popup order-placed-wrap">
      <div className="offer-content">
        <button
          className="modal-closeBtn"
          onClick={() => props.handlePopup()}
          type="button">
          <i className="fal fa-times-circle"></i>
        </button>
        <div className="success-mark"><i className="fal fa-check-circle"></i> </div>
        <div className="offer-title">
          <span className="order-placed-text"> Your order has been successfully placed. </span>
          <Link className="shop-now my-order-btn" to={{ pathname: "/profile/myorder" }}>
            Go to my order
          </Link>
        </div>
      </div>
    </div>
  );
};


export default OrderSuccessPopup;
