// import Banner from "../components/Dashboard/Banner";
export const apiUrl = {
  auth: {
    login: "auth/login",
    signup: "auth/newSignupApi",
    sellerRegisterationRequest: "auth/sellerRegisterationRequest",
    update: "contactus/contactDetail",
    joinWait: "homepage/waitlist",
    CheckUserStatus: "auth/checkUserStatus",
    changePassword: "auth/changePassword",
    socialLogin: "auth/socialLogin",
    forgotpassword: "auth/forgotPassword",
    forgotpasswordotp: "auth/verifyOtp",
    resend: "auth/resendotp",
    setPassword: "auth/reset/",
    resetPasswordafterForgot: "auth/reset",
    updatepassword: "auth/updatepassword",
    notification: "auth/getNotification",
    readnotification: "auth/readNotification", //notificationId=""
    deleteNotification: "auth/deleteNotification", //notificationId:""
    totalUnreadNotification: "auth/totalUnreadNotification", // method :post , userId:""
    OTPVerify: "auth/verifyOtp", //method: post params:{   "otp":"",  "userId":"" }
    OTPVerifyEmail: "auth/verifyEmail", //method: post params:{   "otp":"",  "userId":"" }
    OTPLoginVerify: "auth/verifyLoginOtp", //method: post params:{   "otp":"",  "userId":"" }
    mobileSignin: "auth/signupByMobile",
    resendMobileOTP: "auth/resendOtp", // post = > params:{ "userId":""   }
    resendEmailLink: "auth/resendEmailLink", // post = > params:{ "userId":""   }
    GetToken: "getToken", //GetToken api for shipping
    newsLetter: "homepage/newsletterSubscribe",
    getTotalUnreadMsgAndWishlistItem:
      "product/getTotalUnreadMsgAndWishlistItem", //get Method
    CreateStockEntryHeader: "createStockEntryHeader",
    GenerateLabel: "generateLabel",
    get_cms_data_new: "homepage/get_cms_data",
    GenerateManifest: "generateManifest",
    Seller_send_otp: "/auth/sendOtp",
    verifyotp_api: "/auth/verifyMobileOtp",
    send_otp_aadhar: "/auth/sendOtpOnAadharCardNumber",
    verify_aadhar_otp: "/auth/verifyAadharCardOtp",
    seller_registration: "/auth/sellerRegistration",
    update_seller_registration: "/auth/updateSellerProfile",
    introPageData: "/homepage/introPageData",
    homePageData: "/homepage/homepageData",
    draft: "auth/sellerRegistrationDraft",
    updateInformation: "auth/updateSellerProfile",
    aciveInactive: "auth/checkUserStatus",
    getSEller: "auth/getSellerDetails",
    forgetPassword: "auth/forgotPassword",
    otpVerify: "auth/verify_forgot_otp",
    updatePassword: "auth/update_Password",
  },
  performaInvoice: "/order/upload_performa_invoice",
  shippingModule: "order/create_shipping",
  updateShippingLot: "/order/update_shipping_lot",
  receiveAllOrder: "order/receiveAllOrders",
  makePay: {
    payUrl: "order/sendDocument",
    confirmPay: "order/confirmPayment",
  },
  addAddressUser: "auth/add_address",
  productcoupon: {},

  careerDetailUrl: {
    careerDetail: "career/searchCareerDetails",
    experienceDetail: "career/searchExperienceAndLocation",
    jobDetail: "career/jobDetail",
    applyJob: "career/applyjob",
  },
  bazarList: {
    bazarStoreList: "auth/getMarketList",
  },
  product: {
    trends: "product/getTrends",
  },
  category: {
    category: "category/category",
  },
  brand: {
    brand: "brands/brand",
    subCategorySpecifBrand: "brands/brandByCategory",
  },
  contact: {
    user: "contactus/user",
    contactDetail: "contactus/contactDetail",
  },
  userAddress: {
    getUserAddress: "/auth/getuserAddress", //method:{post}
    addUserAddress: "/auth/adduserAddress", // method:{post}
    updateUserAddress: "auth/updateuserAddress", //method:{put}  all,addId:string as address id
    deleteUserAddress: "auth/deleteAddress", //method:{post}  all,addId:string as address id
    useDefault: "auth/changeDefaultAddress",
    userAddressBySeller: "auth/getUserAddressbySeller",
  },
  sellItem: {
    sellItem: "product/sellItems", //{draftId:"60b60b921900b573bb515de9"    (if i complete drafted)}
    draftItem: "product/saveasdraft", //method:post  params:{ all filled data ,draftId:"60b60b921900b573bb515de9"(if updating draft) }
    getOtherCost: "product/getOtherCosts", //method:post params:{ categoryId, price  }
    addBrand: "brands/requestBrand", //method: post {name:,sellerId: ,category,image
    getGSTcategory: "product/getGst", //method :get
    addAtt: "product/addAttribute",
    uploadimgs: "product/uploadImage",
  },
  shippingFrom: {
    country: "category/country",
    city: "auth/getCityList",
  },
  homepage: {
    all: "homepage/homepage", // method {get},give outer component data not product
    banner: "banner/bannerList",
    getProduct: "homepage/home_page_data", // method {post} get product according category
    productList: "product/productList", //
    // getCMS : "homepage/get_AllCms_data"
  },

  filterUrl: {
    getFilterDataUrl: "category/attributeBycatId",
  },

  attribute: {
    getAll: "product/attributes", // get attributes for sell item page
    getAttributeByCategory: "category/attributeBycatId", /// for product listing page
  },
  blog: {
    getAllBlog: "blog/allBlog", //method = {post}  params:{ data: "7-2021"   (not compulsory send it only on filter blog  by a month}
    getRecentBlog: "blog/getRecentblog", //method:post params:{  blogCategoryId:""}
    blogDetail: "blog/blogDetail", //method:get  "blog/blogDetail/:id"
    likeBlog: "blog/blogLike", //method: post params: {   blogId }
    blogArchive: "blog/archives", ///method :get
    blogcreateNewComment: "blog/createNewComment", //method: post params: {   blogId }
  },
  listBlog: {
    getList: "brands/blogs", //method {POST} for blog list...//PAYLOAD:{ blogcategorySlug} for filter
    blogCategoryName: "brands/blogcategory", //method {get} for list of blog category...
  },
  detailedBlog: {
    getBlog: "brands/blogDetails", //after click on specific blog
  },
  wishlist: {
    delete: "product/removeFromWishlist", //method :{ Post },   Payload :{  wishlistId: String }
    add: "product/addToWishlist", //method :{ Post },  Payload :{   productId :string  }
    get: "product/getWishlist", //method :{ Post },
  },
  userAddedProduct: {
    //##########get all seller added product############
    get: "product/sellerProducts", //Payload :{ userId:string in selllerProduct }   method:{Post }
  },
  getorderlist: {
    //##########get all seller added product############
    get: "order/myOrder", //   method:{Post }
  },
  orderdetail: {
    //##########get all seller added product############
    get: "order/orderDetails", //   method:{Post }
  },
  placeOrder: {
    request: "order/placeOrder", //Method: {Post}  order place
    couponCodeVerify: "product/verifyCoupon", //method:post, "couponName":"COUd6646d94718d7",,"categoryId": "","productId":""
    offerPriceOptions: "order/showOfferPriceVarients", //method: post   params:{  "productId":""  }
    getCouponsEndPoint: "order/availableCoupons",
  },
  couponcode: {
    availablecoupon: "order/availableCoupons", // Method : {POST} for coupon
  },

  // coupon: {
  //   orderCoupon: "product/verify_coupon",
  // },
  profile: {
    productList: "product/allProductNames",
    allProductAffilationNames: "product/allProductAffilationNames",
    profileBannerInfo: "order/myProfile",
    verifyAccount: "auth/verifySeller", //method:{ POST }, PAYLOAD :{ params:{ gst,companyName,accountNumber,
    //ifscCode,accountHolderName,userId, termCondition(true/ false),pdf(pdf file)}",
    downloadSalesReport: "order/downloadSalesReport", //method-post, { "year":"2021" ->optional}
    downloadTRansactionReport: "order/withdrawlHistory",
    performanceData: "order/statistics", //method: post // "sortBy":"Last_365_days"
    sellerOrder: "order/SellerOrder", //method:post // "page":1
    orderCancel: "order/cancelOrder", //method:post,params: {"orderId":"", "reason":"string",validReasons:[reasonId]}
    orderReturn: "order/returnOrder", //method:post,params: {"orderId":"", "reason":"string",validReasons:[reasonId]}
    cancelReason: "order/cancelOrderReason", //method :get
    returnReason: "order/returnOrderReason", //method :get
    changeOrderStatus: "order/statuschange", //method: post params: {"status":"Packed","orderId":""}
    sendInvoice: "order/sendInvoice", //method:post params:{"orderId":""  }
    productLiker: "product/allUsersDetailByLike", //method: post  params:{  "productId":""   }
    createCoupon: "product/promoteproduct", //methods:post  params:{"productId":"","userId":"","sellerId":"my id",  "discountPrice":"2000"
    editCoupon: "product/editCoupon", //method:post,params: {couponId, discountPercentage, fromDate, toDate }
    sellerProfile: "product/sellerProfile", //method: post params: {  "sellerId":""  }
    getBank: "auth/getBank", //get
    editBank: "auth/addBank", // post , params:{ bankName, ifsc, accountNumber, reAccountNumber, accountHolderName }
    sellerWalletDetails: "product/sellerWalletDetail", // method: post params:{"sellerId":"608be7c608c7de2367c89638"}
    send_money_request_to_admin: "order/requestFromWalletMoney", //method:post params:{  requestamount }
    transaction_history: "order/withdrawlHistory", // method:postparams:{start_day:"09/15/2021", (pass to get excel report )last_day:"09/17/2021", (pass to get excel report) bankAccount, (optional)page (optional)}

    acceptOrder: "order/acceptOrder", //method-post,   params: {   "orderId":["6e"] // _id of orderitem}
    rejectOrder: "order/rejectOrder", //method - post ,params: {  "orderId":["2232"],   "reason":"Not  good " }
    acceptOffer: "order/acceptOffer", //method - post ,params: {  "orderId":["2323"] // _id of orderitem  }
    rejectOffer: "order/rejectOffer", //method - post ,params: {  "orderId":["2232"],   "reason":"Not  good " }

    promoteProductPrice: "product/promoteProductPrice", // method -post ,params :{ "productId":"",  "currentStorePrice":"","sellPrice":""}
    SenderRecevier: "createAccountOwnerContacts", // Sender/Receiver Shipping Api
    seller: "auth/getSellerDetails",
    getBnk: "auth/getBank",
    myBankUrl: "auth/myBank",
    deleteBankUrl: "auth/deleteBank",
    editBankUrl: "auth/editBank",
    affiliations: "auth/getAffiliations",
    draftData: "auth/DraftData",
    sellerDetails: "auth/getSellerDetails",
    userProfile: "/auth/user_profile_details",
  },

  messenger: {
    allMessage: "order/getallmessage", //method: post
    get: "order/getMessages", //post "senderId":"","productId":""
    send: "order/sendmessages", ///method: post,params: {"message":"","receiverId":"opponent id","productId":""}
    offer: "order/generateOfferInChat",
  },
  seller: {
    follow: "auth/follow", //method=POST  Payload: {sellerId, customerId}
    unfollow: "auth/unfollow", //method=POST Payload: {sellerId, customerId}
    setRating: "auth/setRating", //method=POST  Payload :{ userId ,sellerId ,rating}
  },
  invoice: {
    download: "order/inVoice", ///METHOD: {post}   PAYLOAD: {orderItemId}
  },
  editAddedProduct: {
    loadProduct: "product/editProductDetail/", //post  //product/productDetail/"prodId"
    loadUnApprovedProd: "product/editProductDetail/", //post  //product/productDetail/"prodId"
    deleteImage: "product/removeImage/", // Method:{Post} Payload : productId(string),product_image( image name for delete image)
    deleteProduct: "product/actionOnProduct", // method={delete} /product/deleteProduct/:productId
    updateProduct: "product/updateProduct/", // method {post} product/updateProduct/:productId
    changeProuctStatus: "product/actionOnProduct", //method:{post} ,  "status":"inactive","productId":""
    getDraftProd: "product/draftDetail/", //   "/60b60b921900b573bb515de9"draftId
    getGST: "auth/verifyGst",
    getPan: "auth/checkPanNumberValid",
    getAccount: "auth/checkBankAccountValid",
    addProduct: "product/sellItems",
  },
  footerHeading: {
    api: "homepage/get_cms_data", // { slug:"return_policy"/"privacy_policy"/"legal_information"/"terms_conditions" }
    // api:"homepage/getFooterSection",
    section: "homepage/getFooterSection", //method : post
  },
  testimonial: {
    api: "brands/testimonial", // method :get
  },
  orderReview: {
    // add: "order/addreview", //method :post params: {user_id,seller_id,product_id,rating,comment}
    add: "auth/add_user_review",
    edit: "order/editreview", //method:post, params: {user_id,seller_id,product_id,rating,comment,id}
    delete: "order/deletereview", //method:post,params:{id}
    loadAllreview: "order/getallreview", //method:get
  },
  header: {
    saveSearch: "product/saveSearch", //method postparams : {"data":[url:"",text:""],"userId":"608be7c608c7de2367c89638", "browser_ip":"192.23.325.5"
    popular: "product/popularProducts", //method: pos
  },
  productDetail: {
    aboutProduct: "product/productDetail/", //method - post, /payload.id,param=>
    verifyProduct: "product/verifyProduct", // method -post
    bidding: "product/bidding", // method -post
    prodQuestion: "qnAs/productQuestions", // method -post
    questionList: "qnAs/questionList", // method -post
    updateAnswer: "qnAs/updateAnswer", // method -post
    addToWatchList: "product/addToWatchlist", // method -post
    removeFromWatchList: "product/removeFromWatchlist", // method -post
    productReport: "qnAs/reportproduct", //method: post ,params:{ "report_comment":"","userId":"","productId":"","sellerId":""}
    productOwner: "product/Productsbyuser", // method: post,params:{  "userId":"" }
    reviewByProdId: "order/getallreviewById", // method: post, params: {"productId":""}
    getReviewById: "order/getallreviewById", //method: post,  params: {sellerId or  userId  }
    similarProduct: "product/similarProducts", //method: post, params:  {"categoryId":"", "productId":"","price":""}
    moreProductFromThisSeller: "product/sellerProducts", // params: { "userId":"",  "productId":"except this prod id"}
    likePoduct: "product/like", //method:post  params:{ "productId":"", }
    shipping_cost: "getCostEstimation", // "Get Cost Estimation api"
    latestSellerProduct: "order/latestSellProduct",
    follower: "getFollowsData",
    fStatus: "follow",
    ufStatus: "unfollow",
    offerRequest: "offers/offerRequest",
    sellerOfferRequestList: "offers/sellerOfferRequestList",
    buyerOfferRequestList: "offers/buyerOfferRequestList",
    counterOfferRequest: "offers/counterOfferRequest", //payload: { offerId, counterPrice }
    rejectOfferBySeller: "offers/rejectOfferBySeller", //payload: { offerRequestId }
    rejectOfferByBuyer: "offers/rejectOfferByBuyer", //payload: { offerRequestId }
    updateOfferRequest: "offers/updateOfferRequest", //payload: { offerRequestId, price }
    offerDetailsSeller: "offers/sellerOfferRequestDetails", //payload: { productId }
    offerDetailsBuyer: "offers/buyerOfferRequestDetails", //payload: { offerRequestId }
    estimationTime: "product/checkEstimationTime",
    offerReqShipping: "offers/offerReqShipping",
    accpetOffer_seller: "order/generateOfferInChat",
    offer_request_payment_complete: "offers/offer_request_payment_complete",
    getProductList: "product/productList",
    generateShipping: "order/generateShipping",
    upTrackStatus: "order/shippingStatus",
  },

  banner: {
    downloadBanner: "banner/getdownloadBanner", //method get
  },

  cart: {
    getCart: "product/getCart",
    deleteCart: "product/deleteCart",
    addToCart: "product/addToCart",
    updateCart: "product/updateCart",
    qtyUpdate: "product/changeCartQuantity", // method: post params: { cartId,quantity}
    shippingCost: "order/estimateShippingCost",
    removeAllCart: "order/estimateShippingCost",
  },

  contactUs: {
    getContactReasons: "contactus/getContactReason", //method: get
    sendForm: "contactus/contactUsDetail", //method: post ,params: {"name","email",mobile,concern,typeOfConcern
  },
  career: {
    currentOpening: "career/applyjob", //
  },
};
