import { axiosInstance } from "../../utils/Service";
import { apiUrl } from "../../utils/urlEndpoints";

export const forgetPassword = (payload, setShowLoader, addToast, setEnterMobileScreen, setOtp) => {
    let token = localStorage.getItem("token");
    axiosInstance
        .post(apiUrl.auth.forgetPassword, payload, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.data.status === "success") {
                addToast(response.data.message, {
                    appearance: "success",
                    autoDismissTimeout: 2500,
                });
                setShowLoader(false)
                setEnterMobileScreen(false)
                setOtp(true)
            }
            else{
                addToast(response.data.message, {
                    appearance: "error",
                    autoDismissTimeout: 2500,
                });
                setShowLoader(false)
            }
        })
        .catch(e => {
            setShowLoader(false)
        })
};