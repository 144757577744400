import React, { useEffect, useState } from "react";
import SellerInfo from "./SellerInfo";
import { connect } from "react-redux";
import {
  addCart,
  removeAllProdFromCart,
  getCart,
} from "./../../store/actions/cartAction";
import "./ProductDetails.css";
import ReactImageMagnify from "react-image-magnify";
import MakeOffer from "./MakeOffer";
import { Authorization } from "../../utils/Authorization";
import { setLoginPopupStatus } from "../../store/actions/common";
import Toast from "light-toast";
import { withRouter } from "react-router-dom";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { Link } from "react-router-dom";
import { timeSince, priceWithSymbol } from "../../utils/constants";
import { truncateText } from "../../utils/commonFunctions";
import { timeFunction } from "../Profile/MainProfile/Notification/function";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
} from "react-share";
import Modal from "react-bootstrap/Modal";
import { parse } from "query-string";
import { deleteWishList } from "../../store/actions/Wishlist";
import Popup from "../SellAnItem/Popup";
import Slider from "react-slick";
import {
  reportProduct,
  likeProduct,
  followerdata,
  checkEstimationTime,
} from "../../store/actions/productDetails";
import { useToasts } from "react-toast-notifications";
import { get } from "lodash";
import { getTotalUnreadMsgAndWishlistItem } from "../../store/actions/common";
import { Collapsible } from "collapsible-react-component";
import Alert from "./Alert";
import "collapsible-react-component/dist/index.css";
import ProductReview, { RatingStar } from "./productReview";
import Loader from "../Loader";
import moment from "moment";
import TableData from "./TableData";
import { data } from "jquery";
import { date } from "yup";
import MakeOfferPopup from "./MakeOfferPopup";
import { isShowBuyButton } from "../../utils/commonFunctions";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

const isWireRoadProduct = (categoryName) => {
  console.log('categoryName',categoryName);
  return [
    "6304d6ef48bf984c711e23ef",
    "6304d69b48bf984c711e23d0",
    "6304d4d848bf984c711e23b1",
  ].includes(categoryName)
}

const formattedDate = (createdAt) => {
  const createdAtT = moment.utc(createdAt);
  return createdAtT.format("DD/MM/YYYY h:mm a");
};

class ProductSellerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rates: 0,
      selectedImage: "",
      open: false,
      openFabric: false,
      openMakeOffer: false,
      isVarify: true,
      hideSizeGuide: false,
      openDesc: false,
      current: 0,
      wishList: [],
      wishlist_ID: null,
      notifyMePopup: false,
      reportPopup: false,
      likeThumb: false,
      likeCount: 0,
      showShare: false,
      copied: false,
      matches: window.matchMedia("(min-width:768px)").matches,
      open: false,
      openDes: false,
      openDes: false,
      Class: "",
      classDes: "",
      isOtherSeller: false,
      activeDescription: true,
      activeAdditional: false,
      activePreview: false,
      productId: "",
      getPinCode: "",
      exactDilMessage: true,
      dileveryTime1: "",
      dileveryTime2: "",
      isLocalCart: false,
      errorMsg: "",
      showLoader: false,
      loaderMessage: "",
      getProfileStatus: "",
      viewStatus: false,
    };
  }

  handleCloseMakeOffer = () => {
    this.setState({ openMakeOffer: false });
  };

  removeWishList = (wishlist_id) => {
    this.props.deleteFromWishList({ wishlistId: wishlist_id });
  };

  copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ copied: true });
  };

  closeAlert = () => {
    this.setState({ isOtherSeller: false });
  };

  removeCartAndSaveProdCart = () => {
    let query = parse(this.props.location.search);
    let userId = localStorage.getItem("userId");

    if (userId) {
      let payload = [
        {
          product_id: this.props.details.data[0]._id,
          save_for_later: false,
          sellerId: this.props.details.data[0].userData[0]._id,
          user_id: userId,
          isLocalProduct: query.cat === "Local",
        },
      ];
      Toast.loading("Loading...");
      this.props.removeAllProdFromCart((callback) => {
        this.props.addCart({ cart: payload }, (callback1) => {
          if (callback1.status === "success") {
            Toast.success("Added to Cart", 1000);
            setTimeout(
              () => this.props.history.push({ pathname: "/my-cart" }),
              1500
            );
          } else {
            Toast.fail(callback1.message, 1000);
          }
        });
      });
    } else {
      let val = this.props.details.data[0];
      let cart = [];
      let query = parse(this.props.location.search);

      let payload = {
        brandData: val.brandData,
        Title: val.Title,
        Price: val.Price,
        default_image: val.default_image,
        productId: val._id,
        sellerId: val.userData,
        wishlist: false,
        Quantity: 1,
        prodQuantity: val.Quantity,
        isLocalProduct: query.cat === "Local",
      };
      cart.push(payload);

      localStorage.setItem("bazar_cart", JSON.stringify(cart));
      Toast.success("Added to Cart", 1000);
      setTimeout(() => this.props.getCart(), 800);
      setTimeout(() => this.props.history.push({ pathname: "/my-cart" }), 1500);
    }
  };

  handleAddCart = (val) => {
    let checkLoginStatus = Authorization();
    let userId = localStorage.getItem("userId");

    let sellersId = this.props.cartData
      ? this.props.cartData.map((item) => item.sellerId[0]._id)
      : [];

    if (sellersId[0] === val.userData[0]._id || !sellersId[0]) {
      if (checkLoginStatus.status) {
        let query = parse(this.props.location.search);

        let payload = [
          {
            product_id: val._id,
            Quantity: val.minOrderQty,
            sellerId: val.userData[0]._id,
          },
        ];
        this.setState({ showLoader: true, loaderMessage: "Please wait..." });
        this.props.addCart({ cart: payload }, (callback) => {
          if (callback.status === "success") {
            this.setState({ showLoader: false });
            this.props.addToast("Added to Cart", {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            setTimeout(
              () => this.props.history.push({ pathname: "/my-cart" }),
              1500
            );
          } else {
            if (callback.case === "local") {
              this.setState({ showLoader: false });
              this.setState({
                isOtherSeller: true,
                isLocalCart: true,
                errorMsg: callback.message,
              });
            } else {
              this.setState({ showLoader: false });
              this.props.addToast(callback.message, {
                appearance: "error",
                autoDismissTimeout: 2500,
              });
            }
          }
        });
      } else {
        let query = parse(this.props.location.search);
        let cart = [];
        if (localStorage.getItem("bazar_cart")) {
          cart = JSON.parse(localStorage.getItem("bazar_cart"));
        }

        if (cart.length > 0) {
          if (cart[0].isLocalProduct === (query.cat === "Local")) {
            if (cart[0].zipCode !== val.zip_code) {
              this.setState({
                isOtherSeller: true,
                isLocalCart: true,
                errorMsg:
                  "Another product is available in cart with different zip code",
              });
              return false;
            }
          } else {
            if (cart[0].isLocalProduct) {
              Toast.hide();
              this.setState({
                isOtherSeller: true,
                isLocalCart: true,
                errorMsg: "Local order exist in your cart. First remove them",
              });
            } else {
              this.setState({
                isOtherSeller: true,
                isLocalCart: true,
                errorMsg: "Normal order exist in your cart. First remove them",
              });
            }
            return false;
          }
        }

        let payload = {
          brandData: val.brandData,
          Title: val.Title,
          Price: val.Price,
          default_image: val.default_image,
          productId: val._id,
          sellerId: val.userData,
          wishlist: false,
          Quantity: 1,
          prodQuantity: val.Quantity,
          isLocalProduct: query.cat === "Local",
          zipCode: val.zip_code,
        };
        cart.push(payload);
        localStorage.setItem("bazar_cart", JSON.stringify(cart));
      }
    } else {
      this.setState({
        isOtherSeller: true,
        isLocalCart: false,
        errorMsg: "different seller",
      });
    }
    this.props.getCart();
  };

  removeExistsOrder = () => {
    this.setState({
      isOtherSeller: true,
      isLocalCart: true,
      errorMsg: "Normal order exist in your cart. First remove them",
    });
  };

  componentDidUpdate(PreProps) {
    if (PreProps.details !== this.props.details) {
      this.setState({
        selectedImage: this.props.details.data[0].default_image,
        likeThumb: this.props.details.data[0].isLiked,
        likeCount: this.props.details.data[0].like,
        rates: this.props.details.average_rating,
      });
    }
    const propsWishList = get(this.props.wishlist, ["data"], []);
    if (
      !(propsWishList.length === this.state.wishList.length) &&
      propsWishList.length !== 0 &&
      this.props.details
    ) {
      const Wish = propsWishList.filter(
        (item) => item.product_id === this.props.details.data[0]._id
      );
      this.setState({
        wishList: propsWishList,
        wishlist_ID: Wish[0] ? Wish[0].wishlist_id : null,
      });
    }
  }

  changeImage = (e) => {
    e.preventDefault();
    let imgsrc;
    const selectedImage = e.target.src;
    if (selectedImage === undefined) {
      imgsrc = e.target.children[0].src;
    } else {
      imgsrc = selectedImage;
    }
    this.setState({ selectedImage: imgsrc });
  };

  description = () => {
    this.setState({ openDes: !this.state.openDes });
    if (this.state.openDes) {
      this.setState({ classDes: "" });
    } else {
      this.setState({ classDes: "selectedDes" });
    }
  };

  imageProps = () => {
    return {
      smallImage: {
        alt: "img",
        width: 300,
        height: 400,
        objectFit: "contain",
        // isFluidWidth: true,
        objectPosition: "center",
        src: this.state.selectedImage,
      },
      largeImage: {
        src: this.state.selectedImage,
        width: 1200,
        height: 1200,
      },
      enlargedImageContainerStyle: { background: "#fff", zIndex: 9 },
    };
  };

  verifyProduct = () => {
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      let userId = localStorage.getItem("userId");
      let payload = {
        userId: userId,
        product_id: this.props.details.data[0]._id,
      };
      this.props.verifyproduct(payload, (completion) => {
        if (completion.mess === "already verify by this user") {
          alert("Already verified by this user");
        } else {
          alert("Thank you for verification.");
        }
      });
    } else {
      this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    }
  };

  likeProduct = (type) => {
    const { likeThumb, likeCount } = this.state;
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      if (this.props.sellerInfo.userDetail[0]._id !== checkLoginStatus.userId) {
        // this is not my product
        this.props.likeProduct(
          {
            productId: this.props.details.data[0]._id,
            type: type,
          },
          (status) => {
            if (status) {
              this.setState({
                likeThumb: !likeThumb,
                likeCount: !likeThumb ? likeCount + 1 : likeCount - 1,
              });

              setTimeout(() => {
                this.props.getTotalUnreadMsgAndWishlistItem((callback) => {});
              }, 1000);
            }
          }
        );
      }
    } else {
      this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    }
  };

  gotoChat = (link, search) => {
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      if (this.props.sellerInfo.userDetail[0]._id !== checkLoginStatus.userId) {
        // this is not my product
        this.props.history.push({
          pathname: link,
          state: { chatPayload: search },
        });
      }
    } else
      this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
  };

  chooseAttr = (attr, value) => {
    this.setState({ [attr]: value });
  };

  makeOffer = () => {
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      this.setState({ openMakeOffer: true });
    } else {
      this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    }
  };

  checkAlreadyCart = () => {
    let gotocart = false;
    this.props.cartData &&
      this.props.cartData.length > 0 &&
      this.props.cartData.forEach((item) => {
        if (item.productId === this.props.details.data[0]._id) {
          gotocart = true;
        }
      });
    return gotocart;
  };

  gotocart = () => {
    this.props.history.push({
      pathname: "/my-cart",
    });
  };

  checkDisabled = () => {
    let disabled = false;
    if (this.props.details.data[0].deletedAt === 1) {
      disabled = true;
    } else if (this.props.details.data[0].Moderate === 1) {
      disabled = true;
    } else if (this.props.details.data[0].Quantity <= 0) {
      disabled = true;
    } else {
      disabled = false;
    }
    return disabled;
  };
  next = () => {
    this.slider.slickNext();
  };
  previous = () => {
    this.slider.slickPrev();
  };

  componentDidMount() {
    let status = localStorage.getItem("approved");
    this.setState({ getProfileStatus: status });
    var date = this.props.location.pathname;
    var datearray = date.split("/");
    this.setState({ productId: datearray[3] });
    const handler = (e) => this.setState({ matches: e.matches });
  }

  estimationTime = (loadItem) => {
    this.setState({
      showLoader: true,
      loaderMessage: "Fetching Estimated Time...",
    });
    const payload = {
      productId: loadItem,
    };
    this.props.checkEstimationTime(payload, (status) => {
      if (status) {
        this.setState({ dileveryTime1: status.maximumDays, showLoader: false });
        this.setState({ dileveryTime2: status.minimumDays });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  handlepincode = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value.length > 0) {
        this.setState({ exactDilMessage: false });
        this.setState({
          getPinCode: e.target.value,
          dileveryTime1: "",
          dileveryTime2: "",
        });
      } else {
        this.setState({ getPinCode: e.target.value });
        this.setState({ dileveryTime1: "" });
        this.setState({ dileveryTime2: "" });
        this.setState({ exactDilMessage: true });
      }
    }
  };

  checkEstimate = (e) => {
    if (this.state.getPinCode == "") {
      return this.props.addToast("Please enter pincode", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (this.state.getPinCode.length < 6) {
      return this.props.addToast("Please enter six digit pincode", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    }
    this.setState({
      showLoader: true,
      loaderMessage: "Fetching Estimated Time...",
    });
    const payload = {
      productId: this.state.productId,
      zipCode: this.state.getPinCode,
    };
    this.props.checkEstimationTime(payload, (status) => {
      if (status.status === "success") {
        Toast.hide();
        this.setState({ exactDilMessage: false });
        let splitValue1 = status.maximumDays.split(" ");
        let splitValue2 = status.minimumDays.split(" ");

        this.setState({ dileveryTime1: splitValue1[0], showLoader: false });
        this.setState({ dileveryTime2: splitValue2[0] });
      } else {
        this.setState({ showLoader: false });
        Toast.hide();
        this.props.addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    });
  };
  collapseNow = () => {
    this.setState({ open: !this.state.open });
    if (this.state.open) {
      this.setState({ Class: "" });
    } else {
      this.setState({ Class: "selected" });
    }
  };

  buyNowHandler = (e, url) => {
    e.preventDefault();
    let checkLoginStatus = Authorization();
    let userId = this.props.details.data[0].userData[0]._id;
    let selfIdId = this.props.details.data[0].selfUserData[0]._id;
    // return;

    if (userId == selfIdId) {
      this.props.addToast("You cann't buy your own product", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });

      return false;
    }

    if (checkLoginStatus.status) {
      this.props.history.push(url, this.props.details);
    } else {
      this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    }
  };

  closeShareModal = () => {
    this.setState({ showShare: false });
  };

  openReportPoup = () => {
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      this.setState({ reportPopup: true });
    } else {
      this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    }
  };

  getDescription = () => {
    let ele = document.querySelector(".product-details-tab");
    ele.scrollIntoView({ behavior: "smooth" });
  };

  descriptionHandler = () => {
    this.setState({ activeDescription: true });
    this.setState({ activeAdditional: false });
    this.setState({ activePreview: false });
  };

  additionalInformationHandler = () => {
    this.setState({ activeAdditional: true });
    this.setState({ activeDescription: false });
    this.setState({ activePreview: false });
  };

  additionalPreview = () => {
    this.setState({ activePreview: true });
    this.setState({ activeDescription: false });
    this.setState({ activeAdditional: false });
  };

  isEnbleMakeOffer = (item) => {
    let isEnble = false;
    if (item.offerRequestData) {
      if (item.offerRequestData.offerStatus === "Accepted") {
        if (
          item.orderData[0] &&
          item.orderData[0].payment.status === "Unpaid"
        ) {
          let date = new Date(item.offerRequestData.updatedAt);
          var oldDateObj = new Date();
          var newDateObj = new Date();
          newDateObj.setTime(date.getTime() + 720 * 60 * 1000);
          if (newDateObj.getTime() < oldDateObj.getTime()) {
            isEnble = false;
          } else {
            isEnble = true;
          }
        }
      } else {
        isEnble = true;
      }
    }
    return isEnble;
  };

  render() {
    let query = parse(this.props.location.search);

    let { details, sellerInfo, userInfo } = this.props;
    const { current, isOtherSeller, isLocalCart, errorMsg } = this.state;
    const settings = {
      dots: false,
      slidesToShow: 4,
      swipeToSlide: true,
      vertical: true,
      infinite: false,
      verticalSwiping: true,
      className: "prod-image-scroll",
      responsive: [
        {
          breakpoint: 767,
          settings: {
            vertical: false,
            // infinite:true,
            // slidesToShow:4,
          },
        },
      ],
      afterChange: (current) => this.setState({ current: current }),
    };
    var isBuyButtonVisible = isShowBuyButton(
      this.props.details &&
        this.props.details.data &&
        this.props.details.data[0] &&
        this.props.details.data[0]
    );

    return (
      <>
        <div
          className="item-seller-info seller-product-detail"
          style={{ width: "100%" }}
        >
          {this.state.showLoader ? (
            <Loader message={this.state.loaderMessage} />
          ) : null}
          {isOtherSeller && (
            <Alert
              removeCartAndSaveProdCart={this.removeCartAndSaveProdCart}
              closeAlert={this.closeAlert}
              isLocalCart={isLocalCart}
              errorMsg={errorMsg}
            />
          )}
          {this.state.notifyMePopup && (
            <Popup
              closePopup={() => this.setState({ notifyMePopup: false })}
              submitFun={() => {
                this.setState({ notifyMePopup: false });
              }}
              content={
                "Product is Currentry out of stock, We will notify you when it is available"
              }
            />
          )}
          {this.state.reportPopup && (
            <ReportReason
              closePopup={() => this.setState({ reportPopup: false })}
              submitFun={() => {
                this.setState({ reportPopup: false });
              }}
              reportProduct={this.props.reportProduct}
              sellerId={sellerInfo.userDetail[0]._id}
              userId={localStorage.getItem("userId")}
              productId={details.data[0]._id}
            />
          )}
          {this.state.openMakeOffer ? (
            <MakeOffer
              handleCloseMakeOffer={this.handleCloseMakeOffer}
              id={this.props.details.data[0]._id}
              img={this.props.details.data[0].default_image}
              name={this.props.details.data[0].Title}
              price={this.props.details.data[0].Price}
              sellerId={sellerInfo.userDetail[0]._id}
              getproductDetail={() =>
                this.props.getproductDetail(
                  { ...this.props.match.params },
                  (status) => {}
                )
              }
            />
          ) : null}
          {/* <MakeOfferPopup/> */}

          <div className="slide-box-wrap">
            <div className="pro-box-wrapper product-detail-box">
              <div className="pro-slide-wrap">
                <div className="option-container make-offer-img-left-container">
                  {details ? (
                    <Slider {...settings} ref={(c) => (this.slider = c)}>
                      {details.data[0].Image.map((img, index) => (
                        <div
                          className="option-container-item"
                          id={index}
                          onMouseEnter={this.changeImage}
                        >
                          <img src={img.original} alt="product" />
                        </div>
                      ))}
                    </Slider>
                  ) : null}
                </div>
                <div className="product-view product-view-wrapper">
                  {details && details.data[0].label && (
                    <span className="availability-info">new</span>
                  )}
                  {/* <span className="heart-like profile" onClick={() => this.likeProduct("wishlist")}>
                  <i className={`fa-heart ${this.state.likeThumb ? "fas" : "far"}`} />
                </span> */}
                  {/* <button className="mobile-proshare-btn prod-share-btn complete-purchase share-profile"
                    onClick={() => this.setState({ showShare: true })}
                  >
                    <i className="far fa-share-alt"></i>
                  </button> */}
                  {this.imageProps().smallImage.src !== "" ? (
                    <div className="main-item product-image-page">
                      {
                        <ReactImageMagnify
                          {...this.imageProps()}
                          imageStyle={{
                            objectFit: "contain",
                            objectPosition: "center",
                            minWidth: 200,
                            maxWidth: 300,
                            minHeight: 400,
                            maxHeight: 400,
                          }}
                          imageClassName="product-fitimage"
                        />
                      }
                    </div>
                  ) : (
                    <SkeletonLoader
                      width="90%"
                      height="25em"
                      style={{ margin: "5%" }}
                    />
                  )}
                </div>
              </div>
              {/* //////// Sticky Div End //////// */}
              {details ? (
                <div className="des-wrap product-description">
                  <div className="left-block-new">
                    <div className="sales-details sales-detail-wrap">
                      <div className="product-head-wrap">
                        <div className="head-star-wrap">
                          <div className="ltl-wrap">
                            <div className="product-detail-main-head">
                              <Link
                                to={`/user-profile/${
                                  details.data[0].userData.length > 0
                                    ? details.data[0].userData[0]._id
                                    : ""
                                }`}
                                className="user-prof-link "
                              >
                                {" "}
                                <h2>
                                  {details.data[0].userData.length > 0
                                    ? details.data[0].userData[0].companyName
                                    : null}
                                </h2>
                              </Link>

                              <div
                                className="rtl-wrap"
                                style={{ marginTop: "13px" }}
                              >
                                {this.state.rates ? (
                                  <RatingStar value={this.state.rates} />
                                ) : null}
                              </div>
                            </div>
                            <h3>
                              {details.data[0].productName}{" "}
                              <span class="detail-catg">
                                {details.data[0].productGrade
                                  ? "(" + details.data[0].productGrade + ")"
                                  : null}
                              </span>
                            </h3>
                          </div>
                        </div>
                        <div className="costume-box-wrapper product-costume-wrap">
                          <div className="box-wrp">
                            <span className="name">Type:</span>
                            <span className="value">
                              {details.data[0].categoryData[0].name}{" "}
                              {details.data[0].process!=="undefined"? "(" + details.data[0].process + ")"
                                : null}
                            </span>
                          </div>
                          <div className="box-wrp"  style={{marginRight:"11rem"}}>
                            <span className="name">Size:</span>
                            <span className="value">
                              {details.data[0].productSize} mm
                            </span>
                          </div>
                          {!isWireRoadProduct(details.data[0].category) &&
                          <div className="box-wrp">
                            <span className="name">Size Tolerance:</span>
                            <span className="value">
                              {details.data[0].productSizeTolerance}%
                            </span>
                          </div>
                           }
                        </div>
                      </div>
                      <div
                        className="product-tble-wrp product-table-seller"
                        style={{ flexDirection: "column" }}
                      >
                       
                       {!isWireRoadProduct(details.data[0].category) && <div className="box-wrp">
                          <span className="name">
                            Specification Tolerance: {""}
                          </span>
                          <span className="value">
                            {"" + details.data[0].specificationTolerance}%
                          </span>
                        </div>}
                        {details.data[0].spec && (
                          <table>
                            {Object.keys(details.data[0].spec).map(function (key, index) {
                              let filteredLength = []
                              const value = details.data[0].spec[key];

                              // Skip if the value contains "N/A"
                              // if (value.includes("N/A")) {
                              //   return null;
                              // }
                              let displayValue=0

                              // Determine how to handle ranges and single values
                              if (value.startsWith("N/A")) {
                                // Remove the key from the object
                                delete details.data[0].spec[key];
                              } else {
                              
                               displayValue= value;
                              if (value.includes("-")) {
                                // If it's a range (like "14-16%"), don't show "max" or "min"
                                displayValue = value.replace(/(max|min|N\/A)/i, "").trim();
                              } else if (value.match(/^\d+(\.\d+)?%$/)) {
                                // If it's a single percentage, add "max" or "min" based on the key
                                displayValue = `${value} ${key.toLowerCase().includes("min") ? "min" : "max"}`;
                              }else {
                                displayValue = value.replace(/(N\/A)/i, "").trim();
                              }
                              filteredLength.push(value)
                            }

                              return Object.keys(filteredLength).length % 2 === 1 ? (
                                Object.keys(filteredLength).length - 1 === index &&
                                  Object.keys(filteredLength).length > 2 ? (
                                  <>
                                    {details.data[0].spec[key] !== "% " && (
                                      <>
                                        <tr key={index}>
                                          <th>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                          <th>{displayValue}</th>
                                        </tr>
                                        <tr>
                                          <th
                                            style={{
                                              color: "transparent",
                                              borderRightColor: "transparent",
                                            }}
                                          >
                                            T
                                          </th>
                                          <th
                                            style={{
                                              color: "transparent",
                                              borderLeftColor: "transparent",
                                            }}
                                          >
                                            0
                                          </th>
                                        </tr>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {details.data[0].spec[key] !== "% " && (
                                      <tr key={index}>
                                        <th>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                        <th>{displayValue}</th>
                                      </tr>
                                    )}
                                  </>
                                )
                              ) : (
                                <>
                                  {details.data[0].spec[key] !== "% " && (
                                    <tr key={index}>
                                      <th>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                      <th>{displayValue}</th>
                                    </tr>
                                  )}
                                </>
                              );
                            })}
                          </table>
                        )}
                      </div>
                      <div className="costume-box-wrapper product-costume-wrap costume-product-qty">
                        <div className="box-wrp">
                          <span className="name">Qty Available (in mt) :</span>
                          <span className="value">
                            {details.data[0].productWeight}
                          </span>
                        </div>
                        {details.data[0].deliveryDateFrom && (
                          <div className="box-wrp">
                            <span className="name">Delivery Window :</span>
                            <span className="value">
                              {moment(details.data[0].deliveryDateFrom).format(
                                "Do MMM"
                              )}{" "}
                              -{" "}
                              {moment(details.data[0].deliveryDateTo).format(
                                "Do MMM"
                              )}
                            </span>
                          </div>
                        )}
                        <div className="box-wrp">
                          <span className="name">M.O.Q. (in mt) :</span>
                          <span className="value">
                            {details.data[0].minOrderQty}
                          </span>
                        </div>
                        <div className="box-wrp">
                          <span className="name">Delivery :</span>
                          {details.data[0].cityData.map((item) => {
                            return (
                              <>
                                <span className="value">
                                  {details.data[0].deliveryType} {item.cityName}
                                </span>
                              </>
                            );
                          })}
                        </div>
                        {/* <div className="box-wrp">
                          <span className="name">Packaging :</span>
                          <span className="value">40 kg Jute Bags</span>
                        </div> */}
                        {/* <div className="box-wrp">
                        <span className="name">Loading Charges (PMT) :</span>
                        <span className="value">&#8377;  {details.data[0].loadingCharge.$numberDecimal}</span>
                      </div> */}
                        <div className="box-wrp">
                          <span className="name">Payment Terms :</span>
                          <span className="value">
                            {details.data[0].paymentType}{" "}
                            {details.data[0].otherPayType
                              ? `(${details.data[0].otherPayType})`
                              : null}
                          </span>
                        </div>

                        {details &&
                        details.data[0] &&
                        details.data[0].loadingCharge &&
                        details.data[0].loadingCharge.$numberDecimal > 0 ? (
                          <div className="box-wrp">
                            <span className="name">Loading Charges :</span>
                            <span className="value">
                              &#8377;{" "}
                              {details.data[0].loadingCharge.$numberDecimal} /mt
                              (Included in Price)
                            </span>
                          </div>
                        ) : null}
                        {details &&
                        details.data[0] &&
                        details.data[0].insuranceCharge &&
                        details.data[0].insuranceCharge.$numberDecimal > 0 ? (
                          <div className="box-wrp">
                            <span className="name">Insurance Charges :</span>
                            <span className="value">
                              &#8377;{" "}
                              {details.data[0].insuranceCharge.$numberDecimal}
                              /mt (Included in Price)
                            </span>
                          </div>
                        ) : null}

                        <div className="box-wrp">
                        {(details.data[0].categoryData[0].name=="Coil") ?
                  <span className="name">Bundle Weight :</span>:  <span className="name">Packaging :</span>}
                          {(details.data[0].categoryData[0].name=="Coil") ?
                          <span className="value">
                            {`${details.data[0].coilCount} Coils (${details.data[0].coilWeight} Kgs approx.)`}
                          </span>:null
                          }
                          {details.data.length > 0 ? (
                            <>
                              {details.data[0].packaging.map((item) => {
                                return (
                                  <>
                                    <span className="value">{item}</span>
                                    <br />
                                  </>
                                );
                              })}
                            </>
                          ) : null}
                        </div>
                        <div className="box-wrp">
                          <span className="name">Remarks :</span>
                          {details.data[0].remarks || ""}
                        </div>
                      </div>
                      {/* <button
                    // on share click it will open popup
                    className="prod-share-btn complete-purchase share-profile"
                    onClick={() => this.setState({ showShare: true })}
                  >
                    <i className="far fa-share-alt"></i>Share
                  </button> */}
                      {/* <Modal className="sharepopup-wrp"
                    show={this.state.showShare}
                    onHide={() => this.setState({ showShare: false })}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header>
                      <Modal.Title id="example-custom-modal-styling-title" className="close-button">
                        Share <i className="fa fa-times" aria-hidden="true" onClick={this.closeShareModal}></i>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="product-share-popup-modal">
                      <FacebookShareButton
                        url={window.location.href}
                        quote={[this.props.details.data[0].Title]}
                        hashtag={["#BAZAAR"]}
                      >
                        <FacebookIcon round={true}></FacebookIcon>
                      </FacebookShareButton>

                      <span></span>
                      <TwitterShareButton
                        url={window.location.href}
                        title={this.props.details.data[0].Title}
                        hashtags={["BAZAAR"]}
                      >
                        <TwitterIcon round={true}></TwitterIcon>
                      </TwitterShareButton>
                      <span></span>
                      <PinterestShareButton
                        url={window.location.href}
                        media={this.props.details.data[0].default_image}
                      >
                        <PinterestIcon round={true}></PinterestIcon>
                      </PinterestShareButton>
                      <span></span>
                      <EmailShareButton
                        url={window.location.href}
                        subject={`View ${this.props.details.data[0].Title} on BAZAAR`}
                        body={`${window.location.href}`}
                        separator={"  "}
                      >
                        <EmailIcon round={true}></EmailIcon>
                      </EmailShareButton>
                      <WhatsappShareButton
                        url={window.location.href}
                        title={window.location.href}
                        // separator=":: "
                        // className="Demo__some-network__share-button"
                        className="whatsapp-logo-wrapper-new"
                      >
                        <button className="whatsapp-logo">
                          <img src="/assets/images/whatsapp-new.svg" />
                        </button>
                      </WhatsappShareButton>
                    </Modal.Body>
                    <Modal.Footer>
                      <a onClick={this.copy} className="copy-share-url">
                        <i className="fal fa-copy"></i>{!this.state.copied ? "Copy profile URL" : "Copied!"}
                      </a>
                    </Modal.Footer>
                  </Modal> */}
                      {/* {details ? (
                    <div className="updated-bar mobile-updated-bar">
                      <span className="updated-time">
                        updated {timeSince(new Date(details.data[0].updatedAt))}
                      </span>
                      <span
                        className="reports"
                      >
                        <span className="report-btns" onClick={this.openReportPoup}>
                          <i className="fas fa-exclamation-triangle"></i>
                          <div className="report">Report</div>
                        </span>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <button
                          onClick={() => this.likeProduct("like")}>
                          <i className={`fa-thumbs-up  ${this.state.likeThumb ? "fas" : "fal"} `}></i>
                        </button>
                      </span>


                    </div>
                  ) : null} */}
                      {/* <div className="brandName">
                    <div className="brand-name">
                      {this.props.details.data[0].brandData[0].name}
                    </div>
                    <div className="product-name mobile-product-name">{details.data[0].Title}</div>
                  </div> */}
                      {/* <div className=" product-details-wrapper-details">
                    <div className="product-name">{details.data[0].Title}</div>
                    <div className="price">
                      {details.data[0].Price.sell_price &&
                        (details.data[0].Price.sell_price !==
                          details.data[0].Price.current_store_price
                        ) ? (
                        <p>
                          <span className="buy-info">
                            {priceWithSymbol(details.data[0].Price.sell_price)}
                          </span>
                          <span className="old-price">
                            {priceWithSymbol(
                              details.data[0].Price.current_store_price
                            )}
                          </span>
                        </p>
                      ) : (
                        <span className="buy-info">
                          {priceWithSymbol(
                            details.data[0].Price.current_store_price
                          )}
                        </span>
                      )}

                      {
                        details.data[0] ?
                          details.data[0].shippingCost === 0 ?
                            <span className="free-shipping"> Free shipping</span>
                            :
                            <span className="free-shipping"> {priceWithSymbol(details.data[0].shippingCost)} Shipping</span>
                          : null
                      }

                    </div>
                  </div> */}

                      {/* <div className="buttons-wrap">
                    {!this.checkDisabled() ? ( //if not-out-of-stock
                      <div className="btn-wrap">
                        <div className="pro-btn-wrap">
                          <Link
                            className=" complete-purchase btn-shop"
                            style={{ marginRight: "10px", padding: "unset" }}
                            to="#"
                            onClick={(e) => this.buyNowHandler(e, `/checkout?pid=${details.data[0]._id}&category=${details.data[0].categoryData[0].slug}&type=${query.cat}`)}
                          >

                            <button
                              className={this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ? "complete-purchase disablebutton" : "complete-purchase"}
                              style={{ borderLeft: "0px", borderRight: "0px" }}
                              disabled={this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ? true : false}

                            >
                              Buy now
                            </button>
                          </Link>
                          {
                            !this.checkAlreadyCart() ? (
                              <button
                                className={this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ? "complete-purchase disablebutton" : "complete-purchase"}
                                onClick={() => this.handleAddCart(details.data[0])}
                                disabled={this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ? true : false}
                              >
                                Add to cart
                              </button>
                            ) : (
                              query.cat === "Local" ?
                                <button
                                  onClick={this.removeExistsOrder}
                                  className="complete-purchase "
                                >
                                  Add to cart
                                </button>
                                : <button
                                  onClick={() => this.gotocart()}
                                  className="complete-purchase "
                                >
                                  Go to cart
                                </button>
                            )
                          }
                        </div>
                        <div className="pro-btn-wrap">
                          {
                            this.props.getuserData ?
                              <button
                                onClick={() => { this.makeOffer(); }}
                                className={
                                  this.props.getuserData._id === this.props.details.data[0].userData[0]._id ||
                                    this.isEnbleMakeOffer(this.props.details.data[0]) ? "complete-purchase make-offer disablebutton" : "complete-purchase make-offer"}
                                style={{ marginRight: "10px" }}
                                disabled={
                                  this.props.getuserData._id === this.props.details.data[0].userData[0]._id ||
                                    this.isEnbleMakeOffer(this.props.details.data[0]) ? true : false
                                }
                              >
                                Make an offer
                              </button>

                              : <button onClick={() => { this.makeOffer(); }} className={"complete-purchase make-offer"} style={{ marginRight: "10px" }}>
                                Make an offer
                              </button>
                          }

                          <button
                            className={this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ? "complete-purchase msg-seller disablebutton" : "complete-purchase msg-seller"}
                            disabled={this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ? true : false}
                            onClick={() =>
                              this.gotoChat(
                                "/chats/all",
                                { sender_id: sellerInfo.userDetail[0]._id, product_id: details.data[0]._id }
                              )
                            }
                          >
                            Message seller
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="complete-purchase disabled btn_purchase_wrap"
                        style={{ maxWidth: "none" }}
                        onClick={() => this.setState({ notifyMePopup: true })}
                      >
                        Product out of stock
                      </button>
                    )}
                  </div> */}
                      {/* <>
                    {this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ?
                      <span className="info-msg" style={{ color: "red" }}>You can't purchase your own product</span> : null}
                  </> */}

                      {/* {this.props.details.data[0].Quantity <= this.props.details.data[0].low_stack && this.props.getuserData && this.props.getuserData._id !== this.props.details.data[0].userData[0]._id ?
                    this.props.details.data[0].Quantity === 0 ? null : <span className="product-left-alert">{`Only ${this.props.details.data[0].Quantity} ${this.props.details.data[0].Quantity === 1 ? "is" : "are"} left.`}</span> : null
                  } */}

                      {/* {
                    this.state.matches ?
                      null :
                      <div className="product-item-details">
                        {this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ?
                          null :
                          <>
                            <div className="pincode-wrap">
                              <input type="text" placeholder="Enter Delivery Pincode" value={this.state.getPinCode} onChange={(e) => this.handlepincode(e)} maxLength="6" />
                              <button type="button" onClick={this.checkEstimate}>Check</button>
                            </div><br />
                            {this.state.exactDilMessage ?
                              <span className="pincode">Enter pincode for estimated delivery time </span>
                              : null}
                            {this.state.dileveryTime1 && this.state.dileveryTime2 ? <span className="pincode">{"Product will be deliver in between " + this.state.dileveryTime2 + " to " + this.state.dileveryTime1 + " days"}</span> : null}
                          </>
                        }
                        {details.data[0].returnPolcyData.length > 0 ?
                          <div className="return-policy">
                            <span>Services</span>
                            <img src="\assets\images\cropped-Refresh_icon.png" alt="img"></img> &nbsp; {details.data[0].returnPolcyData[0].returnPolicyDay + " days return policy"}
                          </div> : null
                        }
                        <span className={"it-dtls alternate-item" + this.state.Class} onClick={() => this.collapseNow()}>Item Details</span>
                        <Collapsible open={this.state.open}>
                          {details.data[0].Condition ? (
                            <div className="product-clr">
                              <div className="product-heading">
                                Condition:{" "}
                              </div>
                              <span className={`product-verification-btn`}>
                                {details.data[0].Condition}
                              </span>
                            </div>
                          ) : null}
                         
                          <div className="product-clr">
                            <div className="product-heading">
                              Shipping:{" "}
                            </div>
                            <span className={`product-verification-btn`}>
                              {details.data[0] ?
                                details.data[0].shippingCost === 0 ?
                                  <span>Free</span>
                                  :
                                  <span>
                                    {priceWithSymbol(details.data[0].shippingCost)} </span>
                                : null
                              }
                            </span>
                          </div>

                        
                          {details.data[0].Attrs.length > 0
                            ? details.data[0].Attrs.map((item, i) => (
                              <div className="product-clr">
                                <div className="product-heading">
                                  {item.name}:
                                </div>
                                <span
                                  className={`product-verification-btn`}
                                >
                                  {item.value}
                                </span>
                                {item.name === "size" && details.data[0].sizeGuide ? (
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.setState({ hideSizeGuide: true })
                                    }
                                  >
                                    Size Guide
                                  </Link>
                                ) : null}
                              </div>
                            ))
                            : null}

                          <div className="product-clr">
                            <div className="product-heading">
                              Brand:{" "}
                            </div>
                            <span className={`product-verification-btn`}>
                              {details.data[0].brandData[0].name}
                            </span>
                          </div>
                          <div className="product-clr">
                            <div className="product-heading">
                              Category:{" "}
                            </div>
                            <span className={`product-verification-btn`}>
                              {details.data[0].categoryData[0].name}
                              {
                                details.data[0].subcategoryData[0] &&
                                <span>
                                  {` ${details.data[0].subcategoryData[0].name}`}
                                </span>
                              }
                              {
                                details.data[0].subchildcategoryData[0] &&
                                <span>
                                  {` ${details.data[0].subchildcategoryData[0].name}`}
                                </span>
                              }
                            </span>

                          </div>
                          <div className="product-clr">
                            <div className="product-heading">
                              Posting:{" "}
                            </div>
                            <span className={`product-verification-btn`}>
                              {`${details.data[0].createdAt.split("T")[0].split("-")[2]}-${details.data[0].createdAt.split("T")[0].split("-")[1]}-${details.data[0].createdAt.split("T")[0].split("-")[0]}`}
                            </span>
                          </div>
                        </Collapsible>
                      </div>
                  } */}
                      {/* <>
                    {this.state.matches ?
                      <div className="item-name-detail pin-code-wrapper">
                        {this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ?
                          null :
                          <>
                            <div className="pincode-wrap">
                              <input type="text" placeholder="Enter Delivery Pincode" value={this.state.getPinCode} onChange={(e) => this.handlepincode(e)} maxLength="6" />
                              <button type="button" onClick={this.checkEstimate}>Check</button>
                            </div><br />
                            {this.state.exactDilMessage ?
                              <span className="pincode">Enter pincode for estimated delivery time </span>
                              : null}
                            {this.state.dileveryTime1 && this.state.dileveryTime2 ? <span className="pincode">{"Product will be deliver in between " + this.state.dileveryTime2 + " to " + this.state.dileveryTime1 + " days"}</span> : null}
                          </>
                        }
                        {details.data[0].returnPolcyData.length > 0 ?
                          <div className="return-policy">
                            <span>Services</span>
                            <img src="\assets\images\cropped-Refresh_icon.png" alt="img"></img> &nbsp; {details.data[0].returnPolcyData[0].returnPolicyDay + " days return policy"}
                          </div> : null
                        }


                        <span
                          className={
                            "it-dtls description-head" + (this.state.openDesc ? "open-collapse " : "")
                          }
                          onClick={this.description}
                        >
                          Description
                        </span>

                        <div
                          className={"desc description-wrapper description-data-wrap strong-override" + (this.state.openDesc ? "desc-open" : "")}
                          dangerouslySetInnerHTML={{ __html: details.data[0].Description }}
                        />
                        {details.data[0].Description.length > 140 ?
                          <a className="show-more" onClick={this.getDescription}>Show More</a> : null
                        }
                      </div> :


                      <div className="item-name-detail alternate-description">
                        <span
                          className={
                            "it-dtls " + this.state.classDes + (this.state.openDesc ? "open-collapse" : "")
                          }
                          onClick={this.description}
                        >
                          Description
                        </span>
                        <Collapsible open={this.state.openDes}>

                          <div
                            className={"desc " + (this.state.openDesc ? "desc-open" : "")}

                            dangerouslySetInnerHTML={{
                              __html: details.data[0].Description,
                            }}
                          />
                        </Collapsible>

                      </div>

                    }
                    <p className="fontSmall">{details.data[0].Product_tag ? "#Tag : " : null} {details.data[0].Product_tag}</p>
                  </> */}

                      {/* {details && details.data[0].coupon_data.length > 0 && (
                    <div>
                      <span className="offers-show">Available offers</span>
                      <span className="offerdata-box">
                        use coupon code{" "}
                        <span className="offer-datainfo">
                          {details.data[0].coupon_data[0].coupon_code}
                        </span>{" "}
                        for {details.data[0].coupon_data[0].discount_amount} % off
                      </span>
                    </div>
                  )} */}
                      {/* {this.props.details && (
                    <div className="share-icon-group">
                      <TwitterShareButton
                        url={window.location.href}
                        title={this.props.details.data[0].Title}
                        hashtags={["BAZAAR"]}
                      >
                        <i className="fab fa-twitter"></i>
                      </TwitterShareButton>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={[this.props.details.data[0].Title]}
                        hashtag={["#BAZAAR"]}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </FacebookShareButton>
                      <EmailShareButton
                        url={window.location.href}
                        subject={`View ${this.props.details.data[0].Title} on BAZAAR`}
                      >
                        <i className="fas fa-envelope icon-chat-new"></i>
                      </EmailShareButton>
                      <PinterestShareButton
                        url={window.location.href}
                        media={this.props.details.data[0].default_image}
                      >
                        <i className="fab fa-pinterest"></i>
                      </PinterestShareButton>
                    </div>

                  )} */}
                    </div>
                    {this.state.getProfileStatus == "pending" ||
                    details.data[0].productPrice.$numberDecimal == 0 ? null : (
                      <div className="offer-price-box new-offer-price-box">
                        <h3>Offer Price</h3>
                        <div className="offer-price card-total-price">
                          <h5
                            className={
                              isBuyButtonVisible
                                ? "actual-price"
                                : "actual-price actual-price-expired"
                            }
                          >
                            &#8377;{" "}
                            {details.data[0].productPrice.$numberDecimal}/
                            <span className="unit-mt">mt</span>
                          </h5>
                          {!isBuyButtonVisible && (
                            <div className="created-date posted-date">
                              <span className="price-expired">
                                Price Expired
                              </span>
                            </div>
                          )}
                          <label class="font10">(+ 18% GST)</label>
                        </div>
                        {isBuyButtonVisible && (
                          <div>
                            <label class="font10">
                              Valid until:{" "}
                              {formattedDate(details.data[0].expiryDate) || ""}
                            </label>
                          </div>
                        )}
                        <div className="created-date posted-date">
                          <i className="icon-time"></i>
                          <span className="create-time">
                            {timeFunction(new Date(details.data[0].updatedAt))}
                          </span>
                        </div>
                        {/* <h4>&#8377; {parseInt(details.data[0].productPrice.$numberDecimal) + parseInt(details.data[0].loadingCharge.$numberDecimal) + parseInt(details.data[0].insuranceCharge.$numberDecimal)}<span className="offer-unit">/mt</span></h4> */}
                        {/* <span className="gst-tax">excluding GST & Taxes </span> */}
                        <div className="buy-btn-wrapper btn-wrapper detail-buy-now">
                          {this.props.getuserData &&
                          this.props.getuserData.userType == "Seller" ? null : (
                            <>
                              {isBuyButtonVisible && (
                                <Link
                                  to="#"
                                  className="product-detail-btn"
                                  onClick={(e) =>
                                    this.buyNowHandler(
                                      e,
                                      `/checkout?pid=${details.data[0]._id}&category=${details.data[0].categoryData[0].slug}&type=${query.cat}`
                                    )
                                  }
                                >
                                  {/* <button
                                    disabled={
                                      this.state.getProfileStatus == "pending"
                                        ? true
                                        : false
                                    }
                                    className="offer-btn-wrp"
                                  >
                                    Buy Now
                                  </button> */}
                                </Link>
                              )}
                              <Link
                                className="product-detail-btn"
                                to={`/make-offer/${details.data[0]._id}`}
                              >
                                <button
                                  className="make-offer"
                                  // onClick={() => {
                                  //   this.makeOffer();
                                  // }}
                                >
                                  Add to Cart
                                </button>
                              </Link>
                            </>
                          )}

                          {/* <button className="offer-btn-wrp" onClick={() => this.handleAddCart(details.data[0])}>Add To Cart</button> */}
                          {/* <button
                  className={this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ? "complete-purchase msg-seller disablebutton" : "complete-purchase msg-seller"}
                  disabled={this.props.getuserData && this.props.getuserData._id === this.props.details.data[0].userData[0]._id ? true : false}
                  onClick={() =>
                    this.gotoChat(
                      "/chats/all",
                      { sender_id: sellerInfo.userDetail[0]._id, product_id: details.data[0]._id }
                    )
                  }
                >
                  Message seller
                </button> */}
                        </div>
                        {/* <span className="offer-expiry">{timeFunction(new Date(details.data[0].updatedAt))}</span> */}

                        {/* <span className="offer-expiry">
                          This offer will expire at 08:00 pm
                        </span> */}
                      </div>
                    )}
                  </div>
                  <div className="terms-condition-new">
                    <h3>Terms of Contract</h3>
                    <ul className="terms-new-wrap">
                    {(details.data[0].categoryData[0].name=="Coil") ?
                      <li>
                        EMD deposit of INR 1000 per tonne
                      </li>:null
                    }
                      <li>
                        For Advance Payment Term, Advance Payment of 25% of
                        total order value to be paid within one business day of
                        order confirmation. Balance Payment is due 1 business
                        day before material loading.
                      </li>
                      <li>
                        No cancellations will be allowed after order placement,
                        unless mutually agreed by both buyer and seller.
                      </li>
                      <li>
                        GST of 18% or as applicable. If applicable, TDS to be
                        deducted by buyer at 0.1% or as applicable.
                      </li>
                      {this.state.viewStatus ? (
                        <>
                          <li>
                            For orders with multiple dispatches, booking amount
                            will be adjusted on pro-rata basis for each
                            dispatch.
                          </li>
                          <li>
                            Order may be cancelled if material/goods is not
                            picked up on scheduled dispatch date and time,
                            unless an extension was preapproved by the seller.
                          </li>
                          <li>
                            Insurance (if provided) to be claimed directly with
                            insurance provider
                          </li>
                          <li>
                            Any changes to these Terms must be mutually agreed
                            by both the Buyer and the Seller.
                          </li>
                          <li>
                            In case of quality issue, joint analysis will be
                            done and sample will be tested at a third party lab.
                            The result of third party lab will be final and
                            binding on both parties.
                          </li>
                          {/* <li>
                            50 Kg weight tolerance for local deliveries and 150
                            kg weight tolerance for non-local deliveries
                          </li>
                          <li>
                            Insurance to be claimed directly with insurance
                            provider
                          </li> */}
                        </>
                      ) : null}
                      <button
                        className="view-all-terms"
                        onClick={() =>
                          this.setState({ viewStatus: !this.state.viewStatus })
                        }
                      >
                        {this.state.viewStatus ? `Read less` : `Read more`}
                      </button>
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="update_wrapper_box">
              <div className="updt-info-wrp">
                {/* {details ? (
                <div className="updated-bar">
                  <span className="updated-time">
                    updated {timeSince(new Date(details.data[0].updatedAt))}
                  </span>
                  <span
                    className="reports"
                  >
                    <span className="report-btns" onClick={this.openReportPoup}>
                      <i className="fas fa-exclamation-triangle"></i>
                      <div className="report">Report</div>
                    </span>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button
                      onClick={() => this.likeProduct("like")}>
                      <i className={`fa-thumbs-up  ${this.state.likeThumb ? "fas" : "fal"} `}></i>
                    </button>
                  </span>


                </div>
              ) : null} */}

                {details ? (
                  <div className="seller-info">
                    <SellerInfo
                      data={sellerInfo}
                      product_id={details.data[0]._id}
                      gotoChat={this.gotoChat}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div className="product-details-tab">
            <ul className="nav-pills">
              <li className="nav-item" onClick={this.descriptionHandler}>
                <a className={this.state.activeDescription ? "nav-link active" : "nav-link"} >Description</a>
              </li>
              <li className="nav-item" onClick={this.additionalInformationHandler}>
                <a className={this.state.activeAdditional ? "nav-link active" : "nav-link"} >Additional Information</a>
              </li>
              <li className="nav-item" onClick={this.additionalPreview}>
                <a className={this.state.activePreview ? "nav-link active" : "nav-link"} >Reviews</a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={this.state.activeDescription ? "tab-pane active" : "tab-pane"}>
                {this.state.activeDescription ?
                  <div className="description">
                    <h4 className="title">Description </h4>
                    <p>
                      <div
                        className={"desc " + (this.state.openDesc ? "desc-open" : "")}

                        dangerouslySetInnerHTML={{
                          __html: details && details.data && details.data[0] && details.data[0].Description,
                        }}
                      />
                    </p>
                  </div> : null
                }

              </div>
              <div className={this.state.activeAdditional ? "tab-pane active" : "tab-pane"}>
                {
                  this.state.activeAdditional ?
                    <div className="description">
                      <h4 className="title">Item Details </h4>

                      {details && details.data ?
                        <div className="product-item-details">

                          {details.data[0].Condition ? (
                            <div className="product-clr">
                              <div className="product-heading">
                                Condition:{" "}
                              </div>
                              <span className={`product-verification-btn`}>
                                {details.data[0].Condition}
                              </span>
                            </div>
                          ) : null}


                          <div className="product-clr">
                            <div className="product-heading">
                              Shipping:{" "}
                            </div>
                            <span className={`product-verification-btn`}>
                              {details.data[0] ?
                                details.data[0].shippingCost === 0 ?
                                  <span>Free</span>
                                  :
                                  <span>
                                    {priceWithSymbol(details.data[0].shippingCost)} </span>
                                : null
                              }
                            </span>
                          </div>


                          {details.data[0].Attrs.length > 0
                            ? details.data[0].Attrs.map((item, i) => (
                              <div className="product-clr">
                                <div className="product-heading">
                                  {item.name}:
                                </div>
                                <span
                                  className={`product-verification-btn`}
                                >
                                  {item.value}
                                </span>
                                {item.name === "size" && details.data[0].sizeGuide ? (
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.setState({ hideSizeGuide: true })
                                    }
                                  >
                                    Size Guide
                                  </Link>
                                ) : null}
                              </div>
                            ))
                            : null}

                          <div className="product-clr">
                            <div className="product-heading">
                              Brand:{" "}
                            </div>
                            <span className={`product-verification-btn`}>
                              {details.data[0].brandData[0].name}
                            </span>
                          </div>
                          <div className="product-clr">
                            <div className="product-heading">
                              Category:{" "}
                            </div>
                            <span className={`product-verification-btn`}>
                              {details.data[0].categoryData[0].name}
                              {
                                details.data[0].subcategoryData[0] &&
                                <span>
                                  {` ${details.data[0].subcategoryData[0].name}`}
                                </span>
                              }
                              {
                                details.data[0].subchildcategoryData[0] &&
                                <span>
                                  {` ${details.data[0].subchildcategoryData[0].name}`}
                                </span>
                              }
                            </span>



                          </div>
                          <div className="product-clr">
                            <div className="product-heading">
                              Posting:{" "}
                            </div>
                            <span className={`product-verification-btn`}>
                              {`${details.data[0].createdAt.split("T")[0].split("-")[2]}-${details.data[0].createdAt.split("T")[0].split("-")[1]}-${details.data[0].createdAt.split("T")[0].split("-")[0]}`}
                            </span>
                          </div>
                        </div> : null
                      }
                    </div> : null
                }
              </div>
              <div className={this.state.activePreview ? "tab-pane active" : "tab-pane"}>
                <div className="description">
                  {this.state.activePreview && this.props.reviewList ?
                    <>
                      <h4 className="title">Review</h4>
                      <div className="product-item-details product-review-wrap">
                        <ProductReview reviewList={this.props.reviewList} />
                      </div>
                    </>

                    : null
                  }

                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartData: state.cart.getcart,
    wishlist: state.Wishlist.wishlist,
    getuserData: state.userProfile.userProfileBanner.userdata,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCart: (data, id, callback) => dispatch(addCart(data, id, callback)),
    removeAllProdFromCart: (callback) =>
      dispatch(removeAllProdFromCart(callback)),
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
    deleteFromWishList: (payload, status) =>
      dispatch(deleteWishList(payload, status)),
    reportProduct: (payload, completion) => reportProduct(payload, completion),
    likeProduct: (payload, status) => likeProduct(payload, status),
    followerdata: (payload) => dispatch(followerdata(payload)),
    getCart: () => dispatch(getCart()),
    getTotalUnreadMsgAndWishlistItem: (payload) =>
      dispatch(getTotalUnreadMsgAndWishlistItem(payload)),
    checkEstimationTime: (payload, completion) =>
      dispatch(checkEstimationTime(payload, completion)),
  };
};

export default withToast(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSellerInfo))
);

const reportOptions = [
  { value: "duplicate", label: "Duplicate" },
  { value: "fake", label: "Fake" },
  { value: "abuse", label: "Abuse" },
];

const ReportReason = (props) => {
  const [comment, setComment] = React.useState("");

  const { addToast } = useToasts();

  const handleReportChange = (e) => {
    // setComment(options.label);
    setComment(e.target.value);
  };

  return (
    <div className="make-offer-popup report-popup report-wrapper">
      <div className="offer-content">
        <button
          className="modal-closeBtn"
          onClick={() => {
            props.closePopup(null);
          }}
          type="button"
        >
          {/* <span className="closeBtn-crossLeft"></span>
          <span className="closeBtn-crossRight"></span> */}
          <i className="fal fa-times-circle"></i>
        </button>
        <div className="offer-title report-item-wrap">Report an item</div>
        <div className="offer-title">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.reportProduct(
                {
                  report_comment: comment,
                  // userId: props.userId,
                  productId: props.productId,
                  sellerId: props.sellerId,
                },
                (status) => {
                  if (status) {
                    addToast("Product reported", {
                      appearance: "success",
                      autoDismissTimeout: 2500,
                    });
                  } else {
                    addToast("Failed to report product", {
                      appearance: "error",
                      autoDismissTimeout: 2500,
                    });
                  }
                }
              );
              props.submitFun(comment);
            }}
          >
            <div className="issue-heading issue-item-wrap">
              What is the issue ?
            </div>
            <textarea
              name="report_comment"
              required
              id="reason"
              rows="6"
              cols="50"
              minLength={10}
              maxLength={1000}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Please write issue..."
            />
            <button className="header-sell">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};
