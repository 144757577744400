import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";
import {
  SET_WISHLIST,
  SET_NEW_WISHLIST,
  REMOVE_WISHLIST,
  SET_SELLER_WISHLIST,
  SET_PRODUCT_LIST_WISHLIST,
  SET_PRODUCT_LIST_WISHLIST_SELLER_PROD
} from "./../actionsTypes";
import { filter } from "lodash";

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const getWishList = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance
        .post(apiUrl.wishlist.get, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
      if (response.status === 200) {
        var wishlist = response.data.data
          .filter((data) => data.productData)
          .map((item) => {
            if (item.productData) {
              const obj = {
                auctionData: item.auction_data,
                product_id: item.productData._id,
                wishlist_id: item._id,
                product_data: item.productData,
                image_path: response.data.PRODUCTIMAGEPATH,
                categorydata: item.categorydata,
              };
              return obj;
            }
          });
        dispatch(setWishlist({ data: wishlist, totalLenght: response.data.allData, page: response.data.page }));
      } else {
        // dispatch(setWishlist({}));
      }
    } catch (err) {
      // dispatch(setWishlist({}));
    }
  };
};

export const addWishList = (payload, completion) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(apiUrl.wishlist.add, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        response.data.data.wishlist_id = response.data.data._id;
        dispatch(setNewWishlist(response.data.data));

        if (completion) {
          completion(true);
        }
      })
      .catch((err) => {

      });
  };
};

export const deleteWishList = (payload) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(apiUrl.wishlist.delete, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch(removeWishlist(payload));
      });
  };
};




const setWishlist = (data) => {
  return {
    type: SET_WISHLIST,
    payload: data,
  };
};
const setSellerWishlist = (data) => {
  return {
    type: SET_SELLER_WISHLIST,
    payload: data,
  };
};

const setNewWishlist = (data) => {
  return {
    type: SET_NEW_WISHLIST,
    payload: data,
  };
};

export const removeWishlist = (data) => {
  return {
    type: REMOVE_WISHLIST,
    payload: data,
  };
};



//////////////////


export const addWishListProdList = (payload, type) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(apiUrl.wishlist.add, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        response.data.data.wishlist_id = response.data.data._id;
        if (type === "sellerProd") {
          dispatch({
            type: SET_PRODUCT_LIST_WISHLIST_SELLER_PROD,
            payload
          });
        } else {
          dispatch({
            type: SET_PRODUCT_LIST_WISHLIST,
            payload
          });
        }

      })
      .catch((err) => {

      });
  };
};

export const deleteWishListProdList = (payload, type) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(apiUrl.wishlist.delete, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (type === "sellerProd") {
          dispatch({
            type: SET_PRODUCT_LIST_WISHLIST_SELLER_PROD,
            payload
          });
        } else {
          dispatch({
            type: SET_PRODUCT_LIST_WISHLIST,
            payload
          });
        }
      });
  };
};