import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const BrandSlider = () => {
	let settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		autoplay: true,
		slidesToShow: 12,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint:375,
				settings: {
				   slidesToShow: 2,
				   slidesToScroll: 1,
				},
			   },
			{
				breakpoint: 768,
				settings: {
				   slidesToShow: 4,
				   slidesToScroll: 1,
				},
			   },
			{
			 breakpoint: 992,
			 settings: {
				slidesToShow: 6,
				slidesToScroll: 1,
			 },
			},
			{
				breakpoint: 1024,
				settings: {
				   slidesToShow: 6,
				   slidesToScroll: 1,
				},
			   },
			   {
				breakpoint: 1200,
				settings: {
				   slidesToShow: 8,
				   slidesToScroll: 1,
				},
			   },
		 ]
	  };
	  useEffect(() => {
        const wow = new WOW.WOW({
          live: true, // Enables the live mode to detect changes dynamically
        });
        wow.init();
      }, []);

  return (
	<div className='show-testimonials brand-slider wow animate__animated animate__fadeInUp'>
			<Slider {...settings}>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/bpa-grp.jpeg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/bs-group.jpeg" alt="brand-logo" />
					</div>

			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/gktmt.jpeg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/imx-maruti.jpeg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/Ishwar.jpeg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/astha-logo.png" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/fr-industries.jpeg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/orion-ferro.jpg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/saturn-logo.webp" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/saturn-logo-raigarh.jpg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/singh-ferro-allows.jpeg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/sky-allows.jpg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/vandana-logo.jpeg" alt="brand-logo" />
					</div>
					
			</div>
          </div>
        </Slider>
	</div>
  )
}

export default BrandSlider