import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  setAboutProductData,
  getSubCategorySpecificBrands,
  setUploadImageData,
  addBrandRequest,
  getGSTCategory,
} from "../../store/actions/sellItem";
import { Error, customStyles } from "./index11";
import Popup from "./Popup";
import AddBrandPopup from "./AddBrandPopup";
import ScrollToTop from "../../ScrollToTop";
import swal from 'sweetalert';

// import CreatableSelect from "react-select/creatable";
const pic_headers = ["- Default Image", "", "", "", ""];
class AboutProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EDITMODE: props.aboutProductData.EDITMODE || false,
      categoriesData: [],
      category: [],
      subCategory: [],
      childSubCategory: [],
      brandsData: [],
      brand: [],
      gstCatList: [],
      drag: false,
      dragging: false,
      brandPopup: false,
      shipping_cost: null,
      getHsnCode: [],
      HsnCodeValue: ""
    };
  }

  dropRef = React.createRef();

  handleChangeImage = (event) => {
    let { files, fileValues } = this.props;
    if ((event.target.files.length + files.length) < 9) {
      const newfiles = [...files];
      const newfileValues = [...fileValues];
      Object.keys(event.target.files).map((data, index) => {
        newfiles[index + files.length] = URL.createObjectURL(
          event.target.files[data]
        );
        newfileValues[index + files.length] = event.target.files[data];
      });
      this.props.enterText(newfiles, "files")
      this.props.enterText(newfileValues, "fileValues")
    } else
      swal(`You can only select upto ${8 - files.length} Images`);
  };

  handleDraggedImage = (event) => {
    let { files, fileValues } = this.props;
    if (event.dataTransfer.files.length + files.length < 9) {
      const newfiles = [...files];
      const newfileValues = [...fileValues];
      Object.keys(event.dataTransfer.files).map((data, index) => {
        newfiles[index + files.length] = URL.createObjectURL(
          event.dataTransfer.files[data]
        );
        newfileValues[index + files.length] =
          event.dataTransfer.files[data];
      });
      this.props.enterText(newfiles, "files")
      this.props.enterText(newfileValues, "fileValues")
    } else
      swal(`You can only select upto ${8 - files.length} Images`);
  };

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter > 0) return;
    this.setState({ dragging: false });
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.handleDraggedImage(e);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  componentDidMount() {
    this.dragCounter = 0;
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
    let element = document.getElementsByClassName("menu-item-container")[0];
    element.scrollIntoView();

    getGSTCategory(status => this.setState({ gstCatList: status }))
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  static getDerivedStateFromProps = (props, state) => {
    if (
      props.categories === state.categoriesData &&
      props.brands === state.brandsData
    ) {
      return state;
    }

    const categoriesData = props.categories;
    const category = categoriesData.map((item) => ({
      value: item,
      label: item.name,
    }));
    const brandsData = props.brands;
    delete brandsData["active"];
    const brand = brandsData;
    return {
      ...state,
      categoriesData,
      category,
      brandsData,
      brand,
    };
  };

  selectCategory = (e) => {
    const id = e.value._id;
    this.props.setDropDownVal(e, 'selectedCategory')
    const categoriesData = this.state.categoriesData;
    const subCategoryData =
      categoriesData.filter((item) => item._id === id)[0].subcategoryData || [];
    const subCategory = subCategoryData.map((item) => ({
      value: item,
      label: item.name,
    }));
    this.props.get_subCategory_specific_brand({ categoryId: id });
    this.setState({ subCategory });
    this.props.setDropDownVal("", 'selectedSubCategory')
    this.props.setDropDownVal("", 'selectedBrand')
  };

  selectSubCategory = (e) => {
    const childSubCategory = e.value.subchildcategory.map((item) => ({
      value: item,
      label: item.name,
    }));
    if (e.value.hsnCode != undefined) {
      const hsnData = e.value.hsnCode.map((hsnItem) => ({
        value: hsnItem.name,
        label: hsnItem,
      }));

      this.setState({ getHsnCode: hsnData });
    }
    this.props.setDropDownVal(e, 'selectedSubCategory');
    this.props.setDropDownVal("", 'selectedChildSubCategory');
    this.setState({ childSubCategory });
  };

  selectSubCategoryChild = (e) => this.props.setDropDownVal(e, 'selectedChildSubCategory');
  selectHsnCode = (e) => {
    this.props.enterText(e.label, "hsn");
  };
  selectBrand = (e) => this.props.setDropDownVal(e, 'selectedBrand');

  renderSelectBox = (options, placeholder, value, onChangeHandler, showErr) => {

    return (
      <Select
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
        styles={showErr && value === "" && customStyles}
        classNamePrefix={"select-opt"}
      // id="id"
      // defaultMenuIsOpen={"true"}
      />
    );
  };
  closeBranPopup = () => {
    this.setState({ brandPopup: false })
  }

  submitBrandForm = (data, returnStatus) => {
    const payload = {
      name: data.brandName,
      sellerId: data.userId,
      category: data.categoryId.value._id,
      image: data.image[0]
    }
    let formdata = new FormData();
    Object.keys(payload).forEach((key) =>
      formdata.append(key, payload[key])
    );
    this.props.addBrandRequest(formdata, (status) => {

      returnStatus(status)
    })
  }

  getCost = (e) => {
    this.setState({ shipping_cost: e })
  }

  render() {

    const { category, subCategory, childSubCategory, brand, brandPopup, gstCatList } = this.state;

    const { title, quantity, selectedCategory, selectedSubCategory, selectedChildSubCategory, selectedBrand, files, hsn_code, selected_gst, showErr } = this.props;
    return (
      <div>
        <ScrollToTop />
        {brandPopup &&
          <AddBrandPopup
            closePopup={this.closeBranPopup}
            submitFun={this.submitBrandForm}
            categoryList={category}
          />
        }
        <h5> TELL US WHAT YOU ARE SELLING?</h5>
        <div>
          <div className="slct-optns">
            <div className="optns">
              <div className="label-txt">Enter Product Name <span className="highlighted">*</span></div>
              <input
                className={
                  showErr && title === ""
                    ? "input-url error-sell-input"
                    : "input-url"
                }
                placeholder="Product Name"
                value={title}
                onChange={(e) => this.props.enterText(e.target.value, "title")}
                maxLength={100}
              />
              <br />
              {showErr && title === "" && <Error />}
            </div>
            <div className="optns">
              <div className="label-txt">Enter Product Quantity </div>
              <input
                className={
                  showErr && quantity === ""
                    ? "input-url error-sell-input"
                    : "input-url"
                }
                placeholder="Quantity"
                value={quantity}
                onChange={this.props.enterQuantity}
                maxLength={3}
              />
              <br />
              {showErr && quantity === "" && <Error />}
            </div>
          </div>

          <div className="slct-optns">

            <div className="optns">
              <div className="label-txt">Select Product Category <span className="highlighted">*</span></div>
              {this.renderSelectBox(
                category,
                "Category",
                selectedCategory,
                this.selectCategory,
                showErr
              )}
              {showErr && selectedCategory === "" && <Error />}
            </div>
            {selectedCategory ? (
              <div className="optns">
                <div className="label-txt">Sub Category <span className="highlighted">*</span></div>
                {this.renderSelectBox(
                  subCategory,
                  "Sub Category",
                  selectedSubCategory,
                  this.selectSubCategory,
                  showErr
                )}
                {/* {(showErr&&selectedSubCategory==="" )&& <Error/>} */}
              </div>
            ) : null}
            {selectedSubCategory &&
              (childSubCategory.length > 0 || selectedChildSubCategory) ? (
              <div className="optns">
                <div className="label-txt">Sub-Child Category</div>
                {this.renderSelectBox(
                  childSubCategory,
                  "Sub-Child Category",
                  selectedChildSubCategory,
                  this.selectSubCategoryChild,
                  showErr
                )}
                {/* {(showErr&&selectedSubCategory==="" )&& <Error/>} */}
              </div>
            ) : null}



          </div>

          <div className="slct-optns multi">
            <div className="optns">
              <div className="label-txt">
                Select Brand
                <span className="sell-popup-opener" onClick={() => this.setState({ brandPopup: !this.state.brandPopup })}>
                  Can't see your brand? <span className="highlighted">*</span>
                </span>

              </div>
              <Select
                options={brand}
                // isMulti
                placeholder="Select Brand"
                value={selectedBrand}
                onChange={this.selectBrand}
                styles={showErr && selectedBrand === "" && customStyles}
                classNamePrefix={"select-opt"}
              // onCreateOption={this.handleCreate}
              />
              {/* {this.renderSelectBox(brand, "Select Brand", selectedBrand, this.selectBrand, showErr)} */}
              {showErr && selectedBrand === "" && <Error />}
            </div>
          </div>

          <div className="slct-optns">


            <div className="optns">

              <div className="label-txt">HSN No. <span className="highlighted">*</span></div>

              <Select
                options={this.state.getHsnCode}
                // isMulti
                placeholder="HSN No."
                value={this.props.hsn_code}
                onChange={this.selectHsnCode}
                // styles={showErr === "" && customStyles}
                classNamePrefix={"select-opt"}
              // onCreateOption={this.handleCreate}
              />
              {/* {this.renderSelectBox(
                this.state.getHsnCode,
                "HSN No.",
                hsn,
                this.selectHsnCode,
                showErr
              )} */}
              {showErr && selectedCategory === "" && <Error />}
            </div>


            <div className="optns">
              <div className="label-txt">Select GST category  <span className="highlighted">*</span> </div>

              <Select
                options={gstCatList}
                placeholder="Select GST"
                value={selected_gst}
                onChange={(e) => this.props.enterText(e, "selected_gst")}
                classNamePrefix={"select-opt"}
                styles={showErr && selected_gst === "" && customStyles}
              />
              <br />
              {showErr && selected_gst === "" && <Error />}
            </div>
          </div>

          {/* upload Image  */}
          <div className="image-head">
            <h5> Upload images <span className="highlighted">*</span></h5>
            <div>
              <div className="uploadimg-desc">
                In your first photo set your item against a white background so
                it is clearly visible. One photo must be from the official
                website.
              </div>
            </div>
            <div className="uploading-file-wrap">
              <label
                ref={this.dropRef}
                className={
                  showErr && files.length === 0
                    ? "custom-file-upload error-sell-input"
                    : "custom-file-upload"
                }
              >
                {
                  files.length < 8
                  && <input
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    multiple
                    onChange={(e) => this.handleChangeImage(e)}
                    capture="environment"
                  />
                }
                {
                  files.length > 0 ?
                    <>
                      {
                        files.map((_, index) => {
                          if (files[index]) {
                            return (
                              <div className="rend-box">
                                <label className="custom-file-render">
                                  <div className="image-text">Photo {index + 1} {pic_headers[index]}</div>
                                  <img
                                    className="display-image"
                                    src={files[index]}
                                    alt="product"
                                  />
                                </label>
                                <div>
                                  {index !== 0 && (
                                    <button
                                      className="default_btn default-maker"
                                      onClick={() => this.props.setDefaultPopup(index)}
                                    >
                                      Make Default
                                    </button>
                                  )}
                                  <button
                                    className="remove-img_btn remove-image"
                                    onClick={() => this.props.resetImgPopupAction(index)}
                                  >
                                    <i className="fal fa-trash-alt"></i>
                                  </button>
                                </div>
                              </div>
                            )
                          }
                        }
                        )
                      }
                      {
                        files.length < 8 ?
                          (
                            <div className="rend-box input-boxes">
                              <i className="fal fa-plus"></i>
                              <div className="box-texts">Upload Image</div>
                            </div>
                          )
                          : null
                      }
                    </>
                    :
                    <span className="upload-icn">
                      <img src="/assets/images/upload-icn.svg" alt="upload" />
                      <span className="browse">Browse to choose a file</span>
                      <span className="drag">
                        Or Drag and Drop upto 8 photos
                      </span>
                    </span>
                }
              </label>
              <br />
            </div>
          </div>
        </div >
        {
          this.props.resetImgPopup && (
            <Popup
              closePopup={this.props.resetImgPopupAction}
              submitFun={this.props.resetImageFun}
              content={
                "Do you really want to permanently delete this Image from server "
              }
            />
          )
        }
        {
          this.props.setDefaultImgPopup && (
            <Popup
              closePopup={(e) => this.props.setDefaultPopup(e)}
              submitFun={this.props.setDefault}
              content={"Set this image as default Image ?"}
            />
          )
        }
      </div >
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    aboutProductData: state.sellItemInfo.aboutProductData,
    categories: state.productListingInfo.categories,
    brands: state.sellItemInfo.specificBrands,
    // uploadImageData: state.sellItemInfo.uploadImageData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAboutProductData: (aboutProductData) =>
      dispatch(setAboutProductData(aboutProductData)),
    get_subCategory_specific_brand: (payload) =>
      dispatch(getSubCategorySpecificBrands(payload)),
    setUploadImageData: (uploadImageData) =>
      dispatch(setUploadImageData(uploadImageData)),
    addBrandRequest: (payload, response) => addBrandRequest(payload, response)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AboutProduct);
