import React from 'react';
import { Link } from 'react-router-dom';


const CardDiaries = ({ props }) => {
    return (
        <div className="diaries-block">
            <div className="szn-img">
                <img src={props.img} alt={props.slug} />
            </div>
            <div className="diaries-caption">
                <p>{props.heading}</p><br />
                <button type="button"><Link to={`story/${props.slug}/${props.id}`}><strong>DISCOVER MORE</strong></Link></button>
            </div>
        </div>

    );
}

export default CardDiaries;