import React from 'react';
import '../../PrivacyPolicy/PrivacyPolicy.css';
import SiteMap from "../../staticPages/Sitemap/SiteMap.jpg"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_cms_data_new } from "../../../store/actions/cms_data";
class Sitemap extends React.Component {

  constructor() {
    super();
    this.state = {
      aboutHtml: ""
    }
  }

  componentDidMount() {
    let getSlug = this.props.match.path.substr(this.props.match.path.lastIndexOf('/') + 1);
    this.props.get_cms_data_new({ slug: getSlug }, (status) => {

      this.setState({ aboutHtml: status.cms_data.description });
    })

    window.scrollTo(0, 0)
  }
  render() {

    return (

      <div className='sitemap full-width-view'>
        <div className='container'>
          <img src='../assets/images/sitemap-one.jpg' />
          <img src='../assets/images/sitemap-two.jpg' />
        </div>
      </div>

    );
  }

}


const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_cms_data_new: (payload, completion) =>
      dispatch(get_cms_data_new(payload, completion))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sitemap));