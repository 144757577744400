import React from 'react';
import '../../PrivacyPolicy/PrivacyPolicy.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_cms_data_new } from "../../../store/actions/cms_data";
class Cancel extends React.Component {

  constructor() {
    super();
    this.state = {
      aboutHtml: ""
    }
  }

  componentDidMount() {
    let getSlug = this.props.match.path.substr(this.props.match.path.lastIndexOf('/') + 1);
    this.props.get_cms_data_new({ slug: getSlug }, (status) => {
      this.setState({ aboutHtml: status.cms_data.description });
    })
    window.scrollTo(0, 0)
  }
  render() {
    return (
      // <div dangerouslySetInnerHTML={{ __html: this.state.aboutHtml }} />
      <div class="footer-pages">
        <div class="container">
          <h3>Returns & Refunds (MetalsBuy.in)</h3>
          <p>Most items purchased from sellers listed on MetalsBuy.in are returnable within the return window, except those
            that are explicitly identified as not returnable. </p>
          <p>For the products that are returned by the customer, the refund is issued to the original payment method
            (in case of pre-paid transactions) or to the bank account, the details for making such refund and the timelines are detailed in the refund policy available here.  </p>
          <div className='space-wrap'>
            <h3>General Returns Policy</h3>
            <div className='footer-details'>
              <ul>
                <li>Applicable products are returnable within the applicable return window if you’ve received them in a condition that is physically damaged, has missing parts or accessories, defective or different from their description on the product detail page on MetalsBuy.in.</li>
                <li>If you report an issue with your Smartphone, Tablet, Laptop, Television, Air Conditioner, Refrigerator, Washing Machine, Microwave, we may facilitate scheduling a technician visit to your location. Subject to MetalsBuy.in Terms and Conditions, a resolution will be provided based on the technician's evaluation report.</li>
                <li>Return will be processed only if:
                  <ul>
                    <li>It is determined that the product was not damaged while in your possession;</li>
                    <li>The product is not different from what was shipped to you;</li>
                    <li>The product is returned in original condition (with brand’s/manufacturer's box, MRP tag intact, user manual, warranty card and all the accessories therein).</li>
                  </ul>
                </li>
                <li>If you wish to return an electronic device that stores any personal information, please ensure that you have removed all such personal information from the device prior to returning.it shall not be liable in any manner for any misuse or usage of such information.</li>
                <li>Products may not be eligible for return in some cases, including cases of buyer's remorse such as incorrect model or color of product ordered or incorrect product ordered.</li>
                <li>Products marked as "non-returnable" on the product detail page cannot be returned.</li>
                <li>No additional information is required to return an eligible order unless otherwise noted in the category specific policy. </li>
                <li>Products purchased by international customers are not eligible for returns. However, orders made by international customers are eligible for refunds.</li>
              </ul>
            </div>
            <p><strong>Note:</strong> If you've received a non-returnable product in a damaged/defective condition, you can contact us within 10 days from the delivery of the product.</p>
          </div>
          <h3>Categories with associated return window and exceptions, if any (Click on the
            below links to jump into the particular category):</h3>
          <div className='lists footer-details'>
            <ul>
              <li><a href='#'>Musical Instruments & Video Games</a></li>
              <li><a href='#'>Books</a></li>
              <li><a href='#'>Mobiles, Computers & Accessories</a></li>
              <li><a href='#'>TV, Appliances & Electronics</a></li>
              <li><a href='#'>Home</a></li>
              <li><a href='#'>Home Improvement</a></li>
              <li><a href='#'>Kitchen</a></li>
              <li><a href='#'>Toys & Baby Products</a></li>
              <li><a href='#'>Sports, Fitness & Outdoors</a></li>
              <li><a href='#'>Beauty, Health & Groceries</a></li>
              <li><a href='#'>Clothing & Accessories</a></li>
              <li><a href='#'>Jewellery, Watches & Eyewear</a></li>
              <li><a href='#'>Handbags & Luggage</a></li>
              <li><a href='#'>Car, Motorbike & Industrial</a></li>
              <li><a href='#'>Lead-Acid Batteries</a></li>
              <li><a href='#'>Collectibles</a></li>
            </ul>
          </div>
          <div className='space-wrap'>
            <h3>1. Musical Instruments & Video Games</h3>
            <div className='category'>
              <h4>Musical Instruments</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
              <p><strong>Non Returnable:</strong>  For all Wind Instruments (Harmonicas & Flutes)</p>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness nature of the product.
                However, in the unlikely event of damaged, defective or different/wrong item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Video Games (Games)</h4>
              <div className='policy'><span> Non-returnable</span></div>
              <p>This item is non-returnable due to consumable (digital) nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Video Games (Accessories)</h4>
              <div className='policy'><span>7 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 7 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with outer box or case, accessories, CDs, user manual, warranty cards, scratch cards, and other accompaniments in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
              <p><strong>Non-returnable:</strong> Select items labelled as non-returnable on the product detail page are not eligible for returns.</p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>2. Books</h3>
            <div className='category'>
              <h4>Books</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
              <p><strong>Note:</strong> Books are non-returnable for International Customers for Export Orders.</p>
            </div>
            <div className='category'>
              <h4>Used Books</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>3. Mobiles, Computers & Accessories</h3>
            <div className='category'>
              <h4>Mobiles (new and Renewed)</h4>
              <div className='policy'><span>7 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 7 days of delivery, in an unlikely event of defective or different/wrong item delivered to you.
                In the rare event that you receive a damaged device, please create a return/refund request within 48 hours of order delivery. Raising a return/refund request any time after 48 hours of order delivery will result in your return/refund request being rejected.
                For few products, we may facilitate scheduling a technician visit to your location. On the basis of the technician's evaluation report, we will provide resolution. Please keep the item in its original condition, with original accessories like chargers, headsets etc., brand outer box, MRP tags attached, user manual in manufacturer packaging for for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Mobile Accessories (new and Renewed)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different/wrong item delivered to you.
                Please keep the item in its original condition, with MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
              <p><strong>7 Days Return/Refund Only:</strong> Power Banks (new and certified refurbished), Headsets (new & certified refurbished), Data Cables(new & certified refurbished) and Travel Chargers & Adapters new & certified refurbished).</p>
              <p><strong>Non-Returnable:</strong> Screen guards, screen protectors and tempered glasses</p>
            </div>
            <div className='category'>
              <h4>Tablets (new and Renewed), Work Space and Gadget Accessories</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, CDs and original accessories in manufacturer packaging for a successful return pick-up. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
                For few products, we may facilitate scheduling a technician visit to your location. On the basis of the technician's evaluation report, we will provide resolution.
              </p>
            </div>
            <div className='category'>
              <h4>Laptops (new and Renewed), Printers</h4>
              <div className='policy'><span>7 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 7 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, CDs and original accessories in manufacturer packaging for a successful return pick-up. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
                For few products, we may facilitate scheduling a technician visit to your location. On the basis of the technician's evaluation report, we will provide resolution.
              </p>
            </div>
            <div className='category'>
              <h4>Desktops, Monitors, Pen Drives, Hard Drives, Memory Cards, Internet Devices,
                Computer Accessories, Graphic Cards, CPU, Power Supplies, Motherboards,
                Cooling Devices, TV cards, Computing Components (new and certified
                refurbished) and Ink</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 7 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, CDs and original accessories in manufacturer packaging for a successful return pick-up. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
                For few products, we may facilitate scheduling a technician visit to your location. On the basis of the technician's evaluation report, we will provide resolution.
              </p>
              <p><strong>Non-Returnable:</strong> Select items labelled as non-returnable on the product detail page are not eligible for returns.</p>
            </div>
            <div className='category'>
              <h4>Networking Devices (new and Renewed)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
              <p><strong>Non-Returnable:</strong> Select items labelled as non-returnable on the product detail page are not eligible for returns.</p>
            </div>
            <div className='category'>
              <h4>Software</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>For software-related technical or installation issues, please contact the brand directly. In case of damages, you may return the product for a full refund. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
              <p><strong>Non-Returnable:</strong> Select items labelled as non-returnable on the product detail page are not eligible for returns.</p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>4. TV, Appliances & Electronics</h3>
            <div className='category'>
              <h4>Televisions, Large Appliances (new and Renewed) like Air Coolers, Air
                Conditioner, Refrigerator, Washing Machine, Dishwasher, Microwave</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different/wrong item delivered to you.
                Please keep the item in its original condition, with user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
                If you report an issue with your Air Conditioner, Refrigerator, Washing Machine or Microwave, we may facilitate scheduling a technician visit to your location. On the basis of the technician's evaluation report, we will provide resolution.
              </p>
            </div>
            <div className='category'>
              <h4>Digital Cameras, Camera Lenses, Camera Accessories, Security cameras,
                Speakers & Home Theaters, Headphones</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Headphones</h4>
              <div className='policy'><span>7 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 7 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>5. Home</h3>
            <div className='category'>
              <h4>Home (new and Renewed)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Wax candles, Wall stickers</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Furniture</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different/wrong item delivered to you.
                Please keep the item in its original condition, with user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
                If you report an issue , we may facilitate scheduling a technician visit  to your location. On the basis of the technician's evaluation report, we will provide resolution.
              </p>
            </div>
            <div className='category'>
              <h4>Pet – Habitats & Supplies, Apparel & Leashes, Training & Behavior Aids, Toys,
                Aquarium Supplies such as Pumps, Filters and Lights</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Pet Food, Pet Shampoos and Conditioners, Pest Control, harnesses, aquarium
                supplies and Pet Grooming Aids</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>6. Home Improvement</h3>
            <div className='category'>
              <h4>All home improvement supplies (drilling machines & accessories), Mopping
                supplies, Ladders (step & telescopic), fasteners & hooks etc</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>All Cleaning, Painting & Decor supplies (like mops/brooms, dustpans, paints,
                brushes, wallpapers, wall paintings etc.)</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>All Plumbing, Kitchen & Bath fixtures (like Taps, Faucets, Bath hardware, sinks
                etc.), Safety & Security systems (Safes, Home security systems, Door locks),
                Sweeping cloths & wipes, Hot water dispensers, Building materials, Dimmer &
                Lighting controls, cord & power management etc.</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>7. Kitchen</h3>
            <div className='category'>
              <h4>Kitchen (new & Renewed)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>All kitchen appliances (like Mixer grinders, Juicers, Blenders, Food Processors,
                Toasters, Induction, Rice Cookers, Oven toaster grills, etc.) and home
                appliances (like Fans, Coolers, Water Heaters, Irons, Air purifiers, Humidifiers,
                Vacuums, etc.)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Extended Warranty cards/vouchers/AMCs etc.</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to consumable (e-vouchers/coupons) nature of the product
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>8. Toys & Baby Products</h3>
            <div className='category'>
              <h4>Toys (new and Renewed)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Baby: Bathing and Accessories including FMCG; Diapering (Pads, Covers,
                Nappy Liners, Wet Wipes); Feeding Bottles, Breastfeeding products, Teethers;
                Baby Personal Care; Baby Hair & Skin Care Products</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness/consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Baby: Toys, Prams, Strollers, Carriers; Baby Utensils & Feeding Sets, Baby
                Apparel, Diaper Bags; Baby Furnitures, Nursery Decor</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>9. Sports, Fitness & Outdoors</h3>
            <div className='category'>
              <h4>Sports, Fitness and Outdoors (new and Renewed)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Aerobic Training Machines like Treadmills, Exercise Bikes, Home Gyms/ Multi
                Gyms, Activity Trackers, Ellipticals, Camping furniture, Bicycles</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>All inner apparel, socks, gym supporters, guards, swimsuits</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Subscription packs, gym vouchers etc.</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness nature of the product.
              </p>
            </div>
            <div className='category'>
              <h4>Garden & Outdoors</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Fertilizers, Seeds, Live Plants</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness/consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>10. Beauty, Health & Groceries</h3>
            <div className='category'>
              <h4>Beauty</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and personal care nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
              </p>
            </div>
            <div className='category'>
              <h4>Sports Nutrition (Whey Protein Powders and related Diet Supplements),</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with outer box or case, user manual, warranty cards, and other accompaniments in manufacturer packaging for a successful return pick-up. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Mobility and Disability Aids, Joint Supports, Wheelchairs etc.</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with outer box or case, user manual, warranty cards, and other accompaniments in manufacturer packaging for a successful return pick-up. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Household Supplies: Laundry Products (Detergents, Fabric Conditioners),
                Household Cleaners (Toilet Cleaners, Floor Cleaners, Hand washes, Tissues),
                Air Fresheners (Sprays, Gels) etc.</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Personal Care: Oral Care (Toothbrushes, toothpastes, mouthwashes etc);
                Feminine Hygiene (Sanitary Pads, Panty Liners, Menstrual Cups etc.); Shaving
                and Hair Removal (Men’s Shaving – Razors, Blades, Shaving Foams, Brushes
                etc.; Men’s Beard Care – Beard Oil, Beard Serum etc.; Women’s Hair Removal –
                Wax Strips, Creams, Razors etc)</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Family Nutrition: (Infant Baby Food, Toddlers’ and Kids’ Health Drinks)</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Vitamin and Mineral Supplements: (Core Letter Vitamins, Multi-Vitamins)</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Contact Lenses and Related Products</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Health Care Products: Ayurveda Products, Pain Relief Products, Herbal
                Supplements, Medical Supplies, Adult Diapers</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Medicines</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene and consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable. We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
                In the event your order is rejected, a refund will be issued as per MetalsBuy.in Refund Policy.
              </p>
            </div>
            <div className='category'>
              <h4>Personal Care Appliances: Shavers, Trimmers, Hair Dryers, Straighteners;
                Skin care, Nail care, Medical equipment</h4>
              <div className='policy'><span>7 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 7 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with outer box or case, user manual, warranty cards, and other accompaniments in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Personal Care Appliances: Oral Care Products and Appliances</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness/consumable nature of the product. However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Grocery & Gourmet</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness/consumable nature of the product. However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Pantry</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness/consumable nature of the product. However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>11. Clothing & Accessories</h3>
            <div className='category'>
              <h4>Clothing: Trackpants, Joggers, Bathrobes, Thermals, Jackets, Blazers, Coats,
                Cardigans, Sweaters, Sweatshirts & Hoodies, Sleepwear, Men's Bottoms,
                Men's Shirts, Men's suits, Men's ethnic wear, Men's Outerwear, Women's bras,
                Accessories</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Clothing: Others (including Women's bottoms, Women's outerwear, Jeans,
                Women's shirts, Women's ethnic wear etc)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
              </p>
              <p><strong>Non-Returnable</strong> Items like lingerie/socks/inner-wear are non-returnable due to hygiene conditions. However, in the unlikely event of a damaged, defective or different item delivered to you, we will provide full refund as applicable.</p>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.</p>
            </div>
            <div className='category'>
              <h4>Women Sports Shoes</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Shoes and Accessories (excluding women's Sports shoes)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Men & Women innerwear, Men's Vests, Women's Lingerie, Swimwear, Socks
                & hosiery</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>Non-Returnable Items like lingerie/socks/inner-wear are non-returnable due to hygiene conditions. However, in the unlikely event of a damaged, defective or different item delivered to you, we will provide full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>12. Jewellery, Watches & Eyewear</h3>
            <div className='category'>
              <h4>Fashion /Imitation Jewelry, Silver jewelry, Precious Jewellery, Eyewear and
                Analog / Digital Watches (new and certified refurbished)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.</p>
            </div>
            <div className='category'>
              <h4>Smartwatches/ Activity Tracker (new and certified refurbished)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.</p>
            </div>
            <div className='category'>
              <h4>Gold Coins / Gold Vedhanis / Gold Chips / Gold Bars / Silver Coins / Silver Bars
                /Silver Chips / Silver Bullion / Rakhi</h4>
              <div className='policy'><span>Non Returnable</span></div>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>13. Handbags & Luggage</h3>
            <div className='category'>
              <h4>Backpacks</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.</p>
            </div>
            <div className='category'>
              <h4>Handbags, Travel Luggage, Wallets & Travel Accessories</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.</p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>14. Car, Motorbike & Industrial</h3>
            <div className='category'>
              <h4>Rims, Car Parts & Accessories, Bike Parts & Accessories, Helmets & other
                Protective Gear, Vehicle Electronics (new and Renewed)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Car Tires, Car Care, Chemicals & Fluids, Engine Oils</h4>
              <div className='policy'><span>Non Returnable </span></div>
              <p>This item is non-returnable due to consumable nature of the product. However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Fasteners, Industrial Electrical, Lab & Scientific Products, Material Handling
                Products, Professional Medical Supplies, Tapes, Measure & Inspect Products,
                Industrial Hardware, Industrial Power & Hand Tools (new and certified
                refurbished)</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Occupational Health & Safety Products (masks, gloves, safety glasses etc.)</h4>
              <div className='policy'><span>Non Returnable</span></div>
              <p>This item is non-returnable due to hygiene/health and personal care/wellness nature of the product. However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
            <div className='category'>
              <h4>Packaging & Shipping Supplies, Industrial adhesive tapes & sealants, Food
                Service Equipment & Supplies, airflow & air quality meters etc.</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>This item is eligible for return/refund, within 10 days of delivery, in an unlikely event of damaged, defective or different item delivered to you.
                Please keep the item in its original condition, with brand outer box, MRP tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>15. Lead-Acid Batteries</h3>
            <div className='category'>
              <h4>Rims, Car Parts & Accessories, Bike Parts & Accessories, Helmets & other
                Protective Gear, Vehicle Electronics (new and Renewed)</h4>
              <div className='policy'><span>Non-Returnable</span></div>
              <p>This item is non-returnable due to consumable nature of the product.
                However, in the unlikely event of damaged, defective or different item delivered to you, we will provide a full refund as applicable.
                We may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
          <div className='space-wrap'>
            <h3>16. Collectibles</h3>
            <div className='category'>
              <h4>Fine Art, Sports collectibles, Entertainment collectibles, Coin collectibles</h4>
              <div className='policy'><span>10 Days Return/Refund Only</span></div>
              <p>In an unlikely event of damaged, defective or different item delivered to you, sellers to be informed within 10 days of delivery.
                We/Seller may contact you to ascertain the damage or defect in the product prior to processing the return/refund.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_cms_data_new: (payload, completion) =>
      dispatch(get_cms_data_new(payload, completion))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cancel));
