import React from "react";

const Loader = (props) => {
    return (
        <>
            <div className="loader-wrapper">
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
                <div className="loader-text">{props.message}</div>
            </div>
        </>
    )
}

export default Loader;