import React, { useState, useEffect } from "react";
import ActionItem from "./ActionItem";
import Statistics from "./AccountStatistics";
import Performance from "./Performance";
import { priceWithSymbol } from "../../../../utils/constants";
import { Link } from "react-router-dom";
import { isEmpty, get } from "lodash";
import Loader from "../../../Loader";

const temp = [
  { key: "AOV", unit: "/item", note: "Average Order Value" },
  { key: "ALV", unit: "/item", note: "ABAP List Viewer" },
  { key: "STR", unit: "%", note: "Sell-Through Rate" },
  { key: "item_views", unit: "/day", note: "Item Views" },
  { key: "item_likes", unit: "/day", note: "Item Likes" },
  { key: "cancellation", unit: "%", note: "Cancellation rate" },
  { key: "response_rate", unit: "%", note: "Response rate" },
  { key: "shipping_time", unit: "hours", note: "Shipping Time" },
  { key: "seller_rating", unit: "/5", note: "Seller Rating" },
];

const dropdown = [
  { key: "All Time", value: "All_time" },
  { key: "Last 24 hours", value: "Last_24_hours" },
  { key: "Last 7 days", value: "Last_7_days" },
  { key: "Last 30 days", value: "Last_30_days" },
  { key: "Last 365 days", value: "Last_365_days" },
];

const MyDashboard = (props) => {
  console.log("active data",props)
  const [active_data, setActive_data] = React.useState({});
  const [showLoader, setShowLoader] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState("");

  //let order = active_data[1].Inactiveorder
  React.useEffect(() => {
    setShowLoader(true);
    setLoaderMessage("Please wait...");
    if (props.profileInfo.active_data) {
      let tempObj = {};
      Object.keys(props.profileInfo.active_data[0]).map((i) =>
        typeof props.profileInfo.active_data[0][i] === "object" &&
          props.profileInfo.active_data[0][i] !== null
          ? (tempObj[i] = parseFloat(
            props.profileInfo.active_data[0][i].$numberDecimal
          ))
          : (tempObj[i] = parseFloat(props.profileInfo.active_data[0][i]))
      );
      props.profileInfo.active_data[0] = tempObj;
      setActive_data(props.profileInfo.active_data);
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 1500);
  }, [props.profileInfo]);
console.log("active_data[1]?.Inactiveproduct",active_data)
  return (
    <div>
      {showLoader ? <Loader message={loaderMessage} /> : null}
      {/* Card -row */}
      <div className="feature-card-row bazar-transaction-dashboard">
        {/* first card */}
        <div className="feature-card orange">
          {/* <span>Account balance</span>
          <h4>
            {!isEmpty(active_data)
              ? priceWithSymbol(active_data[0].current_balance)
              : priceWithSymbol(0)}
          </h4> */}
          {props &&
            props.profileInfo &&
            props.profileInfo.toatl_order_amount ? (
            <h6 className="in-process">
              Total order amount:{" "}
              <div>
                {props &&
                  props.profileInfo &&
                  props.profileInfo.toatl_order_amount}
              </div>
            </h6>
          ) : null}
          {props && props.profileInfo && props.profileInfo.pending_orders ? (
            <h6 className="in-process">
              <Link to="/profile/seller-order/pending">
                Pending orders:{" "}
                <div>
                  {props &&
                    props.profileInfo &&
                    props.profileInfo.pending_orders}
                </div>
              </Link>
            </h6>
          ) : null}
          {props && props.profileInfo && props.profileInfo.pending_offers ? (
            <h6 className="in-process">
              <Link to="/profile/my-offer/pending">
                Offers received:{" "}
                <div>
                  {props &&
                    props.profileInfo &&
                    props.profileInfo.pending_offers}
                </div>
              </Link>
            </h6>
          ) : null}
          {/* <Link to={{ pathname: "/profile/seller/withdraw" }} className="transcation-btn-wrap">
            <button className="withdraw-btn-wrap feature-button header-sell">
              Withdraw balance
            </button>
          </Link> */}
        </div>

        {/* second card */}
        <div className="feature-card purple">
          <Link className="card-link-dashboard" to="/profile/my-listing/active">
            <span>Active listing</span>
            <h4>{!isEmpty(active_data) ? active_data[1].Activeproduct : 0}</h4>
          </Link>
          <Link className="card-link-dashboard" to="/profile/my-listing/inactive">
            <span>Inactive listing</span>
            <h4>{!isEmpty(active_data) ? active_data[1].Inactiveproduct : 0}</h4>
          </Link>
          <Link className="card-link-dashboard" to="/profile/seller-order/processed">
            <span>Order in progress</span>
            <h4>{!isEmpty(active_data) ? active_data[1].Inactiveorder : 0}</h4>
          </Link>
          {/* <Link className="withdraw-btn-wrap  feature-button header-sell transcation-btn-wrap" to="/sell-product">
            Start listing
          </Link> */}
        </div>

        {/* third-card */}
        <div className="feature-card green">
          {/* <span>Ready to ship</span> */}
          <Link
            to="/profile/seller-order/processed"
            className="bazar-help-center"
          >
            {" "}
            Ready to ship:{" "}
            <div>
              {props && props.profileInfo && props.profileInfo.ready_to_ship}
            </div>
          </Link>
          <Link
            to="/profile/seller-order/processed"
            className="bazar-help-center"
          >
            {" "}
            Awating payment:{" "}
            <div>
              {props && props.profileInfo && props.profileInfo.awating_payment}
            </div>
          </Link>
        </div>
      </div>
      <ActionItem profileInfo={props.profileInfo} />

      {/* <Statistics
        profileInfo={props.profileInfo}
        dropdown={dropdown}
        statisticsFilter={props.statisticsFilter}

      /> */}

      {/* <Performance
        data={temp}
        dropdown={dropdown}
        userPerformance={props.userPerformance}
        performanceFilter={props.performanceFilter}
      /> */}
    </div>
  );
};
export default MyDashboard;
