import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../../utils/Service';
import { addAddress } from '../../../store/actions/addAddress';
import { getuserAddress } from '../../../store/actions/checkout';
import { apiUrl } from '../../../utils/urlEndpoints';
import Loader from '../../Loader';

const AddressAdd = ({getAddressHandle, paramsUserId}) => {
    const [addressForm, setAddressForm] = useState(false);
    const [loaderHandle, setLoaderHandle] = useState(false)

    const [addressLine, setAddressLine] = useState("");
    const [city, setCity] = useState("");
    const [stateForm, setStateForm] = useState("");
    const [pin, setPin] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState({value:""});
    const [landmark, setLandmark] = useState("");
    const [street, setStreet] = useState("");

    const [addressLineError, setAddressLineError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [stateFormError, setStateFormError] = useState(false);
    const [pinError, setPinError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [landmarkError, setLandmarkError] = useState(false);
    const [streetError, setStreetError] = useState(false);

    const [state, setState] = useState(null)
    useEffect(() => {
        axiosInstance.get("category/country").then((res) => {
            setState(res.data.data[0].stateData)
        });
    }, [])

    const handleEmail = (e) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (filter.test(e.target.value)) {
        }
        setEmail(e.target.value)
        setEmailError(false)
    }

    const handlePin = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            setPin(e.target.value)
            setPinError(false)
        }
    }

    const handlePhone = (event) =>{
        let value = event.target.value
        value = value.replaceAll(' ','')
        setPhone(phone => value.length <= 10 && !isNaN(Number(value)) && { value } || phone)
        setPhoneError(false)
    }

    const handleSaveAddress = () => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (email.length === 0) {
            setEmailError(true)
            return
        }
        else if (!filter.test(email)) {
            setEmailError(true)
            return
        }
        else if (name.length === 0) {
            setNameError(true)
            return
        }
        else if (phone.value.length === 0) {
            setPhoneError(true)
            return
        }
        else if (addressLine.length === 0) {
            setAddressLineError(true)
            return
        }
        else if (city.length === 0) {
            setCityError(true)
            return
        }
        else if (landmark.length === 0) {
            setLandmarkError(true)
            return
        }
        else if (street.length === 0) {
            setStreetError(true)
            return
        }
        else if (stateForm.length === 0) {
            setStateFormError(true)
            return
        }
        else if (pin.length === 0) {
            setPinError(true)
            return
        }
        setLoaderHandle(true)
        axiosInstance.post("/auth/add_address", {
            email: email,
            full_name: name,
            phone: phone.value,
            billing_flat_no: addressLine,
            billing_postcode: pin,
            billing_state: stateForm,
            billing_city: city,
            landmark: landmark,
            street: street,
            paramsUserId
        }).then((res) => {
            getAddressHandle()
            setAddressForm(false)
            setAddressLine("");
            setCity("");
            setStateForm("");
            setPin("");
            setEmail("");
            setName("");
            setPhone({value:""});
            setLandmark("");
            setStreet("");
            setLoaderHandle(false)
        }).catch(()=>{
            setLoaderHandle(false)
        });
    }

    const handleName = (e) => {
        let value = e.target.value
        value = value.replace(/[^A-Za-z]/ig, '')
        setName(value);
        setNameError(false)
    }

    const handleCity =(e) =>{
        let value = e.target.value
        value = value.replace(/[^A-Za-z]/ig, '')
        setCity(value); 
        setCityError(false)
    }


    return (
        <div className={`cart-page-address ${addressForm ? "have-separator":""}`}>
            {loaderHandle && <Loader message={"Please wait..."} />}
            {addressForm ? <div className="login-description w-100 login-description-form-wrap address-field-wrap upload-document-wrapper place-order-address">
                <form>
                    <div className="address-field-new-wrap">
                        <div className="form-block-wrap">
                            <label className="enter-mobile-label">
                                Email*
                            </label>
                            <div className="form-input">
                                <input
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </div>
                            {
                                emailError && <span className='error'>Please enter a valid email</span>
                            }
                        </div>
                        <div className="form-block-wrap">
                            <label className="enter-mobile-label">
                                Name*
                            </label>
                            <div className="form-input">
                                <input
                                    type="text"
                                    placeholder="Enter your name"
                                    value={name}
                                    onChange={handleName}
                                />
                            </div>
                            {
                                nameError && <span className='error'>Please enter your name</span>
                            }
                        </div>
                        <div className="form-block-wrap">
                            <label className="enter-mobile-label">
                                Phone number*
                            </label>
                            <div className="form-input">
                                <input
                                    type="text"
                                    placeholder="Enter your phone number"
                                    value={phone.value}
                                    onChange={handlePhone}
                                />
                            </div>
                            {
                                phoneError && <span className='error'>Please enter your phone number</span>
                            }
                        </div>

                        <div className="form-block-wrap">
                            <label className="enter-mobile-label">
                                Address line*
                            </label>
                            <div className="form-input">
                                <input
                                    type="text"
                                    placeholder="Enter Address line"
                                    id="addressOne"
                                    maxLength="50"
                                    value={addressLine}
                                    onChange={(e) => { setAddressLine(e.target.value); setAddressLineError(false) }}
                                />
                            </div>
                            {
                                addressLineError && <span className='error'>Please enter address line</span>
                            }
                        </div>

                        <div className="form-block-wrap">
                            <div className="form-intput">
                                <label className="enter-mobile-label">City*</label>
                                <input
                                    type="text"
                                    placeholder="Enter city name here"
                                    id="city"
                                    maxLength="100"
                                    value={city}
                                    onChange={handleCity}
                                />
                            </div>
                            {
                                cityError && <span className='error'>Please enter city</span>
                            }
                        </div>

                        <div className="form-block-wrap">
                            <div className="form-intput">
                                <label className="enter-mobile-label">Landmark*</label>
                                <input
                                    type="text"
                                    placeholder="Enter landmark here"
                                    maxLength="100"
                                    value={landmark}
                                    onChange={(e) => { setLandmark(e.target.value); setLandmarkError(false) }}
                                />
                            </div>
                            {
                                landmarkError && <span className='error'>Please enter landmark</span>
                            }
                        </div>
                        <div className="form-block-wrap">
                            <div className="form-intput">
                                <label className="enter-mobile-label">Street*</label>
                                <input
                                    type="text"
                                    placeholder="Enter street here"
                                    maxLength="100"
                                    value={street}
                                    onChange={(e) => { setStreet(e.target.value); setStreetError(false) }}
                                />
                            </div>
                            {
                                streetError && <span className='error'>Please enter street</span>
                            }
                        </div>

                        <div className="names-wrapper">
                            <div className="input-form-wrap select-affilation-profile address-name-wrap">
                                <div className="form-intput select-affilation-wrap">
                                    <label className="enter-mobile-label">
                                        State*
                                    </label>

                                    <div className="select-main-wrapper">
                                        <select
                                            id="state"
                                            onChange={(e) => { setStateForm(e.target.value); setStateFormError(false) }}
                                            value={stateForm}
                                        >
                                            <option value={""}>Select</option>
                                            {state && state.length
                                                ? state.map(
                                                    (item, index) => (
                                                        <option value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    )
                                                )
                                                : null}
                                        </select>
                                        <i className="icon-arrow-down"></i>
                                    </div>
                                    {
                                        stateFormError && <span className='error'>Please select state</span>
                                    }
                                </div>
                            </div>

                            <div className="form-block-wrap">
                                <div className="form-intput">
                                    <label className="enter-mobile-label">
                                        Pin code*
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter pincode here"
                                        id="zip"
                                        maxLength="6"
                                        value={pin}
                                        onChange={handlePin}
                                    />
                                </div>
                                {
                                    pinError && <span className='error'>Please enter pin code</span>
                                }
                            </div>
                        </div>
                        <div className='navigation-back' style={{ flexDirection: "column" }}>
                            <button type='button' className='next-btn' onClick={handleSaveAddress}>Save</button>
                        </div>
                    </div>
                </form>
            </div> : <button className="add-new-address-btn" onClick={() => setAddressForm(true)}>+ Add new address</button>}
        </div>
    )
}

export default AddressAdd