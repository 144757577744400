import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
import { submitContactForm } from "../../../store/actions/cms_data";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    useEffect(() => {
      const wow = new WOW.WOW({
        live: true, // Enables the live mode to detect changes dynamically
      });
      wow.init();
    }, []);
    return <Component {...props} {...toastFuncs} />;
  };
}

class ContactUs extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      phone: "",
      email: "",
      companyName: "",
      concern: "",

      nameError: false,
      phoneError: false,
      emailError: false,
      concernError: false,
      companyNameError: false,
      invalidEmail: false,
    };
  }

  handleContactUs = (e) => {
    e.preventDefault();

    if (this.state.name == "") {
      this.setState({ nameError: true });
      return;
    } else if (this.state.phone == "") {
      this.setState({ phoneError: true });
      return;
    } else if (this.state.email == "") {
      this.setState({ emailError: true });
      return;
    } else if (this.state.companyName == "") {
      this.setState({ companyNameError: true });
      return;
    } else if (this.state.concern == "") {
      this.setState({ concernError: true });
      return;
    }
    const payload = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.phone,
      concern: this.state.concern,
      companyName: this.state.companyName,
    };

    this.props.submitContactForm(payload, (status) => {
      if (status.status == "success") {
        this.props.addToast(status.message, {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        this.setState({
          name: "",
          phone: "",
          email: "",
          companyName: "",
          concern: "",
        });
      } else {
      }
    });
  };

  handleName = (e) => {
    const re = /^[A-Za-z ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ name: e.target.value, nameError: false });
    }
  };

  handlePhone = (e) => {
    var regex = /[0-9]|\./;
    if (regex.test(e.target.value)) {
      this.setState({ phone: e.target.value, phoneError: false });
    }
  };

  handleEmail = (e) => {
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (validRegex.test(e.target.value)) {
      this.setState({
        email: e.target.value,
        emailError: false,
        invalidEmail: false,
      });
    } else {
      this.setState({ email: e.target.value, invalidEmail: true });
    }
  };

  handleCompanyName = (e) => {
    this.setState({ companyName: e.target.value, companyNameError: false });
  };

  handleConcern = (e) => {
    this.setState({ concern: e.target.value, concernError: false });
  };
  render() {
    return (
      <>

      <section className="our-office">
        <div className="container">
          <div className="our-office-branch">
          <div className="our-office-wrap wow animate__animated animate__fadeInUp ">
          <span className="our-branch">INDIA OFFICE</span>
            <div className="our-branch-content">
              <div className="map">
                <img src="../../../assets/images/contact-map.png" alt="contact-map" />
              </div>
              <div className="text-content-wrapper">
              <div className="address-info">
                <div className="icons">
                  <span className="office-location office-icons"></span>
                </div>
                <div className="content">
                  <address>Ring Road No. 2, Gondwara, Raipur,
                    Chhattisgarh 492001, India</address>
                </div>
              </div>
              <div className="text-wrap">
                <div className="icons">
                <span className="office-tel office-icons"></span>
                </div>
                <div className="content">
                  <a href="tel:7880007036" className="number">+91 78800 07036</a>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="our-office-wrap wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">
          <span className="our-branch">USA OFFICE</span>
            <div className="our-branch-content">
              <div className="map">
              <img src="../../../assets/images/contact-map.png" alt="contact-map" />
              </div>
              <div className="text-content-wrapper">
              <div className="address-info">
                <div className="icons">
                <span className="office-location office-icons"></span>
                </div>
                <div className="content">
                  <address>14900 Avery Ranch Blvd Ste C200
                  PMB 1034, Austin, TX 78717, USA</address>
                </div>
              </div>
              <div className="text-wrap">
                <div className="icons">
                <span className="office-tel office-icons"></span>
                </div>
                <div className="content">
                  <a href="tel:+18722210709" className="number">+1 (872) 221-0709</a>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="our-office-wrap wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" data-wow-offset="15">
          <div className="our-branch-content">
             <div className="text-content-wrapper">
             <div className="text-wrap">
                <div className="icons">
                <span className="office-message office-icons"></span>
                </div>
                <div className="content">
                 <a href="maulto:contact@metalsbuy.com" className="mail">contact@metalsbuy.com</a>
                </div>
              </div>
             </div>
            </div>
          </div>
          </div>
        </div>
      </section>


        <section class="contact-ready-section wow animate__animated animate__fadeInUp">
          <div class="container">
            <h2><span className="title">Interested in Getting in touch with us?</span></h2>
            <div class="contact-ready-wrapper">
              {/* <div className="ltl-contact-wrap">
                <h2>Interested in Getting in touch with us?</h2>
                <div className="contact-detail-wrap">
                  <p>
                    Please call us at{" "}
                    <a href="tel:+91-7880007036
" className="contact">
                      91-7880007036

                    </a>
                  </p>
                  <p>
                    Email us at{" "}
                    <a href="mailto:contact@metalsbuy.com" className="contact">
                      contact@metalsbuy.com
                    </a>
                  </p>
                </div>
                <div className="contact-img">
                  <img src="/assets/images/contact-us-img.jpg" alt="contact" />
                </div>
              </div> */}
              <div class="rtl-contact-wrap">
                <div class="ready-form-control">
                  <form onSubmit={(e) => this.handleContactUs(e)} className="contact-info">
                    <div class="mb-3 form-block">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        onChange={(e) => this.handleName(e)}
                        value={this.state.name}
                      />
                      {this.state.nameError ? (
                        <span className="error">Please enter name</span>
                      ) : null}
                    </div>
                    <div class="mb-3 form-block">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Phone Number"
                        onChange={(e) => this.handlePhone(e)}
                        value={this.state.phone}
                        maxLength="10"
                      />
                      {this.state.phoneError ? (
                        <span className="error">Please enter phone number</span>
                      ) : null}
                    </div>
                    <div class="mb-3 form-block">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        onChange={(e) => this.handleEmail(e)}
                        value={this.state.email}
                      />
                      {this.state.emailError ? (
                        <span className="error">Please enter email</span>
                      ) : null}
                      {this.state.invalidEmail ? (
                        <span className="error">Please enter valid email</span>
                      ) : null}
                    </div>
                    <div class="mb-3 form-block">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Company Name"
                        onChange={(e) => this.handleCompanyName(e)}
                        value={this.state.companyName}
                      />
                      {this.state.companyNameError ? (
                        <span className="error">Please enter company name</span>
                      ) : null}
                    </div>
                    <div class="mb-3 form-block message-box">
                      <textarea
                        rows="3"
                        cols="50" placeholder="Message"
                        className="form-control text-area"
                        onChange={(e) => this.handleConcern(e)}
                      />
                      {this.state.concernError ? (
                        <span className="error">Please enter your concern</span>
                      ) : null}
                    </div>
                    <div class="mb-3 form-block submit-btn">
                      <button
                        type="submit"
                        class="btn btn-orange contact-btn-page"
                      >
                        Submit
                      </button>
                    </div>
                    {/* <div class="mb-3">
                      <p class="contact-note">or you can <a href="#">contact us</a></p>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    submitContactForm: (payload, completion) =>
      dispatch(submitContactForm(payload, completion)),
  };
};
export default withToast(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactUs))
);
