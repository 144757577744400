
import React from 'react';
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';

const AddressSearchBox = (props) => {
    const [address, setAddress] = React.useState("");
    const handleChange = address => {
        setAddress(address);
    };
    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                setAddress(address);
                const latLong = {
                    lat: results[0].geometry.location.lat(),
                    long: results[0].geometry.location.lng()
                }
                props.enterText(latLong, "latLong");
            })
            .catch(error => {
                props.enterText({ lat: 0, long: 0 }, "latLong")
            });
        // geocodeByPlaceId()
    };

    return (
        <div className="location-auto">
            <label className="label-txt">Search Nearby Place <span className="highlighted">*</span></label>
            <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                highlightFirstSuggestion={true}
                // shouldFetchSuggestions={address.length > 1}
                // searchOptions={{ types: ['address'], componentRestrictions: { country: "IN" } }}
                searchOptions={{ componentRestrictions: { country: "IN" } }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                    return (
                        <div>
                            <input
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: props.showErr && props.latLong === "" ? 'location-search-input error-sell-input' : 'location-search-input',
                                    autoFocus: true,

                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )
                }}
            </PlacesAutocomplete>
            {props.showErr && props.latLong === "" &&
                <span className="error-sell-text">
                    <sup>**</sup>Please type address and select one
                </span>}
        </div>
    );
}
export default AddressSearchBox;