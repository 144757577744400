import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import { axiosInstance } from '../../../../utils/Service';
import { ButtonLoader } from '../../../../utils/constants';
import Countdown from 'react-countdown';




const RfqProduct = ({ name, setLoaderState, setShowLoader }) => {
    let history = useHistory();
    let location = useLocation()
    const [offerDataListRfq, setofferDataListRfq] = useState([])
    const [totalOffers, setTotalOffers] = useState(null)
    const [page, setPage] = useState(1)
    const [moreLoading, setMoreLoading] = useState(false)
    const userData = useSelector(store => store.userProfile.userProfileBanner.userdata);

    console.log("first", location)

    useEffect(() => {
        if (userData) {
            let token = localStorage.getItem("token");
            setShowLoader(true);
            axiosInstance.post(`/requestForQuote/request_list`, {
                "userType": userData.userType,
                "requestStatus": name === "Active" ? "Pending" : name
            }, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            }).then((res) => {
                const sortedData = res.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setofferDataListRfq(sortedData);
                setMoreLoading(false);
                setLoaderState();
            }).catch(() => {
                setMoreLoading(false);
                setLoaderState();
            });
        }
    }, [userData, name]);
    

    const handleLoadMore = () => {
        setPage(page + 1);
        setMoreLoading(true)
        if (userData) {
            if (userData.userType === "Buyer") {
                let token = localStorage.getItem("token");
                axiosInstance.post(`/offers/buyer_offer_list`, {
                    offerStatus: name,
                    page: page + 1
                }, {
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                }).then((res) => {
                    setofferDataListRfq([...offerDataListRfq, ...res.data.data])
                    setTotalOffers(res.data.totalLength)
                    setMoreLoading(false)
                }).catch(() => {
                    setMoreLoading(false)
                });
            }
            if (userData.userType === "Seller") {
                let token = localStorage.getItem("token");
                axiosInstance.post(`/offers/seller_offer_list`, {
                    offerStatus: name,
                    page: page + 1
                }, {
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                }).then((res) => {
                    setofferDataListRfq([...offerDataListRfq, ...res.data.data])
                    setTotalOffers(res.data.totalLength)
                    setMoreLoading(false)
                }).catch(() => {
                    setMoreLoading(false)
                });
            }
        }
    }

    const handleLink = (event) => {
        console.log("event", event)
        history.push({ pathname: `/profile/rfq-offers/offer-detail/${event.id}/${event.name}`, state: { ...location.state, event } });
    }

    const renderer = ({days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return null;
        } else {
            // Render a countdown
            return (
                <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                    <p>Expires in:</p>
                    <span style={{ color: "#ed9829" }}>
                        <span>
                            {days}:{hours}:{minutes}:{seconds}
                        </span>
                        &nbsp; hrs
                    </span>
                </div>
            );
        }
    };

    function timeAgo(lastCounterActivity) {
        const now = new Date(); // Current time
        const lastActivityTime = new Date(lastCounterActivity); // Convert to Date object
        const timeDifference = now - lastActivityTime; // Time difference in milliseconds
    
        // Calculate time in minutes and hours
        const minutes = Math.floor(timeDifference / 1000 / 60);
        const hours = Math.floor(minutes / 60);
        
        if (minutes < 1) {
            return "Just now";
        } else if (minutes < 60) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        }
    }
    

    return (
        <div className="plp-product-screen">
            {offerDataListRfq && offerDataListRfq.length ?
                offerDataListRfq.map((item, index) => {
                    return <div key={index} className="buy-prod buy-product-wrapper order-process-wrapper">
                        <div className="inner-product-detail-wrap new-list-wrapper">
                            {/* <div className="est-deliveries order-east-deliveries">
                                <div className="prod-img order-product-img" style={{ "width": "148px", height: "148px" }}><button style={{ "width": "148px", height: "148px" }}><img
                                    src={item.product_data[0].default_image}
                                    onClick={() => handleLink({ id: item._id, name })}
                                    alt="img" /></button></div>
                            </div> */}
                            <div className="prod-detail product-orderinfo product-info-detail-wrap" style={{ alignItems: "flex-start" }}>
                                <Link onClick={() => handleLink({ id: item._id, name })} className="prod-link prod-new-link"
                                >
                                    <div className="product-box-wrapper sub-detail-order">
                                        <div className="home-detail qty-pricing">
                                            <div className="home-details"><span className="prod-text bold sub-name">Name: {item.productName}
                                            </span></div>
                                            <div className="home-details"><span className="prod-text bold sub-name">Quantity:&nbsp;</span><span
                                                className="prod-text value">{item.quantity}mt</span></div>
                                            {item.price ? <div className="home-details"><span className="prod-text bold sub-name">Price:&nbsp;</span><span
                                                className="prod-text value">₹{item.price}</span></div> : null}
                                            <div className="home-details"><span className="prod-text bold sub-name">Payment Terms:&nbsp;</span><span
                                                className="prod-text value">{item.paymentTerms}</span></div>
                                                {userData.userType === 'Buyer'? <div className="home-details"><span className="prod-text bold sub-name">Sellers received Request For Quote                                         :&nbsp;</span><span
                                                className="prod-text value">{/* Extract and display all seller full names */}
                                                    {item.sellerData.map(seller => seller.full_name).join(', ')}
                                                </span></div>: ''}
                                             

                                                {userData.userType === 'Seller'?<div className="home-details"><span className="prod-text bold sub-name">Buyer Name:&nbsp;</span><span
                                                className="prod-text value">{item.address_data.length > 0
                                                    ? item.address_data.map(address => address.full_name).join(', ')
                                                    : 'No Buyer Name found'}</span></div>: '' } 
                                                
                                            {/* {!(userData.userType === "Seller" && name === "AutoDeclined") && <div className="home-detail qty-pricing">
                                                <div className="home-details"><span className="prod-text bold sub-name">Company name:&nbsp;</span><span
                                                    className="prod-text value">{
                                                        (item.seller_data &&
                                                            item.seller_data[0] &&
                                                            item.seller_data[0].companyName) ?
                                                            item.seller_data[0].companyName :
                                                            (item.buyerData &&
                                                                item.buyerData.companyName) ?
                                                                item.buyerData.companyName : null
                                                    }</span></div>
                                            </div>} */}
                                        </div>
                                    </div>
                                </Link>
                                <div className=''>
                                    <button style={{ width: "222px" }} onClick={() => handleLink({ id: item._id, name })} className='date-wrp make-new-payment-btn pay-btn'>View offer details</button>
                                    {/* <div className="order-status-wrapper-box" style={{ marginBottom: "8px" }}>
                                        <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                                            <p>Offer status:</p>
                                            <div className="order-acceptance date-wrp">
                                                <span className="process order-request-state date-wrp">
                                                    {item.offerStatus === "AutoDeclined" ? "Auto Declined" : 
                                                    item.offerStatus === "CounterOffer" ? "Counter Offer" : item.offerStatus}</span>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* {location.pathname === "/profile/my-offer/pending" ? !(item.offerData.length === 1 && userData.userType === "Seller") && <div className="order-status-wrapper-box" style={{ marginBottom: "8px" }}>
                                        <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                                            <p>Offer seen:</p>
                                            <div className="order-acceptance date-wrp">
                                                <span className="">{item.readStatus ? "Yes" : "No"}</span>
                                            </div>
                                        </div>
                                    </div> : null} */}
                                    {location.pathname === "/profile/rfq-offers/active" && <div className="order-status-wrapper-box">
                                        <div className="order-acceptance date-wrp">
                                            <span className="">
                                                {userData && userData.userType === "Buyer" ? <Countdown
                                                    date={new Date(item.validUntil).getTime()}
                                                    renderer={renderer}
                                                    zeroPadTime={2}
                                                /> :
                                                    <Countdown
                                                        date={new Date(item.validUntil).getTime()}
                                                        renderer={renderer}
                                                        zeroPadTime={2}
                                                    />}
                                            </span>
                                        </div>
                                    </div>
                                    }

                                    {location.pathname === "/profile/rfq-offers/active" && userData.userType === 'Seller' && item && item.sellerData && item.sellerData.length > 0 && (
                                    <div className="order-status-wrapper-box">
                                        <div className="order-acceptance date-wrp">
                                        <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                                            <p>Status:</p>
                                            <span style={{ color: "#ed9829" }}>
                                            {(() => {
                                                // Flatten all counter offers across all sellers into one array
                                                const allCounterOffers = item.sellerData.reduce((acc, sellerItem) => {
                                                if (sellerItem && sellerItem.counterOffer && sellerItem.counterOffer.length > 0) {
                                                    return acc.concat(sellerItem.counterOffer);
                                                }
                                                return acc;
                                                }, []);

                                                // Get the latest counter offer from all sellers
                                                const latestCounterOffer = allCounterOffers.length > 0 
                                                ? allCounterOffers[allCounterOffers.length - 1] 
                                                : null;

                                                if (!latestCounterOffer) return null;

                                                // Display the latest status
                                                return (
                                                <div className="offer-status">
                                                    {latestCounterOffer.offerFrom === 'Seller' ? (
                                                    <span style={{ color: "#ed9829" }}>Offer sent</span>
                                                    ) : (
                                                    <span style={{ color: "#ed9829" }}>Counter Offer Received</span>
                                                    )}
                                                </div>
                                                );
                                            })()}
                                            </span>
                                        </div>
                                        </div>
                                    </div>
                                    )}

                                    {location.pathname === "/profile/rfq-offers/active" && userData.userType === 'Buyer' && <div className="order-status-wrapper-box">
                                        <div className="order-acceptance date-wrp">
                                            <span style={{}}>
                                            <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                                                <p>Last Activity:</p>
                                                <span style={{ color: "#ed9829" }}>
                                                    {item.lastCounterActivity ? timeAgo(item.lastCounterActivity) : 'Not available'}
                                                </span>
                                            </div>
                                            </span>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                })
                : <div className="product-head">
                    <div className="product-img">
                        <img src="\assets\images\no-product.png" alt="img"></img>
                    </div>
                    <h6> No Request For Quote (RFQ) in your list </h6>
                </div>}
            {/* {
                offerDataListRfq && offerDataListRfq.length < totalOffers ? <div className="plp-loadMore-div">
                    <button className="shop-now" onClick={handleLoadMore}>
                        {moreLoading ? <ButtonLoader content={"Loading"} /> : "Load more"}
                    </button>
                </div>
                    :
                    null
            } */}
        </div>
    )
}

export default RfqProduct;