import TextField from "@material-ui/core/TextField";
import React, { useState } from 'react';
import { useToasts } from "react-toast-notifications";

const LoginWithOtp = (props) => {
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showPass2, setShowPass2] = React.useState(false);
    const [showPass1, setShowPass1] = React.useState(false);
    const [password2, setPassword2] = React.useState("");
    const [passNotMatch, setPassNotMatch] = React.useState(false);
    const [matchPassword, setMatchPassword] = React.useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const { addToast } = useToasts();

    const handleFirstName = (e) => {
        const re = /^[A-Za-z]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFirstName(e.target.value);
        }

    }

    const handleLastName = (e) => {
        const re = /^[A-Za-z]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setLastName(e.target.value);
        }

    }

    const handleEmail = (e) => {
        var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (validRegex.test(e.target.value)) {
            setEmail(e.target.value);
            setInvalidEmail(false);
        } else {
            setInvalidEmail(true);
            setEmail(e.target.value);
        }

    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleConfirmPassword = (e) => {
        if (password == e.target.value) {
            setMatchPassword(false);
            setPassword2(e.target.value);
        } else {
            setMatchPassword(true);
            setPassword2(e.target.value);
        }

    }

    const formSubmit = (e) => {
        e.preventDefault();
        if (password !== password2) {
            return addToast("Password and Confirm Password does not match", {
                appearance: "error",
                autoDismissTimeout: 2500,
            });
        }
        if (invalidEmail) {
            return false
        }

        const payload = {
            email: email,
            mobile: props.mobile,
            first_name: firstName,
            last_name: lastName,
            password: password,
            confirmPassword: password2,
            userType: props.getType.type
        }

        props.login(payload);
    }


    return (
        <>

            <form autoComplete="off" onSubmit={(e) => formSubmit(e)}>
                <div className="detail-from ">
                    <div className="user-detail-edit">
                        <h4 className="form-title quick-signup">
                            Quick Sign up to MetalsBuy
                        </h4>
                    </div>
                    <div className="form-input">
                        <TextField
                            variant="outlined"
                            required
                            type="text"
                            name="firstName"
                            label="First Name*"
                            value={firstName}
                            onChange={(e) => handleFirstName(e)}
                            InputProps={{
                                inputProps: {
                                    maxLength: 30,
                                    minLength: 2,
                                },
                            }}

                        />
                    </div>
                    <div className="form-input">
                        <TextField
                            variant="outlined"
                            type="text"
                            name="lastName"
                            label="Last Name"
                            value={lastName}
                            onChange={(e) => handleLastName(e)}
                            InputProps={{
                                inputProps: {
                                    maxLength: 30,
                                    minLength: 2,
                                },
                            }}

                        />
                    </div>
                    <div className="form-input">
                        <TextField
                            variant="outlined"
                            required
                            type="email"
                            name="email"
                            label="Email Address*"
                            value={email}
                            onChange={(e) => handleEmail(e)}
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                        {invalidEmail ? <span className="password-otp-page">Enter a valid email address</span> : null}
                    </div>


                    <div className="form-input">
                        <TextField
                            variant="outlined"
                            required
                            type={showPass1 ? "text" : "password"}
                            name="password"
                            label="Password*"
                            value={password}
                            InputProps={{
                                inputProps: {
                                    maxLength: 15,
                                    minLength: 6,
                                    autoComplete: "new-password",
                                },
                            }}
                            onChange={(e) => handlePassword(e)}
                        />
                        <i
                            onClick={() => setShowPass1(!showPass1)}
                            className={`${showPass1 ? "fal fa-eye" : "far fa-eye-slash"
                                }`}
                        />

                    </div>
                    <div className="form-input">
                        <TextField
                            variant="outlined"
                            required
                            type={showPass2 ? "text" : "password"}
                            name="password2"
                            label="Confirm Password*"
                            value={password2}
                            InputProps={{
                                inputProps: {
                                    maxLength: 15,
                                    minLength: 6,
                                    autoComplete: "new-password",
                                },
                            }}
                            onChange={(e) => handleConfirmPassword(e)}
                        />
                        {matchPassword && password2 !== password ? <span className="password-otp-page">Password and confirm password does not match</span> : null}
                        <i
                            onClick={() => setShowPass2(!showPass2)}
                            className={`${showPass2 ? "fal fa-eye" : "far fa-eye-slash"
                                }`}
                        />
                    </div>

                    <div className="sign-btn">
                        <button className="shop-now quick-sign-up">Sign up</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default LoginWithOtp;