import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';


export const BlogCard = (props) => {
    return (
        <div className="blogcard-quad" >
            <figure>
                <img src={props.blog_image} alt={props.slug} />
            </figure>
            <div className="blogs-text">
                <div className="blog-date">
                    <i className="fas fa-stopwatch"></i>
                    {
                        moment(props.created_At).calendar({
                            sameDay: 'DD/MM/YYYY h:mm a',
                            nextDay: 'DD/MM/YYYY h:mm a',
                            nextWeek: 'DD/MM/YYYY h:mm a',
                            lastDay: 'DD/MM/YYYY h:mm a',
                            lastWeek: 'DD/MM/YYYY h:mm a',
                            sameElse: 'DD/MM/YYYY h:mm a'
                        })
                    }
                </div>
                <div className="blog-heading">
                    {props.content_heading}
                </div>
                <div className="blog-para"
                    dangerouslySetInnerHTML={{
                        __html: props.short_desc,
                    }}
                />
                <div className="read-wrap">
                    <Link to={{ pathname: `/story/${props.slug}/${props._id}` }} className="read-btn">
                        <button>Read Article</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export const BlogCard2 = (props) => {
    return (
        <div className="blogcard-dual" >
            <figure>
                <img src={props.blog_image} alt={props.slug} />
            </figure>
            <div className="blogs-text">
                <div className="blog-date">
                    <i className="fas fa-stopwatch"></i>
                    {
                        moment(props.created_At).calendar({
                            sameDay: 'DD/MM/YYYY h:mm a',
                            nextDay: 'DD/MM/YYYY h:mm a',
                            nextWeek: 'DD/MM/YYYY h:mm a',
                            lastDay: 'DD/MM/YYYY h:mm a',
                            lastWeek: 'DD/MM/YYYY h:mm a',
                            sameElse: 'DD/MM/YYYY h:mm a'
                        })
                    }
                </div>
                <div className="blog-heading">
                    {props.content_heading}
                </div>
                <div className="read-wrap">
                    <Link Link to={{ pathname: `/story/${props.slug}/${props._id}` }} className="read-btn">
                        <button>Read Article</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
