import {
  SET_DISCOVER_TREND_DATA,
  SET_DASHBOARD_BLOCK_DATA,
  SET_BANNER_IMAGE_DATA,
  SET_DASHBOARD_PRODUCT_DATA,
  SAVE_WISHLIST_DATA,
  SAVE_TESTIMONIAL,
} from "./../actionsTypes";

const initialState = {
  discoverTrendData: [],
  dashboardBlockData: [],
  bannerImage: [],
  wishlist: [],
  testimonial: [],
};

const dashboardInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISCOVER_TREND_DATA:
      return { ...state, discoverTrendData: action.payload };
    case SET_DASHBOARD_BLOCK_DATA:
      return { ...state, dashboardBlockData: action.payload };
    case SET_BANNER_IMAGE_DATA:
      return { ...state, bannerImage: action.payload };
    case SET_DASHBOARD_PRODUCT_DATA:
      return { ...state, [action.payload.blockID]: action.payload };
    case SAVE_WISHLIST_DATA:
      return { ...state, wishlist: action.payload };
    case SAVE_TESTIMONIAL:
      return { ...state, testimonial: action.payload };
    default:
      return state;
  }
};
export default dashboardInfo;
