import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateShippingLot } from '../../store/actions/updateShippingLot';
import { useToasts } from "react-toast-notifications";
import Loader from '../Loader';

const OrderReceived = ({ item, setAllReceived }) => {
  const { addToast } = useToasts();
  const [enableForm, setEnableForm] = useState(true)
  const dispatch = useDispatch()
  const [fileAttach, setFileAttach] = useState([]);
  const [errorImages, setErrorImages] = useState(false)
  const [showLoader, setShowLoader] = useState(false);
  const [lotReceived, setLotReceived] = useState(false)
  const [showMessage, setShowMessage] = useState("Please wait...");
  const [img, setImg] = useState("");
  const fileHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFileAttach(e.target.files);
      let src_ = URL.createObjectURL(e.target.files[0]);
      setImg(src_);
      setErrorImages(false)
    }
  };
  useEffect(() => {
    setLotReceived(item.isReceived)
  }, [item])
  const handleReceived = () => {
    let formData = new FormData();
    if (fileAttach.length == 0) {
      setErrorImages(true)
      return;
    }
    formData.append("order_shipping_id", item._id)
    formData.append("isReceived", true)
    for (let element of fileAttach) {
      formData.append("media", element);
    }
    setShowLoader(true)
    updateShippingLot(formData, (status) => {
      if (status.status == "success") {
        setLotReceived(true)
        setAllReceived(status.isReceivedAllProducts)
        setShowLoader(false);
        addToast(status.message, {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
      } else {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    })(dispatch);
  }

  useEffect(() => {
    if (item.createdAt) {
      let date_1 = new Date();
      let date_2 = new Date(item.createdAt);

      const days = (date_1, date_2) => {
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.floor(difference / (1000 * 3600 * 24));
        return TotalDays;
      }
      if (days(date_1, date_2) < 2) {
        setEnableForm(true)
      }
      if (days(date_1, date_2) > 1) {
        setEnableForm(false)
      }
    }
  }, [item])

  return (
    <>
      {showLoader ? <Loader message={showMessage} /> : null}
      <li>
        <div className='received-listing-wrap'>
          <h4>Lot {item.lot_number}</h4>

          {!lotReceived && <div className="order-detail-top order-detail-wrapper order-new-wrp" style={{ padding: "0", margin: "0" }}>
            <div className="order-delivery-wrap">
              <div className="order-top order-top-wrapper file-img-inputs">
                <div
                  className="shipping-module-wrp"
                >
                  <div className="input-file-wrp-shipping">
                    <i class={`fas fa-plus ${fileAttach.length > 0 ? "green-plus-icon" : ""}`}></i>
                    <input
                      type="file"
                      className="file-in"
                      name="upload"
                      accept=".jpg,.png"
                      onChange={(e) => fileHandler(e)}
                      multiple={true}
                    />
                  </div>
                  {errorImages && <p>Please upload images</p>}
                </div>
              </div>
            </div>
          </div>}
          {!lotReceived && <button onClick={handleReceived} className='tracking-status-btn order-track-btn'>Received</button>}
          {lotReceived && <h5>Received</h5>}
        </div>
        <div className='received-images'>
          {
            item && item.media && item.media.length > 0 ? item.media.map((ele, index) => {
              return <a href={ele.mediaData} target='_blank' className='received-images-box' key={index}>
                <img src={ele.mediaData} alt='received-image' />
              </a>
            })
              :
              fileAttach && Object.keys(fileAttach).map((ele, index) => {
                return <div className='received-images-box' key={index}>
                  <img src={URL.createObjectURL(fileAttach[ele])} alt='received-image' />
                </div>
              })
          }
        </div>
      </li>
    </>
  )
}

export default OrderReceived