import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  setRedirectToNextPage,
  getHomePageDataWithLogin,
  getHomePageDataWithoutLogin,
} from "../../store/actions/common";
import { getHomePage } from "../../store/actions/dashboard";
import DiscoverBrands from "./DiscoverBrands";
import CategoryProduct from "./CategoryProduct";
import EditorsPick from "./EditorsPick";
import BazaarDiaries from "./BazaarDiaries";
import Banner from "./Banner";
import Counter from "./Counter";
import LazyLoad from "react-lazyload";
import StaticBlock from "./StaticBlock";
import ReviewPage from "./ReviewPage";
import { SpinnerLoader, timeSince } from "../../utils/constants";
import RoundCardCategory from "./RoundCardCategory";
import LocationPopup from "../../PopupFolder/locationPopup";
import { getWishList } from "../../store/actions/Wishlist";
import SellerRegistrationForm from "../Auth/sellerRegistrationForm";
import MultiUserSupport from "../MultiUserSupport/MultiUserSupport";
import ClientTestimonial from "../ClientTestimonial/ClientTestimonial";
import OurPartner from "../OurPartner/OurPartner";
import MainPage from "../MainPage/MainPage";
import { Authorization } from "../../utils/Authorization";
import CompleteProfileOutside from "../Complete Profile Outside/completeProfileOutside";
import UserSupport from "./userSupport";
import Testimonial from "./testimonial";
import Partner from "./partner";
import Loader from "../Loader";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardBlocks: [],
      allQuesId: [],
      allAnsId: [],
      allTextAns: [],
      spinnerLoader: false,
      locationPopup: false,
      getSellerRegistrationPopup: true,
      isProfileStatus: false,
      approveProfile: false,
      loginInfoState: {},
      handlePop: true,
      dashboardData: [],
      pageData: {},
      loaderMessage: "",
    };
  }
  componentDidMount() {
    this.setState({ spinnerLoader: true, loaderMessage: "Loading..." });
    //var profileCompleted = localStorage.getItem("isProfileCompleted");
    var profileCompleteStatus = localStorage.getItem("isProfileCompleted");
    var appProfile = localStorage.getItem("approved");
    var loginInfo = Authorization();
    this.setState({
      isProfileStatus: profileCompleteStatus,
      approveProfile: appProfile,
      loginInfoState: loginInfo,
    });
    window.scrollTo(0, 0);
    if (this.props.isLoggedIn) {
      this.props.getWishList();
    }

    this.props.getHomePageDataWithLogin({}, (status) => {
      this.setState({ dashboardData: status.data[0].records });
      this.setState({ spinnerLoader: false });
    });

    this.props.getHomePageDataWithoutLogin((status) => {
      this.setState({ pageData: status });
    });
  }
  enableScrollBar = () => {
    return document
      .getElementsByTagName("html")[0]
      .classList.remove("remove-scroll-bar");
  };

  contShopping = (zip) => {
    this.enableScrollBar();
    this.setState({ locationPopup: false }, () =>
      this.props.history.push(
        `/product-listing-page/${"Local"}?category=${"Local"}&zip=${zip}`
      )
    );
  };

  useCurrentLoc = () => {
    this.enableScrollBar();
    if (this.props.browser_location.coords) {
      this.setState({ locationPopup: false }, () =>
        this.props.history.push(
          `/product-listing-page/${"Local"}?category=${"Local"}&lat=${
            this.props.browser_location.coords.latitude
          }&long=${this.props.browser_location.coords.longitude}`
        )
      );
    } else {
      this.showAlert(() => {
        this.setState({ locationPopup: false });
      });
    }
  };

  desableScrollBar = () => {
    return document
      .getElementsByTagName("html")[0]
      .classList.add("remove-scroll-bar");
  };

  openLocationPopup = (state) => {
    this.setState(state, () => {
      this.desableScrollBar();
    });
  };

  handleFormHideShow = () => {
    this.setState({ getSellerRegistrationPopup: false });
  };

  static getDerivedStateFromProps = (props, state) => {
    const ImagePath =
      (props &&
        props.dashboardBlockData &&
        props.dashboardBlockData.ImagePath) ||
      "";
    const data =
      (props && props.dashboardBlockData && props.dashboardBlockData.data) ||
      [];

    const dashboardBlockData = data.map((item) => {
      if (item.banner_name) {
        return {
          editor_pic: `${item.banner_name}`,
          id: item._id,
          name: item.translation_data[0].name,
          type: item.type,
          block_name: item.block_name,
          order: item.order,
          product_count: item.product_count,
        };
      } else
        return {
          id: item._id,
          name: item.translation_data[0].name,
          type: item.type,
          block_name: item.block_name,
          order: item.order,
          product_count: item.product_count,
        };
    });

    dashboardBlockData.sort((a, b) => a.order - b.order);

    if (dashboardBlockData.length !== 0) {
      return {
        ...state,
        dashboardBlocks: dashboardBlockData,
      };
    } else return { ...state };
  };

  dynamicRender = (data) => {
    switch (data.categoryName) {
      case "Lumps":
        return <CategoryProduct data={data} />;
      case "Chips":
        return <CategoryProduct data={data} />;

      case "Fines":
        return <CategoryProduct data={data} />;
      default:
        return null;
    }
  };

  closePopupOnload = () => {
    this.setState({ handlePop: false });
    localStorage.setItem("pendinModal", false);
  };

  render() {
    const { spinnerLoader } = this.state;

    if (this.props.redirectToNextPage) {
      const { from } = this.props.location.state || { from: { pathname: "/" } };
      this.props.setRedirectToNextPage(false);
      return <Redirect to={from} />;
    }

    return (
      <>
        <div>
          {this.state.approveProfile == "pending" &&
          localStorage.getItem("pendinModal") == "true" ? (
            <>
              {this.state.handlePop ? (
                <div className="metal-buy-pop-up">
                  <div className="popup-wrapper">
                    <div className="popup-content">
                      <div className="popup-img">
                        <img
                          src="/assets/images/metals-success-logo.png"
                          alt="img"
                          title="logo"
                        />
                      </div>

                      <span className="approve-text  normal-text">
                        Thank you for signing up!
                        <span className="lower-text">
                          {" "}
                          We have received your information. Please allow us
                          upto 48 hrs to confirm it. During this time, your
                          account may have limited access.
                        </span>
                      </span>
                      <button
                        className="click-btn"
                        onClick={() => this.closePopupOnload()}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

          {spinnerLoader ? (
            <Loader Style={"bg-white"} message={this.state.loaderMessage} />
          ) : null}
          <div className={"dashboard"}>
            <div>
              <LazyLoad>
                <Banner />
              {!this.state.loginInfoState.status && <Counter/>}
              </LazyLoad>
              {this.state.dashboardData.length !== 0 &&
                this.state.dashboardData.map((data, index) => {
                  return (
                    <LazyLoad key={index}>{this.dynamicRender(data)}</LazyLoad>
                  );
                })}
              {/* <LazyLoad><UserSupport /></LazyLoad>
              <LazyLoad><Testimonial data={this.state.pageData}/></LazyLoad>
              <LazyLoad><Partner data={this.state.pageData}/></LazyLoad> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loggedUserInfo: state.userInfo.loggedUserInfo,
  redirectToNextPage: state.generalInfo.redirectToNextPage,
  dashboardBlockData: state.dashboardInfo.dashboardBlockData,
  testimonial: state.dashboardInfo.testimonial,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setRedirectToNextPage: (data) => dispatch(setRedirectToNextPage(data)),
    getHomePage: (payload, completion) =>
      dispatch(getHomePage(payload, completion)),
    getHomePageDataWithLogin: (payload, completion) =>
      dispatch(getHomePageDataWithLogin(payload, completion)),
    getHomePageDataWithoutLogin: (completion) =>
      dispatch(getHomePageDataWithoutLogin(completion)),
    getWishList: () => dispatch(getWishList()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
