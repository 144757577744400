import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
const Services = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
          live: true, // Enables the live mode to detect changes dynamically
        });
        wow.init();
      }, []);

    return (
       <div className="dashboard-services wow animate__animated animate__fadeInUp ">
        <div className="container">
        </div>
       </div>
    );
}

export default Services;