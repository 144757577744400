import React, { useEffect, useState } from 'react'
import RolePermissionPopup from '../../RolePermissionPopup'
import { axiosInstance } from '../../../../utils/Service';
import { useSelector } from 'react-redux';
import Loader from '../../../Loader';
import RolePermissionPopupEdit from '../../RolePermissionPopupEdit';
import DeletePopup from '../DeletePopup/DeletePopup';
import PageNotFound from '../../../../pageNotFound';
import { useToasts } from 'react-toast-notifications';
import ChangePasswordPopup from '../ChangePasswordPopup/ChangePasswordPopup';

const RolePermission = () => {
  const [popup, setPopup] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [changePassword, setChangePassword] = useState(false)
  const [roleId, setRoleId] = useState(null)
  const { addToast } = useToasts();

  const [deletePopupHandle, setDeletePopupHandle] = useState(false)

  const [page, setPage] = useState(1)

  const userData = useSelector(store => store.userProfile.userProfileBanner.userdata);

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
console.log("roleiddd",roleId)
  useEffect(() => {
    handleListing()
  }, [userData, page])

  const handleListing = () => {
    if (userData) {
      let token = localStorage.getItem("token");
      setLoading(true)
      axiosInstance.post(`/role_permission/sub_user_list`, {
        "page": page
      }, {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        },
      }).then((res) => {
        setData(res.data)
        setLoading(false)
      }).catch((e) => {
        setLoading(false)
      });
    }
  }

  const handleDelete = (item) => {
    setPopupData(item)
    setDeletePopupHandle(true)
  }

  return (
    localStorage.getItem("childType") !== "all" ? <div className='signup-form signup-frm-wrp signup-page-wrapp signup-new-wrap basic-info new-profile-address role-permission'>
      {loading ? <Loader message={"Please wait..."} /> :
        <>
          {
            data ? <div className='inner-sign signup-without-img'>
                  <div className='add-roles-permissions-wrap'>
                    <button className='add-roles-permissions' onClick={() => setPopup(true)}>+ Add</button>
                  </div>
              <div className='sign-form'>
                <div className='new-cover-wrap'>
                  <div className='detail-from signup-detail-form sign-up-form-wrap new-edit-profile-body'>
                    {
                      (data && data.data && data && data.data.length>0) ? data.data.map((item) => {
                        return <div className='names-wrap' key={item._id}>
                          <div className='roles-details'>
                            <div className='form-block-wrap sub-form-wrp'>
                              <div className='enter-mobile-label'>
                                Name:
                              </div>
                              <h5>{item.firstName} {item.lastName}</h5>
                            </div>
                            <div className='form-block-wrap sub-form-wrp'>
                              <div className='enter-mobile-label'>
                                Email:
                              </div>
                              <h5>{item.email}</h5>
                            </div>

                            <div className='form-block-wrap sub-form-wrp'>
                              <div className='enter-mobile-label'>
                                Designation:
                              </div>
                              <h5>{item.designation}</h5>
                            </div>

                            <div className='form-block-wrap sub-form-wrp'>
                              <div className='enter-mobile-label'>
                                Mobile:
                              </div>
                              <h5>{item.mobile}</h5>
                            </div>
                            <div className='form-block-wrap sub-form-wrp'>
                              <div className='enter-mobile-label'>
                                Permission:
                              </div>
                              <h5>{item.premission === 0 ? "Order Tracking" : "All"}</h5>
                            </div>

                          </div>

                          <div className='role-buttons'>
                            <button className='edit-role' onClick={() => { setPopupEdit(true); setPopupData(item) }}>
                              <i className='fas fa-edit'></i>
                            </button>
                            <button className='edit-role' onClick={() => handleDelete(item)}>
                              <i className='fas fa-trash'></i>
                            </button>
                            <button className='change-role-pass' onClick={() => { setChangePassword(true); setRoleId(item._id) }}>Change password</button>
                          </div>
                        </div>
                      }) : <p style={{"marginBottom": "13px","fontSize": "18px"}}>No data found</p>
                    }
                  </div>
                  {data.data && data.totalLength > data.data.length && <div className="load-more-btn">
                    <button className="shop-now"
                    // onClick={()=>setPage(prev=>prev+1)}
                    >
                      Load more
                    </button>
                  </div>}
                </div>
              </div>
            </div> : null
          }
        </>
      }

      {popup && <RolePermissionPopup addToast={addToast} setPopup={setPopup} userData={userData} setLoading={setLoading} handleListing={handleListing} />}
      {popupEdit && <RolePermissionPopupEdit setPopup={setPopupEdit} popupData={popupData} userData={userData} setLoading={setLoading} handleListing={handleListing} />}
      {deletePopupHandle && <DeletePopup setLoading={setLoading} setPopup={setDeletePopupHandle} popupData={popupData} handleListing={handleListing} />}
      {changePassword && <ChangePasswordPopup setChangePassword={setChangePassword} handleListing={handleListing} setLoading={setLoading} addToast={addToast} roleId={roleId} />}

    </div> : <PageNotFound />
  )
}

export default RolePermission