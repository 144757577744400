import React, { useState, useEffect } from "react";
import OfferProduct from "./OfferProduct";
import { SpinnerLoader } from "../../../../utils/constants";
// import "../../Profile.css";
import { useToasts } from "react-toast-notifications";
import { myofferRoutes } from "../profileRoutes";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  Link,
  useParams,
} from "react-router-dom";
import OfferDetails from "./OfferDetails";
import { get } from "lodash";
import PageNotFound from "../../../../pageNotFound";
import Loader from "../../../Loader";

const Myoffer = (props) => {
  const [innerLoader, setInnerLoader] = useState(false);
  const location = useLocation();
  const { addToast } = useToasts();
  const param = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  useEffect(() => {
    setShowLoader(true);
    setShowMessage("Please wait...");
    window.scrollTo(0, 0);
  }, [location.state]);

  // useEffect(() => {
  //   let paramArr = location.pathname.split("/");
  //   if (paramArr.length > 4) {
  //     props.history.push("/not-found");
  //   }
  // }, []);

  const setLoaderState = () => {
    setShowLoader(false);
  };

  const tabRender = () => (
    <Switch>
      <Route
        path={`/profile/my-offer/offer-detail/:prodId/:prodType`}
        exact
        render={(prev) => (
          <OfferDetails {...prev} setShowLoader={setShowLoader} setLoaderState={setLoaderState} />
        )}
      />
      <Route
        path="/profile/my-offer/pending"
        render={(prev) => (
          <OfferProduct
            {...prev}
            name="Pending"
            setLoaderState={setLoaderState}
          />
        )}
      />
      <Route
        path="/profile/my-offer/expired"
        render={(prev) => (
          <OfferProduct
            {...prev}
            name="Expired"
            setLoaderState={setLoaderState}
          />
        )}
      />
      <Route
        path="/profile/my-offer/auto-declined"
        render={(prev) => (
          <OfferProduct
            {...prev}
            name="AutoDeclined"
            setLoaderState={setLoaderState}
          />
        )}
      />
      <Redirect from="/profile/my-offer" to="/profile/my-offer/pending" />

      <Route component={PageNotFound} path="*" />
    </Switch>
  );

  return (
    <div style={{ position: "relative" }}>
      {showLoader ? <Loader message={showMessage} /> : null}
      <div
        className={"blogtags-sec" + (innerLoader ? " spinner-time-div" : "")}
      >
        {(location.pathname === "/profile/my-offer/pending" || location.pathname === "/profile/my-offer/auto-declined" || location.pathname === "/profile/my-offer/expired") && (
          <div className="tags-wrap">
            <div className="container">
              <div className="tags 45">
                {myofferRoutes.map((data, indx) => (
                  <Link
                    key={indx}
                    to={{
                      pathname: data.prefix + data.pathname,
                      state: data.state,
                    }}
                    className={
                      data.pathname ===
                        location.pathname.replace("/profile/my-offer/", "/")
                        ? "active-tab"
                        : ""
                    }
                  >
                    {data.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="container">{tabRender()}</div>
      </div>
    </div>
  );
};

export default Myoffer;
