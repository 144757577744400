import React, { useState, useEffect } from "react";
import "../Profile.css";
import { connect, useDispatch, useSelector } from "react-redux";
import Toast from "light-toast";
import {
  getSellerProfile,
  getSellerAddedProduct,
} from "../../../store/actions/profile";
import {
  getAllFilterData,
} from "../../../store/actions/productListing";

import { setLoginPopupStatus, getTotalUnreadMsgAndWishlistItem } from "../../../store/actions/common";
import { isEmpty, get } from "lodash";
import { SpinnerLoader } from "../../../utils/constants";
import SellerDetails from './SellerDetails';
import SellerProduct from './SellerProduct';
import Filter from "./Filter/Filter"
import { parse, stringify } from "query-string";
import NewInHeader from "../../ProductListing/NewInHeader";
import Loader from "../../Loader";


const SellerProfile = (props) => {
  let dispatch = useDispatch();
  let location = props.location;
  let query = parse(location.search);


  const [spinnerLoader, setSpinnerLoader] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [closeModal, setCloseModal] = useState(true);
  const [penDiv, setOpenDiv] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches)
  const [filterOnMob, setFilterOnMob] = useState(query);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");


  const [state, setState] = React.useState({
    showLoader: false,
    spinnerLoader: false,
    NoProduct: false
  })

  let sellerProd = useSelector(state => state.userProfile.sellerAddedProduct)



  useEffect(() => {
    props.getSellerProfile(props.match.params.id);

  }, [])


  useEffect(() => {
    setShowLoader(true);
    setShowMessage("Please wait...")
    let query = parse(location.search);
    let filter = {};
    Object.keys(query).forEach(item => {
      if (item === "price") {
        filter[item] = query[item].split('-');
      } else {
        filter[item] = query[item].split(',');
      }
    })
    props.getSellerAddedProduct({ sellerId: props.match.params.id, ...filter }, (status) => {
      if (status) setButtonLoader(false)
      setTimeout(() => setShowLoader(false), 500);
    });
  }, [location])

  useEffect(() => {
    if (props.sellerProduct.filterData) {
      getAllFilterData({ FilterData: props.sellerProduct.filterData, attributeType: "sellerPrdoucts" }, { category: "demo" }, callback => {

        setFilterData(callback)
        setTimeout(() => setSpinnerLoader(false), 500);
      })(dispatch)
    }
  }, [props.sellerProduct])



  const filterInMob = (e, val, name) => {
    let newState = { ...filterOnMob };
    if (e.target.checked) {
      if (newState[name]) {
        if (name === "delivery") {
          newState[name] = val;
        } else {
          newState[name] = newState[name] + "," + val;
        }
      } else {
        newState[name] = val;
      }
    } else {
      let newVal = newState[name].split(",");
      let newnewState = newVal.filter((item) => item !== val);
      newState[name] = newnewState.toString();
    }
    setFilterOnMob(newState)
  }



  const handlerCategoryFilter = (val, name, cSlug) => {
    if (name === "category") {
      setOnURL(location.pathname, { [name]: val });
    } else {
      setOnURL(location.pathname, { category: cSlug, [name]: val });
    }
  };


  const priceFilter = (e, val, name) => {
    let query = parse(location.search);
    let newVal = [val.min + "-" + val.max];
    query[name] = newVal;
    if (matches) {
      setOnURL(location.pathname, query);
    } else {
      let newState = { ...filterOnMob };
      let newVal = [val.min + "-" + val.max];
      newState[name] = newVal;
      setFilterOnMob(newState)
    }
  };



  const setFilterDataLocal = (name, val) => {
    let filteredArr = JSON.parse(localStorage.getItem("filteredArr"));
    if (filteredArr) {
      filteredArr[name] = val
    } else {
      filteredArr = { [name]: val };
    }
    localStorage.setItem("filteredArr", JSON.stringify(filteredArr));
  }

  const mainFilterHandler = (e, val, name, labelChecked) => {
    let query = parse(location.search);
    if (matches) {
      if (e.target.checked || labelChecked == true) {
        if (query[name]) {
          if (name === "delivery") {
            query[name] = val;
          } else {
            query[name] = query[name] + "," + val;
          }
        } else {
          query[name] = val;
        }
      }
      else {
        let newVal = query[name].split(",");
        let newquery = newVal.filter((item) => item !== val);
        query[name] = newquery.toString();
      }
      setOnURL(location.pathname, query);
    } else {
      filterInMob(e, val, name)
    }
  }




  const attrFilterHandler = (e, val, name) => {

    let query = parse(location.search);
    if (e.target.checked) {
      let product_data = { [name]: [val] }


      if (query.product_data) {
        let queryVal = JSON.parse(query.product_data);
        if (Object.keys(queryVal).filter(item => item === name).length > 0) {
          queryVal[name].push(val);
          query.product_data = JSON.stringify(queryVal);
        } else {
          // product_data[name].push(val)
          const returnedTarget = Object.assign(queryVal, product_data);
          query.product_data = JSON.stringify(returnedTarget);
        }
        setOnURL(location.pathname, query);
      } else {
        query.product_data = JSON.stringify(product_data);
        setOnURL(location.pathname, query);
      }
    } else {
      let queryVal = JSON.parse(query.product_data);
      let removeAttr = queryVal[name].filter(item => item !== val);
      queryVal[name] = removeAttr;
      query.product_data = JSON.stringify(queryVal);
      setOnURL(location.pathname, query);
    }
  }


  const setOnURL = (path, query) => {
    let url = stringify(query).replace(/%23/g, "#");

    props.history.push({
      pathname: path,
      search: url,
    });
  };


  const loadMoreProd = ({ page }) => {
    let query = parse(location.search);
    let filter = {};
    Object.keys(query).forEach(item => {
      if (item === "price") {
        filter[item] = query[item].split('-');
      } else {
        filter[item] = query[item].split(',');
      }
    })

    props.getSellerAddedProduct({ sellerId: props.match.params.id, ...filter, page: page + 1 }, (status) => {
      if (status) setButtonLoader(false)
    });
    setTimeout(() => setSpinnerLoader(false), 1500);
  }

  const closeSmallScreenFilter = () => {
    setCloseModal(true);
    setOpenDiv(false);
  }

  const smallScreenFilter = () => {
    setCloseModal(false);
    setOpenDiv(true);
  }






  const applyBtnHandler = () => {
    let query = parse(location.search);
    let queryANdOwnData = {
      ...query, ...filterOnMob
    }
    setOnURL(location.pathname, queryANdOwnData);
    setCloseModal(true);
    setOpenDiv(false);
  }



  return (
    <>
      {matches && (
        <div className="view-seller-wrap seller-profile-page">
          {showLoader ? <Loader message={showMessage} /> : null}
          <div className={"container" + (spinnerLoader ? " spinner-time-div" : "")}>

            {!isEmpty(props.sellerDetails) && props.sellerDetails.data &&
              <SellerDetails allProd={props.sellerDetails.allProducts} {...props.sellerDetails.data[0]} />
            }

            <NewInHeader
              prodLength={props.prodLength}
              noProduct={state.NoProduct}
              type={"sellerProfile"}
              filterData={filterData}
              filter_data={props.sellerProduct.product}

            />
            <div className={Object.keys(filterData).length > 0 ? "product-filter-wrap mainlisting-wrap" : "product-filter-wrap"}>
              {
                Object.keys(filterData).length > 0 &&
                <Filter
                  filterData={filterData}
                  sellerId={props.match.params.id}
                  handlerCategoryFilter={handlerCategoryFilter}
                  mainFilterHandler={mainFilterHandler}
                  location={location}
                  priceFilter={priceFilter}
                  attrFilterHandler={attrFilterHandler}
                  applyBtnFilter={applyBtnHandler}
                  filterOnMob={filterOnMob}
                />
              }
              <SellerProduct
                data={props.sellerProduct}
                loadMore={loadMoreProd}
                buttonLoader={buttonLoader}
                getWishCount={props.getTotalUnreadMsgAndWishlistItem}
              />
            </div>

            {/* {
          shareAttr() &&
          <Category
            filter_data={shareAttr()}
            selectedAttr={props.filter_Attr_array}
            loader={state.showLoader}
            productCount={props.prodLength}
          />
        } */}



          </div>
        </div>

      )}

      {!matches && (
        <div className="view-seller-wrap seller-profile-page">
          {showLoader ? <Loader message={showMessage} /> : null}
          <div className={"container" + (spinnerLoader ? " spinner-time-div" : "")}>

            {!isEmpty(props.sellerDetails) && props.sellerDetails.data &&
              <SellerDetails allProd={props.sellerDetails.allProducts} {...props.sellerDetails.data[0]} />
            }

            <NewInHeader
              prodLength={props.prodLength}
              noProduct={state.NoProduct}
              type={"sellerProfile"}
              smallScreenFilter={smallScreenFilter}
              filterData={filterData}
              filter_data={props.sellerProduct.product}
            />
            <div className={Object.keys(filterData).length > 0 ? "product-filter-wrap mainlisting-wrap" : "product-filter-wrap"}>
              {
                Object.keys(filterData).length > 0 &&
                <Filter
                  filterData={filterData}
                  sellerId={props.match.params.id}
                  handlerCategoryFilter={handlerCategoryFilter}
                  mainFilterHandler={mainFilterHandler}
                  location={location}
                  priceFilter={priceFilter}
                  attrFilterHandler={attrFilterHandler}
                  closeSmallScreenFilter={closeSmallScreenFilter}
                  closeModal={closeModal}
                  getdivstatus={penDiv}
                  applyBtnFilter={applyBtnHandler}
                  filterOnMob={filterOnMob}
                />
              }
              <SellerProduct
                data={props.sellerProduct}
                loadMore={loadMoreProd}
                buttonLoader={buttonLoader}
                getWishCount={props.getTotalUnreadMsgAndWishlistItem}
              />
            </div>

            {/* {
          shareAttr() &&
          <Category
            filter_data={shareAttr()}
            selectedAttr={props.filter_Attr_array}
            loader={state.showLoader}
            productCount={props.prodLength}
          />
        } */}



          </div>
        </div>

      )}
    </>





  )
}

const mapStateToProps = (state, ownProps) => {

  return {
    sellerDetails: state.userProfile.sellerDetails,
    sellerId:
      state.userProfile.sellerDetails &&
      state.userProfile.sellerDetails.userdata &&
      state.userProfile.sellerDetails.userdata._id,
    userId: state.userInfo.loggedUserInfo.userId,
    sellerProduct: state.userProfile.sellerAddedProduct,
    prodLength: state.userProfile.sellerAddedProduct.productCount,
    filter_data: state.productListingInfo.attributeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSellerProfile: (sellerId) => dispatch(getSellerProfile(sellerId)),
    getSellerAddedProduct: (payload, status) =>
      dispatch(getSellerAddedProduct(payload, status)),
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
    getTotalUnreadMsgAndWishlistItem: (payload) => dispatch(getTotalUnreadMsgAndWishlistItem(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SellerProfile);
