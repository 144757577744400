import React, { Component } from 'react';
import './style.css';
import {
    getDetailedBlog, postComment, getComment, recentBlog, likeBlog, getBlogArchives
    , createNewComment
} from '../../store/actions/blogAction';
import { connect } from "react-redux";
import Comment from './Comment';
import { isEmpty, get } from 'lodash';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import SimilarBlog from './SimilarBlog';
import { Link } from 'react-router-dom';
import { Authorization } from "../../utils/Authorization";
import { setLoginPopupStatus } from "../../store/actions/common";
import { useToasts } from "react-toast-notifications";
import moment from 'moment';
import SomethingWrong from '../../PopupFolder/someThingWrong';
import { SpinnerLoader, isMobile } from "../../utils/constants";
import Toast from "light-toast";
import Pagination from './pagination';



class ReadBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinnerLoader: false,
            desc: '',
            error: false,
            getLoginToken: ''
        };
    }


    componentDidMount = () => {
        let element = document.getElementsByClassName('blog-article-one')[0];
        const token = localStorage.getItem("token");
        this.setState({ getLoginToken: token });
        element.scrollIntoView();
        this.setState({ spinnerLoader: true });
        this.props.getDetailedBlog({ blogId: this.props.match.params.blogId })
        this.props.getBlogArchives();
        setTimeout(() => this.setState({ spinnerLoader: false }), 1500);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.blogId !== this.props.match.params.blogId) {
            let element = document.getElementsByClassName('blog-article-one')[0];
            element.scrollIntoView();
            this.setState({ spinnerLoader: true });
            this.props.getDetailedBlog({ blogId: nextProps.match.params.blogId })
            setTimeout(() => this.setState({ spinnerLoader: false }), 1500);
        }
    }

    likeThisBlog = () => {
        let checkLoginStatus = Authorization();
        if (checkLoginStatus.status) {
            this.props.likeBlog({ blogId: this.props.match.params.blogId }, (status) => {
                if (status) {
                    this.props.getDetailedBlog({ blogId: this.props.match.params.blogId })
                    this.props.addToast(status, { appearance: "success" });
                }
            })
        }
        else {
            this.props.addToast("Login Required", { appearance: "warning" });
            this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
        }
    }

    clickhandler = (e) => {
        e.preventDefault()
        let checkLoginStatus = Authorization();
        if (checkLoginStatus.status) {
            let blogcommentid = this.props.detailedBlogData.data[0]._id;
            e.preventDefault();
            this.setState({
                desc: ''
            });
            const post = {
                blogId: blogcommentid,
                comment: this.state.desc,
            }
            if (this.state.desc === "") {
                this.setState({ error: true })
                this.props.addToast("Please fill data", { appearance: "error" });

            } else {
                this.setState({ error: false })
                this.props.createNewComment(post, (status) => {
                    this.props.addToast("Data Submit successfully", { appearance: "success" });
                    this.props.getDetailedBlog({ blogId: this.props.match.params.blogId })
                })
            }
        } else {
            this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
        }

    }
    render() {
        const { spinnerLoader } = this.state;
        const { detailedBlogData, relatedBlog, archives } = this.props;
        const blogData = get(detailedBlogData, ['data', [0]], false)
        return (
            <div >
                {spinnerLoader ? <SpinnerLoader /> : null}
                <div className={"blog-article-one blog-articles" +
                    (spinnerLoader ? " spinner-time-div" : "")}>
                    {
                        !isEmpty(detailedBlogData) ?
                            detailedBlogData.data.length > 0 ?
                                < div className="article-wrap" >
                                    <div className="article-detail">
                                        <div className="container">
                                            <div className="article-desc">
                                                <div className="article-head blog-expandimage">
                                                    <figure>
                                                        <img src={
                                                            isMobile() ?
                                                                blogData.mobile_blog_image
                                                                : blogData.blog_image
                                                        }
                                                            alt={blogData.slug} />
                                                    </figure>
                                                </div>
                                                <div className="article-head-wrap">
                                                    <div className="blog-date blog-main-date"><i className="fas fa-stopwatch"></i>
                                                        {
                                                            moment(blogData.created_At).calendar({
                                                                sameDay: 'DD/MM/YYYY h:mm a',
                                                                nextDay: 'DD/MM/YYYY h:mm a',
                                                                nextWeek: 'DD/MM/YYYY h:mm a',
                                                                lastDay: 'DD/MM/YYYY h:mm a',
                                                                lastWeek: 'DD/MM/YYYY h:mm a',
                                                                sameElse: 'DD/MM/YYYY h:mm a'
                                                            })
                                                        }
                                                    </div>
                                                    <h4>{blogData.content_heading}</h4>
                                                    <div
                                                        className="article-wrap"
                                                        dangerouslySetInnerHTML={{ __html: blogData.short_desc }}
                                                    />
                                                </div>
                                                <div className="blog-info-wrp" >
                                                    <div className="social-info">
                                                        <div className="share-block share-section">
                                                            <h4>Share this:</h4>
                                                            <div className="social-links">
                                                                <div className="share-icon-group">
                                                                    <div className="social-link">
                                                                        <TwitterShareButton
                                                                            url={window.location.href}
                                                                            title={blogData.content_heading}
                                                                            hashtags={["MetalsBuy"]}
                                                                        >
                                                                            <i className="fab fa-twitter-square"></i>
                                                                            Twitter
                                                                        </TwitterShareButton>
                                                                    </div>
                                                                    <div className="social-link">
                                                                        <FacebookShareButton
                                                                            url={window.location.href}
                                                                            quote={[blogData.content_heading]}
                                                                            hashtag={["#MetalsBuy"]}
                                                                        >
                                                                            <i className="fab fa-facebook-square"></i>
                                                                            Facebook
                                                                        </FacebookShareButton>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <h4>Like this:</h4>
                                                            <div className="social-links">
                                                                <button className="social-link like-btn" onClick={this.likeThisBlog}>
                                                                    <i className={isEmpty(blogData.likeData) ? "fas fa-star" : "fas fa-star fill"}></i>
                                                                    {isEmpty(blogData.likeData) ? 'Like' : 'Liked'}
                                                                </button>
                                                                {
                                                                    !blogData.like &&
                                                                    <div className="like-text"> Be the first to like this.</div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            blogData.tag && blogData.tag.length > 0 &&
                                                            <div className="purple-tags">
                                                                <ul className="tags-listing">
                                                                    <li className="active-tag">
                                                                        <div className="tag-box">tags</div>
                                                                    </li>
                                                                    {
                                                                        blogData.tag.map((data, i) =>
                                                                            <li>
                                                                                <div className="tag-box">#{data}</div>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="blog-comment">
                                                        <form>
                                                            <div className="form-group">
                                                                <label for="message">Message</label>

                                                                <textarea value={this.state.desc} onChange={(e) => { this.setState({ desc: e.target.value }) }} className="form-control" id="message" name="" ></textarea>
                                                            </div>
                                                            <div className="form-group">
                                                                <input onClick={this.clickhandler} className="btn shop-now" type="submit" value="Post Comment" />
                                                                {
                                                                    this.state.error ?
                                                                        <span></span> : null
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="comment-vlogs">
                                                    <h1> Comments</h1>

                                                    <Pagination
                                                        paginationData={this.props.detailedBlogData}
                                                    />

                                                </div>
                                                <div className="news-blocks">
                                                    <div className="news-block">
                                                        <img src="\assets\images\news-bg-2.png" alt="img" />
                                                        <div className="news-content">
                                                            <div className="news-text">
                                                                <h5>company news</h5>
                                                                <h3>Mercari joins The Coalition to Protect America’s Small Sellers (PASS)</h3>
                                                            </div>
                                                            <Link className="news-link">
                                                                <i className="fas fa-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="news-block">
                                                        <img src="\assets\images\news-bg.png" alt="img" />
                                                        <div className="news-content">
                                                            <div className="news-text">
                                                                <h5>company news</h5>
                                                                <h3>Our first ever Big Game ad</h3>
                                                            </div>
                                                            <Link className="news-link">
                                                                <i className="fas fa-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <SimilarBlog data={relatedBlog} />
                                            </div>
                                            {/* <Comment
                                        postComment={detailedBlogData.commentData}
                                        blog_id={detailedBlogData._id}
                                        blogComment={detailedBlogData.commentData}
                                    /> */}
                                        </div>
                                    </div>
                                    {
                                        archives.length > 0 &&
                                        <div className="archives">
                                            <div className="container">
                                                <h5>archives</h5>
                                                <ul className="archives-wrap">
                                                    {
                                                        archives.map((data, i) =>
                                                            <li>
                                                                <Link to={{ pathname: `/blog/${data.value}` }}>
                                                                    <button className="archive-btn">{data.name}</button>
                                                                </Link>
                                                            </li>)
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                                :
                                <SomethingWrong />
                            : null
                    }
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        detailedBlogData: state.blogData.detailedBlogData,
        relatedBlog: state.blogData.relatedBlog,
        archives: state.blogData.archives
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDetailedBlog: (payload) => dispatch(getDetailedBlog(payload)),
        likeBlog: (payload, status) => likeBlog(payload, status),
        createNewComment: (payload, status) => dispatch(createNewComment(payload, status)),
        setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
        getBlogArchives: () => dispatch(getBlogArchives()),

        // postComment: (payload, data) => dispatch(postComment(payload, data)),
        // getComment: (payload, data) => dispatch(getComment(payload, data)),
        recentBlog: () => dispatch(recentBlog()),
    }
}
// export default connect(mapStateToProps, mapDispatchToProps)(ReadBlog);

export default withToast(
    connect(mapStateToProps, mapDispatchToProps)(ReadBlog)
);

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts();
        return <Component {...props} {...toastFuncs} />;
    };
}
