import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../Profile.css";
import { isEmpty } from "lodash";
import { sellerDashRoutes } from "../MainProfile/profileRoutes";
const DashboardTopSection = (props) => {
  const location = useLocation();

  const { userdata } = props.profileInfo;
  return (
    <div className="blogtags-sec">
      {!isEmpty(props.profileInfo) ? (
        <div className="profile-data profile-data-top-wrapper">
          <div className="container">
            {/* <div className="profile-account profile-account-wrapper">
              {userdata.length !== 0 && userdata.user_image ? (
                <figure className="profile-img">
                  <img src={`${userdata.user_image}`} alt="profile-img" />
                </figure>
              ) : (
                <figure className="profile-img">
                  <img src="/assets/images/profile-img.png" alt="profile-img" />
                </figure>
              )}
            </div> */}
          </div>
        </div>
      ) : null}

      <div className="tags-wrap tags-main-wrapper">
        <div className="container">
          {/* <div className="tags tags-wrapper-content">
            {sellerDashRoutes.map((data, indx) => (
              <Link
                key={indx}
                to={{
                  pathname: data.prefix + data.pathname,
                  state: data.state,
                }}
                className={
                  data.pathname ===
                    location.pathname.replace("/profile/seller/", "/")
                    ? "active-tab"
                    : ""
                }
              >
                {data.name}
              </Link>
            ))}

            <span
              className={props.activePage === "SalesReport" ? "active-tab" : ""}
              onClick={() => props.changePage("SalesReport")}
            >
              Sales Report
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
// }
export default DashboardTopSection;
