import React, { useState } from "react";
import "../ProductDetail/ProductDetails.css";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from 'react-redux';
import { addAttributeRequest } from "../../store/actions/sellItem";




const AttributePopup = (props) => {
  const { addToast } = useToasts();
  let dispatch = useDispatch()


  let attributes = useSelector(store => store.sellItemInfo.attributeList);

  const [state, setState] = useState({
    attributeName: "",
    attrvalue: "",
    attrname: ""
  })

  const attrChangeHandler = (e) => {
    let name = e.target.name;
    setState({ ...state, [name]: e.target.value })
  }


  const handlerSubmit = (e) => {
    e.preventDefault();

    let payload;
    if (state.attributeName === "new") {
      payload = {
        category: props.state.categories.value._id,
        subcategory: props.state.subcategory.value._id,
        attr_name: state.attrname,
        dropdownLable: state.attrvalue,
        dropdownValue: state.attrvalue
      }
    } else {
      payload = {
        attr_id: state.attributeName,
        dropdownLable: state.attrvalue,
        dropdownValue: state.attrvalue
      }
    }


    if (state.attributeName === "") {
      addToast("Please select attribute.", {
        appearance: "error",
        autoDismissTimeout: 2000,
      })
      return false;
    }


    if (state.attrvalue === "") {
      addToast("Attribute name and attribute value is required.", {
        appearance: "error",
        autoDismissTimeout: 2000,
      })
      return false;
    }

    if (state.attributeName === "new" && state.attrname === "") {
      addToast("Attribute name is required.", {
        appearance: "error",
        autoDismissTimeout: 2000,
      })
      return false;
    }



    // return
    addAttributeRequest(payload, callback => {
      addToast("We have received your attribute request. Please wait for approval.", {
        appearance: "success",
        autoDismissTimeout: 1000,
      })
      setTimeout(() => props.setShowPopup(false), 1000)
    })(dispatch)

  }



  return (
    <div className="make-offer-popup">
      <div className="offer-content">
        <button
          className="modal-closeBtn"
          onClick={() => props.setShowPopup(false)}
          type="button"
        >
          <i className="fal fa-times-circle"></i>
        </button>

        {
          <div className="register-popup add-brand add-attribute seller-add-brand">
            <div className="offer-title">Add new attribute</div>
            <form>
              <div className="label-txt">Select Attribute</div>
              <select onChange={attrChangeHandler} name="attributeName">
                <option value="">Please select</option>
                <option value="new">Add new attribute</option>
                {
                  attributes.map((item, key) =>
                    item.catlog_input_type !== "text" &&
                    <option key={key} value={item._id}>{item.attribute_label[0]}</option>
                  )
                }
              </select>
              {
                state.attributeName === "new" &&
                <>
                  <div className="label-txt">Attribute name</div>
                  <input
                    type="text"
                    placeholder="Enter Attribute Name"
                    maxLength={30}
                    required
                    name="attrname"
                    onChange={attrChangeHandler}
                  />
                </>
              }


              <div className="label-txt">Attribute Value</div>
              <input
                type="text"
                placeholder="Enter Attribute Value"
                maxLength={30}
                required
                name="attrvalue"
                onChange={attrChangeHandler}
              />
              <div className="add-links">
                <button onClick={(e) => { handlerSubmit(e) }} className="btn-purple">
                  submit
                </button>
              </div>
            </form>
          </div>
        }
      </div>
    </div>
  );
};



export default AttributePopup;
