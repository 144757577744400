import React, { useEffect } from 'react';
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";


const OTPForm = (props) => {
    const timer = () => props.setState("time", props.time - 1);

    useEffect(() => {
        if (props.time <= 0) { return; }
        const id = setInterval(timer, 1000);
        return () => clearInterval(id);
    }, [props.time])


    return (
        <div className="detail-from otp-screen main-otp-screen signup-otp-page">
            <div className='registration-form-head otp-registration-head'>
                <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link>
            </div>
            <h4 className="verify-mobile"> Verify OTP</h4>
            {/* <div className="otp-timer otp-timer-wrap">
                <span>+91{props.mobileNumber} <span className="form-title go-back">
                    <span className='change-number-text' onClick={() => props.backHandler()}>change</span></span></span>
            </div> */}
            {/* <h4 className="enter-otp">Enter <span className="otp-text">OTP</span>  </h4> */}
            <div className='mobile-otp-base-wrapper'>
                <div className='otp-mobile-wrap'>
                    <div className="form-input form-input-otp otp-page-signin">
                        <div className='otp-form-wrap'>
                            <TextField
                                variant="outlined"
                                required
                                InputProps={{
                                    inputProps: {
                                        maxLength: 6,
                                        minLength: 6,
                                    },
                                }}
                                type="text"
                                name="OTP"
                                label="Enter OTP"
                                value={props.OTP}
                                onChange={(e) => {
                                    let checkDigit = new RegExp(/^\d*$/);
                                    if (checkDigit.test(e.target.value)) {
                                        props.setState("OTP", e.target.value);
                                    }
                                }}
                            />
                        </div>
                        {/* <button className="otp-verify"
                            disabled={props.OTP.length < 6 ? true : false}
                            onClick={props.submitOTP}
                        >
                            Verify
                        </button> */}
                    </div>
                    <div className="otp-timer">
                        <span>{`00:${props.time}`}</span>
                    </div>
                </div>
                <div className="otp-btns">
                    <div className="btns-wrap submit-otp-wrapper">
                        <button className="otp-btn resend-otp-wrap"
                            disabled={props.time === 0 ? false : true}
                            onClick={props.resendMobOTP}
                        >
                            Resend OTP
                        </button>
                    </div>
                </div>
                <div className='next-btn-wrap'>
                    <button className="otp-verify"
                        disabled={props.OTP.length < 6 ? true : false}
                        onClick={props.submitOTP}
                    >
                        Next
                    </button>
                </div>
            </div>
            {/* <div className='mobile-otp-base-wrapper'>
                <div className='otp-mobile-wrap'>
                    <div className="form-input form-input-otp otp-page-signin">
                        <div className='otp-form-wrap'>

                            <TextField
                                variant="outlined"
                                required
                                InputProps={{
                                    inputProps: {
                                        maxLength: 6,
                                        minLength: 6,
                                    },
                                }}
                                type="text"
                                name="OTP"
                                label="Enter email OTP"
                                value={props.EmailOTP}
                                onChange={(e) => {
                                    let checkDigit = new RegExp(/^\d*$/);
                                    if (checkDigit.test(e.target.value)) {
                                        props.setState("emailOTP", e.target.value);
                                    }
                                }}
                            />
                        </div>
                        <button className="otp-verify"
                            disabled={props.EmailOTP.length < 6 ? true : false}
                            onClick={props.submitOTPEMAIL}
                        >
                            Verify
                        </button>
                    </div>
                    <div className="otp-timer">
                        <span>{`00:${props.time}`}</span>
                    </div>
                </div>


                <div className="otp-btns">
                    <div className="btns-wrap submit-otp-wrapper">
                        <button className="otp-btn resend-otp-wrap"
                            disabled={props.time === 0 ? false : true}
                            onClick={props.resendMobOTP}
                        >
                            Resend email OTP
                        </button>
                    </div>
                </div>
            </div> */}
        </div >
    )
}

export default OTPForm;