import React from "react";

const ProductSelectFilter = (props) => {
  return (
    <div className="delivery-details select-product-filter filter-border">
      <h4>Product</h4>
      <div className="input-wrapper">
        <button className="search-btn">
          <i className="search-btn-icon"></i>
        </button>
        <input
          type="text"
          placeholder="Search for product"
          className="form-control"
          onChange={(e) => props.handleProductSearch(e)}
        />
      </div>
      {Object.keys(props.filterData).length !== 0 ? (
        <>
          {props.productDataList.map((item) => {
            return (
              <label class="check-wrp">
                {item}
                <input
                  type="checkbox"
                  value={item}
                  onChange={(e) => props.handleProducts(e)}
                />
                <span class="checkmark1"></span>
              </label>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default ProductSelectFilter;
