import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

export default function Faq() {
    useEffect(() => {
        const wow = new WOW.WOW({
          live: true, // Enables the live mode to detect changes dynamically
        });
        wow.init();
      }, []);

    return (
        <div className='faq-wrapper'>
            <div className='container-main'>
                <h2><img src="../../../assets/images/faq-new.png" alt="faq-img" /></h2>
                <Accordion preExpanded={[12]}>
                    <AccordionItem uuid={12} className="wow animate__animated animate__fadeInUp">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                              <strong>01</strong>  <p>What is Metalsbuy?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Metalsbuy is a leading eCommerce platform for the Iron and Steel industry with real-time prices, availability and order tracking. Metalsbuy offers a range of services, including an online marketplace where buyers and sellers can connect, logistics support to streamline transportation and delivery, and order tracking tools to help companies optimize their supply chain operations. By providing a one-stop-solution for all procurement needs, Metalsbuy aims to revolutionize the way material procurement works in the Iron and Steel industry and make it more efficient and cost-effective.                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>02</strong>
                                <p>Who can join Metalsbuy?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Currently companies, merchant importers and traders involved in buying or selling of Ferro Alloys and Manganese Ore are eligible to join the platform. It's important to note that Metalsbuy's offerings may expand or change over time, and the eligibility criteria for joining the platform may evolve accordingly.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>03</strong>
                                <p>What documents are needed to join Metalsbuy?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            To register on Metalsbuy, users need to provide their email address, phone number and GST number.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>04</strong>
                                <p>Which products are currently offered on Metalsbuy?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Metalsbuy currently offers buying and selling of Silicomanganese, Ferromanganese, Ferrochrome, Ferrosilicon, Manganese Ore and Ferromanganese slag on its platform.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>05</strong>
                                <p>Can Ferro Alloys and Metal Traders sell on Metalsbuy?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Metalsbuy currently allow only Original Manufacturers and Merchant importers to sell products on the platform. Traders are permitted to buy products on Metalsbuy but are not eligible to sell. This policy is in place to ensure the authenticity and quality of the products available for purchase on the platform. It's possible that Metalsbuy's policies may change in the future, and the eligibility criteria for selling and buying on the platform may evolve accordingly.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>06</strong>
                                <p>How does Metalsbuy ensures there is no fraudulent user on the platform.</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Metalsbuy verifies all Buyers and Sellers on its platform, ensuring that users are dealing with trusted entities. This verification process may involve checking the authenticity of user-provided documents, such as business registration certificates, PAN cards, and bank account details. By verifying users, Metalsbuy aims to reduce the risk of fraudulent activity and provide a more secure trading environment for its users.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>07</strong>
                               <p> Where is Metalsbuy available?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Metalsbuy is currently available in India, and both buyers and sellers from all over India are eligible to join and trade on the platform.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.7s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>08</strong>
                                <p>Is there a fee to join Metalsbuy?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>There is no fee to join Metalsbuy. </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.8s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>09</strong>
                                <p>How does Metalsbuy ensure quality of the products?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Metalsbuy's policies require all sellers on the platform to maintain the highest quality of products. All products purchased on our website come with a Product Test Certificate that assures the quality of the product. </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.9s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>10</strong>
                               <p> Does Metalsbuy provide support for transportation services?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes, Metalsbuy offers support for transportation services, including getting quotes from transporters and coordinating with them to ensure timely dispatch and delivery of products. However, it's important to note that all transportation-related costs are borne by the buyer or seller (in the case of FOR deliveries).  </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.10s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>11</strong>
                                <p>Can I create multiple user accounts for my company on Metalsbuy?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes, Metalsbuy allows companies to create multiple user accounts for their employees. Further, different users can have different levels of access depending on company needs. Also, only the primary user account holder is authorized to add other users to the platform. </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.11s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>12</strong>
                                <p>Who can see my listings?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Your listing is visible to all users on the platform. If you want to create a private listing, visible to only certain buyers, you can reach out to us at <a href='mailto:contact@metalsbuy.com '>contact@metalsbuy.com </a> </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.12s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>13</strong>
                                <p>Can I cancel my order placed on Metalsbuy?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Order placed on Metalsbuy can only be canceled if both the Buyer and Seller agree to do so.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.13s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>14</strong>
                               <p> What if the Seller does not delivers the product?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Metalsbuy ensures that the product purchased on our platform is delivered to the Buyer. However, if for some reason the product is not delivered to the buyer, Metalsbuy will work with seller to investigate the issue and ensure a refund to the buyer if necessary.</p>
                        </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.14s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>15</strong>
                                <p>Can I make offer to a Seller and will my offer be visible to other users in the platform?</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes, Metalsbuy provide Buyers with option to make offer to Sellers. The rates and other details quoted in these offers are only visible to the specific buyer and seller involved in the transaction. It’s not visible to the other users in the platform.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.15s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <strong>16</strong>
                                <p>Who should I reach out to if I need assistance?  </p>                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>You can reach us at <a href='mailto:contact@metalsbuy.com '>contact@metalsbuy.com </a> </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                </Accordion>
            </div>
        </div>
    );
}