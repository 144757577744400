import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
  const UserSupport = () => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: true, // Enables the live mode to detect changes dynamically
    });
    wow.init();
  }, []);

  return (
    <>
      <div className="multiuser-support user-support">
        <div className="container-main">
          <h3 className="text-center">
            HERE'S WHY YOU SHOULD CHOOSE METALSBUY
          </h3>
          {/* <div className="support-wrapper">
          
            <Link to="/buyer" className="support-box">
              <div className="user-type-wrap">
                <img src="/assets/images/buyer-img.png" alt="user-type" />
              </div>
              <h4>Buyer</h4>
            </Link>
            <Link to="/seller" className="support-box">
              <div className="user-type-wrap">
                <img src="/assets/images/seller-img.png" alt="user-type" />
              </div>
              <h4>Seller</h4>
            </Link>
          </div> */}
          <div className="service-wrapper">
            <div className="service-details wow animate__animated animate__fadeInUp " data-wow-delay="0.1s">
              <div className="icons-info">
                <i className="like-icon"></i>
              </div>
              <div className="service-info">
                <h6>QUALITY ASSURANCE</h6>
                <p>
                  We provide products only from manufacturers ensuring quality
                  products that meet the specified grades as per the standards.
                </p>
              </div>
            </div>
            <div className="service-details wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
              <div className="icons-info">
                <i className="online-icon"></i>
              </div>
              <div className="service-info">
                <h6>VERIFIED SELLERS</h6>
                <p>
                  At Metalsbuy, authenticity is our hallmark. We exclusively
                  host product manufacturers as sellers, ensuring that you
                  receive products directly from the manufacturers themselves,
                  rather than from traders or third-party vendors.
                </p>
              </div>
            </div>
            <div className="service-details wow animate__animated animate__fadeInUp " data-wow-delay="0.3s">
              <div className="icons-info">
                <i className="verified-icon"></i>
              </div>
              <div className="service-info">
                <h6> ONLINE NEGOTIATIONS</h6>
                <p>
                  ONLINE NEGOTIATIONS Stop negotiating through your broker. Our
                  platform empowers buyers to negotiate directly with multiple
                  sellers at a time, guaranteeing fair deals and swift
                  transactions.
                </p>
              </div>
            </div>
            <div className="service-details wow animate__animated animate__fadeInUp " data-wow-delay="0.4s">
              <div className="icons-info">
                <i className="support-icon"></i>
              </div>
              <div className="service-info">
                <h6> LOGISTICS SUPPORT AND TRACKING</h6>
                <p>
                  We don't stop at the transaction. We go the extra mile with
                  transportation support at best available rates through our network of transporters
                  , along with real time vehicle tracking to ensure your orders reach you hassle-free.
                </p>
              </div>
            </div>
            <div className="service-details wow animate__animated animate__fadeInUp " data-wow-delay="0.5s">
              <div className="icons-info">
                <i className="resource-icon"></i>
              </div>
              <div className="service-info">

                <h6>               DISCOVER NEW CUSTOMERS AND SUPPLIERS
                </h6>
                <p>
                  Sellers can discover new domestic as well as international customers and buyers can discover new suppliers to fulfil their procurement needs.

                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default UserSupport;
