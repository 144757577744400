import { isEmpty } from "lodash";
import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";
import { SAVE_MESSAGE_LIST, SOCKET_CONNECTED, SAVE_CONVERSATION_MESSAGE, SAVE_OFFER_LINK } from "./../actionsTypes";

const token = localStorage.getItem("tokenUser") ? localStorage.getItem("tokenUser"): localStorage.getItem("token")
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const socketConnected = (isConnected) => {

  return {
    type: SOCKET_CONNECTED,
    isConnected: !!isConnected,
  };
};


export const getMessageList = (payload, res) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(apiUrl.messenger.allMessage, payload,{
        headers:{
            Authorization:"Bearer "+token
        }});
      if (res) {
        if (response.data && response.data.data && !isEmpty(response.data.data[0])) {
          res({ status: true, data: response.data.data[0] })
        }
        else {
          res({ status: false })
          dispatch(saveConversation({}))
        }
      }
      dispatch(saveMessageList(response.data));
    } catch (err) {
      if (res) res({ status: false })

    }
  };
};

const saveMessageList = (payload) => {
  return {
    type: SAVE_MESSAGE_LIST,
    payload: payload,
  };
};

export const getConversation = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.messenger.get, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(true);
        dispatch(saveConversation(response.data))

      })
      .catch((err) => { completion(false) });
  };
};

/////////// Offer in Chat /////////////

export const chatOffer = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.messenger.offer, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
        dispatch(
          {
            type: SAVE_OFFER_LINK,
            payload: response.data,
          }
        )

      })
      .catch((err) => { completion({ message: err.response.data.message, status: err.response.data.status }) });
  };
};

export const saveConversation = (payload) => {
  return {
    type: SAVE_CONVERSATION_MESSAGE,
    payload: payload,
  };
}

export const sendMsg = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.messenger.send, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {

        completion(response.data.Data);
      })

  };
};

export const loadProd = async (payload, completion) => {
  try {
    let response = await axiosInstance
      .get(apiUrl.productDetail.aboutProduct + `${payload}`,"",{
        headers:{
            Authorization:"Bearer "+token
        }});
    let tempPrice = response.data.data[0].Price

    Object.keys(tempPrice).map(p =>
      typeof tempPrice[p] === 'object' && tempPrice[p] !== null
        ? response.data.data[0].Price[p] = response.data.data[0].Price[p].$numberDecimal
        : null
    )
    completion(response.data);
  } catch (err) { completion(false) }
}
