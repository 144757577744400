import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { RatingStar } from "../../../ProductDetail/productReview";
import moment from "moment";

const ReviewCard = (props) => {
  const [options, openOptions] = useState(false);

  function useComponentVisible(event) {
    const ref = useRef(false);
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        openOptions(false);
      }
    }
    useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);
      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });
    return { ref };
  }

  const { ref } = useComponentVisible(false);
  return (
    <div className="review-contacts contacts review-rating-wrapper"  >
      <Link to={{
        pathname: `/product/${props.categoryData[0].name}/${props.ProductId}`,
        search: `cat=${props.categoryData[0].slug}`,
      }}>
        <img
          src={
            props.ProductImage[0]
          }
          alt={props.ProductName[0]}
        />
        <span className="review-name">{props.username[0]}</span>
        {/* <strong className="text-grey">{props.brandData[0].name}</strong> */}
        <strong className="prod-name-one-line">
          {/* Product : */}
          {props.ProductName[0]}
        </strong>
        <div className="rating-review-star">
          <div className="rating-star">
            <RatingStar value={props.rating} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="review-wrapper">
              <span className="review-comment">{props.review_comment}</span>
            </div>
          </div>
        </div>
        <small>
          {
            moment(props.updatedAt).calendar({
              sameDay: 'DD/MM/YYYY h:mm a',
              nextDay: 'DD/MM/YYYY h:mm a',
              nextWeek: 'DD/MM/YYYY h:mm a',
              lastDay: 'DD/MM/YYYY h:mm a',
              lastWeek: 'DD/MM/YYYY h:mm a',
              sameElse: 'DD/MM/YYYY h:mm a'
            })
          }


        </small>
      </Link>
      {
        (props.type !== "Seller_review" && (props.editRev && props.UserId === localStorage.getItem("userId"))) &&
        <div className="list-nav review-nav" ref={ref}>
          <div className="dropdown-btn" onClick={() => openOptions(!options)}></div>
          <ul className={"list-options " + (options ? "list-active" : "")}>
            <li className="list-link" onClick={() => props.editRev(true, props)}>Edit</li>
            <li className="list-link" onClick={() => props.deleteRev(true, props)}>Delete</li>
          </ul>
        </div>
      }
    </div>
  );
};
export default ReviewCard;
