import React from 'react';
import Slider from "react-slick";
import { isEmpty } from 'lodash';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BlogCard } from '../Blog/BlogCard';

const SimilarBlog = (props) => {
    const [
        current
        ,
        setCurrent] = React.useState(0)
    let slider = HTMLInputElement | null;
    const next = () => {
        slider.slickNext();
    };

    const previous = () => {
        slider.slickPrev();
    };
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // adaptiveHeight: true,
        afterChange: (current) => setCurrent(current),
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        !isEmpty(props.data) && props.data.data.length > 0 ?
            <div className="related-posts">
                <div className="related-sliders">
                    <h5>Related Post</h5>
                    <div className="home-banner">
                        <button className="btn-bannerprev btn-banner" onClick={previous}>
                            <i className="icon-bannerprev"></i>{" "}
                        </button>
                        <button className="btn-bannernext btn-banner" onClick={next}>
                            <i className="icon-bannernext"></i>{" "}
                        </button>
                    </div>
                </div>
                <Slider {...settings} ref={(c) => { slider = c }}>
                    {props.data.data.map((data, index) => (
                        <BlogCard
                            key={index}
                            {...data}
                        />
                    ))}
                </Slider>
            </div>
            :
            null
    )
}
export default SimilarBlog;