import React from "react";
import { Link } from "react-router-dom";
function CareerHeader() {
  return (
    <>
      {/* <div className="career-banner">
        <div className="container">
          <p>Want to help build a future where all useful things are used?</p>
          <div className="join-btn">
            <Link to="/" className="join-us">
              Join us
            </Link>
          </div>
        </div>
      </div>
      <div className="detail-section">
        <div className="container">
          <p>
           launched in 2013 in Japan, and has since become the largest
            mobile marketplace in the Japanese market. Now we’re on a global
            mission to{" "}
            <span className="highlight-text">
              change the way people buy and sell.
            </span>{" "}
            And with a fast-growing user base in the U.S. of over 45 million
            downloads, we are on our way to doing just that. This is MetalsBuy: The
            Selling App.
          </p>
        </div>
      </div>
      <div className="benefit-section">
        <div className="container">
          <h3>Perks and benefits</h3>
          <div className="benefits-wrap">
            <ul className="benefits-listing">
              <li>
                <div className="benefit-card">
                  <div className="benefit-icon">
                    <i className="icon-medical"></i>
                  </div>
                  <div className="benefit-text">
                    Full benefits (medical, dental, vision)
                  </div>
                </div>
              </li>
              <li>
                <div className="benefit-card">
                  <div className="benefit-icon">
                    <i className="icon-commuter"></i>
                  </div>
                  <div className="benefit-text">Commuter benefits</div>
                </div>
              </li>
              <li>
                <div className="benefit-card">
                  <div className="benefit-icon">
                    <i className="icon-meal"></i>
                  </div>
                  <div className="benefit-text">
                    Catered meals and stocked kitchen
                  </div>
                </div>
              </li>
              <li>
                <div className="benefit-card">
                  <div className="benefit-icon">
                    <i className="icon-events"></i>
                  </div>
                  <div className="benefit-text">
                    Company events and happy hours
                  </div>
                </div>
              </li>
              <li>
                <div className="benefit-card">
                  <div className="benefit-icon">
                    <i className="icon-country"></i>
                  </div>
                  <div className="benefit-text">Office in two countries</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default CareerHeader;
