import React from "react";

function Chips(){
    return(
        <>
         <h1>Chips Category</h1>
        </>
    )
}

export default Chips;