import React, { useState } from 'react'
import { axiosInstance } from '../../../../utils/Service'

const ChangePasswordPopup = ({ setChangePassword, setLoading, handleListing, addToast,roleId }) => {
    console.log("111",roleId)
    const [password, setPassword] = useState("")

    const [passwordError, setPasswordError] = useState(false)

    const [passwordCnf, setPasswordCnf] = useState("")

    const [passwordCnfError, setPasswordCnfError] = useState(false)

    const [passwordMatchError, setPasswordMatchError] = useState(false)

    const [passwordLengthError, setPasswordLengthError] = useState(false)

    const handleForm = () => {
        if (password.length === 0) {
            setPasswordError(true);
            return;
        }

        else if(password.length<8){
            setPasswordLengthError(true);
            return;
        }

        else if (passwordCnf.length === 0){
            setPasswordCnfError(true);
            return;
        }

        else if(password !== passwordCnf){
            setPasswordMatchError(true);
            return;
        }

        let token = localStorage.getItem("token");
        setLoading(true)
        axiosInstance.post(`/role_permission/update_sub_user_password`, {
            password:password,
            roleId:roleId
        }, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setLoading(false)
            handleListing()
            setChangePassword(false)
            addToast(res.data.message, {
                appearance: "success",
                autoDismissTimeout: 2500,
            });
        }).catch((e) => {
            setLoading(false)
        });

    }


    return (
        <div className='make-offer-popup-new role-add-popup'>
            <div className='offer-popup-content' style={{height:"44%", maxHeight:"388px"}}>
                <div className='offer-popup-content-header'>
                    <h3>Change Password</h3>
                    <button
                        className=""
                        type="button"
                        onClick={() => setChangePassword(false)}
                    >
                        <i className="fal fa-times-circle"></i>
                    </button>
                </div>
                <div className="slct-optns select-wrapper">
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Password{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="password"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value); setPasswordError(false); setPasswordMatchError(false); setPasswordLengthError(false) }}
                                placeholder="Enter password"
                            />
                        </div>
                        {passwordError && <div className='error'>Please enter password</div>}
                        {passwordLengthError && <div className='error'>Please enter atleast 8 letters</div>}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Confirm Password{" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="password"
                                value={passwordCnf}
                                onChange={(e) => { setPasswordCnf(e.target.value); setPasswordCnfError(false); setPasswordMatchError(false); setPasswordLengthError(false) }}
                                placeholder="Enter confirm password"
                            />
                        </div>
                        {passwordCnfError && <div className='error'>Please enter confirm password</div>}
                        {passwordMatchError && <div className='error'>Passwords not matching</div>}
                    </div>
                    <div className="navigation-back" style={{ flexDirection: "column" }}>
                        <button onClick={handleForm} className="next-btn">Change password</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordPopup