import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";

export const getFilterData = (payload, callback) => {
    return (dispatch) => {
      return axiosInstance
        .post(apiUrl.filterUrl.getFilterDataUrl, payload)
        .then((response) => {
            callback(response);
        });
    };
  };