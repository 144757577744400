import React from "react";
import "../../PrivacyPolicy/PrivacyPolicy.css";
import { Link, withRouter } from "react-router-dom";
import { get_cms_data_new } from "../../../store/actions/cms_data";
import { connect } from 'react-redux';
import BannerBlock from '../../Dashboard/Banner';
import Timeline from '../../../components/Dashboard/Timeline';
import Counter from '../../../components/Dashboard/Counter';
class AboutUs extends React.Component {
  constructor() {
    super();
    this.state = {
      aboutHtml: ""
    }
  }
  componentDidMount() {
    let getSlug = this.props.match.path.substr(this.props.match.path.lastIndexOf('/') + 1);
    this.props.get_cms_data_new({ slug: getSlug }, (status) => {
      this.setState({ aboutHtml: status.cms_data.description })
    })
    window.scrollTo(0, 0);
  }

  render() {
    return (
      // <div dangerouslySetInnerHTML={{ __html: this.state.aboutHtml }} />
      <>
        <BannerBlock />
        <section className="start-selling-section about-page">
          <Timeline />
          <Counter/>
        </section>
        {/* <section className="start-selling-section business-nationwide">
          <div className="container">
            <h2>Take your business nationwide</h2>
            <p>Sell to customers in every Indian zip code — regardless of your location. Take your existing bricks-and-mortar store online, or test a new idea. We handle shipping and host your storefront. You sell.</p>
          </div>
        </section> */}
        {/* <section className="about-order-products">
          <div className="container">
            <h3>Benefits to Buyers</h3>
            <div className="order-products-wrapper">
              <div className="about-product-card">
                <h4>Best Rates</h4>
                <p>Buyers can see the rates offered by all sellers on our platform. They can buy at the best rate or can counter offer sellers for even better rates.</p>
              </div>
              <div className="about-product-card">
                <h4>Quality Assurance</h4>
                <p>All sellers on our platform are original manufacturers of the product. Buyers can also see seller ratings and can be assured they are buying from a quality seller.
                </p>
              </div>
              <div className="about-product-card">
                <h4>On time Delivery</h4>
                <p>Get quotes from Metalsbuy recommended transporter and get the best rates and on time delivery.</p>
              </div>
              <div className="about-product-card">
                <h4>Buy with Peace</h4>
                <p>All sellers on our platform are verified, so you know you are dealing with a trusted seller.
                </p>
              </div>
              <div className="about-product-card">
                <h4>Order Tracking</h4>
                <p> Your order is tracked from start to finish. You can track orders and also view/provide details at various steps during order fulfillment.</p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="about-order-products">
          <div className="container">
            <h3>Benefits to Sellers</h3>
            <div className="order-products-wrapper">
              <div className="about-product-card">
                <h4>Large Pool of trusted buyers</h4>
                <p> Get access to a large pool of trusted buyers. We do background checks on all buyers to ensure you get enquiries from credible buyers only.</p>
              </div>
              <div className="about-product-card">
                <h4>On time Payment</h4>
                <p>Get on time payments from buyers and real time payment notifications.</p>
              </div>
              <div className="about-product-card">
                <h4>Increase in Orders</h4>
                <p>Get more orders for your products and sell products quickly.</p>
              </div>
              <div className="about-product-card">
                <h4>Efficient negotiationss</h4>
                <p>See offers from multiple buyers at a time and accept/reject offers through the Metalsbuy platform and reduce time and effort in negotiations.</p>
              </div>
              <div className="about-product-card">
                <h4>Order Tracking</h4>
                <p>Keep track of your orders from dispatch to delivery.</p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="about-order-products about-govt-services-wrap">
          <div className="container">
            <h3>What do I need to get started?</h3>
            <p>Our self-service setup process is simple and fast, and there's support if you need it. Before you start, you'll need to make sure you have a couple of things ready:</p>
            <div className="order-products-wrapper">
              <div className="about-govt-services-card">
                <span className="about-circle-wrap pan-img">
                </span>
                <h4>PAN</h4>
                <p>We need your PAN number to be tax-compliant. If you're a business, we'll need a PAN number in the name of your business. If you're a sole proprietor it should be in your name.</p>
              </div>
              <div className="about-govt-services-card">
                <span className="about-circle-wrap gstin-img">
                </span>
                <h4>GSTIN</h4>
                <p>GST Identification Numbers are GST tax registration numbers, introduced by the Government of India to keep track of ecommerce businesses. We'll need one of these too.</p>
              </div>
              <div className="about-govt-services-card">
                <span className="about-circle-wrap gstin-img">
                </span>
                <h4>Product list</h4>
                <p>We can connect to your existing tools via API if you already have a software solution in place. If you don't you can upload a .CSV file from Excel or a similar program.</p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="about-order-products about-govt-services-wrap sell-nation-wide">
          <div className="container">
            <h3>Take your business nationwide</h3>
            <div className="order-products-wrapper">
              <div className="nation-card-wrap">
                <h4>Sell nationwide</h4>
                <p>Wherever your base store is located, you can sell across India. With Bazaar's nationwide network of carrier partners, it doesn't matter where your customers are: you have 138 crore people waiting to buy.</p>
              </div>
              <div className="nation-card-wrap">
                <h4>Grow fast</h4>
                <p>Scale nationwide with easy access to new customers and tools to help you build and run your store. Plug in new tools when necessary to support your growth, and dial in your product descriptions and marketplace identity to increase sales.</p>
              </div>
              <div className="nation-card-wrap">
                <h4>Manage easily</h4>
                <p>Getting started is simple and fast. So is managing your  storefront. With a centralized dashboard showing you inventory, order management, sales, pricing and more, you're in control.</p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="about-order-products local-like-business">
          <div className="container">
            <h3>Product We Are Selling</h3>
            <div className="order-products-wrapper">
              <div className="about-product-card">
                <span className="about-circle-wrap upload-about-product">
                </span>
                <h4>Silico Manganese</h4>
              </div>
              <div className="about-product-card">
                <span className="about-circle-wrap about-storefront">
                </span>
                <h4>Ferro Manganese</h4>
              </div>
              <div className="about-product-card">
                <span className="about-circle-wrap about-get-orders">
                </span>
                <h4>Ferro Silicon</h4>
              </div>
              <div className="about-product-card">
                <span className="about-circle-wrap about-fullfill-orders">
                </span>
                <h4>Ferro Chrome</h4>
              </div>
              <div className="about-product-card">
                <span className="about-circle-wrap about-get-orders">
                </span>
                <h4>Manganese Ore</h4>
              </div>
              <div className="about-product-card">
                <span className="about-circle-wrap about-fullfill-orders">
                </span>
                <h4>Ferro Manganese Slag</h4>
              </div>

            </div>
          </div>
        </section> */}
        {/* <section className="about-contact-section">
          <div className="container">
            <div className="about-contact-wrapper">
              <div className="ltl-block-contact">
                <h4>Ready to start growing your business online?</h4>
                <p>It gives you the keys to growth via a nationwide audience, turnkey tools and built-in trust. Get hyperlocal delivery across India. Set up in just a few minutes.</p>
                <div className="btn-wrapper">
                  <a href="#" className="get-started">Get Started</a>
                  <div className="about-or-text">or</div>

                  <Link to="/contactUs" className="contact-us">Contact Us</Link>
                </div>
              </div>
              <div className="rtl-block-contact">
                <div className="form-block">
                  <input type="text" placeholder="Name" className="form-control" />
                </div>
                <div className="multiple-form-field">
                  <div className="form-block phone-number-contact">
                    <input type="number" placeholder="Phone Number" className="form-control" />
                  </div>
                  <div className="form-block email-id-contact">
                    <input type="email" placeholder="Email" className="form-control" />
                  </div>
                </div>
                <div className="form-block">
                  <input type="text" placeholder="Company Name" className="form-control" />
                </div>
                <div className="form-block">
                  <input type="text" placeholder="What do you sell" className="form-control" />
                </div>
                <div className="form-block-btn">
                  <input type="submit" placeholder="submit" className="submit-btn" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_cms_data_new: (payload, completion) =>
      dispatch(get_cms_data_new(payload, completion))
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AboutUs));
