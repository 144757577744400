import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import Toast from "light-toast";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "../../Loader";
import { AxiosInstance } from "axios";
import { axiosInstance, axiosInstanceAuth } from "../../../utils/Service";
import { Authorization } from "../../../utils/Authorization";

import {
  signUpRequest,
  signUpUpdate,
  socialLogin,
  showSignupErr,
  OTPVerify,
  loginRequest,
  resendSignOTP,
  resendEmailLink,
  OTPVerifyEmail,
  getSellerDEtail,
} from "../../../store/actions/common";
import { detect } from "detect-browser";
import { SET_SIGNUP_USER_ERROR } from "../../../store/actionsTypes";
import { useToasts } from "react-toast-notifications";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OTPForm from "../../Auth/OTPform";
import { getProfileBannerInfo } from "../../../store/actions/profile";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import Select from 'react-select';
import "react-phone-number-input/style.css";
//import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";
import { createRef } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import Slider from "react-slick";
import { RatingStar } from "../../ProductDetail/productReview";

class BasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      firstName: "",
      lastName: "",
      password: "",
      password2: "",
      showPass1: false,
      showPass2: true,
      email: "",
      mobile: "",
      dob: "",
      checkbox: false,
      showInputForm: false,
      error: false,
      ip: "",
      errorMsg: "",
      status: "",
      refferal_code: "",
      passNotMatch: false,
      OTPForm: false,
      showOTPform: false,
      OTP: "",
      emailOTP: "",
      token: "", // for otp user
      time: 50, //timer for resend otp
      showCalendar: false,
      gender: "",
      showLoader: false,
      showMessage: "",
      invalidEmail: false,
      fullName: "",
      invalidPassword: false,
      verifiedMobileOTP: false,
      verifiedEmailOTP: false,
      value: "",
      userType: "Buyer",
      dialCode: "",
      mobileApi: "",
      secondStep: true,
      firstStep: true,
      addressOne: "",
      addressOneError: false,
      state: "",
      stateError: false,
      city: "",
      cityError: false,
      zipCode: "",
      zipCodeError: false,
      PAN: "",
      panError: false,
      cin: "",
      cinError: false,
      gstNumber: "",
      gstError: false,
      selectedImagePan: null,
      panImageError: false,
      selectedCINImage: null,
      selectedImageGst: null,
      gstImageError: false,
      stateList: [],
      disableStatus: true,
      defaultPanImage: "",
      defaultGstImage: "",
      defaultCinImage: "",
      processBar: 0,
      stepsCount: 1,
      submitDisable: false,
      defaultCin: "",

      businessYear: "",
      monthlyNeed: "",
      designation: "",
      secCity: "",
      secState: "",
      secPin: "",
      secAddress1: "",
      secAddress2: "",
      interestedSelling: "",
      Website: "",
      Facebook: "",
      google: "",
      linkedIn: "",
      bankName: "",
      accountNumber: "",
      cAccountNumber: "",
      ifsc: "",
      accountHolderName: "",
      affData: [],
      ibterestSell: [],
      proManufactur: [],
      setUserType: "",
      cinImageDisable: false,
    };
    this.companyNameRef = createRef();
    this.firstNameRef = createRef();
    this.lastNameRef = createRef();
    this.emailRef = createRef();
    this.passwordRef = createRef();
    this.loginInfo = Authorization();
  }

  componentDidMount() {
    // fetch("https://api.ipify.org?format=json")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     this.setState({ ip: json.ip });
    //   });
    this.setState({ setUserType: localStorage.getItem("userType") });
    axiosInstance.get("category/country").then((res) => {
      this.setState({ stateList: res.data.data[0].stateData });
    });

    this.props.getSellerDEtail((status) => {

      let dataCurrent = status.data[0];
      console.log("dataCurrent", status)
      this.setState({
        fullDataSeller: status,
        userType: dataCurrent.userType,
        companyName: dataCurrent.companyName,
        email: dataCurrent.email,
        mobile: dataCurrent.mobile,
        mobileApi: dataCurrent.mobile,
        countryCode: dataCurrent.countryCode,
        // dataCurrent.first_name
        firstName: dataCurrent.first_name,
        lastName: dataCurrent.last_name,
        addressOne: dataCurrent.addressLine1ForMailing,
        city: dataCurrent.cityForMailing,
        state: dataCurrent.stateForMailing,
        zipCode: dataCurrent.zipCodeForMailing,
        PAN: dataCurrent.PAN,
        gstNumber: dataCurrent.gst,
        defaultPanImage: dataCurrent.pan,
        defaultGstImage: dataCurrent.gstFile,
        defaultCinImage: dataCurrent.cin_images,
        submitDisable:
          dataCurrent.cin == "" ||
            dataCurrent.mobile == "" ||
            dataCurrent.cin_images == null
            ? false
            : true,
        defaultCin: dataCurrent.cin,
        cin: dataCurrent.cin,
        mobileDisAble: dataCurrent.mobile !== "" ? true : false,

        businessYear: dataCurrent.businessYears,
        monthlyNeed: dataCurrent.monthlyNeed,
        designation: dataCurrent.designation,
        secCity: dataCurrent.city,
        secState: dataCurrent.state,
        secPin: dataCurrent.zipCode,
        secAddress1: dataCurrent.addressLine1,
        secAddress2: dataCurrent.addressLine2,
        Website: dataCurrent.websiteLink,
        Facebook: dataCurrent.facebookLink,
        google: dataCurrent.otherLink,
        linkedIn: dataCurrent.linkedinLink,
        bankName: dataCurrent.bankName,
        accountNumber: dataCurrent.accountNumber,
        cAccountNumber: dataCurrent.accountNumber,
        ifsc: dataCurrent.ifsc,
        accountHolderName: dataCurrent.accountHolderName,
        affData: dataCurrent.affiliationData,
        ibterestSell: dataCurrent.interstInBuying || [],
        proManufactur: dataCurrent.productManufacturing || [],
      });
    });
  }

  componentDidUpdate(previous, current) {
    if (
      Object.keys(previous.userDataDEtail).length == 0 &&
      Object.keys(this.props.userDataDEtail).length !== 0
    ) {
      let dataCurrent = this.props.userDataDEtail.userDataDEtail[0];
      this.setState({
        userType: dataCurrent.userType,
        companyName: dataCurrent.companyName,
        email: dataCurrent.email,
        mobile: dataCurrent.mobile,
        // dataCurrent.first_name
        firstName: dataCurrent.first_name,
        lastName: dataCurrent.last_name,
        addressOne: dataCurrent.addressLine1ForMailing,
        city: dataCurrent.cityForMailing,
        state: dataCurrent.stateForMailing,
        zipCode: dataCurrent.zipCodeForMailing,
        PAN: dataCurrent.PAN,
        gstNumber: dataCurrent.gst,
        defaultPanImage: dataCurrent.pan,
        defaultGstImage: dataCurrent.gstFile,
        defaultCinImage: dataCurrent.cin_images,
        submitDisable:
          dataCurrent.cin == "" || dataCurrent.mobile == "" ? false : true,
        defaultCin: dataCurrent.cin,
        cin: dataCurrent.cin,
        mobileDisAble: dataCurrent.mobile !== "" ? true : false,

        businessYear: dataCurrent.businessYears,
        monthlyNeed: dataCurrent.monthlyNeed,
        designation: dataCurrent.designation,
        secCity: dataCurrent.city,
        secState: dataCurrent.state,
        secPin: dataCurrent.zipCode,
        secAddress1: dataCurrent.addressLine1,
        secAddress2: dataCurrent.addressLine2,
        Website: dataCurrent.websiteLink,
        Facebook: dataCurrent.facebookLink,
        google: dataCurrent.otherLink,
        linkedIn: dataCurrent.linkedinLink,
        bankName: dataCurrent.bankName,
        accountNumber: dataCurrent.accountNumber,
        cAccountNumber: dataCurrent.accountNumber,
        ifsc: dataCurrent.ifsc,
        accountHolderName: dataCurrent.accountHolderName,
        affData: dataCurrent.affiliationData,
        ibterestSell: dataCurrent.interstInBuying,
        proManufactur: dataCurrent.productManufacturing,
      });
    }
  }

  changeInput = (e) => {
    if (e.target.name === "companyName") {
      const re = /^[A-Za-z/\W|_/g ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "firstName") {
      const re = /^[A-Za-z ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "lastName") {
      const re = /^[A-Za-z ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "password") {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let checkSpecial = specialChars.test(e.target.value);

      function hasUpperCase(str) {
        return /[A-Z]/.test(str);
      }

      const number = /\d/;
      let checkNumber = number.test(e.target.value);


      if (checkSpecial && hasUpperCase(e.target.value) && checkNumber) {
        this.setState({
          [e.target.name]: e.target.value,
          invalidPassword: false,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          invalidPassword: true,
        });
      }
    } else this.setState({ [e.target.name]: e.target.value, status: false });
  };

  changeInputEmail = (e) => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(e.target.value)) {
      this.setState(
        { [e.target.name]: e.target.value, status: false, invalidEmail: false },
        () =>
          this.props.showSignupErr({
            type: SET_SIGNUP_USER_ERROR,
            payload: { errData: "", status: false },
          })
      );
      this.props.showSignupErr({
        type: SET_SIGNUP_USER_ERROR,
        payload: { errData: "", status: false },
      });
    } else {
      if (e.target.value.length > 0) {
        this.setState({ [e.target.name]: e.target.value, invalidEmail: true });
      } else {
        this.setState({ [e.target.name]: e.target.value, invalidEmail: false });
      }
    }
  };

  checkboxState = () => {
    this.setState({ checkbox: !this.state.checkbox, error: false });
  };

  responseFacebook = (response) => {
    if (response.status !== "unknown") {
      const name = response.name.split(" ");
      const first_name = name[0];
      const last_name = name[name.length - 1];
      const email = response.email;
      const socialId = response.id;
      const payload = {
        first_name,
        last_name,
        email,
        socialId,
        provider: "facebook",
      };
      this.props.socialLogin(payload, (status) => {
        if (status) this.setState({ showLoader: false });
        this.props.addToast("Signed with Facebook", { appearance: "success" });
        this.props.closePopup(true);
      });
    }
  };

  responseGoogle = (response) => {
    const data = response.profileObj;
    const first_name = data.givenName;
    const last_name = data.familyName;
    const email = data.email;
    const socialId = data.googleId;
    const payload = {
      first_name,
      last_name,
      email,
      socialId,
      provider: "google",
    };
    this.props.socialLogin(payload, (status) => {
      if (status) this.setState({ showLoader: false });
      this.props.addToast("Signed with Google", { appearance: "success" });
      this.props.closePopup(true);
    });
  };

  enterPhone = (e, code) => {

    let temp = e;
    this.setState({ mobile: temp });
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  submitOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const params = {
      otp: this.state.OTP,
      token: this.state.token,
    };
    this.props.OTPVerify(params, (callback) => {
      if (callback.status) {
        this.setState({ showLoader: false });
        this.setState({ showOTPform: true, verifiedMobileOTP: true });

        this.props.addToast(
          callback.data.message,
          { appearance: "success" },
          () => {
            this.setState({ showOTPform: false });
          }
        );
        setTimeout(() => {
          localStorage.setItem(
            "approved",
            callback.data && callback.data.userData.Approval
          );
          localStorage.setItem("pendinModal", true);
          window.location.reload();
        }, 1000);
      } else {
        this.setState({ showLoader: false });
        this.props.addToast(callback.data.message, { appearance: "error" });
      }
    });
  };

  submitOTPEmail = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const params = {
      otp: this.state.emailOTP,
      token: this.state.token,
    };
    this.props.OTPVerifyEmail(params, (callback) => {
      if (callback.status) {
        this.setState({ showLoader: false });
        this.setState({ showOTPform: true, verifiedEmailOTP: true });

        this.props.addToast(
          callback.data.message,
          { appearance: "success" },
          () => {
            this.setState({ showOTPform: false });
          }
        );

        setTimeout(() => {
          if (this.state.verifiedEmailOTP && this.state.verifiedMobileOTP) {
            localStorage.setItem(
              "isProfileCompleted",
              callback.data && callback.data.userData.isProfileCompleted
            );

            window.location.reload();
          }
        }, 1000);
      } else {
        this.setState({ showLoader: false });
        this.props.addToast(callback.data.message, { appearance: "error" });
      }
    });
  };

  resendMobOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });

    this.props.resendSignOTP({ token: this.state.token }, (callback) => {
      if (callback) {
        this.setState({ showLoader: false });
        this.props.addToast(callback.message, { appearance: "success" });
        this.setState({ time: 50 });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  resendEmailOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });

    this.props.resendSignOTP({ token: this.state.token }, (callback) => {
      if (callback) {
        this.setState({ showLoader: false });
        this.props.addToast(callback.message, { appearance: "success" });
        this.setState({ time: 50 });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  resendEmail = () => {
    this.props.resendEmailLink({ token: this.state.token }, (callback) => {
      this.props.addToast(callback.message, { appearance: "success" });
      this.setState({ time: 50 });
    });
  };

  loginFunction = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const payload = {
      email: this.state.email,
      password: this.state.password,
    };
    localStorage.setItem("rememberMe", true);
    localStorage.setItem("bazaarEmail", payload.email);
    localStorage.setItem("bazaarPass", payload.password);

    console.log('payload ',payload);

    this.props.loginRequest(payload, (status) => {
      if (status.status) {
        this.props.getProfileBannerInfo();
        window.location.reload();
        this.setState({ showLoader: false });
        this.props.addToast("Login Successfully", { appearance: "success" });
        this.props.closePopup(false);
        this.props.addToast(status.data.errData, { appearance: "error" });
      }
    });
  };

  ChangeFormateDate = () => {
    const [dd, mm, yy] = this.state.dob.split(/-/g);
    return `${mm}-${dd}-${yy}`;
  };
  setDateOfBirth = (props) => {
    const newDOB =
      props.getDate() +
      "-" +
      (props.getMonth() + 1) +
      "-" +
      props.getFullYear();
    this.setState({ showCalendar: !this.state.showCalendar, dob: newDOB });
  };

  backHandler = () => {
    this.setState({ OTPForm: false });
  };

  getCode = (item) => {
  };

  buyerHandle = (e) => {
    this.setState({ userType: e.target.value });
  };

  sellerHandle = (e) => {
    this.setState({ userType: e.target.value });
  };

  nextStep = () => { };

  addressOnehandler = (e) => {
    this.setState({ addressOne: e.target.value });
    this.setState({ addressOneError: false });
  };
  cityhandler = (e) => {
    this.setState({ city: e.target.value });
    this.setState({ cityError: false });
  };
  statehandler = (e) => {
    this.setState({ state: e.target.value });
    this.setState({ stateError: false });
  };
  zipcodehandler = (e) => {
    this.setState({ zipCode: e.target.value });
    this.setState({ zipCodeError: false });
  };
  panChangeHandler = (e) => {
    this.setState({ PAN: e.target.value });
    this.setState({ panError: false });
  };
  cinChangeHandler = (e) => {
    this.setState({ cin: e.target.value });
    this.setState({ cinError: false });
  };
  gstNumberChangeHandler = (e) => {
    this.setState({ gstNumber: e.target.value });
    this.setState({ gstError: false });
  };
  panImageHandler = (e) => {
    this.setState({ selectedImagePan: e.target.files[0], defaultPanImage: "" });
    this.setState({ panImageError: false });
  };
  cinImageHandler = (e) => {
    this.setState({ selectedCINImage: e.target.files[0], defaultCinImage: "" });
  };
  gstImageHandler = (e) => {
    this.setState({ selectedImageGst: e.target.files[0], defaultGstImage: "" });
    this.setState({ gstImageError: false });
  };

  handleNextClick = () => {
    this.setState({ processBar: 50, stepsCount: 2 });
    if (this.state.companyName.length === 0) {
      this.companyNameRef.current.focus();
    } else if (this.state.firstName && this.state.firstName.length === 0) {
      this.firstNameRef.current.focus();
    } else if (this.state.lastName && this.state.lastName.length === 0) {
      this.lastNameRef.current.focus();
    } else if (this.state.email.length === 0) {
      this.emailRef.current.focus();
    }
    // else if (this.state.password.length === 0) {
    //   this.passwordRef.current.focus();
    // }
    else if (this.state.invalidEmail) {
      return null;
    } else if (this.state.invalidPassword) {
      return true;
    } else {
      this.setState({ firstStep: false, secondStep: true });
    }
  };

  formSubmit = (e) => {
    e.preventDefault();
    // if(this.state.cin == ""){
    //    this.setState({cinError:true});
    //    document.getElementById("cin").focus();
    // }
    // else
    this.setState({ passNotMatch: false, processBar: 100, stepsCount: 1 });
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const browser = detect();
    const payload = {
      cin: this.state.cin,
      mobile: this.state.mobileApi,
      countryCode: this.state.dialCode,
    };

    let formdata = new FormData();
    for (let item in payload) {
      formdata.append(item, payload[item]);
    }
    formdata.append("cin_image", this.state.selectedCINImage);
    this.props.signUpUpdate(formdata, (status) => {

      if (status.res.status === "success") {
        if (
          this.state.cin !== "" &&
          this.state.mobileApi !== "" &&
          this.state.selectedCINImage !== null
        ) {
          this.setState({
            submitDisable: true,
            cinImageDisable: true,
            defaultCin: this.state.cin,
            mobileDisAble: true,
          });
        }
        this.setState({ showLoader: false });
        this.props.addToast(status.res.message, { appearance: "success" });
        // this.props.addToast(status.res.message ? status.res.message : "OTP sent on your mobile and email", { appearance: "success" });
      } else {
        this.setState({
          errorMsg: status.res.message,
          status: status.res.status,
        });
        this.setState({ showLoader: false });
        this.props.addToast(status.res.message, { appearance: "error" });
        // Toast.fail("SignUp Fail..", 1000);
      }
    });
  };

  responseFacebook = (response) => {
    if (response.status !== "unknown") {
      const name = response.name.split(" ");
      const first_name = name[0];
      const last_name = name[name.length - 1];
      const email = response.email;
      const socialId = response.id;
      const payload = {
        first_name,
        last_name,
        email,
        socialId,
        provider: "facebook",
      };
      this.props.socialLogin(payload, (status) => {
        if (status) this.setState({ showLoader: false });
        this.props.addToast("Signed with Facebook", { appearance: "success" });
        this.props.closePopup(true);
      });
    }
  };

  back = () => {
    this.setState({
      firstStep: true,
      secondStep: false,
      processBar: 0,
      stepsCount: 1,
    });
  };
  handleEdit = () => { };

  render() {
    const {
      firstName,
      lastName,
      password,
      showPass1,
      password2,
      showPass2,
      email,
      error,
      status,
      errorMsg,
      passNotMatch,
      mobile,
      showOTPform,
      OTP,
      time,
      dob,
      showCalendar,
      gender,
    } = this.state;

    return (
      <>
        {this.state.showLoader ? (
          <Loader message={this.state.showMessage} />
        ) : null}

        {/* <div className="outside-layer" onClick={() => this.props.closePopup(true)}></div> */}
        <div
          className={
            "signup-form signup-frm-wrp signup-page-wrapp signup-new-wrap basic-info new-profile-address" +
            (showOTPform ? " otp-open" : "") +
            (this.state.OTPForm ? " otp-open" : "")
          }
        >
          <Helmet>
            <style>
              {`
           .facebook-button-holder, .google-button-holder {
            position: relative;
           }
            .facebook-button-holder span button, .google-button-holder button:first-child {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 15;
              width: 100%;
              height: 100%;
             opacity:0 !important;
            }
            `}
            </style>
          </Helmet>

          <button
            className="close-btn"
            // onClick={() => this.props.closePopupAll()}
            onClick={() => this.props.closePopup(true)}
          >
            {/* <i className="fal fa-times-circle"></i> */}
          </button>

          <div
            className="inner-sign signup-without-img"
            style={showOTPform ? { justifyContent: "space-around" } : {}}
          >
            <div className="sign-form">
              {console.log("first", this.state)}
              {this.state.OTPForm ? (
                <OTPForm
                  OTP={OTP}
                  EmailOTP={this.state.emailOTP}
                  setState={(key, val) => this.setState({ [key]: val })}
                  submitOTP={this.submitOTP}
                  submitOTPEMAIL={this.submitOTPEmail}
                  time={time}
                  resendMobOTP={this.resendMobOTP}
                  resendEmail={this.resendEmail}
                  backHandler={this.backHandler}
                  mobileNumber={this.state.mobile}
                  verifyMobileOTP={this.state.verifiedMobileOTP}
                  verifyEmailOTP={this.state.verifiedEmailOTP}
                />
              ) : null}
              <form autoComplete="off" onSubmit={(e) => this.formSubmit(e)}>
                <div className="user-detail-edit signup-head-wrap new-edit-profile-head">
                  <div className="complete-profile-wrap">
                    <div className="step-count-registration">
                      <span className="step-number">
                        Step {this.state.stepsCount}
                      </span>
                      <span className="total-step">Out of 2</span>
                    </div>
                  </div>
                  <div className="process-bar-wrapper">
                    <div className="progress-bar-wrapper">
                      <ProgressBar
                        completed={this.state.processBar}
                        className="process-bar"
                      />
                    </div>
                    <span className="completed-process">
                      {this.state.processBar}% complete
                    </span>
                  </div>
                </div>
                <div className="new-cover-wrap">
                  {this.state.firstStep ? (
                    <div className="detail-from signup-detail-form sign-up-form-wrap new-edit-profile-body">
                      <div className="form-block-wrap company-name-wrp">
                        <div className="enter-mobile-label">Company Name:</div>
                        <div className="form-input">
                          <TextField
                            variant="outlined"
                            disabled={this.state.disableStatus}
                            required
                            type="text"
                            name="companyName"
                            // label="Company Name*"
                            placeholder="Enter company name here"
                            value={this.state.companyName}
                            onChange={(e) => this.changeInput(e)}
                            onKeyDown={this.handleEnter}
                            InputProps={{
                              inputProps: {
                                ref: this.companyNameRef,
                                maxLength: 30,
                                minLength: 2,
                              },
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="form-input">
                    <TextField
                      variant="outlined"
                      required
                      type="text"
                      name="firstName"
                      label="First Name*"
                      value={firstName}
                      onChange={(e) => this.changeInput(e)}
                      onKeyDown={this.handleEnter}
                      InputProps={{
                        inputProps: {
                          maxLength: 30,
                          minLength: 2,
                        },
                      }}
                    />
                  </div> */}
                      <div className="names-wrap">
                        <div className="form-block-wrap sub-form-wrp">
                          <div className="enter-mobile-label">First Name:</div>
                          <div className="form-input">
                            <TextField
                              required
                              variant="outlined"
                              disabled={this.state.disableStatus}
                              type="text"
                              name="firstName"
                              // label="Full Name*"
                              placeholder="Enter first name"
                              value={this.state.firstName}
                              onChange={(e) => this.changeInput(e)}
                              onKeyDown={this.handleEnter}
                              InputProps={{
                                inputProps: {
                                  ref: this.firstNameRef,
                                  maxLength: 30,
                                  minLength: 2,
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-block-wrap sub-form-wrp">
                          <div className="enter-mobile-label">Last Name:</div>
                          <div className="form-input">
                            <TextField
                              required
                              variant="outlined"
                              disabled={this.state.disableStatus}
                              type="text"
                              name="lastName"
                              // label="Full Name*"
                              placeholder="Enter last name"
                              value={this.state.lastName}
                              onChange={(e) => this.changeInput(e)}
                              onKeyDown={this.handleEnter}
                              InputProps={{
                                inputProps: {
                                  ref: this.lastNameRef,
                                  maxLength: 30,
                                  minLength: 2,
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-block-wrap sub-form-wrp">
                          <div className="enter-mobile-label">Email:</div>
                          <div className="form-input">
                            <TextField
                              variant="outlined"
                              disabled={this.state.disableStatus}
                              required
                              type="email"
                              name="email"
                              placeholder="Enter email address"
                              // label="Email Address or Mobile No.*"
                              value={email}
                              onChange={(e) => this.changeInputEmail(e)}
                              onKeyDown={this.handleEnter}
                              inputProps={{
                                ref: this.emailRef,
                              }}
                            />
                            {this.state.invalidEmail ? (
                              <span className="error">
                                Enter a valid email address
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="names-wrap">
                        <div className="form-block-wrap sub-form-wrp ">
                          <div className="enter-mobile-label">Mobile</div>
                          <div className="form-input number-code-wrapper signup-phone-input signup-code">
                            <PhoneInput
                              country={"in"}
                              disableDropdown={false}
                              // placeholder="Enter mobile no."
                              className={this.state.mobile && "arrow-hide"}
                              enableSearch={true}
                              value={this.state.countryCode + this.state.mobile}
                              onChange={(phone, country) => {
                                const reducedPhone = phone.replace(
                                  country.dialCode,
                                  ""
                                );
                                this.setState({
                                  mobile: phone,
                                  mobileApi: reducedPhone,
                                  dialCode: country.dialCode,
                                });
                              }}
                              isValid={(value, country) => {
                                if (value.length === 0) {
                                  return false;
                                } else {
                                  return true;
                                }
                              }}
                              inputProps={{
                                required: true,
                              }}
                              disabled={this.state.mobileDisAble}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="enter-mobile-label">Mobile*</div>
                  <div className="form-input number-code-wrapper signup-phone-input signup-code">
                    <span className="number-code">
                      +91
                    </span>
                    <TextField
                      variant="outlined"
                      type="text"
                      required
                      InputProps={{
                        inputProps: {
                          maxLength: 10,
                          minLength: 10,
                          autoComplete: "new-password",
                        },
                      }}
                      label="Enter mobile no.*"
                      value={mobile}
                      onKeyDown={this.handleEnter}
                      onChange={(e) => this.enterPhone(e)}
                    />
                  </div> */}
                      {/* <div className="form-block-wrap">
                      <div className="enter-mobile-label">Password*</div>
                      <div className="form-input">
                        <TextField
                          variant="outlined"
                          disabled={this.state.disableStatus}
                          required
                          type={showPass1 ? "text" : "password"}
                          name="password"
                          // label="Password*"
                          placeholder="Enter password"
                          value={password}
                          InputProps={{
                            inputProps: {
                              ref: this.passwordRef,
                              maxLength: 15,
                              minLength: 6,
                              autoComplete: "new-password",
                            },
                          }}
                          onChange={(e) => this.changeInput(e)}
                          onKeyDown={this.handleEnter}
                        />
                        <i
                          onClick={() => this.setState({ showPass1: !showPass1 })}
                          className={`${showPass1 ? "fal fa-eye" : "far fa-eye-slash"
                            }`}
                        />
                      </div>
                   
                      {
                        this.state.invalidPassword ?
                          <div className='passwrd-text'>
                            <p>Password should contain 1 special and 1 capital character</p>
                            <p>Password should have atleast 1 number</p>
                          </div> : null
                      }

                    </div> */}

                      {/* <Link to="/checkout">
                  <h3>Alok</h3>
                  </Link> */}

                      <div className="login-btn-wrapper">
                        {/* <button
                        type={"button"}
                        className="shop-now"
                        onClick={this.handleNextClick}
                      >
                        Next
                      </button> */}
                        {/* <button className="shop-now" onClick={()=>{this.demo()}}>DEmo </button> */}
                      </div>
                      {/* <div className="login-condition">
                    <p>Don’t have an account? <button className="sign-up-here">Sign up here</button></p>
                  </div> */}
                    </div>
                  ) : this.state.secondStep ? (
                    // second step of signup form
                    <></>
                  ) : null}

                  <div className="login-description login-description-form-wrap address-field-wrap upload-document-wrapper new-doc-wrap">
                    {/* <button
                        type="button"
                        className="btn-bck"
                        onClick={() => this.back()}
                      >
                        <i class="fas fa-long-arrow-left"></i>
                      </button> */}
                    <div className="new-mailing-address-wrp">
                      <div className="mail-address-head">
                        <h3>Mailing Address:</h3>
                      </div>
                      <div className="address-field-new-wrap">
                        {/* <div className="addres-header-wrap">
                          <h4>Manufacturing Plant Address</h4>
                        </div> */}
                        <div className="names-wrap">
                          <div className="form-block-wrap">
                            <label className="enter-mobile-label">
                              Address line:
                            </label>
                            <div className="form-input">
                              <input
                                type="text"
                                placeholder="Type here"
                                onChange={(e) => this.addressOnehandler(e)}
                                id="addressOne"
                                value={this.state.addressOne}
                                maxLength="50"
                                disabled={this.state.disableStatus}
                              />
                            </div>
                            {this.state.addressOneError ? (
                              <span className="error">
                                Please enter address line 1
                              </span>
                            ) : null}
                          </div>

                          <div className="form-block-wrap address-line">
                            <div className="form-intput">
                              <label className="enter-mobile-label">City</label>
                              <input
                                type="text"
                                placeholder="Enter city name here"
                                onChange={(e) => this.cityhandler(e)}
                                id="city"
                                value={this.state.city}
                                maxLength="100"
                                disabled={this.state.disableStatus}
                              />
                            </div>
                            {this.state.cityError ? (
                              <span className="error">Please enter city</span>
                            ) : null}
                          </div>
                          <div className="input-form-wrap select-affilation-profile address-name-wrap">
                            <div className="form-intput select-affilation-wrap new-state">
                              <label className="enter-mobile-label">
                                State:
                              </label>

                              <div className="select-main-wrapper">
                                <select
                                  onChange={(e) => this.statehandler(e)}
                                  id="state"
                                  disabled={this.state.disableStatus}
                                >
                                  <option value={""}>Select</option>
                                  {this.state.stateList.length
                                    ? this.state.stateList.map(
                                      (item, index) => (
                                        <option
                                          value={item._id}
                                          selected={
                                            this.state.state == item._id
                                              ? true
                                              : null
                                          }
                                        >
                                          {item.name}
                                        </option>
                                      )
                                    )
                                    : null}
                                  {/* {
                                    stateDataArr && stateDataArr.stateData && stateDataArr.stateData.map(item => <option value={item._id} selected={state == item._id ? true : false}>{item.name}</option>)
                                  } */}
                                </select>
                                <i className="icon-arrow-down"></i>
                              </div>
                              {this.state.stateError ? (
                                <span className="error">
                                  Please select state
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {/* <div className='form-intput'>
                        <label>state</label>
                        <select onChange={(e) => statehandler(e)} id="state" disabled={profileComponent}>
                          <option value={""}>Please select state</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>

                        </select>

                      </div> */}
                      </div>
                      <div className="names-wrap">
                        {/* <div className="form-block-wrap">
                          <div className="form-intput pan-gst-wrap">
                            <label className="enter-mobile-label">
                              PAN number:
                            </label>
                            <input
                              type="text"
                              placeholder="Enter PAN number here"
                              onChange={(e) => this.panChangeHandler(e)}
                              id="pan"
                              value={this.state.PAN}
                              maxLength="10"
                              disabled={this.state.disableStatus}
                            />
                          </div>
                          {this.state.panError ? (
                            <span className="error">
                              Please enter PAN number
                            </span>
                          ) : null}
                        </div>
                        <div className="form-block-wrap">
                          <div className="form-intput pan-gst-wrap">
                            <label className="enter-mobile-label">
                              CIN number:
                            </label>
                            <input
                              type="text"
                              placeholder="Enter CIN number here"
                              onChange={(e) => this.cinChangeHandler(e)}
                              id="cin"
                              value={this.state.cin}
                              maxLength="21"
                              disabled={
                                this.state.defaultCin == "" ? false : true
                              }
                            />
                          </div>
                          {this.state.cinError ? (
                            <span className="error">
                              Please enter CIN number
                            </span>
                          ) : null}
                        </div> */}
                        <div className="form-block-wrap">
                          <div className="form-intput pan-gst-wrap">
                            <label className="enter-mobile-label">
                              GST number:
                            </label>
                            <input
                              type="text"
                              placeholder="Enter GST number here"
                              onChange={(e) => this.gstNumberChangeHandler(e)}
                              id="gstNumber"
                              value={this.state.gstNumber}
                              maxLength="15"
                              disabled={this.state.disableStatus}
                            />
                          </div>
                          {this.state.gstError ? (
                            <span className="error">
                              Please enter GST number
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <>
                      {/* {previousNext ?
                            <div className="registration-btn registration-btn-wrpper">
                              <input className="btn shop-now go-back" type="button" value={"Go Back"} onClick={handlePrevious} />
                              <input className="btn shop-now next-btn" type="button" value={"Next"} onClick={nextValidation} />
                            </div> : null

                          } */}
                    </>
                  </div>
                </div>
                <div className="new-cover-wrap business-wrap new-designation-wrap">
                  <Link
                    to={{
                      pathname: "/profile/complete_profile",
                      query: "edit",
                    }}
                    className="edit-btn"
                  >
                    <i class="fas fa-edit"></i>
                  </Link>
                  <div className="names-wrap">
                    <div className="form-block">
                      <label>Year Established:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.businessYear}
                        disabled={true}
                      />
                    </div>
                    <div className="form-block">
                      <label>Aggregate Monthly  {this.state.setUserType == "Buyer"
                        ? "Need"
                        : "Production"} (In Mt)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.monthlyNeed}
                        disabled={true}
                      />
                    </div>
                    <div className="form-block">
                      <label>Designation*</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.designation}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mail-address-head">
                    <h3>Manufacturing Plant Address:</h3>
                  </div>
                  <div className="names-wrap address-main-new-wrap">
                    <div className="form-block">
                      <label>City</label>
                      {this.state.secCity && (
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.secCity}
                        />
                      )}
                    </div>
                    <div className="form-block select-state-wrap new-state-wrp">
                      <label>State</label>
                      <select
                        onChange={(e) => this.statehandler(e)}
                        id="state"
                        disabled={this.state.disableStatus}
                        className="form-control"
                      >
                        {this.state.stateList.length
                          ? this.state.stateList.map((item, index) => (
                            <option
                              value={item._id}
                              selected={
                                this.state.secState === item._id
                                  ? true
                                  : false
                              }
                            >
                              {this.state.secState === item._id
                                ? item.name
                                : ""}
                            </option>
                          ))
                          : null}
                      </select>
                      {/* <input type="text" className="form-control" value={this.state.secState} /> */}
                    </div>
                    <div className="form-block">
                      <label>Pin code</label>
                      {this.state.secPin && (
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.secPin}
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-block-wrap-main">
                    <div className="form-block">
                      <label>Address Line 1:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.secAddress1}
                        disabled={true}
                      />
                    </div>
                    <div className="form-block">
                      <label>Address Line 2:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.secAddress2}
                        disabled={true}
                      />
                    </div>
                    <div className="form-block">
                      <label>
                        Interested In{" "}
                        {this.state.setUserType == "Buyer"
                          ? "Buying"
                          : "Selling"}
                        :
                      </label>
                      {this.state.ibterestSell && this.state.ibterestSell.map((item, index) => {
                        return (
                          <>
                            <div className="select-values">{`${item}${index + 1 !== this.state.ibterestSell.length ? ", " : ""} `}&nbsp;</div>
                          </>
                        );
                      })}
                    </div>
                    <div className="form-block">
                      <label>Affiliations & Assocations:</label>
                      {this.state.affData.map((item, index) => {
                        return (
                          <>
                            <div className="select-values">
                              {`${item.name}${index + 1 !== this.state.affData.length ? ", " : ""} `}&nbsp;
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="form-block">
                      <label>Product Manufacturing:</label>
                      {this.state.proManufactur && this.state.proManufactur.map((item, index) => {
                        return (
                          <>
                            <div className="select-values">{`${item}${index + 1 !== this.state.proManufactur.length ? ", " : ""} `}&nbsp;</div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {this.state.Website == null &&
                  this.state.Facebook == null &&
                  this.state.google == null &&
                  this.state.linkedIn == null ? (
                  <div className="new-cover-wrap business-wrap social-link-file">
                    {this.state.Website !== null ? null : (
                      <div className="form-block">
                        <label>Website:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.Website}
                          disabled={true}
                        />
                      </div>
                    )}
                    {this.state.Facebook !== null ? null : (
                      <div className="form-block">
                        <label>Facebook:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.Facebook}
                          disabled={true}
                        />
                      </div>
                    )}
                    {this.state.google !== null ? null : (
                      <div className="form-block">
                        <label>Google:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.google}
                          disabled={true}
                        />
                      </div>
                    )}
                    {this.state.linkedIn !== null ? null : (
                      <div className="form-block">
                        <label>Linkedin:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.linkedIn}
                          disabled={true}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
                <div className="new-cover-wrap business-wrap contact-new-wrap">
                  <div className="names-wrap">
                    <div className="form-block">
                      <label>Bank Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.bankName}
                        disabled={true}
                      />
                    </div>
                    <div className="form-block">
                      <label>Account Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.accountNumber}
                        disabled={true}
                      />
                    </div>
                    <div className="form-block">
                      <label>Confirm Account Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.cAccountNumber}
                        disabled={true}
                      />
                    </div>
                    <div className="form-block">
                      <label>IFSC</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.ifsc}
                        disabled={true}
                      />
                    </div>
                    <div className="form-block">
                      <label>Account Holder Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.accountHolderName}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                {this.state.fullDataSeller &&
                        this.state.fullDataSeller.total_review && <div className="seller-review-wrapper" style={{textAlign:"left"}}>
                  <div className="container">
                    <h3>Reviews</h3>
                    <Slider {...{
                      dots: false,
                      infinite: false,
                      arrows: true,
                      speed: 1000,
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      responsive: [
                        {
                          breakpoint: 1023,
                          settings: {
                            slidesToShow: 2,
                          },
                        },
                        {
                          breakpoint: 767,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          },
                        },
                      ],
                    }}>
                      {this.state.fullDataSeller &&
                        this.state.fullDataSeller.total_review && this.state.fullDataSeller.total_review.length>0 &&
                        this.state.fullDataSeller.total_review.map((item) => {
                          return (
                            <div className="review-card-wrap" key={item.id}>
                              <div className="review-card">
                                <div className="prof-img">
                                  <span className="product-review-images" style={{height:"70px"}}>
                                    <img
                                      src={this.state.setUserType == "Buyer"?item.sellerData.user_image:item.buyerData.user_image}
                                      alt="user-prof"
                                      style={{objectFit:"cover"}}
                                    />
                                  </span>
                                  <br />
                                  <span className="name">{this.state.setUserType == "Buyer"?item.sellerData.full_name:item.buyerData.full_name}</span>
                                </div>
                                <div className="review-content">{item.comment}</div>

                                <div className="rating-review-wrap ratings-wrapper seller-review">
                                  <RatingStar value={item.rating} />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                </div>}

                <div>
                  {/* <Slider {...{
                    dots: false,
                    infinite: false,
                    arrows: true,
                    speed: 1000,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    responsive: [
                      {
                        breakpoint: 1023,
                        settings: {
                          slidesToShow: 2,
                        },
                      },
                      {
                        breakpoint: 767,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        },
                      },
                    ],
                  }}>
                    {userData &&
                      userData.review_Data &&
                      userData.review_Data.map((item) => {
                        return (
                          <div className="review-card-wrap" key={item.id}>
                            <div className="review-card">
                              <div className="prof-img">
                                <span className="product-review-images">
                                  <img
                                    src={item.buyerData.user_image}
                                    alt="user-prof"
                                  />
                                </span>
                                <br />
                                <span className="name">{item.buyerData.full_name}</span>
                              </div>
                              <div className="review-content">{item.comment}</div>

                              <div className="rating-review-wrap ratings-wrapper seller-review">
                                <RatingStar value={item.rating} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider> */}
                </div>
                {/* <div className="login-description description-new-wrap upload-desc-wrapper new-upload-wrap">
                  <div className="main-img-wrap">
                    <div className="form-intput input-upload-form">
                      {this.state.selectedImagePan ? (
                        <button
                          onClick={() =>
                            this.setState({ selectedImagePan: null })
                          }
                          className="close-img-btn"
                        >
                          <i className="close-img-icon"></i>
                        </button>
                      ) : null}
                      <div
                        className={`select-edit-wrap ${
                          this.state.disableStatus ? "disabled" : ""
                        }`}
                      >
                        {this.state.selectedImagePan && (
                          <div className="select-img-upload">
                            <img
                              alt="not found"
                              src={URL.createObjectURL(
                                this.state.selectedImagePan
                              )}
                            />
                          </div>
                        )}

                        {this.state.defaultPanImage == "" ? (
                          <>
                            {!this.state.selectedImagePan && (
                              <div className="input-file-upload-wrap">
                                <div className="input-upload">
                                  <div className="file-image">
                                    <img
                                      src="/assets/images/choose-img-icon.svg"
                                      alt="icon"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="input-file-upload-wrap">
                            <div className="input-upload">
                              <div className="file-image">
                                <img
                                  src={this.state.defaultPanImage}
                                  alt="icon"
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <input
                          type="file"
                          className="file-upload-input"
                          name="myImage"
                          onChange={(event) => {
                            this.panImageHandler(event);
                          }}
                          disabled={this.state.disableStatus}
                        />
                      </div>
                      <div className="upload-label-wrap">
                        <label class="upload-label">Pan Card*</label>
                      </div>
                      {this.state.panImageError ? (
                        <span className="error">
                          Please select pan card image
                        </span>
                      ) : null}
                    </div>

                    <div className="form-intput input-upload-form">
                      {this.state.selectedImageGst ? (
                        <button
                          onClick={() =>
                            this.setState({ selectedImageGst: null })
                          }
                          className="close-img-btn"
                        >
                          <i className="close-img-icon"></i>
                        </button>
                      ) : null}
                      <div
                        className={`select-edit-wrap ${
                          this.state.disableStatus ? "disabled" : ""
                        }`}
                      >
                        {this.state.selectedImageGst && (
                          <div className="select-img-upload">
                            <img
                              alt="not fount"
                              width={"250px"}
                              src={URL.createObjectURL(
                                this.state.selectedImageGst
                              )}
                            />
                          </div>
                        )}

                        {this.state.defaultGstImage == "" ? (
                          <>
                            {!this.state.selectedImageGst && (
                              <div className="input-file-upload-wrap">
                                <div className="input-upload">
                                  <div className="file-image">
                                    <img
                                      src="/assets/images/choose-img-icon.svg"
                                      alt="icon"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="input-file-upload-wrap">
                            <div className="input-upload">
                              <div className="file-image">
                                <img
                                  src={this.state.defaultGstImage}
                                  alt="icon"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <input
                          type="file"
                          name="myImage"
                          className="file-upload-input"
                          onChange={(event) => {
                            this.gstImageHandler(event);
                          }}
                          disabled={this.state.disableStatus}
                        />
                      </div>
                      <div className="upload-label-wrap">
                        <label className="upload-label">
                          GST Registration Certificate*
                        </label>
                      </div>

                      {this.state.gstImageError ? (
                        <span className="error">
                          Please select adhaar card image
                        </span>
                      ) : null}
                    </div>
                    <div className="form-intput input-upload-form">
                      {this.state.selectedCINImage ? (
                        <button
                          onClick={() =>
                            this.setState({ selectedCINImage: null })
                          }
                          className="close-img-btn"
                        >
                          <i className="close-img-icon"></i>
                        </button>
                      ) : null}
                      <div
                        className={`select-edit-wrap ${
                          this.state.disableStatus ? "disabled" : ""
                        }`}
                      >
                        {this.state.selectedCINImage && (
                          <div className="select-img-upload">
                            <img
                              alt="not fount"
                              width={"250px"}
                              src={URL.createObjectURL(
                                this.state.selectedCINImage
                              )}
                            />
                          </div>
                        )}

                        {this.state.defaultCinImage == "" ? (
                          <>
                            {!this.state.selectedCINImage && (
                              <div className="input-file-upload-wrap">
                                <div className="input-upload">
                                  <div className="file-image">
                                    <img
                                      src="/assets/images/choose-img-icon.svg"
                                      alt="icon"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="input-file-upload-wrap">
                            <div className="input-upload">
                              <div className="file-image">
                                <img
                                  src={this.state.defaultCinImage}
                                  alt="icon"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <input
                          type="file"
                          name="myImage"
                          className="file-upload-input"
                          onChange={(event) => {
                            this.cinImageHandler(event);
                          }}
                          disabled={this.state.submitDisable}
                        />
                      </div>
                      <div className="upload-label-wrap">
                        <label className="upload-label">CIN</label>
                      </div>
                    </div>
                  </div>
                </div> */}
                {!this.state.submitDisable ? (
                  <>
                    <div className="term-condition">
                      <div className="term-condition-wrapper-box">
                        {/* <input
                              type="checkbox"
                              className="signup-checkbox"
                              checked={this.state.checkbox}
                              onChange={this.checkboxState}
                              onKeyDown={this.handleEnter}
                            /> */}

                        {/* <label htmlFor="vehicle1">
                              {" "}
                              <p>
                                By clicking on submit you accept our{" "}
                                <Link to="/" className="condition-link">
                                  terms & conditions
                                </Link>
                              </p>
                            </label> */}
                        <br />
                      </div>

                      <div className="privacy-error-msz">
                        {error && (
                          <div className="error">
                            Please accept Terms & Conditions and Privacy Policy{" "}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="login-btn-wrapper">
                      <button className="shop-now">Submit</button>
                    </div>
                  </>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signUpUpdate: (payload, completion) =>
      dispatch(signUpUpdate(payload, completion)),
    // getToken:(payload, completion) =>
    //   dispatch(getToken(payload, completion)),
    OTPVerify: (payload, completion) =>
      dispatch(OTPVerify(payload, completion)),
    OTPVerifyEmail: (payload, completion) =>
      dispatch(OTPVerifyEmail(payload, completion)),
    resendSignOTP: (payload, compilation) =>
      resendSignOTP(payload, compilation),
    resendEmailLink: (payload, compilation) =>
      resendEmailLink(payload, compilation),
    loginRequest: (payload, completion) =>
      dispatch(loginRequest(payload, completion)),
    socialLogin: (payload, completion) =>
      dispatch(socialLogin(payload, completion)),
    showSignupErr: (payload) => dispatch(showSignupErr(payload)),
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
    getSellerDEtail: (callback) => dispatch(getSellerDEtail(callback)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  showLoginPopup: state.generalInfo.showLoginPopup,
  status: state.userInfo.SignupError.status,
  errData: state.userInfo.SignupError.errData,
  userDataDEtail: state.userDataDEtail,
});
export default withRouter(
  withToast(connect(mapStateToProps, mapDispatchToProps)(BasicInfo))
);

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}
