import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getProductListCat } from "../../store/actions/productListing";
import { useDispatch } from "react-redux";
import ProductScreen from "../ProductListing/ProductScreen";
import Filter from "../Profile/SellerProfile/Filter/Filter";
import SortBy from "./sortBy";
import { object } from "yup";
import Loader from "../Loader";
import BreadCrumb from "../ProductDetail/BreadCrumb";

function categoriesProduct() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [category, setCategory] = useState(
    location.state && location.state.categorie
  );
  const [products, setProducts] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [stateData, setStateDate] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [loaderMessage, setloaderMessage] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [penDiv, setOpenDiv] = useState(true);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    setShowLoader(true);
    setloaderMessage("Loading...");
    getFilter();
  }, [location]);

  const getFilter = () => {
    let obj;
    if (Object.keys(stateData).length != 0) {
      var payload = location.state;
    } else if (location.state) {
      var payload = {
        category: location.state && location.state.categorie,
      };
    } else {
      var payload = location.state;
    }
    getProductListCat({ ...payload, allowCSP: true }, (status) => {
      setFilterData(status);
      setProducts(status.data);
      setShowLoader(false);
    })(dispatch);
  };

  const handleFilterData = (data) => {
    setStateDate(data);
  };

  const closeSmallScreenFilter = () => {
    setCloseModal(true);
    setOpenDiv(false);
  };
  const openModalFilter = () => {
    setCloseModal(false);
    setOpenDiv(true);
  };
  return (
    <div className="filter-product-listing-wrapper">
      {showLoader ? <Loader message={loaderMessage} /> : null}
      <div className="filter-select-wrap">
        {/* <BreadCrumb details={products}/> */}
        <SortBy filterData={filterData} stateData={stateData} />
      </div>
      <h3 className="filter-select-head" onClick={openModalFilter}>
        Filter By
      </h3>
      <div className="product-filter-wrap mainlisting-wrap">
        {matches ? (
          <Filter filterData={filterData} handleFilterData={handleFilterData} />
        ) : (
          <Filter
            filterData={filterData}
            handleFilterData={handleFilterData}
            closeSmallScreenFilter={closeSmallScreenFilter}
            closeModal={closeModal}
          />
        )}

        <ProductScreen
          products={products}
          filter_data={filterData}
          getFilter={getFilter}
        />
      </div>
    </div>
  );
}

export default categoriesProduct;