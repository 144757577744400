import React, { Component } from 'react'
import "../ProductDetail/ProductDetails.css";
import ReactImageMagnify from "react-image-magnify";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { RatingStar } from "../ProductDetail/productReview";
// import DeliveryStep from '../OrderDetails/deliveryStep';

export default class OrderProcessDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedImage: "",
        };
    }
    imageProps = () => {
        return {
            smallImage: {
                alt: "img",
                width: 200,
                height: 200,
                src: this.state.selectedImage,
            },
            largeImage: {
                src: this.state.selectedImage,
                width: 800,
                height: 800,
            },
            enlargedImageContainerStyle: { background: "#fff", zIndex: 9 },
        };
    };
    render() {
        return (
            <div className="slide-box-wrap order-detail-slide-box">
                {/* <DeliveryStep
                    allSteps={allSteps}
                    completed={orderdetail.order_status_code}
                    currentOrderStatus={orderStatus}
                /> */}
                <div className="pro-box-wrapper product-detail-box">
                    <div className="pro-slide-wrap">
                        <div className="product-view product-view-wrapper">
                            {this.imageProps().smallImage.src !== "" ? (
                                <div className="main-item product-image-page">
                                    {
                                        <ReactImageMagnify
                                            {...this.imageProps()}
                                            imageStyle={{
                                                objectFit: "contain",
                                                objectPosition: "center",
                                                maxWidth: 200,
                                                minWidth: 200,
                                                minHeight: 200,
                                                maxHeight: 200,
                                            }}
                                            imageClassName="product-fitimage"
                                        />
                                    }
                                </div>
                            ) : (
                                <SkeletonLoader
                                    width="90%"
                                    height="300px"
                                    style={{ margin: "5%" }}
                                />
                            )}
                        </div>
                    </div>
                    {/* //////// Sticky Div End //////// */}
                    <div className="des-wrap product-description">
                        <div className="sales-details sales-detail-wrap">
                            <div className="product-head-wrap">
                                <div className="head-star-wrap">
                                    <div className="ltl-wrap">
                                        <h2>ABC Industries</h2>
                                        <h3>MC Silicomagnese 60/14</h3>
                                    </div>
                                    <div className="rtl-wrap">
                                        <RatingStar />
                                    </div>
                                </div>
                                <div className="costume-box-wrapper product-costume-wrap">
                                    <div className="box-wrp">
                                        <span className="name">Type:</span>
                                        <span className="value">Lumps</span>
                                    </div>
                                    <div className="box-wrp">
                                        <span className="name">Size:</span>
                                        <span className="value">0-3 mm (Jiggs)</span>
                                    </div>
                                    <div className="box-wrp">
                                        <span className="name">Tolerance:</span>
                                        <span className="value">01%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="product-tble-wrp product-table-seller">
                                <table>
                                    <tr>
                                        <th>Mn</th>
                                        <th>60%</th>
                                        <th>C</th>
                                        <th>0.5%</th>
                                    </tr>
                                    <tr>
                                        <td>Si</td>
                                        <td>14 min</td>
                                        <td>Si</td>
                                        <th>0.1%</th>
                                    </tr>
                                    <tr>
                                        <td>P</td>
                                        <td>0.1% max</td>
                                        <td colspan="2"></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="costume-box-wrapper product-costume-wrap costume-product-qty">
                                <div className="box-wrp">
                                    <span className="name">Qty Available (in mt) :</span>
                                    <span className="value">500</span>
                                </div>
                                <div className="box-wrp">
                                    <span className="name">Delivery Window :</span>
                                    <span className="value">Jun 30 - Jul 15</span>
                                </div>
                                <div className="box-wrp">
                                    <span className="name">M.O.Q. (in mt) :</span>
                                    <span className="value">25</span>
                                </div>
                                <div className="box-wrp">
                                    <span className="name">Delivery Location :</span>
                                    <span className="value">Ahemdabad, Gujrat</span>
                                </div>
                                <div className="box-wrp">
                                    <span className="name">Packaging :</span>
                                    <span className="value">40 kg Jute Bags</span>
                                </div>
                                <div className="box-wrp">
                                    <span className="name">Loading Charges (PMT) :</span>
                                    <span className="value">&#8377;  10000</span>
                                </div>
                                <div className="box-wrp">
                                    <span className="name">Payment Terms :</span>
                                    <span className="value">25% Advance</span>
                                </div>
                            </div>
                        </div>
                        <div className="offer-price-box">
                            <h3>Offer Price</h3>
                            <h4>&#8377; 10000 <span className="offer-unit">/mt</span></h4>
                            <span className="gst-tax">excluding GST & Taxes </span>
                            <div className="btn-wrapper">
                                <button className="offer-btn-wrp">Buy Now</button>
                                <button className="make-offer">Make Offer</button>
                            </div>
                            {/* <span className="offer-expiry">This offer will expire at 08:00 pm</span> */}
                        </div>

                        {/* <div className='terms-condition-new'>
                            <h3>Terms of Offer</h3>
                            <ul className='terms-new-wrap'>
                                <li>Advance payment to be made in full before the product is loaded for delivery</li>
                                <li>Payment to be made in full within 24 hrs of receipt of Proforma invoice</li>
                                <li>Advance amount will be applied to the order in a pro rata basis where the product is delivered in multiple smaller quantities to fulfill the order.</li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="update_wrapper_box">
                    <div className="updt-info-wrp">

                    </div>
                </div>
            </div>
        )
    }
}
