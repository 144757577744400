import React, { useEffect } from 'react';
import { getCountry } from "../../../store/actions/sellItem";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getUserAddress, saveAddress, updateAddress } from "../../../store/actions/profile";
import Loader from '../../Loader';
import { useToasts } from "react-toast-notifications";


const schema = yup.object().shape({
    first_name: yup
        .string()
        .required("Please enter first name")
        .min(2, "First name must be at least 2 characters")
        .max(30, "First name must be at most 30 characters")
        .matches(
            /^[A-Za-z\s]+$/i,
            "Alphabetical characters only"
        ),
    last_name: yup
        .string()
        .required("Please enter last name")
        .min(2, "Last name must be at least 2 characters")
        .max(30, "Last name must be at most 30 characters")
        .matches(
            /^[A-Za-z\s]+$/i,
            "Alphabetical characters only"
        ),

    email: yup
        .string()
        .required("Please enter your email address")
        .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            "Please use correct email"
        ),
    phone: yup
        .string()
        .required("Please enter mobile")
        .matches(/^[0-9]*$/, "Numeric value only")
        .min(10, "Mobile number is not valid")
        .max(10, "Mobile number is not valid"),
    flat_no: yup
        .string()
        .required("Please enter flat number"),
    street: yup
        .string()
        .required("Please enter Street"),
    postcode: yup
        .string()
        .required("Please enter pin code")
        .matches(/^[0-9]*$/, "Numeric value only")
        .min(6, "Post code must be 6 digits")
        .max(6, "Post code must be 6 digits"),
    country: yup
        .string(),
    state: yup
        .string()
        .required("Please select state"),
    city: yup
        .string()
        .required("Please enter city")
        .min(2, "City must be at least 2 characters")
        .max(20, "City must be at most 20 characters")
        .matches(
            /^[A-Za-z\s]+$/i,
            "Alphabetical characters only"
        ),
    makeDefault: yup
        .bool(),
    // .oneOf([true], "Please select default address"),
    addressType: yup
        .string()
        .required("Please select address type")
});


const AddAddress = ({ setShowAddr, addId, getShippingCost, manageAddress, uAddress }) => {
    let dispatch = useDispatch();
    const { addToast } = useToasts();
    const [getPinCode, setGetPinCode] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);
    let countryList = useSelector(store => store.sellItemInfo.countryList);
    let addressList = useSelector(store => store.userProfile.userAddress)




    const { register, formState: { errors }, handleSubmit, reset, getValues, setValue } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });


    useEffect(() => {
        getCountry()(dispatch)
    }, []);


    useEffect(() => {
        if (addId) {
            setTimeout(() => {
                addressList.forEach(item => {
                    if (item._id === addId) {
                        setValue('first_name', item.first_name)
                        setValue('last_name', item.last_name)
                        setValue('email', item.email)
                        setValue('phone', item.phone)
                        setValue('flat_no', item.flat_no)
                        setValue('street', item.street)
                        setValue('landmark', item.landmark)
                        setValue('postcode', item.postcode)
                        setValue('country', item.country[0]._id)
                        setValue('city', item.city)
                        setValue('state', item.state[0]._id)
                        setValue('addressType', item.type)
                        setValue('makeDefault', item.is_default)
                        reset({
                            keepValues: true,
                            keepErrors: false
                        })
                    }
                })
            }, 500)
        }
    }, [addId]);

    const onSubmit = (data) => {
        if (addId) {
            setShowLoader(true);
            data.addId = addId;
            updateAddress(data, (callback) => {
                if (callback.status === "success") {
                    uAddress();
                    setShowLoader(false);
                    addToast(callback.message, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                    window.scrollTo(0, 0);
                } else {
                    setShowLoader(false);
                    addToast(callback.message, {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                }
            })(dispatch)
            setTimeout(() => setShowAddr(false), 500);
            if (!manageAddress) {
                getShippingCost(addId, 'load')
            }

        } else {
            setShowLoader(true);
            saveAddress(data, (callback) => {
                if (callback.status === "success") {
                    uAddress();
                    setShowLoader(false);
                    addToast(callback.message, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                    window.scrollTo(0, 0);
                } else {
                    setShowLoader(false);
                    addToast(callback.message, {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                }
            })(dispatch)
            setTimeout(() => {
                setShowAddr(false)
                getUserAddress({}, (status) => {

                })(dispatch);
            }, 500)

        }
    }

    // const minMaxLength = (e) => {
    //     if (e.target.value.length <= 6) {
    //         setGetPinCode(e.target.value);
    //     }
    // }

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="addressForm">
            {showLoader ? <Loader message="Please wait..." /> : null}
            <div className="desc-txt-wrap">
                <div className="desc-txt edit-address address-edit-wrp">
                    <div className="edit-wrapper">
                        <label className="edit-label">
                            First Name<span className="highlighted">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="First name"
                            {...register("first_name")}
                            maxlength="30"
                        />
                        {errors.first_name && (
                            <span className="error">
                                {errors.first_name.message}
                            </span>
                        )}

                    </div>
                    <div className="edit-wrapper">
                        <label className="edit-label">
                            Last Name<span className="highlighted">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Last name"
                            {...register("last_name")}
                            maxlength="30"
                        />
                        {errors.last_name && (
                            <span className="error">
                                {errors.last_name.message}
                            </span>
                        )}
                    </div>

                    <div className="edit-wrapper">
                        <div className='label-wrapper'>
                            <label className="edit-label">
                                Email<span className="highlighted">*</span>
                            </label>
                            {/* <button className='forget-email-btn'>Forgot password</button> */}
                        </div>
                        <input
                            type="email"
                            placeholder="Email"
                            {...register("email")}
                            maxlength="100"
                        />
                        {errors.email && (
                            <span className="error">
                                {errors.email.message}
                            </span>
                        )}
                    </div>
                    <div className="edit-wrapper phone-number-edit-block">
                        <label className="edit-label">
                            Mobile Number<span className="highlighted">*</span>
                        </label>
                        <div className='number-code-wrapper edit-address-wrp address-mobile-phone'>
                            {/* <span className="number-code">
                                +91
                            </span> */}
                            <input
                                type="text"
                                value="+91"
                                disabled
                                className="number-code"
                            />
                            <input
                                type="text"
                                placeholder="Mobile number"
                                {...register("phone")}
                                maxlength="10"
                            />
                        </div>
                        {errors.phone && (
                            <span className="error add-address-phone-error">
                                {errors.phone.message}
                            </span>
                        )}
                    </div>
                    <div className="edit-wrapper">
                        <label className="edit-label">Flat Number<span className="highlighted">*</span></label>
                        <input
                            type="text"
                            maxLength={10}
                            placeholder="Flat number"
                            {...register("flat_no")}
                            maxlength="100"
                        />
                        {errors.flat_no && (
                            <span className="error">
                                {errors.flat_no.message}
                            </span>
                        )}
                    </div>
                    <div className="edit-wrapper">
                        <label className="edit-label">Street<span className="highlighted">*</span></label>
                        <input
                            type="text"
                            placeholder="Street"
                            {...register("street")}
                            maxlength="100"
                        />
                        {errors.street && (
                            <span className="error">
                                {errors.street.message}
                            </span>
                        )}
                    </div>
                    <div className="edit-wrapper">
                        <label className="edit-label">Landmark</label>
                        <input
                            type="text"
                            placeholder="Landmark"
                            {...register("landmark")}
                            maxlength="100"
                        />
                    </div>
                    <div className="edit-wrapper">
                        <label className="edit-label">
                            Pin Code<span className="highlighted">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Pin code"
                            {...register("postcode")}
                        />
                        {errors.postcode && (
                            <span className="error">
                                {errors.postcode.message}
                            </span>
                        )}
                    </div>
                    <div className="edit-wrapper">
                        <label className="edit-label">
                            Country<span className="highlighted">*</span>
                        </label>
                        <div className="select-state">
                            <select {...register("country")}>
                                <option value={""} className="options">Please select country</option>
                                {
                                    countryList && countryList.map(item =>
                                        <option selected value={item._id} className="options">{item.name}</option>
                                    )
                                }
                            </select>
                            {errors.country && (
                                <span className="error">
                                    {errors.country.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='edit-wrap'>
                        <div className="edit-wrapper edit-city-wrap">
                            <label className="edit-label">
                                Town/City<span className="highlighted">*</span>
                            </label>
                            <input
                                type="text"
                                placeholdeFr="City"
                                {...register("city")}
                                maxlength="50"
                            />
                            {errors.city && (
                                <span className="error">
                                    {errors.city.message}
                                </span>
                            )}
                        </div>
                        <div className="edit-wrapper">
                            <label className="edit-label">
                                State<span className="highlighted">*</span>
                            </label>
                            <div className="select-state">
                                <select {...register("state")}>
                                    <option value={""}>Please select state</option>
                                    {
                                        countryList &&
                                        countryList[0] &&
                                        countryList[0].stateData.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }
                                </select>
                                {errors.state && (
                                    <span className="error">
                                        {errors.state.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='default-add default-add-block'>
                        <div className='radio-wrap'>
                            <input type="checkbox" {...register("makeDefault")} className="checkbox" />
                            <span className='custom'></span>
                        </div>
                        <label for="vehicle1 vechicle-checkbox"> Make this my default address</label>
                    </div>
                    {errors.makeDefault && (
                        <span className="error">
                            {errors.makeDefault.message}
                        </span>
                    )}

                    <div className="edit-wrapper">
                        <label className="edit-label">Address Type<span className="highlighted">*</span></label>
                        <div className="select-state">
                            <select  {...register("addressType")}>
                                <option value="">Select an address type</option>
                                <option value="Home">Home (7 am - 9 pm delivery)</option>
                                <option value="Office">Office (10 am - 6 pm delivery)</option>
                            </select>
                            {errors.addressType && (
                                <span className="error">
                                    {errors.addressType.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="desc-txt edit-address">
                        <div className="btns-wrapping">
                            <button onClick={() => setTimeout(() => setShowAddr(), 10)} className="complete-purchase purchase-address cancel-purchase">
                                Cancel{" "}
                            </button>
                            <button className="complete-purchase purchase-address" type="submit">
                                {addId ? "Update address" : "Add address"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}


export default AddAddress;