import {
  SET_PRODUCT_DETAIL,
  SET_RELATED_PRODUCTS,
  SET_QA_LIST,
  SET_QUES,
  SET_PRODUCT_REVIEW_LIST,
  SET_PRODUCT_OWNER,
  SET_MORE_PRODUCT_FROM_THIS_SELLER,
  SET_REVIEW_ON_PRODUCT,
  GET_FOLLOWER
  // SET_ANS
} from "../actionsTypes";

const initialState = {
  qalist: [],
  reviewList: [],
  productOwner: {},
  moreProductFromSeller: {},
  relatedProduct: {},
  productReview: {}
};

const setQues = (state, payload) => {
  let newData = [...state.qalist];
  newData.push(payload);
  return { ...state, qalist: newData };
};

const productDetails = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_DETAIL:
      return { ...state, details: action.payload };
    case SET_RELATED_PRODUCTS:
      return { ...state, relatedProduct: action.payload };
    case SET_QA_LIST:
      return { ...state, qalist: action.payload };
    case SET_QUES:
      return setQues(state, action.payload);
    case SET_PRODUCT_REVIEW_LIST:
      return { ...state, reviewList: action.payload };
    case SET_PRODUCT_OWNER:
      return { ...state, productOwner: action.payload };
    case SET_MORE_PRODUCT_FROM_THIS_SELLER:
      return { ...state, moreProductFromSeller: action.payload };
    case SET_REVIEW_ON_PRODUCT:
      return { ...state, productReview: action.payload };
      case GET_FOLLOWER:
      return { ...state, followerData: action.payload };
    default:
      return state;
  }
};
export default productDetails;
