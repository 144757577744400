import React, { useState, useEffect } from "react";
import NotifyMessage from "./notifyMessageIcon";
import ProfileDropDown from "./profileDropdown";
import LoginOption from "./loginOption";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
import { get } from "lodash";
import LocationEditPopup from "../../../PopupFolder/locationEditpopup";
import { checkUserActiveOrNot, getCityByZip, setLoginPopupStatus } from "../../../store/actions/common";
import { Authorization } from "../../../utils/Authorization";
import { useToasts } from "react-toast-notifications";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HeaderCategory from "../HeaderCategory";


const mapApi = "AIzaSyCGrtkBlGlz9rRx0fjGAn5URGEw_1uV6Ak";


const MainHeader = (props) => {
  const history = useHistory();
  const userLocation = useSelector((state) => state.generalInfo.userLocation);
  const location = useLocation();
  const dispatch = useDispatch();
  const [editLocation, setEditLocation] = useState(false);
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);
  let query = parse(location.search);
  const { addToast } = useToasts();
  const [searchBox, setsearchBox] = useState(false)
  const [sideMenu, setSideMenu] = useState(false)

  useEffect(() => {
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      checkUserActiveOrNot()
    }
    if (query.type === "delivery") {

      getCityByZip(query.deliveryZipCode, (status) => {
        if (status.status === "Error") {
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })(dispatch)

    }
  }, [location])

  useEffect(()=>{
    setSideMenu(false)
  },[window.location.href])

  useEffect(() => {
    if (localStorage.getItem("incomplete") == "false") {
      history.push(
        `/profile/businessProfile`
      )
    }
  }, [])


  /* const localSearchEdit = (searchQ) => {
    setEditLocation(false);
    history.push(
      `/product-listing-page/${"Local"}?${searchQ}`
    )
  } */

  const sellRegistor = () => {
    history.push("/sell-product");
  }

  const sellRegistorlog = () => {
    props.setLoginPopupStatus({ status: true, screenToShow: "login" })
    //history.push("/sellerRegistration");
  }
  const openSearch = () => {
    setsearchBox(!searchBox);
  }
  return (
    <div className={`header-login ${searchBox ? "search-active" : ""}`}>
      <div className="container">
        <div className="login">
          <div className="logo-class logo-class-wrapper" onClick={props.goToHome}>
            <img
              src={props.logo ? props.logo : "/assets/images/metal-buy-logoo.png"}
              alt="MetalsBuy"
            />
          </div>
          <button class="mob-menu" onClick={() => { setSideMenu(true) }}>
            <span class="menu-bar"></span>
            <span class="menu-bar"></span>
            <span class="menu-bar"></span>
          </button>
          <div className={`header-content-wrap ${sideMenu ? "active" : ""}`}>
            <div className="logo-class logo-class-wrapper inner-menu-logo" onClick={props.goToHome}>
              <img
                src={props.logo ? props.logo : "/assets/images/metal-buy-logoo.png"}
                alt="logo"
              />
            </div>
            <button onClick={() => { setSideMenu(false) }} className="header-close-wrap"><i className="close-icon"></i></button>
            {props.children}
            {/*  <div className="header-address header-address-wrapper" onClick={() => props.openLocationPopup({ locationPopup: true })}>
            <div className="product-location-wraps product-location-main-wrp">
              <div className="product-location">
                <div className="location-mark">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="location-text">
                    <div className="deliver-text">Deliver to</div>
                    <div className="wrap-delivery">
                      <div className="delivery-text">
                        {
                          query.type === "delivery"
                            ? get(userLocation, ["cityArr", [0]], "") + " " + query.deliveryZipCode
                            : "Select location"
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="signup-register">
              {<>
                {props.isLoggedIn && (
                  <NotifyMessage
                    notificationCount={props.notificationCount}
                    messageMenu={props.messageMenu}
                    setMessageMenu={props.setMessageMenu}
                  />
                )}
                <div>
                  {/* {props.isLoggedIn ? (
                  <>
                    <a className="shop-now register-seller register-as-seller" onClick={sellRegistor}>{props.userData && props.userData.verified === "verified" ?
                      'Sell with us' : 'Sell with us'}</a>

                  </>
                ) : (
                  <a className="shop-now register-seller sell-with-us-mob" onClick={sellRegistorlog}>Sell with us</a>
                )} */}

                  {/* {props.isLoggedIn ?
                    <Link
                      to="/my-cart"
                      onClick={props.setValue}
                      className="wish-list msg-number wishlist-ic-wrap"
                      data-count={props.cartLength !== 0 ? props.cartLength : null}
                    >
                      <div className="tooltip">Cart</div>
                      <i className="shopping-cart-icon"></i>
                      <span className="cart-value">{props.cartLength !== 0 ? props.cartLength : null}</span>
                    </Link> : null
                  } */}



                </div>

                {props.isLoggedIn ? (
                  <ProfileDropDown
                    userName={props.userName}
                    setValue={props.setValue}
                    logout={props.logout}
                    userProfileBanner={props.userProfileBanner}
                  />
                ) : (
                  <LoginOption registerOpen={props.registerOpen} />
                )}

              </>}
            </div>
          </div>
          {/* <button className="search-btn-mob" onClick={openSearch}><i className="search-btn-icon"></i></button> */}
        </div>
      </div>
      {/*  {editLocation ? (
        <LocationEditPopup
          closePopup={() => setEditLocation(false)}
          contShopping={(searchQuery) => localSearchEdit(searchQuery)}
        />
      ) : null} */}

    </div>
  );
};


const mapStateToProps = (state, ownProps) => ({
  brands: state.productListingInfo.brands
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);


