import { Stop } from "@react-pdf/renderer";
import React, { useState } from "react";

const StopWatch = () => {
    const [time, setTime] = useState({ ms: 0, s: 0, m: 0, h: 0 });
    const [interve, setInterve] = useState();
    const [status, setStatus] = useState(0);
    let updatems = time.ms;
    let updates = time.s;
    let updatem = time.m;
    let updateh = time.h;

    const run = () => {
        if (updatems === 100) {
            updates++;
            updatems = 0;
        } if (updates === 60) {
            updatem++;
            updates = 0;
        } if (updatem === 60) {
            updateh++;
            updatem = 0;
        }
        updatems++;
        return setTime({ ms: updatems, s: updates, m: updatem, h: updateh })
    }

    const start = () => {
        setStatus(1);
        run();
        setInterve(setInterval(run, 10));
    }

    const stop = () => {
        setStatus(2);
        clearInterval(interve);
    }

    const reset = () => {
        setStatus(0);
        clearInterval(interve);
        setTime({ ms: 0, s: 0, m: 0, h: 0 })
    }

    const resume = () => {
        start();
    }
    return (
        <>
            <span>{time.h >= 10 ? time.h : `0${time.h}`}</span> &nbsp; : &nbsp;
            <span>{time.m >= 10 ? time.m : `0${time.m}`}</span> &nbsp; : &nbsp;
            <span>{time.s >= 10 ? time.s : `0${time.s}`}</span> &nbsp; : &nbsp;
            <span>{time.ms >= 10 ? time.ms : `0${time.ms}`}</span> &nbsp; &nbsp;

            {status == 0 ?
                <button onClick={() => start()}>Start</button> : null
            }

            {status == 1 ?
                <>
                    <button onClick={() => stop()}>Stop</button> &nbsp; &nbsp;
                    <button onClick={() => reset()}>Reset</button>
                </>

                : null
            }

            {status == 2 ?
                <>
                    <button onClick={() => resume()}>Resume</button> &nbsp; &nbsp;
                    <button onClick={() => reset()}>Reset</button>
                </>

                : null
            }

        </>
    )
}

export default StopWatch;