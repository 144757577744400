import React from "react";
import Menu from "react-burger-menu/lib/menus/slide";
import { connect, useDispatch } from "react-redux";
import { getMessageList } from "../../store/actions/chatAction";
import { isEmpty } from "lodash";
import { timeFunction } from "./timeFunction";
import { Link } from "react-router-dom";
import { getTotalUnreadMsgAndWishlistItem } from "../../store/actions/common";



const RightMenu = (props) => {
  const [userId] = React.useState(localStorage.getItem("userId"))
  let dispatch = useDispatch()
  React.useEffect(() => {
    props.loadMsg({ tab: "All" });
  }, []);

  const handleMessageTab = () => {
    props.changeState();

    getTotalUnreadMsgAndWishlistItem(callback => {


    })(dispatch)

  }
  return (
    <div id="outer-container-right-menu" className=" msg-home" style={{ height: "100%" }}>
      <Menu
        width={335}
        right
        isOpen={props.menuOpen}
        pageWrapId={"page-wrap-right-menu"}
        outerContainerId={"outer-container-right-menu"}
        onClose={props.changeState}
        id={"sidebar-menu"}
      >
        <div className="right-menu-header message">
          <h2>Messages</h2>
          <i className="fa fa-times" onClick={props.changeState} />
        </div>

        {!isEmpty(props.msgListObj) ? (
          props.msgListObj.data.length > 0 ? (
            <main
              id="page-wrap-right-menu" className="msg-popup"
              style={{ height: "100%" }}
            >
              {props.msgListObj.data.slice(0, 9).map((data, index) => {
                return (
                  <Link
                    to={{
                      pathname: "/chats/all",
                      state: { chatPayload: { sender_id: data.sender_id, product_id: data.product_id } }
                    }}
                    key={index}
                    onClick={() => handleMessageTab()}
                    className="msg-card contacts"

                  >
                    {/* /assets/images/avtar.png */}
                    <div>
                      <img
                        src={
                          data.receiver_id === userId
                            ? (data.sender_image ? data.sender_image : "/assets/images/avtar.png")
                            : (data.receiver_image ? data.receiver_image : "/assets/images/avtar.png")
                        }
                        alt={data.sender_name}
                        className="sender-image"
                      />
                    </div>
                    <div>
                      {
                        data.sender_id === props.userId ?
                          <strong className="msg-list-one-line">You: {data.user_message}</strong> :
                          <strong className="msg-list-one-line">{data.user_message}</strong>
                      }
                      <span>{data.sender_id === props.userId ? data.receiver_name : data.sender_name}</span>
                    </div>

                    <small>{timeFunction(new Date(data.createdAt))}</small>
                  </Link>
                );
              })}
            </main>
          ) : (
            <main
              id="page-wrap-right-menu"
              className="page-wrap-right-msg"
              style={{ height: "100%", overflow: "auto" }}
            >
              <div className="no-msg-find">
                <img src="../../../assets/images/chat_empty.svg" alt="" />
                No messages yet</div>
            </main>
          )
        ) : null}

        {!isEmpty(props.msgListObj) && props.msgListObj.data.length > 0 && (
          <div className="right-menu-header view-btn">
            <Link
              to={{
                pathname: "/chats/all",
                state: { tab: "All" }
              }}
              onClick={props.changeState}>
              See all Messages
            </Link>
          </div>
        )}
      </Menu>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    msgListObj: state.chatModule.messageListObj,
    getsender: state.chatModule.conversation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadMsg: (tab) => dispatch(getMessageList(tab)),
    getTotalUnreadMsgAndWishlistItem: (payload) => dispatch(getTotalUnreadMsgAndWishlistItem(payload)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
