import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { ButtonLoader, RupeesIcon } from "../../../../utils/constants";
import "../../../ProductDetail/ProductDetails.css";
import moment from "moment";
import InputReason from "./inputReason";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";

const ListingProduct = (props) => {
  let location = useLocation();
  let history = useHistory();

  const data = useSelector((state)=>state)
  console.log("data111",data)

  const [showInputForm, setInpuForm] = useState(false);
  const [orderId, setOrderId] = useState(""); /// for delete or reject purpose
  const [orderType, setOrderType] = useState(""); /// for delete or reject purpose
  const [buttonLoader, setButtonLoader] = React.useState(false);

  const loadMore = () => {
    setButtonLoader(true);
    props.loadMore({
      page: props.myProduct.page + 1,
      type: props.type,
    });
    setTimeout(() => {
      setButtonLoader(false);
    }, 700);
  };

  const getPopupStatus = (getItem) => {
    props.setReviewPopup(true);
    localStorage.setItem("ReviewProductId", getItem.product_id);
  };

  const timeBy = (data) => {
    return moment(data).calendar({
      sameDay: "DD/MM/YYYY h:mm a",
      nextDay: "DD/MM/YYYY h:mm a",
      nextWeek: "DD/MM/YYYY h:mm a",
      lastDay: "DD/MM/YYYY h:mm a",
      lastWeek: "DD/MM/YYYY h:mm a",
      sameElse: "DD/MM/YYYY h:mm a",
    });
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };
  const renderer1 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    props.loadMore({
      page: 1,
      type: props.type,
    });
  }, [props.type]);

  const getClass = (data) => {
    if (data === "Completed") return "confirm";
    else if (data === "Processed") return "process";
    else if (data === "Cancelled") return "cancel";
    // else if (data === "Returned") return "return";
    else return "";
  };

  const goTodetailPage = (e, payload) => {
    console.log("location.state", location.state, payload);
    e.preventDefault();
    if (props.type !== "Cancelled") {
      history.push({
        pathname: `/profile/myorder/order-detail/${payload.orderId}/${props.type}`,
        state: { ...location.state, payload },
      });
    } else {
      return;
    }
  };

  console.log("props.myProduct.productCount", props);

  return (
    <div className="plp-product-screen">
      {showInputForm && (
        <InputReason
          closePopup={() => setInpuForm(false)}
          reasonList={props.reasonList}
          submitFun={(reason, array) => {
            if (props.type === "Completed") {
              props.returnProduct(
                { orderId, reason: reason, validReasons: array, orderType },
                {
                  page: 1,
                  type: props.type,
                }
              );
            } else if (props.type === "Processed" || props.type === "Pending") {
              props.cancelProduct(
                { orderId, reason: reason, validReasons: array, orderType },
                {
                  page: 1,
                  type: props.type,
                }
              );
            }
            setTimeout(() => setInpuForm(false), 1000);
          }}
          content={`Please select the reason for ${
            props.type === "completed" ? " return" : "cancel"
          }`}
          formAction={
            props.type === "Completed"
              ? "return"
              : props.type === "Processed"
              ? "cancel"
              : props.type === "Pending"
              ? "cancel"
              : null
          }
        />
      )}
      {!isEmpty(props.myProduct) &&
        props.myProduct.product.length > 0 &&
        props.myProduct.product.map((item, i) => {
          const userRating = item.reviewData[0] ? item.reviewData[0].rating : 0;

          const payload = {
            orderId: item._id,
            //productId: item.product_detail._id
            type: props.type,
          };

          return (
            <>
              <div
                className="buy-prod buy-product-wrapper order-process-wrapper"
                key={i}
              >
                <div className="inner-product-detail-wrap new-list-wrapper">
                  <div className="est-deliveries order-east-deliveries">
                    <div
                      className="prod-img order-product-img"
                      style={{ width: "148px", height: "148px" }}
                    >
                      <Link
                        to={`/profile/myorder/order-detail/${payload.orderId}/${props.type}`}
                        onClick={(e) => goTodetailPage(e, payload)}
                        style={{ width: "148px", height: "148px" }}
                      >
                        <img
                          src={item.product_detail.default_image}
                          alt="img"
                        />
                      </Link>
                    </div>

                    {/* <div className="delivery-time">
                  {
                    item.orderData && item.orderData.localOrderType !== "freePickup" && props.type !== "Cancelled" &&
                    <div className="est-delivery estimated-delivery-time">Estimated delivery {
                      moment(item.delivery_date_from).calendar({
                        sameDay: 'DD/MM/YYYY h:mm a',
                        nextDay: 'DD/MM/YYYY h:mm a',
                        nextWeek: 'DD/MM/YYYY h:mm a',
                        lastDay: 'DD/MM/YYYY h:mm a',
                        lastWeek: 'DD/MM/YYYY h:mm a',
                        sameElse: 'DD/MM/YYYY h:mm a'
                      })
                    } to {
                        moment(item.delivery_date_to).calendar({
                          sameDay: 'DD/MM/YYYY h:mm a',
                          nextDay: 'DD/MM/YYYY h:mm a',
                          nextWeek: 'DD/MM/YYYY h:mm a',
                          lastDay: 'DD/MM/YYYY h:mm a',
                          lastWeek: 'DD/MM/YYYY h:mm a',
                          sameElse: 'DD/MM/YYYY h:mm a'
                        })
                      }
                    </div>
                  }
                </div> */}
                  </div>

                  <div className="prod-detail product-orderinfo product-info-detail-wrap">
                    <Link
                      className="prod-link prod-new-link"
                      key={i}
                      to="#"
                      onClick={(e) => goTodetailPage(e, payload)}
                    >
                      {/* <div className="prod-title product-switch">
                    <span className="prod-text bold head">
                      {item.brandData[0].name}
                    </span>
                    <span className="prod-text sub-head">
                      {item.product_detail.Title}
                    </span>
                  </div> */}

                      <div className="product-box-wrapper sub-detail-order">
                        <div className="home-detail qty-pricing">
                          {/* <div className="home-details">
                            <span className="prod-text bold name">
                              {item.sellerData[0].companyName}&nbsp;
                            </span>
                          </div> */}
                          <div className="home-details">
                            <span className="prod-text bold sub-name">
                              {item.product_detail.productName}{" "}
                              {item.product_detail.productGrade}&nbsp;
                            </span>
                          </div>
                          <div className="home-details">
                            <span className="prod-text bold sub-name">
                              Quantity:&nbsp;
                            </span>
                            <span className="prod-text value">
                              {item.order_item_qty}mt
                            </span>
                          </div>
                          <div className="home-details">
                            <span className="prod-text bold sub-name">
                              Price (PMT):&nbsp;
                            </span>
                            <span className="prod-text value">
                              ₹{item.offerDetails ? item.offerDetails.final_offer_price: item.unit_price}
                            </span>
                          </div>
                          <div className="pro-price">
                            <span className="prod-text bold sub-name">
                              Total Price:&nbsp;
                            </span>
                            <span className="prod-text value">
                              {RupeesIcon}
                              {item.total_order_amount}
                            </span>
                          </div>
                        </div>
                        <div className="home-detail qty-pricing">
                          <div className="home-details">
                            <span className="prod-text bold sub-name">
                              Seller name:&nbsp;
                            </span>

                            <span className="prod-text value">
                              {/* {item.seller_details.full_name} */}
                              {item.sellerData[0] && item.sellerData[0].companyName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="prod-title prod-toggle rtl-delivery-information delivery-details-right">
                    <div className="del-details my-del product-detail-new inprogress-wrp">
                      <Link
                        key={i}
                        to="#"
                        onClick={(e) => goTodetailPage(e, payload)}
                      >
                        {/* <div className="delivery-date date-wrp">Delivered on 06/10/2022 10:58 am</div> */}
                        <div className="date-wrapper date-wrap-title">
                          {props.type === "Completed" ? (
                            <>
                              <strong className="delivery-date">
                                Delivered on{" "}
                                {moment.utc(item.current_status_time).calendar({
                                  sameDay: "DD/MM/YYYY h:mm a",
                                  nextDay: "DD/MM/YYYY h:mm a",
                                  nextWeek: "DD/MM/YYYY h:mm a",
                                  lastDay: "DD/MM/YYYY h:mm a",
                                  lastWeek: "DD/MM/YYYY h:mm a",
                                  sameElse: "DD/MM/YYYY h:mm a",
                                })}
                              </strong>
                              {/* <span> Your order has been delivered </span> */}
                            </>
                          ) : props.type === "Cancelled" ? (
                            <>
                              <strong className="delivery-date">
                                {item.order_status ==="Processed" ? "Expired":"Cancelled"} on{" "}
                                {moment.utc(item.updatedAt).calendar({
                                  sameDay: "DD/MM/YYYY h:mm a",
                                  nextDay: "DD/MM/YYYY h:mm a",
                                  nextWeek: "DD/MM/YYYY h:mm a",
                                  lastDay: "DD/MM/YYYY h:mm a",
                                  lastWeek: "DD/MM/YYYY h:mm a",
                                  sameElse: "DD/MM/YYYY h:mm a",
                                })}
                              </strong>
                              {console.log("item",item)}
                              <span>{item.order_status ==="Processed"?"Your item has been expired":" Your item has been cancelled "}</span>
                            </>
                          ) : props.type === "Processed" ? (
                            <>
                                  <div className="created-date posted-date order-date-page">
                                    Approved at:{" "}
                                    {moment.utc(item.updatedAt).utcOffset('+05:30').calendar({
                                      sameDay: "DD/MM/YYYY HH:mm",
                                      nextDay: "DD/MM/YYYY HH:mm",
                                      nextWeek: "DD/MM/YYYY HH:mm",
                                      lastDay: "DD/MM/YYYY HH:mm",
                                      lastWeek: "DD/MM/YYYY HH:mm",
                                      sameElse: "DD/MM/YYYY HH:mm",
                                    })}
                                  </div>
                              {/* <span className="order-created"> Your order has been created </span> */}
                            </>
                          ) : props.type === "Returned" ? (
                            <>
                              <strong className="delivery-date">
                                Return request on{" "}
                                {moment.utc(item.Returned_time).calendar({
                                  sameDay: "DD/MM/YYYY h:mm a",
                                  nextDay: "DD/MM/YYYY h:mm a",
                                  nextWeek: "DD/MM/YYYY h:mm a",
                                  lastDay: "DD/MM/YYYY h:mm a",
                                  lastWeek: "DD/MM/YYYY h:mm a",
                                  sameElse: "DD/MM/YYYY h:mm a",
                                })}
                              </strong>
                              <span className="item-return">
                                {" "}
                                Item returned
                              </span>
                            </>
                          ) : props.type === "Pending" &&
                            item.isVisible == false ? (
                            <>
                              <div className="count-wrap">
                                <i
                                  className="fas fa-clock"
                                  style={{
                                    margin: "0 5px 9px 0",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#f47900",
                                  }}
                                ></i>
                                <p style={{ color: "#f47900" }}>Expires in:</p>
                                <Countdown
                                  date={
                                      item.categories.name!=="Coil"?new Date(item.createdAt).getTime() +
                                      30 * 60 * 1000:
                                    new Date().getTime()+( new Date( new Date(item.createdAt)).setHours(24, 0, 0, 0)- new Date().getTime())
                                  }
                                  renderer={item.categories.name!=="Coil"?renderer1:renderer}
                                  zeroPadTime={2}
                                />
                                &nbsp;mins
                              </div>
                              <strong>
                                {new Date(item.createdAt).getDate()}/
                                {new Date(item.createdAt).getMonth()}/
                                {new Date(item.createdAt).getFullYear()}{" "}
                                {new Date(item.createdAt).getHours()}:
                                {new Date(item.createdAt).getMinutes()}:
                                {new Date(item.createdAt).getSeconds()}
                              </strong>
                            </>
                          ) : null}
                        </div>
                        {/* {props.type == "Pending" ? (
                          <span className="date-wrp">Waiting for approval</span>
                        ) : null} */}

                        {item.odid && (
                          <span className="date-wrp">
                            Order ID: {item.odid}{" "}
                          </span>
                        )}

                        {props.type === "Cancelled" ? null : ( // </div> //   </span> //     Accepted //   <span className={getClass(item.sellerApproval === "Accepted" ? item.order_status : item.sellerApproval)}> //   Cancel status: // <div className="status-details">
                          <div className="date-wrp main-wrp-order-status">
                            <div className="order-status-wrapper-box">
                              <span className="date-wrp order-status-date-wrap">
                                Order Status:
                              </span>
                              <div className="order-acceptance date-wrp">
                                <span
                                  className={
                                    getClass(
                                      item.sellerApproval === "Accepted"
                                        ? item.order_status
                                        : item.sellerApproval
                                    ) + " order-request-state date-wrp"
                                  }
                                  style={{
                                    color: item.order_status == "Processed" && item.sellerApproval==="Pending" && item.paymentReceipts.length>0 ? "#f5c139" : item.sellerApproval==="Pending"?"red":"inherit",
                                  }}
                                >
                                  {/* {item.order_status} */}
                                  {item.sellerApproval === "Accepted"
                                    ? item.order_status == "ProformaInvoice"
                                      ? "Proforma Invoice"
                                      : item.order_status == "Processed"
                                      ? "Processing"
                                      : item.order_status == "ReadyToShip"
                                      ? "Ready To Ship"
                                      : item.order_status == "Processed"
                                      ? "Processing"
                                      : item.order_status == "GoodsDispatched"
                                      ? "Goods Dispatched"
                                      : item.order_status == "OrderComplete"
                                      ? "Order Complete"
                                      : item.order_status == "GoodsReceived"
                                      ? "Goods Received"
                                      : item.order_status
                                    : (item.sellerApproval==="Pending" && item.paymentReceipts.length>0)?"Awaiting Payment Confirmation":(item.sellerApproval==="Pending" )?"EMD Payment Pending":item.sellerApproval}
                                </span>
                              </div>
                            </div>
                            {/* <div className="mail-wrapper">
                            <i className="icon-mail"></i>
                          </div> */}
                          </div>
                        )}
                        {(item.categories.name=="Coil")?
                         <span className="date-wrp">
                            EMD Amount: {item.order_item_qty*1000}{" "}
                          </span>:null
                        }
                        {props.type == "Processed" ? (
                          <>
                            {item.paymentReceipts.length > 0 ? null : (
                              <span className="date-wrp make-new-payment-btn pay-btn">
                                Make Payment
                              </span>
                            )}
                          </>
                        ) : null}
                      </Link>

                      {props.type == "Processed" ? (
                        <Link
                          className="shop-now msg-btn button-icon"
                          to={{
                            pathname: "/chats/all",
                            state: {
                              chatPayload: {
                                sender_id: item.seller_details._id,
                                product_id: item.product_id,
                              },
                            },
                          }}
                          data-count={item.messageCount}
                        >
                          <i
                            className="fas fa-envelope icon-chat-new"
                            data-count={item.messageCount}
                          />
                        </Link>
                      ) : null}
                    </div>

                    {/* {item.sellerApproval === 'Rejected' ?
                  null :
                  props.type === "Processed" || props.type === "Pending" ? (
                    <div className="switch-wrapper action-btn">
                      <button
                        className="header-sell"
                        onClick={() => {
                          setInpuForm(true);
                          setOrderId(item._id);
                          setOrderType(item.orderType)
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : props.type === "Completed" &&
                    item.current_status_time &&
                    moment().isBefore(moment(item.current_status_time).add(item.returnPolcyData[0].returnPolicyDay, "days")) ?
                    (
                      <div className="switch-wrapper action-btn my-action" >
                        <button
                          className="header-sell"
                          onClick={() => {
                            setInpuForm(true);
                            setOrderId(item._id);
                            setOrderType(item.orderType)
                          }}
                        >
                          Return
                        </button>

                      </div>
                    ) : null}
                {props.type !== "Cancelled" && props.type !== "Returned" ? (<div className="btn-msg msg-btn">
                  {
                    item.customer_details.customer_id !== item.seller_details._id &&

                    <Link
                      className="shop-now msg-btn"
                      to={{
                        pathname: "/chats/all",
                        state: { chatPayload: { sender_id: item.seller_details._id, product_id: item.product_id } }
                      }}
                      data-count={item.messageCount}
                    >
                      <i className="fas fa-envelope icon-chat-new" data-count={item.messageCount} />
                    </Link>
                  }
                </div>) : null} */}
                  </div>
                </div>
                {/* {props.type === "Processed" && <div className="seller-account-wrap">
                  <div className='seller-account-head'>
                    <h1>Seller account details</h1>
                  </div>
                  <div className='bank-detail-info-wrap'>
                    <div className='bank-holder-name detail-bnk'>
                      <h2>Account holder :</h2>
                      <h4>Alok sharma</h4>
                    </div>
                    <div className='bank-account-number  detail-bnk'>
                      <h2>Account Number :</h2>
                      <h4>109934785688</h4>
                    </div>
                    <div className='bank-account-ifsc  detail-bnk'>
                      <h2>IFSC code :</h2>
                      <h4>SBI059</h4>
                    </div>
                  </div>
                  <div className="upload-document">
                    <h5>Browse Receipt</h5>
                    <div className="file-upload-wrapper">
                      <div className="upload-file">
                        <input type="file" className="file-uploader" />
                        <img src="/assets/images/upload-icn.svg" alt="img-icon" />
                      </div>
                    </div>
                    <div className="uploaded-image">
                      <img src="" alt="uploaded-img" />
                    </div>
                  </div>
                </div>} */}
              </div>
            </>
          );
        })}

      {!isEmpty(props.myProduct) && props.myProduct.product.length === 0 && (
        <div className="product-head">
          <div className="product-img">
            <img src="\assets\images\no-product.png" alt="img"></img>
          </div>
          <h6> No Product in your list </h6>
        </div>
      )}

      {console.log("props",props)}

      {!isEmpty(props.myProduct) &&
      props.myProduct.product.length < props.myProduct.productCount ? (
        <div className="plp-loadMore-div">
          <button className="shop-now" onClick={loadMore}> {buttonLoader ? <ButtonLoader content={"Loading"} /> : "Load more"}</button>
        </div>
      ) : null}
    </div>
  );
};

export default ListingProduct;

export const Rating = ({ val }) =>
  [1, 2, 3, 4, 5].map((i) =>
    i <= parseInt(val) ? (
      <li className="fill">
        <i className="fas fa-star"></i>
      </li>
    ) : (
      <li>
        <i className="far fa-star"></i>
      </li>
    )
  );
