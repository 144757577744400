import React from "react";


const AppStore = () => {
    return (
        <>
            <div className="coming-soon">
                <img src="/assets/images/coming-soon.jpg" alt="" />
            </div>
        </>
    )
}


export default AppStore;