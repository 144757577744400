import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './MainPage.css'
import { setLoginPopupStatus, logout } from "../../store/actions/common";
import { useDispatch } from 'react-redux';
import { getHomePageDataWithoutLogin } from '../../store/actions/common';
import { Authorization } from '../../utils/Authorization';
import Slider from "react-slick";
import UserSupport from '../Dashboard/userSupport';
import Testimonial from '../Dashboard/testimonial';
import BannerBlock from '../Dashboard/Banner';
import ProductPageNew from '../ProductPageNew/ProductPageNew';
import NewPopup from '../Dashboard/newPopup';
import Counter from '../Dashboard/Counter';
import WhyMetalbuy from '../Dashboard/WhyMetalbuy';
import DashboardService from '../Dashboard/Services';
import Timeline from '../Dashboard/Timeline';
import BuyerSeller from '../Dashboard/BuyerSeller';
import BuyerTestimonials from '../Dashboard/BuyerTestimonials';
import SupplierTestimonials from '../Dashboard/SupplierTestimonials';
import PlatformProducts from '../Dashboard/PlatformProducts';
import BrandSlider from '../Dashboard/BrandSlider';
const MainPage = (props) => {
    const [pageData, setPageData] = useState({});
  const [waitList, setWaitLIst] = useState(false);
    const loginInfo = Authorization();
    const openWaitList = () => {
        setWaitLIst(true);
      }
    
      const closePopup = () => {
        setWaitLIst(false);
    
      }
    var profileCompleted = localStorage.getItem("isProfileCompleted");
    var profileApproved = localStorage.getItem("approved");
    let dispatch = useDispatch();

    const registerOpen = (screenToShow) => {
        dispatch(setLoginPopupStatus({
            status: true,
            screenToShow: screenToShow,
            type: "user"
        }))
    };

    useEffect(() => {
        getHomePageDataWithoutLogin((item) => {
            setPageData(item);
        })(dispatch);
    }, [])

    const logoutHandler = () => {
        logout()(dispatch)
    }
    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

  
    return (
        <>
            <BannerBlock/>
            <Counter/>
            <UserSupport />
            <WhyMetalbuy/>
             <DashboardService/>
            <Timeline />
            <PlatformProducts />
            <BuyerSeller />
            <BrandSlider />
            <BuyerTestimonials />
            <SupplierTestimonials />
            <section className='footer-section'>
                <div className='container-main'>

                </div>
            </section>
            {waitList ? 
            <NewPopup closePopup={closePopup}/> : null
        }
            
        </>
    );
}
export default MainPage;