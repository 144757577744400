import React from "react";
import Slider from "react-slick";
import moment from "moment";
import ReactStars from "react-rating-stars-component";



class ProductReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1299,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const { reviewList } = this.props;
    return reviewList.length > 0 ? (
      <div style={{ width: "100%" }}>
        <Slider {...settings} ref={(c) => (this.slider = c)}>
          {reviewList.map((data, i) => (
            <div className="review-card-wrap" key={i} index={i}>
              <div className="review-card">
                <div className="rating-date">
                  <RatingStar value={data.rating} />
                  <span>
                    {
                      moment(data.updatedAt).calendar({
                        sameDay: 'DD/MM/YYYY',
                        lastDay: 'DD/MM/YYYY',
                        nextDay: 'DD/MM/YYYY',
                        nextWeek: 'DD/MM/YYYY',
                        lastDay: 'DD/MM/YYYY',
                        lastWeek: 'DD/MM/YYYY',
                        sameElse: 'DD/MM/YYYY'
                      })
                    }


                  </span>
                </div>
                <div className="prof-img">
                  <span className="product-review-images">
                    {
                      <img
                        src={
                          data.userimage[0]
                            ? data.userimage[0]
                            : "/assets/images/profile-img.png"
                        }
                        alt={data.username[0]}
                      />
                    }
                  </span>
                  <br />
                  <span className="name">{data.username[0]}</span>
                </div>
                <div className="review-content">{data.review_comment}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    ) : <h4>No Review Found</h4>;
  }
}
export default ProductReview;

export const RatingStar = ({ value }) => {
  const secondExample = {
    size: 30,
    count: 5,
    color: "black",
    activeColor: "red",
    value: value,
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="fas fa-star" style={{ color: "#D3D3D3" }}></i>,
    halfIcon: <i className="fa fa-star-half-alt" style={{ color: "#f86202" }} />,
    filledIcon: <i className="fa fa-star" style={{ color: "#f86202" }} />,
    edit: false
  };
  return (
    <ReactStars {...secondExample} />
  )
};
