import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import MakeOfferPopup from '../../../ProductDetail/MakeOfferPopup';
import { axiosInstance } from '../../../../utils/Service';
import { lastIndexOf } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DeliveryAddress from '../../../OrderDetails/deliveryAddress';
import Countdown from 'react-countdown';



const OfferDetails = ({ item, setShowLoader, setLoaderState }) => {
  const userData = useSelector(store => store.userProfile.userProfileBanner.userdata);
  const locations = useLocation();
  const history = useHistory();
  const { addToast } = useToasts();

  const [showMessage, setShowMessage] = useState("");
  const [offerPopUp, setOfferPopup] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [showAcceptPopup, setShowAcceptPopup] = useState(false)

  const [latestOfferData, setLatestOfferData] = useState(null)

  const {prodId, prodType} = useParams()

  let location = {...locations, state:{event:{id:prodId}}, state:{event:{name:prodType}}}

  console.log("locations", locations)
  console.log("location", location)

  useEffect(() => {
    if(prodId){
      setShowLoader(true)
      setShowMessage("Please wait...")
      let token = localStorage.getItem("token");
      axiosInstance.post(`/offers/offer_details`, {
        "offerId": prodId,
      }, {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        },
      }).then((res) => {
        setDetailData(res.data)
        setShowLoader(false)
        setLoaderState()
      }).catch(() => {
        setLoaderState()
      });
    }
    else{
      window.location = "/"
    }
  }, [prodId])

  const handleAccept = () => {
    setShowAcceptPopup(true)
  }

  const handleCounter = (item) => {
    setOfferPopup(true);
    setLatestOfferData(item)
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
          <p>Expires in:</p>
          <span style={{color:"#ed9829"}}>
            <span>
              {minutes}:{seconds}
            </span>
            &nbsp; mins
          </span>
        </div>
      );
    }
  };

  const handleAcceptOffer = () =>{
    setShowLoader(true)
    let token = localStorage.getItem("token");
    axiosInstance.post('/offers/accept_offer_request', {
      "offerId": prodId,
    }, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    }).then((res) => {
      setShowLoader(false)
      setShowAcceptPopup(false)
      if(userData.userType==="Buyer"){
        (res.data.data.product_Data1.coilCount!==undefined && res.data.data.product_Data1.coilWeight!==undefined)?
        window.location.href = "/profile/myorder/pending":window.location.href = "/profile/myorder/processed"
      }
      else{
        window.location.href = "/profile/seller-order/processed"
      }
    }).catch(() => {
      setShowLoader(false)
      setShowAcceptPopup(false)
    });
  }

  // const handleDecline = () => {
  //   setShowLoader(true)
  //   let token = localStorage.getItem("token");
  //   axiosInstance.post(`/offers/decline_offer_request`, {
  //     "offerId": location.state.event.id,
  //   }, {
  //     headers: {
  //       "Authorization": "Bearer " + token,
  //       "Content-Type": "application/json"
  //     },
  //   }).then((res) => {
  //     setShowLoader(false)
  //     window.location = "/profile/my-offer/declined"
  //   }).catch(() => {
  //     setShowLoader(false)
  //   });
  // }

  return (
    <>
      {detailData && detailData.data && detailData.data[0] && detailData.data[0].product_data && detailData.data[0].product_data[0] && <div className='order-details order-detail-page'>
        <div className='multiple-items multiple-items-wrapper new-multiple-wrap'>
          <div className='container'>
            <div className="ordered-products order-product-wrp">
              <button className="chat-module__back-button order-bck-page" onClick={history.goBack}>
                <span className="bck-order">
                  <i></i> Back
                </span>
              </button>
              <div className="products-images products-images-wrapper pending-order" style={{ justifyContent: "flex-start" }}>
                <div className="img-wrapper">
                  <img
                    src={detailData.data[0].product_data[0].default_image}
                    alt={"Images"}
                  />
                </div>
                <div className="product-detail">
                  <div className="cart-item-detail">
                    <div className="value">
                      <div className="current-value">Product Name:</div>
                      <div className="new-value">
                        {detailData.data[0].product_data[0].productName}
                        {" "}
                        {detailData.data[0].product_data[0].productGrade}&nbsp;
                      </div>
                    </div>
                    <div className="value">
                      <div className="current-value">Price:</div>
                      <div className="new-value">
                        ₹{detailData.data[0].product_data[0].productPrice.$numberDecimal}/mt
                      </div>
                    </div>
                    {detailData.data[0].product_data[0] && detailData.data[0].product_data[0].specificationTolerance && <div className="value">
                      <div className="current-value">
                        Specification Tolerance:
                      </div>
                      <div className="new-value">{detailData.data[0].product_data[0].specificationTolerance}%</div>
                    </div>}
                    <div className="value">
                      <div className="current-value">Payment Type:</div>
                      <div className="new-value">
                        {detailData.data[0].product_data[0].paymentType} {detailData.data[0].product_data[0].otherPayType ? `(${detailData.data[0].product_data[0].otherPayType})` :null}
                      </div>
                    </div>
                    <div className="value">
                      <div className="current-value">Size:</div>
                      <div className="new-value">
                        {detailData.data[0].product_data[0].productSize}mm
                      </div>
                    </div>
                    {/* <div className="value">
                      <div className="current-value">Size Tolerance:</div>
                      <div className="new-value">
                        {detailData.data[0].product_data[0].productSizeTolerance}%
                      </div>
                    </div> */}
                    <div className="value">
                      <div className="current-value">Delivery:</div>
                      <div className="new-value">
                        {detailData.data[0].product_data[0].deliveryType
                          && detailData.data[0].product_data[0].deliveryType}
                      </div>
                    </div>
                    {detailData.data[0].product_data[0].insuranceCharge && detailData.data[0].product_data[0].insuranceCharge.$numberDecimal && <div className="value">
                      <div className="current-value">Insurance Charge:</div>
                      <div className="new-value">
                        ₹{detailData.data[0].product_data[0].insuranceCharge.$numberDecimal}/mt
                      </div>
                    </div>}
                    {detailData.data[0].product_data[0].loadingCharge && detailData.data[0].product_data[0].loadingCharge.$numberDecimal && <div className="value">
                      <div className="current-value">Loading Charge:</div>
                      <div className="new-value">
                        ₹{detailData.data[0].product_data[0].loadingCharge.$numberDecimal}/mt
                      </div>
                    </div>}
                    <div className="value">
                      <div className="current-value">Packaging:</div>
                      {(detailData.data[0].product_data[0].coilCount && detailData.data[0].product_data[0].coilWeight) ?
                      <span className="value">
                      {`${detailData.data[0].product_data[0].coilCount} Coils (${detailData.data[0].product_data[0].coilWeight} Kgs approx.)`}
                      </span>:null
                     }
                      {
                        detailData.data[0].product_data[0].packaging.map((item, index) => {
                          return <div className="new-value" key={index}>
                            {item}
                          </div>
                        })
                      }
                    </div>
                    {detailData.data[0].product_data[0].deliveryDateFrom && <div className="value">
                      <div className="current-value">Delivery Window:</div>
                      <div className="new-value">
                        {new Date(detailData.data[0].product_data[0].deliveryDateFrom).toLocaleDateString("en-IN")} - {new Date(detailData.data[0].product_data[0].deliveryDateTo).toLocaleDateString("en-IN")}
                      </div>
                    </div>}


                    {/* {props.orderdetail.isVisible == false ? (
                <div className="count-wrap">
                  <p>Expires in:</p>
                  <span>
                    <Countdown
                      date={new Date(props.orderdetail.createdAt).getTime() + 30 * 60 * 1000}
                      renderer={renderer}
                      zeroPadTime={2}
                    />
                    &nbsp; mins
                  </span>
                </div>
              ) : null} */}
                  </div>
                  <div className="order-table-specs">
                    <div className="current-value" style={{ marginBottom: "5px" }}>Specification:</div>
                    <div className="product-tble-wrp product-table-seller">
                      <table>
                        {detailData.data[0].product_data[0].spec && Object.keys(detailData.data[0].product_data[0].spec).map(function (
                          key,
                          index
                        ) {
                          let filteredLength = []
                          const value =detailData.data[0].product_data[0].spec[key];

                          // Skip if the value contains "N/A"
                          // if (value.includes("N/A")) {
                          //   return null;
                          // }

                          // Determine how to handle ranges and single values
                          let displayValue = value;
                          if (value.startsWith("N/A")) {
                            // Remove the key from the object
                            delete detailData.data[0].product_data[0].spec[key];
                          } else {
                          if (value.includes("-")) {
                            // If it's a range (like "14-16%"), don't show "max" or "min"
                            displayValue = value.replace(/(max|min|N\/A)/i, "").trim();
                          } else if (value.match(/^\d+(\.\d+)?%$/)) {
                            // If it's a single percentage, add "max" or "min" based on the key

                            displayValue = `${value} ${key.toLowerCase().includes("min") ? "min" : "max"}`;
                          } else {
                            displayValue = value.replace(/(N\/A)/i, "").trim();
                          }
                          filteredLength.push(value)
                        } 
                         
                          return Object.keys(filteredLength).length %
                            2 ===
                            1 ? (
                            Object.keys(filteredLength).length - 1 ===
                              index &&
                              Object.keys(filteredLength).length > 2 ? (
                              <>
                                {detailData.data[0].product_data[0].spec[key] !== "% " ? (
                                  <>
                                    <tr>
                                      <th>
                                        {key.charAt(0).toUpperCase() +
                                          key.slice(1)}
                                      </th>
                                      <th>{displayValue} </th>
                                    </tr>
                                    <tr>
                                      <th
                                        style={{
                                          color: "transparent",
                                          borderRightColor: "transparent",
                                        }}
                                      >
                                        T
                                      </th>
                                      <th
                                        style={{
                                          color: "transparent",
                                          borderLeftColor: "transparent",
                                        }}
                                      >
                                        0
                                      </th>
                                    </tr>
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {detailData.data[0].product_data[0].spec[key] !== "% " ? (
                                  <tr>
                                    <th>
                                      {key.charAt(0).toUpperCase() +
                                        key.slice(1)}
                                    </th>
                                    <th>{displayValue} </th>
                                  </tr>
                                ) : null}
                              </>
                            )
                          ) : (
                            <>
                              {detailData.data[0].product_data[0].spec[key] !== "% " ? (
                                <tr>
                                  <th>
                                    {key.charAt(0).toUpperCase() +
                                      key.slice(1)}
                                  </th>
                                  <th>{displayValue} </th>
                                </tr>
                              ) : null}
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                  <div className="value">
                      <div className="current-value">Comments:</div>
                      {detailData.data[0].offerData.additionalComment}
  
                    </div>
                </div>
              </div>
            </div>











            <div className="ordered-products order-product-wrp">
              <div className='order-left order-delivery-location'>
                <div className='order-left-top'>
                  <span className="order-title">Offer Information</span>
                </div>
              </div>



              {
                detailData && detailData.data.map((item, index) => {
                  return <div className='offer-column'>
                    {userData && <h5>{
                      
                        detailData.data.length % 2 === 1 ? (index % 2 === 0 ? userData.userType === "Buyer" ? "Your offer" : "Buyer counter offer"
                          :
                          userData.userType === "Seller" ? "Your offer" : "Seller counter offer")
                          :
                          (index % 2 === 1 ? userData.userType === "Buyer" ? "Your offer" : "Buyer counter offer"
                            :
                            userData.userType === "Seller" ? "Your offer" : "Seller counter offer")}</h5>}






                    <div className="products-images products-images-wrapper pending-order" key={index} style={{ justifyContent: "flex-start" }}>
                      <div className="">
                        <div className="cart-item-detail">
                          <div className="value">
                            <div className="current-value">Date and time:</div>
                            <div className="new-value">
                              {moment(item.offerData.offerDate).calendar({
                                sameDay: "DD/MM/YYYY h:mm a",
                                nextDay: "DD/MM/YYYY h:mm a",
                                nextWeek: "DD/MM/YYYY h:mm a",
                                lastDay: "DD/MM/YYYY h:mm a",
                                lastWeek: "DD/MM/YYYY h:mm a",
                                sameElse: "DD/MM/YYYY h:mm a",
                              })}
                            </div>
                          </div>
                          <div className="value">
                            <div className="current-value">Quantity:</div>
                            <div className="new-value">
                              {item.offerData.quantity}
                            </div>
                          </div>
                          <div className="value">
                            <div className="current-value">Offer price:</div>
                            <div className="new-value">
                              ₹{item.offerData.price}/mt
                            </div>
                          </div>
                          <div className="value">
                            <div className="current-value">Payment Type:</div>
                            <div className="new-value">
                              {item.offerData.paymentTerms} {detailData.data[0].product_data[0].otherPayType ? `(${detailData.data[0].product_data[0].otherPayType})` :null}
                            </div>
                          </div>
                          <div className="value">
                            <div className="current-value">Size:</div>
                            <div className="new-value">
                              {item.offerData.size}mm
                            </div>
                          </div>
                          <div className="value">
                            <div className="current-value">Delivery:</div>
                            <div className="new-value">
                              {item.offerData.deliveryType}
                            </div>
                          </div>
                          <div className="value">
                            <div className="current-value">Packaging:</div>
                            {
                              (item.offerData.packaging)?
                              item.offerData.packaging.map((ele, index) => {
                                return <div className="new-value" key={index}>
                                  {ele}
                                </div>
                              }):null
                            }
                          </div>
                          <div className="value">
                            <div className="current-value">Delivery Window:</div>
                            <div className="new-value">
                              {moment(
                                item.offerData.delivery_window_from
                              ).format("Do MMM")}{" "}
                              -{" "}
                              {moment(
                                item.offerData.delivery_window_to
                              ).format("Do MMM")}
                            </div>
                          </div>
                        </div>
                        {item.offerData.additionalComment ?
                          <div className="value">
                            <div className="current-value">Additional Comment:</div>
                            <div className="new-value">
                              {item.offerData.additionalComment}
                            </div>
                          </div> : null}
                      </div>
                      <div className='action-value value-tab' style={{
                        width: "100%",
                        maxWidth: "220px",
                        marginLeft: "auto"
                      }}>
                        {item.offerData.offerDate && index === 0 && (item.offerStatus==="Pending" || item.offerStatus==="CounterOffer") ? (
                          <Countdown
                            date={new Date(item.offerData.offerDate).getTime() + 30 * 60 * 1000}
                            renderer={renderer}
                            zeroPadTime={2}
                          />

                        ) : null}
                        {/* {index === 0 && <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                        <p>Offer seen:</p>
                        <span>
                          {item.offerData.readStatus ? "Yes":"No"}
                        </span>
                      </div>} */}
                        {userData && prodType === "Pending" && index === 0 && (item.offerStatus==="Pending" || item.offerStatus==="CounterOffer")
                          ? ((detailData.data.length % 2 === 1 && userData.userType === "Seller") || (detailData.data.length % 2 === 0 && userData.userType === "Buyer")) ? <>
                            <button className='date-wrp make-new-payment-btn' style={{ marginTop: "0" }} onClick={handleAccept}>Accept</button>
                            <button className='date-wrp make-new-payment-btn' onClick={() => handleCounter(item.offerData)}>Counter</button>
                          </>
                            :
                            index === 0 && <div className="count-wrap" style={{ justifyContent: "flex-start" }}>
                              <p>Offer seen:</p>
                              <span>
                                {item.readStatus ? "Yes" : "No"}
                              </span>
                            </div>
                          : null}
                      </div>
                    </div>
                  </div>
                })
              }
            </div>











            {/* <div className='order-details order-detail-page payment-order-page' style={{ padding: "0" }}>
              <div className='payment-wrp-type'>
                {detailData && detailData.data && detailData.data[0] && detailData.data[0].offerData && detailData.data[0].offerData.length > 0 &&
                  detailData.data[0].offerData.map((item, index) => {
                    return <div key={index} className='table-offer-wrap' style={{ paddingBottom: "0" }}>
                      <div className='table-scroll-new' style={{ marginBottom: "0" }}>
                        <div className='table-offer-new'>
                          <div className='table-headings head-row'>
                            <div className='price-heading head-tble'>Price</div>
                            <div className='qty-heading head-tble'>Quantity</div>
                            <div className='payment-heading head-tble'>Payment Terms</div>
                            <div className='time-heading head-tble'>Time Left</div>
                            {location.state.event.name === "Pending" && <div className='action-heading head-tble'>Action</div>}
                          </div>
                          <div className='table-value-new values-row'>
                            <div className='price-value value-tab'>
                              <div className='mob-head'>Price</div>
                              <div className='price-type'>
                                {index === 0 && "Original Price: "}
                                {index === 1 && "Seller Counter Price: "}
                                {index === 2 && "Buyer Counter Price: "}
                                &#x20b9;{item.price}/mt</div>
                              <div className='date'>
                                {moment(item.offerDate).calendar({
                                  sameDay: "DD/MM/YYYY h:mm a",
                                  nextDay: "DD/MM/YYYY h:mm a",
                                  nextWeek: "DD/MM/YYYY h:mm a",
                                  lastDay: "DD/MM/YYYY h:mm a",
                                  lastWeek: "DD/MM/YYYY h:mm a",
                                  sameElse: "DD/MM/YYYY h:mm a",
                                })}</div>
                            </div>

                            <div className='qty-value value-tab'><div className='mob-head'>Quantity</div>{item.quantity}</div>
                            <div className='payment-value value-tab'><div className='mob-head'>Payment Terms</div>{item.paymentTerms}</div>
                            <div className='time-value value-tab'>
                              <div className='mob-head'>Time Left</div>
                              30 min</div>
                            {(
                              (detailData.data[0].offerData.length - 1) === index) && userData && location.state.event.name === "Pending"
                              ? ((index % 2 === 0 && userData.userType === "Seller") || (index % 2 === 1 && userData.userType === "Buyer")) ?
                                <div className='action-value value-tab'>
                                  <div className='mob-head'>Action</div>
                                  <button className='btn' onClick={handleAccept}>Accept</button>
                                  <button className='btn' onClick={handleCounter}>Counter</button> */}
            {/* {index !== 2 && <button className='btn' onClick={handleCounter}>Counter</button>} */}
            {/* <button className='btn' onClick={handleDecline}>Decline</button> */}
            {/* </div> : null : null}
                          </div>
                          {item.additionalComment && <p style={{ marginBottom: "20px" }}>{item.additionalComment}</p>}
                        </div>
                      </div>
                    </div>
                  })
                }
                {showAcceptPopup && <div className='accept-popup'>
                  <div className="swal-overlay swal-overlay--show-modal">
                    <div className="swal-modal delete-swal"><div className="swal-icon swal-icon--warning">
                      <span className="swal-icon--warning__body">
                        <span className="swal-icon--warning__dot"></span>
                      </span>
                    </div>
                      <div className="swal-title">Are you sure?</div>
                      <div className="swal-text">You want to delete this product!</div>
                      <div className="swal-footer">
                        <div className="swal-button-container">
                          <button onClick={()=>{setShowAcceptPopup(false)}} className="swal-button swal-button--cancel">Cancel</button>
                          <div className="swal-button__loader">
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                        <div className="swal-button-container">
                          <button className="swal-button swal-button--confirm swal-button--danger">OK</button>
                          <div className="swal-button__loader">
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
                {offerPopUp && <MakeOfferPopup setShowLoader={setShowLoader} data={location.state.event} detailData={detailData} setOfferPopup={setOfferPopup} setDetailData={setDetailData} />}
              </div>
            </div> */}
            {showAcceptPopup && <div className='accept-popup'>
              <div className="swal-overlay swal-overlay--show-modal" style={{zIndex:"20"}}>
                <div className="swal-modal delete-swal"><div className="swal-icon swal-icon--warning">
                  <span className="swal-icon--warning__body">
                    <span className="swal-icon--warning__dot"></span>
                  </span>
                </div>
                  <div className="swal-title">Are you sure?</div>
                  <div className="swal-text">You want to accept this offer!</div>
                  <div className="swal-footer">
                    <div className="swal-button-container">
                      <button onClick={() => { setShowAcceptPopup(false) }} className="swal-button swal-button--cancel">Cancel</button>
                    </div>
                    <div className="swal-button-container">
                      <button onClick={handleAcceptOffer} className="swal-button swal-button--confirm swal-button--danger">OK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
            {offerPopUp && <MakeOfferPopup latestOfferData={latestOfferData} setShowLoader={setShowLoader} detailData={detailData} setOfferPopup={setOfferPopup} setDetailData={setDetailData} />}
            <div className='ordered-products order-product-wrp' style={{ marginTop: "20px", borderRadius: "10px" }}>
              <DeliveryAddress userData={userData} orderdetail={{
                product_detail: {
                  user_address_details: detailData.data[0].address_data[0],
                  offerId: detailData.data[0]._id,
                  createdDate: detailData.data[0].createdAt,
                  seller_details: detailData.data[0].seller_data[0],
                  buyer_details: detailData.data[0].buyerData,
                  offerStatus:detailData.data[0].offerStatus
                }
              }} />
            </div>
          </div>
        </div>
      </div >}
    </>
  )
}
export default OfferDetails;
