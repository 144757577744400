import { isEmpty } from "lodash";
import React, { useRef, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { RupeesIcon, priceWithSymbol } from "../../utils/constants";

const CenterView = (props) => {


  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      return messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.conversation]);

  useEffect(() => {
    props.setMsgText(
      props.linkData && props.linkData.data && props.linkData.data.paymentLink
    );
    if (
      props.linkData &&
      props.linkData.data &&
      props.linkData.data.paymentLink
    ) {
      setTimeout(() => {
        props.sendMessage();
      }, 2000);
    }
    // setLink(replaceURLs(props.linkData && props.linkData.data && props.linkData.data.paymentLink));
  }, [props.linkData]);

  const showModal = () => {
    props.handleModal();
  };

  const controlComponent = () => {
    props.backScreen();
  };

  function replaceURLs(message) {
    if (!message) return;
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      );
    });
  }

  return (
    <div className="contacts-chat">
      {props &&
      props.conversation &&
      props.conversation.isSellerAccount === true ? (
        <h3>
          {props &&
            props.conversation &&
            props.conversation.userData[0] &&
            props.conversation.userData[0].full_name}
        </h3>
      ) : (
        <h3>
          {props &&
            props.conversation &&
            props.conversation.sellerData &&
            props.conversation.sellerData.full_name}
        </h3>
      )}
      <div className="contacts-chat-inner">
        {!isEmpty(props.conversation.userData) &&
          props.conversation.userData[0] && (
            <div>
              <span
                className="back-link chats-back"
                onClick={() => controlComponent()}
              >
                {" "}
                Back{" "}
              </span>
              <div
                className="chat-block-head"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "border-bottom": "1px solid",
                }}
              >
                {props.prod.data && props.prod.data.length ? (
                  <Link
                    to={`/product/${props.prod.data[0].categoryData[0].name}/${props.prod.data[0]._id}/?cat=${props.prod.data[0].categoryData[0].slug}`}
                  >
                    <figure className="chat-img-div">
                      <img
                        className="chat-img"
                        src={
                          props.prod.data && props.prod.data.length > 0
                            ? props.prod.data[0].default_image
                            : "/assets/images/avtar.png"
                        }
                        alt={props.conversation.userData[0].full_name}
                      />
                    </figure>
                    <span>{props.conversation.userData[0].full_name}</span>
                  </Link>
                ) : null}
              </div>
            </div>
          )}
        <div className="chats-wrapper" id="chat-scroll-box">
          {!isEmpty(props.conversation) ? (
            props.conversation.data.length > 0 ? (
              <>
                {props.conversation.data.map((msg, ind) => {
                  console.log(msg)
                  {console.log("first1111", msg.receiver_id, props.userId)}
                  if (msg.receiver_id === props.userId) {
                    return (
                      <div className="contacts" key={ind}>
                        <img
                          src={
                            msg.sender_image
                              ? msg.sender_image
                              : "/assets/images/avtar.png"
                          }
                          alt={msg.sender_name}
                        />
                        {msg.user_message.includes("http") ? (
                          <span className="click-top-pay-message clickpay-wrapper">
                            {msg.paymentCompleted ? (
                              <div className="payment-completed-wrap">
                                Payment completed
                              </div>
                            ) : (
                              <a
                                href={msg.user_message}
                                target="_blank"
                                className="proceed-payment-wrp"
                              >
                                Proceed to pay
                              </a>
                            )}
                            <div className="paid-wrapper-main">
                              <div className="buy-info paid-buyer-info">
                                {priceWithSymbol(msg.paymentPrice)}
                              </div>
                              {msg.paymentCompleted ? (
                                <div className="paid-pay">
                                  {msg.paymentCompleted ? "Paid" : null}
                                </div>
                              ) : null}
                            </div>
                          </span>
                        ) : (
                          <span>{msg.user_message}</span>
                        )}
                        <div className="sender-designation">
                        <small> {msg.sender_designation && msg.sender_designation.length > 0
    ? `${msg.sender_name}, ${msg.sender_designation}`
    : msg.sender_name}</small>
                        <small> {msgTime(msg)}</small>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="contacts coming-chat" key={ind}>
                        <img
                          src={
                            msg.sender_image
                              ? msg.sender_image
                              : "/assets/images/avtar.png"
                          }
                          alt={msg.sender_name}
                        />

                        {msg.user_message.includes("http") ? (
                          <span className="click-top-pay-message clickpay-wrapper">
                            <a target="_blank" className="click-pay-wrp">
                              Offer created
                            </a>
                            <div className="paid-wrapper-main">
                              <span className="buy-info paid-buyer-info">
                                {priceWithSymbol(msg.paymentPrice)}
                              </span>
                              {msg.paymentCompleted ? (
                                <span className="paid-pay">
                                  {msg.paymentCompleted ? "Paid" : null}
                                </span>
                              ) : null}
                            </div>
                          </span>
                        ) : (
                          <span>{msg.user_message}</span>
                        )}

                       <div className="sender-designation">
                       <small> {msg.sender_designation && msg.sender_designation.length > 0
    ? `${msg.sender_name}, ${msg.sender_designation}`
    : msg.sender_name}</small>
                        <small> {msgTime(msg)}</small>
                       </div>
                      </div>
                    );
                  }
                })}
                <div ref={messagesEndRef} />
              </>
            ) : (
              <div className="product-head">
                <h6>No message</h6>
              </div>
            )
          ) : (
            <>
              <div className="product-head">
                <h6>No message</h6>
              </div>
            </>
          )}
        </div>
      </div>
      {!isEmpty(props.conversation) &&
        !isEmpty(props.conversation.userData) && (
          <div className="chatbox-bottom">
            <form
              className="send-form"
              onSubmit={(e) => {
                e.preventDefault();
                if (props.msgText.trim().length > 0) {
                  props.sendMessage();
                }
              }}
            >
              <input
                type="text"
                placeholder="Type...."
                onChange={(e) => props.setMsgText(e.target.value)}
                value={props.msgText}
                maxLength={250}
                max={250}
              />

              {props.conversation.isSellerAccount ? (
                <img
                  src="\assets\images\offer.png"
                  alt="img"
                  className="make-offer-icon"
                  onClick={() => showModal()}
                />
              ) : null}

              <button className="send-btn"></button>
            </form>
          </div>
        )}
    </div>
  );
};
export default CenterView;

const msgTime = (msg) => {
  return moment(msg.createdAt).calendar({
    sameDay: "DD/MM/YYYY h:mm a",
    nextDay: "DD/MM/YYYY h:mm a",
    nextWeek: "DD/MM/YYYY h:mm a",
    lastDay: "DD/MM/YYYY h:mm a",
    lastWeek: "DD/MM/YYYY h:mm a",
    sameElse: "DD/MM/YYYY h:mm a",
  });
};