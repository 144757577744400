import React from "react";
import { Switch, Route, useLocation, Link, Redirect, withRouter } from "react-router-dom";

import ContactList from "./contactList";

const chatTabRoute = [
  {
    pathname: "/all",
    name: "All",
    state: { tab: "All" },
    prefix: "/chats",
  },
  {
    pathname: "/selling",
    name: "Selling",
    state: { tab: "Selling" },
    prefix: "/chats",
  },
  {
    pathname: "/buying",
    name: "Buying",
    state: { tab: "Buying" },
    prefix: "/chats",
  },
];

const LeftScreen = (props) => {
  const location = useLocation();

  const tabRender = () => (
    <Switch>
      {chatTabRoute.map((i) => (
        <Route
          key={Math.random()}
          path={i.prefix + i.pathname}
          render={(prev) => (
            <ContactList {...prev} farScreen={props.farScreen} />
          )}
        />
      ))}
      <Redirect from="*" to="/chats/all" />
    </Switch>
  );

  return (
    <div className="message">
      <div
        style={{ display: "flex", marginBottom: "10px", alignItems: "center" }}
      >
        <button
          className="chat-module__back-button"
          onClick={() => props.history.goBack()}
        >
          <i></i>
        </button>
        <h2 style={{ marginBottom: 0, marginLeft: "10px" }}>messages</h2>
      </div>
      <div className="tags-wrap chat-msg-tab">
        <div className="tags">
          {chatTabRoute.map((data, indx) => {
            return (
              <Link
                key={indx}
                to={{
                  pathname: data.prefix + data.pathname,
                  state: data.state,
                  userData: data,
                }}
                className={
                  data.pathname === location.pathname.replace("/chats/", "/")
                    ? "active-tab"
                    : (!props.isSeller && data.name == "Selling") ||
                      (!props.isSeller && data.name == "Buying")
                    ? "hideTab"
                    : ""
                }
              >
                {!props.isSeller ? "Buying" : data.name}
              </Link>
            );
          })}
        </div>
      </div>
      {tabRender()}
    </div>
  );
};
export default withRouter(LeftScreen);
