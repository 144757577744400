import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";


const HeaderSearch = (props) => {


  useEffect(() => {
    var wage = document.getElementsByClassName("react-autosuggest__input")[0];
    wage.addEventListener("keydown", function (e) {
      if (e.key === 13) {

      }
    });
    return () => wage.removeEventListener("keydown", (e) => { })
  }, [document.getElementsByClassName("react-autosuggest__input--focused")[0]]);



  return (
    <div className="search-brand search-brand-desktop header-search-brand">
      <form onSubmit={(e) => { e.preventDefault(); props.onPresssEnter() }} className="search-form-wrap">
        <button className="search-btn-mob top-search-btn"><i className="search-btn-icon"></i></button>
        <Autosuggest
          suggestions={props.suggestions}
          onSuggestionsFetchRequested={() => { }}
          onSuggestionsClearRequested={props.clear}
          getSuggestionValue={props.getSuggestionValue}
          alwaysRenderSuggestions={true}
          renderSuggestion={(suggest) =>
            // renderSuggestion(props.suggestions, props.value, e, f)
            renderSuggestion(suggest, props.value)
          }
          onSuggestionSelected={props.onSuggestionSelected}
          inputProps={props.inputProps}
        />
      </form>
      {/* <i
        onClick={props.onPresssEnter}
        className={props.inputProps.value === "" ? "" : "icon-search"}
      ></i> */}
    </div>
  );
};
export default HeaderSearch;

const renderSuggestion = (suggestion, value) => {
  if (suggestion === "not") {
    return (
      <button disabled={true}>
        <strong>Sorry no data found</strong>
      </button>
    );
  }
  return suggestion.productName;
};
