import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { get } from "lodash";

const ViewOrder = (props) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div className="order-details">
      <div className="order-detail-top">
        <div className="container">

          <div className="order-top"></div>
        </div>
      </div>

      <div className="order-progress">
        <div className="container">
          <div className="order-bottom">
            <div className="order-bottom-detail"></div>
            <button>Back Button</button>
            <button>Summary Button</button>
          </div>

        </div>

      </div>
    </div>
  );
};

export default ViewOrder;
