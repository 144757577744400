import React from "react";
import { Link } from "react-router-dom";
function News() {
    return (
        <div className="news-component">
            <div className="container-main">
                <div className='news-box'>
                    <Link to="/" className="news-box-inner">
                        <div className="box-content">
                            <h3>Lorem Ipsum is simply dummy</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        </div>
                        <div className='img'>
                            <img src="/assets/images/event-meet-up.jpg" alt="meet-up" />
                        </div>
                    </Link>
                    <Link to="/" className="news-box-inner">
                        <div className="box-content">
                            <h3>Lorem Ipsum is simply dummy</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        </div>
                        <div className='img'>
                            <img src="/assets/images/event-meet-up.jpg" alt="meet-up" />
                        </div>
                    </Link>
                    <Link to="/" className="news-box-inner">
                        <div className="box-content">
                            <h3>Lorem Ipsum is simply dummy</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        </div>
                        <div className='img'>
                            <img src="/assets/images/event-meet-up.jpg" alt="meet-up" />
                        </div>
                    </Link>
                    <Link to="/" className="news-box-inner">
                        <div className="box-content">
                            <h3>Lorem Ipsum is simply dummy</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        </div>
                        <div className='img'>
                            <img src="/assets/images/event-meet-up.jpg" alt="meet-up" />
                        </div>
                    </Link>
                    <Link to="/" className="news-box-inner">
                        <div className="box-content">
                            <h3>Lorem Ipsum is simply dummy</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        </div>
                        <div className='img'>
                            <img src="/assets/images/event-meet-up.jpg" alt="meet-up" />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default News;