import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

const Pagination = (props) => {
    const [item, setItem] = useState([]);
    const [visible, setVisible] = useState(5);

    useEffect(() => {
        if (props.paginationData.data && props.paginationData.data.length > 0) {
            setItem(props.paginationData.data[0].commentData);
        }
    }, [])

    const showMore = () => {
        setVisible(preValue => preValue + 5);
    }
    return (
        <>
            {item.slice(0, visible).map((item, j) => {
                return (
                    <div className="comment-vlog">
                        <div className="profile-wrap">
                            <span className="name">{item.comment_author_name}</span>
                            <span className="comment">{item.comment_content}</span>
                        </div>
                    </div>
                )
            })}
            {item.length > 5 ?
                <button className="see-all view-all-btn-wrap" onClick={showMore}>View all<i className="fa fa-angle-right" aria-hidden="true"></i></button> :
                null
            }

        </>
    )
}

export default withRouter(Pagination);