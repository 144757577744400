import React, { useState } from "react";
import "./popup.css";
import { useToasts } from "react-toast-notifications";
import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "query-string";
import { getProductListInDeliverTo } from '../store/actions/productListing'
import { useDispatch } from "react-redux";



const handleEnter = (event) => {
  if (event.keyCode === 13) {
    document.getElementById("zip-submit").click();
  }
};



const LocationPopup = (props) => {

  let location = useLocation()
  let history = useHistory()
  let query = parse(location.search);
  let dispatch = useDispatch()

  const [zip_code, setZipcode] = useState(query.type === "delivery" ? query.deliveryZipCode : "");
  const { addToast } = useToasts();

  const zipHandler = (e) => {
    let checkPrice = new RegExp(/^\d*$/);
    const value = e.target.value
    if (checkPrice.test(value)) {
      setZipcode(value)
    }
  }


  const submit = () => {

    if (zip_code === "") {
      addToast("Please Enter Pin code", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
      return false;
    }
    if (zip_code.length < 6) {
      addToast("Please Enter Valid Pin code", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
      return false;
    }

    getProductListInDeliverTo({
      type: "delivery", deliveryZipCode: [zip_code]
    }, callback => {
      if (callback && callback.data.length === 0) {
        addToast("No product found!", {
          appearance: "error",
          autoDismissTimeout: 2000,
        });
      } else {
        props.contShopping(zip_code)
      }
    })(dispatch)





  }



  return (
    <div className="make-offer-popup location-popup select-location-pop-up">
      <div className="offer-content">
        <button
          className="modal-closeBtn"
          onClick={() => props.closePopup(null)}
          type="button"
        >
          <i className="fal fa-times-circle"></i>
        </button>

        <div className="popup-body">
          <div className="loc-img">
            <img src="/assets/images/img-01.png" alt="" />
          </div>
          {/* <h3>
            See if you're eligible for free &nbsp;
            <span>same-day pickup</span>
          </h3> */}
          {/* <button className="useLocation" onClick={props.useCurrentLoc}>
            Use Current Location
          </button> */}
          {/* <div className="location-choose">
            <span className="location-select">OR</span>
          </div> */}
          <input
            required
            minLength={6}
            maxLength={6}
            type="text"
            name="zip_code"
            className="zip-code"
            placeholder="Enter a pin code"
            value={zip_code}
            onChange={(e) => zipHandler(e)}
            onKeyDown={handleEnter}
          />

          <button
            type="submit"
            className="cont-shopping"
            id="zip-submit"
            // onClick={() => {
            //   zip_code
            //     ? props.contShopping(zip_code)
            //     : addToast("Please Enter Zip code", {
            //       appearance: "error",
            //       autoDismissTimeout: 2000,
            //     });
            // }}
            onClick={submit}
          >
            Continue shopping
          </button>
        </div>

        {/* <div className="add-links">
          {props.closePopup ? (
            <span onClick={() => props.closePopup(null)} className="btn-purple">
              {props.cancelTxt ? props.cancelTxt : "No"}
            </span>
          ) : null}
          {props.submitFun ? (
            <span onClick={() => this.props.submitFun()} className="btn-purple">
              {props.successTxt ? props.successTxt : "Yes"}
            </span>
          ) : null}
        </div> */}
      </div>
    </div>
  );
};


export default LocationPopup;
