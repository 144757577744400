import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Toast from "light-toast";
import { useToasts } from "react-toast-notifications";
import { getBankList, myBank, deleteMyBank, editMyBank } from "../../../../store/actions/profile";
import { useHistory } from "react-router-dom";
import { apiUrl } from "../../../../utils/urlEndpoints";
import { axiosInstance } from "../../../../utils/Service";
import { priceWithSymbol } from "../../../../utils/constants";
import { connect, useDispatch } from "react-redux";
import Popup from "../../../SellAnItem/Popup";
import {
  getProfileBannerInfo,
} from "../../../../store/actions/profile";
import swal from 'sweetalert';
import Loader from "../../../Loader/index"




function WithdrawAmount(props) {
  let dispatch = useDispatch()
  const [show, setShow] = React.useState(false);
  const { addToast } = useToasts();
  const [accountNumber, setAccount] = React.useState("");
  const [reAccountNumber, setReAccountNumber] = React.useState("");
  const [ifscCode, setIfsc] = React.useState("");
  const [accountHolderName, setAccountHolder] = React.useState("");
  const [userId] = React.useState(localStorage.getItem("userId"));
  const history = useHistory();
  const [bankList, setBankList] = React.useState([]);
  const [selectedBank, setselectedBank] = React.useState("");
  const [showErr, setShowErr] = React.useState(false);
  const [label, setLabel] = React.useState("");
  const [myBankList, setMyBankList] = React.useState({});
  const [bankId, setBankId] = React.useState("");
  const [showLoader, setShowLOader] = React.useState(false);
  const [withdraw_amount, setWithdraw_amount] = React.useState("");
  const [dummyBank, setDummyBank] = React.useState("");
  const [responseBankData, setResponseBankData] = React.useState({
    Bank_Name: "",
    Acc_Number: "",
  });
  const [responseWalletData, setResponseWalletData] = React.useState({
    Wallet_Amount: "",
  });


  React.useEffect(() => {
    getBankList((res) => {
      setBankList(res);
    });

    props.myBank({}, (status) => {
      if (status) {

        setMyBankList(status.data);
      }
    })
  }, []);

  React.useEffect(() => {
    // POST request using axios inside useEffect React hook
    const article = { sellerId: userId };
    axiosInstance
      .post(apiUrl.profile.sellerWalletDetails, article)
      .then((res) => {
        let bank_detail_of_seller_data = res.data.sellerData;
        setResponseBankData({
          Bank_Name: bank_detail_of_seller_data.bank && bank_detail_of_seller_data.bank.bankName,
          Acc_Number: bank_detail_of_seller_data.bank && bank_detail_of_seller_data.bank.accountNumber,
          Acc_Holder: bank_detail_of_seller_data.bank && bank_detail_of_seller_data.bank.accountHolderName,
          IFSC_Code: bank_detail_of_seller_data.bank && bank_detail_of_seller_data.bank.ifscCode
        });

        let wallet_detail_of_seller_data = res.data.walletData;
        let tmp = ['lastRequestAmount', 'receivedAmount', 'totalAmount'];

        if (wallet_detail_of_seller_data) {
          tmp.map(i =>
            typeof wallet_detail_of_seller_data[i] === 'object' && wallet_detail_of_seller_data[i] !== null
              ? wallet_detail_of_seller_data[i] = wallet_detail_of_seller_data[i].$numberDecimal
              : null
          )

          setResponseWalletData({
            Wallet_Amount: wallet_detail_of_seller_data.totalAmount,
          });
        }

      });
  }, []);

  const OnwithdrawClick = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    return await axiosInstance
      .post(apiUrl.profile.send_money_request_to_admin, {
        requestamount: withdraw_amount, bankId: dummyBank
      }, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(async (res) => {
        addToast("Withdraw Request Submitted Successfully", {
          appearance: "success",
          autoDismissTimeout: 5000,
        });
        getProfileBannerInfo()(dispatch)
        history.push("/profile/seller/dashboard")
        //window.location.reload();
      })
      .catch((err) => {
        addToast(err.response.data.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      });
  };

  //  On submit function will led to edit/change bank api with the following mentioned params....
  const onSubmitChangeBank = async (e) => {
    const token = localStorage.getItem("token");
    e.preventDefault();
    if (accountNumber !== reAccountNumber) {
      return setShowErr(true);
    }
    if (label == "add") {
      setShowLOader(true);
      return await axiosInstance
        .post(apiUrl.profile.editBank, {
          accountNumber,
          ifsc: ifscCode,
          bankName: selectedBank,
          reAccountNumber,
          accountHolderName,
        }, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(async (res) => {

          setShowLOader(false);
          addToast("Bank added successfully", {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          setShow(false);
          props.myBank({}, (status) => {
            if (status) {
              setMyBankList(status.data);
            }
          })

        })
        .catch((err) => {
          setShowLOader(false);
          // alert(err);

        });
    } else {
      setShowLOader(true);
      const payload = {
        bankId: bankId,
        bankName: selectedBank,
        ifsc: ifscCode,
        accountNumber: accountNumber,
        accountHolderName: accountHolderName
      }
      props.editMyBank(payload, (status) => {
        if (status) {
          setShowLOader(false);
          addToast("Bank detail updated successfully", {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          setShow(false);
          props.myBank({}, (status) => {
            if (status) {
              setMyBankList(status.data);
            }
          })

        } else {
          setShowLOader(false);
        }
      })
    }


  };

  const onChange = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || re.test(e.target.value)) {
      setWithdraw_amount(e.target.value)
    }
  }

  const closeChangeModal = () => {
    setShow(false)
  }

  const handleChangeBank = (e) => {

    setLabel("edit");
    setAccount(e.accountNo);
    setReAccountNumber(e.accountNo);
    setIfsc(e.ifsc);
    setAccountHolder(e.accountHolderName);
    setselectedBank(e.bankName);
    setBankId(e._id);
    setShow(true);

  }

  const handleDeleteBank = (e) => {

    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this bank?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    })
      .then(willDelete => {
        if (willDelete) {



          const payload = {
            bankId: e._id
          }
          props.deleteMyBank(payload, (status) => {

            if (status) {
              swal(status.message);
              props.myBank({}, (status) => {
                if (status) {
                  setMyBankList(status.data);
                }
              })
            } else {

              addToast(status.message, {
                appearance: "error",
                autoDismissTimeout: 2500,
              });
            }
          })


        }
      });

  }


  const handleAddBank = () => {
    setLabel("add");
    setAccount("");
    setReAccountNumber("");
    setIfsc("");
    setAccountHolder("");
    setShow(true);
    setselectedBank("");
  }



  return (
    <div className="withdraw-panel">
      {showLoader ? <Loader message="Please wait..." /> : null}
      <h4>Withdraw Balance</h4>
      <p>
        The money will be in your bank{" "}
        <span className="italic-text">account 3-4 business days</span>,
        depending on your bank.
      </p>
      <div className="balance-block">
        <div className="balance-number">
          {priceWithSymbol(Math.round(responseWalletData.Wallet_Amount))}
        </div>
        <h6>Your Balance</h6>
      </div>
      <form onSubmit={OnwithdrawClick}>
        {/* {<Popup
            submitFun={() => (window.location.href = "/profile/seller/dashboard")}
            successTxt={"Go to Dashboard"}
            content={"Congratulation, Withdraw request is successfully Submitted"}
            className="sebmit-prod"
          />} */}
        <div className="withdraw-inputs">
          <div className="amount-block">
            <label className="label-text">Amount</label>
            <div className="input-amount-wrap">
              <input
                required
                className="input-amount"
                placeholder="Enter Amount"
                type="text"
                variant="outlined"
                autoComplete="off"
                value={withdraw_amount}
                // max={100000}
                // min={100}
                onChange={(e) => onChange(e)}
              />
              <h5>Max: {Math.round(typeof responseWalletData.Wallet_Amount === 'object' && responseWalletData.Wallet_Amount !== null
                ? responseWalletData.Wallet_Amount.$numberDecimal
                : responseWalletData.Wallet_Amount)}/-</h5>
            </div>
          </div>
          <div className="bank-selector">
            <label className="label-text">To</label>
            <div className="withdrwa-select-wrap">

              <select
                className="withdraw-select"
                required
                value={dummyBank}
                onChange={(e) => {
                  setDummyBank(e.target.value);
                }}
              >
                <option value={""}>Select Bank</option>
                {
                  myBankList.length > 0 ? myBankList.map(item => <option value={item._id} >{item.bankName}</option>) : null
                }
              </select>
              <i className="fas fa-chevron-down"></i>
            </div>
            {/* //<Link to="/" className="add-bank-link">Add Bank Account</Link> */}
          </div>
        </div>
        <div className="withdraw-btns">
          <button className="withdraw-cancel" onClick={() => history.goBack()}>
            Cancel
          </button>
          <button className="withdraw-continue" type="submit">
            Withdraw
          </button>
        </div>
      </form>

      <div className="bank-column">
        <h4>Bank Detail</h4>

        <div className="bank-boxes">
          {myBankList.length > 0 ? myBankList.map((item) => {
            return (
              <div className="bank-balance">
                <div className="bank-icon">
                  <img src="../../assets/images/bank-icon.png" alt="bank" />
                </div>
                <div className="bank-detail">
                  <div className="bank-name">{item.bankName}</div>
                  <div className="bank-number">{item.accountNo}</div>
                  <div className="change-btn-wrapper">
                    <button className="change-btn" onClick={() => handleChangeBank(item)}>
                      <i className="fa fa-pencil" aria-hidden="true"></i> Edit
                    </button> &nbsp;&nbsp;&nbsp;&nbsp;

                    <button className="change-btn" onClick={() => handleDeleteBank(item)}>
                      <i className="fa fa-trash" aria-hidden="true"></i> Delete
                    </button>
                  </div>
                </div>
              </div>
            )
          }) : null
          }

        </div>

        {show ?
          <Modal
            show={show}
            dialogClassName="modal-90w bank"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header>
              <Modal.Title id="example-custom-modal-styling-title" className="close-button">

                {label == "add" ? "Add your bank detail" : "Update your bank detail"} <i className="fa fa-times" aria-hidden="true" onClick={closeChangeModal}></i>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="change-form" onSubmit={onSubmitChangeBank}>
                {/* TextField From Material-Ui */}
                <div className="seller-inputs">
                  <div className="seller-input input-icon">
                    <FormControl variant="outlined">
                      <InputLabel>Bank List *</InputLabel>
                      <Select
                        type="text"
                        label="Bank Name"
                        value={selectedBank}
                        required
                        onChange={(e) => setselectedBank(e.target.value)}
                      >
                        {bankList.map((bank) => (
                          <MenuItem value={bank.bankName}
                          >
                            {bank.bankName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <i className="fal fa-building"></i> */}
                  </div>
                  <div className="seller-input input-icon seller-acc">
                    <TextField
                      type="password"
                      autoComplete="false"
                      variant="outlined"
                      label="Account Number"
                      value={accountNumber}
                      required
                      InputProps={{
                        inputProps: {
                          maxLength: 15,
                          minLength: 9,
                          autoComplete: "new-password",
                        },
                      }}
                      onChange={(e) => {
                        let checkDigit = new RegExp(/^\d*$/);
                        if (checkDigit.test(e.target.value)) {
                          setAccount(e.target.value)
                        }
                      }}
                    />
                    <i className="far fa-credit-card"></i>
                  </div>
                  <div className="seller-input input-icon seller-acc">
                    <TextField
                      type="text"
                      variant="outlined"
                      autoComplete="new-password"
                      label="Confirm Account Number"
                      value={reAccountNumber}
                      required
                      InputProps={{
                        inputProps: {
                          maxLength: 15,
                          minLength: 9,
                        },
                      }}
                      onChange={(e) => {
                        let checkDigit = new RegExp(/^\d*$/);
                        if (checkDigit.test(e.target.value)) {
                          setReAccountNumber(e.target.value)
                        }
                      }}
                    />
                    <i className="far fa-credit-card"></i>
                  </div>
                  {showErr && reAccountNumber !== accountNumber && (
                    <span className="error">
                      Account No. and Confirm Account No. doesn't match{" "}
                    </span>
                  )}
                  <div className="seller-input dual-input">
                    <TextField
                      type="text"
                      label="IFSC Code"
                      variant="outlined"
                      value={ifscCode}
                      required
                      InputProps={{
                        inputProps: {
                          maxLength: 11,
                          minLength: 11,
                        },
                      }}
                      onChange={(e) => setIfsc(e.target.value)}
                    />
                  </div>
                  <div className="seller-input dual-input">
                    <TextField
                      type="text"
                      label="Account Holder Name"
                      variant="outlined"
                      value={accountHolderName}
                      required
                      InputProps={{
                        inputProps: {
                          maxLength: 40,
                          minLength: 2,
                        },
                      }}
                      onChange={(e) => setAccountHolder(e.target.value)}
                    />
                  </div>
                </div>

                <div className="add-links">
                  <button type="submit" className="btn-purple" >
                    {label == "add" ? "Submit" : "Update"}
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal> : null
        }

        <button className="shop-now bank-account" onClick={() => handleAddBank("add")}> Add new bank account</button>
      </div>
      {/* <div className="withdraw-amounts">
                <h4>Transaction Details</h4>
                <ul className="transaction-listing">
                    <li>
                        <div className="list-heading">Available Balance</div>
                        <div className="list-value">$500.00</div>
                    </li>
                    <li>
                        <div className="list-heading">Withdrawal Amount</div>
                        <div className="list-value">$100.00</div>
                    </li>
                    <li>
                        <div className="list-heading">Fee</div>
                        <div className="list-value">Free</div>
                    </li>
                    <li>
                        <div className="list-heading">Total Amount</div>
                        <div className="list-value purple-value">$100.00</div>
                    </li>
                    <li>
                        <div className="list-heading">Remaining Balance</div>
                        <div className="list-value">$400.00</div>
                    </li>
                </ul>
            </div> */}
      {/* <div className="withdraw-btns">
                <button className="withdraw-cancel">Cancel</button>
                <button className="withdraw-continue">Continue</button>
            </div> */}
    </div >
  );
}

const mapStateToProps = (state, ownProps) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    myBank: (payload, completion) => dispatch(myBank(payload, completion)),
    deleteMyBank: (payload, completion) => dispatch(deleteMyBank(payload, completion)),
    editMyBank: (payload, completion) => dispatch(editMyBank(payload, completion))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WithdrawAmount));
