import * as React from "react"
import { Link } from "react-router-dom";




const PageNotFound = () => {
  return (
    <div className="not-found-wrapper">
      <div className="page-content">
        <section
          className="error-section">
          <h1>Error 404</h1>
          <img src="/assets/images/not-found.jpg" alt="error 404" />
          <h4>Looking for something?</h4>
          <p>Ooopps! That page can’t be found.</p>
          <p>Go to <Link to="/" className="go-home-btn"> Home </Link> page</p>
        </section>
      </div>
    </div>
  )
}

export default PageNotFound


