import Buying from "./MyOrder";
import ReviewPage from "./Review";
import NotificationPage from "./Notification";
import editPassword from "./EditProfile/editPassword";
import Selling from "./SellerOrder";
import MyOffer from "./Myoffer";
import OfferProduct from "./OfferProduct";
import YourShop from "./MyListing";
import SellerDashBoard from "../SellerDashBoard";
import basicInfo from "./basicInfo";
import WishList from "./WishList";
import ManageAddress from "../manageAddress/address";
import BusinessProfile from "../Business Profile/businessProfile";
import CompleteProfile from "../Complete Profile/completeProfile";
import RfqListing from "./RoqListing";
import RolePermission from "./RolePermission";

export const profileRoutes = [
  {
    pathname: "/seller",
    name: "Seller Dashboard",
    icon: "fas fa-chart-line",
    component: SellerDashBoard,
    prefix: "/profile",
    for: "seller",
  },
  // {
  //     pathname: "/businessProfile",
  //     name: "Business Profile",
  //     icon: "fas fa-solid fa-id-card",
  //     component: BusinessProfile,
  //     prefix: "/profile",
  //     for: "seller"
  // },
  {
    pathname: "/profile",
    name: "My Profile",
    icon: "fa fa-address-card",
    component: basicInfo,
    prefix: "",
    for: "seller",
  },
  {
    pathname: "/sell-product",
    name: "Create Listing",
    icon: "fas fa-box-open",
    component: OfferProduct,
    prefix: "",
    for: "seller",
  },
  {
    pathname: "/sell-product",
    name: "Create Order",
    icon: "fas fa-box-open",
    component: OfferProduct,
    prefix: "",
    for: "seller",
  },
  {
    pathname: "/my-listing",
    name: "My Listings",
    icon: "fas fa-clipboard-list",
    state: { tab: "Active" },
    component: YourShop,
    prefix: "/profile",
    for: "seller",
  },
  {
    pathname: "/rfq-offers",
    name: "Request For Quote (RFQ)",
    icon: "fas fa-box-open",
    component: RfqListing,
    prefix: "/profile",
    for: "user",
  },
  {
    pathname: "/rfq-offers",
    name: "Request For Quote (RFQ)",
    icon: "fas fa-box-open",
    component: RfqListing,
    prefix: "/profile",
    for: "seller",
  },
  {
    pathname: "/my-offer",
    name: "My Offers",
    icon: "fas fa-box-open",
    component: MyOffer,
    prefix: "/profile",
    for: "seller",
  },
  {
    pathname: "/seller-order",
    name: "My Orders",
    icon: "fas fa-box-open",
    component: Selling,
    prefix: "/profile",
    for: "seller",
  },
  {
    pathname: "/notification",
    name: "Notifications",
    icon: "fas fa-bell",
    component: NotificationPage,
    prefix: "/profile",
    for: "seller",
  },
  {
    pathname: "/change-password",
    name: "Change Password",
    icon: "fa fa-key",
    component: editPassword,
    prefix: "/profile",
    for: "seller",
  },
  // {
  //   pathname: "/offered-product",
  //   name: "Logout",
  //   icon: "fa fa-sign-out",
  //   component: OfferProduct,
  //   prefix: "/profile",
  //   for: "seller",
  // },

  // {
  //     pathname: "/seller-review",
  //     name: "Seller Review",
  //     icon: "fas fa-star",
  //     component: ReviewPage,
  //     prefix: "/profile",
  //     for: "seller"
  // },
  // {
  //     pathname: "/manage_address",
  //     name: "Manage Address",
  //     icon: "fa fa-address-card",
  //     component: ManageAddress,
  //     prefix: "/profile",
  //     for: "user"
  // },
  {
    pathname: "/complete_profile",
    name: null,
    icon: null,
    component: CompleteProfile,
    prefix: "/profile",
    for: "user",
  },
  {
    pathname: "/profile",
    name: "My Profile",
    icon: "fa fa-address-card",
    component: basicInfo,
    prefix: "",
    for: "user",
  },
  {
    pathname: "/rfq-offers",
    name: "Request For Quote (RFQ)",
    icon: "fas fa-box-open",
    component: MyOffer,
    prefix: "/profile",
    for: "user",
  },

  {
    pathname: "/role-permission",
    name: "Role and Permission",
    icon: "fas fa-box-open",
    component: RolePermission,
    prefix: "/profile",
    for: "user",
  },
  {
    pathname: "/role-permission",
    name: "Role and Permission",
    icon: "fas fa-box-open",
    component: RolePermission,
    prefix: "/profile",
    for: "seller",
  },

  
  {
    pathname: "/my-offer",
    name: "My Offers",
    icon: "fas fa-box-open",
    component: MyOffer,
    prefix: "/profile",
    for: "user",
  },
  // {
  //     pathname: "/wishList",
  //     name: "My WishList",
  //     icon: "fas fa-heart",
  //     component: WishList,
  //     prefix: "/profile",
  //     for: "user"
  // },
  {
    pathname: "/myorder",
    name: "My Orders",
    icon: "fas fa-box-open",
    component: Buying,
    prefix: "/profile",
    for: "user",
  },
  {
    pathname: "/change-password",
    name: "Change Password",
    icon: "fa fa-key",
    component: editPassword,
    prefix: "/profile",
    for: "user",
  },
  //   {
  //     pathname: "/review",
  //     name: "Review",
  //     icon: "fas fa-star",
  //     component: ReviewPage,
  //     prefix: "/profile",
  //     for: "user",
  //   },
  {
    pathname: "/notification",
    name: "Notifications",
    icon: "fas fa-bell",
    component: NotificationPage,
    prefix: "/profile",
    for: "user",
  },
];

export const myListingRoutes = [
  {
    pathname: "/active",
    name: "Active",
    state: { tab: "Active" },
    prefix: "/profile/my-listing",
  },
  {
    pathname: "/inactive",
    state: { tab: "Inactive" },
    name: "Inactive",
    prefix: "/profile/my-listing",
  },
  // {
  //     pathname: "/draft",
  //     name: "Draft",
  //     state: { tab: "Draft" },
  //     prefix: "/profile/my-listing"
  // },
  // {
  //     pathname: "/unApproved",
  //     name: "Pending",
  //     state: { tab: "unApproved" },
  //     prefix: "/profile/my-listing"
  // },
  {
    pathname: "/rejected",
    name: "Rejected",
    state: { tab: "rejected" },
    prefix: "/profile/my-listing",
  },
];

export const myOrderRoutes = [
  {
    pathname: "/pending",
    name: "Pending",
    state: { tab: "Pending" },
    prefix: "/profile/myorder",
  },
  {
    pathname: "/processed",
    name: "In Progress",
    state: { tab: "Processed" },
    prefix: "/profile/myorder",
  },
  {
    pathname: "/completed",
    name: "Completed",
    state: { tab: "Completed" },
    prefix: "/profile/myorder",
  },
  {
    pathname: "/cancelled",
    name: "Cancelled/Expired",
    state: { tab: "Cancelled" },
    prefix: "/profile/myorder",
  },
  // {
  //   pathname: "/returned",
  //   name: "Returned",
  //   state: { tab: "Returned" },
  //   prefix: "/profile/myorder",
  // },
];

export const sellerOrderRoutes = [
  {
    pathname: "/pending",
    name: "Pending",
    state: { tab: "Pending" },
    prefix: "/profile/seller-order",
  },
  // {
  //     pathname: "/offered",
  //     name: "Offered",
  //     state: { tab: "Offered" },
  //     prefix: "/profile/myorder"
  // },
  {
    pathname: "/processed",
    name: "In Progress",
    state: { tab: "Processed" },
    prefix: "/profile/seller-order",
  },
  {
    pathname: "/completed",
    name: "Completed",
    state: { tab: "Completed" },
    prefix: "/profile/seller-order",
  },
  {
    pathname: "/cancelled",
    name: "Cancelled/Expired",
    state: { tab: "Cancelled" },
    prefix: "/profile/seller-order",
  },
  // {
  //   pathname: "/returned",
  //   name: "Returned",
  //   state: { tab: "Returned" },
  //   prefix: "/profile/seller-order",
  // },
];

export const sellerDashRoutes = [
  {
    pathname: "/dashboard",
    name: "My Dashboard",
    state: { tab: "dashBoard" },
    prefix: "/profile/seller",
  },
  {
    pathname: "/seles-report",
    name: "Sales Report",
    state: { tab: "salesReport" },
    prefix: "/profile/seller",
  },
  {
    pathname: "/transaction-history",
    name: "Transaction History",
    state: { tab: "withdrawHistory" },
    prefix: "/profile/seller",
  },
];

export const myofferRoutes = [
  {
    pathname: "/pending",
    name: "Pending",
    state: { tab: "Pending" },
    prefix: "/profile/my-offer",
  },
  {
    pathname: "/expired",
    name: "Expired",
    state: { tab: "Expired" },
    prefix: "/profile/my-offer",
  },
  {
    pathname: "/auto-declined",
    state: { tab: "Auto Declined" },
    name: "Auto Declined",
    prefix: "/profile/my-offer",
  },
];

export const rfqRoutes = [
  {
    pathname: "/active",
    name: "Active",
    state: { tab: "Active" },
    prefix: "/profile/rfq-offers",
  },
  {
    pathname: "/expired",
    name: "Expired",
    state: { tab: "Expired" },
    prefix: "/profile/rfq-offers",
  },
];

export const myofferedSellerRoutes = [
  {
    pathname: "/pending",
    name: "Pending",
    state: { tab: "Pending" },
    prefix: "/profile/offered-product",
  },
  {
    pathname: "/accepted",
    state: { tab: "Accepted" },
    name: "Accepted",
    prefix: "/profile/offered-product",
  },
  {
    pathname: "/rejected",
    name: "Rejected",
    state: { tab: "Rejected" },
    prefix: "/profile/offered-product",
  },
];
