import React, { useEffect } from "react";
import moment from "moment";
const DeliveryStep = (props) => {
  console.log("deliverary",props)
  useEffect(() => {
   
    let timeArray = [];
    let mergedArray = [];
    if (props.completed !== undefined) {
      for (let a = 0; a < props.completed.length; a++) {
        timeArray.push(props.completed[a].time);
      }

      // for (let b = 0; b < props.allSteps.length; b++) {}
    }
    
  }, [props.completed, props.allSteps]);
  return (
    <div className="order-timeline status-timeline">
      <ul className="timline order-process-list">
        {props.allSteps.map((data, i) => {
          return (
            <li className={props.numberOrderStatus[props.numberOrderStatus.length-1]>=(data.order) ? "active" : ""}>
              <span className="circle-timeline"></span>
              <span className="timeline-text">
                {data.name === "Completed" ? "Delivered" : data.name}
               
              </span>
              {getActivClass(data, props.completed) && (
                <span className="timeline-timings">
                  {moment(getActivDate(data, props.completed)).calendar({
                    sameDay: "DD/MM/YYYY h:mm a",
                    nextDay: "DD/MM/YYYY h:mm a",
                    nextWeek: "DD/MM/YYYY h:mm a",
                    lastDay: "DD/MM/YYYY h:mm a",
                    lastWeek: "DD/MM/YYYY h:mm a",
                    sameElse: "DD/MM/YYYY h:mm a",
                  })}
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DeliveryStep;

const getActivClass = (all, comp) => {
  console.log("getActivClass",all)
  let returnClass = "";
  comp.map((data) => {
  
    if (data.name === all.name) {
      return (returnClass = "active");
    }
  });
  return returnClass;
};

const getActivDate = (all, comp) => {
  let returnDate = "Alok";
  comp.map((data) => {
    if (data.name === all.name) {
      return (returnDate = data.time);
    }
  });
  return returnDate;
};
