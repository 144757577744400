export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const truncateText = (source, size) => {
  if (source) {
    return source.length > size ? source.slice(0, size - 1) + "..." : source;
  }
};

export const isShowBuyButton = (product) => {
  if (!product) return false;
  const updatedAt = product.updatedAt;
  const expiryDate = product.expiryDate;

  if (expiryDate) {
    return isProductExpired(expiryDate);
  }

  if (updatedAt) {
    return isProductUpdatedToday(updatedAt);
  }

  return false;
};

export const isProductUpdatedToday = (date) => {
  const differenceInMs = Date.now() - new Date(date).getTime();
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
  return differenceInDays < 1;
};

export const isProductExpired = (date) => {
  const expirationDateTime = new Date(date);
  const currentDateTime = new Date();

  if (expirationDateTime > currentDateTime) return true;
  return false;
};

export const mapApi = "AIzaSyCGrtkBlGlz9rRx0fjGAn5URGEw_1uV6Ak";
