import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { ButtonLoader, RupeesIcon } from "../../../../utils/constants";
import "../../../ProductDetail/ProductDetails.css";
import moment from "moment";

const ListingProductChild = (props) => {
  const [buttonLoader, setButtonLoader] = React.useState(false);

  const loadMore = () => {
    setButtonLoader(true);
    props.loadMore({
      page: props.myProduct.page + 1,
      type: "Processed",
    });
    setTimeout(() => {
      setButtonLoader(false);
    }, 700);
  };

  // useEffect(() => {
  //   props.loadMore({
  //     page: 1,
  //     type: props.type,
  //   });
  // }, [props.type]);

  return (
    <div className="plp-product-screen child-screens container-main">
      <h3>Orders list</h3>
      {!isEmpty(props.myProduct) &&
        props.listing.length > 0 &&
        props.listing.map((item, i) => {
          const userRating = (item.reviewData && item.reviewData.length>0 && item.reviewData[0]) ? item.reviewData[0].rating : 0;

          const payload = {
            orderId: item._id,
            //productId: item.product_detail._id
            type: props.type,
          };

          return (
            <>
              <div
                className="buy-prod buy-product-wrapper order-process-wrapper"
                key={i}
              >
                <div className="inner-product-detail-wrap new-list-wrapper">
                  <div className="est-deliveries order-east-deliveries">
                    <div
                      className="prod-img order-product-img"
                      style={{ width: "148px", height: "148px" }}
                    >
                      <Link
                        to={`/process-orders/${payload.orderId}`}
                        // onClick={(e) => goTodetailPage(e, payload)}
                        style={{ width: "148px", height: "148px" }}
                      >
                        <img
                          src={item.product_detail.default_image}
                          alt="img"
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="prod-detail product-orderinfo product-info-detail-wrap">
                    <Link
                      className="prod-link prod-new-link"
                      key={i}
                      to={`/process-orders/${payload.orderId}`}
                    // onClick={(e) => goTodetailPage(e, payload)}
                    >

                      <div className="product-box-wrapper sub-detail-order">
                        <div className="home-detail qty-pricing">
                          <div className="home-details">
                            <span className="prod-text bold sub-name">
                              {item.product_detail.productName}{" "}
                              {item.product_detail.productGrade}&nbsp;
                            </span>
                          </div>
                          <div className="home-details">
                            <span className="prod-text bold sub-name">
                              Quantity:&nbsp;
                            </span>
                            <span className="prod-text value">
                              {item.order_item_qty}mt
                            </span>
                          </div>
                          <div className="pro-price">
                            <span className="prod-text bold sub-name">
                              Total Price:&nbsp;
                            </span>
                            <span className="prod-text value">
                              {RupeesIcon}
                              {item.total_order_amount}
                            </span>
                          </div>
                        </div>
                        <div className="home-detail qty-pricing">
                          <div className="home-details">
                            {props.userData.userdata.userType === "Buyer" ? <span className="prod-text bold sub-name">
                              Seller name:&nbsp;
                            </span>
                              :
                              <span className="prod-text bold sub-name">
                                Buyer name:&nbsp;
                              </span>}

                              {console.log("props",props)}

                            <span className="prod-text value">
                              {props.userData.userdata.userType === "Seller" ? item.customerData[0].companyName : item.sellerData[0].companyName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="prod-title prod-toggle rtl-delivery-information delivery-details-right">
                    <div className="del-details my-del product-detail-new inprogress-wrp">
                      <Link
                        key={i}
                        to={`/process-orders/${payload.orderId}`}
                      // onClick={(e) => goTodetailPage(e, payload)}
                      >
                        <div className="date-wrapper date-wrap-title">
                          <div className="created-date posted-date order-date-page">
                            Approved at:{" "}
                            {moment(item.updatedAt).calendar({
                              sameDay: "DD/MM/YYYY h:mm a",
                              nextDay: "DD/MM/YYYY h:mm a",
                              nextWeek: "DD/MM/YYYY h:mm a",
                              lastDay: "DD/MM/YYYY h:mm a",
                              lastWeek: "DD/MM/YYYY h:mm a",
                              sameElse: "DD/MM/YYYY h:mm a",
                            })}
                          </div>
                        </div>

                        {item.odid && (
                          <span className="date-wrp">
                            Order ID: {item.odid}{" "}
                          </span>
                        )}
                        {props.type == "Processed" ? (
                          <>
                            {item.paymentReceipts.length > 0 ? null : (
                              <span className="date-wrp make-new-payment-btn pay-btn">
                                Make Payment
                              </span>
                            )}
                          </>
                        ) : null}
                      </Link>

                      {props.type == "Processed" ? (
                        <Link
                          className="shop-now msg-btn button-icon"
                          to={{
                            pathname: "/chats/all",
                            state: {
                              chatPayload: {
                                sender_id: item.seller_details._id,
                                product_id: item.product_id,
                              },
                            },
                          }}
                          data-count={item.messageCount}
                        >
                          <i
                            className="fas fa-envelope icon-chat-new"
                            data-count={item.messageCount}
                          />
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}

      {!isEmpty(props.myProduct) && props.listing.length === 0 && (
        <div className="product-head">
          <div className="product-img">
            <img src="\assets\images\no-product.png" alt="img"></img>
          </div>
          <h6> No Product in your list </h6>
        </div>
      )}

      {!isEmpty(props.myProduct) &&
        props.listing.length < props.myProduct.all_records ? (
        <div className="plp-loadMore-div" style={{marginBottom:"20px"}}>
          <button className="shop-now" onClick={loadMore}>
          {buttonLoader ? <ButtonLoader content={"Loading"} /> : "Load more"}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ListingProductChild;

export const Rating = ({ val }) =>
  [1, 2, 3, 4, 5].map((i) =>
    i <= parseInt(val) ? (
      <li className="fill">
        <i className="fas fa-star"></i>
      </li>
    ) : (
      <li>
        <i className="far fa-star"></i>
      </li>
    )
  );
