import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Toast from "light-toast";

const ReadyToShip = (props) => {
    const [spinnerLoader, setSpinnerLoader] = useState(false);
    const [width, setwidth] = useState(false);
    const [height, setheight] = useState(false);
    const [length, setlength] = useState(false);
    const [weight, setweight] = useState(false);
    const [costEstimateListDetail, setcostEstimateListDetail] = useState([]);


    useEffect(() => {

    }, [])
    function carrierDetail(e) {
        Toast.loading("Loading...");
        //setSpinnerLoader(true);
        e.preventDefault()
        var tokenData = JSON.parse(localStorage.getItem("ShippingTokenDetail"));

        var order_id = props.orderdetail._id;
        const carrierObject = {
            authToken: tokenData.token,
            organizationId: tokenData.organizationId,
            orderId: order_id,
            breadth: weight,
            height: height,
            length: length,
            weight: width
        }

        props.Cost_Estimation(carrierObject, (status) => {
            if (status) {
                Toast.hide();
                //setSpinnerLoader(false);

                setcostEstimateListDetail(status.costEstimateList);

            } else {
                Toast.hide();
                //setSpinnerLoader(false)

            };
        });
    }
    return (
        <>
            <h3>Find Carrier</h3>
            <Form onSubmit={(e) => carrierDetail(e)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Width</Form.Label>
                    <Form.Control type="number" placeholder="Enter Width..." onChange={(e) => setwidth(e.target.value)} />
                    <Form.Text className="text-muted">

                    </Form.Text>
                </Form.Group><br />

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Height</Form.Label>
                    <Form.Control type="number" placeholder="Enter Height..." onChange={(e) => setheight(e.target.value)} />
                    <Form.Text className="text-muted">

                    </Form.Text>
                </Form.Group><br />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Length</Form.Label>
                    <Form.Control type="number" placeholder="Enter Length..." onChange={(e) => setlength(e.target.value)} />
                    <Form.Text className="text-muted">

                    </Form.Text>
                </Form.Group><br />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Weight</Form.Label>
                    <Form.Control type="number" placeholder="Enter Weight..." onChange={(e) => setweight(e.target.value)} />
                    <Form.Text className="text-muted">

                    </Form.Text>
                </Form.Group><br />
                <div className="buttons-wrp">
                    <Button type="submit" className="submit-button" variant="primary" >
                        Next
                    </Button>

                </div>
            </Form>
        </>
    )

}

export default ReadyToShip;