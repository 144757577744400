import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCoupons, applyProductCoupon } from '../../../store/actions/checkout';


const Coupon = ({ appliedCouponHandler, cartData, query, appliedCouponAmt }) => {



    let dispatch = useDispatch();

    const [openPop, setOpenPop] = useState(false);
    const [couponData, setCouponData] = useState({});
    const [couponName, setcouponName] = useState("");
    const [isError, setIsError] = useState(false);
    const [isApplied, setIsApplied] = useState(false);
    const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [appliedMessage, setAppliedMessage] = useState("")


    useEffect(() => {
        if (appliedCouponAmt === 0) {
            removeCoupon()
        }
    }, [appliedCouponAmt])


    useEffect(() => {
        if (cartData[0]) {
            let payload = {};
            if (query.pid) {
                payload = {
                    orderMethod: "buyNow",
                    productId: cartData[0].productId,
                    categoryId: cartData[0].categoryId,
                    quantity: cartData[0].Quantity
                }
            } else {
                payload = { orderMethod: "addToCart" };
            }
            getCoupons(payload, callback => {
                setCouponData(callback)
            })(dispatch);
        }
    }, [cartData])

    const applyHandler = (item) => {
        if (item !== "") {
            setcouponName(item);

            let payload = {};
            if (query.pid) {
                payload = {
                    couponName: item,
                    productId: cartData[0].productId,
                    categoryId: cartData[0].categoryId,
                    quantity: cartData[0].Quantity
                }
            } else {
                payload = { couponName: item }
            }

            applyProductCoupon(payload, callback => {
                if (callback.status) {
                    setIsAppliedCoupon(true)
                    let cartIds = callback.cart.map(item => item._id)
                    let couponPrice = 0;
                    setIsApplied(true)
                    setIsError(false)
                    setAppliedMessage(callback.message)
                    if (callback.productCouponData[0].discount_type === "fixed") {
                        appliedCouponHandler(callback.productCouponData[0].discount, item, callback)
                    } else {
                        let discount = callback.productCouponData[0].discount;

                        if (query.pid) {
                            couponPrice += parseInt(cartData[0].Price.sell_price * cartData[0].Quantity);
                        } else {
                            cartData.filter(item => {
                                if (item.status && item.productApprovalStatus && item.deletedAt === 0 && cartIds.includes(item.cartId)) {
                                    couponPrice += parseInt(item.Price.sell_price * item.Quantity);
                                    return item;
                                }
                            });
                        }

                        let mainDis = (couponPrice * discount) / 100;
                        appliedCouponHandler(mainDis, item, callback)
                    }
                } else {
                    setIsApplied(false)
                    setIsError(true)
                    setErrorMsg(callback.message)
                    appliedCouponHandler(0, item, callback)
                }

            })(dispatch)
            setTimeout(() => setOpenPop(false), 500)
        }
    }


    const removeCoupon = () => {
        setcouponName("");
        setIsApplied(false);
        setIsError(false)
        appliedCouponHandler(0, "")
        setIsAppliedCoupon(false)
    }

    const getDate = (item) => {
        return item.endDate
    }

    const handlerChange = (e) => {
        if (e.target.value === "") {
            setcouponName("");
            setIsApplied(false);
            setIsError(false)
            appliedCouponHandler(0, "")
            setIsAppliedCoupon(false)
        }
        setcouponName(e.target.value)
    }

    return (
        <div className="shipping-itemwrap shipping-remade coupon-shipping-item">
            <div className="head-wrap">
                <span className="checkout-headings"> Coupon</span>
            </div>

            <div className="desc-txt coupon-block">
                <input
                    type="text"
                    name="landmark"
                    placeholder="Enter coupon code"
                    value={couponName}
                    onChange={handlerChange}
                />
                {
                    !isAppliedCoupon ?
                        <button className={"complete-purchase complete-purchase-wrapper"} onClick={() => applyHandler(couponName)}>
                            Apply coupon
                        </button>
                        : <button className={"complete-purchase complete-purchase-wrapper"} onClick={removeCoupon}>
                            Remove coupon
                        </button>
                }

            </div>

            <span className="view-promo-code" onClick={() => setOpenPop(true)}>View available coupons</span>
            {
                openPop ?
                    <div className={"coupon-modal active-coup"}>
                        <div className="modal-content">
                            {
                                couponData.data &&
                                    couponData.data.length > 0 ?
                                    <h4>All Available coupons</h4>
                                    : <h4 className='no-coupon'>No coupon found</h4>
                            }

                            <span className="close" onClick={() => setOpenPop(false)}><i className="fa fa-times" aria-hidden="true"></i></span>
                            <div className='coupon-boxes'>
                                {
                                    couponData.data && couponData.data.map(item =>
                                        <>

                                            <div className="coupon-box" key={Math.random()}>
                                                <div>
                                                    <strong className="code-txt">{item.couponName}</strong>
                                                    {
                                                        item.discountType === "percentage" ?
                                                            <span className='discount-cl'>Flat {item.discountValue}% off</span>
                                                            : <span className='discount-cl'>Flat {item.discountValue} off</span>
                                                    }

                                                    <span className='valid-coupon'>Valid till: {getDate(item)}</span></div>
                                                {item.couponName === couponName ?
                                                    <span className="shop-now add-coupon-btn applied-btn" style={{ backgroundColor: 'green' }}>Applied</span>
                                                    :
                                                    <span className="shop-now add-coupon-btn" onClick={() => { applyHandler(item.couponName) }}>Apply</span>
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    : null
            }


            <div className="applied-msg">
                {isError && <span style={{ color: "red" }} className="error-msz-coupn coupon-invalid"><i class="fas fa-check-circle"></i>{errorMsg}</span>}
                {isApplied && <span style={{ color: "green" }} className="success-msz-coupon coupon-valid"><i class="far fa-times-circle" style={{ cursor: 'pointer' }} onClick={removeCoupon}></i>{appliedMessage}</span>}
            </div>
        </div>
    )
}

export default Coupon;