import { axiosInstance } from "../../utils/Service";
import { apiUrl } from "../../utils/urlEndpoints";

export const receiveAllOrder = (payload, complete) => {
    const token = localStorage.getItem("token");
    return (dispatch) => {
      return axiosInstance
        .post(apiUrl.receiveAllOrder, payload, {
          headers: {
            Authorization: "Bearer " + (token?token:localStorage.getItem("tokenUser")),
          },
        })
        .then((response) => {
          complete(response.data)
        })
        .catch((err) => {
          complete(err.response.data)
  
        });
    };
}

