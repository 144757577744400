import React from "react";
import { Link, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { RupeesIcon } from "../../utils/constants";
import { useDispatch } from "react-redux";
import ProductCard from "./ProductCard";
import { useToasts } from "react-toast-notifications";
import { Authorization } from "../../utils/Authorization";
import { openLoginScreen } from "../../store/actions/common";




const CartData = (props) => {
  let dispatch = useDispatch();

  const { addToast } = useToasts();

  let totalprice = 0;
  let history = useHistory();


  const goToCheckout = () => {
    let isOutStockProd = props.cartData.filter(item => item.prodQuantity === 0);
    if (isOutStockProd.length > 0) {
      addToast("Please remove out of stock product", { appearance: 'error' });
    } else {
      let checkLoginStatus = Authorization();
      if (checkLoginStatus.status) {
        history.push('/checkout')
      } else {
        openLoginScreen({ status: true, screenToShow: "login" })(dispatch)
      }
    }
  }


  return (
    <div className="cartsection">
      {isEmpty(props.cartData) ? (
        <div className="cart-caption add-cart-wrp">
          <div className="product-head product-head-wrp">
            <div className="product-img">
              <img src="\assets\images\no-product.png" alt="img"></img>
            </div>
            <h6 className="add-pro-cart"> Add product in your cart</h6>
            <Link to="./" className="cart-to-home-wrapper">
              <button type="button">Go to home page</button>
            </Link>

          </div>
        </div>
      ) : (
        <div>
          {
            props.cartData.map((item, i) => {
              totalprice += parseFloat(item.Price.$numberDecimal)
              return (
                <ProductCard
                  data={item}
                  type="cart"
                  isBuyNow={false}
                  key={Math.random()}
                />
              );
            })
          }

          <div className="cart-bottom">
            <h6 className="cart-total cart-total-head">
              <span className="total-price">Total Price</span> {RupeesIcon}{totalprice}
            </h6>
            <Link to="#" onClick={goToCheckout} className="checkout-proceed-btn checkout-process-shop-btn">
              <button className="shop-now">Make Order</button>
            </Link>
          </div>
        </div>
      )}
    </div >
  );

}

export default CartData;
