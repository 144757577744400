import React, { useState } from "react";
import Slider from "react-slick";
import { connect, useDispatch } from "react-redux";
import { getBannerImg } from "../../store/actions/dashboard";
import { Link, withRouter, useHistory } from "react-router-dom";
import { setLoginPopupStatus } from "./../../store/actions/common";
import { parse, stringify } from "query-string";
import { Authorization } from "../../utils/Authorization";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get } from "lodash";
import { isMobile } from "../../utils/constants";
import SkeletonLoader from "tiny-skeleton-loader-react";
import NewPopup from "./newPopup";

const BannerBlock = (props) => {
  const [waitList, setWaitLIst] = useState(false);
  let checkLoginStatus = Authorization();
  const history = useHistory();
  const dispatch = useDispatch()

  const openWaitList = () => {
    setWaitLIst(true);
  };

  const closePopup = () => {
    setWaitLIst(false);
  };

  const redirection = () => {
    history.push("/ytgtfg");
  };

  const registerOpen = (screenToShow) => {
    dispatch(
      setLoginPopupStatus({
        status: true,
        screenToShow: screenToShow,
        type: "user",
      })
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {checkLoginStatus.status ? (
        <div className="home-banner login-banner">
          {/* <button className="btn-bannerprev btn-banner" onClick={props.previous}>
        <i className="icon-bannerprev"></i>{" "}
      </button>
      <button className="btn-bannernext btn-banner" onClick={props.next}>
        <i className="icon-bannernext"></i>{" "}
      </button> */}
          {/* <div className="container-main"> */}
            <div className="img-wrap-banner">
              <img src={props.img} alt={props.caption} />
            </div>


            <div className="home-banner-content">
              <div className="container">
              <h2>
                Grow Your{" "}
                <Link to="/" className="banner-link">
                  Business
                </Link>{" "}<br/>
                With Us
              </h2>
              {/* <button class="common-btn" onClick={() => { props.openWaitList() }}>Join Our Wait List</button> */}
              </div>
            </div>
          {/* </div> */}
          {/* <div>
          {waitList ?
            <NewPopup
              closePopup={closePopup}
            />
            : null
          }
        </div> */}
        </div>
      ) : (
        <div className="home-banner ">
          <Slider {...settings}>
            <div className="home-banner-content">
              <div className="container">
                <div className="slider-content">
                  <h2>
                    YOUR<br></br>
                    ONE-CLICK ACCESS<br></br>
                    <span className="banner-sm-text">
                      TO A WORLD OF FERRO ALLOYS
                    </span>
                  </h2>
                  <div className="banner-btn align-btn">
                    <button onClick={() => registerOpen("login")} className="btn">
                      Buy/Sell
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-banner-content reliable-source">
              <div className="container">
                <div className="slider-content">
                  <h2>
                    RELAIBLE SOURCING,<br></br>
                    <span className="banner-sm-text">
                      EXCEPTIONAL QUALITY
                    </span>
                  </h2>
                  <div className="banner-btn">
                    <Link to='/contactUs' className="btn">
                      Schedule a demo
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-banner-content trusted-partner">
              <div className="container">
                <div className="slider-content">
                  <h2>
                    A TRUSTED PARTNER<br></br>
                    <span className="banner-sm-text">
                      FOR METAL SOURCING
                    </span>
                  </h2>
                  <div className="banner-btn">
                    <Link to='/product-list' className="btn">
                      Check our products
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      )}
    </>
  );
};

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      bannerImages: [],
    };
  }

  exploreHandler = () => {
    let checkLoginStatus = Authorization();
    if (!checkLoginStatus.status) {
      let query = { ...parse(this.props.location.search) };
      query.tab = "sign-up";
      let url = stringify(query);
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: url,
      });
      this.props.setLoginPopupStatus({ status: true, screenToShow: "sign-up" });
    } else {
      this.props.history.push("/profile");
    }
  };

  componentDidMount() {
    this.props.getBannerImg({ type: "slider" });
  }

  static getDerivedStateFromProps = (props, state) => {
    const propsLength =
      (props &&
        props.bannerImg &&
        props.bannerImg.data &&
        props.bannerImg.data.length) ||
      0;
    const stateLength = state.bannerImages.length;
    if (propsLength === stateLength && stateLength !== 0) return { ...state };
    const bannerData = get(
      props,
      ["bannerImg", "data", [0], isMobile() ? "images_for_images" : "images"],
      []
    );
    const id = get(props, ["bannerImg", "data", [0], "_id"], "");
    const structuredTrendsData = bannerData.map((item) => {
      return {
        bannerName: item.name,
        link: item.link,
        caption: item.caption,
        img: `${item.name}`,
        id: id,
      };
    });
    return {
      ...state,
      bannerImages: structuredTrendsData,
    };
  };

  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  render() {
    const settings = {
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
      fade: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="bnr">
        {this.state.bannerImages.length !== 0 ? (
          <Slider {...settings} ref={(c) => (this.slider = c)}>
            {this.state.bannerImages.map((data, index) => {
              return (
                <BannerBlock
                  index={index}
                  key={index}
                  {...data}
                  next={this.next}
                  previous={this.previous}
                  exploreHandler={this.exploreHandler}
                  registerOpen={this.props.registerOpen}
                  openWaitList={this.props.openWaitList}
                />
              );
            })}
          </Slider>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  allData: state,
  bannerImg: state.dashboardInfo.bannerImage,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerImg: (payload) => dispatch(getBannerImg(payload)),
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Banner));

// const Banner = () => {
//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };
//   return (
//       <div className="home-banner ">
//         <Slider {...settings}>
//             <div className="home-banner-content">
//             <div className="container">
//             <div className="slider-content">
//               <h2>
//               YOUR<br></br>
//            ONE-CLICK ACCESS<br></br>
//                 <span className="banner-sm-text">
//                 TO A WORLD OF FERRO ALLOYS
//                 </span>
//               </h2>
//             <div className="banner-btn align-btn">
//             <Link to='/' className="btn">
//               Buy/Sell
//               </Link>
//             </div>
//               </div>
//             </div>
//             </div>
//             <div className="home-banner-content reliable-source">
//             <div className="container">
//             <div className="slider-content">
//               <h2>
//               RELAIBLE SOURCING,<br></br>
//                 <span className="banner-sm-text">
//                EXCEPTIONAL QUALITY
//                 </span>
//               </h2>
//               <div className="banner-btn">
//               <Link to='/' className="btn">
//               Schedule a demo
//               </Link>
//               </div>
//               </div>
//             </div>
//             </div>
//             <div className="home-banner-content trusted-partner">
//             <div className="container">
//             <div className="slider-content">
//               <h2>
//               A TRUSTED PARTNER<br></br>
//                 <span className="banner-sm-text">
//                 FOR METAL SOURCING
//                 </span>
//               </h2>
//               <div className="banner-btn">
//               <Link to='/' className="btn">
//               Check our products
//               </Link>
//               </div>
//               </div>
//             </div>
//             </div>
//         </Slider>
//       </div>
//   );
// };

// export default Banner;
