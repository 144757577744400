import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { connect } from "react-redux";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Auth from "./components/Auth";
import ErrorBoundary from "./components/Error/ErrorBoundry";
import { Authorization } from "./utils/Authorization";
import { setLoggedUserInfo, setNetworkStatus } from "./store/actions/common";
import { geolocated } from "react-geolocated";
import { ToastProvider } from "react-toast-notifications";
import "dotenv/config.js";
import ScrollToTop from "./ScrollToTop";
import SeconderyHeader from "./components/MainPage/seconderyHeader";
import HeaderMaster from "./components/HeaderMaster/HeaderMaster";
import FooterMaster from "./components/FooterMaster/FooterMaster";

const App = (props) => {
  const loginInfo = Authorization();
  const getTokenInfo = () => {
    if (loginInfo.status) {
      props.setLoggedUserInfo({
        isLoggedIn: loginInfo.status,
        email: loginInfo.payload.email,
        userId: loginInfo.userId,
        userName: loginInfo.payload && loginInfo.payload.name && loginInfo.payload.name.split(" ")[0],
      });
    } else {
      props.setLoggedUserInfo({
        isLoggedIn: loginInfo.status,
      });
    }
  };

  useEffect(() => {
    getTokenInfo();
  }, []);

  const showLoginPopup = props.showLoginPopup.status;
  const loc = {
    coords: props.coords,
    isGeolocationAvailable: props.isGeolocationAvailable,
    isGeolocationEnabled: props.isGeolocationEnabled,
    positionError: props.positionError,
  };

  return (
      <ToastProvider autoDismiss={true}>
        <ErrorBoundary>
          <BrowserRouter>
            <ScrollToTop />
            <>
              <HeaderMaster loginInfo={loginInfo} />
              <Routes
                // browser_location={loc}
                loginInfo={loginInfo}
              />
              {showLoginPopup && <Auth showLoginPopup={showLoginPopup} />}
              {/* {window.location.pathname == "/" || loginInfo.status == false ? null : <Footer />} */}
              <FooterMaster />
            </>
          </BrowserRouter>
        </ErrorBoundary>
      </ToastProvider>
  );
};

const mapStateToProps = (state, ownProps) => ({
  showLoginPopup: state.generalInfo.showLoginPopup,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedUserInfo: (loggedUserInfo) =>
      dispatch(setLoggedUserInfo(loggedUserInfo)),
    setNetworkStatus: (data) => dispatch(setNetworkStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

// geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   watchPosition: true,
//   userDecisionTimeout: 5000,
// })
