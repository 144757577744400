import React, { useEffect, useState } from "react";
import ProductCard from "../Cart/ProductCard";
import { decode } from "js-base64";
import { priceWithSymbol } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getSeller } from "../../store/actions/sellerRegisterationAction";
import { useDispatch, useSelector } from "react-redux";

const OrderDetails = ({
  couponData,
  cartData,
  appliedCouponHandler,
  shippingCost,
  appliedCouponAmt,
  isShippingAvail,
  query,
  updateQtyForBuyNowAndMakeOffer,
  shippingDetail,
  quantityError,
  val,
}) => {
  const getSellerData = useSelector((state) => state.getSeller);
  const dispatch = useDispatch();
  let totalprice = 0;
  const location = useLocation();
  const [productPrice, setProductPrice] = useState(null);
  const [productId, setProductId] = useState(null);
  const [viewStatus, setViewStatus] = useState(false);

  useEffect(() => {
    val(productPrice);
  }, [productPrice]);

  const appliedCouponProds = () => {
    let couponids = couponData.cart.map((item) => item._id);
    return couponids;
  };

  useEffect(() => {
    console.log("location.state", location.state);
    if (location.state) {
      if (location.state.data[0]) {
        if (
          location.state.page == "Listing" &&
          location.state.data.loadingCharge &&
          location.state.data.insuranceCharge
        ) {
          let price = parseInt(location.state.data.productPrice.$numberDecimal);
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (location.state.page == "Listing") {
          let price = parseInt(
            location.state.data.productPrice &&
              location.state.data.productPrice.$numberDecimal
          );
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          (location.state.data[0].loadingCharge == null ||
            !location.state.data[0].loadingCharge) &&
          (location.state.data[0].insuranceCharge == null ||
            !location.state.data[0].insuranceCharge)
        ) {
          let price = parseInt(
            location.state.data[0].productPrice &&
              location.state.data[0].productPrice.$numberDecimal
          );
          let gst =
            location.state.data[0].gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data[0].gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.data[0].loadingCharge !== null &&
          location.state.data[0].insuranceCharge == null
        ) {
          let price = parseInt(
            location.state.data[0].productPrice.$numberDecimal
          );
          let gst =
            location.state.data[0].gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data[0].gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.data[0].loadingCharge == null &&
          location.state.data[0].insuranceCharge !== null
        ) {
          let price =
            parseInt(location.state.data[0].productPrice.$numberDecimal) +
            parseInt(location.state.data[0].insuranceCharge.$numberDecimal);
          let gst =
            location.state.data[0].gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data[0].gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.data[0].loadingCharge &&
          location.state.data[0].insuranceCharge
        ) {
          let price = parseInt(
            location.state.data[0].productPrice.$numberDecimal
          );
          let gst =
            location.state.data[0].gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data[0].gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        }
      } else {
        if (
          location.state.page == "Listing" &&
          location.state.data.loadingCharge &&
          location.state.data.insuranceCharge
        ) {
          let price = location.state.data.productPrice && parseInt(location.state.data.productPrice.$numberDecimal) || 0;
          let gst =
            location.state.data.gst &&
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.page == "Listing" &&
          location.state.data.loadingCharge == null &&
          location.state.data.insuranceCharge == null
        ) {
          let price = parseInt(
            location.state.data.productPrice &&
              location.state.data.productPrice.$numberDecimal
          );
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          (location.state.data.loadingCharge == null ||
            !location.state.data.loadingCharge) &&
          (location.state.data.insuranceCharge == null ||
            !location.state.data.insuranceCharge)
        ) {
          let price = parseInt(
            location.state.data.productPrice &&
              location.state.data.productPrice.$numberDecimal
          );
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.data.loadingCharge !== null &&
          location.state.data.insuranceCharge == null
        ) {
          let price = parseInt(
            location.state.data.productPrice &&
              location.state.data.productPrice.$numberDecimal
          );
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.data.loadingCharge == null &&
          location.state.data.insuranceCharge !== null
        ) {
          let price =
            parseInt(
              location.state.data.productPrice &&
                location.state.data.productPrice.$numberDecimal
            ) + parseInt(location.state.data.insuranceCharge.$numberDecimal);
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.page == "Listing" &&
          location.state.data.loadingCharge !== null &&
          location.state.data.insuranceCharge == null
        ) {
          let price = parseInt(
            location.state.data.productPrice &&
              location.state.data.productPrice.$numberDecimal
          );
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.page == "Listing" &&
          location.state.data.loadingCharge == null &&
          location.state.data.insuranceCharge !== null
        ) {
          let price = parseInt(
            location.state.data.productPrice &&
              location.state.data.productPrice.$numberDecimal
          );
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        } else if (
          location.state.data.loadingCharge &&
          location.state.data.insuranceCharge
        ) {
          let price = parseInt(location.state.data.productPrice.$numberDecimal);
          let gst =
            location.state.data.gst.$numberDecimal != "NaN"
              ? parseInt(location.state.data.gst.$numberDecimal)
              : 18;
          let price_gst = (price / 100) * gst;
          let total_price = price + price_gst;
          setProductPrice(total_price);
        }
      }
    } else {
      // window.location = "/"
    }
  }, []);

  const getOnlyQty = (e) => {
    if (location.state.page == "Listing") {
      let prodPrice = parseInt(location.state.data.productPrice.$numberDecimal);

      let totalProdPrice = prodPrice * e;
      let gst =
        location.state.page == "Listing"
          ? parseInt(location.state.data.gst.$numberDecimal)
          : location.state.gst;

      let price_gst = (totalProdPrice * gst) / 100;
      setProductPrice(price_gst + totalProdPrice);
    } else {
      let prodPrice = parseInt(
        location.state.data[0].productPrice.$numberDecimal
      );
      let totalProdPrice = prodPrice * e;
      let gst =
        location.state.page == "Listing"
          ? parseInt(location.state.data[0].gst.$numberDecimal)
          : location.state.gst;

      let price_gst = (totalProdPrice * gst) / 100;
      setProductPrice(price_gst + totalProdPrice);
    }
  };
  console.log("location.state", location.state);
  return (
    <div className="summary-section checkout-summary">
      {location.state && (
        <div>
          <h4 className="summary-title">order summary</h4>
          <div className="checkout-cardwrap">
            <ProductCard
              appliedCouponHandler={appliedCouponHandler}
              query={query}
              type="checkout"
              makeOffer={query.perk}
              isShippingAvail={isShippingAvail}
              updateQtyForBuyNowAndMakeOffer={updateQtyForBuyNowAndMakeOffer}
              shippingDetail={shippingDetail}
              quantityError={quantityError}
              productData={
                location.state.page == "Listing"
                  ? location.state.data
                  : location.state.data[0]
              }
              getOnlyQty={getOnlyQty}
            />
          </div>

          <div className="payment-detail-wrapper">
            <div className="delivery-detail">
              <span className="product-billing amt">Total Amount :</span>
              <span className="product-billing value">
                {priceWithSymbol(productPrice)}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
