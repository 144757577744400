import * as types from "./../actionsTypes";
import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";





export const offerPayment = (id, callback) => {
    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .get(apiUrl.productDetail.offer_request_payment_complete + "?id=" + id, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                callback(response.data)
            })
            .catch(e => {

            })
    };
};


export const getOffersListBuyer = (payload) => {
    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.buyerOfferRequestList, payload, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                if (response.data.status === "success") {
                    dispatch({
                        type: types.OFFERLIST_BUYER,
                        payload: response.data
                    })
                }
            })
            .catch(e => {

            })
    };
};



export const getOffersListSeller = (payload) => {
    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.sellerOfferRequestList, payload, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                if (response.data.status === "success") {
                    dispatch({
                        type: types.OFFERLIST_SELLER,
                        payload: response.data
                    })
                }
            })
            .catch(e => {

            })
    };
};





export const offerDetail_seller = (data, callback) => {

    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.offerDetailsSeller, data, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                if (response.data.status === "success") {
                    callback(response.data.data)
                } else {
                    callback(response.data.data)
                }
            })
            .catch(e => {
                callback({})
            })
    };
};



export const offerDetail_buyer = (data, callback) => {
    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.offerDetailsBuyer, data, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                if (response.data.status === "success") {
                    callback(response.data.data)
                } else {
                    callback(response.data.data)
                }
            })
            .catch(e => {
                callback({})
            })
    };
};


export const rejectOfferByBuyer = (data, callback) => {
    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.rejectOfferByBuyer, data, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                if (response.data.status === "success") {
                    callback(response.data)
                }
            })
            .catch(e => {
                callback({ status: 'error' })
            })
    };
};



export const priceUpdates = (data, callback) => {
    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.updateOfferRequest, data, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                callback(response.data)
            })
            .catch(e => {
                callback({})
            })
    };
};



export const rejectOfferBySeller = (data, callback) => {
    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.rejectOfferBySeller, data, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                if (response.data.status === "success") {
                    callback(response.data.data)
                }
            })
            .catch(e => {
                callback({})
            })
    };
};


export const acceptOfferBySeller = (data, callback) => {

    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.accpetOffer_seller, data, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                callback(response.data)
            })
            .catch(e => {
                callback({})
            })
    };
};


export const counterOffer_seller = (data, callback) => {

    let token = localStorage.getItem("token");
    return (dispatch) => {
        return axiosInstance
            .post(apiUrl.productDetail.counterOfferRequest, data, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                callback(response.data)
            })
            .catch(e => {
                callback({})
            })
    };
};