export const timeFunction = (date) => {
  const temp = new Date(date);

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return `${temp.getDate()}/${temp.getMonth() + 1}/${temp
      .getFullYear()
      .toString()
      .substr(-2)}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${temp.getDate()}/${temp.getMonth() + 1}/${temp
      .getFullYear()
      .toString()
      .substr(-2)}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${temp.getDate()}/${temp.getMonth() + 1}/${temp
      .getFullYear()
      .toString()
      .substr(-2)}`;
    // return Math.floor(interval) + " d";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hr";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " min";
  }
  return Math.floor(seconds) + " sec";
};
