import React, { Component } from "react";
import "../Profile.css";

const range = (min, max) =>
  Array(max - min + 1)
    .fill()
    .map((_, i) => min + i);

const RatingItem = ({ checked, colored, onChange, value }) => (
  <label className={`rating__item ${colored ? "rating__item--selected" : ""}`}>
    <input
      checked={checked}
      className="rating__input"
      onChange={(e) => onChange(value)}
      type="radio"
      value={value}
    />
  </label>
);

export const Rating = ({ min, max, onChange, value }) => {
  return (
    <div className="rating form-input">
      <div className="required-wrapper rating-required-wrapper"> <span className="rating-text">Rating<span className="required-field">*</span></span></div>
      {range(min, max).map((item) => (
        <RatingItem
          colored={value >= item}
          checked={value === item}
          value={item}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

const RatingPopup = (props) => {
  return (
    <div className="popup-wrap">
      <div className="signup-form rating-popup">
        <button className="close-btn" onClick={props.closePopUp}>
          <i className="icon-close"></i>
        </button>
        <h2>Rate the User</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the
        </p>
        <Rating
          min={1}
          max={5}
          onChange={(rating) => props.setRating(rating)}
          value={props.rating}
        />
        <button className="verify-btn" onClick={props.submitRating}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default RatingPopup;
