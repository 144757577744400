
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { getProductList, getAllAttributesForProductListing, getAllFilterData } from "../../store/actions/productListing";
import { setLoginPopupStatus, getTotalUnreadMsgAndWishlistItem } from "../../store/actions/common";
import { getUseLocation } from "../../store/actions/common";
import NewInHeader from "./NewInHeader";
import ProductScreen from "./ProductScreen";
import { withRouter } from "react-router-dom";
import "./ProductListing.css";
import { SpinnerLoader } from "../../utils/constants";
import { StaticBlock } from "./StaticBlock";
import { parse, stringify } from "query-string";
import { isEmpty, mapApi } from '../../utils/commonFunctions'
import Filter from "../Profile/SellerProfile/Filter/Filter"
import swal from 'sweetalert';
import Loader from '../Loader';



const ProductListingPage = (props) => {
  let dispatch = useDispatch();

  let location = props.location;
  let query = parse(location.search);


  const [filterData, setFilterData] = useState({});
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches)
  const [filterOnMob, setFilterOnMob] = useState(query);
  const [penDiv, setOpenDiv] = useState(false);
  const [closeModal, setCloseModal] = useState(true);
  const userLocation = useSelector((state) => state.generalInfo.userLocation);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");


  const [state, setState] = useState({
    showLoader: true,
    NoProduct: false
  });


  const useCurrentLoc = () => {
    if (props.browser_location.coords) {
      props.history.push(
        `/product-listing-page/${"Local"}?category=${"Local"}&lat=${props.browser_location.coords.latitude}&long=${props.browser_location.coords.longitude}`
      )
    } else {
      swal("Please enable your location")
    }
  }

  const applyCurrentLocation = (radius) => {
    if (props.browser_location.coords) {
      props.history.push(
        `/product-listing-page/${"Local"}?category=${"Local"}&lat=${props.browser_location.coords.latitude}&long=${props.browser_location.coords.longitude}&radius=${radius}`
      )
    } else {
      swal("Location is disabled, Please enable manually");
    }
  }

  useEffect(() => {

    setShowLoader(true);
    setShowMessage("Please wait...");
    // if (props.browser_location.coords) {
    //   let myLat = props.browser_location.coords.latitude;
    //   let myLon = props.browser_location.coords.longitude;
    //   getUseLocation(myLat, myLon, mapApi)(dispatch)
    // }

  }, [])


  useEffect(() => {
    prodList({ page: 1 })
  }, [location]);


  const prodList = ({ page }) => {

    let query = parse(location.search);
    let filter = {};
    Object.keys(query).forEach(item => {
      if (item === "price") {
        filter[item] = query[item].split('-');
      } else if (item === "product_data") {
        filter[item] = JSON.parse(query[item]);
      } else if (item === "type") {
        filter[item] = query[item];
      } else {
        filter[item] = query[item].split(',');
      }
    })

    props.getProductList({ ...filter, page: page, pinCode: filter && filter.deliveryZipCode && filter.deliveryZipCode[0] }, (status) => {
      if (status) {
        setTimeout(() => setShowLoader(false), 1000)
      } else {
        swal("Timeout: Something went wrong.")
        setTimeout(() => setShowLoader(false), 1000)
      }
    });
  }


  const filterInMob = (e, val, name) => {
    let newState = { ...filterOnMob };
    if (e.target.checked) {
      if (newState[name]) {
        if (name === "delivery") {
          newState[name] = val;
        } else {
          newState[name] = newState[name] + "," + val;
        }
      } else {
        newState[name] = val;
      }
    } else {
      let newVal = newState[name].split(",");
      let newnewState = newVal.filter((item) => item !== val);
      newState[name] = newnewState.toString();
    }
    setFilterOnMob(newState)
  }


  const loadMoreProd = ({ page }) => {
    prodList({ page: props.productList.productList.page + 1 })
  }


  useEffect(() => {
    if (props.productList.productList.FilterData) {
      let query = parse(location.search);
      let filter = {};
      Object.keys(query).forEach(item => {
        if (item === "category") {
          if (query[item] === "Local") {
            filter.attributeType = "local"
          } else if (query[item] === "Popular") {
            filter.attributeType = "popular"
          } else {
            let newData = props.productList.productList.filterArray.filter(item => item.category);
            filter.categoryId = newData[0] && newData[0].category[0];
          }

        } else if (query[item] === "delivery") {
          filter.attributeType = "delivery"
        } else if (item === "tagId") {
          let newData = props.productList.productList.filterArray.filter(item => item.tagId);
          filter.brandId = newData[0] && newData[0].tagId;
        } else if (item === "block_name" || item === "searchValue") {
          filter.attributeType = "homepage"
        } else if (query[item] === "Similar product") {
          filter.attributeType = "similarProducts"
        }
      })

      getAllFilterData({ FilterData: props.productList.productList.FilterData, ...filter, browser_ip: "49.249.246.150" }, {}, callback => {
        if (!isEmpty(callback)) {
          setFilterData(callback)
        } else {
          setFilterData({})
        }
      })(dispatch)
    }
  }, [props.productList.productList])


  const handlerCategoryFilter = (val, name, cSlug) => {
    let query = parse(location.search);
    if (name === "category") {
      query[name] = val;
      if (matches) {
        setOnURL(location.pathname, { ...query, category: cSlug, [name]: val });
      } else {
        let newState = { ...filterOnMob };
        newState[name] = val;
        setFilterOnMob(newState)
      }
    } else {
      if (matches) {
        setOnURL(location.pathname, { ...query, category: cSlug, [name]: val });
      } else {
        let newState = { ...filterOnMob };
        newState.category = cSlug;
        newState[name] = val;
        setFilterOnMob(newState)
      }
    }
  };


  const priceFilter = (e, val, name) => {
    let query = parse(location.search);
    let newVal = [val.min + "-" + val.max];
    query[name] = newVal;
    if (matches) {
      setOnURL(location.pathname, query);
    } else {
      let newState = { ...filterOnMob };
      let newVal = [val.min + "-" + val.max];
      newState[name] = newVal;
      setFilterOnMob(newState)
    }
  };


  const mainFilterHandler = (e, val, name, labelChecked) => {
    let query = parse(location.search);
    if (matches) {
      if (e.target.checked || labelChecked == true) {
        if (query[name]) {
          if (name === "delivery") {
            query[name] = val;
          } else {
            query[name] = query[name] + "," + val;
          }
        } else {
          query[name] = val;
        }
      } else {
        let newVal = query[name].split(",");
        let newquery = newVal.filter((item) => item !== val);
        query[name] = newquery.toString();
      }
      setOnURL(location.pathname, query);
    } else {
      filterInMob(e, val, name)
    }
  }


  const smallScreenFilter = () => {
    setCloseModal(false);
    setOpenDiv(true);
  }

  const closeSmallScreenFilter = () => {
    setCloseModal(true);
    setOpenDiv(false);
    setFilterOnMob(query)
  }

  const attrFilterHandlerOnMob = (e, val, name) => {
    let newState = { ...filterOnMob };
    if (e.target.checked) {
      let product_data = { [name]: [val] }
      if (newState.product_data) {
        let newStateVal = JSON.parse(newState.product_data);
        if (Object.keys(newStateVal).filter(item => item === name).length > 0) {
          newStateVal[name].push(val);
          newState.product_data = JSON.stringify(newStateVal);
        } else {
          const returnedTarget = Object.assign(newStateVal, product_data);
          newState.product_data = JSON.stringify(returnedTarget);
        }
      } else {
        newState.product_data = JSON.stringify(product_data);
      }
    } else {
      let newStateVal = JSON.parse(newState.product_data);
      let removeAttr = newStateVal[name].filter(item => item !== val);
      newStateVal[name] = removeAttr;
      newState.product_data = JSON.stringify(newStateVal);
    }
    setFilterOnMob(newState)
  }

  const attrFilterHandler = (e, val, name) => {

    let query = parse(location.search);
    if (e.target.checked) {
      let product_data = { [name]: [val] }
      if (query.product_data) {
        let queryVal = JSON.parse(query.product_data);
        if (Object.keys(queryVal).filter(item => item === name).length > 0) {
          queryVal[name].push(val);
          query.product_data = JSON.stringify(queryVal);
        } else {
          const returnedTarget = Object.assign(queryVal, product_data);
          query.product_data = JSON.stringify(returnedTarget);
        }
      } else {
        query.product_data = JSON.stringify(product_data);
      }
    } else {
      let queryVal = JSON.parse(query.product_data);
      let removeAttr = queryVal[name].filter(item => item !== val);
      queryVal[name] = removeAttr;
      query.product_data = JSON.stringify(queryVal);
    }

    if (matches) {
      setOnURL(location.pathname, query);
    } else {
      attrFilterHandlerOnMob(e, val, name)
    }
  }


  const removeFilters = (item) => {
    let filteredArr = JSON.parse(localStorage.getItem("filteredArr"));
    delete filteredArr[item]
    localStorage.setItem("filteredArr", JSON.stringify(filteredArr));
    let query = parse(location.search);
    delete query[item]
    setOnURL(location.pathname, query);
  }

  const clearAll = () => {
    let query = parse(location.search);
    delete query.brand;
    delete query.delivery;
    delete query.condition;
    delete query.price;
    delete query.product_data;
    delete query.sortby;

    setOnURL(location.pathname, query);
    setFilterOnMob(query)
  }

  const setOnURL = (path, query) => {
    let url = stringify(query).replace(/%23/g, "#");
    props.history.push({
      pathname: path,
      search: url,
    });
  };


  const applyBtnHandler = () => {
    let query = parse(location.search);
    let queryANdOwnData = {
      ...query, ...filterOnMob
    }
    setOnURL(location.pathname, queryANdOwnData);
    setCloseModal(true);
    setOpenDiv(false);
  }


  return (
    <>
      {matches && (
        <div>
          {showLoader ? <Loader message={showMessage} /> : null}
          {
            !isEmpty(filterData) &&
            <StaticBlock data={filterData.static_block_data} position={1} />
          }
          <div
            className="container"
          >
            {
              <NewInHeader
                prodLength={props.prodLength}
                noProduct={state.NoProduct}
                userLocation={userLocation}
                filterData={filterData}
                useCurrentLoc={useCurrentLoc}
                applyCurrentLocation={applyCurrentLocation}
                filter_data={props.productList.productList.data}
              />
            }

            <div className={!isEmpty(filterData) ? "plp-main-screen mainlisting-wrap" : "plp-main-screen"}>

              {
                Object.keys(filterData).length > 0 ?
                  <Filter
                    filterData={filterData}
                    sellerId={props.match.params.id}
                    handlerCategoryFilter={handlerCategoryFilter}
                    mainFilterHandler={mainFilterHandler}
                    location={location}
                    priceFilter={priceFilter}
                    attrFilterHandler={attrFilterHandler}
                    applyBtnFilter={applyBtnHandler}
                    filterOnMob={filterOnMob}
                  /> :
                  // <div className="plp-category"></div>
                  null
              }

              <ProductScreen
                filter_data={props.productList.productList}
                removeFilters={removeFilters}
                location={location}
                loadMoreProd={loadMoreProd}
                clearAll={clearAll}
                getWishCount={props.getTotalUnreadMsgAndWishlistItem}
              />

            </div>
          </div>
          {
            !isEmpty(filterData) &&
            <>
              <StaticBlock data={filterData.static_block_data} position={2} />
              <StaticBlock data={filterData.static_block_data} position={3} />
            </>
          }

        </div>
      )}
      {!matches && (

        <div>
          {showLoader ? <Loader message={showMessage} /> : null}
          {
            !isEmpty(filterData) &&
            <StaticBlock data={filterData.static_block_data} position={1} />
          }
          <div
            className="container"
          >
            {
              <NewInHeader
                prodLength={props.prodLength}
                noProduct={state.NoProduct}
                smallScreenFilter={smallScreenFilter}
                userLocation={userLocation}
                filterData={filterData}
                useCurrentLoc={useCurrentLoc}
                applyCurrentLocation={applyCurrentLocation}
                filter_data={props.productList.productList.data}
              />
            }

            <div className={!isEmpty(filterData) ? "plp-main-screen mainlisting-wrap" : "plp-main-screen"}>
              {
                !isEmpty(filterData) ?
                  <Filter
                    filterData={filterData}
                    sellerId={props.match.params.id}
                    handlerCategoryFilter={handlerCategoryFilter}
                    mainFilterHandler={mainFilterHandler}
                    location={location}
                    priceFilter={priceFilter}
                    attrFilterHandler={attrFilterHandler}
                    closeSmallScreenFilter={closeSmallScreenFilter}
                    closeModal={closeModal}
                    getdivstatus={penDiv}
                    clearAll={clearAll}
                    applyBtnFilter={applyBtnHandler}
                    filterOnMob={filterOnMob}

                  />
                  : <div className="plp-category"></div>
              }

              <ProductScreen
                filter_data={props.productList.productList}
                removeFilters={removeFilters}
                location={location}
                loadMoreProd={loadMoreProd}
                clearAll={clearAll}
                getWishCount={props.getTotalUnreadMsgAndWishlistItem}
              />

            </div>
          </div>
          {
            !isEmpty(filterData) &&
            <>
              <StaticBlock data={filterData.static_block_data} position={2} />
              <StaticBlock data={filterData.static_block_data} position={3} />
            </>
          }

        </div>

      )}
    </>


  )
}



const mapStateToProps = (state, ownProps) => {
  return {
    subcat: state.productListingInfo.subcat,
    productList: state.productListingInfo,
    productListData: state.productListingInfo.productList.data,
    prodLength: state.productListingInfo.productList.all_records,
    filter_Attr_array: state.productListingInfo.productList.filterArray,
    filter_data: state.productListingInfo.attributeList,
    path: state.productListingInfo.productList.PRODUCTIMAGEPATH,
    wishlist: state.Wishlist.wishlist,
    categories: state.productListingInfo.categories,
    brands: state.productListingInfo.brands,
    ipAddress: state.generalInfo.ip_address,
    page_no: state.productListingInfo.productList.page,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (payload, callback) => {
      dispatch(getProductList(payload, callback));
    },
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
    getAttributes: (payload, query) =>
      dispatch(getAllAttributesForProductListing(payload, query)),
    getTotalUnreadMsgAndWishlistItem: (payload) => dispatch(getTotalUnreadMsgAndWishlistItem(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductListingPage)
);
