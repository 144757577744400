import React, { Component } from "react";
import { setDescription } from "../../store/actions/sellItem";
import { connect } from "react-redux";
import { Error, PriceError } from "./index11";
import { useToasts } from "react-toast-notifications";
import { CKEditor } from 'ckeditor4-react';
import Slider from "react-slick";

var settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 945,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EDITMODE: props.itemDescription.EDITMODE || false,
    };
  }

  getSelectedCond = (match) => {
    let result = this.props.conditionOptions.filter(obj => obj.head === match)
    if (result[0]) {
      const payload = {
        ...result[0], active: true
      }
      return payload
    }
    else return {}
  }

  setCondition = (e) => {
    const { condition } = this.props;
    condition.map((i, j) => {
      if (j.toString() === e.target.id) {
        condition[j].active = true;
      } else {
        condition[j].active = false;
      }
    });
    this.props.enterText(condition[e.target.id], "selectedCondition");
    this.props.enterText(condition, "condition");
  };

  product_tag = (e, index) => {
    const product_tag = [...this.props.product_tag];
    if (!product_tag[index].includes("#")) {
      product_tag[index] = `#${e.target.value}`;
    } else {
      product_tag[index] = e.target.value;
    }
    this.props.enterText(product_tag, "product_tag");
  };


  onChangeDescription = (evt) => {
    var newContent = evt.editor.getData();
    this.props.enterText(newContent, "description")
  };

  render() {
    const { description, product_tag, condition, showErr, selectedCondition } = this.props

    return (
      <div>
        <h5>Description</h5>
        <div className="description-container">
          <div className="label-txt">
            Please share a short description of your item. <span className="highlighted">*</span>
          </div>
          <div className={`desc-field  ${showErr && description === "" ? " ckEditorError" : ""}`}>

            {/* className= {(showErr&&description=="" )? "input-description error-sell-input":"input-description" } */}
            <CKEditor
              initData={description}
              data={description}
              onChange={this.onChangeDescription}
            />
            {showErr && description === "" && <Error />}
          </div>
        </div>
        {/* ####################################################################### */}
        <div>
          <label className="label-txt">Product Tags </label>
          <div className="slct-optns tag-row">
            {[0, 1, 2].map((i) => (
              <div className="optns">
                <input
                  className={"input-url"}
                  type="text"
                  name="product_tag"
                  placeholder="Add a hashtag"
                  value={product_tag[i]}
                  onChange={(e) => this.product_tag(e, i)}
                  maxLength={25}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="condition-wrap">
          <label className="label-txt">Condition <span className="highlighted">*</span></label>
          <div className="">
            {
              <Slider {...settings} ref={(c) => (this.slider = c)}>
                {condition.map((i, j) => (
                  <div className="optns" key={j}>
                    <label className="label-txt" htmlFor={j}>
                      <div
                        className={"condition-card " + (i.active ? "selected" : "")}
                      >
                        <span className={"head"}>{i.head}</span>
                        <br />
                        <span className="desc">{i.desc}</span>
                      </div>
                    </label>
                    <input
                      name="sellCondition"
                      id={j}
                      type="radio"
                      checked={i.active}
                      onChange={this.setCondition}
                      style={{ display: "none" }}
                    />
                  </div>
                ))}
              </Slider>
            }
          </div>
          {showErr && selectedCondition === "" && <Error />}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    itemDescription: state.sellItemInfo.itemDescription,
    // allSell : state.sellItemInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDescription: (itemDescription) =>
      dispatch(setDescription(itemDescription)),
  };
};
export default withToast(connect(mapStateToProps, mapDispatchToProps)(Description));

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

