import React, { useState, useEffect } from "react";
import ReviewHead from "./reviewHead";
import { isEmpty } from "lodash";
import ReviewCard from "./reviewCard";
import { ButtonLoader, SpinnerLoader } from "../../../../utils/constants";
import { ReviewPoup, DeletePopup } from "../../../OrderDetails/index";
import {
  getAllReviewOnMyProducts,
  getAllReviewByUserId,
  addReviewOnProduct,
  editReviewOnProduct,
  deleteReviewOnProduct,
} from "../../../../store/actions/profile";
import Toast from "light-toast";
import "../../Profile.css";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import swal from "sweetalert";
import Loader from "../../../Loader";

const ReviewPage = (props) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [deletePopup, setdeletePopup] = useState(false);
  const [reviewData, setReviewData] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [heading, setHeading] = useState("");
  const [innerLoader, setInnerLoader] = useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState("");
  const location = useLocation();
  const { addToast } = useToasts();

  useEffect(() => {
    setShowLoader(true);
    setLoaderMessage("Please wait...");
    if (location.pathname.split("/")[2] === "seller-review") {
      props.getAllReviewBySellerId({ reviewBy: "Seller", page: 1 });
    } else {
      props.getAllReviewByUserId({ reviewBy: "User", page: 1 });
    }
    setTimeout(() => setShowLoader(false), 1500);
  }, []);

  const loadMore = () => {
    const type = location.pathname.split("/")[2];
    setButtonLoader(true);
    type === "seller-review"
      ? props.getAllReviewBySellerId({
          reviewBy: "Seller",
          page: props.sellerReview.page + 1,
        })
      : props.getAllReviewByUserId({
          reviewBy: "User",
          page: props.reviewAndRating.page + 1,
        });

    setTimeout(() => {
      setButtonLoader(false);
    }, 700);
  };

  const setReviewDataToUpdate = (data) => {
    setRating(data.rating ? data.rating : 0);
    setComment(data.review_comment ? data.review_comment : "");
    setHeading(data.heading ? data.heading : "");
  };

  const submitReview = (editId) => {
    if (
      comment.trim().length < 1 ||
      rating === 0 ||
      heading.trim().length < 1
    ) {
      return Toast.fail("Please fill all the fields and select rating", 2000);
    } else {
      setShowLoader(true);
      setLoaderMessage("Please wait...");
      const payload = {
        product_id: reviewData.ProductId,
        seller_id: reviewData.SellerId,
        user_id: reviewData.UserId,
        rating,
        comment,
        heading,
        review_from: "Web",
      };
      const addReviewPayload = {
        sellerId: reviewData.SellerId,
        buyerId: reviewData.UserId,
        heading,
        rating,
        comment,
      };

      editId
        ? props.editReview({ ...payload, id: editId }, (status) => {
            if (status.data.status) {
              setShowLoader(false);
              addToast(status.message, {
                appearance: "success",
                autoDismissTimeout: 2500,
              });
              props.getAllReviewByUserId({ reviewBy: "User", page: 1 });
              setReviewPopup(false);
            } else {
              setShowLoader(false);
              addToast(status.message, {
                appearance: "error",
                autoDismissTimeout: 2500,
              });
            }
          })
        : props.addReview(payload, (status) => {
            if (status.status === "Success") {
              setShowLoader(false);
              addToast(status.message, {
                appearance: "success",
                autoDismissTimeout: 2500,
              });
              props.getAllReviewByUserId({ reviewBy: "User", page: 1 });
              setReviewPopup(false);
            } else {
              setShowLoader(false);
              addToast(status.message, {
                appearance: "error",
                autoDismissTimeout: 2500,
              });
            }
          });
    }
  };

  const deleteReview = (reviewDetail) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this review!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteReview({ id: reviewDetail._id }, (status) => {
          if (status.status === "Success") {
            swal("Deleted!", status.message);
            props.getAllReviewByUserId({ reviewBy: "User", page: 1 });
          } else {
            addToast(status.message, {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
          }
        });
      } else {
        swal("Your review is safe!");
      }
    });
  };

  const type =
    location.pathname.split("/")[2] === "seller-review"
      ? "Seller_review"
      : "Review";
  const review =
    type === "Seller_review" ? props.sellerReview : props.reviewAndRating;
  return !isEmpty(review) ? (
    <div style={{ position: "relative" }}>
      {showLoader ? <Loader message={loaderMessage} /> : null}
      <div
        className={"blogtags-sec" + (innerLoader ? " spinner-time-div" : "")}
      >
        <ReviewHead data={review} type={type} />

        {review.reviewList.length > 0 ? (
          review.reviewList.map((data, index) => (
            <ReviewCard
              {...data}
              type={type}
              editRev={(state, data) => {
                setReviewDataToUpdate(data);
                setReviewData(data);
                setReviewPopup(state);
              }}
              deleteRev={(state, data) => {
                setReviewDataToUpdate(data);
                setReviewData(data);
                // setdeletePopup(state)
                deleteReview(data);
              }}
            />
          ))
        ) : (
          <div className="product-head product-head-wrapper">
            <div className="review-img">
              <img src="/assets/images/review-img.png" alt="review-img" />
            </div>
            <h6> No Review </h6>
          </div>
        )}

        {review.reviewList.length < review.totalReview ? (
          <div className="plp-loadMore-div">
            <button className="shop-now" onClick={loadMore}>
              {buttonLoader ? (
                <ButtonLoader content={"Loading"} />
              ) : (
                "Load more"
              )}
            </button>
          </div>
        ) : null}

        {reviewPopup ? (
          <ReviewPoup
            onClose={() => setReviewPopup(false)}
            {...reviewData}
            submit={submitReview}
            newRating={rating}
            setRating={setRating}
            newComment={comment}
            setComment={setComment}
            heading={heading}
            setHeading={setHeading}
          />
        ) : null}

        {deletePopup ? (
          <DeletePopup
            onClose={() => setdeletePopup(false)}
            delete={deleteReview}
          />
        ) : null}
      </div>
    </div>
  ) : null;
};
// export default ReviewPage;

const mapStateToProps = (state, ownProps) => {
  return {
    sellerReview: state.userProfile.sellerReview,
    reviewAndRating: state.userProfile.reviewAndRatingByMe,
    userId: state.userInfo.loggedUserInfo.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllReviewBySellerId: (paylaod) =>
      dispatch(getAllReviewOnMyProducts(paylaod)),
    getAllReviewByUserId: (paylaod) => dispatch(getAllReviewByUserId(paylaod)),
    addReview: (addReviewPayload, status) =>
      dispatch(addReviewOnProduct(addReviewPayload, status)),
    editReview: (payload, status) =>
      dispatch(editReviewOnProduct(payload, status)),
    deleteReview: (payload, status) =>
      dispatch(deleteReviewOnProduct(payload, status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewPage);
