import React from 'react';
// import Card from "../../Dashboard/Card";
import Card from "../../ProductListing/Card";
import { isEmpty } from 'lodash';
import { ButtonLoader } from "../../../utils/constants";

const SellerProduct = (props) => {

    return (
        <>
            <div className='seller-listings'>
                <div className="popular-costumes seller-profile">
                    {!isEmpty(props.data) &&
                        props.data.product.map((data, i) => (
                            <Card query={{}} key={i} {...data} pagetype="sellerProd" getWishCount={props.getWishCount} />
                        ))
                    }




                </div>
                {
                    !isEmpty(props.data) &&
                        props.data.product.length < props.data.productCount ? (
                        <div className="plp-loadMore-div">
                            <button className="shop-now" onClick={() => props.loadMore({ page: props.data.page })}>
                                {props.buttonLoader ? <ButtonLoader content={"Loading"} /> : "Load more"}
                            </button>
                        </div>
                    ) : null
                }
                {!isEmpty(props.data) && props.data.product.length === 0 && (
                    <div className="product-head">
                        <div className="product-img">
                            <img src="\assets\images\no-product.png" alt="img"></img>
                        </div>
                        <h6>No Product Found</h6>
                    </div>
                )}
            </div>
        </>
    );
};
export default SellerProduct;