import React from "react";
import Toast from "light-toast";
import swal from "sweetalert";
class InputReason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: [],
      textReason: "",
      validReasons: [],
    };
  }
  componentDidMount() {
    if (this.props.formAction) {
      this.props.reasonList(this.props.formAction, (call) => {
        if (call.data.length > 0) {
          this.setState({ reason: call.data });
        }
      });
    }
  }

  handleChange = (event) => {
    var value = event.target.value;
    let filtered = this.state.validReasons;
    filtered[0] = value;

    this.setState({ validReasons: filtered });
  };

  render() {
    return (
      <div className="make-offer-popup cancel-return">
        <div className="offer-content">
          <button
            className="modal-closeBtn"
            onClick={() => {
              this.props.closePopup(null);
            }}
            type="button"
          >
            {/* <span className="closeBtn-crossLeft"></span>
            <span className="closeBtn-crossRight"></span> */}
            <i className="fal fa-times-circle"></i>
          </button>
          <div className="offer-title heading">{this.props.content}</div>
          <div className="offer-title">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.textReason.length < 20) {
                  this.setState({ showErrormsg: true });
                } else {
                  this.props.submitFun(
                    this.state.textReason,
                    this.state.validReasons
                  );
                  this.setState({ showErrormsg: false });
                }
                // swal("Please enter min 20 char")
                // return false
              }}
            >
              {this.state.reason.length > 0 && (
                <div className="reason-selector">
                  <i className="fas fa-chevron-down"></i>
                  <select
                    className="full-width"
                    onChange={(e) => this.handleChange(e)}
                    required
                  >
                    <option value="">Please select one</option>
                    {this.state.reason.map((i, j) => (
                      <option value={i._id} key={j}>
                        {i.reason}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="text-reason">
                <textarea
                  id="reason"
                  name="reason"
                  placeholder="Comments..."
                  rows="6"
                  cols="50"
                  minLength={10}
                  maxLength={1000}
                  value={this.state.textReason}
                  onChange={(e) =>
                    this.setState({ textReason: e.target.value })
                  }
                />
              </div>
              {this.state.showErrormsg && (
                <span className="character-text" style={{ color: "red" }}>
                  Please enter min 20 character
                </span>
              )}
              <button className="header-sell" onClick={this.props.submitFun}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default InputReason;
