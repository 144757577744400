import React from 'react'
import ShippingBuyerLots from './shippingBuyerLots'

const ShippingModuleBuyer = (props) => {
    return (
        <div class="order-detail-top order-detail-wrapper received-order seller-block buyer-item-info">
            <div class="container order-container">
                <div className='shipping-module-wrp' style={{ marginBottom: "0" }}>
                    <h2>Shipment Details</h2>
                </div>
                {props.item.map((element, index) => {
                    return <ShippingBuyerLots key={index} item={element} />
                })}
            </div>
        </div>
    )
}

export default ShippingModuleBuyer