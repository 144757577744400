import React, { useState, useEffect } from "react";
import CustomReport from "./CustomReport";
import RelatedArticle from "./RelatedArticles";
import { get } from "lodash";
import Loader from "../../../Loader";

const SalesReport = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");

  useEffect(() => {
    setShowLoader(true);
    setLoaderMessage("Please wait...")

    setTimeout(() => {
      setShowLoader(false);
    }, 1500);
  }, [])
  return (
    <div className="Sales-page-wrap">
      {showLoader ? <Loader message={loaderMessage} /> : null}
      <div className="Sales-page">
        <CustomReport
          downloadReport={props.downloadReport}
          createDate={get(props.profileInfo, ["userdata", "createdAt"], false)}
        />
      </div>
      <RelatedArticle
        data={props.salesReports}
        downloadReport={props.downloadReport}
      />

    </div>
  );
};
export default SalesReport;
