import React from "react";
import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {

  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1 className="went-wrong">Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
