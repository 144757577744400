import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/Service'
import ListingProductChild from '../Profile/MainProfile/MyOrder/listingProductChild'
import logo from "../.."
import { Link } from 'react-router-dom'
import Loader from '../Loader'

const OrderModule = () => {
  const [orderData, setOrderData] = useState(null)
  const [orderList, setOrderList] = useState([])
  const [userData, setUserData] = useState(null)
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    let token = localStorage.getItem("tokenUser");
    setLoading(true)
    axiosInstance.post(`/order/myProfile`, {
      page: 1,
      type: "Processed"
    }, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    }).then((response) => {
      setUserData(response.data)
      axiosInstance.post(`${response.data.userdata.userType==="Buyer" ? "/order/myOrder":"/order/SellerOrder"}`, {
        page: 1,
        type: "Processed"
      }, {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        },
      }).then((res) => {
        setLoading(false)
        setOrderData(res.data)
        setOrderList(res.data ? res.data.data:null)
      }).catch(() => {
        setLoading(false)
      });
    }).catch(() => {
      setLoading(false)
    });
  }, [])

  const loadMore = (data) =>{
    let token = localStorage.getItem("tokenUser");
    setLoading(true)
    axiosInstance.post(`${userData.userdata.userType==="Buyer" ? "/order/myOrder":"/order/SellerOrder"}`, {
      page: data.page,
      type: data.type
    }, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    }).then((res) => {
      setLoading(false)
      console.log("res.data",res.data)
      setOrderData(res.data)
      if(res.data && res.data.data){
        setOrderList([...orderList,...res.data.data])
      }
    }).catch(() => {
      setLoading(false)
    });
  }

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("ShippingIdToken");
    localStorage.removeItem("incomplete");
    localStorage.removeItem("isProfileCompleted");
    localStorage.removeItem("approved");
    localStorage.removeItem("pendinModal");
    localStorage.removeItem("userType");
    localStorage.removeItem("childType");
    localStorage.removeItem("subId");
    localStorage.removeItem("tokenUser");
    window.location = "/"
  }

  return (
    <div className=''>
      {loading && <Loader message={"...please wait"} />}
      <div className='header'>
        <div className="primary-header">
          <div className="container-main"><Link className="logo" to="/">
            <img src="/assets/images/metal-buy-logoo.png" alt="logo" itle="logo" /></Link>
            <div className="navbar-wrapper">
              <nav className="navbar">
                <ul className="nav-menu">
                  <li className=""><Link to="/page/aboutUs">About Us</Link></li>
                  <li className=""><Link to="/product-list">Products</Link></li>
                  <li className=""><Link to="/buyer">Buyers</Link></li>
                  <li className=""><Link to="/seller">Sellers</Link></li>
                </ul>
              </nav>
            </div>
            <div className="signup-login-wrapper"><button className="login-btn main-btn" onClick={handleLogout}>Logout</button></div><button
              className="mob-menu"><span className="menu-bar"></span><span className="menu-bar"></span><span
                className="menu-bar"></span></button>
          </div>
        </div>
      </div>
      <ListingProductChild
        myProduct={
          orderData
        }
        listing={orderList}
        userData={userData}
        type={"data.state.tab"}
        userId={"props.userId"}
        loadMore={loadMore}
        // reasonList={props.getReasonList}
        cancelProduct={() => { }}
        returnProduct={() => { }}
        downloadLink={""}
        ProductId={'jhbi'}
      />
    </div>
  )
}

export default OrderModule