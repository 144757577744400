import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Rating } from "../Profile/MainProfile/MyOrder/listingProduct";
import { Link } from "react-router-dom";
import { SpinnerLoader } from "../../utils/constants";
import { useSelector } from "react-redux";
import { RatingStar } from "../ProductDetail/productReview";
import ReactStars from "react-rating-stars-component";
const MoreAction = (props) => {
  console.log(props.orderdetailMain);

  const userProfile = useSelector((state) => state.userProfile);
  const rating = 5;
  console.log(rating);

  const [userType, setUserType] = useState(null);

  useEffect(() => {
    setUserType(
      userProfile &&
      userProfile.userProfileBanner &&
      userProfile.userProfileBanner.userdata &&
      userProfile.userProfileBanner.userdata.userType
    );
  }, [userProfile]);


  return (
    <div className="order-right">
      <span className="order-title" style={{"marginBottom":"9px"}}>
        {`${userType === "Buyer" ? "Seller" : "Buyer"} Feedback`}{" "}
      </span>

      {/* {userType && userType === "Buyer" ? (
        <div>
          {!props &&
            props.orderdetailMain &&
            props.orderdetailMain.seller_average_rating ? (
            <button
              className="rating-delete-btn rating-delete-wrap"
              onClick={() => props.setReviewPopup(true)}
              style={{ marginRight: "5px" }}
            >
              Add review
            </button>
          ) : (
            props &&
            props.orderdetailMain &&
            props.orderdetailMain.seller_average_rating && 
            // <RatingStar
            //   value={props.orderdetailMain.seller_average_rating}
            // />
            <ReactStars size= {30}
            count= {5}
            color= "black"
            activeColor= "red"
            value={props.orderdetailMain.seller_average_rating}
            a11y= {true}
            isHalf= {true}
            emptyIcon={ <i className="fas fa-star" style={{ color: "#D3D3D3" }}></i>}
            halfIcon={ <i className="fa fa-star-half-alt" style={{ color: "#f86202" }} />}
            filledIcon={ <i className="fa fa-star" style={{ color: "#f86202" }} />}
            edit= {false} />
          )}
        </div>
      ) : (
        <div>
          {!props &&
            props.orderdetailMain &&
            props.orderdetailMain.buyer_average_rating
            ? (
              <button
                className="rating-delete-btn rating-delete-wrap"
                onClick={() => props.setReviewPopup(true)}
                style={{ marginRight: "5px" }}
              >
                Add review
              </button>
            ) : (
              props &&
              props.orderdetailMain &&
              props.orderdetailMain.buyer_average_rating && <RatingStar
                value={props.orderdetailMain.buyer_average_rating}
              />
            )}
        </div>
      )} */}

      <h5 style={{"textTransform": "initial","fontWeight": "400", "marginBottom":"10px"}}>{props.orderdetail && props.orderdetail.seller_details && props.orderdetail.seller_details.companyName && props.orderdetail.seller_details.companyName}</h5>

      <button
        className="rating-delete-btn rating-delete-wrap"
        onClick={() => props.setReviewPopup(true)}
        style={{ marginRight: "5px" }}
      >
        Add review
      </button>

      {/* {!isEmpty(props.reviewData) ? (
                <div>
                  <div className="show-review-rating">
                    <ul className="ratings">
                      <Rating val={props.reviewData.rating} />
                    </ul>
                  </div>
                  <button
                    className="rating-delete-btn rating-delete-wrap"
                    onClick={() => props.setReviewPopup(true)}
                    style={{ marginRight: "5px" }}
                  >
                    Edit review
                  </button>
                </div>
              ) : null} */}
    </div>
  );
};

export default MoreAction;
