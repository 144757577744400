import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";

const OTPFormMobileLogin = ({ OTP, time, setOTP, resendOTP }) => {
  // Timer function to count down every second
  useEffect(() => {
    if (time <= 0) return;
    const countdown = setInterval(() => setOTP("time", time - 1), 1000);
    return () => clearInterval(countdown);
  }, [time, setOTP]);

  return (
    <div className="otp-form-container" style={{ marginTop: "10px" }}>
      <div className="enter-mobile-label">Verify OTP</div>
      <div className="otp-input">
        <TextField
          variant="outlined"
          label="Enter OTP"
          required
          value={OTP}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) setOTP("OTP", value);
          }}
          inputProps={{
            maxLength: 6,
          }}
          InputProps={{
            sx: {
              padding: "0px", // Adjust padding as needed
            },
          }}
          InputLabelProps={{
            sx: {
              top: "2px", // Adjust label positioning
            },
          }}
          style={{
            marginTop: "10px",
          }}
        />
      </div>
      <div className="otp-timer" style={{marginTop: "10px"}}>
        <span>{`00:${time < 10 ? `0${time}` : time}`}</span>
      </div>
      <div className="login-condition" style={{marginTop: "10px"}}>
        <button onClick={resendOTP} disabled={time > 0} style={{ fontWeight: "bold", paddingBottom: "0" }}>
          Resend OTP
        </button>
      </div>
    </div>
  );
};

export default OTPFormMobileLogin;
