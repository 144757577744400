import React, { useState } from 'react'
import { axiosInstance } from '../../utils/Service'
import { useSelector } from 'react-redux';

const CounterRfq = ({ setLoader, setOfferPopup, productsDetail, sellerData, userType }) => {
    const userData = useSelector(
      (store) => store.userProfile.userProfileBanner.userdata
    );

    const [payOptions, setPayOptions] = useState([
      { id: 1, name: "Advance Payment" },
      { id: 2, name: "Next Day" },
      { id: 3, name: "7 Day Credit" },
      { id: 4, name: "Other" },
    ]);

    let dFList = [
      { id: 1, name: "FOR" },
      { id: 2, name: "EXW" },
    ];

    const [qty, setQty] = useState({ value: "" })
    const [price, setPrice] = useState({ value: "" })
    const [paymentTerms, setPaymentTerms] = useState("")
    const [delivery, setDelivery] = useState('')
    const [commentBox, setCommentBox] = useState("")

    const [qtyError, setQtyError] = useState(false)
    const [qtyMoqError, setQtyMoqError] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [payTermsError, setPayTermsError] = useState(false)
    const [deliveryError, setDeliveryError] = useState(false)




    const handleQty = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setQty(qty => value.length <= 10 && !isNaN(Number(value)) && { value } || qty)
        setQtyError(false);
        setQtyMoqError(false)
    }

    const handlePrice = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPrice(price => value.length <= 10 && !isNaN(Number(value)) && { value } || price)
        setPriceError(false)
    }

    const handlePaymentTerm = (event) => {
        let value = event.target.value
        setPaymentTerms(value)
        setPayTermsError(false)
    }

    const handleDelivery = (event) => {
        let value = event.target.value
        setDelivery(value)
        setDeliveryError(false)
    }

    console.log("productsDetail",productsDetail)
    const handleCounter = () => {
        if (qty.value.length === 0) {
            setQtyError(true)
            return
        }
        else if (price.value.length === 0) {
            setPriceError(true)
            return
        }
        else if (paymentTerms.length === 0) {
            setPayTermsError(true)
            return
        }
        else if (delivery.length === 0) {
            setDeliveryError(true)
            return
        }
        setLoader(true)
       
        let token = localStorage.getItem("token");
        let apiUrl = `/requestForQuote/seller_counter_offer`
        axiosInstance.post(apiUrl, 
            {
                "requestId": productsDetail.data._id,
                "seller_request_id": sellerData.seller_request_id,
                "sellerId": sellerData.sellerId,
                "buyerId": productsDetail.data.buyerId,
                "quantity": qty.value,
                "price": price.value,
                "paymentType": paymentTerms,
                "delivery": delivery,
                "comments":commentBox,
                "offerFrom": userType
        
        }, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setLoader(false)
            window.location.reload()
        }).catch(() => {
            setLoader(false)
        });
    }

    return (
        <div className='make-offer-popup-new'>
            <div className='offer-popup-content' style={{height:"52%"}}>
                <div className='offer-popup-content-header'>
                    <h3>Counter Offer ({sellerData.companyName})</h3>
                    <button
                        className=""
                        type="button"
                        onClick={() => setOfferPopup(false)}
                    >
                        <i className="fal fa-times-circle"></i>
                    </button>
                </div>
                <div className="slct-optns select-wrapper">
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Quantity (mt){" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                placeholder="Enter quantity"
                                value={qty.value}
                                onChange={handleQty}
                            />
                        </div>
                        {qtyError ? <p className='error'>Please enter quantity</p> : null}
                        {qtyMoqError ? <p className='error'>You cannot enter quantity less than minimum order quantity</p> : null}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Price (INR/mt){" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                placeholder="Enter price"
                                value={price.value}
                                onChange={handlePrice}
                            />
                        </div>
                        {priceError ? <p className='error'>Please enter price</p> : null}
                    </div>
                    <div className="names-wrapper form-block-wrap">
              <div className="input-form-wrap select-affilation-profile address-name-wrap">
                <label className="enter-mobile-label">
                  Select payment terms*
                </label>
                <div className="select-main-wrapper">
                  <select
                    className="make-offer-select-tag"
                    value={paymentTerms}
                    onChange={handlePaymentTerm}
                  >
                    <option hidden>Select payment terms</option>
                    {payOptions.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {/* <i className="icon-arrow-down"></i> */}
                </div>
                {payTermsError ? (
                  <p className="error">Please select payment terms</p>
                ) : null}
              </div>
            </div>
            <div className="names-wrapper form-block-wrap">
            <div className="input-form-wrap select-affilation-profile address-name-wrap">
              <label className="enter-mobile-label">Delivery*</label>
              <div className="form-input">
                <select
                  className="make-offer-select-tag"
                  value={delivery}
                  onChange={handleDelivery}
                >
                  <option hidden>Select Delivery</option>
                  {dFList.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {deliveryError ? (
                <p className="error">Please select delivery</p>
              ) : null}
            </div>
            </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Comment Box
                        </label>
                        <div className="form-input">
                            <textarea value={commentBox} placeholder='Enter comment' onChange={(e) => { setCommentBox(e.target.value) }} rows={3}></textarea>
                        </div>
                    </div>
                    <div className="navigation-back" style={{ flexDirection: "column" }}>
                        <button className="next-btn" onClick={handleCounter}>Counter Offer</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CounterRfq