import React from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { parse } from "query-string";




const BreadCrumb = (props) => {
  const queryString = parse(props.location.search);
  let location = useLocation()
  let param = useParams()
  let history = useHistory()



  const clickhand = (e) => {
    e.preventDefault()
    history.goBack()
  }




  return (
    <div className="breadcrumb-wrap">
      <ul className="breadcrumb">
        <li>
          <Link to="/">Home</Link>
        </li>
        {
          queryString.cat === "Local" ?
            <>
              <li>

                <Link to={`#`}
                  onClick={clickhand}
                >
                  Local
                </Link>
              </li>
              <li>
                {/* <Link to={`/product-listing-page/${props.match.params.catslug}/?category=${props.details && props.details.categoryData[0].slug}`}> */}
                <Link to={{
                                    pathname: (props.details && props.details.categoryData[0].name == "Fines" ? `/listing/${props.details && props.details.categoryData[0]._id}` :
                                      props.details && props.details.categoryData[0].name == "Chips" ? `/listing/${props.details && props.details.categoryData[0]._id}` :
                                        props.details && props.details.categoryData[0].name == "Lumps" ? `/listing/${props.details && props.details.categoryData[0]._id}` : "/"
                                    ),
                                    state: {
                                      categorie: (props.details && props.details.categoryData[0].name == "Fines" ? `${props.details && props.details.categoryData[0]._id}` :
                                        props.details && props.details.categoryData[0].name == "Chips" ? `${props.details && props.details.categoryData[0]._id}` :
                                          props.details && props.details.categoryData[0].name == "Lumps" ? `${props.details && props.details.categoryData[0]._id}` : "/"
                                      )
                                    }
                                  }}>
                  {props.match.params.catslug}
                </Link>

              </li>
            </>
            :
            queryString.cat === "Deliver" ?
              <li>
                <Link
                  to="#"
                  onClick={clickhand}
                >
                  {props.match.params.catslug}
                </Link>

              </li>
              :
              <li>
                {/* <Link
                  to={`/product-listing-page/${props.match.params.catslug}/?category=${props.details && props.details.categoryData[0].slug}`}
                > */}
                 <Link to={{
                                    pathname: (props.details && props.details.categoryData[0].name == "Fines" ? `/listing/${props.details && props.details.categoryData[0]._id}` :
                                      props.details && props.details.categoryData[0].name == "Chips" ? `/listing/${props.details && props.details.categoryData[0]._id}` :
                                        props.details && props.details.categoryData[0].name == "Lumps" ? `/listing/${props.details && props.details.categoryData[0]._id}` : "/"
                                    ),
                                    state: {
                                      categorie: (props.details && props.details.categoryData[0].name == "Fines" ? `${props.details && props.details.categoryData[0]._id}` :
                                        props.details && props.details.categoryData[0].name == "Chips" ? `${props.details && props.details.categoryData[0]._id}` :
                                          props.details && props.details.categoryData[0].name == "Lumps" ? `${props.details && props.details.categoryData[0]._id}` : "/"
                                      )
                                    }
                                  }}>
                  {props.match.params.catslug}
                </Link>
              </li>
        }

        <li>{props.details.Title}</li>
      </ul>
    </div>
  );
};
export default withRouter(BreadCrumb);
