import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import "./index.css";
import App from "./App";
import reducer from "./store/reducer";
import * as serviceWorker from "./serviceWorker";
import "animate.css"

const store = createStore(
  reducer,
  // compose(
  //   applyMiddleware(thunk),
  //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);


let isSlow;
let loaded;

ReactDOM.render(
  <Provider store={store}>
    <App
      onStart={() => {
        loaded = false;
        isSlow = setTimeout(() => {
          // Show "Slow Internet Connection." message.
          document.body.className = "slow";
          console.debug("slow");
        }, 3000); // Intentionally a low number for testing. Try some different values here, or play with different Internet Throttling setting.
      }}
      onLoad={() => {
        loaded = true;
        clearTimeout(isSlow);
        setTimeout(() => { document.body.className = ""; }, 1000); // Hide message after 1s.
        console.debug("loaded");
      }}
      loaded={loaded}
    />
    {/* {app()} */}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
