export const timeFunction = (date) => {
  let dates = new Date(date);
  let seconds = Math.floor((new Date() - dates) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + (Math.floor(interval) === 1 ? " day ago" : " days ago");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }

  return Math.floor(seconds) + " seconds ago";
};

export const color = [
  { key: "Report Your Product", value: "#ff0000" },
  { key: "Order Placed", value: "#588acc" },
  { key: "Message Received", value: "#b5b561" },
  { key: "Like Your Product", value: "#008000" },
  { key: "Product Approval", value: "#733381" },
];

export const getColor = (string) => {
  const index = color.findIndex((x) => x.key === string);
  if (index >= 0) {
    return { background: color[index].value, color: "#fff" };
  }
};
