import React from 'react'

const PaymentFilter = (props) => {
    return (
        <div className='delivery-details payemnt-delivery-details filter-border'>
            <h4>Payment Terms</h4>
            {props.paymentType && props.paymentType.map((item)=>{
              return(
                <label class="check-wrp">{item.name}
                <input type="checkbox" value={item.name} onChange={(e)=>props.typeHandle(e)}/>
                <span class="checkmark1"></span>
            </label>
              )
            })}
            
        </div>
    )
}

export default PaymentFilter