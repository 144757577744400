import React from 'react'

const Terms = () => {
    return (
        <div className='privacy-policy terms-condition'>
            <div className='container-main'>
                <h2>TERMS OF USE</h2>
                <p>Welcome to the Terms of Use (“Terms”) of ALLFERRO TRADEWIZE PRIVATE LIMITED, a private limited company, with its registered office at Ring Road No.2 Gondwara Road Bhanpuri, Raipur, Chhattisgarh,
                    492001 [Company” “We”, “Us”, “AllFerro” or “Our”) operating under its brand name ‘Metalsbuy’.
                </p>
                <p>
                    These Terms are an electronic record in terms of the Information Technology Act, 2000 and the rules that are applicable and as amended from time to time. This electronic record is generated by a computer system and does not require any physical or digital signatures. These Terms are published in accordance with the provisions of Rule 3(1)(a) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 framed under the Information Technology Act, 2000 (as amended from time to time), that require publishing the terms of
                    services and practices for access or usage of the Platform and Services (as defined below).
                </p>
                <div className='privacy-content-wrap'>
                    <h4>DEFINITIONS</h4>
                    <p>All terms used with a capitalized first letter shall have the meaning assigned to them hereunder.</p>
                    <ol>
                        <li>
                            “Applicable Law(s)”  or  “Law(s)”  means,  any  statute,  law,  regulation,  ordinance,  rule, judgment, order, decree, clearance, approval, directive, guideline, code, standard, manual, policy, requirement, or other government restriction or any similar form of decision, or determination by, or any interpretation or administration of any of the foregoing by, any

                            Government Authority as amended from time to time.

                        </li>
                        <li>
                            “Confidential Information” shall mean any and all information and data,
                            in whatever form, disclosed by a Party (the “Discloser”) to the other Party (the “Recipient”) including any and all patent and patent applications, trade secrets, proprietary and confidential information, ideas, samples, media, techniques, drawings, system reports, user interface, portals, data products, data inputs, data thresholds, compression methods, works of authorship, models, inventions, know-how, processes, equipment, algorithms, pricing models, and software object and source code related to the past, current and future products and services of the Discloser, and information concerning research, development, design specifications, financial matters, procurement, shareholders, officers, customers, investors, employees, business relationships, forecasts, sales and marketing plans and any improvements and enhancements thereof, drafts of agreements, or information communicated between the Parties in any way or form; and information received
                            from others that the Discloser is obligated to treat as confidential.

                            Exceptions: Confidential Information shall not include information or matter that the Recipient can provide substantial documented evidence that: (a) was already known to the Recipient prior to disclosure and such prior knowledge can be demonstrated by the Recipient by dated, written records; (b) is independently developed by or for the Recipient without reference to or use of the Confidential Information which can be demonstrated by the Recipient by dated written records; or (c) which at the time of disclosure by the Discloser is generally available to the public or thereafter becomes generally available to the public other than through a breach of any obligation under this Agreement caused by an act or omission on the part of the Recipient.

                            The burden of demonstrating the applicability of any of the exceptions to disclosure of Confidential Information shall be upon the Party seeking to rely on the exception.
                        </li>

                        <li>
                            “Establishment” shall mean a sole-proprietorship/ partnership/ company/ LLP/ trusts/societies.
                        </li>
                        <li>
                            “Platform/Portal” shall mean the Company’s website made available to the Users at www.metalsbuy.com or www.metalsbuy.in
                        </li>
                        <li>
                            “User” or “You” or “Your” shall mean any User that has successfully completed the registration process and has access to the Platform.
                        </li>
                        <li>
                            “Seller”, shall mean a User, authorised by a registered Establishment to register and access the Platform, manage the account and sell the Products to the Buyer in accordance with the Terms detailed herein.
                        </li>

                        <li>
                            “Buyer” shall mean a User authorised by an Establishment to register and access the Platform, manage the account and
                            buy the Products from the Seller in accordance with the Terms detailed herein.
                        </li>

                        <li>
                            “Product(s)” shall mean those products that have been listed on the Platform by the Seller.
                        </li>

                        <li>
                            “Services” shall mean the service on offer in the Portal, including: i) facilitation of buying and selling of Products on the Platform; ii) display and availability of Products on the Platform; iii) such other incidental
                            and ancillary services; and iv) and as provided under clause 8 of these Terms.
                        </li>

                    </ol>
                    <h4>
                        RELATIONSHIP BETWEEN THE PARTIES
                    </h4>
                    <p>The Buyer shall register on the Platform of the Company to purchase Products from the Seller. The Platform and the Company is a mere facilitator between the Buyer and the Seller</p>
                    <h4>UPDATES AND AMENDMENTS</h4>
                    <p>If You do not agree with these Terms, then refrain from using the Platform. We reserve the right to change, modify, amend, or update these Terms from time to time and such amended provisions of these Terms shall be effective immediately upon being posted on the Platform. You can determine when these Terms were last revised by referring to the ‘LAST UPDATED’ at the top of these Terms. Your continued use of the Platform shall be deemed to signify
                        Your acceptance of these provisions or amended provisions of these Terms.</p>

                    <h4>
                        ELIGIBILITY CRITERIA
                    </h4>
                    <ol>
                        <li>
                            Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. Registered User represent and warrant that they have the right to access or use the Platform. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 are not eligible to use the Platform.
                        </li>
                        <li>
                            You must have a valid GST registration and PAN number if operating in India or equivalent documents as per your country of origin if operating outside India, to be able to register on the Platform
                        </li>
                        <li>
                            You must be an authorised representative of your Establishment.
                        </li>
                        <li>
                            International Users: You may register on the Platform if You are based outside the territory of India provided that, You adhere to the laws of India while using the Platform and are not restricted to contract under any applicable law.
                        </li>

                    </ol>

                    <h4>
                        REGISTRATION PROCESS
                    </h4>
                    <ol>
                        <li>
                            The User shall register on the Platform as a Buyer and/or Seller by providing details including but not limited to name of the Establishment, email, password, phone/mobile number etc.
                        </li>
                        <li>
                            On providing such details there shall be a 2 (two) factor authentication via SMS. Users shall be liable for any service charges by their mobile service providers for SMS/data usage.
                        </li>
                        <li>
                            User shall also be required to provide necessary details required to carry out KYC including but not limited to GST registration certificate, PAN card, Aadhar card, address proof, CIN, DIC registration etc.
                        </li>
                        <li>
                            On acceptance and verification of the documents provided by the User, they shall become a registered User and shall be able to access the Platform.
                        </li>
                        <li>
                            When you register on the Platform, we create an account based on the information You have provided. Please ensure that your credentials/password and any other information pertaining to your account are kept confidential to prevent unauthorised access.
                        </li>
                        <li>
                            We will not be liable for any mistake or misuse of your account by either You or by any other person gaining access to the Platform through your account. To clarify, You will solely be responsible for the security of your account.
                        </li>
                        <li>
                            You shall inform Us in the event that there are any changes to Your e-mail, address, mobile number, change in authorisation, control or legal status or cessation of business of the Establishment you are representing. You acknowledge and accept that You shall provide Us with an advance notice in writing of at least thirty (30) business days in such an event.
                        </li>
                    </ol>

                    <h4>GENERAL DISCLAIMERS</h4>
                    <ol>
                        <li>	User must use the Services and the Platform in compliance with all Applicable Laws and regulations.</li>
                        <li>
                            By accessing the Platform, You agree that you meet the Eligibility Criteria (as detailed above under clause 3) and that you are fully able and competent to understand and accept these Terms as a binding contract.
                        </li>
                        <li>
                            You agree that You shall not access the Platform by any other means other than through the interfaces provided by Us.
                        </li>
                        <li>
                            You agree that You shall not acquire, copy, or monitor any portion of the Platform in any way to reproduce or circumvent the navigational structure or presentation of the Platform, to obtain or attempt to obtain any materials, documents, or information through any means not specifically made available through the Platform.
                        </li>
                        <li>
                            You agree that You shall not attempt to reverse engineer, de-encrypt, or otherwise derive the design, internal logic, structure or inner workings (including algorithms and source code) of the Platform, any other software, products, models, prototypes, or other items provided by Us.
                        </li>
                        <li>
                            You agree that You shall not use any deep link, robot, spider or other automatic device or methodology, or any similar or equivalent manual process, to access, acquire, download, copy or monitor the Platform or any part thereof, or obtain any content through means not specifically made available through the Platform.
                        </li>
                        <li>
                            You agree that the Company is not responsible for any error caused due to network related issues, or issues arising through malware /viruses.
                        </li>
                        <li>
                            You agree that You shall not breach any provisions of these Terms or violate any provisions of Applicable law.
                        </li>
                        <li>
                            You are aware that by accessing the Platform and using the Services You transacting at Your sole risk.
                        </li>
                        <li>
                            Your account on the Platform shall be subject to suspension/termination by Company, at the sole discretion of Company, especially in cases of ascertainment of any false information, fraudulent activities etc.
                        </li>

                        <li>
                            You are prohibited from collecting email addresses or other contact information of other users, through any means whatsoever, without authorization from the Company; selling, cross-selling or distributing Services to any third party or allowing multi-user access to the Services by sharing your password and user identification; using any automated software, hardware or any other similar mechanism to use access, navigate or search the website and/or mobile application; and posting or transmitting any information on the website and/or mobile application including unauthorized or unsolicited advertising, promotional materials, or any other forms of unauthorized solicitation to other users (other than the information specifically permitted/ required to be posted or transmitted under these Terms of Use and/or Additional Agreements)
                        </li>

                        <li>
                            The website and/or mobile application may be linked to other websites on the World Wide Web that are not under the control of or maintained by the Company. Such links do not indicate any responsibility or endorsement on our part for the external website concerned, its contents or the links displayed on it. Company has no responsibility or liability for and makes no representations whatsoever about any other website that you may have access to through our Website and/or mobile application. These linked sites are only for your convenience, and therefore, you access them at your own risk. Company shall not be liable for any loss associated with your use of such other websites.
                        </li>
                    </ol>

                    <h4>
                        DISCLAIMER AND LIMITATION OF LIABILITIES OF THE COMPANY
                    </h4>
                    <p>
                        THE COMPANY DOES NOT WARRANTY OR GAURANTEE ANY SALES OR INCREASE IN SALES OF YOUR PRODUCTS NOR DOES IT MAKE ANY REPRESENTATIONS WITH RESPECT TO THE QUALITY OF THE PRODUCTS AVAILABLE ON THE PLATFORM. THE COMPANY IS MERELY A FACILITATOR. THIS PLATFORM IS PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS PLATFORM OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS PLATFORM. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS PLATFORM IS AT YOUR SOLE RISK. COMPANY RESERVES THE RIGHT TO WITHDRAW OR DELETE ANY INFORMATION FROM THIS PLATFORM AT ANY TIME IN ITS DISCRETION. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY DOES NOT WARRANT THAT THIS PLATFORM, ITS SERVERS, OR E-MAIL SENT FROM COMPANY ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PLATFORM, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES.
                    </p>
                    <h4>TERMS OF ENGAGEMENT</h4>
                    <ol>
                        <li>
                            <p>	The Buyer may choose to buy the Product(s) listed by the Seller at the price (inclusive of all taxes) or by sharing an offer to/seeking a quote from the Seller through the interface provided under the Platform. For the Buyer to seek a quote for purchasing the Product on the Platform the Buyer shall furnish the following information:</p>
                            <ul>
                                <li>Detailed Product specification or request as per Seller’s specifications</li>
                                <li>Quantity</li>
                                <li>Delivery window</li>
                                <li>Payment Term (advance, next day of delivery, other relevant information)</li>
                                <li>Delivery City and mode of transportation</li>
                                <li>
                                    Packaging
                                </li>
                                <li>
                                    Pre-Inspection
                                </li>
                                <li>
                                    Tolerance%
                                </li>
                                <li>Any other information as needed by the buyer</li>
                            </ul>
                        </li>
                        <li>
                            The Buyer agrees to provide all information required including but not limited to GST, PAN Number, CIN, address proof etc. and register on the Platform.
                        </li>
                        <li>
                            The Buyer agrees to provide GST, TDS and TCS returns and tax compliance details, and account statements for transactions completed on the Platform if requested by the Company.
                        </li>
                        <li>
                            The Buyer understands and agrees that the Company may at its sole discretion conduct a background verification on the Buyer. The Buyer agrees and gives consent for background verification to be carried out by the Company.
                        </li>
                        <li>
                            The Buyer agrees to comply with the terms of use, privacy policy and all other rules and policies on the Platform.
                        </li>
                        <li>
                            The Buyer shall be solely responsible for protecting the Buyer’s login credentials. Any unauthorized use shall be the responsibility of the Buyer.
                        </li>
                        <li>
                            The Buyer understands and agrees the Company is not responsible for any third-party services availed by the Buyer through the vendor list or quotes provided by the Company.
                        </li>
                        <li>
                            The Buyer agrees that on purchase of the Product from the Seller, the Buyer shall be solely responsible to make the payments and shall take timely delivery of the Products.
                        </li>
                        <li>
                            For non-F.O.R delivery, the Buyer shall be responsible for arranging their own transportation. The Company can help provide contacts of third-party logistics company along with contacts of transit insurance providers through the Platform.
                        </li>
                        <li>
                            Any extension in the delivery timeline must be agreed to between the Buyer and Seller in writing before the expiry of previously agreed delivery timelines.
                        </li>
                        <li>
                            In case of any weighment is required by the Buyer, the Buyer shall inform the same to the Seller before dispatch of the Products. The weighment shall be carried out as per the industry standard or in a local association approved weighbridge, or as per Buyer and Seller’s mutual agreement.
                        </li>
                        <li>
                            The Buyer agrees that all engagement with the Seller shall be through the Platform. The Buyer shall not approach any Seller directly or indirectly to circumvent the Company. The Company reserves the right to collect success fee or any other fee the if the Seller and Buyer engages with each other outside of the Platform.
                        </li>
                        <li>
                            Quality checks: If the Buyer requests for any quality checks after receiving the material, he /she must do so within 24 hrs of receiving the material and any quantity of the received material must not have been consumed (i.e., the entire lot must be intact). The quality check shall be performed by a third party laboratory from a list of Metalsbuy approved laboratories. Based on the quality check report, the party at fault shall be liable for all charges of the laboratory tests, initial cost, travel, transport and any other additional charges.
                        </li>
                        <li>
                            The Buyer agrees not to share any information regarding the paid features with any other user on the Platform or any third Party. The Buyer shall not share or sell any information obtained by the Buyer on the Platform or of the Company to other users or third parties.
                        </li>
                        <li>
                            It is recommended that Buyers avail transit insurance through the Seller or through an insurance marketplace. However, if the Buyer chooses to not have a transit insurance then the Buyer shall be solely responsible for any issues arising during transit and cannot claim the same from the Seller or Company in any event.
                        </li>
                        <li>
                            The Buyer hereby agrees and understands that any transit insurance claim should be made directly with the insurance company as per their procedure even if the transit insurance was
                            bought through the Seller. The Buyer hereby understands and acknowledges that they cannot raise any such claim from the Seller or the Company in any event.
                        </li>
                        <li>
                            In case the Buyer has obtained transit insurance through a Seller, and in case of loss or damage of the goods, Buyer should provide the Seller any and all information needed to make the claim. The Buyer understands and agrees that such claim processing can take time and claim amount paid is as per the insurance provider. If the claim is partially or fully denied, they cannot raise any such claim from the Seller or the Company in any event.
                        </li>
                        <li>
                            The Buyer agree that the Company is not a party in any insurance claim in any event.
                        </li>
                        <li>
                            The Buyer agrees, acknowledges and understand that Company is not responsible or liable for any disputes between the Buyer and Seller including disputes arising from or related to payments. The Company will not be responsible for any loss of business including loss of profits, revenue, contracts, anticipated savings, data, goodwill or any other indirect or consequential losses or expenditures.
                        </li>

                    </ol>
                    <h4>CODE OF CONDUCT</h4>
                    <ol>
                        <li>
                            <p>By way of example, and not as a limitation, You agree and undertake that when accessing the Platform or using the Service, You will not:
                            </p>
                            <ol className='lower-content'>
                                <li>
                                    defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;
                                </li>
                                <li>
                                    publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;
                                </li>
                                <li>
                                    offer any stolen or fake or out-of-stock Products to the Buyers;
                                </li>
                                <li>
                                    upload files that contain software or other material protected by intellectual property laws unless You own or control the rights thereto or have received all necessary consents;
                                </li>
                                <li>
                                    upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Platform;
                                </li>
                                <li>
                                    conduct or forward surveys, contests, pyramid schemes or chain letters;
                                </li>
                                <li>
                                    violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;
                                </li>
                                <li>
                                    violate any applicable laws or regulations for the time being in force in or outside India; and
                                </li>
                                <li>
                                    violate, abuse, unethically manipulate or exploit, any of the Terms of the Platform.
                                </li>
                                <li>
                                    Form an alliance with other Buyers/Sellers within or outside the portal to manipulate the prices or practice monopoly through such alliance. The relevant applicable law including but not limited to the Monopoly and Restrictive Trade Practices (MRTP) Act laws will apply in such situations.
                                </li>
                            </ol>
                            <li>
                                Company reserves the right to deny Buyer and/or Seller or any User access/terminate access/suspend access to the Platform at its sole discretion without providing any reason whatsoever.
                            </li>
                            <li>
                                Company may at its discretion take action against any User, Seller or Buyer on the Platform if they violate the code of conduct as stated in this clause 7 including but not limited to removal or suspension of the User’s account.
                            </li>
                            <li>
                                Company shall on knowledge or on complaint received of any abusive or inappropriate information published on the Platform as mentioned in this clause 7 shall delete such comments or/and take any other necessary action as its deems fit.
                            </li>

                            <li>
                                The Company shall not be not liable for any information or abuse communicated or posted by any User or Buyer or Seller on the Platform.
                            </li>

                            <li>
                                On failure to comply with this clause or on violation of any terms, or in the event the Company deems it necessary, then the Company at its sole discretion can remove any Buyer or Seller or any information posted by them including but not limited to ratings/feedbacks/comments provided by a Buyer/Seller to another Buyer/Seller in the Platform.

                            </li>

                        </li>
                    </ol>
                    <h4>SCOPE OF SERVICE</h4>
                    <ol>
                        <li>
                            The Platform enables Buyers and Sellers to interact for the purchase of Products. Company is not and cannot be a party to or control in any manner any transaction between the Buyers and Sellers. All commercial/contractual terms are offered by and agreed to between Buyers and Sellers. Such terms shall include a refund policy, return policy and any other terms that are exclusive to the Seller and the Buyer. Company does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between seller and the buyer.
                        </li>

                        <li>
                            Company is not responsible for any non-performance or breach of any contract entered into between buyers and seller. Company cannot and does not guarantee that the concerned Buyers/Sellers will perform any transaction concluded through the platform. Company shall not and is not required to mediate or resolve any dispute or disagreement between buyers and seller.
                        </li>

                        <li>
                            The Buyer and Seller hereby agree that any sale and/or purchase of the Products(s) through the Platform is legally binding on the Buyer and Seller.
                        </li>
                        <li> The Buyer may choose to buy the Product(s) listed by the Seller at the price (inclusive of all taxes) or by sharing an offer to/seeking a quote from the Seller through the interface provided under the Platform The Seller must display the relevant Product information as identified below on the Platform for facilitating sale to prospective Buyers by way of a pre-decided sale price or inviting a quote for each available Product or multiple Products (all prices listed shall be inclusive of all taxes). A prospective Buyer may initiate the request for purchase by sharing an offer /quote within a time period as specified by the Seller. The Seller may at its own discretion accept/reject/negotiate the offer/quote with the prospective Buyer.</li>
                        <li>The Seller agrees to always keep the stock availability on the Platform updated at all times.</li>
                        <li>
                            The Seller and Buyer agree and understand that any offer posted on the Platform shall expire within a pre-defined period from the time of the post on the Platform. The timeline may only be revised or changed at the sole discretion of the Company. The Seller and Buyer agrees that the Company shall not be liable for any party’s failure to accept the offer or any failure due to technical issues.
                        </li>
                        <li>In case of F.O.R Delivery, the Seller shall ship the purchased Products to the Buyer and ensure timely delivery as per the agreement. Seller will be solely responsible for delivering the product on time. If a Product is not deliverable to the Buyer after dispatch for any reason including but not limited to theft, damage, fire or accident, then the Seller will have to send a new consignment within a stipulated timeline agreed between the Buyer and the Seller.</li>
                        <li>
                            To clarify, Company is not responsible for the services offered by such third-parties and the User hereby acknowledges and agrees that the Company will not be held liable for any such third-party services.
                        </li>
                        <li>
                            It is recommended that Buyers avail transit insurance through the Seller or through an insurance marketplace. However, if the Buyer chooses to not have a transit insurance then the Buyer shall be solely responsible for any issues arising during transit and cannot claim the same from the Seller or Company in any event.
                        </li>
                        <li>
                            The Buyer hereby agrees and understands that any transit insurance claim should be made directly with the insurance company as per their procedure. The Buyer hereby understands and acknowledges that they cannot raise any such claim from the Seller or the Company in any event.
                        </li>
                        <li>
                            In case the Buyer has obtained transit insurance through a Seller, and in case of loss or damage of the goods, Buyer should provide the Seller any and all information needed to make the claim. The Buyer understands and agrees that such claim processing can take time and claim amount paid is as per the insurance provider. If the claim is partially or fully denied, they cannot raise any such claim from the Seller or the Company in any event.
                        </li>
                        <li>
                            The Buyer and Seller agree that the Company is not a party in any insurance claim in any event.
                        </li>
                        <li>
                            The Buyer and Seller understands that the Company may charge a subscription fee with additional features on the Platform. The Seller and Buyer agree that in case of any applicable subscription fee, the Seller and Buyer will pay such subscription fee and/or service fee associated with use of the Platform or use of any services within the Platform in a timely manner
                        </li>
                        <li>
                            The Buyer and Seller agree to provide order tracking information as required by the order tracking feature of the platform including and not limited to invoices, proof of payments, material dispatch information, material receipt information etc.
                        </li>
                        <li>
                            The Seller agrees that Buyer can request pre-inspection of the product by a third party at Buyer’s cost. The Seller must accommodate such request and cooperate with the third party to complete pre-inspection as per standard processes. The Buyer agrees that the pre-inspection to be conducted on a date agreed mutually by both the Buyer and the Seller.
                        </li>
                    </ol>

                    <h4>
                        PAYMENT TERMS
                    </h4>
                    <ol>
                        <li>
                            Upon the Buyer proceeding to purchase the Product shortlisted on the Platform and/or upon acceptance of any quote shared by a Seller the Buyer and Seller shall proceed to close the transaction to purchase the product on the Platform. On confirmation from the Buyer for the Products, the Seller shall raise invoice(s) and send it to the Buyer. The Buyer shall make the payment as per the payment terms stated in the invoice(s) by the Seller.
                        </li>
                        <li>
                            On completion of the payments, the proof of payments shall be uploaded on the Platform.
                        </li>
                        <li>
                            The Buyer and Seller agrees, acknowledges and understand that Company is not responsible or liable for any disputes between the Buyer and Seller including disputes arising from or related to payments. We will not be responsible for any loss of business including loss of profits, revenue, contracts, anticipated savings, data, goodwill or any other indirect or consequential losses or expenditures.
                        </li>
                        <li>
                            The Buyer and Seller shall pay a subscription fee for any paid feature of the platform he/she subscribes to, which shall be payable at the start date of such service. If service or the use of the Platform was started during a month and not at the beginning of the month, prorated subscription fee for reminder of the month/quarter or year (as per the subscription plan) will be charged on the start date of the service.
                        </li>
                        <li>
                            The invoice for the subscription fee shall be raised by the Company and shall be payable within

                            7 days from the date of invoice.

                        </li>
                    </ol>
                    <h4>
                        OBLIGATIONS
                    </h4>
                    <ol>
                        <li>
                            The Users while using the Platform shall during its association with Platform, shall appoint a representative, who shall be Company’s point of contact for any and all matters related to these Terms.
                        </li>
                        <li>
                            The Users shall, at all times, comply with all Applicable Laws including without limitation compliance with laws relating to GST etc.
                        </li>
                        <li>
                            The Company shall not be held responsible for any delay or failure to comply with Our obligations under these Terms if such delay or failure arises from any cause which is beyond Our reasonable control.
                        </li>
                        <li>
                            Order fulfilment has to be done through the Platform and all necessary and related documents shall be uploaded on the Platform for the purpose of tracking order fulfilment and dispute resolution (if any dispute arises).
                        </li>
                        <li>
                            The Buyer while using the Platform for purchasing Product(s) must:
                            <ol>
                                <li>Register himself as a Buyer on the Platform;</li>
                                <li>
                                    verify the authenticity of the Product(s) before purchasing the Product(s) through the Platform;
                                </li>
                                <li>check the authenticity of the Product and for defects in the Product(s) purchased from the Seller upon delivery of the Product;</li>
                                <li>
                                    ensure that the Product(s) conform to the specifications as provided by the Seller(s);
                                </li>
                                <li>
                                    shall make timely payments for a confirmed order. as per the terms of the offer, unless agreed mutually by the Buyer and Seller to change any of the previously agreed upon terms; and
                                </li>
                                <li>
                                    shall take delivery of product from the Seller at the specified timeframe or as per a mutually agreed time .
                                </li>


                            </ol>
                        </li>

                        <li>
                            The Seller while using the Platform for listing Product(s):
                            <ol>
                                <li>
                                    shall maintain records of all the Products purchased by the Buyers through the Platform, all returns, refunds, etc., as may be required for audit and regulatory purposes and for the Platform’s users service purposes;
                                </li>
                                <li>
                                    shall not sell the Product at a price lower on any other third-party website or social media platform including WhatsApp, than the price offered on the Platform;
                                </li>
                                <li>
                                    shall fulfill a confirmed order as per the terms of the offer, unless agreed mutually by the Buyer and Seller to change any of the previously agreed upon terms
                                </li>
                                <li>
                                    shall be solely responsible and liable for any complaints and queries of Buyers with respect to the Products, delayed dispatch of Products (attributable to the Seller) purchased beyond the committed date or any complaints with respect to the quality or quantity of the Products delivered; and
                                </li>
                                <li>
                                    shall be solely responsible for making any representations or warranties with respect to the quality of the Product to the Buyer, including all relevant Product warranties.
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <h4>
                        CANCELLATION GUIDELINES
                    </h4>
                    <ol>
                        <li>An order once accepted by the Seller/Buyer can only be cancelled by mutual agreement between the Buyer and Seller.</li>
                        <li>
                            In case the Buyer does not make the payment on time (unless mutually agreed by Buyer and Seller to extend the payment due date), Seller may at its discretion cancel such order after giving prior notice to Buyer.
                        </li>
                        <li>
                            In case the Buyer does not makes payment on time or does not pick up material or takes delivery within the delivery timeline (unless mutually agreed by Buyer and Seller to change the pickup delivery date outside the delivery timeline), or extend the payment due date), Seller may at its discretion cancel such order after giving prior notice to the Buyer. Any refund of

                            advance payment(s) paid by the Buyer, will be as per the Payment Terms mutually agreed by the Buyer and the Seller.
                        </li>

                        <li>
                            The Buyer and Seller agree that the Company shall not be liable in case of any cancellation and any payment related disputes between the Buyer and the Seller.
                        </li>
                    </ol>
                    <h4>
                        TERMINATION
                    </h4>
                    <ol>
                        <p>Company may suspend or terminate User’s access of the Platform and/or use of Service:</p>
                        <li>
                            if it believes, in its sole and absolute discretion that User have breached, violated, abused, or unethically manipulated or exploited any term of these Terms or anyway otherwise acted unethically.
                        </li>

                        <li>
                            if Company has reasonable grounds to suspect that any information provided by User is untrue, inaccurate, not current or incomplete, or not in accordance with these Terms.
                        </li>

                        <li>
                            Company reserves the right to refuse to process transactions/communications by Buyer and/or Seller with a prior history of questionable charges based on rating and review including without limitation, breach of any agreements between the Seller and Buyer or breach/violation of any law or breach of any policy.
                        </li>
                    </ol>

                    <h4>FEEDBACK MECHANISM</h4>
                    <ol>
                        <li>
                            You may write to contact@metalsbuy.com for any feedback or queries that he/she may have concerning the Platform or Services made available through the Platform. The Users agree that while using Platform, the Users are subject to evaluation by Us. The evaluation shall be based on a system of rating and review, which shall be on the basis of feedback and usage of the Platform, such as but not restricted to, the quality of Products, usage/actual sales through the Platform or on the basis of interaction between Seller and Buyers. In case of any issue the Buyer or Seller shall provide adequate proof and upload documents in regard to the same on the Platform. Any such feedback or disputes raised by Users will be assessed and decided by the Company in its sole discretion and any such decision made shall be final.
                        </li>


                    </ol>

                    <h4>USE OF YOUR PERSONAL INFORMATION</h4>
                    <p>
                        In course of User registration on the Platform and use of the Platform, the User may be asked to provide certain Personal Information to Company. Company’s information collection and use policies with respect to privacy of such information are set forth in the Privacy Policy available at our website and/or mobile application.
                    </p>

                    <h4>PRODUCT WARRANTY/GUARANTEE</h4>
                    <ol>
                        <li>
                            The Seller shall be solely liable for any claims, damages, allegations arising out of the Products offered for sale via Platform (including but not limited to quality, quantity, price, merchantability, finish, appearance, size, colour, functionality, reliability, use for a particular purpose, guarantee, warranty or any other related claim) and shall hold Company harmless and indemnified against all such claims and damages.
                        </li>
                        <li>
                            Seller hereby represents to own or have the necessary permissions to sell Products as offered by the Seller on the Platform. Seller shall not imply that Product is in any way sponsored or endorsed by Company. Seller shall be exposed to liability if, for example, Product quality is bad or does not match up with the specifications/ provided by the Seller on Platform or contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; or violates or advocates the violation of any law or regulation. The Seller shall be responsible for any return & exchange policy that is offered to the Buyers by the Seller.
                        </li>
                        <li>
                            The Seller hereby agree, confirm and acknowledge that the Product is owned by the Seller or Establishment (as the case may be). Company is merely a platform to offer to sell Product of the Seller. Company is not responsible/ liable for the Product, its design, its function and condition manufacturing and selling and financial obligations, warranties, guarantees whatsoever.
                        </li>
                    </ol>
                    <h4>
                        INTELLECTUAL PROPERTY RIGHTS
                    </h4>

                    <ol>
                        <li>The Platform and the processes, and their selection and arrangement, including but not limited to all text, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (collectively, the “Content”) on the Platform is owned and controlled by Company or its licensors and the design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, patent and trademark laws, and various other intellectual property rights (whether registered or unregistered). Through User‘s use of the Platform, by no means are any rights impliedly or expressly granted to User in respect of such Content. Company reserves the right to change or modify the Content from time to time at its sole discretion.</li>
                        <li>
                            Except as expressly provided herein, the Users acknowledge and agree that they shall not copy, republish, post, display, translate, transmit, reproduce or distribute any Content through any medium without obtaining the necessary authorization from the Company. It is hereby clarified that no other rights are being conveyed to the User by virtue of accessing or using the Platform or Services.
                        </li>
                        <li>
                            The Users hereby voluntarily provide a license to the Company to allow the use and display of any logos or trademarks on the Platform. The logos shall be displayed on the Platform on an ‘as is’ basis and Company shall not change or modify the logos and the Users shall retain ownership of the same.
                        </li>
                    </ol>

                    <h4>
                        CONFIDENTIALITY
                    </h4>

                    <ol>
                        <li>
                            You hereby agree not to disclose or attempt to use or personally benefit from any non-public information that it may learn or discover on the Platform or through the Services. This obligation shall continue until such time as the non-public information has become publicly known through any action of the User. If the User is compelled by order of a court or other governmental or legal body (or have notice that such an order is being sought) to divulge any such non-public information, the User agrees to promptly and diligently notify Us and cooperate fully with the Company in protecting such information to the extent possible under Applicable Law.
                        </li>
                        <li>
                            We may access, preserve and disclose any of the User’s information if required to do so by Applicable Law or if We in good faith believe that it is necessary to (i) respond to any claims asserted to the Company (ii) to comply with any legal process (iii) to prevent any act of fraud

                            (iii) for any improvement of the Service/Platform.
                        </li>

                    </ol>

                    <h4>INDEMNIFICATION</h4>
                    <p>
                        The Users hereby agree to indemnify, defend and hold harmless Company from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Company that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by User pursuant to these Terms or Terms and Conditions of Registration or Privacy Policy.
                    </p>

                    <h4>SEVERABILITY</h4>
                    <p>
                        If any provision of these Terms is found by a competent court or other binding authority to be invalid, You agree that every attempt shall be made to give effect to the parties intentions as reflected in that provision, and the remaining provisions contained in the Terms shall continue in full force and effect.
                    </p>

                    <h4>
                        DISPUTE RESOLUTION
                    </h4>
                    <p>If any dispute, controversy or claim arises under, out of, or in relation to these Terms, including any dispute concerning the formation, construction, interpretation, or breach of these Terms or any party’s performance of its obligations under it, such dispute shall at the first instance be attempted to be resolved through mutual good faith consultations. If the dispute is not resolved in this manner within forty-five (45) days of either party sending a notice to the other party of such dispute, then the dispute shall be resolved by binding arbitration under the provisions of the Arbitration & Conciliation Act 1996, by a sole arbitrator in Raipur, India. Such sole arbitrator shall be appointed by mutually by the parties.</p>

                    <h4>GOVERNING LAW</h4>

                    <p>The laws of India shall govern these Terms, regardless of location. In case of failure to resolve the dispute as per Clause 20 of these terms the User hereby expressly consent to exclusive jurisdiction of the courts of Raipur, India, for all matters or disputes arising out of or relating to these Terms or access or use of the Platform/Service.</p>

                    <p >
                    ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THESE TERMS OF USE OR YOUR USE OF THE PLATFORM/SERVICES MUST BE CONTESTED BY YOU WITHIN 1 (ONE) MONTH OF THE DATE THAT SUCH CAUSE OF ACTION FIRST AROSE, OR SUCH CAUSE OF ACTION IS FOREVER WAIVED BY YOU. EACH CAUSE OF ACTION SHALL BE ADJUDICATED INDIVIDUALLY, AND YOU HEREBY EXPRESSLY AGREE NOT TO COMBINE YOUR CLAIM WITH THE CLAIM OF ANY THIRD PARTY.
                    </p>

                    <h4>
                    NOTICES
                    </h4>
                      
                      <ol>
                        <li> All notices or demands to or upon Company shall be effective if in writing and shall be deemed to be duly made when sent to AllFerro Tradewize Private Limited, Ring Road No.2 Gondwara Road Bhanpuri, Raipur, Chhattisgarh, 492001.</li>
                        <li>
                        All notices or demands to or upon a User(s) shall be effective if either delivered personally, sent by courier, certified mail, by facsimile or email to the last-known correspondence, fax or email address provided by the User(s) on the Platform, or by posting such notice or demand on an area of the Platform that is publicly accessible.
                        </li>

                        <li>
                        Notice to a User(s) shall be deemed to be received by such User(s) if and when the Platform is able to demonstrate that communication, whether in physical or electronic form, has been sent to such User(s), or immediately upon Platform’s posting such notice on an area of the Platform that is publicly accessible.
                        </li>

                      
                      </ol>

                      <h4>
                        GRIEVANCE REDRESSAL
                        </h4>

                        <ol>
                            <li>Headings for any section of these Terms are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.</li>

                            <li>
                            Company shall have the right to assign its obligations and duties mentioned under these Terms to any person or entity.
                            </li>

                            <li>
                            All calls to Company are completely confidential. However, Your call may be recorded to ensure quality of service. Further, for training purpose and to ensure excellent customer service, calls from Company may be monitored and recorded.
                            </li>

                            <li>
                            In case of any issues or queries the User shall be able to contact customer services at  <a href='mailto:contact@metalsbuy.com'>contact@metalsbuy.com</a>.
                            </li>

                            
                        </ol>

                </div>
            </div>
        </div>
    )
}

export default Terms