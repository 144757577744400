import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import { connect } from "react-redux";
import Toast from "light-toast";
import { updateContactDetails } from "../../../../store/actions/profile";
import { isEmpty } from "lodash";
import { useToasts } from "react-toast-notifications";
import { SpinnerLoader } from "../../../../utils/constants";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../../Loader";

const EditPasswordInfo = (props) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [showP1, setShowP1] = React.useState(false);
  const [showP2, setShowP2] = React.useState(false);
  const [showP3, setShowP3] = React.useState(false);
  const [innerLoader, setInnerLoader] = React.useState(false);
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  const { addToast } = useToasts();

  React.useEffect(() => {
    if (!isEmpty(props.profileInfo) && !isEmpty(props.profileInfo.userdata)) {

      setName(props.profileInfo.userdata.full_name);
      setEmail(props.profileInfo.userdata.email);
      setMobile(
        new RegExp(/^\d*$/).test(props.profileInfo.userdata.mobile)
          ? props.profileInfo.userdata.mobile
          : ""
      );
      setDob(props.profileInfo.userdata.dob); //mm-dd-yy
    }
  }, [props.profileInfo]);

  const updateContactDetails = (e) => {
    e.preventDefault();
    setShowLoader(true);
    setShowMessage("Please wait...")
    if (currentPassword || newPassword || confirmPassword) {
      if (!currentPassword) {
        notify("Please Enter Current password", "error");
        setShowLoader(false);
      }
      else if (!newPassword) {
        notify("Please Enter New password", "error");
        setShowLoader(false);
      }
      else if (!confirmPassword) {
        notify("Please Enter Confirm password", "error");
        setShowLoader(false);
      }

      else if (newPassword !== confirmPassword) {
        notify("New password and Confirm password does not match", "error");
        setShowLoader(false);
      }

      else {
        const payload = {
          userId: props.profileInfo.userdata._id,
          full_name: name,
          date_of_birth: dob,
          currentPassword,
          newPassword,
          confirmPassword,
          mobile,
          // email,
        };
        props.updateContactDetails(payload, (status) => {
          if (status.status == "success") {
            setShowLoader(false);
            notify(status.message, "success");
            setTimeout(() => {
              history.push("/profile")
            }, 2000);
          } else {
            setShowLoader(false);
            notify(status.message, "error", 3500);
          }
        });
      }
    } else {
      const payload = {
        userId: props.profileInfo.userdata._id,
        full_name: name,
        date_of_birth: dob,
        mobile,
      };
      props.updateContactDetails(payload, (status) => {
        if (status) {
          Toast.success("Password updated successfully", 2000);
          history.push("/profile")
        }
      });
    }
  };

  const notify = (info, type, time) => {
    return addToast(info, {
      appearance: type,
      autoDismissTimeout: time ? time : 2500,
    });
  };

  const enterPhone = (e) => {
    let temp = e.target.value;
    let checkDigit = new RegExp(/^\d*$/);
    if (checkDigit.test(temp)) {
      setMobile(temp);
    }
  };

  const setDateOfBirth = (props) => {
    const newDOB =
      props.getDate() +
      "-" +
      (props.getMonth() + 1) +
      "-" +
      props.getFullYear();
    setDob(newDOB);
    setShowCalendar(!showCalendar);
  };

  return (
    <div style={{ position: "relative" }}>
      {showLoader ? <Loader message={showMessage} /> : null}
      <div style={{ opacity: innerLoader ? "0" : "1" }}>
        <form className="detail-from" onSubmit={(e) => updateContactDetails(e)}>
          <h4 className="form-title">My profile</h4>
          <div className="group-wrap edit-profile change-passwrd-my-profile">
            <div className="form-input">
              <span>Current Password</span>
              <div className="form-wrapp">
                <input
                  type={showP1 ? "text" : "password"}
                  placeholder="Current Password"
                  required
                  minLength={6}
                  maxLength={15}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <i
                  onClick={() => setShowP1(!showP1)}
                  className={`toggle-password fa fa-fw  ${showP1 ? " fa-eye" : " fa-eye-slash"
                    }`}
                ></i>
              </div>
            </div>
            <div className="form-input">
              <span>New Password</span>
              <div class="form-wrapp">
                <input
                  type={showP2 ? "text" : "password"}
                  required
                  placeholder="New Password"
                  minLength={6}
                  maxLength={15}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <i
                  onClick={() => setShowP2(!showP2)}
                  className={`toggle-password fa fa-fw  ${showP2 ? " fa-eye" : " fa-eye-slash"
                    }`}
                ></i>
              </div>
            </div>
            <div className="form-input">
              <span>Confirm Password</span>
              <div className="form-wrapp">
                <input
                  type={showP3 ? "text" : "password"}
                  required
                  placeholder="Confirm Password"
                  minLength={6}
                  maxLength={15}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <i
                  onClick={() => setShowP3(!showP3)}
                  className={`toggle-password fa fa-fw  ${showP3 ? " fa-eye" : " fa-eye-slash"
                    }`}
                />
              </div>
            </div>
          </div>
          <div className="flex-row">
            <button type="submit" className="header-sell change-passwrd-sell">
              Change Password
            </button>
            <Link to={"/profile"} className="header-sell">
              Back
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    profileInfo: state.userProfile.userProfileBanner,
    addressList: state.userProfile.userAddress,
    userId: state.userInfo.loggedUserInfo.userId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateContactDetails: (payload, completion) =>
      dispatch(updateContactDetails(payload, completion)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordInfo);
