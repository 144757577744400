import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import ForgotPass from "../components/Auth/ForgotPass";
import ConfermPass from "../components/Auth/ConfermPass";
import Blog from "../components/Blog";
import ProductDetail from "../components/ProductDetail/ProductDetail";
import Cart from "../components/Cart/CartContainer";
import PageNotFound from "../pageNotFound";
import CheckOut from "../components/CheckOut";
import ProductListingPage from "../components/ProductListing";
import { Authorization, isSeller } from "./../utils/Authorization";
import { connect } from "react-redux";
import ReadBlog from "../components/ReadBlog";
import MainProfile from "../components/Profile/MainProfile";
import OrderDetails from "../components/OrderDetails";
import AboutUs from "../components/staticPages/AboutUs";
import ContactUs from "../components/staticPages/ContactUs";
import HowItWork from "../components/staticPages/HowItWork";
import Authenticity from "../components/staticPages/Authenticity";
import HowToSell from "../components/staticPages/HowToSell";
import OrderTracking from "../components/staticPages/OrderTracking";

import AppStore from "../components/Dashboard/AppStore";
import {
  setLoginPopupStatus,
  setRedirectToPrivateRoute,
} from "./../store/actions/common";
import SellerProfile from "../components/Profile/SellerProfile";

import OrderSuccess from "../components/CheckOut/PlacedOrderPage/OrderSuccess";
import OrderCancel from "../components/CheckOut/PlacedOrderPage/OrderCancel";
import NewForgetPassword from "../components/Auth/newForgetPassword";

import MyReferalCode from "../components/MyReferalCode";
import MyCoupon from "../components/MyCoupon";
import SellerDashBoard from "../components/Profile/SellerDashBoard";
import ChatMainScreen from "../components/ChatModule";
import ProductReviewList from "../components/ProductReviewList";
import Career from "../components/staticPages/Career";
import Payments from "../components/staticPages/Payments";
import Cancellation from "../components/staticPages/Cancellation";
import Security from "../components/staticPages/Security";
// import Terms from "../components/staticPages/Terms";
import Sitemap from "../components/staticPages/Sitemap";
import License from "../components/staticPages/License";
import LocalBazar from "../components/staticPages/LocalBazar";
import ViewOrder from "../components/OrderDetails/ViewOrder";
import ReadyToShip from "../components/OrderDetails/ReadytoShip";
import SellerRegistrationForm from "../components/Auth/sellerRegistrationForm";
import AddProduct from "../components/SellAnItem/AddProduct/AddProduct";
import AddOrder from "../components/SellAnItem/AddProduct/AddOrder";
import JobDescription from "../components/staticPages/Career/jobDescription";
import PaymentPage from "../components/Profile/MainProfile/Myoffer/PaymentPage";
import BrandList from "../components/Header/HeaderCategory/brandList";
import Loader from "../components/Loader";
import GooglePlay from "../components/Dashboard/GooglePlay";
import MainPage from "../components/MainPage/MainPage";
import completeProfileOutside from "../components/Complete Profile Outside/completeProfileOutside";
import Lumps from "../components/staticPages/lumps";
import Chips from "../components/staticPages/chips";
import Fines from "../components/staticPages/fines";
import News from "../components/staticPages/news";
import Event from "../components/staticPages/events";

import Buyer from "../components/staticPages/User/Buyer";
import Seller from "../components/staticPages/User/Seller";
import Faq from "../components/staticPages/Faq/Faq";
import Policy from "../components/staticPages/Policy/Policy";
import categoriesProduct from "../components/Product_Listing";
import StopWatch from "../components/Stopwatch";
import ProductPageNew from "../components/ProductPageNew/ProductPageNew";
import OrderProceedSuccess from "../components/OrderProceedSuccess/OrderProceedSuccess";
import Practice from "../components/practice";
import Terms from "../components/TermsOfService/Terms";
import MakeOfferNew from "../components/makeOffer";
import MakeRfq from "../components/makeRfq";
import MakeRfqDetails from "../components/makeRfq/details";
import SellerDetails from "../components/Profile/SellerProfile/SellerDetails";
import OrderModule from "../components/OrderModule/OrderModule";
import OrderModuleDetails from "../components/OrderModule/OrderModuleDetails";
import AddWireProduct from "../components/SellAnItem/AddProduct/AddWireProduct";

const Routes = (props) => {
  return (
    <Switch>
      <Route exact component={MainPage} path="/" />
      <Route exact component={OrderModule} path="/process-orders" />
      <Route exact component={OrderModuleDetails} path="/process-orders/:id" />
      {/* <Route component={Practice} path="/practice" /> */}
      <Route component={Seller} path="/seller" />
      <Route component={OrderProceedSuccess} path="/order-process-success" />
      <Route component={ProductPageNew} path="/product-list" />
      <Route component={Buyer} path="/buyer" />
      <PrivateRoute component={Dashboard} exact path="/main" />
      <Route component={Policy} exact path="/policy" />
      <PrivateRoute component={BrandList} exact path="/brandList/:brands" />
      <PrivateRoute exact component={ForgotPass} path="/forgotpass" />
      <PrivateRoute exact component={ReadyToShip} path="/readyToShip" />
      <PrivateRoute exact component={ConfermPass} path="/confirm-password" />
      <Route component={Blog} exact path="/blog" />
      <Route component={NewForgetPassword} exact path="/resetpassword/:id" />
      <PrivateRoute component={SellerProfile} exact path="/seller/:id" />
      <PrivateRoute
        component={ReadBlog}
        exact
        path="/story/:blog_slug/:blogId"
      />
      <PrivateRoute
        component={ProductDetail}
        exact
        path="/product/:catslug/:id"
      />
      <PrivateRoute component={Cart} path="/my-cart" />
      <PrivateRoute component={MakeOfferNew} path="/make-offer/:id" />
      <PrivateRoute component={MakeRfq} path="/make-rfq-offer" />
      <PrivateRoute component={MakeRfqDetails} path="/make-rfq-offer/:id/:companyId" />
      <PrivateRoute component={Loader} path="/loader" />
      <PrivateRoute component={AppStore} path="/app_store" />
      <PrivateRoute component={GooglePlay} path="/google_play" />
      <PrivateRoute exact path="/product-listing-page/:name">
        <ProductListingPage
          {...props}
          browser_location={props.browser_location}
        />
      </PrivateRoute>
      <Route component={AboutUs} exact path="/page/aboutUs" />
      <Route component={Terms} exact path="/page/terms" />
      <Route component={StopWatch} exact path="/stopWatch" />
      <Route component={categoriesProduct} exact path="/listing/:category" />

      <Route
        component={completeProfileOutside}
        exact
        path="/complete_profile_now"
      />
      <Route component={ContactUs} exact path="/contactUs" />
      {/* <PrivateRoute component={SellerRegistrationWithoutLogin} exact path="/sellerRegistration" /> */}
      <Route
        component={SellerRegistrationForm}
        exact
        path="/sellerRegistration/:userId"
      />
      <Route component={Career} exact path="/career" />

      <PrivateRoute component={Lumps} exact path="/lumps" />
      <PrivateRoute component={Chips} exact path="/chips" />
      <PrivateRoute component={Fines} exact path="/fines" />
      <Route component={News} exact path="/news" />
      <Route component={Event} exact path="/event" />
      {/* <PrivateRoute component={Faq} exact path="/faq" /> */}
      <Route component={Faq} exact path="/faq" />
      <PrivateRoute component={HowItWork} exact path="/page/how-it-work" />
      {/* <Route component={PrivacyPolicy} exact path="/policy/:name" /> */}
      <PrivateRoute component={Payments} exact path="/page/payments" />
      <PrivateRoute component={Authenticity} exact path="/page/privacy" />
      <PrivateRoute component={HowToSell} exact path="/page/how-to-sell" />
      <PrivateRoute
        component={OrderTracking}
        exact
        path="/page/order-tracking"
      />
      <PrivateRoute component={ProductReviewList} exact path="/reviews/:id" />
      <PrivateRoute
        component={Cancellation}
        exact
        path="/page/cancellationReturns"
      />
      <PrivateRoute component={Security} exact path="/page/security" />
      {/* <Route component={Terms} exact path="/page/terms" /> */}
      <PrivateRoute component={Sitemap} exact path="/page/sitemap" />
      {/* <PrivateRoute component={Policy} exact path="/policy" /> */}
      <PrivateRoute component={License} exact path="/license" />
      <PrivateRoute component={LocalBazar} exact path="/page/localBazaar" />
      {/* <PrivateRoute component={Invoice} exact path="/invoice"/> */}
      <PrivateRoute component={ViewOrder} exact path="/view-order" />
      <PrivateRoute
        component={PaymentPage}
        exact
        path="/myprofile/offer-payment"
      />
      <PrivateRoute
        component={JobDescription}
        exact
        path="/job-description/:position/:experience/:location"
      />
      <PrivateRoute exact component={MyReferalCode} path="/referal" />
      <PrivateRoute exact component={MyCoupon} path="/mycoupon" />
      <PrivateRoute component={CheckOut} path="/checkout" />
      <PrivateRoute component={OrderSuccess} exact path="/order-success" />
      <PrivateRoute component={OrderCancel} exact path="/order-cancel" />
      <PrivateRoute component={MainProfile} path="/profile" />
      <PrivateRoute
        component={OrderDetails}
        exact
        path="/order-detail/:prodId/:prodType"
      />
      <PrivateRoute component={AddProduct} exact path="/sell-product/:id?" />
      <PrivateRoute component={AddWireProduct} exact path="/wire/sell-product/:id?" />
      <PrivateRoute component={AddOrder} exact path="/add-order/:id?" />
      <PrivateRoute
        component={SellerDashBoard}
        exact
        path="/selling/dashboard"
      />
      <PrivateRoute component={ChatMainScreen} path="/chats" />
      <PrivateRoute component={SellerDetails} path="/user-profile/:id" />
      <PrivateRoute component={PageNotFound} path="/not-found" />
      <PrivateRoute component={PageNotFound} path="*" />
    </Switch>
  );
};

const PrivateRouteHandler = ({ component: Component, ...props }) => {
  const loginInfo = Authorization();

  if (loginInfo.status) {
    return (
      <Route
        {...props}
        render={(propsParams) => <Component {...propsParams} />}
      />
    );
  }
  else if(localStorage.getItem("tokenUser")){
    return (
      <Route
        {...props}
        render={(propsParams) => <Component {...propsParams} />}
      />
    );
  }
  else {
    props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    props.setRedirectToPrivateRoute(true);
    return (
      <Redirect
        to={{
          pathname: "/",
          search: "?tab=login",
          state: {
            from: props.location.pathname + props.location.search,
          },
        }}
      />
    );
  }
};

const mapStateToProps = (state) => {
  return {
    userData: state.userProfile.userProfileBanner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
    setRedirectToPrivateRoute: (data) =>
      dispatch(setRedirectToPrivateRoute(data)),
  };
};

const PrivateRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRouteHandler);
export default Routes;
