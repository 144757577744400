import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/Service'
import { useSelector } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

const MakeOfferPopup = ({ setShowLoader, latestOfferData, detailData, setOfferPopup, setDetailData }) => {
    const userData = useSelector(store => store.userProfile.userProfileBanner.userdata);

    const [qty, setQty] = useState({ value: "" })
    const [price, setPrice] = useState({ value: "" })
    const [payTerms, setPayTerms] = useState("")
    const [commentBox, setCommentBox] = useState("")
    const [addressId, setAddressId] = useState("")
    const [size, setSize] = useState("")
    const [delivery, setDelivery] = useState("")
    const [deliveryFrom, setDeliveryFrom] = useState("")
    const [deliveryTo, setDeliveryTo] = useState("")
    const [packaging, setPackaging] = useState([])

    const [qtyError, setQtyError] = useState(false)
    const [qtyMoqError, setQtyMoqError] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [payTermsError, setPayTermsError] = useState(false)
    const [sizeError, setSizeError] = useState(false)
    const [deliveryError, setDeliveryError] = useState(false)
    const [deliveryFromError, setDeliveryFromError] = useState(false)
    const [deliveryToError, setDeliveryToError] = useState(false)
    const [packagingError, setPackagingError] = useState(false)


    const { addToast } = useToasts();

    const history = useHistory()

    useEffect(() => {
        if (latestOfferData) {
            setQty({ value: latestOfferData.quantity })
            setPrice({ value: latestOfferData.price })
            setPayTerms(latestOfferData.paymentTerms)
            setSize(latestOfferData.size)
            setDelivery(latestOfferData.deliveryType)
            let act = moment(latestOfferData.delivery_window_from).format("YYYY-MM-DD");
            let act2 = moment(latestOfferData.delivery_window_to).format("YYYY-MM-DD");
            setDeliveryFrom(act)
            setDeliveryTo(act2)
            let dummyPackaging = []
            latestOfferData.packaging.forEach((item) => {
                dummyPackaging.push({ name: item })
            })
            setPackaging(dummyPackaging)
            setCommentBox(latestOfferData.additionalComment)
        }
    }, [latestOfferData])


    const [payOptions, setPayOptions] = useState([
        { id: 1, name: "Advance Payment" },
        { id: 2, name: "Next Day" },
        { id: 3, name: "7 Day Credit" },
        { id: 4, name: "Other" },
    ])

    let dFList = [
        { id: 1, name: "FOR" },
        { id: 2, name: "EXW" },
        //   { id: 3, name: "Local" },
        //   { id: 4, name: "Nation Wide" },
        //   { id: 4, name: "Inside State Only" },
    ];

    const handleQty = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setQty(qty => value.length <= 10 && !isNaN(Number(value)) && { value } || qty)
        setQtyError(false);
        setQtyMoqError(false)
    }

    const handlePrice = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPrice(price => value.length <= 10 && !isNaN(Number(value)) && { value } || price)
        setPriceError(false)
    }

    const handleCounter = () => {
        if (qty.value.length === 0) {
            setQtyError(true)
            return
        }
        else if (Number(qty.value) < Number(detailData.data[0].product_data[0].minOrderQty)) {
            setQtyMoqError(true)
            return
        }
        else if (price.value.length === 0) {
            setPriceError(true)
            return
        }
        else if (size.length === 0) {
            setSizeError(true)
            return
        }
        else if (delivery.length === 0) {
            setDeliveryError(true)
            return
        }
        else if (deliveryFrom.length === 0) {
            setDeliveryFromError(true)
            return
        }
        else if (deliveryTo.length === 0) {
            setDeliveryToError(true)
            return
        }
        else if (packaging.length === 0) {
            setPackagingError(true)
            return
        }
        else if (payTerms.length === 0) {
            setPayTermsError(true)
            return
        }
        setShowLoader(true)
        let dummyPackaging = []
        packaging.forEach((item) => {
            dummyPackaging.push(item.name)
        })
        let token = localStorage.getItem("token");
        axiosInstance.post(`/offers/offer_request`, {
            "productId": detailData.data[0].product_data[0]._id,
            "sellerId": detailData.data[0].product_data[0].User_id,
            "quantity": qty.value,
            "price": price.value,
            "paymentTerms": payTerms,
            "offerType": "CounterOffer",
            "offerBy": userData.userType,
            "additionalComment": commentBox,
            "counter_offer_id": detailData.data[0]._id,

            "userId": userData._id,
            "size": size,
            "deliveryType": delivery,
            "delivery_window_from": deliveryFrom,
            "delivery_window_to": deliveryTo,
            "packaging": dummyPackaging
        }, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            if (res.data && res.data.message === "Your offer has been automatically declined as it was below the acceptable price set by the seller. Please consider submitting a revised offer with a higher price.") {
                addToast(res.data.message, {
                    appearance: "error",
                    autoDismissTimeout: 2500,
                });
                setTimeout(() => {
                    history.push("/profile/my-offer/auto-declined");
                }, 2500);
            }
            else {
                axiosInstance.post(`/offers/offer_details`, {
                    "offerId": detailData.data[0]._id,
                }, {
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                }).then((res) => {
                    setDetailData(res.data);
                    setOfferPopup(false)
                    setShowLoader(false)
                }).catch(() => {
                    setShowLoader(false)
                });
            }


        }).catch(() => {
            setShowLoader(false)
        });
    }

    return (
        <div className='make-offer-popup-new'>
            <div className='offer-popup-content'>
                <div className='offer-popup-content-header'>
                    <h3>Counter Offer</h3>
                    <button
                        className=""
                        type="button"
                        onClick={() => setOfferPopup(false)}
                    >
                        <i className="fal fa-times-circle"></i>
                    </button>
                </div>
                <div className="slct-optns select-wrapper">
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Quantity (mt){" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                placeholder="Enter quantity"
                                value={qty.value}
                                onChange={handleQty}
                            />
                        </div>
                        {qtyError ? <p className='error'>Please enter quantity</p> : null}
                        {qtyMoqError ? <p className='error'>You cannot enter quantity less than minimum order quantity</p> : null}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Price (INR/mt){" "}
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                placeholder="Enter price"
                                value={price.value}
                                onChange={handlePrice}
                            />
                        </div>
                        {priceError ? <p className='error'>Please enter price</p> : null}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Size (mt)
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                            <input
                                className={"input-url"}
                                type="text"
                                placeholder="Enter Size"
                                value={size}
                                onChange={(e) => { setSize(e.target.value); setSizeError(false) }}
                            />
                        </div>
                        {sizeError ? <p className='error'>Please enter size</p> : null}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Delivery
                            <span className="highlighted">*</span>
                        </label>
                        <div className="select-main-wrapper">
                            <select
                                className='make-offer-select-tag'
                                value={delivery}
                                onChange={(e) => { setDelivery(e.target.value); setDeliveryError(false) }}
                            >
                                {dFList.map((item, index) => {
                                    return <option key={index} value={item.name}>{item.name}</option>
                                })}
                            </select>
                        </div>
                        {deliveryError ? <p className='error'>Please select delivery</p> : null}
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Delivery Window
                            <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap" style={{ position: "relative" }}>
                            <input
                                className='input-url'
                                type="date"
                                // placeholder="Enter Size"
                                value={deliveryFrom}
                                onChange={(e) => { setDeliveryFrom(e.target.value); setDeliveryFromError(false) }}
                            />
                        </div>
                        {deliveryFromError ? <p className='error'>Please select delivery date</p> : null}
                    </div>
                    <div className="form-block-wrap">
                        <div className="input-wrap" style={{ position: "relative" }}>
                            <input
                                type="date"
                                className='input-url'
                                // placeholder="Enter Size"
                                value={deliveryTo}
                                onChange={(e) => { setDeliveryTo(e.target.value); setDeliveryToError(false) }}
                            />
                        </div>
                        {deliveryToError ? <p className='error'>Please select delivery date</p> : null}
                    </div>
                    <div className="form-block-wrap select-payment">
                        <label className="enter-mobile-label">
                            Select payment terms
                        </label>
                        <div className="select-main-wrapper">
                            <select value={payTerms} onChange={(e) => { setPayTerms(e.target.value); setPayTermsError(false) }}>
                                {payOptions.map((item, index) => {
                                    return <option key={index} value={item.name}>{item.name}</option>
                                })}
                            </select>
                            {/* <i className="icon-arrow-down"></i> */}
                        </div>
                        {payTermsError ? <p className='error'>Please select payment terms</p> : null}
                    </div>
                    <div className="form-block-wrap select-payment login-description-form-wrap address-field-wrap input-form-wrap select-affilation-profile address-name-wrap">
                        <label className="enter-mobile-label">
                            Packaging
                        </label>
                        <div className="select-main-wrapper">
                            <div className="input-form-wrap select-affilation-profile form-block-wrap" style={{ marginBottom: "0" }}>
                                <div className="form-intput select-affilation-wrap complete-profile-select" style={{ marginBottom: "0" }}>
                                    <div className="select-main-wrapper make-offer-multi-select">
                                        <Multiselect
                                            id="state"
                                            options={[
                                                {
                                                    name: "40 kg bag"
                                                },
                                                {
                                                    name: "50 kg bag"
                                                },
                                                {
                                                    name: "1 MT Jumbo bag"
                                                }
                                            ]} // Options to display in the dropdown
                                            selectedValues={packaging} // Preselected value to persist in dropdown
                                            onSelect={(e) => { setPackaging(e); setPackagingError(false) }} // Function will trigger on select event
                                            onRemove={(e) => { setPackaging(e); setPackagingError(false) }} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        <i className="icon-arrow-down"></i>
                                    </div>
                                    {packagingError ? <p className='error'>Please select packaging</p> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                            Comment Box
                        </label>
                        <div className="form-input">
                            <textarea value={commentBox} placeholder='Enter comment' onChange={(e) => { setCommentBox(e.target.value) }} rows={3}></textarea>
                        </div>
                    </div>
                    <div className="navigation-back" style={{ flexDirection: "column" }}>
                        <button className="next-btn" onClick={handleCounter}>Counter Offer</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MakeOfferPopup