import React from "react";
import Card from "./Card";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getDashboardCategoryProd } from "../../store/actions/dashboard";
import { connect } from "react-redux";
import { get } from 'lodash';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class EditorsPick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      productData: [],
      wishlist: [],
      id: this.props.data.id || "",
    };
  }

  componentDidMount() {
    const { id, type, block_name } = this.props.data;
    this.props.getProduct({ block_name, type: type[0], id }, (status) => {

    });
  }
  static getDerivedStateFromProps = (props, state) => {
    const wishlist = get(props.wishlist, ['data'], []);
    const propsLength = (props && props.productData && props.productData.data && props.productData.data.length) || 0;
    const stateLength = state.productData.length;
    if (
      propsLength === stateLength &&
      stateLength !== 0 &&
      wishlist.length === 0
    )
      return { ...state };
    const filePath =
      props && props.productData && props.productData.PRODUCTIMAGEPATH;
    const ProductDataArr =
      (props && props.productData && props.productData.data) || [];
    const structuredProductData = ProductDataArr.map((item) => {
      let is_wishlist = false;
      let wishlist_id = "";
      wishlist.length !== 0 &&
        wishlist.map((data) => {
          if (data.product_id === item._id) {
            is_wishlist = true;
            wishlist_id = data.wishlist_id;
            return true;
          }
        });
      return {
        ...item.Price,
        description: item.Description,
        origin: item.Origin,
        categorySlug: item.categoryData.slug,
        categoryName: item.categoryData.name,
        title: item.Title,
        shippingType: item.shippingType,
        deletedAt: item.deletedAt,
        Moderate: item.Moderate,
        mainImage: `${item.default_image}`,
        extra_img: (item.Image[1] && item.Image[1].original) || null,
        id: item._id,
        is_Auction: item.is_Auction,
        auctions_data: item.auctions_data,
        wishlist_id: is_wishlist ? wishlist_id : "",
        attr: item.Attrs,
      };
    });

    return {
      ...state,
      productData: structuredProductData,
    };
  };

  next = () => {
    if (this.slider) {
      this.slider.slickNext();
    }
  };
  previous = () => {
    if (this.slider) {
      this.slider.slickPrev();
    }
  };

  render() {
    const { current, productData } = this.state;
    const { editor_pic, id, name, type, block_name, product_count } =
      this.props.data;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      afterChange: (current) => this.setState({ current: current }),
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="editors-picks" id={id}>
        <div className="container">
          <div className="costume-prev">
            <button
              onClick={this.previous}
              className={
                current > 0 && current + 2 <= productData.length
                  ? "active-prev"
                  : "inactive-prev"
              }
            >
              <i className="icon-prev"></i> PREV
            </button>
            <button
              onClick={this.next}
              className={
                current + 2 < productData.length
                  ? "active-next"
                  : "inactive-next"
              }
            >
              {" "}
              NEXT <i className="icon-next"></i>
            </button>
          </div>
          <div className="editors-costumes">
            <div className="editors-caption">
              <img src={editor_pic} alt="editors" />
              <div className="picks-caption">
                <h2>{name}</h2>
                <Link
                  to={`/product-listing-page/block?block_name=${block_name}&id=${id}`}
                  className="shop-now"
                >
                  See all
                </Link>
              </div>
            </div>
            <div className="popular-picks">
              {this.state.productData.length !== 0 && (
                <Slider {...settings} ref={(c) => (this.slider = c)}>
                  {this.state.productData
                    .slice(0, product_count)
                    .map((data, i) => (
                      <Card key={i} {...data} />
                    ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let id = ownProps.data.id;
  return {
    wishlist: state.Wishlist.wishlist,
    allData: state.dashboardInfo,
    productData: state.dashboardInfo[id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: (payload, completion) => dispatch(getDashboardCategoryProd(payload, completion)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditorsPick);
