import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css';
import { Link } from "react-router-dom";

const PlatformProducts = () => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: true, // Enables the live mode to detect changes dynamically
    });
    wow.init();
  }, []);

  return (
    <div className="product-listing-new">
    <div className="platform-product">
      <div className="container-main">
      <h3 className="text-center">
            PRODUCTS
        </h3>
        <div className="products-list">
          <Link to='/product-list' className="trade-mnaterials wow animate__animated animate__fadeInUp " data-wow-delay="0.1s">
            <div className="material-img">
              <img
                src="/assets/images/mangenes.png"
                alt="metal-img"
              />
            </div>
            {/* <h4>Ferrochrome
            </h4> */}
            <h4>Manganese Ore</h4>
            <div className='trade-onhover'>
                <strong>PRODUCTS<i></i></strong>
            </div>
          </Link>
          <Link to='/product-list' className="trade-mnaterials wow animate__animated animate__fadeInUp " data-wow-delay="0.1s">
            <div className="material-img">
              <img src="/assets/images/PlatformProducts2.png" alt="metal-img" />
            </div>
            <h4>Silico Manganese</h4>
            <div className='trade-onhover'>
                <strong>PRODUCTS<i></i></strong>
            </div>
          </Link>
          <Link to='/product-list' className="trade-mnaterials wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
            <div className="material-img">
              <img src="/assets/images/PlatformProducts3.png" alt="metal-img" />
            </div>
            <h4>Ferro Manganese</h4>
            <div className='trade-onhover'>
                <strong>PRODUCTS<i></i></strong>
            </div>
          </Link>
          <Link to='/product-list' className="trade-mnaterials wow animate__animated animate__fadeInUp " data-wow-delay="0.3s">
            <div className="material-img">
              <img src="/assets/images/PlatformProducts4.png" alt="metal-img" />
            </div>
            <h4>Ferro Silicon</h4>
            <div className='trade-onhover'>
                <strong>PRODUCTS<i></i></strong>
            </div>
          </Link>
          <Link to='/product-list' className="trade-mnaterials wow animate__animated animate__fadeInUp " data-wow-delay="0.4s">
            <div className="material-img">
              <img src="/assets/images/PlatformProducts5.png" alt="metal-img" />
            </div>
            <h4>Ferro Chrome</h4>
            <div className='trade-onhover'>
                <strong>PRODUCTS<i></i></strong>
            </div>
          </Link>
          <Link to='/product-list' className="trade-mnaterials wow animate__animated animate__fadeInUp " data-wow-delay="0.5s">
            <div className="material-img">
              <img
                src="/assets/images/PlatformProducts6.png"
                alt="metal-img"
              />
            </div>
            <h4>Ferro Manganese Slag</h4>
            <div className='trade-onhover'>
                <strong>PRODUCTS<i></i></strong>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PlatformProducts
