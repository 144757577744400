import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setLoginPopupStatus,
  showLoginErr,
  setRedirectToPrivateRoute,
  setRedirectToNextPage,
} from "../../store/actions/common";
import Signup from "./Signup";
import Login from "./Login";
import { Authorization } from "../../utils/Authorization";
import { parse, stringify } from "query-string";


class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginPopup: this.props.showLoginPopup.status,
      screenToShow: this.props.showLoginPopup.screenToShow,
    };
  }

  componentDidMount() {
    let query = { ...parse(this.props.location.search) };
    if (!this.props.showLoginPopup.screenToShow && !this.state.screenToShow) {
      this.setState({ screenToShow: query.tab });
    }
  }


  closePopup = (flag) => {
    let query = { ...parse(this.props.location.search) };
    Object.keys(query).map((item, i) => {
      if (item === "tab") {
        delete query[item];
      }
    });
    let url = stringify(query);

    this.props.history.push({
      pathname: this.props.location.state
        ? this.props.location.state.from
        : this.props.location.pathname,
      search: url,
    });

    this.props.showLoginErr({ status: false, errData: "" });
    this.props.setLoginPopupStatus(false);
    if (flag === true) {
      if (this.props.isRedirectedToPrivatePage) {
        this.props.setRedirectToNextPage(false);
        this.props.setRedirectToPrivateRoute(false);
        this.props.history.push("/");
      }
    }
    else if (flag === false) {
      if (this.props.isRedirectedToPrivatePage) {
        this.props.setRedirectToNextPage(true);
        this.props.setRedirectToPrivateRoute(false);
      }
    }
  };

  switchScreen = (screenToShow) => {
    let query = { ...parse(this.props.location.search) };
    query.tab = screenToShow;
    let url = stringify(query);
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: url,
    });
    this.setState({ screenToShow });
  };


  render() {
    return (
      <div className="popup-wrap signup-popup-wrapper">
        {this.state.screenToShow === "login" ? (
          <Login
            closePopup={this.closePopup}
            switchScreen={this.switchScreen}
          />
        ) : (
          <Signup
            closePopup={this.closePopup}
            switchScreen={this.switchScreen}
          />
        )}
      </div >
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
    showLoginErr: (status) => dispatch(showLoginErr(status)),
    setRedirectToPrivateRoute: (data) =>
      dispatch(setRedirectToPrivateRoute(data)),
    setRedirectToNextPage: (data) => dispatch(setRedirectToNextPage(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  showLoginPopup: state.generalInfo.showLoginPopup,
  isRedirectedToPrivatePage: state.generalInfo.redirectedToPrivateRoute,
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
