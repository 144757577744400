import axios from "axios";
import axiosRetry from "axios-retry";

import { API_URL, API_PREFIX, API_PREFIX_AUTH } from "../config2";

export const axiosInstance = axios.create({ baseURL: `${API_URL}${API_PREFIX}`, timeout: 50000, });
export const axiosInstanceAuth = axios.create({ baseURL: `${API_URL}${API_PREFIX_AUTH}` });

axiosRetry(axiosInstance, {
  retryDelay: (retryCount) => {
    return retryCount * 1500;
  },
});
