import React, { useRef, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getMessageList,
  getConversation,
  sendMsg,
  loadProd,
  chatOffer,
} from "../../store/actions/chatAction";
import { makeAnOffer } from "../../store/actions/productDetails";
import { addCart } from "../../store/actions/cartAction";
import Toast from "light-toast";
import { parse } from "query-string";
import LeftScreen from "./leftScreen";
import RightScreen from "./rightScreen";
import CenterView from "./centerScreen";
import { isEmpty } from "lodash";
import { SpinnerLoader } from "../../utils/constants";
import { getTotalUnreadMsgAndWishlistItem } from "../../store/actions/common";
import Loader from "../Loader";
import { useToasts } from "react-toast-notifications";

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const ChatMainScreen = (props) => {
  const [msgText, setMsgText] = React.useState("");
  const [prod, setProd] = React.useState({});
  const [spinnerLoader, setLoader] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("All");
  const [linkData, setlinkData] = React.useState({});
  const [message, setMessage] = React.useState("");
  const [userId] = React.useState(localStorage.getItem("userId"));
  const [messageList, setMessageList] = React.useState({});
  const [leftScreenStatus, setLeftScreenStatus] = React.useState(false);
  const [modalHandle, setModalHandle] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const { addToast } = useToasts();
  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const query = { ...parse(props.location.search) };
  let str = props.location.pathname.split("/")[2];
  let str2 = str.charAt(0).toUpperCase() + str.slice(1);
  let dispatch = useDispatch();

  useEffect(() => {
    setShowLoader(true);
    setShowMessage("Please wait...");
    props.getMessageList({ tab: str2, page: 1 });
    if (!isEmpty(props.location.state) && props.location.state.chatPayload) {
      setLeftScreenStatus(false);
      props.loadProd(props.location.state.chatPayload.product_id, (status) => {
        if (status) {
          setProd(status);
        }
      });
      loadConversation({
        sender_id: props.location.state.chatPayload.sender_id,
        product_id: props.location.state.chatPayload.product_id,
      });
    } else {
      setLeftScreenStatus(true);
      props.getMessageList({ tab: str2 }, (res) => {
        if (res.status) {
          setMessageList(res.data);
          loadConversation({
            sender_id:
              res.data.sender_id !== userId
                ? res.data.sender_id
                : res.data.receiver_id,
            product_id: res.data.product_id,
          });
          props.loadProd(res.data.product_id, (status) => {
            if (status) {
              setProd(status);
            }
          });
        }
      });
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  }, [props.location.state]);

  useEffect(() => {
    if (!isEmpty(props.location.state) && !props.location.state.chatPayload) {
      props.getMessageList({ tab: str2 }, (res) => {
        if (res.status) {
          loadConversation({
            sender_id:
              res.data.sender_id !== userId
                ? res.data.sender_id
                : res.data.receiver_id,
            product_id: res.data.product_id,
          });
          props.loadProd(res.data.product_id, (status) => {
            if (status) {
              setProd(status);
            }
          });
        }
      });
    }
  }, [props.location.pathname]);

  const loadConversation = (data) => {
    props.getConversation(
      {
        senderId: data.sender_id,
        productId: data.product_id,
      },
      (status) => {
        if (status) setMsgText("");
      }
    );

    getTotalUnreadMsgAndWishlistItem((callback) => {})(dispatch);
  };

  const sendMessage = (link) => {
    console.log("props.location.state",props.location.state)
    if (link) {
      var payload = {
        message: link,
        receiverId: props.location.state.chatPayload.sender_id,
        sender_Id: localStorage.getItem('tokenUser') ? localStorage.getItem('subId'): localStorage.getItem("childType")==="all"? localStorage.getItem('subId'): localStorage.getItem("userId") ,
        recevier_Id:props.location.state.chatPayload.sender_id,
        productId: props.location.state.chatPayload.product_id,
      };
    } else {
      if (!isEmpty(props.location.state) && props.location.state.chatPayload) {
        var payload = {
          message: msgText,
          receiverId: props.location.state.chatPayload.sender_id,
          sender_Id: localStorage.getItem('tokenUser') ? localStorage.getItem('subId'): localStorage.getItem("childType")==="all"? localStorage.getItem('subId'): localStorage.getItem("userId") ,
          recevier_Id :props.location.state.chatPayload.sender_id,
          productId: props.location.state.chatPayload.product_id,
          //todo: OrderId  Here
          orderId: props.location.state.chatPayload.orderId,
        };
      } else {
        var payload = {
          message: msgText,
          receiverId: messageList.receiver_id,
          sender_Id: localStorage.getItem('tokenUser') ? localStorage.getItem('subId'): localStorage.getItem("childType")==="all"? localStorage.getItem('subId'): localStorage.getItem("userId") ,
          recevier_Id:messageList.receiver_id,
          productId: messageList.product_id,
        };
      }
    }

    props.sendMsg(payload, (status) => {
      if (status) {
        props.getMessageList({ tab: str2 }); //load left side latest msg// str2 is tab name
        if (
          !isEmpty(props.location.state) &&
          props.location.state.chatPayload
        ) {
          loadConversation({
            sender_id: props.location.state.chatPayload.sender_id,
            product_id: props.location.state.chatPayload.product_id,
          });
        } else {
          loadConversation({
            sender_id: messageList.sender_id,
            product_id: messageList.product_id,
          });
        }
      }
    });
  };

  const backScreen = () => {
    setLeftScreenStatus(true);
    setModalHandle(false);
  };

  const farScreen = () => {
    setLeftScreenStatus(false);
  };

  const handleModal = () => {
    setModalHandle(true);
  };

  const handleAddCart = (val) => {
    setShowLoader(true);
    setShowMessage("Please wait...");
    let data = {};
    data[val._id] = {
      product_id: val._id,
      name: val.Title,
      desc: val.Description,
      img: val.default_image,
      price: val.Price.sell_price
        ? val.Price.sell_price
        : val.Price.current_store_price,
      path: prod.PRODUCTIMAGEPATH,
      Moderate: val.Moderate,
      deletedAt: val.deletedAt,
      brand: val.brandData[0],
      category: val.categoryData[0],
    };
    props.addCart(data, val._id, (callback) => {
      if (callback) {
        setShowLoader(false);
        addToast("Added to Cart", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        props.history.push({
          pathname: "/my-cart",
        });
      } else {
        setShowLoader(false);
        addToast("Something wrong", {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
      }
    });
  };

  const gotocart = () => {
    props.history.push({
      pathname: "/my-cart",
    });
  };

  const submitPrice = (
    addressId,
    amount,
    productId,
    buyerId,
    returnMessage
  ) => {
    const payload = {
      cartData: [
        {
          Quantity: 1,
          product_id: productId,
          buyer_id: buyerId,
        },
      ],
      bid_price: amount,
      addressId: addressId,
    };
    props.chatOffer(payload, (status) => {
      if (status.status == "success") {
        setlinkData(status);
        returnMessage(status);
      } else {
        returnMessage(status);
      }
    });
  };
  const backClickHandler = () => {
    const statePayload = {
      payload: props.location.state.prevPageData.payload,
      tab: props.location.state.prevPageData.tab,
    };
    props.history.push({
      pathname: "/profile/myorder/order-detail",
      state: {
        ...statePayload,
      },
    });
  };

  return (
    <>
      {/* /////////////// For big screen //////// */}
      {matches && (
        <div className="chat-fld-wrap">
          {showLoader ? <Loader message={showMessage} /> : null}
          <div className="container">
            <LeftScreen
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onBackClick={backClickHandler}
              isSeller={
                props.getData.userdata && props.getData.userdata.isSeller
              }
            />
            <CenterView
              conversation={props.conversation}
              userId={userId}
              setMsgText={setMsgText}
              msgText={msgText}
              sendMessage={sendMessage}
              linkData={linkData}
              prod={prod}
            />
            {/* <RightScreen
              conversation={props.conversation.isSellerAccount}
              prod={prod}
              cartData={props.cartData}
              handleAddCart={handleAddCart}
              gotocart={gotocart}
              setMsgText={setMsgText}
              makeAnOffer={props.makeAnOffer}
              getproductDetail={() =>
                props.loadProd(query.p_id, (status) => {
                  if (status) {
                    setProd(status);
                  }
                })
              }
              isSeller={
                props.getData.userdata && props.getData.userdata.isSeller
              }
              sendLink={submitPrice}
              linkData={linkData}
              message={message}
              msgText={msgText}
              sendMessage={sendMessage}
            /> */}
          </div>
        </div>
      )}

      {/* /////////////// For small screen ////////// */}
      {!matches && (
        <div className="chat-fld-wrap">
          {showLoader ? <Loader message={showMessage} /> : null}
          <div className="container">
            {leftScreenStatus ? (
              <LeftScreen
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isSeller={
                  props.getData.userdata && props.getData.userdata.isSeller
                }
                farScreen={farScreen}
              />
            ) : null}

            {!leftScreenStatus ? (
              <CenterView
                conversation={props.conversation}
                userId={userId}
                setMsgText={setMsgText}
                msgText={msgText}
                sendMessage={sendMessage}
                linkData={linkData}
                prod={prod}
                backScreen={backScreen}
                handleModal={handleModal}
              />
            ) : null}

            {/* <RightScreen
              sendMessage={sendMessage}
              conversation={props.conversation.isSellerAccount}
              prod={prod}
              cartData={props.cartData}
              handleAddCart={handleAddCart}
              gotocart={gotocart}
              makeAnOffer={props.makeAnOffer}
              getproductDetail={() =>
                props.loadProd(query.p_id, (status) => {
                  if (status) {
                    setProd(status);
                  }
                })
              }
              isSeller={
                props.getData.userdata && props.getData.userdata.isSeller
              }
              sendLink={submitPrice}
              linkData={linkData}
              message={message}
              modalHandle={modalHandle}
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  msgListObj: state.chatModule.messageListObj,
  conversation: state.chatModule.conversation,
  cartData: state.cart.getcart,
  getData: state.userProfile.userProfileBanner,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getMessageList: (tab, res) => dispatch(getMessageList(tab, res)),
    sendMsg: (payload, completion) => dispatch(sendMsg(payload, completion)),
    getConversation: (payload, completion) =>
      dispatch(getConversation(payload, completion)),
    loadProd: (payload, completion) => loadProd(payload, completion),
    addCart: (data, id, callback) => dispatch(addCart(data, id, callback)),
    makeAnOffer: (payload, completion) =>
      dispatch(makeAnOffer(payload, completion)),
    getTotalUnreadMsgAndWishlistItem: (payload) =>
      dispatch(getTotalUnreadMsgAndWishlistItem(payload)),
    chatOffer: (payload, completion) =>
      dispatch(chatOffer(payload, completion)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatMainScreen)
);
