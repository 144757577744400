import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
import {Link} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const SupplierTestimonials = () => {
	let settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
			 breakpoint: 992,
			 settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			 },
			},
			
		 ]
	  };
	  useEffect(() => {
		const wow = new WOW.WOW({
		  live: true, // Enables the live mode to detect changes dynamically
		});
		wow.init();
	  }, []);

  return (
	<div className="supplier-testimonials">
		<div className='show-testimonials'>
		<div className="container">
			<h4>Supplier Testimonials</h4>
			<div className="wow animate__animated animate__fadeInUp">
			<Slider {...settings}>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/bk.png" alt="Buyer-testimonials1" />
					</div>
					<div className="testimonial-details">
						<p>Metalsbuy is my go-to platform for selling metal raw materials. It's not just about listing; it's about connecting with real buyers and closing deals at fair prices.</p>
							<span className='buyer-name'><strong>Mr. BK Singh</strong>Srijan Alloys</span>
					</div>
			</div>
          </div>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/nr.png" alt="Buyer-testimonials1" />
					</div>
					<div className="testimonial-details">
						<p>Metalsbuy has streamlined my sales process. Their focus on trust and transparency sets them apart. Real-time price negotiations make transactions smooth and efficient.
</p>
							<span className='buyer-name'><strong>Shubham Agrawal</strong>NR Steels</span>
					</div>
			</div>
          </div>
		  <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/bk.png" alt="Buyer-testimonials1" />
					</div>
					<div className="testimonial-details">
						<p>Metalsbuy is my go-to platform for selling metal raw materials. It's not just about listing; it's about connecting with real buyers and closing deals at fair prices.</p>
							<span className='buyer-name'><strong>Mr. BK Singh</strong>Srijan Alloys</span>
					</div>
			</div>
          </div>
          <div className="testimonial-container">
		  <div className="show-testimonials-wrap">
					<div className="testimonials-img">
						<img src="../../../assets/images/nr.png" alt="Buyer-testimonials1" />
					</div>
					<div className="testimonial-details">
						<p>Metalsbuy has streamlined my sales process. Their focus on trust and transparency sets them apart. Real-time price negotiations make transactions smooth and efficient.
</p>
							<span className='buyer-name'><strong>Shubham Agrawal</strong>NR Steels</span>
					</div>
			</div>
          </div>
        </Slider>
		</div>
		</div>
	</div>
	</div>
  )
}

export default SupplierTestimonials