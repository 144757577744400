import { SAVE_MESSAGE_LIST, SOCKET_CONNECTED, SAVE_CONVERSATION_MESSAGE } from "./../actionsTypes";

const initialState = {
  messageListObj: {},
  isSocketConnected: true,
  conversation: {}
};

const saveMessageList = (state, payload) => {
  if (payload.page) {
    if (payload.page === 1) {
      return {
        ...state,
        messageListObj: {
          data: payload.data, //payload.data,
          Total: payload.TotalMessage,
          page: payload.page,
        },
      };
    } else {
      return {
        ...state,
        messageListObj: {
          ...state.messageListObj,
          data: [...state.messageListObj.data, ...payload.data],
          Total: payload.TotalProducts,
          page: payload.page,
        },
      };
    }
  }
  else {
    return { ...state }
  }
};

const chatModule = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MESSAGE_LIST:
      return saveMessageList(state, action.payload);
    case SAVE_CONVERSATION_MESSAGE:
      return {
        ...state,
        conversation: action.payload,
      };
    case SOCKET_CONNECTED:
      return {
        ...state,
        isSocketConnected: action.isConnected,
      };
    default:
      return state;
  }
};
export default chatModule;
