import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { priceWithSymbol, RupeesIcon } from "../../../../utils/constants";
import moment from "moment";
import { useSelector } from "react-redux";
import { Rating } from "../MyOrder/listingProduct";
import {
  acceptThisOrder,
  rejectThisOrder,
} from "../../../../store/actions/profile";
import Loader from "../../../Loader/index";
import InputReason from "../MyOrder/inputReason";
import Countdown from "react-countdown";

const PendingTab = (props) => {
  const sellerName = useSelector(
    (state) => state.userInfo.loggedUserInfo.userName
  );
  const [showLoader, setShowLoader] = useState(false);
  const { addToast } = useToasts();
  const [showMessage, setShowMessage] = useState("");
  const [rejectOrderID, setRejectOrderId] = React.useState("");
  const [type, setType] = React.useState(""); // normal or offer
  const [showInputForm, setInpuForm] = React.useState(false);
  let location = useLocation();
  let history = useHistory();


  const acceptOrder = (data) => {
    setShowLoader(true);
    const payload = { orderId: [data] };
    acceptThisOrder(payload, (status) => {
      if (status.status) {
        setShowLoader(false);
        {
          status.data &&
            addToast(status.data.message, {
              appearance: "success",
              autoDismissTimeout: 4000,
            });
        }
        setTimeout(() => history.push("/profile/seller-order/processed"), 4000);
      }
    });
  };

  const rejectOrder = (id, reason) => {
    const payload = { orderId: [id], reason: reason };
    rejectThisOrder(payload, (status) => {
      if (status.status) {
        addToast(
          status.data.message,
          {
            appearance: "success",
            autoDismissTimeout: 4000,
          },
          () => {
            setRejectOrderId("");
          }
        );
        setTimeout(() => history.push("/profile/seller-order/cancelled"), 4000);
      }
    });
  };

  const getClass = (data) => {
    if (data === "Completed") return "confirm order-status-box";
    else if (data === "Processed") return "process order-status-box";
    else if (data === "Cancelled") return "cancel order-status-box";
    else if (data === "Returned") return "return order-status-box";
    else return "order-status-box";
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const renderer1 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };



  return (
    <div className="plp-product-screen">
      {showLoader ? <Loader message={showMessage} /> : null}
      {!isEmpty(props) &&
        props.product.length > 0 &&
        props.product.map((item, i) => {
          const payload = {
            orderId: item._id,
            view: "seller",
            type: "Pending",
          };
          let grand_total =
            typeof item.grand_total === "object" && item.grand_total !== null
              ? item.grand_total.$numberDecimal
              : item.grand_total;
          return (
            <div key={"id"} className="buy-prod buyer-product-wrapper">
              <div className="est-deliveries order-east-deliveries">
                <div className="prod-img product-img-wrapper">
                  <Link
                    to={(location) => ({
                      pathname: `/profile/seller-order/order-detail/${item._id}/Pending`,
                      state: { ...location.state, payload },
                    })}
                  >
                    <img src={item.product_detail.default_image} alt="img" />
                  </Link>
                </div>
                {/* {props.type === "Processed" && (
                <div className="est-delivery">
                  Estimate Delivery{" "}
                  {moment(item.delivery_date_to).format("DD-MM-YYYY")}
                </div>
              )} */}
              </div>
              <div className="prod-detail product-orderinfo product-info-detail-wrap">
                <Link
                  className="prod-link seller-pending-prod"
                  key={i}
                  to={(location) => ({
                    pathname: `/profile/seller-order/order-detail/${item._id}/Pending`,
                    state: { ...location.state, payload },
                  })}
                >
                  <div className="prod-title product-switch">
                    <span className="prod-text bold head">
                      {item.product_detail.productName}
                    </span>
                    {/* <span className="prod-text sub-head">
                            {item.product_detail.Title}
                          </span> */}
                  </div>

                  {/* 24 sept {item.odid && <p>Order ID: {item.odid} </p>} */}
                  <div className="status-details status-order-wrapper" style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "3px 0 7px 0" }}>
                    <div className="order-status-text" style={{ fontWeight: "600", fontFamily: "Inter", fontSize: "16px", color: "#333333" }}>
                      Payment status:
                    </div>
                    <span
                      className={getClass(
                        item.current_status || item.order_status
                      )}
                    >
                      {item.order_status == "ProformaInvoice" ? "Proforma Invoice" :
                        item.order_status == "ReadyToShip" ? "Ready To Ship" :
                        item.order_status == "Processed" ? "Processing" :
                          item.order_status == "GoodsDispatched" ? "Goods Dispatched" :
                            item.order_status == "OrderComplete" ? "Order Complete" :
                              item.order_status == "GoodsReceived" ? "Goods Received" :
                                item.order_status
                      }
                    </span>
                  </div>


                  {/*  24-oct-{item.product_detail.Attrs
                    ? item.product_detail.Attrs.map((attrName, i) => (
                      <div className="home-detail" key={i}>
                        <span className="prod-text">{attrName.name}:</span>
                        <span className="prod-text bold">
                          {attrName.value}
                        </span>
                      </div>
                    ))
                    : null} */}

                  <div className="home-detail qty-pricing home-qty-pricing">
                    <div className="home-details">
                      <span className="prod-text bold quty">
                        Quantity:&nbsp;
                      </span>
                      <span className="prod-text value">
                        {item.order_item_qty}mt{" "}
                        {/*24-oct - {item.order_item_qty}*/}
                      </span>
                    </div>
                    <div className="home-detail qty-pricing home-qty-pricing">
                      <div className="home-details">
                        <span className="prod-text bold quty" style={{ marginLeft: '0px' }}>
                          Price (PMT):&nbsp;
                        </span>
                        <span className="prod-text value">
                          ₹{item.unit_price}
                        </span>
                      </div>
                    </div>
                    <div className="pro-price" style={{ marginTop: "-0.5rem" }}>
                      <span className="prod-text bold quty">Total Price:&nbsp;</span>
                      <span className="prod-text value">
                        {RupeesIcon} {item.total_order_amount}
                      </span>
                    </div>
                    <div className="home-detail qty-pricing" style={{ marginTop: "0.5rem" }}>
                      <div className="home-details">
                        <span
                          className="prod-text bold sub-name"
                          style={{ marginLeft: "0" }}
                        >
                          Buyer name:&nbsp;
                        </span>
                        <span className="prod-text value">
                          {item.customerData[0].companyName}
                        </span>
                      </div>
                    </div>
                  </div>

                </Link>
              </div>

              <div className="prod-title prod-toggle product-detail-completed">
                <div className="del-details" style={{ marginBottom: "0" }}>
                  <Link
                    key={i}
                    to={(location) => ({
                      pathname: `/profile/seller-order/order-detail/${item._id}/Pending`,
                      state: { ...location.state, payload },
                    })}
                    className="completed-success"
                  >
                    {props.type === "Completed" ? (
                      <>
                        <strong className="delivery-date">
                          Delivered on{" "}
                          {moment(item.current_status_time).calendar({
                            sameDay: "h:mm a",
                            nextDay: "[Tomorrow] h:mm a",
                            nextWeek: "dddd",
                            lastDay: "[Yesterday] h:mm a",
                            lastWeek: "DD/MM/YYYY h:mm a",
                            sameElse: "DD/MM/YYYY h:mm a",
                          })}
                        </strong>
                      </>
                    ) : props.type === "Processed" ? (
                      <>
                        <strong>
                          Item request received on{" "}
                          {moment().format("Do MMMM YYYY h:mm a")}
                          {/*24-sept{moment(item.createdAt).format("MMMM Do YYYY h:mm a")} */}
                        </strong>
                      </>
                    ) : props.type === "Cancelled" ? (
                      <>
                        <strong className="delivery-date">
                          Cancelled on{" "}
                          {moment(item.current_status_time).calendar({
                            sameDay: "DD/MM/YYYY h:mm a",
                            nextDay: "DD/MM/YYYY h:mm a",
                            nextWeek: "DD/MM/YYYY h:mm a",
                            lastDay: "DD/MM/YYYY h:mm a",
                            lastWeek: "DD/MM/YYYY h:mm a",
                            sameElse: "DD/MM/YYYY h:mm a",
                          })}
                          {/*24-sept {moment(item.cancel_time).format("MMMM Do YYYY h:mm a")} */}
                        </strong>
                        <span> Your item has been cancelled </span>
                      </>
                    ) : props.type === "Returned" ? (
                      <>
                        <strong className="delivery-date">
                          Return request on{" "}
                          {moment().format("Do MMMM YYYY h:mm a")}
                          {/*24-sept {moment(item.Returned_time).format("MMMM Do YYYY h:mm a")} */}
                        </strong>
                        <span> Item returned</span>
                      </>
                    ) : null}

                    {/* 24 sept {item.odid && <p>Order ID: {item.odid} </p>} */}
                    {item.isVisible == false ? (
                      <div className="count-wrap" style={{ alignItems: "center" }}>
                        <i className="fas fa-clock" style={{ margin: "0 5px 10px 0", color: "#f47900" }}></i>
                        <p style={{ color: "#f47900" }}>Expires in:</p>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <Countdown
                            date={item.categories.name!=="Coil"?new Date(item.createdAt).getTime() +
                              30 * 60 * 1000:
                            new Date().getTime()+( new Date( new Date(item.createdAt)).setHours(24, 0, 0, 0)- new Date().getTime())
                          }
                            renderer={item.categories.name!=="Coil"?renderer1:renderer}
                            zeroPadTime={2}
                          />
                          <span style={{ marginBottom: "5px" }}>&nbsp;mins</span>
                        </span>
                      </div>
                    ) : null}
                    <div className="rating-msg rating-msz-wrapper">
                      {(props.type === "Completed" ||
                        props.type === "Returned") && (
                          <div className="rating-star rating-star-new">
                            <ul className="ratings">
                              <Rating
                                val={
                                  item.reviewData[0]
                                    ? item.reviewData[0].rating
                                    : 0
                                }
                              />
                            </ul>
                          </div>
                        )}

                      {/* {item.customer_details.customer_id !==
                              item.seller_details._id && (
                                <Link
                                  className="shop-now msg-btn"
                                  to={{
                                    pathname: "/chats/all",
                                    state: { chatPayload: { sender_id: item.customer_details.customer_id, product_id: item.product_id } }
                                  }}
                                  data-count={item.messageCount}
                                >
                                  <i className="fas fa-envelope icon-chat-new" data-count={0} />
                                </Link>
                              )} */}
                    </div>
                  </Link>
                </div>
                {showInputForm && (
                  <InputReason
                    closePopup={() => {
                      setInpuForm(false);
                      setRejectOrderId("");
                      setType("");
                    }}
                    content={`Please type the reason for reject`}
                    orderId={item._id}
                    submitFun={(reason) => {
                      if (reason.length > 20) {
                        rejectOrder(item._id, reason);
                        setInpuForm(false);
                      }
                    }}
                  />
                )}
                {item.isVisible == false && item.sellerApproval == "Pending" ? (
                  <div className="approval-wrap">
                    <button
                      className="approve-btn btn"
                      onClick={() => acceptOrder(item._id)}
                    >
                      Confirm Payment
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default PendingTab;
