import { Authorization } from "./Authorization";

const userEmail = localStorage.getItem("userEmail");
const loginInfo = Authorization();

export const onUserLogin = (email) => {
  const analytics = window.analytics;
  if (email && analytics && !isSuperAdmin()) {
    analytics.track("User login", {
      email: email || "",
    });
  }
};

export function onProductView(product) {
  const analytics = window.analytics;
  const userData = (loginInfo && loginInfo.payload) || {};
  console.log('userData ',userData);
  if (!isSuperAdmin() && analytics) {
    analytics.track("Product View", {
      viewerEmail: userData.email,
      viewerCompany: userData.name,
      viewerUserType: getUserType(),
      productId: product._id || " ",
      productName: `${product.productName} (${product.productGrade})`,
      sellerCompanyName:
        (product.userData[0] && product.userData[0].companyName) || "",
      sellerName: (product.userData[0] && product.userData[0].full_name) || "",
    });
  }
}

export const onPageView = (userData) => {
  const analytics = window.analytics;
  if (!isSuperAdmin() && analytics && userData) {
    analytics.page(undefined, {
      viewerEmail: userData.email,
      viewerCompany: userData.name,
      viewerUserType: getUserType(),
    });
  }
};

const isSuperAdmin = () => {
  return localStorage.getItem("isSuperAdmin");
};

const getUserType = () => {
  return localStorage.getItem("userType");
};
