import React from "react";
import { useToasts } from "react-toast-notifications";

const RelatedArticle = (props) => {
  const { addToast } = useToasts();

  return (
    <div className="rel-art">
      <h4>Related Articles</h4>
      <div className="related-article-table">
        <table style={{ width: "100%" }} id="article-tbl">
          <tr>
            <th>Document Name</th>
            <th>Tax Year</th>
            <th>Action</th>
          </tr>
          {props.data.map((item, i) => (
            <tr key={i}>
              <td>{item.name}</td>
              <td>{item.year}</td>
              <td>
                <button
                  onClick={() => {
                    addToast("Downloading", {
                      appearance: "info",
                      autoDismissTimeout: 2500,
                    });
                    props.downloadReport({ year: item.year });
                  }}
                >
                  <i className="fas fa-file-download"></i>Download
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};
export default RelatedArticle;
