import React, { Component } from "react";
import SimilarProduct from "./SimilarProduct";
import ProductSellerInfo from "./ProductSellerInfo";
import Question from "./Question";
import BreadCrumb from "./BreadCrumb";
import { withRouter } from "react-router-dom";
import { onProductView } from "../../utils/Analytics";

import {
  getproductDetail,
  verifyproduct,
  latestSellProduct,
  makeAnOffer,
  postques,
  postans,
} from "../../store/actions/productDetails";
import { getCart } from "../../store/actions/cartAction";
import { SpinnerLoader } from "../../utils/constants";
import { connect } from "react-redux";
import "./ProductDetails.css";
import { isEmpty, get } from "lodash";
import { parse } from "query-string";
import { useToasts } from "react-toast-notifications";
import Toast from "light-toast";
import Loader from "../Loader";
import TableData from "./TableData";
function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerLoader: false,
      questionPopup: false,
      matches: window.matchMedia("(min-width:768px)").matches,
      showLoader: false,
    };
  }

  componentDidUpdate(prevProps) {
    let query = parse(this.props.location.search);
    if (prevProps.match.params.id !== this.props.match.params.id) {
      window.scrollTo(0, 0);
      Toast.loading("loading...", () => {});
      this.props.getproductDetail(
        { ...this.props.match.params, ...query },
        (status) => {
          if (status.status) {
            Toast.hide();
          } else {
            Toast.hide();
          }
        }
      );
      let userId = localStorage.getItem("userId");
      this.props.getCart(userId);
    }
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    const handler = (e) => this.setState({ matches: e.matches });
    let query = parse(this.props.location.search);
    window.addEventListener("scroll", this.handleScroll);
    this.props.getproductDetail(
      { ...this.props.match.params, ...query },
      (status) => {
        if (status.status) {
          if (this.props.details) {
            onProductView(this.props.details.data[0]);
          }
          this.setState({ showLoader: false });
        } else {
          this.setState({ showLoader: false });
        }
      }
    );
    let userId = localStorage.getItem("userId");
    this.props.getCart(userId);
    window.scrollTo(0, 0);
    this.props.latestSellProduct(
      { productName: this.props.details },
      (status) => {}
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (!this.state.spinnerLoader) {
      let left = document.getElementsByClassName("slide-box-wrap")[0];
      let right = document.getElementsByClassName("des-wrap")[0];
      let el1 = left && left.getBoundingClientRect();
      let el2 = right && right.getBoundingClientRect();
      if (el1.top < 0 && !(el2 && el2.bottom < window.innerHeight)) {
        left.classList.add("image-sticky");
      } else {
        left.classList.remove("image-sticky");
      }
    }
  };

  render() {
    const { spinnerLoader } = this.state;

    return (
      <div>
        {this.state.showLoader ? <Loader message="Please wait..." /> : null}
        <div className="product-sellerwrap seller-wrp-details">
          <div className="container-main">
            {this.props.details && (
              <BreadCrumb details={this.props.details.data[0]} />
            )}
            <ProductSellerInfo
              details={this.props.details}
              verifyproduct={this.props.verifyproduct}
              makeAnOffer={this.props.makeAnOffer}
              getproductDetail={this.props.getproductDetail}
              sellerInfo={this.props.productOwner}
              userInfo={this.props.userInfo}
              reviewList={this.props.productReviewList}
            />

            <div className="container-main similar-product-container">
              <TableData details={this.props.details} />
              {!isEmpty(this.props.moreFromThisSelller) ? (
                <>
                  <SimilarProduct
                    relatedProduct={this.props.moreFromThisSelller}
                    wishlist={get(this.props.wishlist, ["data"], [])}
                    heading={"Similar Products from other seller"}
                    block_name={"More result"}
                    details={this.props.details}
                  />
                </>
              ) : null}
              {/* {!isEmpty(this.props.relatedProduct) ? (
                <SimilarProduct
                  relatedProduct={this.props.relatedProduct}
                  wishlist={get(this.props.wishlist, ['data'], [])}
                  heading={"Similar Offers By Other Seller’s"}
                  block_name={"Similar product"}
                  details={this.props.details}
                />
              ) : null}
              <div className="same-seller-product">
                <SimilarProduct
                  relatedProduct={this.props.relatedProduct}
                  wishlist={get(this.props.wishlist, ['data'], [])}
                  heading={"More Offers By Same Seller’s"}
                  block_name={"Similar product"}
                  details={this.props.details}
                />
              </div> */}
              {/* {this.props.details.data[0].isActive ? ( */}
              {/* ) : null} */}
              <Question
                details={this.props.details}
                postques={this.props.postques}
                postans={this.props.postans}
                pid={this.props.match.params.id}
                popupState={this.state.questionPopup}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    qalist: state.productDetails.qalist,
    details: state.productDetails.details,
    productReviewList: state.productDetails.reviewList,
    productOwner: state.productDetails.productOwner,

    wishlist: state.Wishlist.wishlist,
    allBrand: state.productListingInfo.brands,
    relatedProduct: state.productDetails.relatedProduct,
    moreFromThisSelller: state.productDetails.moreProductFromSeller,
    userInfo: state.userInfo.loggedUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getproductDetail: (payload, status) =>
      dispatch(getproductDetail(payload, status)),
    latestSellProduct: (payload, status) =>
      dispatch(latestSellProduct(payload, status)),
    verifyproduct: (payload, completion) =>
      dispatch(verifyproduct(payload, completion)),
    makeAnOffer: (payload, completion) =>
      dispatch(makeAnOffer(payload, completion)),
    postques: (payload, completion) => dispatch(postques(payload, completion)),
    postans: (payload, completion) => dispatch(postans(payload, completion)),
    getCart: (payload) => dispatch(getCart(payload)),
  };
};

export default withToast(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetail))
);
