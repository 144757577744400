import React from "react";
import "../ProductDetail/ProductDetails.css";


const Popup = (props) => {


  return (
    <div className={"make-offer-popup seller-product-wrapper" + (props.class ? props.class : "")}>
      <div className="offer-content">
        {props.closePopup ? (
          <button
            className="modal-closeBtn"
            onClick={() => props.closePopup(null)}
            type="button"
          >
            {/* <span className="closeBtn-crossLeft"></span>
            <span className="closeBtn-crossRight"></span> */}
            <i className="fal fa-times-circle"></i>
          </button>
        ) : null}
        <div className="offer-title">{props.content}</div>
        <div className="add-links">
          {
            props.closePopup ? (
              <span onClick={() => props.closePopup(null)} className="btn-purple">
                {props.cancelTxt ? props.cancelTxt : "No"}
              </span>
            ) : null
          }

          <span onClick={props.submitFun} className="btn-purple">
            {props.successTxt ? props.successTxt : "Yes"}
          </span>

        </div>
      </div>
    </div>
  );
};
export default Popup;
