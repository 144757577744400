import {
  SET_ABOUT_PRODUCT_DATA,
  CLEAR_SELL_ITEM_INFO,
  SET_UPLOAD_IMAGE_DATA,
  SET_ITEM_DESC,
  // SET_ITEM_SELLER_INFO,
  SET_ITEM_SHIPPING_INFO,
  SET_COUNTRY_DATA,
  SET_ATTRIBUTE_DATA,
  SET_USER_SELECTED_ATTRIBUTES,
  SET_SUBCATEGORY_BRANDS,
  SET_ITEM_DETAIL_FOR_EDIT,
} from "./../actionsTypes";
import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";
import { getUserAddedProduct } from "./profile";
// import {Authorization} from './../../utils/Authorization'
import axios from "axios";

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;


export const uploadMultiImgs = (payload, callback) => {
  const token = localStorage.getItem("token");

  return axiosInstance
    .post(apiUrl.sellItem.uploadimgs, payload, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback(response.data.data)
    })
    .catch((err) => {
      callback([]);
    });
};


export const sellAnItem = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.sellItem.sellItem, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          completion(true);
          dispatch(clearSellItemInfo());
        } else completion(false);
      })
      .catch((err) => {

        completion(false);
      });
  };
};

export const addBrandRequest = async (payload, completion) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      apiUrl.sellItem.addBrand,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    completion(response.data);
  } catch (_) {
    return completion(false);
  }
};




export const addAttributeRequest = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.sellItem.addAtt, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {

        completion(response.data);
      })
      .catch((err) => {

        completion(false);
      });
  };
};


export const getGSTCategory = async (completion) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      apiUrl.sellItem.getGSTcategory,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = response.data.data.map(i => ({ value: i, label: `${i.value} %` }))
    completion(data);
  } catch (_) {
    return completion(false);
  }
};
const CancelToken = axios.CancelToken;
export const costApiCancel = CancelToken.source();
export const getEstimateCost = async (payload, completion) => {
  // costApiCancel.cancel(" canceled By User")
  try {
    const response = await axiosInstance.post(
      apiUrl.sellItem.getOtherCost,
      payload,
      {
        cancelToken: costApiCancel.token,
      }
    );
    completion(response.data);
  } catch (err) {
    completion(false);
  }
};
export const draftAnItem = async (payload, completion) => {
  const token = localStorage.getItem("token");
  try {

    await axiosInstance.post(apiUrl.sellItem.draftItem, payload,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }).then(res => {
        completion(res.data)

      }).catch(err => {

        completion({})
      })

  } catch (err) {

  }
};

export const updateProduct = (payload, productId, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.editAddedProduct.updateProduct + productId, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {

        completion(true);
      })
      .catch((err) => {

        completion(false);
      });
  };
};

export const addProduct = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.editAddedProduct.addProduct, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {

        completion(false);
      });
  };
};

export const addOrder = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post('orders/create-order', payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {

        completion(false);
      });
  };
};

export const getCountry = () => {
  const country = apiUrl.shippingFrom.country;
  return (dispatch) => {
    return axiosInstance.get(country).then((response) => {
      const country = (response && response.data && response.data.data) || [];
      dispatch(setCountry(country));
    });
  };
};


export const getCity = (callback) => {
  const city = apiUrl.shippingFrom.city;
  return (dispatch) => {
    return axiosInstance.get(city).then((response) => {
      // const country = (response && response.data && response.data.data) || [];
      // dispatch(setCountry(country));
      callback(response.data);
    });
  };
};
export const getAttribute = (sub_cat_id, callback) => {
  const attribute = apiUrl.attribute.getAll;
  return (dispatch) => {
    return axiosInstance.post(attribute, sub_cat_id).then((response) => {
      const attributeList =
        (response && response.data && response.data.data) || [];
      dispatch(setAttribute(attributeList));
      callback(attributeList)
    });
  };
};

export const getSubCategorySpecificBrands = (payload) => {
  const attribute = apiUrl.brand.subCategorySpecifBrand;
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(attribute, payload);
      const brand = response.data.data;
      const arr = brand.map((element) => {
        return { value: element, label: element.name };
      });
      dispatch(setSubcategoryBrand(arr)); // for SellAn Item Page
    } catch (e) {
      return true;
    }
  };
};





const setSubcategoryBrand = (data) => {
  return {
    type: SET_SUBCATEGORY_BRANDS,
    payload: data,
  };
};
// export const getCountryCode = () => {  ///for country code
//     const country = apiUrl.shippingFrom.country;
//     return dispatch => {
//         return axiosInstance.get(country)
//                 .then(response => {
//                     const country = (response && response.data && response.data.data) || [];
//                     dispatch(setCountry(country));
//                 })
//     }
// }

export const setAttribute = (attribute) => {
  return {
    type: SET_ATTRIBUTE_DATA,
    payload: attribute,
  };
};

export const setCountry = (country) => {
  return {
    type: SET_COUNTRY_DATA,
    payload: country,
  };
};

export const setAboutProductData = (aboutProductData) => {
  return {
    type: SET_ABOUT_PRODUCT_DATA,
    payload: aboutProductData,
  };
};

export const clearSellItemInfo = () => {
  return {
    type: CLEAR_SELL_ITEM_INFO,
    payload: {},
  };
};

export const setUploadImageData = (uploadImageData) => {
  return {
    type: SET_UPLOAD_IMAGE_DATA,
    payload: uploadImageData,
  };
};
export const setDescription = (description) => {
  return {
    type: SET_ITEM_DESC,
    payload: description,
  };
};
// export const setItemSellerInfo = (itemSellerInfo) => {
//   return {
//     type: SET_ITEM_SELLER_INFO,
//     payload: itemSellerInfo,
//   };
// };
export const setShippingInfo = (itemShippingnfo) => {
  return {
    type: SET_ITEM_SHIPPING_INFO,
    payload: itemShippingnfo,
  };
};
export const setUserSelectedAttr = (userSelectedAttr) => {
  return {
    type: SET_USER_SELECTED_ATTRIBUTES,
    payload: userSelectedAttr,
  };
};

//############### LOAD PRODUCT FOR EDIT ### AND SAVE IN STORE ####################################

// export const loadProductForEdit = (payload, type) => {
//   let userId = localStorage.getItem("userId");
//   return (dispatch) => {
//     return axiosInstance
//       .post(
//         type === "edit"
//           ? apiUrl.editAddedProduct.loadProduct + payload
//           : type === "unApproved"
//             ? apiUrl.editAddedProduct.loadUnApprovedProd + payload
//             : apiUrl.editAddedProduct.getDraftProd + payload,
//         { userId }
//       )
//       .then((response) => {

//         const data = response.data.data[0];
//         const itemImage = {
//           files: () =>
//             data.Image.map((data) => {
//               return `${data.original}`;
//             }),
//           fileValues: Array(data.Image.length).fill(0),
//           PRODUCTIMAGEPATH: response.data.PRODUCTIMAGEPATH,
//         };

//         const aboutProductData = {
//           title: data.Title,
//           selectedCategory: data.categoryData[0]
//             ? {
//               label: data.categoryData[0].name,
//               value: data.categoryData[0],
//             }
//             : [],
//           selectedSubCategory: data.subcategoryData[0]
//             ? {
//               label: data.subcategoryData[0].name,
//               value: data.subcategoryData[0],
//             }
//             : [],
//           selectedBrand: data.brandData[0]
//             ? [
//               {
//                 label: data.brandData[0].name,
//                 value: data.brandData[0],
//               },
//             ]
//             : [],
//           quantity: data.Quantity,
//           productId: data._id,
//           hsn:data.hsn_code
//         };

//         const itemDescription = {
//           description: data.Description,
//           product_tag: data.Product_tag.split(",") || ["", "", ""],
//           condition: data.Condition,
//         };

//         const attributes = {
//           name: data.Attrs.map((data) => data.name),
//           value: data.Attrs.map((data) => data.value),
//           condition: { value: data.Condition, label: data.Condition },
//         };

//         // const sellerinfo = {
//         //   shippingFrom: {
//         //     label: data.countryData[0].name,
//         //     value: data.countryData[0],
//         //   },
//         //   selectedState: {
//         //     label: data.stateData[0].statename,
//         //     value: data.stateData[0],
//         //   },
//         //   mobileNo: data.Mobile_no,
//         //   city: data.City,
//         //   EDITMODE: true,
//         // };
//         const shipping = {
//           storePrice: data.Price.current_store_price,
//           priceWillReceive: data.Price.sell_price,
//           EDITMODE: true,
//           productId: data._id,
//           Product_status: data.Product_status,
//         };

//         const payload = {
//           ...itemImage,
//           ...aboutProductData,
//           ...itemDescription,
//           ...attributes,
//           shipping,
//         };

//         dispatch(setAllDataForEdit(payload));

//         dispatch(setAboutProductData(aboutProductData));
//         dispatch(
//           setUploadImageData({ ...itemImage, files: itemImage.files() })
//         );
//         dispatch(setDescription(itemDescription));
//         dispatch(setUserSelectedAttr(attributes));
//         dispatch(setShippingInfo(shipping));

//         // dispatch(setItemSellerInfo(sellerinfo));

//         // status(true);
//       })
//       .catch((err) => {

//         dispatch(setAllDataForEdit([]));
//       });
//   };
// };



// const setAllDataForEdit = (data) => {
//   return {
//     type: SET_ITEM_DETAIL_FOR_EDIT,
//     payload: data,
//   };
// };



export const loadProductForEdit = (payload, type) => {
  let userId = localStorage.getItem("userId");
  let url = apiUrl.editAddedProduct.loadProduct;
  return (dispatch) => {
    return axiosInstance
      .get(url + payload)
      .then((response) => {
        const data = response.data.data[0];
        dispatch({
          type: SET_ITEM_DETAIL_FOR_EDIT,
          payload: data,
        })
      })
      .catch((err) => {

        dispatch({
          type: SET_ITEM_DETAIL_FOR_EDIT,
          payload: {},
        })
      });
  };
};








export const deleteImage = (payload, status) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.editAddedProduct.deleteImage, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        status(true);
      });
  };
};

export const deleteProduct = (payload, type, status) => {

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.editAddedProduct.deleteProduct, {productId:payload, action:type.action},{
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch(getUserAddedProduct({ status:type.type, page: 1 }));
        dispatch(getUserAddedProduct({ status:"inactive", page: 1 }));
        dispatch(getUserAddedProduct({ status:"active", page: 1 }));

        status(true);
      });
  };
};

export const changeProdStatus = (payload, type, status) => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.editAddedProduct.changeProuctStatus, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let actionStatus = payload.action;
        if(actionStatus == "inactive"){
          dispatch(getUserAddedProduct({ status:payload.action, page: 1 }));
          dispatch(getUserAddedProduct({ status:"active", page: 1 }));
        }else{
          dispatch(getUserAddedProduct({ status:payload.action, page: 1 }));
          dispatch(getUserAddedProduct({ status:"inactive", page: 1 }));
        }
        
        status(true);
      });
  };
};

export const getGstToken = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance.post(apiUrl.editAddedProduct.getGST, payload).then((result) => {
      completion(result.data.data);
    }).catch((err) => {

    })
  }
}
