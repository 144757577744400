import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_cms_data_new } from "../../../store/actions/cms_data";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";



function LocalBazar(props) {
  const [aboutHtml, setaboutHtml] = useState(false);


  useEffect(() => {
    let getSlug = props.match.path.substr(props.match.path.lastIndexOf('/') + 1);
    props.get_cms_data_new({ slug: getSlug }, (status) => {
      setaboutHtml(status.cms_data.description)
    })
    window.scrollTo(0, 0);
  }, []);



  return (
    // <div dangerouslySetInnerHTML={{ __html: aboutHtml }} />
    <>
      <section className="meet-local-bazar">
        <div className="container">
          <div className='meet-local-bazar-wrapper'>
            <div className='right-blog-wrap'>
              <img src="/assets/images/meet-local-bazar.jpg" alt="blog-base" />
            </div>
            <div className='meet-local-left-blog'>
              <h3>Meet Local MetalsBuy</h3>
              <p>The easy way to buy and sell items locally!</p>
              <a href='#' className='get-started'>Get Started</a>
            </div>
            <div className='blog-bottom-arrow'>
              <a href='#' className='arrow-down-blog-wrp'><span className='blog-arrow-img'></span></a>
            </div>
          </div>
        </div>
      </section>
      <section className="about-order-products local-order-products">
        <div className="container">
          <h3>Shopping local Re-Imagined</h3>
          <div className="order-products-wrapper">
            <div className="about-product-card">
              <span className="about-circle-wrap skip-meet-up">
              </span>
              <h4>Skip the meetup</h4>
              <p>Hassle Free way to Buy & Sell items Locally</p>
            </div>
            <div className="about-product-card">
              <span className="about-circle-wrap save-time">
              </span>
              <h4>Save time</h4>
              <p>Quick & Same Day Delivery </p>
            </div>
            <div className="about-product-card">
              <span className="about-circle-wrap free-payment">
              </span>
              <h4>Worry free payment</h4>
              <p>Get paid securely in the app — no need to exchange cash.</p>
            </div>
          </div>
          <h5 className="order-subhead">From the local stores to your favourite brands, grocery shopping, etc.. we are always on the move for you.</h5>
        </div>
      </section>
      <section className="local-shipping-section">
        <div className="container">
          <h2>Perks of shopping local</h2>
          <div className="local-shipping-wrapper">
            <div className="left-local-shipping">
              <div className="inner-list-wrapper">
                <h4>faster delivery</h4>
                {/* <p>Fits your schedule, easy pickup, secure payment.</p> */}
                <div className="time-list-wrapper">
                  <div className="list-item-wrap">
                    <span className="list-number">01</span>
                    <span className="list-text">Hyperlocal marketplaces can be useful for sellers if they want to deliver orders to their
                      customers in a few hours or the same day.</span>
                  </div>
                  <div className="list-item-wrap">
                    <span className="list-number">02</span>
                    <span className="list-text">With the increasing demand for nearby deliveries, sellers wish to establish a presence
                      online so they can deliver quickly. With a hyperlocal marketplace, they can get a platform to do the same.</span>
                  </div>
                </div>
              </div>
              <div className="inner-list-wrapper">
                <h4>versatile inventory</h4>
                {/* <p>Coordination headaches, in-person meetups.</p> */}
                <div className="time-list-wrapper">
                  <div className="list-item-wrap">
                    <span className="list-number">01</span>
                    <span className="list-text">Hyperlocal marketplaces are geography specific. Thus if you start a hyperlocal on-demand
                      market, you can aggregate various sellers onto one platform and deliver seamlessly in a small area in a short period.</span>
                  </div>
                  <div className="list-item-wrap">
                    <span className="list-number">02</span>
                    <span className="list-text">A hyperlocal marketplace can give you access to a variety of inventory and be beneficial to the sellers
                      on your platform and your business.</span>
                  </div>
                </div>
              </div>
              <div className="inner-list-wrapper">
                <h4>sell directly to consumers</h4>
                <div className="time-list-wrapper">
                  <div className="list-item-wrap">
                    <span className="list-number">01</span>
                    <span className="list-text">As more products would be available on one platform, sellers will be able to browse and shop conveniently.
                      This would give your sellers more visibilty.</span>
                  </div>
                  <div className="list-item-wrap">
                    <span className="list-number">02</span>
                    <span className="list-text">They will be able to sell directly customers without the involvement of mediators who take commissions to
                      provide a fleet of riders.</span>
                  </div>
                </div>
              </div>
              <div className="inner-list-wrapper">
                <h4>no specific packaging guidelines</h4>
                <div className="time-list-wrapper">
                  <div className="list-item-wrap">
                    <span className="list-number">01</span>
                    <span className="list-text">A great advantage of hyperlocal deliveries is the absence of packaging guidelines.
                      No rider will send you packaging requirements for each other.</span>
                  </div>
                  <div className="list-item-wrap">
                    <span className="list-number">02</span>
                    <span className="list-text">The sellers will only need to package them according to the product and vehicle. Since
                      the distances are not very long, the packaging will remain sturdy.</span>
                  </div>
                  <div className="list-item-wrap">
                    <span className="list-text">**Note: The only condition is that the products must not spill or get tampered on the way.</span></div>
                </div>
              </div>
              <div className="inner-list-wrapper">
                <h4>no additional investment for sellers</h4>
                <div className="time-list-wrapper">
                  <div className="list-item-wrap">
                    <span className="list-text additional">With hyperlocal marketplaces, local sellers get a platform to sell their products
                      without setting up their stores online. Also, buyers get multiple options on one stage. Hence, it is a win-win solution for everyone.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-local-shipping">
            <img src="/assets/images/local-shipping-right-img.png" alt="right-local-img" />
          </div>
        </div>
      </section>
      {/* <section className="about-order-products local-like-business local-bazar-working">
        <div className="container">
          <h3>How It Works?</h3>
          <div className="order-products-wrapper">
            <div className="about-product-card">
              <h4>List it with Mercari Local</h4>
              <p>Take a few photos, name your
                price, select Mercari Local as the
                delivery option, and tap “List”.</p>
              <span className="local-bazar-product-cost">How much does ot cost?</span>
              <div className="local-step-wrapper">
                <span className="step-digit">01</span>
                <span className="step-digit-text">STEP</span>
              </div>
            </div>
            <div className="about-product-card">
              <h4>Schedule the pickup</h4>
              <p>Once your item sells, we’ll ask for
                your availability.</p>
              <div className="local-step-wrapper">
                <span className="step-digit">02</span>
                <span className="step-digit-text">STEP</span>
              </div>
            </div>
            <div className="about-product-card">
              <h4>Place item for pickup</h4>
              <p>The driver will pick up the item at
                your chosen time.</p>
              <div className="local-step-wrapper">
                <span className="step-digit">03</span>
                <span className="step-digit-text">STEP</span>
              </div>
            </div>
            <div className="about-product-card">
              <h4>Get paid</h4>
              <p>You get paid as soon as the
                buyer rates the transaction.</p>
              <div className="local-step-wrapper">
                <span className="step-digit">04</span>
                <span className="step-digit-text">STEP</span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="selling-easy-section">
        <div className="container">
          <div className="selling-easy-wrapper">
            <div className="selling-easy-left-block">
              <h2>See how easy selling locally can be</h2>
            </div>
            <div className="selling-easy-right-block">
              <div className="local-selling-img-wrap">
                <img src="/assets/images/forth-article.png" alt="sell-img" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="local-bazar-ideas">
        <div className="container">
          <h2>Need some ideas?</h2>
          <p>Try checking your closet or garage for items like these.</p>
          <div className="local-idea-wrap">
            <div className="about-local-idea">
              <div className="about-local-img">
                <img src="/assets/images/local-wellness.png" alt="sell-img" />
              </div>
              <h4>Wellness</h4>
            </div>
            <div className="about-local-idea">
              <div className="about-local-img">
                <img src="/assets/images/local-game-toy.png" alt="sell-img" />
              </div>
              <h4>Electronics</h4>
            </div>
            <div className="about-local-idea">
              <div className="about-local-img">
                <img src="/assets/images/local-home-appliances.png" alt="sell-img" />
              </div>
              <h4>Home</h4>
            </div>
            <div className="about-local-idea">
              <div className="about-local-img">
                <img src="/assets/images/local-baby-chair.png" alt="sell-img" />
              </div>
              <h4>Baby</h4>
            </div>
            <div className="about-local-idea">
              <div className="about-local-img">
                <img src="/assets/images/local-office.png" alt="sell-img" />
              </div>
              <h4>Office</h4>
            </div>
            <div className="about-local-idea">
              <div className="about-local-img">
                <img src="/assets/images/local-outdoor.png" alt="sell-img" />
              </div>
              <h4>Outdoor</h4>
            </div>
          </div>
          <a href="#" className="list-now-btn">List Now</a>
        </div>
      </section> */}
      <section className="local-product-availablity">
        <div className="container">
          <h2>Now Available In</h2>
          <ul className="nation-wide-wrapper">
            <li className="nation-list-wrap">
              <ul className="nation-list-menu">
                <li>Ahmedabad</li>
                <li>Delhi</li>
                <li>Mumbai</li>
              </ul>
            </li>
            <li className="nation-list-wrap">
              <ul className="nation-list-menu">
                <li>Bangalore</li>
                <li>Hyderabad</li>
                <li>Pune</li>
              </ul>
            </li>
            <li className="nation-list-wrap">
              <ul className="nation-list-menu">
                <li>Chennai</li>
                <li>Kolkata</li>
                <li className="many-more">Many More <span>adding soon..</span></li>
              </ul>
            </li>
          </ul>
          {/* <a href="#" className="list-now-btn">List of all cities</a> */}
        </div>
      </section>
      <section className="about-order-products sell-nation-wide local-user-feedback">
        <div className="container">
          <h3>Our Users Voice</h3>
          {/* <p>Our sellers have made over $1 billion in sales on MetalsBuy</p> */}
          <div className="order-products-wrapper">
            <div className="nation-card-wrap">
              <h4>Amazing service!</h4>
              <p>Needed a last minute gift for our kids.
                Didn’t just make my day, but their day too.</p>
            </div>
            <div className="nation-card-wrap">
              <h4>Super easy.</h4>
              <p>I didn’t need time to print the label, package, and go to the post office.A lot of energy was saved.</p>
            </div>
            <div className="nation-card-wrap">
              <h4>Fast alerts.</h4>
              <p>Really quick with payment and scheduling delivery from Uber.Would definitely recommend to anyone.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="local-frequently-ask-questions">
        <div className="container">
          <h3>Have questions?</h3>
          {/* <p>Check out articles on MetalsBuy Local.</p> */}
          {/* <div className="local-accordion">
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What can I sell on Mercari Local?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    You can list items that weigh less than 50 lbs and fit into the trunk of a car (45" x 35" x 15"). Bulky, fragile, and hard to ship items are perfect for Mercari Local. Think home decor, video game consoles, fitness equipment, and outdoor gear.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How to pack for Mercari Local?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Put your item in a sealed bag or box. If it's fragile, package it with care to protect it from bumps on the road or sudden stops in transit. Label your package with the buyer's name and apartment number.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How do I get paid?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    You get paid after your item has been delivered and rated by the buyer. Once you have a balance, you can request a direct deposit to your checking account or cash out in minutes with Instant Pay.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How are sellers protected on Mercari?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    With each Mercari Local order, you're covered with $200 Shipping Protection if your item is damaged or lost in transit.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div> */}
          {/* <a href="#" className="list-now-btn">View all FAQ’s</a> */}
          <div className="faq-wrap">
            <h4>What can I sell on ?</h4>
            <p>You can list items that weigh less than 5 kgs.</p>
          </div>
          <div className="faq-wrap">
            <h4>How to Pack</h4>
            <p>There are no specific packaging guidelines for , just make sure it's sealed properly.</p>
            <p>If it's fragile, package it with care so that  the items don't get damaged in transit.</p>
            <p>Label your package with the buyer's contact details.</p>
          </div>
          <div className="faq-wrap">
            <h4>How do I get paid?</h4>
            <p>You get paid once your item is delivered successfully.</p>
            <p>Once you have a balance, you can request a direct deposit to your verified bank A/C</p>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_cms_data_new: (payload, completion) =>
      dispatch(get_cms_data_new(payload, completion))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocalBazar));