import React from "react";
import CardDiaries from "./CardDiaries";
import Slider from "react-slick";
import { connect } from "react-redux";
import { getDashboardCategoryProd } from "../../store/actions/dashboard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



class BazaarDiaries extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      totalData: 0,
      DiariesData: [],
    };
  }


  componentDidMount() {
    const { id, block_name } = this.props.data;
    this.props.getProduct({ block_name, id }, (status) => { });
  }

  static getDerivedStateFromProps = (props, state) => {
    const filePath = props && props.DiariesData && props.DiariesData.IMAGEPATH;
    const DiariesArr = (props && props.DiariesData && props.DiariesData.data) || [];
    const DiariesArrLength = (props && props.DiariesData && props.DiariesData.data.length) || 0;

    const structuredBlogData = DiariesArr.map((item) => {
      return {
        img: `${filePath}${item.blog_image}`,
        id: item._id,
        heading: item.content_heading,
        short_desc: item.short_desc,
        slug: item.slug,
      };
    });
    return {
      ...state,
      totalData: DiariesArrLength,
      DiariesData: structuredBlogData,
    };
  };

  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  render() {
    const { name, product_count } = this.props.data;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      afterChange: (current) => this.setState({ current: current }),
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };


    return (
      <div>
        <div className="szn-diaries">
          <div className="container">
            <div className="szn-caption">
              <h2>{name}</h2>
              <p>
                Explore a world of exciting designs, handpicked just for you
              </p>
            </div>
            <div className="diaries">
              <button onClick={this.previous} className="btn-diaryprev btn-diaries">
                <i className="icon-diaryprev"></i>{" "}
              </button>
              <button onClick={this.next} className="btn-diarynext btn-diaries">
                <i className="icon-diarynext"></i>{" "}
              </button>
              {this.state.DiariesData.length !== 0 && (
                <Slider {...settings} ref={(c) => (this.slider = c)}>
                  {this.state.DiariesData.slice(0, product_count).map(
                    (data, i) => (
                      <CardDiaries key={i} {...data} />
                    )
                  )}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let id = ownProps.data.id;
  return {
    allData: state.dashboardInfo,
    DiariesData: state.dashboardInfo[id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: (payload, completion) => dispatch(getDashboardCategoryProd(payload, completion)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(BazaarDiaries);
