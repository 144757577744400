import React from "react";

class Practice extends React.PureComponent{
    constructor(){
        super();
        this.state={
            name:"Alok"
        }
    }

    handleUpdate=()=>{
        this.setState({name:"Sharma"})
    }
    render(){
        return(
            <>
              <button onClick={()=>this.handleUpdate()}>Update State</button>
            </>
        )
    }
        
        
}

export default Practice;