import React, { useState, useEffect } from "react";
import OfferProduct from "./OfferProduct";
import { SpinnerLoader } from "../../../../utils/constants";
// import "../../Profile.css";
import { useToasts } from "react-toast-notifications";
import { myofferedSellerRoutes } from "../profileRoutes";
import { Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import OfferDetails from "./OfferedDetails";
import { get } from "lodash";
import Loader from "../../../Loader";

const Myoffer = (props) => {
  const [innerLoader, setInnerLoader] = useState(false);
  const location = useLocation();
  const { addToast } = useToasts();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  useEffect(() => {
    setShowLoader(true);
    setShowMessage("Please wait...");
    window.scrollTo(0, 0);
  }, [location.state]);

  useEffect(() => {
    let paramArr = location.pathname.split("/");

    if (paramArr.length > 4) {
      props.history.push("/not-found");
    }
  }, []);

  const setLoaderState = () => {
    setShowLoader(false);
  };

  const tabRender = () => (
    <Switch>
      <Route
        path={`/profile/offered-product/offer-detail`}
        exact
        render={(prev) => (
          <OfferDetails {...prev} setShowLoader={setShowLoader} setLoaderState={setLoaderState} />
        )}
      />
      <Route
        path="/profile/offered-product/pending"
        render={(prev) => (
          <OfferProduct
            {...prev}
            name="Pending"
            setLoaderState={setLoaderState}
          />
        )}
      />
      <Route
        path="/profile/offered-product/Accepted"
        render={(prev) => (
          <OfferProduct
            {...prev}
            name="Accepted"
            setLoaderState={setLoaderState}
          />
        )}
      />
      {/* <Route
        path="/profile/offered-product/Rejected"
        render={(prev) => (
          <OfferProduct
            {...prev}
            name="Declined"
            setLoaderState={setLoaderState}
          />
        )}
      /> */}

      <Redirect
        from="/profile/offered-product"
        to="/profile/offered-product/Pending"
      />
    </Switch>
  );

  return (
    <div style={{ position: "relative" }}>
      {showLoader ? <Loader message={showMessage} /> : null}
      <div
        className={"blogtags-sec" + (innerLoader ? " spinner-time-div" : "")}
      >
        {!get(location, ["state", "payload"], false) && (
          <div className="tags-wrap">
            <div className="container">
              <div className="tags 788">
                {myofferedSellerRoutes.map((data, indx) => (
                  <Link
                    key={indx}
                    to={{
                      pathname: data.prefix + data.pathname,
                      state: data.state,
                    }}
                    className={
                      data.pathname ===
                        location.pathname.replace(
                          "/profile/offered-product/",
                          "/"
                        )
                        ? "active-tab"
                        : ""
                    }
                  >
                    {data.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="container">{tabRender()}</div>
      </div>
    </div>
  );
};

export default Myoffer;
