import React, { useState, useEffect } from "react";
import { getSeller } from "../../../store/actions/sellerRegisterationAction";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../../utils/Service";
const AddressList = ({
  item,
  clickEditHandler,
  isDefaultId,
  addressHandler,
  getAddressHandle,
  handleEdit,
  sellerData,
  setLoaderHandle
}) => {
  // const dispatch = useDispatch();
  // const [sellerData, setSellerData] = useState(null);

  // useEffect(() => {
  //   dispatch(
  //     getSeller((callback) => {
  //       if (callback) {
  //         setSellerData(callback[0].companyName);
  //       }
  //     })
  //   );
  // }, []);

  const handleDelete = (id) => {
    setLoaderHandle(true)
    axiosInstance.post("/auth/delete_address", {
      "addressId": id
    }).then((res) => {
      setLoaderHandle(false)
      getAddressHandle()
    }).catch(()=>{
      setLoaderHandle(false)
    });
  }

  return (
    <div className="shipping-inner shipping-remade">
      <div className="ship-radio shipping_radio_btn">
        <input
          type="radio"
          value={item._id}
          checked={item._id === isDefaultId}
          className="radio"
          onChange={addressHandler}
        />
        <div className="radio-circle"></div>
      </div>
      {/* {
                item._id === isDefaultId &&
                <div className="head-wrap ship-edit">
                    <span className="edit-link edit-btn" onClick={() => clickEditHandler(item._id)}>
                        <i className="edit-address-icon"></i>
                        <b className='edit-address-pen'>edit</b>
                    </span>
                </div>
            } */}

      <div className="address-left-checkout-page">
        <div className="desc-txt payment-desc-text">
          <div className="ship-address-wrapper">
            <span className="ship-add-name">{sellerData}</span>
            <br />
            <span className="ship-add-number">{item.phone}</span>
          </div>
          <div className="address-checkout shipping-address shipping-checkout shipping-check-wrapper">
            <i className="location-icon"></i>
            <div className="ship-wrapper-main">
              <span className="ship-add">{item.billing_flat_no}, </span>
              <span className="ship-add">{item.street}, </span>
              {item.landmark && item.landmark.length && (
                <span className="ship-add">{item.landmark}, </span>
              )}
              <span className="ship-add">{item.billing_postcode}, </span>
              <span className="ship-add">{item.billing_city}, </span>
              {item.state && item.state.length > 0 && <span className="ship-add">{item.state[0].name}</span>}
            </div>
          </div>
        </div>
      </div>
      {!item.is_default && <button className="delete-address-checkout" onClick={() => handleDelete(item._id)}><i className="fas fa-trash" style={{color:"#ed9829"}}></i></button>}
      {!item.is_default && <button className="edit-address-checkout" onClick={() => handleEdit(item)}><i className="fas fa-edit" style={{color:"#ed9829"}}></i></button>}
    </div>
  );
};

export default AddressList;
