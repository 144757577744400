import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { RupeesIcon, priceWithSymbol } from '../../utils/constants';
import { Link, withRouter } from 'react-router-dom';
import MakeOffer from "../ProductDetail/MakeOffer";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { chatOffer } from "../../store/actions/chatAction";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Toast from "light-toast";
import { getUserAddress, getUserAddressBySeleer } from "../../store/actions/profile";
import { Modal, Button, Form } from 'react-bootstrap'
import './modal.css';
import { getShippingCost } from "../../store/actions/productDetails";
import { useDispatch } from "react-redux";
import { Shipping_Cost } from "../../store/actions/cartAction";
import Loader from "../Loader";


const RightScreen = (props) => {
  const [makeOffer, setMakeOffer] = React.useState(false);
  const [amount, setAmount] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [AddrId, setAddrId] = React.useState("");
  const [addressData, setAddressData] = React.useState([]);
  const [getResponseMessage, setGetResponseMessage] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hours, setHours] = useState(10)
  const { addToast } = useToasts();
  const [disableButton, setDisableButton] = useState(false);
  let dispatch = useDispatch()


  useEffect(() => {
    if (props.modalHandle) {
      handleOpen();
    }
  }, [props.modalHandle])




  const checkDisabled = (data) => {
    let disabled = false;
    if (data.deletedAt === 1) {
      disabled = true;
    } else if (data.Moderate === 1) {
      disabled = true;
    } else if (data.Quantity <= 0) {
      disabled = true;
    } else {
      disabled = false;
    }
    return disabled;
  };


  const getAmount = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setAmount(e.target.value);
    }
  }

  const submitPrice = () => {
    if (amount == "") {
      return addToast("Please enter amount first", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
    }

    if (amount == 0) {
      return addToast("Amount should greater than 0", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
    }



    if (addressData.length === 0) {
      return addToast("Address is not available", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
    } else if (AddrId == "") {
      return addToast("Please select address first", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
    } else {

    }



    setShowLoader(true);
    setLoaderMessage("Please wait...")

    props.sendLink(AddrId, amount,
      props.chatData.length > 0 ? props.chatData[0].product_id : null,
      props.userData[0]._id, (status) => {

        if (status.status == "success") {
          setShowLoader(false);
          setAmount("");
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2000,
          });

          if (status.data.paymentLink.trim().length > 0) {
            props.sendMessage(status.data.paymentLink);
          }
          setShow(false);
          setAddrId("")
        } else {
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2000,
          });
          setShowLoader(false);
        }
      });
  }
  const addressChange = (e) => {
    setAddrId(e.target.value)
    let payload = [{
      productId: props.prod.data && props.prod.data[0]._id,
      quantity: 1
    }]
    setShowLoader(true);
    setLoaderMessage("Please wait...");
    Shipping_Cost({ productIdArr: payload, addressId: e.target.value }, (callback) => {

      if (callback.status == "error") {
        setShowLoader(false);
        addToast(callback.message, {
          appearance: "error",
          autoDismissTimeout: 2000,
        });
        setDisableButton(true);
      } else {
        setShowLoader(false);
        addToast(callback.message, {
          appearance: "success",
          autoDismissTimeout: 2000,
        });
        setGetResponseMessage(callback.data[0].shippingCost);
        setDisableButton(false);
      }



    })(dispatch)
  }

  function carrierDetail(e) {
    e.preventDefault();
  }


  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setAmount("");
    setAddrId("");
    if (props.userData && props.userData.length > 0) {
      props.getUserAddressBySeleer({ buyerId: props.userData && props.userData[0]._id }, (status) => {

        setAddressData(status);
      })
    }
    setShow(true);

  }

  const close = () => {
    setDisableButton(false);
    setShow(false);
  }
  return !isEmpty(props.prod) && !isEmpty(props.prod.data) ?
    (
      <div className="full-item-detail">
        {showLoader ? <Loader message={loaderMessage} /> : null}
        <div className="item-img">
          <img src={props.prod.data[0].default_image} alt={props.prod.data[0].Title} />
        </div>
        <div className="product-details-name product-details-wrapper-details">
          <div className="product-name">{props.prod.data[0].Title}</div>
          <div className="price">
            {props.prod.data[0].Price.sell_price &&
              (props.prod.data[0].Price.sell_price !== props.prod.data[0].Price.current_store_price)
              ? (
                <p>
                  <span className="buy-info">
                    {priceWithSymbol(props.prod.data[0].Price.sell_price)}
                  </span>
                  <span className="old-price">
                    {priceWithSymbol(props.prod.data[0].Price.current_store_price)}
                  </span>
                </p>
              ) : (
                <p>
                  <span className="buy-info">
                    {priceWithSymbol(props.prod.data[0].Price.current_store_price)}
                  </span>
                </p>
              )
            }
          </div>
        </div>

        {!checkDisabled(props.prod.data[0]) ?
          (
            <div className="pro-btn-wrap">

            </div>
          ) :
          null
          // (
          //   <button
          //     className="complete-purchase disabled"
          //     style={{ maxWidth: "none" }}
          //   // onClick={() => this.setState({ notifyMePopup: true })}
          //   >
          //     Product Out of Stock
          //   </button>
          // )
        }

        <button className="item-btn full-item-btn payment-screen payment-right-screen" style={{ marginTop: "5px" }}>
          <Link to={`/product/${props.prod.data[0].categoryData[0].name}/${props.prod.data[0]._id}/?cat=${props.prod.data[0].categoryData[0].slug}`} className="item-btn-link payment-screen">
            Full item detail
          </Link>
        </button>



        {props.conversation ? <button className="item-btn send-offer payment-screen payment-right-screen" style={{ marginTop: "5px" }} onClick={handleOpen}>
          Send offer
        </button> : null
        }


        {show ?
          <Modal className="pendingorder-popup pending-payemnt-order" show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Payment Link</Modal.Title>
              <button
                className="modal-closeBtn payment-link-close payment-close-wrap"
                onClick={close}
                type="button"
              >
                <i className="fal fa-times-circle"></i>
              </button>
            </Modal.Header>
            <Modal.Body>
              <>
                <Form onSubmit={(e) => carrierDetail(e)}>
                  <div className="offerbtn-popup chat-payment chat-payment-wrapper">
                    <label className="amount">Amount</label>
                    <input type="text" placeholder="Please enter amount" onChange={(e) => getAmount(e)} value={amount} maxLength="6" />
                    <div>
                      <label className="address">Address</label>
                      {
                        addressData.length > 0 ?

                          <>
                            <div className="ship-wrap payment-shipwrap">
                              {addressData && addressData.map((item) => {
                                return (
                                  <>
                                    <div className="shipping-inner shipping-remade payment-link-popup chat-shipping-payment">

                                      <div className="ship-radio">
                                        <input
                                          type="radio"
                                          value={item._id}
                                          name="addradio"
                                          // checked={item._id === isDefaultId}
                                          className="radio"
                                          onChange={addressChange}
                                        />
                                        <div className="radio-circle">
                                          <div className="radio-dot"></div>
                                        </div>
                                      </div>
                                      <div className="desc-txt">
                                        <div className="address-checkout">
                                          <span className="ship-add">{item.flat_no},{" "}</span>
                                          <span className="ship-add">{item.street},{" "}</span>
                                          {item.landmark !== "" && <span className="ship-add">{item.landmark},{" "}</span>}
                                          <span>{item.postcode},{" "}</span>
                                          <span className="ship-add">{item.city},{" "}</span>
                                          <span className="ship-add">{item.state[0].name}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              })}</div>

                            {
                              getResponseMessage !== "" &&
                              <div className="ship-cost">Shipping cost will be apply {RupeesIcon + getResponseMessage}</div>
                            }
                          </> : <span>No Address Found</span>
                      }


                      <div className="radio-circle">
                        <div className="radio-dot"></div>
                      </div>
                    </div>


                    <button onClick={submitPrice} class={disableButton ? "send-payment-link dis_button" : "send-payment-link"} disabled={disableButton}>Send Link</button>&nbsp;&nbsp;&nbsp;
                    {/* <button onClick={close}>Close</button> */}
                  </div>



                </Form>
              </>
            </Modal.Body>
            <Modal.Footer>


            </Modal.Footer>
          </Modal> : null
        }







        {
          makeOffer && (
            <MakeOffer
              handleCloseMakeOffer={() => setMakeOffer(false)}
              bid={props.prod.bidding_data}
              makeAnOffer={props.makeAnOffer}
              id={props.prod.data[0]._id}
              img={props.prod.data[0].default_image}
              name={props.prod.data[0].Title}
              getproductDetail={props.getproductDetail}
            />
          )
        }
      </div >
    )
    : <div className="full-item-detail">
      <div className="product-head">
        <h6>No Product Found</h6>
      </div>
    </div>
};

const mapStateToProps = (state, ownProps) => ({
  chatData: state.chatModule.conversation.chat_id_data,
  userData: state.chatModule.conversation.userData,
  address: state.userProfile.userAddress,
  detail: state
});

const mapDispatchToProps = (dispatch) => {
  return {
    chatOffer: (payload, completion) =>
      dispatch(chatOffer(payload, completion)),
    getUserAddress: (payload, completion) =>
      dispatch(getUserAddress(payload, completion)),
    getUserAddressBySeleer: (payload, completion) =>
      dispatch(getUserAddressBySeleer(payload, completion))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightScreen));




