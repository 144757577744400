import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty, get } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const generateLink = (data, userId) => {
  return data.sender_id !== userId
    ? { sender_id: data.sender_id, product_id: data.product_id }
    : { sender_id: data.receiver_id, product_id: data.product_id };
};

const ContactList = (props) => {
  console.log()
  const msgListObj = useSelector((state) => state.chatModule.messageListObj);

  const [userId] = React.useState(localStorage.getItem("userId"));
  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const location = useLocation();

  const handleleftHideShow = () => {
    props.farScreen();
  };
const userData = useSelector((state)=>state.userProfile.userProfileBanner.userdata
)
// console.log("userDatauserData",userData)
  return (
    <>
      {/* ////////////////// Big Screen ///////// */}
      {matches && (
        <div className="contact-list">
          {!isEmpty(msgListObj) ? (
            msgListObj.data.length > 0 ? (
              msgListObj.data.map((data, index) => {
                return (
                  <Link
                    key={Math.random()}
                    className={"contacts" + funHighLight(location, data, index)}
                    // to={location => `${location.pathname}?${generateLink(data, userId)}`} >
                    to={(location) => ({
                      ...location,
                      state: {
                        chatPayload: generateLink(data, userId),
                        prevPageData:props.location.state && props.location.state.prevPageData ? { ...props.location.state.prevPageData }:"",
                      },
                    })}
                  >
                    <img src={data.product_image} alt={"MetalsBuy"} />
                  
                     <span className="msg-list-one-line">
                      {" "}
                     {userData&&(userData._id===data.sender_id)?data.receiver_name:data.sender_name
}
                    </span>
                    {data.sender_id === userId ? (
                      <strong className="msg-list-one-line">
                        {" "}
                        You: {data.user_message}{" "}
                      </strong>
                    ) : (
                      <strong className="msg-list-one-line">
                        {" "}
                        {data.user_message}{" "}
                      </strong>
                    )}
                 
                   
                    <small>
                      {moment(data.createdAt).calendar({
                        sameDay: "DD/MM/YYYY h:mm a",
                        nextDay: "DD/MM/YYYY h:mm a",
                        nextWeek: "DD/MM/YYYY h:mm a",
                        lastDay: "DD/MM/YYYY h:mm a",
                        lastWeek: "DD/MM/YYYY h:mm a",
                        sameElse: "DD/MM/YYYY h:mm a",
                      })}
                    </small>
                  </Link>
                );
              })
            ) : (
              <div className="product-head">
                <h6>No Chat List</h6>
              </div>
            )
          ) : null}
        </div>
      )}

      {/* /////////// Small Screen ////////////// */}

      {!matches && (
        <div className="contact-list">
          {!isEmpty(msgListObj) ? (
            msgListObj.data.length > 0 ? (
              msgListObj.data.map((data, index) => {
                return (
                  <Link
                    key={Math.random()}
                    className={"contacts" + funHighLight(location, data, index)}
                    // to={location => `${location.pathname}?${generateLink(data, userId)}`} >
                    to={(location) => ({
                      ...location,
                      state: {
                        chatPayload: generateLink(data, userId),
                        prevPageData: props.location.state && props.location.state.prevPageData ?{ ...props.location.state.prevPageData }:"",
                      },
                    })}
                    onClick={() => handleleftHideShow()}
                  >
                    <img src={data.product_image} alt={"MetalsBuy"} />
                    {data.sender_id === userId ? (
                      <strong className="msg-list-one-line">
                        {" "}
                        You: {data.user_message}{" "}
                      </strong>
                    ) : (
                      <strong className="msg-list-one-line">
                        {" "}
                        {data.user_message}{" "}
                      </strong>
                    )}

                    <span className="msg-list-one-line">
                      {" "}
                      {data.product_name}
                    </span>
                    <small>
                      {moment(data.createdAt).calendar({
                        sameDay: "DD/MM/YYYY h:mm a",
                        nextDay: "DD/MM/YYYY h:mm a",
                        nextWeek: "DD/MM/YYYY h:mm a",
                        lastDay: "DD/MM/YYYY h:mm a",
                        lastWeek: "DD/MM/YYYY h:mm a",
                        sameElse: "DD/MM/YYYY h:mm a",
                      })}
                    </small>
                  </Link>
                );
              })
            ) : (
              <div className="product-head">
                <h6>No Chat List</h6>
              </div>
            )
          ) : null}
        </div>
      )}
    </>
  );
};
export default ContactList;

const funHighLight = (location, data, index) => {
  if (get(location, ["state", "tab"], false) && index === 0) {
    return " high-light";
  } else if (get(location, ["state", "chatPayload"], false)) {
    if (
      (location.state.chatPayload.sender_id === data.sender_id ||
        location.state.chatPayload.sender_id === data.receiver_id) &&
      location.state.chatPayload.product_id === data.product_id
    ) {
      return " high-light";
    } else return "";
  } else return "";
};