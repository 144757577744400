import React from "react";
import CommentReply from "./CommentReply";

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

const CommentBox = (props) => {
  const [state, setState] = React.useState({
    showReply: false,
    reply: "",
  });

  const replyHandler = () => {
    props.postReply(props.item._id, state.reply);
    setState({ ...state, reply: "", showReply: false });
  };


  return (
    <div className="comment-section post-sec">
      <div className="user-img">
        <img
          src={`${props.path + props.item.comment_author_image}`}
          alt="editors"
        />
      </div>
      <div className="post-content">
        <strong>{props.item.comment_author_name}</strong>
        <span>{formatAMPM(new Date(props.item.comment_date_gmt))}</span>
        <p>{props.item.comment_content}</p>
        <ul className="post-bottom">
          <li>
            <a
              href="javascript:void(0)"
              onClick={(e) =>
                setState({ ...state, showReply: !state.showReply })
              }
            >
              Reply
            </a>
          </li>
        </ul>
        <div className="reply-field">
          {props.blogComment.map((item, i) => {
            if (props.item._id === item.comment_parent) {
              return (
                <CommentReply
                  key={i}
                  item={item}
                  path={props.path}
                  postReply={props.postReply}
                  id={props.item._id}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        {state.showReply ? (
          <React.Fragment>
            <div className="area-field">
              <textarea
                id="reply-area1"
                name="reply-area1"
                onChange={(e) => setState({ ...state, reply: e.target.value })}
                value={state.reply}
              />
              <button className="btn comment-btn" onClick={() => replyHandler()}>
                Reply
              </button>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default CommentBox;
