import React, { useEffect } from "react";
import "../../ProductDetail/ProductDetails.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { checkorderSuccess } from "../../../store/actions/checkout";


const OrderSuccess = (props) => {

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    let query = props.location.search + "&customer_id=" + userId
    props.checkorderSuccess(query, data => {

    })
  }, [])


  const handlePopup = () => {
    props.history.push("/")
  }


  return (
    <div className="make-offer-popup gggggg">
      <div className="offer-content">
        <button
          className="modal-closeBtn"
          onClick={() => handlePopup()}
          type="button"
        >
          <i className="fal fa-times-circle"></i>
        </button>
        <div className="offer-title">
          Your Order has been successfully place. Please check your email for details or click
          <Link
            className="explore-membership"
            to={{ pathname: "/profile/myorder" }}
          >
            Go to my Order
          </Link> for checking it.</div>
      </div>
    </div>
  );
};


const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkorderSuccess: (payload, data) => dispatch(checkorderSuccess(payload, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccess);
