import React from "react";

export const StaticBlock = (props) => {

  if (props.data) {
    return props.data.map((i) => {
      return i.position === props.position ? (
        <div className="demooo" dangerouslySetInnerHTML={{ __html: i.data && i.data[0] }} />
      ) : null;
    });
  } else return null;
};
