import React, { useState } from 'react';
import { connect } from "react-redux";
import { changeDefaultAddress, getUserAddress, deleteUserAddress } from '../../../store/actions/profile';
import Toast from "light-toast";
import AddAddress from '../../CheckOut/Shipping/AddAddress';
import { Shipping_Cost } from '../../../store/actions/cartAction';
import Loader from '../../Loader';
import swal from 'sweetalert';
import { useToasts } from "react-toast-notifications";

function ManageAddress(props) {
    const [showPopup, setShowpopup] = React.useState(false);
    const [addId, setAddId] = React.useState(null);
    const [formLabel, setFormLabel] = React.useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const { addToast } = useToasts();

    const setDefault = (value) => {
        Toast.loading("Wait...");

        const payload = {
            addressId: value._id
        }
        props.changeDefaultAddress(payload, (status) => {
            if (status) {

                props.getUserAddress({}, (status) => {

                });
                Toast.hide();
            }
        })
    }

    const getUAddress = () => {
        props.getUserAddress({}, (status) => {

        });
    }

    React.useEffect(() => {

    })


    const deleteDefault = (value) => {



        swal({
            title: "Are you sure?",
            text: "you want to remove this address!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setShowLoader(true);
                    setShowMessage("Please wait...");

                    props.deleteUserAddress(value._id, (status) => {
                        if (status) {
                            props.getUserAddress({}, (status) => { });
                            setShowLoader(false);
                            addToast("Address deleted", {
                                appearance: "success",
                                autoDismissTimeout: 2500,
                            });
                        } else {
                            setShowLoader(false);
                            addToast("Please try again", {
                                appearance: "error",
                                autoDismissTimeout: 2500,
                            });
                        }
                    });
                } else {

                }
            });






    }

    const editAddress = (value) => {
        setShowpopup(true);
        setAddId(value._id)
        setFormLabel("Edit Address")
    }

    const cancelHandler = () => {
        setShowpopup(false);
    }

    const popup = () => {
        setFormLabel("Add Address")
        setShowpopup(true);
        setAddId(null);

    }
    const hidepopup = () => {
        setFormLabel("")
        setShowpopup(false);
    }

    return (
        <>
            {showLoader ? <Loader message={showMessage} /> : null}
            <div className='address-wrap address-box-wrapper'>
                <h2>Select delivery address</h2>
                <div className='address-boxes'>

                    <div className='address-box new-address' onClick={popup}>
                        <div className='address-image edit-address-img'>
                            <img src="/assets/images/add-new-address-icon.svg" alt="address" />
                            {/* <i className='fas fa-plus-circle'></i> */}
                        </div>
                        <span className='add-new-address-wrap'>Add new address</span>
                    </div>
                    {props.addressList.map((item) => {
                        return (
                            <>

                                <div className={item.is_default ? 'address-box get_default' : 'address-box'}>
                                    <span className='triangle-topright'></span>
                                    <div className='default-address'>
                                        {/* <h3>Address</h3> */}
                                        <span className='name'>Address</span>
                                        <span className='tick-mark'>
                                            <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <address>
                                            {item.flat_no}, {item.street}, {item.address_prefix} {item.landmark}
                                            <span>{item.city} :{item.postcode}</span>
                                        </address>
                                        <div className='number'>Mobile - <a href="tel:1234567890">{item.phone}</a></div>
                                    </div>
                                    <div className='address-buttons'>
                                        <a onClick={() => editAddress(item)}><i className="fa fa-pencil" aria-hidden="true"></i> Edit</a>
                                        <a onClick={() => deleteDefault(item)} > <i className="fa fa-trash" aria-hidden="true"></i> Remove</a>
                                        {item.is_default ? null : <a onClick={() => setDefault(item)} className="make-default"> Make Default</a>}

                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
            {showPopup ?
                <div className='newaddress-popup popup-wrap'>
                    <div className='pop-ups address-popup-wrap'>
                        <i className="fa fa-times" aria-hidden="true" onClick={hidepopup}></i>
                        <h1>{formLabel}</h1>
                        <AddAddress manageAddress="true" addId={addId} setShowAddr={cancelHandler} uAddress={getUAddress} />
                    </div>
                </div> : null
            }

        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        addressList: state.userProfile.userAddress,
        cartData: state.cart.getcart
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDefaultAddress: (payload, completion) =>
            dispatch(changeDefaultAddress(payload, completion)),
        getUserAddress: (payload, completion) => dispatch(getUserAddress(payload, completion)),
        deleteUserAddress: (addrId, status) =>
            dispatch(deleteUserAddress(addrId, status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAddress);
