import React, { useState, useEffect } from 'react';
import AddressList from './AddressList';
import AddAddress from './AddAddress';
import { Shipping_Cost } from "../../../store/actions/cartAction";
import { getuserAddress } from "../../../store/actions/checkout"
import { useDispatch } from 'react-redux';
import Toast from "light-toast";
import { useLocation, Link } from 'react-router-dom';
import Loader from '../../Loader';
import { Line } from '@react-pdf/renderer';
import { axiosInstance } from '../../../utils/Service';
import { getSeller } from '../../../store/actions/sellerRegisterationAction';



const ShippingAddress = ({ addressList, cartData, updateShippingCost, checkLocalOrNot, getAddressHandle }) => {

    let dispatch = useDispatch();
    const [showAddr, setShowAddr] = useState(false);
    const [addId, setAddId] = useState(null);
    const [showLoader, setShowLoader] = useState(false);

    const [editAddress, setEditAddress] = useState(null)

    const [sellerData, setSellerData] = useState(null);

    useEffect(() => {
        dispatch(
            getSeller((callback) => {
                if (callback) {
                    setSellerData(callback[0].companyName);
                }
            })
        );
    }, []);

    let location = useLocation();

    const [isDefaultId, setIsDefaultId] = useState(null);

    const [loaderHandle, setLoaderHandle] = useState(false)

    const [addressLine, setAddressLine] = useState("");
    const [city, setCity] = useState("");
    const [stateForm, setStateForm] = useState("");
    const [pin, setPin] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState({ value: "" });
    const [landmark, setLandmark] = useState("");
    const [street, setStreet] = useState("");

    const [addressLineError, setAddressLineError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [stateFormError, setStateFormError] = useState(false);
    const [pinError, setPinError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [landmarkError, setLandmarkError] = useState(false);
    const [streetError, setStreetError] = useState(false);

    const [state, setState] = useState(null)
    useEffect(() => {
        axiosInstance.get("category/country").then((res) => {
            setState(res.data.data[0].stateData)
        });
    }, [])

    const handleEmail = (e) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (filter.test(e.target.value)) {
        }
        setEmail(e.target.value)
        setEmailError(false)
    }

    const handlePin = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            setPin(e.target.value)
            setPinError(false)
        }
    }

    const handlePhone = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPhone(phone => value.length <= 10 && !isNaN(Number(value)) && { value } || phone)
        setPhoneError(false)
    }

    const handleSaveAddress = () => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (email.length === 0) {
            setEmailError(true)
            return
        }
        else if (!filter.test(email)) {
            setEmailError(true)
            return
        }
        else if (name.length === 0) {
            setNameError(true)
            return
        }
        else if (phone.value.length === 0) {
            setPhoneError(true)
            return
        }
        else if (addressLine.length === 0) {
            setAddressLineError(true)
            return
        }
        else if (city.length === 0) {
            setCityError(true)
            return
        }
        else if (landmark.length === 0) {
            setLandmarkError(true)
            return
        }
        else if (street.length === 0) {
            setStreetError(true)
            return
        }
        else if (stateForm.length === 0) {
            setStateFormError(true)
            return
        }
        else if (pin.length === 0) {
            setPinError(true)
            return
        }
        setLoaderHandle(true)
        axiosInstance.post("/auth/update_address", {
            email: email,
            full_name: name,
            phone: phone.value,
            billing_flat_no: addressLine,
            billing_postcode: pin,
            billing_state: stateForm,
            billing_city: city,
            landmark: landmark,
            street: street,
            addressId: editAddress._id
        }).then((res) => {
            getAddressHandle()
            setAddressLine("");
            setCity("");
            setStateForm("");
            setPin("");
            setEmail("");
            setName("");
            setPhone({ value: "" });
            setLandmark("");
            setStreet("");
            setEditAddress(null)
            setLoaderHandle(false)
        }).catch(() => {
            setLoaderHandle(false)
        });
    }

    const clickEditHandler = (id) => {
        setShowAddr(true);
        setAddId(id);
        setTimeout(() => {
            let ele = document.getElementById('addressForm');
            ele.scrollIntoView({ behavior: 'smooth' })
        }, 500)

    }

    const cancelHandler = () => {
        setShowAddr(false);
    }


    useEffect(() => {
        getuserAddress(callback => {
            let isDefaultId = callback.filter(item => item.is_default)
            if (!checkLocalOrNot()) {
                getShippingCost(isDefaultId.length === 0 ? callback[0]._id : isDefaultId[0]._id, 'load');
            } else {
                setAddId(isDefaultId.length === 0 ? callback[0]._id : isDefaultId[0]._id);
                setIsDefaultId(isDefaultId.length === 0 ? callback[0]._id : isDefaultId[0]._id);
                updateShippingCost(0, true, isDefaultId.length === 0 ? callback[0]._id : isDefaultId[0]._id);

            }
        })(dispatch)
    }, [
        cartData,
        addressList
    ])

    const getUAddress = () => {
        getuserAddress(callback => {

        })(dispatch)
    }


    const getShippingCost = (id) => {
        let payload = [];
        if (cartData.length > 0) {
            setShowLoader(true);
            cartData.forEach(item => {
                payload.push({ productId: item.productId, quantity: item.Quantity })
            })
            Shipping_Cost({ productIdArr: payload, addressId: id }, callback => {
                if (callback.status === "success") {
                    updateShippingCost(callback.data[0].shippingCost, true, id, callback);
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 500);

                } else {
                    updateShippingCost(0, false, id);
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 500);
                }
            })(dispatch);
        }
        setAddId(id)
        setIsDefaultId(id)
    }

    useEffect(() => {
        return () => {
            Toast.hide()
        }
    }, [])

    const addressHandler = (e) => {
        let val = e.target.value;
        if (checkLocalOrNot()) {
            updateShippingCost(0, true, val);
            setIsDefaultId(val)
        } else {
            getShippingCost(val, 'click');
            setIsDefaultId(val)
        }
    }

    const handleEdit = (item) => {
        console.log("item", item)
        setEditAddress(item)
        setEmail(item.email)
        setAddressLine(item.flat_no);
        setCity(item.city);
        setStateForm(item.state[0]._id);
        setPin(item.postcode);
        setName(item.full_name);
        setPhone({ value: item.phone });
        setLandmark(item.landmark);
        setStreet(item.street);

        setAddressLineError(false);
        setCityError(false);
        setStateFormError(false);
        setPinError(false);
        setEmailError(false);
        setNameError(false);
        setPhoneError(false);
        setLandmarkError(false);
        setStreetError(false);
    }

    const handleName = (e) => {
        let value = e.target.value
        value = value.replace(/[^A-Za-z]/ig, '')
        setName(value);
        setNameError(false)
    }

    const handleCity =(e) =>{
        let value = e.target.value
        value = value.replace(/[^A-Za-z]/ig, '')
        setCity(value); 
        setCityError(false)
    }

    return (
        <div className="shipping-itemwrap shipping-remade checkout-payment-shipping-details">
            {loaderHandle && <Loader message={"Please wait..."} />}
            {editAddress && <div className='cart-page-address edit-popup-model'>
                <div className='model-behind-panel' onClick={() => setEditAddress(false)}></div>
                <div className='login-description w-100 login-description-form-wrap address-field-wrap upload-document-wrapper place-order-address edit-popup-model-content'>
                    <div className='edit-address-header'>
                        <h3>Edit Address</h3>
                        <button onClick={() => setEditAddress(false)}><i className='fas fa-times'></i></button>
                    </div>
                    <form>
                        <div className="address-field-new-wrap">
                            <div className="form-block-wrap">
                                <label className="enter-mobile-label">
                                    Email*
                                </label>
                                <div className="form-input">
                                    <input
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={handleEmail}
                                    />
                                </div>
                                {
                                    emailError && <span className='error'>Please enter a valid email</span>
                                }
                            </div>
                            <div className="form-block-wrap">
                                <label className="enter-mobile-label">
                                    Name*
                                </label>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        placeholder="Enter your name"
                                        value={name}
                                        onChange={handleName}
                                    />
                                </div>
                                {
                                    nameError && <span className='error'>Please enter your name</span>
                                }
                            </div>
                            <div className="form-block-wrap">
                                <label className="enter-mobile-label">
                                    Phone number*
                                </label>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        placeholder="Enter your phone number"
                                        value={phone.value}
                                        onChange={handlePhone}
                                    />
                                </div>
                                {
                                    phoneError && <span className='error'>Please enter your phone number</span>
                                }
                            </div>

                            <div className="form-block-wrap">
                                <label className="enter-mobile-label">
                                    Address line*
                                </label>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        placeholder="Enter Address line"
                                        id="addressOne"
                                        maxLength="50"
                                        value={addressLine}
                                        onChange={(e) => { setAddressLine(e.target.value); setAddressLineError(false) }}
                                    />
                                </div>
                                {
                                    addressLineError && <span className='error'>Please enter address line</span>
                                }
                            </div>

                            <div className="form-block-wrap">
                                <div className="form-intput">
                                    <label className="enter-mobile-label">City*</label>
                                    <input
                                        type="text"
                                        placeholder="Enter city name here"
                                        id="city"
                                        maxLength="100"
                                        value={city}
                                        onChange={handleCity}
                                    />
                                </div>
                                {
                                    cityError && <span className='error'>Please enter city</span>
                                }
                            </div>

                            <div className="form-block-wrap">
                                <div className="form-intput">
                                    <label className="enter-mobile-label">Landmark*</label>
                                    <input
                                        type="text"
                                        placeholder="Enter landmark here"
                                        maxLength="100"
                                        value={landmark}
                                        onChange={(e) => { setLandmark(e.target.value); setLandmarkError(false) }}
                                    />
                                </div>
                                {
                                    landmarkError && <span className='error'>Please enter landmark</span>
                                }
                            </div>
                            <div className="form-block-wrap">
                                <div className="form-intput">
                                    <label className="enter-mobile-label">Street*</label>
                                    <input
                                        type="text"
                                        placeholder="Enter street here"
                                        maxLength="100"
                                        value={street}
                                        onChange={(e) => { setStreet(e.target.value); setStreetError(false) }}
                                    />
                                </div>
                                {
                                    streetError && <span className='error'>Please enter street</span>
                                }
                            </div>

                            <div className="names-wrapper">
                                <div className="input-form-wrap select-affilation-profile address-name-wrap">
                                    <div className="form-intput select-affilation-wrap">
                                        <label className="enter-mobile-label">
                                            State*
                                        </label>

                                        <div className="select-main-wrapper">
                                            <select
                                                id="state"
                                                onChange={(e) => { setStateForm(e.target.value); setStateFormError(false) }}
                                                value={stateForm}
                                            >
                                                <option value={""}>Select</option>
                                                {state && state.length
                                                    ? state.map(
                                                        (item, index) => (
                                                            <option value={item._id}>
                                                                {item.name}
                                                            </option>
                                                        )
                                                    )
                                                    : null}
                                            </select>
                                            <i className="icon-arrow-down"></i>
                                        </div>
                                        {
                                            stateFormError && <span className='error'>Please select state</span>
                                        }
                                    </div>
                                </div>

                                <div className="form-block-wrap">
                                    <div className="form-intput">
                                        <label className="enter-mobile-label">
                                            Pin code*
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter pincode here"
                                            id="zip"
                                            maxLength="6"
                                            value={pin}
                                            onChange={handlePin}
                                        />
                                    </div>
                                    {
                                        pinError && <span className='error'>Please enter pin code</span>
                                    }
                                </div>
                            </div>
                            <div className='navigation-back' style={{ flexDirection: "column" }}>
                                <button type='button' className='next-btn' onClick={handleSaveAddress}>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
            {/* {showLoader ? <Loader message="Fetching shipping detail..." /> : null} */}
            {addressList.length > 0 ?
                <>
                    {/* {addressList[0].state.length > 0 ?
                        <>
                            {
                                addressList && addressList.map((item, i) =>
                                    <AddressList
                                        isDefaultId={isDefaultId}
                                        key={Math.random()}
                                        item={item}
                                        clickEditHandler={clickEditHandler}
                                        addressHandler={addressHandler}
                                    />
                                )
                            }
                        </> :
                        <>
                            <Link to="/profile/complete_profile">
                                <span>Add your manufacturing Address</span>
                            </Link>

                        </>
                    } */}
                    <>
                        {
                            addressList && addressList.map((item, i) =>
                                <AddressList
                                    isDefaultId={isDefaultId}
                                    key={Math.random()}
                                    item={item}
                                    clickEditHandler={clickEditHandler}
                                    addressHandler={addressHandler}
                                    getAddressHandle={getAddressHandle}
                                    handleEdit={handleEdit}
                                    sellerData={sellerData}
                                    setLoaderHandle={setLoaderHandle}
                                />
                            )
                        }
                    </>
                </> : null
            }


            {/* {showAddr ? <AddAddress
                manageAddress="false"
                getShippingCost={getShippingCost}
                uAddress={getUAddress}
                addId={addId}
                setShowAddr={cancelHandler} /> : null}
            {
                !showAddr && <button onClick={() => { setShowAddr(!showAddr); setAddId(null) }} className="shop-now address-btn">+ Add new addresss</button>
            } */}
            {/* <div className="payment-btn-wrap">
                <Link className="payment-btn">
                    <button className="proceed-payment">Make Order</button>
                </Link>
            </div> */}
        </div>
    )
}

export default ShippingAddress;