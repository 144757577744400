import React from "react";
import "./style.css";
import ShippingAddress from "./Shipping/ShippingAddress";
import OrderDetails from "./OrderDetails";
import Payment from "./Payment";
import Coupon from "./Coupon/Coupon";
import { Link } from "react-router-dom";
import OrderSuccessPopup from "./OrderSuccessPopup";
import { getCart } from "../../store/actions/cartAction";
import { getUserAddress } from "../../store/actions/profile";
import { getproductDetail } from "../../store/actions/productDetails";
import { Shipping_Cost } from "../../store/actions/cartAction";
import {
  updateAddress,
  applyProductCoupon,
  placeOrder,
} from "../../store/actions/checkout";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Toast from "light-toast";
import { parse } from "query-string";
import { decode } from "js-base64";
import LocalShipping from "./Shipping/LocalShipping";
import swal from "sweetalert";
import Loader from "../Loader";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import AddressAdd from "./Shipping/AddressAdd";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

class CheckOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingCost: 0,
      appliedCouponAmt: 0,
      couponCode: "",
      paymentMethod: "Razorpay",
      isShippingAvail: true,
      spinnerLoader: false,
      addressId: "",
      prodDetail: [],
      orderSuccess: false,
      couponData: {},
      shippingDetail: {},
      localOrderType: "freePickup",
      showLoader: false,
      showMessage: "",
      productQty: 1,
      getState: [],
      quantityError: "",
      prices: 0,
    };
  }


  updateLocalOrderTypeHandler = (type) => {
    if (type === "freeShipping") {
      this.setState({ localOrderType: type });
      let payload = [];
      this.setState({
        showLoader: true,
        showMessage: "Fetching shipping detail...",
      });
      this.props.cartData.forEach((item) => {
        payload.push({ productId: item.productId, quantity: item.Quantity });
      });
      this.props.Shipping_Cost(
        { productIdArr: payload, addressId: this.state.addressId },
        (callback) => {
          if (callback.status === "success") {
            this.updateShippingCost(
              callback.data[0].shippingCost,
              true,
              this.state.addressId,
              callback
            );
            this.setState({ showLoader: false });
          } else {
            this.updateShippingCost(0, false, this.state.addressId);
            this.setState({ showLoader: false });
          }
        }
      );
    } else {
      this.setState({ localOrderType: type, shippingDetail: undefined });
    }
  };

  updateShippingCost = (price, type, id, shippingDetail) => {
    this.setState({
      shippingCost: price,
      isShippingAvail: type,
      addressId: id,
      shippingDetail: shippingDetail,
    });
  };

  appliedCouponHandler = (data, name, couponData) => {
    this.setState({
      appliedCouponAmt: data,
      couponCode: name,
      couponData: couponData,
    });
  };

  componentDidMount() {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    let query = { ...parse(this.props.location.search) };
    this.props.getUserAddress({}, (status) => {
      if (status.status == "success") {
        this.setState({ showLoader: false });
        let isDefaultId = status.data.filter((item) => item.is_default);

        this.setState({
          addressId:
            isDefaultId.length === 0 ? status.data[0]._id : isDefaultId[0]._id,
          getState: status.data[0].state,
        });
      } else {
        this.setState({ showLoader: false });
      }
    });
    if (query.pid) {
      this.props.getproductDetail(
        { catslug: query.category, id: query.pid },
        (status) => { }
      );
    }
  }

  componentDidUpdate(prevProps) {
    let query = { ...parse(this.props.location.search) };
    if (query.pid) {
      if (this.props.details !== prevProps.details) {
        let Price = this.props.details.data[0].productPrice && this.props.details.data[0].productPrice.$numberDecimal || '';
        Object.keys(Price).map((attr) =>
          typeof Price[attr] === "object" && Price[attr] !== null
            ? (Price[attr] = Price[attr].$numberDecimal)
            : null
        );


        let payload = {
          Quantity: 1,
          brandData: this.props.details.data[0].brandData,
          Title: this.props.details.data[0].Title,
          Price: Price,
          default_image: this.props.details.data[0].default_image,
          productId: this.props.details.data[0]._id,
          sellerId: this.props.details.data[0].userData,
          prodQuantity: this.props.details.data[0].Quantity,
          status: this.props.details.data[0].status,
          productApprovalStatus:
            this.props.details.data[0].productApprovalStatus,
          deletedAt: this.props.details.data[0].deletedAt,
          categoryId: this.props.details.data[0].categoryData[0]._id,
          zipCode: this.props.details.data[0].zip_code,
        };
        this.setState({ prodDetail: [payload] });
      }
    } else {
      if (this.props.cartData && this.props.cartData.length === 0) {
        this.props.history.push("/my-cart");
      }
    }
    if (this.props.adminStatus && this.props.adminStatus.sellerCreatedByAdmin) {
      localStorage.setItem(
        "incomplete",
        this.props.adminStatus.sellerProfileCreatedByAdminCompleted
      );
    }
  }

  updateQtyForBuyNowAndMakeOffer = (qty) => {
    let newState = [...this.state.prodDetail];
    //newState[0].Quantity = qty;
    this.setState({ prodDetail: newState, productQty: qty });
    if (qty > 100) {
      this.state.quantityError = true;
    }
  };

  setPaymentMethod = (val) => {
    this.setState({ paymentMethod: val });
  };

  closePopup = () => {
    this.setState({ orderSuccess: false }, () => this.props.history.push("/"));
  };

  displayRazorpay = async (data, razorSubmit) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      swal("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: "rzp_test_KakF4Dy4HjXa1U", // 'PRODUCTION_KEY',
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: "Bazaar",
      description: "Make secure payment",
      image: "/assets/images/logo.png",
      handler: (razorpayRes) => razorSubmit(razorpayRes),
      // {

      // this.setState({ orderSuccess: true });
      // Toast.success("Order Placed successfully", 1000, () => this.props.history.push('/'))
      // },
      prefill: {
        name: "name",
        email: "example@mail.com",
        phone_number: "9899999999",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // handlerclick = (cost) => {
  //   let min = 2;
  //   let max = 7;
  //   if (localStorage.getItem("incomplete") == "false") {
  //     Toast.info("Please complete your business profile if you have already completed then wait for admin approval.", 5000,);
  //   } else {

  //     if (this.checkLocalOrNot()) {
  //       min = 2;
  //       max = 7
  //     } else {
  //       if (Object.keys(this.state.shippingDetail).length === 0) {

  //       } else {
  //         max = this.state.shippingDetail.maximumDays;
  //         max = this.state.shippingDetail.maximumDays.split(" ");

  //         min = this.state.shippingDetail.minimumDays;
  //         min = this.state.shippingDetail.minimumDays.split(" ");
  //       }
  //     }

  //     let query = { ...parse(this.props.location.search) };
  //     const { couponCode, addressId, paymentMethod, shippingCost, prodDetail } = this.state;

  //     if (addressId === "") {
  //       this.setState({ showLoader: true, showMessage: "Please wait..." })
  //       setTimeout(() => this.setState({ showLoader: false }), 500)
  //       this.props.addToast("Please add address", {
  //         appearance: "error",
  //         autoDismissTimeout: 3000,
  //       });
  //       return
  //     }

  //     if (this.checkLocalOrNot()) {
  //       if (this.state.localOrderType === "") {
  //         this.setState({ showLoader: true, showMessage: "Please wait..." })
  //         setTimeout(() => this.setState({ showLoader: false }), 2000)
  //         this.props.addToast("Please select shipping type.", {
  //           appearance: "error",
  //           autoDismissTimeout: 3000,
  //         });
  //         return
  //       }
  //     }

  //     let cartData = [];
  //     if (query.pid) {
  //       prodDetail.map(item => {
  //         if (item.status && item.productApprovalStatus && item.deletedAt === 0) {
  //           cartData.push({ product_id: item.productId, user_id: localStorage.getItem("userId"), coupon: couponCode, Quantity: item.Quantity })
  //         }
  //       })
  //     } else {
  //       this.props.cartData.map(item => {
  //         if (item.status && item.productApprovalStatus && item.deletedAt === 0) {
  //           cartData.push({ product_id: item.productId, user_id: localStorage.getItem("userId"), coupon: couponCode, Quantity: item.Quantity })
  //         }
  //       })
  //     }

  //     let selectedAdd = this.props.address.filter(item => item._id === addressId)

  //     if (this.checkLocalOrNot()) {
  //       if (query.pid) {

  //         if (this.state.prodDetail[0].zipCode !== (selectedAdd.length === 0 ? "" : selectedAdd[0].postcode)) {
  //           swal("Local shipping is not available for this address");
  //           return false
  //         }
  //       } else {
  //         if (this.props.cartData[0].zipCode !== (selectedAdd.length === 0 ? "" : selectedAdd[0].postcode)) {
  //           swal("Local shipping is not available for this address");
  //           return false
  //         }
  //       }

  //     }

  //     let payload = {
  //       customer_name: selectedAdd[0].first_name + " " + selectedAdd[0].last_name,
  //       customer_email: selectedAdd[0].email,
  //       shippingPrice: shippingCost,
  //       cart: query.pid ? false : true,
  //       cartData,
  //       customer_mobile: selectedAdd[0].phone,
  //       customer_address: `flat no.${selectedAdd[0].flat_no}, street ${selectedAdd[0].street}, ${selectedAdd[0].landmark} ${selectedAdd[0].city}-${selectedAdd[0].postcode}`,
  //       payment_method: paymentMethod,
  //       delivery_date_from: min[0],
  //       delivery_date_to: max[0],
  //       bid_price: query.perk ? decode(query.perk) : null,
  //       ordertype: query.perk ? "Offered" : "Normal",
  //       isCoins: false,
  //       customer_id: localStorage.getItem("userId"),
  //       addressId: addressId,
  //       isLocalOrder: this.checkLocalOrNot(),
  //       localOrderType: this.state.localOrderType
  //     }
  //     this.setState({ showLoader: true, showMessage: "Please wait..." })
  //     this.props.placeOrder(payload, callback => {
  //       this.setState({ showLoader: false })
  //       if (callback.status) {
  //         this.displayRazorpay(callback, (success) => {
  //           this.setState({ orderSuccess: true });
  //         });
  //       } else {
  //         this.props.addToast(callback.response.data.error.error.description, {
  //           appearance: "error",
  //           autoDismissTimeout: 3000,
  //         });

  //       }
  //     })
  //   }
  // };

  childtoParent = (valprice) => {
    this.setState({ prices: valprice });
  };

  handlerclick = () => {
    if (this.state.getState.length == 0) {
      this.props.addToast(
        `Please click on "Add your manufacturing Address" to add Address`,
        {
          appearance: "error",
          autoDismissTimeout: 3000,
        }
      );
      return false;
    }
    let payload = {
      cartData: [
        {
          Quantity: this.state.productQty,
          product_id:
            this.props.location.state.page == "Listing"
              ? this.props.location.state.data._id
              : this.state.prodDetail[0].productId,
        },
      ],
      delivery_days_from:
        this.props.location.state.page == "Listing"
          ? moment(
            this.props.location.state.data.deliveryDateFrom == null
              ? new Date()
              : this.props.location.state.data.deliveryDateFrom
          ).format("YYYY-MM-DD")
          : moment(
            this.props.location.state.data[0].deliveryDateFrom == null
              ? new Date()
              : this.props.location.state.data[0].deliveryDateFrom
          ).format("YYYY-MM-DD"),
      delivery_days_to:
        this.props.location.state.page == "Listing"
          ? moment(
            this.props.location.state.data.deliveryDateTo == null
              ? new Date()
              : this.props.location.state.data.deliveryDateTo
          ).format("YYYY-MM-DD")
          : moment(
            this.props.location.state.data[0].deliveryDateTo == null
              ? new Date()
              : this.props.location.state.data[0].deliveryDateTo
          ).format("YYYY-MM-DD"),
      addressId: this.state.addressId,
      bid_price: null,
      cart: false,
      payment_method: "COD",
      ordertype: "Normal",
      prices: this.state.prices,
      productIdByName:
        this.props.details && this.props.details.data[0].productIdByName,
    };
    // return;
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    this.props.placeOrder(payload, (callback) => {
      this.setState({ showLoader: false });
      if (callback.status === "Success") {
        this.props.addToast(callback.message, {
          appearance: "success",
          autoDismissTimeout: 3000,
        });
        setTimeout(() => {
          this.props.history.push({
            pathname: '/order-process-success',
            state: { data: this.props.details.data,productqty:this.state.productQty }
          });
        }, 500);
      } else {
        this.props.addToast(callback.message, {
          appearance: "error",
          autoDismissTimeout: 3000,
        });
      }
    });
  };

  checkLocalOrNot = () => {
    let isLocal = false;
    if (parse(this.props.location.search).type === "Local") {
      isLocal = true;
    } else {
      if (!parse(this.props.location.search).pid) {
        let checkLocal =
          this.props.cartData.length > 0 &&
          this.props.cartData.filter((item) => item.isLocalProduct);
        if (checkLocal.length > 0) {
          isLocal = true;
        } else {
          isLocal = false;
        }
      }
    }

    return isLocal;
  };
  getAddressHandle = ()=>{
    this.setState({showLoader:true, showMessage:"Please wait..."})
    this.props.getUserAddress({}, (status) => {
      if (status.status == "success") {
        this.setState({ showLoader: false });
        let isDefaultId = status.data.filter((item) => item.is_default);

        this.setState({
          addressId:
            isDefaultId.length === 0 ? status.data[0]._id : isDefaultId[0]._id,
          getState: status.data[0].state,
        });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }



  render() {
    let query = { ...parse(this.props.location.search) };
    return (
      <div className="whitebg">
        {this.state.showLoader ? (
          <Loader message={this.state.showMessage} />
        ) : null}
        <div className="container">
          <div className="main-page">
            <div className="nav-content">
              <Link to="/" className="home-link">
                Home
              </Link>
              <span className="page-name">/ Checkout</span>
            </div>
            <div className="checkout-caption">
              <h2>Checkout</h2>
            </div>
            <div className="checkout-wrap new-checkout-wrapper">
              <div className="checkout-right">
                {this.props.cartData.length > 0 ||
                  this.state.prodDetail.length > 0 ? (
                  <OrderDetails
                    query={query}
                    cartData={
                      query.pid ? this.state.prodDetail : this.props.cartData
                    }
                    type="checkout"
                    updateQtyForBuyNowAndMakeOffer={
                      this.updateQtyForBuyNowAndMakeOffer
                    }
                    shippingCost={this.state.shippingCost}
                    appliedCouponAmt={this.state.appliedCouponAmt}
                    isShippingAvail={this.state.isShippingAvail}
                    appliedCouponHandler={this.appliedCouponHandler}
                    shippingDetail={this.state.shippingDetail}
                    quantityError={this.state.productQty}
                    val={this.childtoParent}
                  />
                ) : null}
                {/* <div className="seller-bank-detail">
                  <h5>Account Details</h5>
                  <p>Account Number - 0909 9090 9896 7654</p><p>IFSC Code - HDFC026302</p>
                  <p>Account Holder Name - Vartika Joshi</p>
                </div> */}
                {/* <div className="upload-verification-doc">
                  <h5>Upload Receipt</h5>
                  <div className='input-upload-form'>
                    <input type="file" className="upload-file" />
                    <img src="/assets/images/doc-upload-file.png" alt="doc" />
                  </div>
                  <div className="result-uploaded-doc">
                    <img src="/assets/images/pdf-img.png" alt="doc" />
                  </div>
                </div> */}
                {/* to="/order-process-success"  */}
                {/* {this.state.productQty <= 100 ? ( */}
                <div className="payment-btn-wrap">
                  <Link className="payment-btn">
                    <button
                      className="proceed-payment"
                      onClick={() => this.handlerclick()}
                      disabled={(this.state.productQty > 100 || (this.props.details && this.props.details.data && this.state.productQty < this.props.details.data[0].minOrderQty) ||
                        (this.props.details && this.props.details.data && this.state.productQty > this.props.details.data[0].productWeight)) ? true : false}
                    >
                      Place Order
                    </button>
                  </Link>
                  {/* <Link to="/" className="payment-detail">
                    Payment account detail
                    <i className="info-icon"></i>
                  </Link> */}
                </div>
                {/* // ) : null} */}
              </div>
              <div className="checkout-left ">
                <div className="head-wrap checkout-wrap">
                  <span className="checkout-headings">Delivery Location</span>
                </div>
                <React.StrictMode>
                  <ShippingAddress
                    addressList={this.props.address}
                    cartData={
                      query.pid ? this.state.prodDetail : this.props.cartData
                    }
                    updateShippingCost={this.updateShippingCost}
                    checkLocalOrNot={this.checkLocalOrNot}
                    getAddressHandle={this.getAddressHandle}
                  />
                </React.StrictMode>
                <AddressAdd getAddressHandle={this.getAddressHandle}/>
                {!query.perk ? (
                  <Coupon
                    query={query}
                    appliedCouponHandler={this.appliedCouponHandler}
                    cartData={
                      query.pid ? this.state.prodDetail : this.props.cartData
                    }
                    appliedCouponAmt={this.state.appliedCouponAmt}
                  />
                ) : null}

                {this.checkLocalOrNot() ? (
                  <LocalShipping
                    updateLocalOrderTypeHandler={
                      this.updateLocalOrderTypeHandler
                    }
                  />
                ) : null}

                <Payment
                  handlerclick={this.handlerclick}
                  radioButtonstate={this.state.paymentMethod}
                  setPayMethod={this.setPaymentMethod}
                  shippingCost={this.state.getCost}
                  isShippingAvail={this.state.isShippingAvail}
                />
              </div>
            </div>

            {this.state.orderSuccess && (
              <OrderSuccessPopup handlePopup={this.closePopup} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cartData: state.cart.getcart,
    address: state.userProfile.userAddress,
    country: state.sellItemInfo.countryList,
    userInfo: state.userInfo.userContactInfo,
    downlaod_app_banner: state.cart.static_block_data,
    details: state.productDetails.details,
    adminStatus: state.userProfile.userProfileBanner.userdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCart: (payload) => dispatch(getCart(payload)),
    getUserAddress: (payload, completion) =>
      dispatch(getUserAddress(payload, completion)),
    updateAddress: (payload, completion) =>
      dispatch(updateAddress(payload, completion)),
    applyProductCoupon: (payload, completion) =>
      dispatch(applyProductCoupon(payload, completion)),
    placeOrder: (payload, completion) =>
      dispatch(placeOrder(payload, completion)),
    getproductDetail: (payload, status) =>
      dispatch(getproductDetail(payload, status)),
    Shipping_Cost: (payload, status) =>
      dispatch(Shipping_Cost(payload, status)),
  };
};

export default withToast(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckOut))
);

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}
