import { CREATE_COUPON, GET_COUPON, REMOVE_COUPON, GET_PRODUCT_WITH_COUPON } from './../actionsTypes';
import { axiosInstance } from './../../utils/Service'
import { apiUrl } from './../../utils/urlEndpoints'

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
export const getProductWithCoupon = (payload) => {
    return dispatch => {
        return axiosInstance.post(apiUrl.productcoupon.getProduct, payload)
            .then(response => {
            })
            .catch(err => {

            })
    }
}

export const setgetProductWithCoupon = (payload) => {
    return {
        type: GET_PRODUCT_WITH_COUPON,
        payload: payload,
    }
}


export const createCoupon = (payload) => {
    return dispatch => {
        return axiosInstance.post(apiUrl.productcoupon.create, payload)
            .then(response => {
                const userId = localStorage.getItem('userId');
                dispatch(getCoupon({ "sellerId": userId }))
                // dispatch(setcreateCoupon(response.data.data));
            })
            .catch(err => {
            })
    }
}

export const setcreateCoupon = (payload) => {
    return {
        type: CREATE_COUPON,
        payload: payload,
    }
}


export const getCoupon = (payload) => {
    return dispatch => {
        return axiosInstance.post(apiUrl.productcoupon.get, payload)
            .then(response => {
                dispatch(setGetCoupon(response.data.data));
            })
            .catch(err => {

            })
    }
}

export const setGetCoupon = (payload) => {
    return {
        type: GET_COUPON,
        payload: payload,
    }
}


export const removeCoupon = (payload) => {
    return dispatch => {
        return axiosInstance.post(apiUrl.productcoupon.remove + "/" + payload.coupponid, { productId: payload.prodId })
            .then(response => {
                // dispatch(setremoveCoupon(payload.prodId));
                const userId = localStorage.getItem('userId');
                dispatch(getCoupon({ "sellerId": userId }))
            })
            .catch(err => {

            })
    }
}

export const setremoveCoupon = (payload) => {
    return {
        type: REMOVE_COUPON,
        payload: payload,
    }
}