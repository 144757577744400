import React, { Component } from "react";
import RoundCard from "./RoundCard";
import Slider from "react-slick";
import { connect } from "react-redux";
import { getHomeBrands } from "../../store/actions/dashboard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get } from "lodash";



class DiscoverBrands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      brands_data: [],
      category: []
    };
  }

  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  componentDidMount = () => {
    const { block_name, id } = this.props.data;
    this.props.getHomeBrands({ block_name, id });
    this.setState({ category: this.props.category });
  };

  static getDerivedStateFromProps = (props, state) => {
    const propsLength = get(props, ["discoverTrendData", "Brands", "length"], 0);

    const stateLength = state.brands_data.length;
    if (propsLength === stateLength && stateLength !== 0) return { ...state };
    const brandsData = get(props, ["discoverTrendData", "Brands"], []);

    const structuredBrandsData = brandsData.map((item) => {
      return {
        id: item._id,
        brandName: item.name,
        img: `${item.brandlogo}`,
        category_id: item._id
      };
    });
    return {
      ...state,
      brands_data: structuredBrandsData,
    };
  };

  next = () => {
    if (this.slider) {
      this.slider.slickNext();
    }
  };

  previous = () => {
    if (this.slider) {
      this.slider.slickPrev();
    }
  };

  render() {
    const { product_count } = this.props.data;

    const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      afterChange: (current) => this.setState({ current: current }),
      responsive: [
        {
          breakpoint: 1792,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 4,
          },
        },

        {
          breakpoint: 575,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 475,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
      ],
    };



    return (
      // <div className="trends trends-wrapper">
      //   <div className="container">
      //     <div className="trends-caption shop-brand-wrapper">
      //       <h2>Shop brands you know and love</h2>
      //       <h3>Create stuff new and used items added every day</h3>
      //     </div>
      //     <div className="trends-wrap">
      //       <div className="trends-list">
      //         {this.state.brands_data.length !== 0 && (
      //           <Slider {...settings} ref={(c) => (this.slider = c)}>
      //             {this.state.brands_data
      //               .slice(0, product_count)
      //               .map((data, index) => (
      //                 <RoundCard key={index} {...data} />
      //               ))}
      //           </Slider>
      //         )}
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <>
      </>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  discoverTrendData: state.dashboardInfo.discoverTrendData,
  category: state.productListingInfo.categories

});

const mapDispatchToProps = (dispatch) => {
  return {
    getHomeBrands: (payload) => dispatch(getHomeBrands(payload)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DiscoverBrands);
