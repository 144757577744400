import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


const RoundCardCategory = (props) => {
  const [subCategory, setsubCategory] = React.useState(false);
  const [catName, setcatName] = React.useState("");
  const [slug, setslug] = React.useState("");
  const [subCategoryData, setsubCategoryData] = React.useState([]);


  function showSubCategory(id) {
    const result = props.category.filter(data => data._id == id);
    setcatName(result[0].name);
    setslug(result[0].slug)
    setsubCategoryData(result[0].subcategoryData);
    setsubCategory(true);
  }

  function closePopup() {
    setsubCategory(false);
  }

  return (
    <>
    </>
    // <div className="trends category-trends">
    //   <div className="container">
    //     <div className="trends-wrap">
    //       <>
    //         <div className="categories-wrap">
    //           {subCategory ?
    //             <div className="categories-pop">
    //               <div className="categories-details">
    //                 <i className="fa fa-times" aria-hidden="true" onClick={closePopup}></i>
    //                 <h3>{catName}</h3>

    //                 <ul>
    //                   {subCategoryData.map((item) => {
    //                     return (
    //                       <li key={Math.random()}>
    //                         <Link to={`/product-listing-page/${catName}?category=${slug}&subcategory=${item._id}`}>
    //                           <div className="img-wrap">
    //                             <img src={item.mobile_subcategory_icon ? item.mobile_subcategory_icon : "assets/images/default-image_450.png"} alt="" />
    //                           </div>
    //                           <span>{item.name}</span>
    //                         </Link>
    //                       </li>
    //                     )
    //                   })}

    //                 </ul>
    //               </div>
    //             </div> : null
    //           }
    //           {
    //             props.category
    //               .map((data, index) => (
    //                 <div className="trend-gallery" onClick={() => { showSubCategory(data._id) }} key={Math.random()}>
    //                   <div className="trend-box">
    //                     <img className="trend-img" src={data.category_icon_mobile ? data.category_icon_mobile : "assets/images/default-image_450.png"} alt="trend-img" width="50" height="50" />
    //                   </div>
    //                   <strong>{data.name}</strong>
    //                 </div>
    //               ))
    //           }
    //         </div>
    //       </>
    //     </div>
    //   </div>
    // </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  discoverTrendData: state,
  category: state.productListingInfo.categories

});

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RoundCardCategory);
