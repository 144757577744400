import React from "react";

function Event() {
    return (
        <div>
            <section className="event-block">
                <div className="container-main">
                    <h2>Upcoming Events and Meet Up</h2>
                </div>
            </section>
            <section className="meet-up-section">
                <div className="container-main">
                    <h2>Meet us</h2>
                    <p>schedule a meeting with us</p>
                    <div className="event-wrapper">
                        <div className="ltl-event">
                            <img src="/assets/images/event-meet-up.jpg" alt="meet-up" />
                        </div>
                        <div className="rtl-event">
                            <h3>MetalsBuy event</h3>
                            <h5>12.11.2023 - 17.11.2023</h5>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Event;