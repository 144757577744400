import React from "react";
import { withRouter } from "react-router";
import { connect } from 'react-redux';


const Payment = (props) => {
  const [isPaymentSelected, setIsPaymentSelected] = React.useState(true);

  return (
    <div className="shipping-itemwrap shipping-remade checkout-shipping payemnt-checkout-shipping">
      <div className="payment-type">
        {/* <span className="checkout-headings">Payment Option</span> */}
        {
          props.isShippingAvail &&
          props.radioButtonstate === "Razorpay" && (
            <>
              {/* <div className="payment-options">
                <label className="payment-boxes">
                  <img src="/assets/images/razor-pay.png" />
                  <input
                    type="radio"
                    onChange={() => setIsPaymentSelected(true)}
                    name="radio"
                    checked={isPaymentSelected}
                  />
                  <span className="checkmark"></span>
                </label>
              </div> */}
              <button
                className={isPaymentSelected ? "complete-purchase payment-submission" : "complete-purchase"}
                onClick={(e) => props.handlerclick(props.shippingCost)}
                disabled={!isPaymentSelected}
              >
                Proceed to payment
              </button>
            </>
          )
        }

        {props.radioButtonstate === "COD" && (
          <button className="shop-now" onClick={(e) => props.handlerclick()}>
            complete purchase
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getCart: state.cart.getcart,
    getProductDetail: state.productDetails

  };
};


const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Payment)
);