import React from "react";
export default function NavigationButton(props) {
  return (
    <div className="navigation-container both-btn">
      {props.query.mode !== "edit" ? (
        // &&
        <div className="navigation-back">
          <button onClick={props.draftItem} className="back-btn">
            Draft
          </button>
          <button onClick={props.submitFun} className="next-btn">
            Upload Product
          </button>
        </div>
      ) : props.query.mode === "edit" ? (
        //&&
        <div>
          <button onClick={props.submitFun} className="next-btn">
            Update Product
          </button>
        </div>
      ) : null}
    </div>
  );
}
