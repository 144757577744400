import {
    SET_BLOG_LIST,
    SET_BLOG_DETAILED_DATA,
    SET_BLOG_CATEGORY_LIST,
    SET_RELATED_BLOG_LIST,
    SET_BLOG_ARCHIVES_LIST,
    SET_BLOG_CREATE_NEW_COMMENT
} from './../actionsTypes'

const initialState = {
    IMAGEPATH: '',
    blogList: [],
    detailedBlogData: {},
    categoryList: [],
    relatedBlog: {},
    archives: [],
    createNewComment:[],
}

const blogData = (state = initialState, action) => {
    switch (action.type) {
        case SET_BLOG_LIST:
            return setBlogList(state, action.payload)
        case SET_BLOG_DETAILED_DATA:
            return { ...state, detailedBlogData: action.payload.data }
        case SET_BLOG_CATEGORY_LIST:
            return { ...state, categoryList: action.payload }
        case SET_RELATED_BLOG_LIST:
            return { ...state, relatedBlog: action.payload }
        case SET_BLOG_ARCHIVES_LIST:
            return { ...state, archives: action.payload }
             case SET_BLOG_CREATE_NEW_COMMENT:
            return { ...state, createNewComment: action.payload }
        default:
            return state;
    }
}
export default blogData;

//////////////////////////////////////////////////////////////////////////////
const setBlogList = (state, payload) => {
    if (payload.page === 1) {
        return {
            ...state,
            blogList: {
                ...state["blogList"],
                list: payload.data, //payload.data,
                totalBlog: payload.len,
                page: payload.page,
                banner: payload.banner
            },
        };
    }
    else {
        return {
            ...state,
            blogList: {
                ...state["blogList"],
                list: [...state["blogList"].list, ...payload.data],
                totalBlog: payload.len,
                page: payload.page,
                banner: payload.banner
            },
        };
    }
};