import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";
import {
  SET_USER_CONTACT_INFO,
  SET_USER_ADDRESS_INFO,
  SAVE_MY_ADDED_PRODUCT,
  GET_ORDER_LIST,
  SET_PROFILE_BANNER_INFO,
  SET_SELLER_PROFILE_INFO,
  SET_FOLLOW_FOLLOWER,
  SAVE_SELLER_ADDED_PRODUCT,
  SET_USER_VERIFY_MSG,
  SAVE_SALES_REPORT_TABLE,
  SAVE_USER_PERFORMANCE_REPORT,
  SET_RATING_GIVEN_BY_ME,
  SET_REVIEW_ON_PRODUCT,
  SET_SOLD_BY_ME,
  SET_PRODUCT_LIKER_DATA,
  SET_RATING_REVIEW_ON_MY_PROD,
  SET_ADDED_ADDRESS_INFO,
  SET_UPDATED_ADDRESS_INFO,
} from "./../actionsTypes";
import { showLoginErr } from "./common";
import swal from "sweetalert";

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const updateManualShippingStatus = (payload, callback) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post("order/changePickupOrderStatus", payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          callback(err.response.data);
        } else {
          callback({ status: "error" });
        }
      });
  };
};

export const updateAddress = (payload, callback) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .put(apiUrl.userAddress.updateUserAddress, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        callback(response.data);
        // if (response.data.status === "success") {
        dispatch({
          type: SET_UPDATED_ADDRESS_INFO,
          payload: response.data.data,
        });
      })
      .catch((err) => {});
  };
};

export const saveAddress = (payload, callback) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.userAddress.addUserAddress, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          callback(response.data);
          // dispatch({
          //   type: SET_ADDED_ADDRESS_INFO,
          //   payload: response.data.data
          // })
        } else {
          callback(response.data);
          swal("something wrong");
        }
      })
      .catch((err) => {});
  };
};

export const updatePassword = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.changePassword, payload)
      .then((response) => {
        completion(true);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          let data = { errData: err.response.data, status: true };
          completion(false);
          dispatch(showLoginErr(data));
        }
      });
  };
};

const setUserContactInfo = (data) => {
  const payload = {
    email: data.data.email,
    fname: data.data.first_name,
    lname: data.data.last_name,
    date: data.data.DoB || "",
    mobile: data.data.mobile || "",
    followers: data.data.followers || [],
    following: data.data.following || [],
    image: data.data.user_image || "",
    IMAGEPATH: data.userimage || "",
  };
  return {
    type: SET_USER_CONTACT_INFO,
    payload: payload,
  };
};

export const getProfileInfo = (userId) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(
        apiUrl.contact.user,
        { userId: userId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        dispatch(setUserContactInfo(response.data));
      });
  };
};

export const getUserAddress = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.userAddress.getUserAddress, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        //
        if (response.data.status == "success") {
          completion(response.data);
          dispatch(saveUserAddress(response.data.data));
        } else {
          completion({ status: "error" });
        }
      })
      .catch((err) => {
        if (err.response) {
          completion({ status: "error" });
        }
      });
  };
};

export const getUserAddressBySeleer = (payload, completion) => {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.userAddress.userAddressBySeller, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {});
  };
};

export const addUserAddress = (payload, completion) => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.userAddress.addUserAddress, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        dispatch(getUserAddress());
        completion(true);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const changeDefaultAddress = (payload, completion) => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.userAddress.useDefault, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(true);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const deleteUserAddress = (addrId, completion) => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  return (dispatch) => {
    return axiosInstance
      .post(
        apiUrl.userAddress.deleteUserAddress,
        { addId: addrId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        dispatch(getUserAddress());
        completion(true);
      });
  };
};

export const updateContactDetails = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.contact.contactDetail, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        //dispatch(setUserContactInfo(response.data));
        completion(response.data);
      });
    // .catch((err) => {
    //   // if (err.response && err.response.data && err.response.data.message) {
    //   //   completion({ status: false, message: err.response.data.message });
    //   // } else {
    //   //   completion({ status: "time out", message: "something went wrong, please try again" });
    //   // }
    // });
  };
};

export const saveUserAddress = (address) => {
  return {
    type: SET_USER_ADDRESS_INFO,
    payload: address,
  };
};

export const getUserAddedProduct = (payload) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.userAddedProduct.get, payload)
      .then((response) => {
        dispatch(saveUserAddedProduct(response.data));
      });
  };
};

export const getProductLiker = (payload) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    const response = await axiosInstance.post(
      apiUrl.profile.productLiker,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch(saveProductLiker(response.data));
  };
};

const saveProductLiker = (data) => {
  return {
    type: SET_PRODUCT_LIKER_DATA,
    payload: data,
  };
};

export const sendCouponCode = async (payload, status) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.post(apiUrl.profile.createCoupon, payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return status(res.data.message);
  } catch (err) {
    return status(false);
  }
};

export const updateCouponCode = async (payload, status) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.post(apiUrl.profile.editCoupon, payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return status(res.data.message);
  } catch (err) {
    return status(false);
  }
};

export const getSellerAddedProduct = (payload, status) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.userAddedProduct.get, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        //
        dispatch(saveSellerAddedProduct(response.data));
        status(true);
      })
      .catch((error) => {
        swal("Timeout please try again.");
        //
        status(true);
      });
  };
};

export const showSalesReportTable = () => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        apiUrl.profile.downloadSalesReport,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(saveSalesReportTable(response.data.data));
    } catch (err) {
      dispatch(saveSalesReportTable([]));
    }
  };
};

export const downloadSalesReport = async (payload, callBack) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      apiUrl.profile.downloadSalesReport,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    callBack(response.data);
  } catch (err) {}
};

export const downloadReport = async (payload, callBack) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      apiUrl.profile.downloadTRansactionReport,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    callBack(response.data);
  } catch (err) {}
};

export const saveSalesReportTable = (salesData) => {
  return {
    type: SAVE_SALES_REPORT_TABLE,
    payload: salesData,
  };
};

export const saveUserAddedProduct = (products) => {
  return {
    type: SAVE_MY_ADDED_PRODUCT,
    payload: products,
  };
};

export const saveSellerAddedProduct = (products) => {
  return {
    type: SAVE_SELLER_ADDED_PRODUCT,
    payload: products,
  };
};

export const getorderlist = (payload, canceToc) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.getorderlist.get, payload, {
        cancelToken: canceToc ? canceToc.token : "",
      })
      .then((response) => {
        const ValidationList = [
          "grand_total",
          "total_amount",
          "unit_price",
          "unit_special_price",
          "unit_total_price",
        ];
        let newData = response.data.data.map((i) => {
          //remove $numberDecimal
          let tempProd = { ...i };
          ValidationList.map((k) =>
            typeof i[k] === "object" && i[k] !== null
              ? (tempProd[k] = i[k].$numberDecimal)
              : null
          );
          return tempProd;
        });

        response.data.data = newData;
        //
        dispatch(setGetorder(response.data));
      })
      .catch((err) => {
        dispatch(setGetorder([]));
      });
  };
};

export const cancelThisOrder = (payload, data, status) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        apiUrl.profile.orderCancel,
        payload,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (data) dispatch(getorderlist(data));
      if (status) status(true);
    } catch (err) {
      if (status) status(false);
    }
  };
};

export const acceptThisOrder = async (payload, status) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      apiUrl.profile.acceptOrder,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (status) status({ status: true, data: response.data });
  } catch (err) {
    if (status) status({ status: true });
  }
};

export const rejectThisOrder = async (payload, status) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      apiUrl.profile.rejectOrder,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (status) status({ status: true, data: response.data });
  } catch (err) {
    if (status) status({ status: true });
  }
};

export const acceptThisOffer = async (payload, status) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      apiUrl.profile.acceptOffer,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (status) status({ status: true, data: response.data });
  } catch (err) {
    if (status) status({ status: true });
  }
};

export const rejectThisOffer = async (payload, status) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      apiUrl.profile.rejectOffer,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (status) status({ status: true, data: response.data });
  } catch (err) {
    if (status) status({ status: true });
  }
};

export const sendInvoice = async (payload, status) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      apiUrl.profile.sendInvoice,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (status) status(true);
  } catch (err) {
    if (status) status(false);
  }
};

export const returnThisOrder = (payload, data, status) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        apiUrl.profile.orderReturn,
        payload,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (data) dispatch(getorderlist(data));
      if (status) status(true);
    } catch (err) {
      if (status) status(false);
    }
  };
};

export const changeOrderStatus = async (payload, status) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.post(
      apiUrl.profile.changeOrderStatus,
      payload,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (status) status(true);
    return true;
  } catch (err) {
    if (status) status(false);
    return false;
  }
};

export const getReasonList = async (payload, callback) => {
  try {
    if (payload === "return") {
      const response = await axiosInstance.get(apiUrl.profile.returnReason);
      return callback(response.data);
    } else if (payload === "cancel") {
      const response = await axiosInstance.get(apiUrl.profile.cancelReason);
      return callback(response.data);
    }
  } catch (err) {}
};

export const reListProd = (payload, callback) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post("product/reListProduct", payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        callback(true);
      })
      .catch((err) => {
        dispatch(setGetorder([]));
        callback(true);
      });
  };
};

export const removeFromReList = (payload, callback) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post("product/removeRelistProducts", payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        callback(true);
      })
      .catch((err) => {
        dispatch(setGetorder([]));
        callback(true);
      });
  };
};

export const getorderDetail = (payload, data, downloadLink) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.orderdetail.get, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (payload.view !== "seller") {
          let { commission } = response.data.Data;
          let valList = [
            "unit_price",
            "unit_special_price",
            "unit_total_price",
          ];
          valList.map((l) =>
            typeof response.data.Data[l] === "object" &&
            response.data.Data[l] !== null
              ? (response.data.Data[l] = response.data.Data[l].$numberDecimal)
              : null
          );
          Object.keys(commission[0]).map((key) =>
            typeof commission[0][key] === "object" &&
            commission[0][key] !== null
              ? (commission[0][key] = commission[0][key].$numberDecimal)
              : null
          );
        }
        //### for pending tab (data in data.data )
        else if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          let { commission } = response.data.data[0];
          Object.keys(commission).map((key) =>
            typeof commission[key] === "object" && commission[key] !== null
              ? (commission[key] = commission[key].$numberDecimal)
              : null
          );
          let valList1 = ["grand_total", "total_amount"];
          valList1.map((l) =>
            typeof response.data.data[0][l] === "object" &&
            response.data.data[0][l] !== null
              ? (response.data.data[0][l] =
                  response.data.data[0][l].$numberDecimal)
              : null
          );
          let valList2 = ["product_price", "realPrice"];
          response.data.data[0].orderItems.map((itm, ind) =>
            valList2.map((val_d) =>
              typeof response.data.data[0].orderItems[ind][val_d] ===
                "object" &&
              response.data.data[0].orderItems[ind][val_d] !== null
                ? (response.data.data[0].orderItems[ind][val_d] =
                    response.data.data[0].orderItems[ind][val_d].$numberDecimal)
                : null
            )
          );
        }
        //### for others  tab (data in data.Data ) // fiq price //fx $numberDecimal
        else if (response.data && response.data.Data) {
          let { commission } = response.data.Data;
          Object.keys(commission[0]).map((key) =>
            typeof commission[0][key] === "object" &&
            commission[0][key] !== null
              ? (commission[0][key] = commission[0][key].$numberDecimal)
              : null
          );
          let temp = ["unit_price", "unit_special_price", "unit_total_price"];
          temp.map((key) => {
            if (response.data.Data[key]) {
              return typeof response.data.Data[key] === "object" &&
                response.data.Data[key] !== null
                ? (response.data.Data[key] =
                    response.data.Data[key].$numberDecimal)
                : null;
            }
          });
        }
        data(response.data);

        if (
          response.data.Data &&
          response.data.Data.order_status === "Completed"
        ) {
          return axiosInstance
            .post(
              apiUrl.invoice.download,
              { orderItemId: response.data.Data._id },
              {
                headers: {
                  Authorization: "Bearer " + token,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              downloadLink(res.data.link);
            }); //+ res.data.data
        }
        // dispatch(setorderDetail(response.data))
      });
  };
};

export const getAllReviewOnProduct = (payload) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.reviewByProdId, payload)
      .then((response) => {
        dispatch(set_ProductReviewList(response.data));
      })
      .catch((err) => {});
  };
};

export const set_ProductReviewList = (payload) => {
  return {
    type: SET_REVIEW_ON_PRODUCT,
    payload: payload,
  };
};

export const getSoldProductBySeller = (props, callback) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.profile.sellerOrder, props, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        const ValidationList = [
          "grand_total",
          "total_amount",
          "unit_price",
          "unit_special_price",
          "unit_total_price",
        ];
        let newData = response.data.data.map((i) => {
          //remove $numberDecimal
          let tempProd = { ...i };
          ValidationList.map((k) =>
            typeof i[k] === "object" && i[k] !== null
              ? (tempProd[k] = i[k].$numberDecimal)
              : null
          );
          return tempProd;
        });
        response.data.data = newData;
        dispatch(setSoldProduct(response.data));
        //
        // callback()
      })
      .catch((err) => {
        dispatch(setSoldProduct({}));
      });
  };
};

export const setSoldProduct = (payload) => {
  return {
    type: SET_SOLD_BY_ME,
    payload: payload,
  };
};

export const setGetorder = (payload) => {
  return {
    type: GET_ORDER_LIST,
    payload: payload,
  };
};

export const getProfileBannerInfo = (payload, callBack) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        apiUrl.profile.profileBannerInfo,
        payload,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(setProfileBannerInfo(response.data));
      if (callBack) {
        callBack(true);
      }
    } catch (e) {
      if (callBack) {
        callBack(true);
      }
    }
  };
};

export const getUserPerformancData = (payload, callBack) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        apiUrl.profile.performanceData,
        payload,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(saveUserPerformance(response.data));
      if (callBack) {
        callBack(true);
      }
    } catch (e) {
      if (callBack) {
        callBack(true);
      }
    }
  };
};

export const saveUserPerformance = (payload) => {
  return {
    type: SAVE_USER_PERFORMANCE_REPORT,
    payload: payload,
  };
};

export const setProfileBannerInfo = (payload) => {
  return {
    type: SET_PROFILE_BANNER_INFO,
    payload: payload,
  };
};

export const verifyProfile = (payload, status) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(apiUrl.profile.verifyAccount, payload, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(getProfileBannerInfo());
        dispatch(setUserVerifyMsg(response.data.message));
        status(response.data);
      })
      .catch((err) => {
        if (err.response) {
          status({
            message: err.response.data.message,
            status: err.response.data.status,
          });
        }
      });
  };
};

export const getBankList = (callback) => {
  return axiosInstance
    .get(apiUrl.profile.getBank)
    .then((res) => callback(res.data.data))
    .catch((err) => callback([]));
};

export const setUserVerifyMsg = (payload) => {
  return {
    type: SET_USER_VERIFY_MSG,
    payload: payload,
  };
};

//for seller ##############################
export const getSellerProfile = (sellerId) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        apiUrl.profile.sellerProfile,
        { sellerId: sellerId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(setSellerProfile(response.data));
    } catch (e) {
      return dispatch(setSellerProfile({}));
    }
  };
};

export const setSellerProfile = (payload) => {
  return {
    type: SET_SELLER_PROFILE_INFO,
    payload: payload,
  };
};

export const sellerFollow = (userId, sellerId, status) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(
        apiUrl.seller.follow,
        { sellerId: sellerId, customerId: userId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        dispatch(setFollowFollower(response.data.sellerDetails));
        status(true);
      })
      .catch((err) => {
        status(false);
      });
  };
};

export const sellerUnfollow = (userId, sellerId, status) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(
        apiUrl.seller.unfollow,
        { sellerId: sellerId, customerId: userId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        dispatch(setFollowFollower(response.data.sellerDetails));
        status(true);
      })
      .catch((err) => {
        status(false);
      });
  };
};

export const setFollowFollower = (payload) => {
  return {
    type: SET_FOLLOW_FOLLOWER,
    payload: payload,
  };
};

export const setRating = (payload, status) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(
        apiUrl.seller.setRating,
        { ...payload },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        status(true);
      })
      .catch((err) => {
        status(false);
      });
  };
};

export const getAllReviewByUserId = (payload) => {
  //review giving from my side
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        apiUrl.productDetail.getReviewById,
        payload
      );
      dispatch(saveReviewInProfile(response.data));
    } catch (err) {}
  };
};

export const saveReviewInProfile = (payload) => {
  return {
    type: SET_RATING_GIVEN_BY_ME,
    payload: payload,
  };
};

export const getAllReviewOnMyProducts = (payload) => {
  //users review's on my products
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        apiUrl.productDetail.getReviewById,
        payload
      );
      dispatch(saveAllReviewOnMyProducts(response.data));
    } catch (err) {
      dispatch(saveAllReviewOnMyProducts([]));
    }
  };
};

export const saveAllReviewOnMyProducts = (payload) => {
  return {
    type: SET_RATING_REVIEW_ON_MY_PROD,
    payload: payload,
  };
};

//####### for review #####
export const addReviewOnProduct = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.orderReview.add, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) =>
        completion({ status: "error", message: "Timeout, PLease try again" })
      );
  };
};
export const addUserReview = (payload, close) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.orderReview.add, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // completion(response.data);
        close();
        window.location.reload();
        console.log(response);
      })
      .catch((err) =>
        // completion({ status: "error", message: "Timeout, PLease try again" })
        console.log(err)
      );
  };
};

export const editReviewOnProduct = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.orderReview.edit, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) =>
        completion({ status: "error", message: "Timeout, PLease try again" })
      );
  };
};

export const deleteReviewOnProduct = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.orderReview.delete, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) =>
        completion({ status: "error", message: "Timeout, Please try again" })
      );
  };
};

export const reducePrice = async (payload, status) => {
  try {
    const res = await axiosInstance.post(
      apiUrl.profile.promoteProductPrice,
      payload
    );
    return status(res);
  } catch (err) {
    return status(false);
  }
};

// Sender/Receviver Shipping Api
export const sender_receiver = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.profile.SenderRecevier, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        //
        completion({ status: true, res: response });
      })
      .catch((err) => {});
  };
};

export const stock_entry_header = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.CreateStockEntryHeader, payload)
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const generate_label = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.GenerateLabel, payload)
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {});
  };
};

export const generate_manifest = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.GenerateManifest, payload)
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

// export const myBank = (payload, completion) => {
//   return axiosInstance
//     .get(apiUrl.profile.myBankUrl, {}, {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     })
//     .then((res) => completion(res.data.data))
//     .catch((err) => completion([]));
// };

export const myBank = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .get(
        apiUrl.profile.myBankUrl,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {});
  };
};

export const deleteMyBank = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.profile.deleteBankUrl, payload)
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {
        completion(err.response.data);
      });
  };
};

export const editMyBank = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.profile.editBankUrl, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

// export const LoadAllReviewOnAllProduct = () => {
//   return (dispatch) => {
//     return axiosInstance
//       .get(apiUrl.orderReview.loadAllreview)
//       .then((response) => {
//
//         // completion(response.data.Data);
//       })
//       .catch((err) => {});
//   };
// };
