import { axiosInstance, axiosInstanceAuth } from "./../../utils/Service";
import {
  SET_PRODUCT_DETAIL,
  SET_RELATED_PRODUCTS,
  SET_QA_LIST,
  SET_QUES,
  SET_PRODUCT_OWNER,
  SET_PRODUCT_REVIEW_LIST,
  SET_MORE_PRODUCT_FROM_THIS_SELLER,
  GET_FOLLOWER,
  GET_FOLLOWING,
} from "./../actionsTypes";
import { apiUrl } from "./../../utils/urlEndpoints";

const token = (localStorage.getItem("token")? localStorage.getItem("token"):localStorage.getItem("tokenUser"));
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const getShipping = (payload, callback) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.generateShipping, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          callback(response.data);
        }
      })
      .catch((err) => {
        if (err.response.data.status === "error") {
          callback(0);
        }
      });
  };
};

export const updateTrackState = (payload, callback) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.upTrackStatus, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((err) => {
        if (err.response && err.response.data.status === "error") {
          callback(0);
        }
      });
  };
};

export const getShippingCost = (payload, callback) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.offerReqShipping, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          callback(response.data.data);
        } else {
          callback(0);
        }
      })
      .catch((err) => {
        if (err.response.data.status === "error") {
          callback(0);
        }
      });
  };
};

export const makeOffer = (payload, callback) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.offerRequest, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch((err) => {
        if (err.response.data.status === "error") {
          callback(false);
        }
      });
  };
};

export const getproductDetail = (payload, status) => {
  return (dispatch) => {
    return axiosInstance
      .get(apiUrl.productDetail.aboutProduct + payload.id)
      .then((response) => {
        const { Price } = response.data.data[0];
        // Object.keys(Price).map(attr =>
        //   typeof Price[attr] === 'object' && Price[attr] !== null
        //     ? Price[attr] = Price[attr].$numberDecimal
        //     : null
        // )
        dispatch(setProductDetails(response.data));

        dispatch(
          getProductOwner({ userId: response.data.data[0].userData[0]._id })
        );
        dispatch(
          getProductReviewList({ productId: response.data.data[0]._id })
        );
        dispatch(
          moreFromThisSeller({
            productId: response.data.data[0]._id,
            userId: response.data.data[0].userData[0]._id,
          })
        );

        // let price = parseInt(
        //   response.data.data[0].Price.sell_price
        //     ? response.data.data[0].Price.sell_price
        //     : response.data.data[0].Price.current_store_price
        // );

        let newpayload = {
          categoryId: response.data.data[0].categoryData[0]._id,
          sellerId: response.data.data[0].userData[0]._id,
          page: 1,
        };
        dispatch(similarProduct(newpayload));
        status({ status: true, dataFound: true });
      })
      .catch((err) => {
        status({ status: true, dataFound: false });
      });
  };
};

// export const getproductDetail = (payload, status) => {
//   return (dispatch) => {
//     return axiosInstance
//       .post(apiUrl.productDetail.aboutProduct + payload.id,
//         { status: payload.cat }
//       )
//       .then((response) => {
//         const { Price } = response.data.data[0]
//         Object.keys(Price).map(attr =>
//           typeof Price[attr] === 'object' && Price[attr] !== null
//             ? Price[attr] = Price[attr].$numberDecimal
//             : null
//         )
//         dispatch(setProductDetails(response.data));
//         dispatch(
//           getProductOwner({ userId: response.data.data[0].userData[0]._id })
//         );
//         dispatch(
//           getProductReviewList({ productId: response.data.data[0]._id })
//         );
//         dispatch(
//           moreFromThisSeller({
//             productId: response.data.data[0]._id,
//             userId: response.data.data[0].userData[0]._id,
//           })
//         );

//         let price = parseInt(
//           response.data.data[0].Price.sell_price
//             ? response.data.data[0].Price.sell_price
//             : response.data.data[0].Price.current_store_price
//         );
//         let newpayload = {
//           categoryId: response.data.data[0].categoryData[0]._id,
//           productId: response.data.data[0]._id,
//           price: price,
//         };
//         dispatch(similarProduct(newpayload));
//         status({ status: true, dataFound: true })
//       })
//       .catch((err) => {
//         status({ status: true, dataFound: false })

//       });
//   };
// };

export const Cost_Estimation = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.shipping_cost, payload)
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const latestSellProduct = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.latestSellerProduct, payload)
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const getProductOwner = (payload) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.productOwner, payload)
      .then((response) => {
        dispatch(set_ProductOwner(response.data));
      })
      .catch((err) => {});
  };
};

export const getOfferPriceList = async (payload, callback) => {
  try {
    const response = await axiosInstance.post(
      apiUrl.placeOrder.offerPriceOptions,
      payload
    );
    callback({ status: true, data: response.data });
  } catch (err) {
    callback({ status: false });
  }
};

export const likeProduct = async (payload, status) => {
  try {
    const response = await axiosInstance.post(
      apiUrl.productDetail.likePoduct,
      payload
    );
    status(response.data.message);
  } catch (err) {
    status(false);
  }
};

export const reportProduct = async (payload, compilation) => {
  try {
    const response = await axiosInstance.post(
      apiUrl.productDetail.productReport,
      payload
    );

    compilation(true);
  } catch (err) {
    compilation(false);
  }
};

export const getProductReviewList = (payload) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.reviewByProdId, payload)
      .then((response) => {
        dispatch(set_ProductReviewList(response.data.data));
      })
      .catch((err) => {});
  };
};

export const followerdata = (payload, completion) => {
  return (dispatch) => {
    return axiosInstanceAuth
      .post(apiUrl.productDetail.follower, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
        dispatch(set_follower(response.data));
      })
      .catch((err) => {});
  };
};

export const followingdata = (payload, completion) => {
  return (dispatch) => {
    return axiosInstanceAuth
      .post(apiUrl.productDetail.follower, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
        dispatch(set_follower(response.data));
      })
      .catch((err) => {});
  };
};

export const followStatus = (payload, completion) => {
  return (dispatch) => {
    return axiosInstanceAuth
      .post(apiUrl.productDetail.fStatus, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {});
  };
};

export const unfollowStatus = (payload, completion) => {
  return (dispatch) => {
    return axiosInstanceAuth
      .post(apiUrl.productDetail.ufStatus, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {});
  };
};

export const moreFromThisSeller = (payload) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.moreProductFromThisSeller, payload)
      .then((response) => {
        let newData = response.data.data.map((i) => {
          //remove $numberDecimal
          if (
            typeof i.Price.current_store_price === "object" &&
            i.Price.current_store_price !== null
          ) {
            return {
              ...i,
              Price: {
                current_store_price: i.Price.current_store_price.$numberDecimal,
                sell_price: i.Price.sell_price.$numberDecimal,
              },
            };
          } else return i;
        });
        dispatch(
          setRelatedProducts({ ...response.data, data: newData, ...payload })
        );
      })
      .catch((err) => {});
  };
};

export const similarProduct = (payload) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.similarProduct, payload)
      .then((response) => {
        // let newData = response.data.data.map(i => { //remove $numberDecimal
        //   if (typeof i.Price.current_store_price === 'object' && i.Price.current_store_price !== null) {
        //     return {
        //       ...i,
        //       Price: {
        //         current_store_price: i.Price.current_store_price.$numberDecimal,
        //         sell_price: i.Price.sell_price.$numberDecimal
        //       }
        //     }
        //   }
        //   else return i
        // })
        dispatch(set_MoreFromThisSeller(response.data.data));
        //dispatch(setRelatedProducts({ ...response.data, data: newData, ...payload }));
      })
      .catch((err) => {});
  };
};

export const verifyproduct = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.verifyProduct, payload)
      .then((response) => {
        completion(response.data);
      });
  };
};

export const makeAnOffer = (payload, completion) => {
  return axiosInstance
    .post(apiUrl.productDetail.bidding, payload)
    .then((response) => {
      completion(response.data.data);
    })
    .catch((err) => {
      completion(422);
    });
};

export const postques = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.prodQuestion, payload)
      .then((response) => {
        dispatch(setQues(response.data.data));
        completion(response.data.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const postans = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.prodQuestion, payload)
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

// export const getquesans = (payload, completion) => {
//   return (dispatch) => {
//     return axiosInstance
//       .post(apiUrl.productDetail.questionList, payload)
//       .then((response) => {
//         dispatch(setQAList(response.data.data));
//         completion(response.data.data);
//       })
//       .catch((err) => {
//         completion([]);
//       });
//   };
// };

export const updateans = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.updateAnswer, payload)
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {
        completion([]);
      });
  };
};

export const addToWatchlist = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.addToWatchList, payload)
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {});
  };
};

export const removewatchlist = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.removeFromWatchList, payload)
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {});
  };
};

////////// Check Estimation Time //////////////

export const checkEstimationTime = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.productDetail.estimationTime, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {
        completion({ status: "error", message: "Timeout, Please try again" });
      });
  };
};

const setQAList = (payload) => {
  return {
    type: SET_QA_LIST,
    payload: payload,
  };
};

const setQues = (payload) => {
  return {
    type: SET_QUES,
    payload: payload,
  };
};

const setProductDetails = (payload) => {
  return {
    type: SET_PRODUCT_DETAIL,
    payload: payload,
  };
};

const setRelatedProducts = (payload) => {
  return {
    type: SET_RELATED_PRODUCTS,
    payload: payload,
  };
};

const set_MoreFromThisSeller = (payload) => {
  return {
    type: SET_MORE_PRODUCT_FROM_THIS_SELLER,
    payload: payload,
  };
};

const set_ProductOwner = (payload) => {
  return {
    type: SET_PRODUCT_OWNER,
    payload: payload,
  };
};

const set_ProductReviewList = (payload) => {
  return {
    type: SET_PRODUCT_REVIEW_LIST,
    payload: payload,
  };
};

const set_follower = (payload) => {
  return {
    type: GET_FOLLOWER,
    payload: payload,
  };
};

const get_following = (payload) => {
  return {
    type: GET_FOLLOWING,
    payload: payload,
  };
};
