import {
  ADD_TO_CART,
  GET_CART,
  SAVE_STATIC_BANNER_IN_CART_PAGE,
  REMOVE_CART_FOR_WISHLIST
} from "./../actionsTypes";
import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;


export const removeAllProdFromCart = (callback) => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.cart.deleteCart, { "type": "all" }, {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          callback(true)
        }

      })
      .catch(e => {

      })
  };
};


export const getCart = (payload) => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    if (token) {
      return axiosInstance
        .post(apiUrl.cart.getCart, { userId: payload })
        .then((response) => {
          let cart = response.data.data.map(val => {
            let Price = (val.productData.length > 0 ? val.productData[0].productPrice: {});
            Object.keys(Price).map(attr =>
              typeof Price[attr] === 'object' && Price[attr] !== null
                ? Price[attr] = Price[attr].$numberDecimal
                : null
            )
            return (
              {
                Quantity: val.Quantity,
                brandData: val.BrandData,
                Title: (val.productData.length > 0 ? val.productData[0].Title : ""),
                Price: Price,
                default_image: (val.productData.length > 0 ? val.productData[0].default_image : ""),
                productId: (val.productData.length > 0 ? val.productData[0]._id : ""),
                sellerId: val.sellerData,
                cartId: val._id,
                wishlist: val.wishlist,
                prodQuantity: (val.productData.length > 0 ? val.productData[0].Quantity : ""),
                status: (val.productData.length > 0 ? val.productData[0].status : ""),
                productApprovalStatus: (val.productData.length > 0 ? val.productData[0].productApprovalStatus : ""),
                deletedAt: (val.productData.length > 0 ? val.productData[0].deletedAt : ""),
                isLocalProduct: val.isLocalProduct,
                zipCode: (val.productData.length > 0 ? val.productData[0].zip_code : "")
              }
            )
          })
          dispatch({
            type: GET_CART,
            payload: cart
          })


          dispatch({
            type: SAVE_STATIC_BANNER_IN_CART_PAGE,
            payload: response.data.static_block_data
          })


        });

    } else {
      let cart = JSON.parse(localStorage.getItem("bazar_cart"));

      dispatch({
        type: GET_CART,
        payload: cart,
      })
    }
  };
};


export const removeCartWhenClickWishlist = (payload) => (dispatch) => {
  dispatch({
    type: REMOVE_CART_FOR_WISHLIST,
    payload: payload
  })
};




export const updateCartProdQty = (payload, completion) => (dispatch) => {

  return axiosInstance
    .post(apiUrl.cart.qtyUpdate, payload)
    .then((response) => {
      if (response.data.status === 'error') {
        completion(response.data)
      } else {
        getCart()(dispatch)
        completion({ status: "success" })
      }
    })
    .catch((err) => {
      if (err.response.data.status === 'error') {
        completion(err.response.data)
      }
    });
};


// export const newCart = (payload, completion) => {
//   return (dispatch) => {
//     return axiosInstance
//       .post(apiUrl.cart.getCart, { userId: payload })
//       .then((response) => {
//         completion(response.data.data);

//       });
//   }
// }

export const deleteCart = (cartid, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.cart.deleteCart, { cartId: cartid })
      .then((response) => {

      });
  };
};



////////////////////////////////////////////////////////////////////////////////
export const getCartSellerArray = (payload, callback) => {
  return axiosInstance
    .post(apiUrl.cart.getCart, { userId: payload })
    .then((response) => {
      let data = response.data.data.map((item) => item.sellerId);
      return callback(data);
    });
};
//////////////////////////////////////////////////////////////////////////



export const addCart = (payload, callback) => {
  const token = localStorage.getItem("token");
  axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.cart.addToCart, payload, {

      })
      .then((response) => {
        callback(response.data)
      })
      .catch((err) => {
        if (err.response && err.response.data.status === "error") {
          callback(err.response.data);
        } else {
          callback(false)
        }

      });
  };
};



export const updateCart = (id, qty, completion) => {
  return (dispatch) => {
    if (localStorage.getItem("cart") === null) {
      var cartData = {};
    } else {
      cartData = JSON.parse(localStorage.getItem("cart"));
    }

    if (localStorage.getItem("token") === null) {
      let cartData = JSON.parse(localStorage.getItem("cart"));
      cartData[id].qty = qty;
      localStorage.setItem("cart", JSON.stringify(cartData));
      completion({ _id: id, Quantity: qty });
    } else {
      let data = {
        cartId: id,
        quantity: qty,
        save_for_later: false,
      };
      axiosInstance.post(apiUrl.cart.updateCart, data).then((response) => {
        completion(response.data.data);
      });
    }
  };
};



export const Shipping_Cost = (payload, completion) => {

  return (dispatch) => {
    return axiosInstance.post(apiUrl.cart.shippingCost, payload, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },

    }).then((response) => {
      completion(response.data);
    }).catch((err) => {

      if (err.code === "ECONNABORTED") {
        completion({ status: "error", type: "timeout", message: "Timeout, Please try again" });
      }
      if (err.response) {
        completion({ ...err.response.data, type: "invalid address", message: "Shipping is not available at this pincode" });
      }
    })
  }
}

export const addItemToCart = (productToAdd) => {
  return {
    type: ADD_TO_CART,
    payload: productToAdd,
  };
};

export const setGetCart = (productToAdd) => {
  return {
    type: GET_CART,
    payload: productToAdd,
  };
};

export const saveBanner = (banner) => {
  return {
    type: SAVE_STATIC_BANNER_IN_CART_PAGE,
    payload: banner,
  };
};
