import React from 'react';
import { postComment, getComment } from '../../store/actions/blogAction';
import { connect } from 'react-redux';
import CommentBox from './CommentBox'
import swal from 'sweetalert';

const Comment = (props) => {
    const [state, setState] = React.useState({
        comment: '',
        showReply: false,
        blogComment: [],
        path: "",
    })
    const userId = localStorage.getItem("userId");

    React.useEffect(() => {
        let payload = { blog_id: props.blog_id };
        props.getComment(payload, data => {
            setState({ blogComment: data.data, path: data.user_image_path })
        })
    }, [])

    const postComment = () => {
        if (state.comment === "") {
            swal("Comment field is require.")
            return false
        }

        let payload = {
            blog_id: props.blog_id,
            blog_author_id: userId,
            comment_content: state.comment.toLowerCase(),
            comment_agent: navigator.userAgent,
            comment_author_url: window.location.hostname,
            comment_parent: ""
        }

        props.postComment(payload, data => {
            setState({ ...state, comment: '' })
            swal(data.message)
        })
    }

    const postReply = (commentid, reply) => {
        if (reply === "") {
            swal("Reply field is require.")
            return false
        }
        let payload = {
            blog_id: props.blog_id,
            blog_author_id: userId,
            comment_content: reply,
            comment_agent: navigator.userAgent,
            comment_author_url: window.location.hostname,
            comment_parent: commentid
        }
        props.postComment(payload, data => {
            setState({ ...state, comment: '' })
            swal(data.message)
        })
    }

    const getComment = () => {
        let arr = []
        for (var i = state.blogComment.length - 1; i >= 0; i--) {
            if (state.blogComment[i].comment_parent === "") {
                arr.push(<CommentBox
                    key={i}
                    item={state.blogComment[i]}
                    blog_id={props.blog_id}
                    postReply={postReply}
                    blogComment={state.blogComment}
                    path={state.path}
                />)
            }
        }
        return arr
    }

    return (
        <div className="comment-wrap">
            <ul className="tabbing-list">
                <li className="active">
                    <a href="#!" className="list-item">
                        {(state.blogComment.length > 0) ? state.blogComment.length : ''} Comments
                    </a>
                </li>
            </ul>
            <div className="comment-block">
                <div className="comment-section">
                    <div className="user-img">
                        <img src="\assets\images\editors.jpg" alt="editors" />
                    </div>
                    <div className="area-field">
                        <textarea id="comment-area" name="comment-area" onChange={(e) => setState({ ...state, comment: e.target.value })} value={state.comment} />
                    </div>
                </div>
                <button className="btn comment-btn" onClick={postComment}>Post Comment</button>
            </div>
            <div className="comment-post-wrap comment-block">
                {getComment()}
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        detailedBlogData: state.blogData.detailedBlogData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        postComment: (payload, data) => dispatch(postComment(payload, data)),
        getComment: (payload, data) => dispatch(getComment(payload, data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Comment);