import React from "react";

const LoginOption = (props) => {
  const [matches, setMatches] = React.useState(window.matchMedia("(min-width: 768px)").matches);


  return (
    <div style={{ display: "flex" }}>
      {matches && (
        <>
          <div className="signin-register homepage-signin-btn" style={{ paddingRight: "20px" }}>
            <span
              onClick={() => props.registerOpen("login")}
              className="header-text"
            >
              Login
            </span>
          </div>
          <div className="signin-register signup-register-wrapper">
            <span
              onClick={() => props.registerOpen("sign-up")}
              className="header-text"
            >
              Sign up
            </span>
          </div>
        </>
      )}
      {!matches && (null)}

    </div>
  );
};

export default LoginOption;
