import React, {useState} from "react";
import { useToasts } from "react-toast-notifications";
import Loader from "../Loader";
import { setPassword } from "../../store/actions/common";
import {useDispatch} from "react-redux";
import {useLocation, withRouter} from "react-router-dom";



const NewForgetPassword=(props)=>{
    const [resetPass, setresetPass] = useState(true); 
    const [passDigits, getPassDigits] = useState(false);
    const [conPassDigits, getConPassDigits] = useState(false);
    const [password, setpassword] = useState("");
    const [chnagePasswordDisable, setchnagePasswordDisable] = useState(true);
    const [confirmpassword, setconfirmpassword] = useState("");
    const [Error, setError] = useState(false);
    const { addToast } = useToasts();
    const [showLoader, setShowLoader] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [getToken, setgetToken] = useState("");
    const [headToken, setHeadToken] = useState("");
    const [otpField, setotpField] = React.useState(true);
    const [otp, setotp] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();








 


    const showDigits = (e) => {
        if (e == "password") {
          getPassDigits(!passDigits);
        } else if (e == "confirmpassword") {
          getConPassDigits(!conPassDigits);
        } else {
    
        }
      }

      const getConfirm = (e) => {
        if (password == e) {
          setchnagePasswordDisable(false);
          setconfirmpassword(e)
          setError(false)
        }
        else {
          setchnagePasswordDisable(true);
          setError(true)
          setconfirmpassword(e)
        }
      }

      const resetPassword = () => {
        if (password.length == 0) {
          return addToast("Please enter password", {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        } else if (confirmpassword.length == 0) {
          return addToast("Please enter confirm password", {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        } else if (confirmpassword != password) {
          return addToast("Password and confirm password should match", {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
        setShowLoader(true);
        setShowMessage("Please wait...");
        const payload = {
          password: password,
          confirmPassword: confirmpassword,
          // token: location.pathname.split("/")[2]
        }
        dispatch(setPassword(payload, location.pathname.split("/")[2], (status) => {
          if (status.status == "success") {
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            setShowLoader(false);
            // setresetPass(false)
            // setotpField(false)
            // setotp(false)
              props.history.push("/");

            props.closePopup(false);
          } else {
            addToast(status.message, {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
            setShowLoader(false);
          }
        }));
      }

    return(
        <section className="new-forget-passwrd-page">
          <h2>Reset Password</h2>
      {showLoader ? <Loader message={showMessage} /> : null}

           {resetPass ?
                    <div className="pass-wrap forget-password-wrapper new-forget-pass">

                      <label class="forget-passwrd-icon-wrap">password</label>
                      <div className="pass-input-wrap forget-password-wrapper pass-one">
                        <input type={passDigits ? "text" : "password"} id="pwd" name="pwd" onChange={(e) => { setpassword(e.target.value) }} placeholder="password" required />
                        {passDigits ? <i className="far fa-solid fa-eye" onClick={() => showDigits("password")}></i> :
                          <i className="far fa-eye fal fa-eye-slash" onClick={() => showDigits("password")} />
                        }

                      </div>
                      <label class="forget-passwrd-icon-wrap">confirm password</label>
                      <div className="pass-input-wrap forget-password-wrapper pass-two">
                        <input type={conPassDigits ? "text" : "password"} id="pwd" name="pwd" onChange={(e) => getConfirm(e.target.value)} placeholder="Confirm password" required />
                        {Error && password !== confirmpassword ? <span className="error">Password and Confirm Password should match</span> : null}
                        {conPassDigits ? <i className="far fa-solid fa-eye" onClick={() => showDigits("confirmpassword")}></i> :
                          <i className="far fa-eye fal fa-eye-slash" onClick={() => showDigits("confirmpassword")} />
                        }
                      </div>
                      <div className="btn-wrap-passwrd">
                      <button className="shop-now" type="button" onClick={resetPassword} >Submit</button>
                      </div>
                    </div> : null
                  }
        </section>
    )
}

export default withRouter(NewForgetPassword);