import { axiosInstance } from './../../utils/Service'

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const getReferal = (payload, res) => {
    return dispatch => {
        return axiosInstance.post('auth/getReferalUsers', { userId: payload })
            .then(response => {
                res(response.data)
            })
            .catch(err => {
            })
    }
}
