import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearSellItemInfo,
  getAttribute,
  getEstimateCost,
  loadProductForEdit,
  costApiCancel,
  addAttributeRequest
} from "../../store/actions/sellItem";
// import LeftPanel from "./LeftPanel";
import AboutProduct from "./AboutProduct1";
import Description from "./Description";
import "./SellItem.css";
import Attributes from "./Attributes";
import AccVerifiedPopup from "./AccVerifiedPopup";
import AttributePopup from "./selectAttributePopup";
import { SpinnerLoader } from "../../utils/constants";
import { get, isEmpty } from "lodash";
import { Prompt } from "react-router-dom";
import { parse } from "query-string";
import { verifyProfile } from "../../store/actions/profile";

export const Error = () => {
  return (
    <span className="error-sell-text">
      <sup>**</sup>This Field Is Required
    </span>
  );
};
export const ErrorImg = () => {
  return (
    <span className="error-sell-text">
      {" "}
      &nbsp;&nbsp;Please Select Atleast 1 and Upto 5 Images
    </span>
  );
};

export const PriceError = () => {
  return (
    <span className="error-sell-text">
      Note<sup>**</sup> : Selling price should be less than or equal to Product price.{" "}
    </span>
  );
};

export const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "red !important",
  }),
};



const conditionOptions = [
  {
    head: "New",
    desc: "New with tags (NWT). Unopened packaging. Unused.",
    active: false,
  },
  {
    head: "Like new",
    desc: "New without tags (NWOT). No signs of wear. Unused.",
    active: false,
  },
  {
    head: "Good",
    desc: "Gently used. One / few minor flaws. Functional.",
    active: false,
  },
  {
    head: "Fair",
    desc: "Used, functional, multiple flaws / defects.",
    active: false,
  },
  {
    head: "Poor",
    desc: "Major flaws, may be damaged, for parts.",
    active: false,
  },
];

class SellAnItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AttributePopup: false,
      showErr: false,
      accountIsVerified: [],
      spinnerLoader: false,
      preventReload: true,

      title: props.aboutProductData.title || "",
      quantity: props.aboutProductData.quantity || 1,
      selectedCategory: props.aboutProductData.selectedCategory || "",
      selectedSubCategory: props.aboutProductData.selectedSubCategory || "",
      selectedChildSubCategory:
        props.aboutProductData.selectedChildSubCategory || "",
      hsn: props.aboutProductData.hsn || "",
      selectedBrand: props.aboutProductData.selectedBrand || "",

      description: props.itemDescription.description || "",
      product_tag: props.itemDescription.product_tag || ["", "", ""],
      selectedCondition: "",
      condition: conditionOptions,

      product_zip: "",
      deliveryTime: props.itemShippingInfo.deliveryTime || "",

      auction: props.itemShippingInfo.auction || false,
      auction_validity: props.itemShippingInfo.auction_validity || "",
      storePrice: props.itemShippingInfo.storePrice || "",
      priceWillReceive: props.itemShippingInfo.priceWillReceive || "",

      files: props.uploadImageData.files || [],
      fileValues: props.uploadImageData.fileValues || [],
      productId: "",
      deliveryType: { value: "Any", label: "Any" },

      resetImgPopup: false,
      setDefaultImgPopup: false,
      currentImgIndex: "",
      defaultImg: "",
      latLong: "", // latLong  from address
      smartPrice: false, //state
      suggestedSmartPrice: 0,
      smartPriceValue: 0,
      otherPrice: {},
      hsn: props.aboutProductData.hsn || "",
      selected_gst: "",
      attributeLabel: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let query = { ...parse(this.props.location.search) };
    if (query.mode && query.pid) {
      this.props.loadProductForEdit(query.pid, query.mode);
    }
    this.setState({ spinnerLoader: true });
    setTimeout(() => this.setState({ spinnerLoader: false }), 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    let query = { ...parse(this.props.location.search) };
    const {
      selectedCategory,
      selectedSubCategory,
      selectedChildSubCategory,
      priceWillReceive,
      storePrice,
    } = this.state;
    if (
      !isEmpty(prevProps.userData) &&
      this.state.accountIsVerified &&
      this.state.accountIsVerified.length === 0
    ) {
      this.setState({
        accountIsVerified: prevProps.userData.userdata,
      });
    }

    if (
      query.mode &&
      query.pid &&
      prevProps.allDetailForEdit !== this.props.allDetailForEdit &&
      !isEmpty(this.props.allDetailForEdit)
    ) {
      const payload = {
        title: this.props.allDetailForEdit.title || "",
        quantity: this.props.allDetailForEdit.quantity || 1,
        // shipping_cost:this.props.allDetailForEdit.shippingCost||"",
        selectedCategory: this.props.allDetailForEdit.selectedCategory || "",
        selectedSubCategory:
          this.props.allDetailForEdit.selectedSubCategory || "",
        selectedChildSubCategory:
          this.props.allDetailForEdit.selectedChildSubCategory || "",
        selectedBrand: this.props.allDetailForEdit.selectedBrand || "",

        description: this.props.allDetailForEdit.description || "",
        product_tag: this.props.allDetailForEdit.product_tag || ["", "", ""],
        selectedCondition: "",
        condition: conditionOptions,

        product_zip: "",
        deliveryTime: this.props.allDetailForEdit.deliveryTime || "",

        auction: this.props.allDetailForEdit.shipping.auction || false,
        storePrice: this.props.allDetailForEdit.shipping.storePrice || "",
        priceWillReceive:
          this.props.allDetailForEdit.shipping.priceWillReceive || "",

        files: this.props.allDetailForEdit.files() || [],
        fileValues: this.props.allDetailForEdit.fileValues || [],
        productId: this.props.allDetailForEdit.productId || "",
        hsn_code: this.props.allDetailForEdit.hsn || ""
      };
      this.setState((prev) => ({ ...prev, ...payload }));
    }

    // if (prevState.selectedSubCategory !== this.state.selectedSubCategory && !isEmpty(this.state.selectedSubCategory)) {
    //   this.props.getAttribute({ subcategoryId: this.state.selectedSubCategory.value._id });
    // }

    if (
      prevState.selectedCategory !== selectedCategory || // for attribute api call
      prevState.selectedSubCategory !== selectedSubCategory ||
      prevState.selectedChildSubCategory !== selectedChildSubCategory
    ) {
      this.props.getAttribute({
        categoryId: get(selectedCategory, ["value", "_id"], ""),
        subcategoryId: get(selectedSubCategory, ["value", "_id"], ""),
        subChildcategoryId: get(selectedChildSubCategory, ["value", "_id"], ""),
      });
    }

    if (
      selectedCategory &&
      storePrice &&
      (prevState.selectedCategory !== selectedCategory ||
        prevState.storePrice !== storePrice ||
        prevState.priceWillReceive !== priceWillReceive)
    ) {
      // costApiCancel.cancel("cancel by me")
      this.props.getEstimateCost(
        {
          categoryId: selectedCategory.value._id,
          price: priceWillReceive ? priceWillReceive : storePrice,
        },
        (status) => {

          if (status) {
            const val =
              parseFloat(status.productPrice) +
              parseFloat(status.commissionCost) +
              parseFloat(status.paymentGatewayCost);
            this.setState({
              otherPrice: status,
              smartPriceValue: Math.round(val),
              suggestedSmartPrice: Math.round(val),
            });
          } else {
            this.setState({
              otherPrice: {},
              smartPriceValue: 0,
              suggestedSmartPrice: 0,
            });
          }
        }
      );
    }
  }

  componentWillUnmount = () => {
    this.props.clearSellItemInfo();
  };

  closePopup = () => {
    this.props.history.push("/");
  };

  closeAttributePopup = () => {
    this.setState({ AttributePopup: false });
  }
  openAttributePopup = (e, b) => {

    this.setState({ AttributePopup: true });
    this.setState({ attributeLabel: b })
  }

  submitAttributeForm = (data, returnStatus) => {

    // const payload = {
    //   name: data.brandName,
    //   sellerId: data.userId,
    //   category: data.categoryId.value._id,
    //   image: data.image[0]
    // }
    // let formdata = new FormData();
    // Object.keys(payload).forEach((key) =>
    //   formdata.append(key, payload[key])
    // );
    this.props.addAttributeRequest(data, (status) => {
      returnStatus(status);

    })
  }


  gotoVerify = () => {
    this.props.history.push("/profile");
  };

  enterQuantity = (e) => {
    let value = e.target.value;
    let checkPrice = new RegExp(/^\d*$/);
    if (checkPrice.test(value)) {
      this.setState({ quantity: e.target.value });
    }

  };

  enterText = (e, key) => {
    this.setState({ [key]: e });
  };

  setDropDownVal = (e, key) => {


    this.setState({ [key]: e });
  };

  //############ Set Default Image  Start  ##########################################
  setDefaultPopup = (index) => {
    // ####### OPEN POPUP FOR SET DEFAULT IMAGE ###########
    this.setState({
      setDefaultImgPopup: !this.state.setDefaultImgPopup,
      resetImgPopup: false,
      currentImgIndex: index,
    });
  };
  setDefault = () => {
    const index = this.state.currentImgIndex;
    let files = this.state.files;
    let fileValues = this.state.fileValues;
    if (this.state.EDITMODE) {
      // #### for edit mode ############
      if (fileValues[index] !== 0) {
        // #### if user make new added img default
        const defaultImage = fileValues[index].name;
        files.splice(0, 0, files.splice(index, 1)[0]);
        fileValues.splice(0, 0, fileValues.splice(index, 1)[0]);
        this.setState({
          files,
          fileValues,
          setDefaultImgPopup: !this.state.setDefaultImgPopup,
          resetImgPopup: false,
          currentImgIndex: "",
          defaultImg: defaultImage,
        });
      } else {
        //###### If make default from already uploaded image
        const defaultImage = files[index].replace(
          this.props.PRODUCTIMAGEPATH,
          ""
        );
        files.splice(0, 0, files.splice(index, 1)[0]);
        fileValues.splice(0, 0, fileValues.splice(index, 1)[0]);
        this.setState({
          files,
          fileValues,
          setDefaultImgPopup: !this.state.setDefaultImgPopup,
          resetImgPopup: false,
          currentImgIndex: "",
          defaultImg: defaultImage,
        });
      }
    } else {
      files.splice(0, 0, files.splice(index, 1)[0]);
      fileValues.splice(0, 0, fileValues.splice(index, 1)[0]);
      this.setState({
        files,
        fileValues,
        setDefaultImgPopup: !this.state.setDefaultImgPopup,
        resetImgPopup: false,
        currentImgIndex: "",
      });
    }
  };
  //  ################# set default image End ##########################################

  // #################### for delete Image #############################################
  resetImgPopup = (index) => {
    // #####OPEN POPUP FOR DETELE CONFERMATION ###########
    this.setState({
      resetImgPopup: !this.state.resetImgPopup,
      setDefaultImgPopup: false,
      currentImgIndex: index,
    });
  };

  resetImageFun = () => {
    const index = this.state.currentImgIndex;
    let files = this.state.files;
    let fileValues = this.state.fileValues;
    if (this.state.EDITMODE && files[index].indexOf("blob:") === -1) {
      //remove if image is not from local storage
      const payload = {
        productId: this.props.productId,
        product_image: files[index].replace(this.props.PRODUCTIMAGEPATH, ""), // delete imgpath from img url
      };
      this.props.deleteImage(payload, (status) => {
        if (status) {
          // if delete successfully
          files.splice(index, 1);
          fileValues.splice(index, 1);
          this.setState({
            files,
            fileValues,
            currentImgIndex: "",
            resetImgPopup: false,
          });
        } else this.setState({ currentImgIndex: "", resetImgPopup: false }); // if not delete fro server ,close se popup
      });
    } else {
      files.splice(index, 1);
      fileValues.splice(index, 1);
      this.setState({
        files,
        fileValues,
        currentImgIndex: "",
        resetImgPopup: false,
      });
    }
  };
  //################## delete image End ##############################################
  sellerForm = (data, returnStatus) => {
    let payload = new FormData();
    Object.keys(data).forEach((key) => payload.append(key, data[key]));
    this.props.verifyAccount(payload, (status) => {

      returnStatus(status);
    });
  };

  render() {

    const { spinnerLoader } = this.state;
    const { downlaod_app_banner } = this.props;
    return (
      <div className="sell-item">
        {spinnerLoader ? <SpinnerLoader /> : null}

        <div className={spinnerLoader ? " spinner-time-div" : ""}>
          {!isEmpty(this.state.accountIsVerified) ? (
            this.state.accountIsVerified.verified === "verified" ? (
              <div className="">
                <div className="sell-image"></div>
                <div className="menu-item-container">
                  <div className="navigation-screen">
                    <AboutProduct
                      {...this.state}
                      enterQuantity={this.enterQuantity}
                      enterText={this.enterText}
                      setDropDownVal={this.setDropDownVal}
                      setDefaultPopup={this.setDefaultPopup}
                      setDefault={this.setDefault}
                      resetImgPopupAction={this.resetImgPopup}
                      resetImageFun={this.resetImageFun}
                    />
                    <Description
                      {...this.state}
                      conditionOptions={conditionOptions}
                      enterText={this.enterText}
                    />
                    <Attributes
                      {...this.state}
                      enterText={this.enterText}
                      searchParam={this.props.location.search}
                      openAttributePopup={this.openAttributePopup}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <AccVerifiedPopup
                  closePopup={this.closePopup}
                  gotoVerify={this.gotoVerify}
                  verification={this.state.accountIsVerified.verified}
                  sellerForm={this.sellerForm}
                />
              </div>
            )
          ) : null}

          {this.state.AttributePopup ?
            <AttributePopup
              closePopup={this.closeAttributePopup}
              submitFun={this.submitAttributeForm}
              attributeLabel={this.state.attributeLabel}
            /> : null
          }
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    userData: state.userProfile.userProfileBanner,
    downlaod_app_banner: state.cart.static_block_data,

    aboutProductData: state.sellItemInfo.aboutProductData,

    itemDescription: state.sellItemInfo.itemDescription,

    itemShippingInfo: state.sellItemInfo.itemShippingInfo,
    uploadImageData: state.sellItemInfo.uploadImageData,

    allDetailForEdit: state.sellItemInfo.allDetailForEdit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearSellItemInfo: () => dispatch(clearSellItemInfo()),
    loadProductForEdit: (payload, type) =>
      dispatch(loadProductForEdit(payload, type)),
    getAttribute: (sub_cat_id) => dispatch(getAttribute(sub_cat_id)),
    verifyAccount: (payload, status) =>
      dispatch(verifyProfile(payload, status)),
    getEstimateCost: (payload, status) => getEstimateCost(payload, status),
    addAttributeRequest: (payload, status) =>
      dispatch(addAttributeRequest(payload, status))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SellAnItem);
