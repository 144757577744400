import React from "react";
import { Redirect } from "react-router-dom";
import {
  setLoginPopupStatus,
  newsletterSubscribe,
} from "../../store/actions/common";
import { Authorization } from "../../utils/Authorization";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./MainPage.css";
import { useDispatch } from "react-redux";
import Ticker from "react-ticker";
import { useToasts } from "react-toast-notifications";
import Loader from "../Loader";
import { onPageView } from "../../utils/Analytics";

const SeconderyFooter = () => {
  const loginInfo = Authorization();
  let dispatch = useDispatch();
  setTimeout(() => {
    onPageView(loginInfo.payload);
  }, 1000);

  const [subscribedEmail, setSubscribedEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const { addToast } = useToasts();
  // if (loginInfo.status == true && window.location.pathname == "/") {
  //     return <Redirect to="/main" />
  // }
  const [menu, setMenu] = useState(false);
  const openMenu = () => {
    setMenu(!menu);
  };
  const registerOpen = (screenToShow) => {
    dispatch(
      setLoginPopupStatus({
        status: true,
        screenToShow: screenToShow,
        type: "user",
      })
    );
  };

  const handleSubEmail = (e) => {
    setSubscribedEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);
    setShowMessage("Loading...");
    const payload = {
      email: subscribedEmail,
      ipAddress: "10.0.2.53",
    };

    dispatch(
      newsletterSubscribe(payload, (status) => {
        if (status.status == "success") {
          setShowLoader(false);
          setShowMessage("");
          setSubscribedEmail("");
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2000,
          });
        } else {
          setShowLoader(false);
          setShowMessage("");
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2000,
          });
        }
      })
    );
  };
  return (
    <div>
      <section className="footer-secondary">
        {showLoader ? <Loader message={showMessage} /> : null}
        <div className="container-main">
          <div className="footer-wrapper">
            <div className="ltl-footer">
              <Link to="/" className="footer-logo">
                <img src="/assets/images/footer-logo.png" alt="footer-logo" />
              </Link>
              {/* <ul className="privacy-menu">
                                <li>
                                    <Link to="/">Terms</Link>
                                </li>
                                <li>
                                    <Link to="/">Privacy</Link>
                                </li>
                            </ul> */}
              <span className="copyright-text">
                Copyright © 2023 Metalsbuy. All rights reserved.
              </span>
            </div>
            <div className="menu-wrapper">
              <div className="footer-list">
                <ul>
                  <li>
                    <a
                      href="https://api.whatsapp.com/send?phone=917880007036&text=Hi"
                      className="nav-link tel-link"
                    >
                      <i></i>
                      +91 78800 07036
                    </a>
                  </li>
                  <li>
                    <a href="tel:+18772210709" className="nav-link tel-link">
                      <i></i>
                      +1 (872) 221-0709
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:contact@metalsbuy.com"
                      className="nav-link mail-link"
                    >
                      <i></i>
                      contact@metalsbuy.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://api.whatsapp.com/send?phone=917880007036&text=Hi"
                      className="nav-link"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/chat-whatsapp.png"
                        alt="chat-whatsapp"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="footer-list">
                                <h5>Category</h5>
                                <ul>
                                    <li>
                                        <Link to="/lumps" className="nav-link">
                                            Lumps
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/chips" className="nav-link">
                                            Chips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/fines" className="nav-link">
                                            Fines
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}
              {/* <div className="footer-list">
                                <h5>Information</h5>
                                <ul>
                                    <li>
                                        <Link to="/blog" className="nav-link" target="_blank">
                                            Blog
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/news" className="nav-link" target="_blank">
                                            News
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/event" className="nav-link" target="_blank">
                                            Events
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}
              <div className="footer-list manage-list">
                <ul>
                  <li>
                    <Link
                      to="/page/aboutUs"
                      className="nav-link"
                      target="_blank"
                    >
                      Company
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/page/aboutUs"
                      className="nav-link"
                      target="_blank"
                    >
                      &gt; About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contactUs" className="nav-link" target="_blank">
                      &gt; Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-list social-media-list">
                <ul>
                  {/* <li>
                                        <Link to="/" className="fb-wrap-icon social-icon" target="_blank">
                                            <i className="icon-facebook"></i>
                                            <div className="nav-link">
                                                Facebook
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/" className="twiter-wrap-icon social-icon" target="_blank">
                                            <i className="icon-twitter"></i>
                                            <div className="nav-link">
                                                Twitter
                                            </div>
                                        </Link>
                                    </li> */}
                  <li>
                    <Link to="/faq" className="nav-link" target="_blank">
                      &gt; FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="/policy" className="nav-link" target="_blank">
                      &gt; Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/page/terms" className="nav-link" target="_blank">
                      &gt; Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            {/* <div className="footer-bottom">
                        <div className="ltl-footer-bottom">
                            <h5>Subscribe to our Newsletter</h5>
                            <p>
                                You will never miss our latest  news, updates
                            </p>
                        </div>
                        <div className="rtl-footer-bottom">
                            <input
                            value={subscribedEmail}
                                type="email"
                                className="form-control"
                                placeholder="Enter Your Email"
                                onChange={(e)=>handleSubEmail(e)}
                                required
                            />
                            <button type="submit" className="btn-submit">
                                Submit
                            </button>
                        </div>
                    </div> */}
          </form>
        </div>
      </section>
    </div>
  );
};
export default SeconderyFooter;
