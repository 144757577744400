import React, { createContext } from "react";
import Card from "./Card";
import Slider from "react-slick";
import { getDashboardCategoryProd } from "../../store/actions/dashboard";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get } from "lodash";

export const GlobalData = createContext();


class CategoryProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      productData: [],
      id: this.props.data.id || "",
    };
  }

  componentDidMount() {
    const { id, type, block_name } = this.props.data;
    //this.props.getProduct({ block_name, type: type[0], id });
  }

  static getDerivedStateFromProps = (props, state) => {
    const wishlist = get(props.wishlist, ['data'], []);
    const propsLength =
      (props &&
        props.productData &&
        props.productData.data &&
        props.productData.data.length) ||
      0;
    const stateLength = state.productData.length;
    if (
      propsLength === stateLength &&
      stateLength !== 0 &&
      wishlist.length === 0
    ) {
      return { ...state };
    }
    const ProductDataArr =
      (props && props.productData && props.productData.data) || [];
    const structuredProductData = ProductDataArr.map((item) => {
      let wishlist_id = "";
      wishlist.length !== 0 && wishlist.map((data) => {
        if (data.product_id === item._id) {
          wishlist_id = data.wishlist_id;
        }
      });
      return {
        ...item.Price,
        quantity: item.Quantity,
        categorySlug: item.categoryData.slug,
        categoryName: item.categoryData.name,
        brandSlug: item.brandData.slug,
        brandName: item.brandData.name,
        title: item.Title,
        shippingType: item.shippingType,
        deletedAt: item.deletedAt,
        Moderate: item.Moderate,
        mainImage: `${item.default_image}`,
        extra_img: (item.Image[1] && item.Image[1].original) || null,
        id: item._id,
        is_Auction: item.is_Auction,
        auctions_data: item.auctions_data
          ? item.auctions_data[0]
          : item.auctions_data,
        wishlistIcon: true,
        wishlist_id: wishlist_id,
        attr: item.Attrs,
        delievery_charge: item.delievery_charge,
        authorised: item.authorised
      };
    });
    return {
      ...state,
      productData: structuredProductData,
    };
  };

  render() {
    const { productData } = this.state;
    const { _id, name, block_name } = this.props.data;


    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      afterChange: (current) => this.setState({ current: current }),
      responsive: [
        {
          breakpoint: 1285,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };


    return (
      <GlobalData.Provider value={this.props.bannerData.userProfile.userProfileBanner.userdata}>
        {
          this.props.data.products.length !== 0 ?
            <div className="popular-arrival" id={_id}>
              {
                this.props.data.products.length !== 0 &&
                <div className="container-main">
                  <div className="arrival-costumes">
                    {
                      this.props.data.products.length !== 0 &&
                      <div className="arrivals-header-wrapper">
                        <div className="arrival-caption arrival-costumes-wrapper">
                          <h2>Offer on {this.props.data.categoryName}</h2>
                          <div className="view-all-link">
                            {this.props.data.products.length > 6 ?
                              <Link
                                to={{
                                  pathname: (`/listing/${_id}`),
                                  state: { categorie: `${_id}` }
                                }}
                                className="shop-now view-all-btn-wrap"
                              >
                                View all
                              </Link> : null
                            }
                          </div>
                        </div>
                        <p></p>
                      </div>
                    }

                    <div className="popular-costumes popular-costumes-deals">
                      {this.props.data.products.length !== 0 && (
                        <Slider {...settings} ref={(c) => (this.slider = c)}>
                          {this.props.data.products.slice(0, 9).map((data, i) => (
                            <Card key={i} {...data} />
                          ))}
                        </Slider>
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>
            : null
        }
      </GlobalData.Provider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let id = ownProps.data.id;
  return {
    wishlist: state.Wishlist.wishlist,
    productData: state.dashboardInfo[id],
    bannerData: state
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: (payload) => dispatch(getDashboardCategoryProd(payload)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CategoryProduct);
