import {
  SET_ABOUT_PRODUCT_DATA,
  CLEAR_SELL_ITEM_INFO,
  SET_UPLOAD_IMAGE_DATA,
  SET_ITEM_DESC,
  // SET_ITEM_SELLER_INFO,
  SET_ITEM_SHIPPING_INFO,
  SET_COUNTRY_DATA,
  SET_ATTRIBUTE_DATA,
  SET_USER_SELECTED_ATTRIBUTES,
  SET_SUBCATEGORY_BRANDS,
  SET_ITEM_DETAIL_FOR_EDIT,
} from "../actionsTypes";

const initialState = {
  aboutProductData: {},
  uploadImageData: {},
  itemDescription: {},

  // itemSellerInfo: {},
  itemShippingInfo: {},
  countryList: [],
  attributeList: [], // available attributes from admin
  attributes: {}, // user selected attributes
  specificBrands: [], // specific brands accourding to selected sub category

  allDetailForEdit: {}
};
const sellItemInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_ABOUT_PRODUCT_DATA:
      return { ...state, aboutProductData: action.payload };
    case SET_UPLOAD_IMAGE_DATA:
      return { ...state, uploadImageData: action.payload };
    case SET_ITEM_DESC:
      return { ...state, itemDescription: action.payload };

    // case SET_ITEM_SELLER_INFO:
    //   return { ...state, itemSellerInfo: action.payload };
    case SET_ITEM_SHIPPING_INFO:
      return { ...state, itemShippingInfo: action.payload };
    case SET_ATTRIBUTE_DATA:
      return { ...state, attributeList: action.payload };
    case SET_COUNTRY_DATA:
      return { ...state, countryList: action.payload };
    case SET_USER_SELECTED_ATTRIBUTES:
      return { ...state, attributes: action.payload };
    case CLEAR_SELL_ITEM_INFO:
      return initialState;
    case SET_SUBCATEGORY_BRANDS:
      return { ...state, specificBrands: action.payload };
    case SET_ITEM_DETAIL_FOR_EDIT:
      return { ...state, allDetailForEdit: action.payload };
    default:
      return state;
  }
};
export default sellItemInfo;
