import React from "react";
const ReviceOffer = (props) => {
    const [revisedPrice, setPrice] = React.useState("")
    const [reason, setReasonList] = React.useState([]);
    const [validReasons, setValidReasons] = React.useState([]);

    const handleChange = (event) => {
        var value = event.target.value;
        let filtered = validReasons;
        filtered[0] = value
        setValidReasons(filtered);
    };
    // React.useEffect(() => {

    // })


    return (
        <div className="make-offer-popup cancel-return">
            <div className="offer-content">
                <button
                    className="modal-closeBtn"
                    onClick={() => {
                        props.closePopup(null);
                    }}
                    type="button"
                >
                    {/* <span className="closeBtn-crossLeft"></span>
            <span className="closeBtn-crossRight"></span> */}
                    <i className="fal fa-times-circle"></i>
                </button>
                <div className="offer-title heading">Revise Offer</div>
                <div className="offer-title">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            props.submitFun(revisedPrice);
                        }}
                    >
                        {reason.length > 0 && (
                            <div className="reason-selector">
                                <i className="fas fa-chevron-down"></i>
                                <select className="full-width" onChange={(e) => handleChange(e)}>
                                    {reason.map((i, j) => (
                                        <option
                                            value={i._id}
                                            key={j}
                                        >
                                            {i.reason}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="text-reason">
                            <input
                                id="revised"
                                name="revised"
                                placeholder="enter revised amount"
                                minLength={1}
                                maxLength={10}
                                value={revisedPrice}
                                onChange={(e) => setPrice(e.target.value)}
                                required
                            />
                        </div>

                        <button className="header-sell">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ReviceOffer;
