import { SET_SEARCH_ITEM } from '../actionsTypes';

const initialState = {
	searchItem:[],
	searchedData:[]
}
const searchItemInfo = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_ITEM :
            return { ...state, searchItem: action.payload };
        default:
            return state;
    }
}
export default searchItemInfo;