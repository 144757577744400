import React from "react";

const SellHeader = (props) => (
  <div className="sell-item-header">
    <span className="sell-page-logo" onClick={() => props.goToHome()}>
      {/* <img src="/assets/images/logo.png" alt="Logo" /> */}
      <img src="/assets/images/bazar-new-png.png" alt="Logo" />
    </span>
    <span className="sell-item-heading">Sell With Us</span>
  </div>
);
export default SellHeader;
