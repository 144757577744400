import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { truncateText } from "../../../utils/commonFunctions";




const ProfileCard = (props) => {
  return (
    <div className="costume-box profile-card-costume">
      <div className="costume-block">
        <Link
          to={`/product/${props.categorydata.name}/${props._id}/?cat=${props.categorydata.slug}`}
          className="product-pack card-link"
        >
          <div className="costumes">
            <img src={`${props.default_image}`} alt="costume-img" />
          </div>
        </Link>
        <div className="wishlist-btns">
          <div className="add-cart-wishlist add-cart-wishlist-wrap" onClick={() => props.handleAddCart(props)}>Add to cart</div>
          <div className="remove-wishlist remove-wishlist-wrapper" onClick={() => props.deleteWish(props.wishlist_id)}>
            Remove
          </div>
        </div>
      </div>
      <Link
        to={`/product/${props.categorydata.name}/${props._id}/?cat=${props.categorydata.slug}`}
        target="_blank"
      >
        <div className="costume-info">
          <strong>{truncateText(props.Title, 14)}</strong>
        </div>
      </Link>
    </div >
  );
};

export default ProfileCard;
