import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const DeliveryAddress = (props) => (
  <div className="order-left order-delivery-location">
    {console.log("props", props)}
    <div className="order-left-top">
      {props.userData && props.userData.userType == "Buyer" ? (
        <span className="order-title">Delivery address</span>
      ) : (
        <span className="order-title">Shipping address</span>
      )}
      {
        props.seller_details &&  <h6 style={{padding: 0,
          margin: "-8px 0 11px 0",
          textTransform: "unset",
          fontWeight: "400"}}>Seller company : {props.seller_details}</h6>
      }
      {props &&
        props.orderdetail &&
        props.orderdetail.product_detail &&
        props.orderdetail.product_detail.user_address_details && (
          <div className="address-block-main">
            <Link
              to={`/user-profile/${props.orderdetail.customer_id}`}
              className="user-prof-link"
            >
              <span className="order-subtitle">
                {props.orderdetail.customer_name}
              </span>
            </Link>

            {props.orderdetail.product_detail &&
              props.orderdetail.product_detail.offerId && (
                <div className="multiple-items">
                  <div className="">
                    <div className="value" style={{ marginBottom: "15px" }}>
                      <div
                        className="current-value"
                        style={{ fontSize: "17px" }}
                      >
                        Offer id:
                      </div>
                      <div className="new-value" style={{ fontSize: "17px" }}>
                        {props.orderdetail.product_detail.offerId}
                      </div>
                    </div>
                    <div className="value">
                      <div
                        className="current-value"
                        style={{ fontSize: "17px" }}
                      >
                        Offered on:
                      </div>
                      <div className="new-value" style={{ fontSize: "17px" }}>
                        {moment(
                          props.orderdetail.product_detail.createdDate
                        ).calendar({
                          sameDay: "DD/MM/YYYY h:mm a",
                          nextDay: "DD/MM/YYYY h:mm a",
                          nextWeek: "DD/MM/YYYY h:mm a",
                          lastDay: "DD/MM/YYYY h:mm a",
                          lastWeek: "DD/MM/YYYY h:mm a",
                          sameElse: "DD/MM/YYYY h:mm a",
                        })}
                      </div>
                    </div>
                  </div>
                  {!(
                    props &&
                    props.userData &&
                    props.userData.userType === "Seller" &&
                    props.orderdetail.product_detail.offerStatus ===
                      "AutoDeclined"
                  ) &&
                    props.orderdetail.product_detail.buyer_details &&
                    props.orderdetail.product_detail.buyer_details
                      .companyName && (
                      <Link
                        to={`/user-profile/${props.orderdetail.product_detail.buyer_details._id}`}
                        className="user-prof-link"
                      >
                        <span
                          className="order-subtitle offer-delivery-company"
                          style={{ margin: "20px 0 0 0" }}
                        >
                          {
                            props.orderdetail.product_detail.buyer_details
                              .companyName
                          }
                        </span>
                      </Link>
                    )}
                </div>
              )}
            <address className="order-text">
              {/* {props.orderdetail.customer_details.address} */}
              <span className="number">
                {props.orderdetail.product_detail.user_address_details.phone}
              </span>
              <div className="delivery-location">
                <i class="fas fa-map-marker-alt"></i>
                <span>
                  {/* D-333,Gali No. 12,Milan Resort,201301, Noida,Uttar Pradesh */}
                  <span>
                    {
                      props.orderdetail.product_detail.user_address_details
                        .billing_flat_no
                    }
                    ,{" "}
                  </span>
                  {props.orderdetail.product_detail.user_address_details
                    .street ? (
                    <span>
                      {
                        props.orderdetail.product_detail.user_address_details
                          .street
                      }
                      ,{" "}
                    </span>
                  ) : null}
                  {props.orderdetail.product_detail.user_address_details
                    .landmark &&
                    props.orderdetail.product_detail.user_address_details
                      .landmark.length && (
                      <span>
                        {
                          props.orderdetail.product_detail.user_address_details
                            .landmark
                        }
                        ,{" "}
                      </span>
                    )}
                  <span>
                    {
                      props.orderdetail.product_detail.user_address_details
                        .billing_postcode
                    }
                    ,{" "}
                  </span>
                  <span>
                    {
                      props.orderdetail.product_detail.user_address_details
                        .billing_city
                    }
                    ,{" "}
                  </span>
                  {props.orderdetail.product_detail.stateDetails &&
                    props.orderdetail.product_detail.stateDetails.name && (
                      <span>
                        {props.orderdetail.product_detail.stateDetails.name}
                      </span>
                    )}
                </span>
              </div>
            </address>
          </div>
        )}
    </div>
  </div>
);
export default DeliveryAddress;
