import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { isEmpty } from 'lodash';
import Toast from "light-toast";
import Loader from "../../../Loader";


const MakeAnOffer = (props) => {


    const [offerPercent, setOfferPercent] = React.useState("")
    const { addToast } = useToasts();
    const [showLoader, setShowLoader] = useState(false);
    const [showMessage, setShowMessage] = useState("");

    React.useEffect(() => {
        if (!isEmpty(props.prevData)) {
            const couponData = props.prevData.couponData[0];
            setOfferPercent(couponData.discountPercentage)
        }
    }, [props.prevData]);

    const submitForm = (e) => {
        e.preventDefault()
        setShowLoader(true);
        setShowMessage("Please wait...");
        props.sendCouponCode({ discountPercentage: offerPercent })

    }


    const handleAmount = (e) => {

        if (e.target.value.length <= 2 && !(parseInt(e.target.value) === 0)) {
            setOfferPercent(e.target.value)

        }

    }

    return (
        <div className="make-offer-popup create-coupon-wrapper coupon-loader">
            {showLoader ? <Loader message={showMessage} /> : null}
            <div className="offer-content">
                <button
                    className="modal-closeBtn"
                    onClick={() => props.closePopup(null)}
                    type="button"
                >
                    <i className="fal fa-times-circle"></i>
                </button>
                <form className="popup-body location offer-making" onSubmit={submitForm}>
                    <h3 className="create-coupon-text">Create a coupon</h3>
                    <div className="inline-form dates-wraps">
                        <label>Discount %</label>
                        <input
                            type="number"
                            required
                            value={offerPercent}
                            onChange={(e) => handleAmount(e)}
                            placeholder="Please enter 1 to 99 percentage"

                        />
                    </div>
                    <div className="make-offer" >

                        <button className="header-sell" type="submit"> Create Coupon</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default MakeAnOffer;