import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { useToasts } from "react-toast-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomReport = (props) => {
  const [startCalendar, setStartCalendar] = React.useState(false);
  const [endCalendar, setEndCalendar] = React.useState(false);
  const { addToast } = useToasts();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  return (
    <div className="custom-sales">
      <div className="custom-reports">
        <h5>Custom Reports</h5>
        <span className="custom-sales-wrap">Create your Custom sales report by selecting a date range </span>
        <span className="custom-sales-wrap">
          Thos will generate a downloadable CSV that can be used for text
          filling
        </span>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (
            new Date(startDate).getTime() === new Date(endDate).getTime()
          ) {
            addToast("Both Dates should not be equal", {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
          } else {
            addToast("Downloading", {
              appearance: "info",
              autoDismissTimeout: 2500,
            });
            props.downloadReport({ start_day: startDate, last_day: endDate });
          }
        }}
        method="posts"
      >
        <div className="cal-wrap" style={{}}>
          <div className="cal-fld">
            <span>Start Date*</span>
            <div className="popCalendar">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                maxDate={(new Date())}
                placeholderText="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                required
              />
            </div>
          </div>
          <div className="cal-fld">
            <span>End Date</span>
            <div className="popCalendar">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                minDate={startDate}
                openToDate={startDate}
                placeholderText="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "30px" }}>
          <button type="submit" className="report-downlaod-btn">
            Generate Report
          </button>
        </div>
      </form>
      <div className="related-articles">
        <h2>Related Articles</h2>
        <a href="/profile">How do i access my Gross sales report</a>
      </div>
    </div>
  );
};
export default CustomReport;
