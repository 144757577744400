import { axiosInstance } from "../../utils/Service";
import { apiUrl } from "../../utils/urlEndpoints";
import { SET_USER_CONTACT_INFO } from "../actionsTypes";

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const getCountry = (callBack) => {
  const country = apiUrl.shippingFrom.country;
  return (dispatch) => {
    return axiosInstance.get(country).then((response) => {
      callBack(response.data.data);
    });
  };
};

export const getSeller = (callBack) => {
  const sellerrrrr = apiUrl.profile.seller;
  return (dispatch) => {
    return axiosInstance
      .get(sellerrrrr, " ", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        callBack(response.data.data);
      });
  };
};

export const getBankDetail = (payload, completion) => {
  const sellerrrrr = apiUrl.profile.getBnk;
  return (dispatch) => {
    return axiosInstance
      .get(sellerrrrr, " ", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      });
  };
};

export const getAffiliations = (payload, completion) => {
  const sellerrrrr = apiUrl.profile.affiliations;
  return (dispatch) => {
    return axiosInstance
      .get(sellerrrrr, " ", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      });
  };
};

export const draftData = (payload, completion) => {
  const sellerrrrr = apiUrl.profile.draftData;
  return (dispatch) => {
    return axiosInstance
      .get(sellerrrrr, " ", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      });
  };
};

export const sellerDEtails = (payload, completion) => {
  const sellerrrrr = apiUrl.profile.sellerDetails;
  return (dispatch) => {
    return axiosInstance
      .get(sellerrrrr, " ", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      });
  };
};

export const getProductData = (payload, completion) => {
  const url = `${apiUrl.profile.productList}/?type=${payload.type || ''}`;
  return (dispatch) => {
    return axiosInstance
      .get(url, " ", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      });
  };
};

export const getProductDataAffilationNames = (payload, completion) => {
  const sellerrrrr = apiUrl.profile.allProductAffilationNames;
  return (dispatch) => {
    return axiosInstance
      .get(sellerrrrr, " ", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      });
  };
};

export const sendOTPHandler = (payload, callBack) => {
  const url = apiUrl.auth.Seller_send_otp;

  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        //
        if (response.data.status) {
          callBack(response.data);
        } else {
          callBack(response.data);
        }
      })
      .catch((e) => {
        if (e.response) {
          callBack(e.response.data);
        }
      });
  };
};

export const verifyOTP = (payload, callBack) => {
  const url = apiUrl.auth.verifyotp_api;

  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        //
        if (response.data.status) {
          callBack(true);
        } else {
          callBack(false);
        }
      })
      .catch((e) => {
        callBack(false);
      });
  };
};

export const verifyAadharOTP = (payload, completion) => {
  const url = apiUrl.auth.verify_aadhar_otp;

  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        //
        if (response.data.status) {
          completion(response.data);
        } else {
          completion(response.data);
        }
      })
      .catch((e) => {
        completion({
          code: e.response.data.code,
          message: e.response.data.message,
          status: e.response.data.status,
        });
      });
  };
};

export const sendOtpAadhar = (payload, completion) => {
  const url = apiUrl.auth.send_otp_aadhar;

  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        if (response.data.status) {
          completion(response.data.responseData);
        } else {
          completion(response.data.responseData);
        }
      })
      .catch((e) => {
        if (e.code === "ECONNABORTED") {
          completion({ status: "error", type: "Timeout, Please try again" });
        } else if (e.status === undefined) {
          completion({ status: "invalid", type: "invalid aadhaar number" });
        }
      });
  };
};

export const submitSellerRegistration = (payload, completion) => {
  const url = apiUrl.auth.seller_registration;
  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        completion(response.data);
      })
      .catch((e) => {
        if (e.response) {
          completion(e.response.data);
        }
      });
  };
};

export const sellerRegistrationDraft = (payload, completion) => {
  const url = apiUrl.auth.draft;
  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        if (response.data.status == "success") {
          completion(true);
        } else {
          completion(false);
        }
      })
      .catch((e) => {
        if (e.response) {
          completion(e.response.data);
        }
      });
  };
};

export const updateSellerProfile = (payload, completion) => {
  const url = apiUrl.auth.updateInformation;
  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        completion(response.data);
      })
      .catch((e) => {
        if (e.response) {
          completion(e.response.data);
        }
      });
  };
};

export const updateSellerRegistration = (payload, completion) => {
  const url = apiUrl.auth.update_seller_registration;
  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        completion(response.data);
      })
      .catch((e) => {
        if (e.response) {
          completion(e.response.data);
        }
      });
  };
};

export const verifyGSTNumber = (payload, completion) => {
  const url = apiUrl.editAddedProduct.getGST;
  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        completion(response.data);
      })
      .catch((e) => {
        completion({
          status: e.response.data.status,
          message: e.response.data.message,
        });
      });
  };
};

export const verifyPanNumber = (payload, callBack) => {
  const url = apiUrl.editAddedProduct.getPan;

  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        callBack(response.data);
      })
      .catch((e) => {});
  };
};

export const verifyAccountNumber = (payload, callBack) => {
  const url = apiUrl.editAddedProduct.getAccount;

  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        callBack(response.data);
      })
      .catch((e) => {});
  };
};

// export const getBazaarList = (payload, callBack) => {
//     if (Object.keys(payload).length === 0) {
//         var country = apiUrl.bazarList.bazarStoreList;
//     } else {
//         var country = `${apiUrl.bazarList.bazarStoreList}?search=${payload.search}`
//     }

//     return (dispatch) => {
//         return axiosInstance.get(country).then((response) => {
//             callBack(response.data)
//         });
//     };
// };
