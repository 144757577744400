import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { searchCareerDetail, getExperienceData, getJobDetail, applyJob } from "../../../store/actions/common";
import Toast from "light-toast";
import { useToasts } from "react-toast-notifications";



const CurrentOpening = (props) => {
  const [positionData, setPositionData] = useState([]);
  const [getPosition, setGetPosition] = useState("");
  const [experienceData, setExperienceData] = useState([]);
  const [getExperience, setGetExperience] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [getLocation, setGetLocation] = useState("");
  const [jobReview, setJobReview] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [jobExperience, setJobExperience] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [jobDescription, setJobDEscription] = useState("");
  const [jobVacancy, setJobVacancy] = useState("");
  const [applied, setApplied] = useState(false);
  const [verify, setVerify] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [qualification, setQualification] = useState("");
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [resume, setResume] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [validMobile, setValidMobile] = useState(false);
  const [jobExperienceId, setJobExperienceId] = useState("");
  const [jobLocationId, setJobLOcationId] = useState("");
  const [jobPositionId, setJobPositionId] = useState("");
  const { addToast } = useToasts();


  useEffect(() => {
    props.searchCareerDetail({}, (status) => {
      if (status) {
        setPositionData(status.data);
      }
    })
  }, []);



  const handlePosition = (e) => {
    const payload = {
      position: e.target.value,
    }
    props.getExperienceData(payload, (status) => {
      if (status) {
        setExperienceData(status.res.data);
      }
    })
    setGetPosition(e.target.value);
  }


  const handleExperience = (e) => {
    const payload = {
      position: getPosition,
      experience: e.target.value
    }
    props.getExperienceData(payload, (status) => {
      if (status) {
        setLocationData(status.res.data);
      }
    });
    setGetExperience(e.target.value);
  }


  const handleLocation = (e) => {
    setGetLocation(e.target.value);
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (getPosition == "") {
      return addToast("Please select position", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (getExperience == "") {
      return addToast("Please select experience", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (getLocation == "") {
      return addToast("Please select location", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    }
    Toast.loading("Wait...");
    const payload = {
      position: getPosition,
      experience: getExperience,
      location: getLocation
    }

    props.getJobDetail(payload, (status) => {
      if (status) {
        Toast.hide();
        setApplied(false);
        setJobTitle(status.res.data.positionData[0].name);
        setJobExperience(status.res.data.experienceData[0].experience);
        setJobExperienceId(status.res.data.experienceData[0]._id);
        setJobLocation(status.res.data.locationData[0].name);
        setJobLOcationId(status.res.data.locationData[0]._id);
        setJobPositionId(status.res.data.positionData[0]._id);
        setJobDEscription(status.res.data.positionData[0].description);
        setJobVacancy(status.res.data.positionData[0].vacancy);
        setJobReview(true);
      } else {
        Toast.hide();
      }
    });
    setGetExperience(e.target.value);
  }

  const applyNow = () => {
    setVerify(true);
  }

  const changeHandle = (e, item) => {
    if (item == "name") {
      setName(e.target.value);
    } else if (item == "email") {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
        setValidEmail(false);
        setEmail(e.target.value);
      } else {
        setValidEmail(true);
        setEmail(e.target.value);
      }
    } else if (item == "mobile") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
        if (e.target.value.length < 10) {
          setValidMobile(true);
          setMobile(e.target.value);
        } else {
          setValidMobile(false);
          setMobile(e.target.value);
        }

      }

    } else if (item == "status") {
      setStatus(e.target.value);
    } else if (item == "qualification") {
      setQualification(e.target.value);
    } else if (item == "comment") {
      setComment(e.target.value);
    } else if (item == "resume") {

      setResume(e.target.files[0]);
    }
  }

  function isValid() {
    if (name == "" || name.length == 0) {
      addToast("Please enter Name", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });

    } else if (email == "" || email.length == 0) {

      addToast("Please enter Email", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (validEmail === true) {
      addToast("Please enter valid email", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (mobile == "" || mobile.length == 0) {
      addToast("Please enter Mobile", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });

    } else if (validMobile == true) {
      addToast("Please enter valid number", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    }
    else if (qualification == "" || qualification.length == 0) {
      addToast("Please enter Qualification", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (status == "" || status.length == 0) {
      addToast("Please enter current employee Status", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });

    } else if (comment == "" || comment.length == 0) {
      addToast("Please enter Comment", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else if (resume == null) {
      addToast("Please add your resume", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    } else {
      return true
    }
  }

  const information = () => {

    if (isValid()) {
      Toast.loading("Wait...");
      const payload = {
        name: name,
        email: email,
        mobile: mobile,
        qualification: qualification,
        position: jobPositionId,
        location: jobLocationId,
        experience: jobExperienceId,
        currentEmployeeStatus: status,
        comment: comment
      }

      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item])
      }
      formdata.append('file', resume);
      props.applyJob(formdata, (status) => {
        if (status) {
          Toast.hide();
          addToast("Applied", {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          setApplied(true);
          setVerify(false);
        } else {
          Toast.hide();
        }
      })
    }

  }





  return (
    <>
      <div className="container">
        <h1>Current Vacancies</h1>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="select-boxes">
            <label>Position <sup className="highlighted">*</sup></label>
            <select id="box-1" onChange={(e) => handlePosition(e)}>
              <option selected disabled>Please select Position</option>
              {
                positionData && positionData.map(item => <option value={item._id} >{item.name}</option>)
              }
            </select>
          </div>
          <div className="select-boxes">
            <label>Experience <sup className="highlighted">*</sup></label>
            <select id="box-2" onChange={(e) => handleExperience(e)}>
              <option selected disabled>Please select Experience</option>
              {
                experienceData && experienceData.map(item => <option value={item._id} >{item.experience}</option>)
              }
            </select>
          </div>
          <div className="select-boxes">
            <label>Location <sup className="highlighted">*</sup></label>
            <select id="box-3" onChange={(e) => handleLocation(e)}>

              <option selected disabled>Please select Location</option>
              {
                locationData && locationData.map(item => <option value={item._id} >{item.name}</option>)
              }
            </select>
          </div>
          <input type="submit" value="Search" className="search-btn shop-now" />
        </form>

        {jobReview ?
          <div className="job-description">
            <h3>{jobTitle}</h3>
            <span><label>Experience: </label>{jobExperience}</span>
            <span><label>Location: </label>{jobLocation}</span>
            <span><label>Vacancy: </label>{jobVacancy}</span>
            <p>{jobDescription}</p>


            {applied ?
              <div className="apply-btn applied-btn">
                <a className="shop-now">Applied</a>
              </div> :
              <div className="apply-btn" onClick={applyNow}>
                <a className="shop-now">Apply Now</a>
              </div>
            }

          </div> : null
        }

        {verify ?
          <div className="current-opening modal">
            <div className="modal-dialog">
              <h3>Job Apply Form</h3>
              <i className="fal fa-times-circle" onClick={() => setVerify(false)}></i>
              <form>
                <div className="form-input">
                  <span>Name <sup className="highlighted">*</sup></span>
                  <input
                    type="text"
                    onChange={(e) => changeHandle(e, "name")}
                    maxLength="30"
                  />
                </div>

                <div className="form-input">
                  <span>Email <sup className="highlighted">*</sup></span>
                  <input
                    type="text"
                    onChange={(e) => changeHandle(e, "email")}
                    maxLength="100"
                  />
                  {validEmail ? <span style={{ color: "red" }} className="enter-mail-text">Please enter valid email</span> : null}

                </div>

                <div className="form-input">
                  <span>Mobile <sup className="highlighted">*</sup></span>
                  <input
                    type="text"
                    onChange={(e) => changeHandle(e, "mobile")}
                    value={mobile}
                    maxLength="10"
                  />
                  {validMobile ? <span style={{ color: "red" }}>Please enter valid mobile number</span> : null}
                </div>

                <div className="form-input">
                  <span>Qualification <sup className="highlighted">*</sup></span>
                  <input
                    type="text"
                    onChange={(e) => changeHandle(e, "qualification")}
                    maxLength="100"
                  />
                </div>

                <div className="form-input">
                  <span>Current Employe Status <sup className="highlighted">*</sup></span>
                  <input
                    type="text"
                    onChange={(e) => changeHandle(e, "status")}
                    maxLength="30"
                  />
                </div>

                <div className="form-input">
                  <span>Comment <sup className="highlighted">*</sup></span>
                  <textarea id="w3review" name="w3review" rows="4" cols="50" maxLength="500"
                    onChange={(e) => changeHandle(e, "comment")}
                  />


                </div>

                <div className="form-input">
                  <span>Upload Resume <sup className="highlighted">*</sup></span>
                  <div className='image_valid img-valiation-wrap'><span className='note-text'>Note:</span> Please select only image file (eg: .png, .jpeg, .jpg)</div>
                  <div className="file-inputs">
                    <input
                      type="file"
                      variant="outlined"
                      className="file-in"
                      name="upload"
                      onChange={(e) => changeHandle(e, "resume")}
                      accept="application/pdf,.doc,.docx"
                    />
                    <i className="fas fa-solid fa-upload"></i>
                    {resume == null ? null : <span>{resume.name}</span>}

                  </div>


                </div>

                <div className="apply-btn" onClick={information}>
                  <a className="shop-now">Submit</a>
                </div>
              </form>
            </div>
          </div> : null
        }
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => {
  return {
    searchCareerDetail: (payload, completion) => dispatch(searchCareerDetail(payload, completion)),
    getExperienceData: (payload, completion) => dispatch(getExperienceData(payload, completion)),
    getJobDetail: (payload, completion) => dispatch(getJobDetail(payload, completion)),
    applyJob: (payload, completion) => dispatch(applyJob(payload, completion)),
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrentOpening));
