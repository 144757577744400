import React, { useState, useEffect } from "react";
import FilterComponent from "./FilterCompo";
import FilterRadio from "./FilterRadio";
import AttrFilters from "./AttrFilters";
import PriceFilter from "./PriceFilter";
import { isEmpty } from "lodash";
import PaymentFilter from "./PaymentFilter";
import SellerRatingFilter from "./SellerRatingFilter";
import SelectSellerFilter from "./SelectSellerFilter";
import ProductSelectFilter from "./ProductSelectFilter";
import { getFilterData } from "../../../../store/actions/filter";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";

const Filter = ({
  filterData,
  clearAll,
  getdivstatus,
  handlerCategoryFilter,
  mainFilterHandler,
  location,
  priceFilter,
  attrFilterHandler,
  closeSmallScreenFilter,
  closeModal,
  applyBtnFilter,
  filterOnMob,
  handleFilterData,
}) => {
  const [show, setShow] = useState(true);
  const [priceshow, setPriceShow] = useState(true);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const dispatch = useDispatch();
  const [filterDataList, setFilterDataList] = useState({});
  const [delivaryTime, setDelivaryTime] = useState("");
  const history = useHistory();
  const param = useParams();
  const locationData = useLocation();
  const [cityList, setCityList] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [forData, setForData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [quantityHandle, setQuantityHandle] = useState([]);
  const [minQty, setMinQty] = useState(0);
  const [maxQty, setMaxQty] = useState(0);
  const [sellerData, setSellerData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [ratingLIst, setRatingLIst] = useState([]);
  const [cityDataList, setCityDataList] = useState([]);
  const [sellerDataList, setsellerDataList] = useState([]);
  const [productDataList, setproductDataList] = useState([]);

  useEffect(() => {
    const payload = {
      FilterData: filterData.FilterData,
      attributeType: "homepage",
    };
    getFilterData(payload, (status) => {
      if (status.status == 200) {
        setFilterDataList(status.data);
        setMinQty(status.data.quantity.minimum);
        setMaxQty(maxQty == 0 ? status.data.quantity.maximum : maxQty);
        setMinPrice(status.data.price.minimum);
        setMaxPrice(maxPrice == 0 ? status.data.price.maximum : maxPrice);
        setCityDataList(status.data.city);
        setsellerDataList(status.data.seller_data);
        setproductDataList(status.data.product_data);
      }
    })(dispatch);
  }, [filterData]);

  const handleCrossFilter = (props) => {
    // closeSmallScreenFilter();
    clearAll();
  };

  const dateHandle = (e) => {
    setDelivaryTime(e.target.value);
    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: priceData,
      quantity: quantityHandle,
      for: forData,
      paymentType: paymentType,
      seller: sellerData,
      deliveredRegion: "",
      deliveredCity: cityList,
      deliveredBy: e.target.value,
      sortby: "",
      product_data: productData,
      rating: ratingLIst,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handleCity = (e) => {
    if (e.value !== undefined && e.checked) {
      setCityList((current) => [...current, e.value]);
      var arr2 = [...cityList, e.value];
    } else {
      const index = cityList.indexOf(e.value);
      cityList.splice(index, 1);
      var arr2 = [...cityList];
      setCityList(arr2);
    }
    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: priceData,
      quantity: quantityHandle,
      for: forData,
      paymentType: paymentType,
      seller: sellerData,
      deliveredRegion: "",
      deliveredCity: arr2,
      deliveredBy: delivaryTime,
      sortby: "",
      product_data: productData,
      rating: ratingLIst,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handlePaymentType = (e) => {
    e.persist();
    if (e.target.value !== undefined && e.target.checked) {
      var payType = [...paymentType, e.target.value];
      setPaymentType((current) => [...current, e.target.value]);
    } else {
      const index = paymentType.indexOf(e.target.value);
      paymentType.splice(index, 1);
      var payType = [...paymentType];
      setPaymentType(payType);
    }

    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: priceData,
      quantity: quantityHandle,
      for: forData,
      paymentType: payType,
      seller: sellerData,
      deliveredRegion: "",
      deliveredCity: cityList,
      deliveredBy: delivaryTime,
      sortby: "",
      product_data: productData,
      rating: ratingLIst,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handleFor = (e) => {
    if (e.value !== undefined && e.checked) {
      setForData((current) => [...current, e.value]);
      var forDataType = [...forData, e.value];
    } else {
      const index = forData.indexOf(e.value);
      forData.splice(index, 1);
      var forDataType = [...forData];
      setForData(forDataType);
    }

    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: priceData,
      quantity: quantityHandle,
      for: forDataType,
      paymentType: paymentType,
      seller: sellerData,
      deliveredRegion: "",
      deliveredCity: cityList,
      deliveredBy: delivaryTime,
      sortby: "",
      product_data: productData,
      rating: ratingLIst,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handlePrice = (e) => {
    let qtyArray = [parseInt(minPrice), parseInt(maxPrice)];
    setPriceData(qtyArray);
    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: qtyArray,
      quantity: quantityHandle,
      for: forData,
      paymentType: paymentType,
      seller: sellerData,
      deliveredRegion: "",
      deliveredCity: cityList,
      deliveredBy: delivaryTime,
      sortby: "",
      product_data: productData,
      rating: ratingLIst,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handleQuantity = (e) => {
    let qtyArray = [parseInt(minQty), parseInt(maxQty)];
    setQuantityHandle(qtyArray);
    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: priceData,
      quantity: qtyArray,
      for: forData,
      paymentType: paymentType,
      seller: sellerData,
      deliveredRegion: "",
      deliveredCity: cityList,
      deliveredBy: delivaryTime,
      sortby: "",
      product_data: productData,
      rating: ratingLIst,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handleSeller = (e) => {
    if (e.target.value !== undefined && e.target.checked) {
      setSellerData((current) => [...current, e.target.value]);
      var sellerList = [...sellerData, e.target.value];
    } else {
      const index = sellerData.indexOf(e.target.value);

      sellerData.splice(index, 1);
      var sellerList = [...sellerData];
      setSellerData(sellerList);
    }

    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: priceData,
      quantity: quantityHandle,
      for: forData,
      paymentType: paymentType,
      seller: sellerList,
      deliveredRegion: "",
      deliveredCity: cityList,
      deliveredBy: delivaryTime,
      sortby: "",
      product_data: productData,
      rating: ratingLIst,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handleProducts = (e) => {
    if (e.target.value !== undefined && e.target.checked) {
      setProductData((current) => [...current, e.target.value]);
      var productList = [...productData, e.target.value];
    } else {
      const index = productData.indexOf(e.target.value);

      productData.splice(index, 1);
      var productList = [...productData];
      setProductData(productList);
    }

    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: priceData,
      quantity: quantityHandle,
      for: forData,
      paymentType: paymentType,
      seller: sellerData,
      deliveredRegion: "",
      deliveredCity: cityList,
      deliveredBy: delivaryTime,
      sortby: "",
      product_data: productList,
      rating: ratingLIst,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handleRating = (e) => {
    if (e.target.value !== undefined && e.target.checked) {
      setRatingLIst((current) => [...current, e.target.value]);
      var arr2 = [...ratingLIst, e.target.value];
    } else {
      const index = ratingLIst.indexOf(e.target.value);

      ratingLIst.splice(index, 1);
      var arr2 = [...ratingLIst];
      setRatingLIst(arr2);
    }
    const obj = {
      FilterData: filterData.FilterData,
      category: param.category,
      price: priceData,
      quantity: quantityHandle,
      for: forData,
      paymentType: paymentType,
      seller: sellerData,
      deliveredRegion: "",
      deliveredCity: cityList,
      deliveredBy: delivaryTime,
      sortby: "",
      product_data: productData,
      rating: arr2,
    };
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
    handleFilterData(obj);
  };

  const handleCitySearch = (e) => {
    let value = e.target.value;
    let result = filterDataList.city.filter((item) =>
      item.cityName.toLowerCase().includes(value.toLowerCase())
    );
    setCityDataList(result);
  };

  const handleSellerSearch = (e) => {
    let value = e.target.value;
    let result = filterDataList.seller_data.filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );
    setsellerDataList(result);
  };

  const handleProductSearch = (e) => {
    let value = e.target.value;
    let result = filterDataList.product_data.filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );
    setproductDataList(result);
  };

  return (
    <>
      {closeModal ? null : (
        <div
          className={
            getdivstatus
              ? "plp-category addFilterText"
              : "plp-category plp-cat-wrapper"
          }
        >
          <ProductSelectFilter
            filterData={filterDataList}
            handleProducts={handleProducts}
            productDataList={productDataList}
            handleProductSearch={handleProductSearch}
          />
          <SelectSellerFilter
            filterData={filterDataList}
            handleSeller={handleSeller}
            sellerDataList={sellerDataList}
            handleSellerSearch={handleSellerSearch}
          />

          <div className="delivery-details">
            <h4>Delivered by</h4>
            <div className="date-form">
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control"
                onChange={(e) => dateHandle(e)}
                pattern="\d{4}-\d{2}-\d{2}"
              />
              <i className="icon-calender"></i>
            </div>
          </div>
          <div className="delivery-details">
            <h4>Delivery City</h4>
            <input
              type="text"
              placeholder="Enter city name"
              className="form-control"
              onChange={(e) => handleCitySearch(e)}
            />
            <div className="delivery-details payemnt-delivery-details filter-border">
              {console.log("filterDataList", filterDataList)}
              {Object.keys(filterDataList).length !== 0 ? (
                <>
                  {cityDataList.map((item) => {
                    return (
                      <label class="check-wrp">
                        {item.cityName}
                        <input
                          type="checkbox"
                          value={item._id}
                          onChange={(e) => handleCity(e.target)}
                        />
                        <span class="checkmark1"></span>
                      </label>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
          {!matches && (
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => closeSmallScreenFilter()}
            ></i>
          )}

          <PaymentFilter
            paymentType={filterDataList.paymentType}
            typeHandle={handlePaymentType}
          />
          <div className="for-wrapper filter-border">
            <div className="delivery-details payemnt-delivery-details">
              <h4>F.O.R.</h4>
              {Object.keys(filterDataList).length !== 0 ? (
                <>
                  {filterDataList.forList.map((item) => {
                    return (
                      <label class="check-wrp">
                        {item.name}
                        <input
                          type="checkbox"
                          value={item.name}
                          onChange={(e) => handleFor(e.target)}
                        />
                        <span class="checkmark1"></span>
                      </label>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>

          <div className="delivery-details range-qty-wrap filter-border">
            <h4>Price</h4>
            <div className="deliver-qty-wrap">
              <div className="qty-description">
                {Object.keys(filterDataList).length !== 0 ? (
                  <>
                    <div className="min-qty new-price-wrp">
                      <input
                        className="min-qty"
                        type="number"
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                      />
                      <span className="input-unit">&#8377;</span>
                    </div>
                  </>
                ) : null}

                <span className="minus-sign"></span>
                {Object.keys(filterDataList).length !== 0 ? (
                  <>
                    <div className="min-qty new-price-wrp">
                      <input
                        className="min-qty"
                        type="number"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                      />
                      <span className="input-unit">&#8377;</span>
                    </div>
                  </>
                ) : null}
              </div>
              <button onClick={handlePrice} className="filter-btn-go">
                Go
              </button>
            </div>
          </div>

          <div className="delivery-details range-qty-wrap filter-border">
            <h4>Quantity</h4>
            <div className="deliver-qty-wrap">
              <div className="qty-description">
                {Object.keys(filterDataList).length !== 0 ? (
                  <>
                    <div className="min-qty">
                      <input
                        type="number"
                        value={minQty}
                        onChange={(e) => setMinQty(e.target.value)}
                        className="input-value"
                      />
                      <span className="input-unit">mt</span>
                    </div>
                  </>
                ) : null}

                <span className="minus-sign"></span>
                {Object.keys(filterDataList).length !== 0 ? (
                  <>
                    <div className="min-qty">
                      <input
                        className="input-value"
                        type="number"
                        value={maxQty}
                        onChange={(e) => setMaxQty(e.target.value)}
                      />
                      <span className="input-unit">mt</span>
                    </div>
                  </>
                ) : null}
              </div>
              <button onClick={handleQuantity} className="filter-btn-go">
                Go
              </button>
            </div>
          </div>
          {Object.keys(filterDataList) != 0 ? (
            <>
              <div className="seller-payment-rating payemnt-delivery-details filter-border">
                <h4>Seller Rating</h4>
                {filterDataList.ratingData.map((item, index) => {
                  return (
                    <SellerRatingFilter
                      filterData={filterDataList}
                      handleRating={handleRating}
                      starValue={item}
                      index={index}
                    />
                  );
                })}
              </div>
            </>
          ) : null}
          <div className="apply-buttons-wrap">
            <div className="header-sell apply" onClick={() => applyBtnFilter()}>
              Apply
            </div>
            <div className="header-sell" onClick={handleCrossFilter}>
              Reset
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;
