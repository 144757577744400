import React, { useState } from 'react'
import { axiosInstance } from '../../../../utils/Service';

const DeletePopup = ({ setLoading, setPopup, popupData, handleListing }) => {
    const handleSubmit = () => {
        let token = localStorage.getItem("token");
        setLoading(true)
        axiosInstance.post(`/role_permission/delete_sub_user`, {
            "roleId": popupData._id
        }, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setLoading(false)
            setPopup(false)
            handleListing()
        }).catch(() => {
            setLoading(false)
            setPopup(false)
        });
    }


    return (
        <div className='make-offer-popup-new role-delete-popup'>
            <div className='offer-popup-content'>
                <div className='offer-popup-content-header'>
                    <h3>Are you sure ?</h3>
                    <button
                        className=""
                        type="button"
                        onClick={() => setPopup(false)}
                    >
                        <i className="fal fa-times-circle"></i>
                    </button>
                </div>
                <div className="navigation-back" style={{ justifyContent: "center" }}>
                    <button onClick={() => setPopup(false)} className="next-btn">No</button>
                    <button onClick={handleSubmit} className="next-btn">Yes</button>
                </div>
            </div>
        </div>
    )
}

export default DeletePopup