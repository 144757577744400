import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { axiosInstance } from '../../utils/Service'
import OrderDetails from '../CheckOut/OrderDetails'
import moment from 'moment'
import AddressAdd from '../CheckOut/Shipping/AddressAdd'
import ShippingAddressMakeOffer from '../CheckOut/Shipping/ShippingAddressMakeOffer'
import Loader from '../Loader'
import { useSelector } from 'react-redux'
import Multiselect from 'multiselect-react-dropdown'
import { useToasts } from 'react-toast-notifications'

const MakeOfferNew = () => {
    const { id } = useParams()
    const userData = useSelector(store => store.userProfile.userProfileBanner.userdata);
    const [payOptions, setPayOptions] = useState([
        { id: 1, name: "Advance Payment" },
        { id: 2, name: "Next Day" },
        { id: 3, name: "7 Day Credit" },
        { id: 4, name: "Other" },
    ])
    let dFList = [
        { id: 1, name: "FOR" },
        { id: 2, name: "EXW" },
        //   { id: 3, name: "Local" },
        //   { id: 4, name: "Nation Wide" },
        //   { id: 4, name: "Inside State Only" },
    ];
    const [prodData, setProdData] = useState(null)
    console.log("prodData", prodData)
    const [addressList, setAddressList] = useState(null)
    const [loader, setLoader] = useState(false)

    const history = useHistory()

    const [qty, setQty] = useState({ value: "" })
    const [price, setPrice] = useState({ value: "" })
    const [payTerms, setPayTerms] = useState("")
    const [commentBox, setCommentBox] = useState("")
    const [addressId, setAddressId] = useState("")
    const [size, setSize] = useState("")
    const [delivery, setDelivery] = useState("")
    const [deliveryFrom, setDeliveryFrom] = useState("")
    const [deliveryTo, setDeliveryTo] = useState("")
    const [packaging, setPackaging] = useState([])

    const [qtyError, setQtyError] = useState(false)
    const [qtyMoqError, setQtyMoqError] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [payTermsError, setPayTermsError] = useState(false)
    const [sizeError, setSizeError] = useState(false)
    const [deliveryError, setDeliveryError] = useState(false)
    const [deliveryFromError, setDeliveryFromError] = useState(false)
    const [deliveryToError, setDeliveryToError] = useState(false)
    const [packagingError, setPackagingError] = useState(false)

    const { addToast } = useToasts();

    console.log("addressId", addressId)


    useEffect(() => {
        setLoader(true)
        getAddressHandle()
        axiosInstance.get(`/product/productDetail/${id}`).then((res) => {
            setProdData(res.data)
            setLoader(false)
        }).catch(() => {
            setLoader(false)
        });
    }, [])

    useEffect(() => {
        if (prodData) {
            setPrice({ value: prodData.data[0].productPrice.$numberDecimal })
            setPayTerms(prodData.data[0].paymentType)
            setSize(prodData.data[0].productSize)
            setDelivery(prodData.data[0].deliveryType)
            let act = moment(prodData.data[0].deliveryDateFrom).format("YYYY-MM-DD");
            let act2 = moment(prodData.data[0].deliveryDateTo).format("YYYY-MM-DD");
            setDeliveryFrom(act)
            setDeliveryTo(act2)
            let dummyPackaging = []
            prodData.data[0].packaging.forEach((item) => {
                dummyPackaging.push({ name: item })
            })
            setPackaging(dummyPackaging)
        }
    }, [prodData])

    const getAddressHandle = () => {
        setLoader(true)
        let token = localStorage.getItem("token");
        axiosInstance.post('/auth/getuserAddress', {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setAddressList(res.data)
            setLoader(false)
        }).catch(() => {
            setLoader(false)
        });
    }

    const handleQty = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setQty(qty => value.length <= 10 && !isNaN(Number(value)) && { value } || qty)
        setQtyError(false);
        setQtyMoqError(false)
    }

    const handlePrice = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPrice(price => value.length <= 10 && !isNaN(Number(value)) && { value } || price)
        setPriceError(false)
    }

    const handleMakeOffer = () => {
        if (qty.value.length === 0) {
            setQtyError(true)
            return
        }
        else if (Number(qty.value) < Number(prodData.data[0].minOrderQty)) {
            setQtyMoqError(true)
            return
        }
        else if (price.value.length === 0) {
            setPriceError(true)
            return
        }
        else if (size.length === 0) {
            setSizeError(true)
            return
        }
        else if (delivery.length === 0) {
            setDeliveryError(true)
            return
        }
        else if (deliveryFrom.length === 0) {
            setDeliveryFromError(true)
            return
        }
        else if (deliveryTo.length === 0) {
            setDeliveryToError(true)
            return
        }
        else if (packaging.length === 0) {
            setPackagingError(true)
            return
        }
        else if (payTerms.length === 0) {
            setPayTermsError(true)
            return
        }
        let token = localStorage.getItem("token");
        setLoader(true)
        let dummyPackaging = []
        packaging.forEach((item) => {
            dummyPackaging.push(item.name)
        })
        if (userData && userData.userType) {
            axiosInstance.post('/offers/offer_request', {
                "productId": id,
                "sellerId": prodData.data[0].userData[0]._id,
                "quantity": qty.value,
                "price": price.value,
                "paymentTerms": payTerms,
                "addressId": addressId,
                "additionalComment": commentBox,
                "offerType": "RequestOffer",
                "offerBy": userData.userType,

                "userId": userData._id,
                "size": size,
                "deliveryType": delivery,
                "delivery_window_from": deliveryFrom,
                "delivery_window_to": deliveryTo,
                "packaging": dummyPackaging
            },
                {
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                }).then((response) => {
                    setLoader(false)
                    if (response.data && response.data.message === "Your offer has been automatically declined as it was below the acceptable price set by the seller. Please consider submitting a revised offer with a higher price.") {
                        addToast(response.data.message, {
                            appearance: "error",
                            autoDismissTimeout: 2500,
                        });
                        setTimeout(() => {
                            history.push("/profile/my-offer/auto-declined");
                        }, 2500);
                    }
                    else {
                        history.push("/profile/my-offer/pending");
                    }
                }).catch((e) => {
                    setLoader(false)
                    addToast(e.response.data.message, {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                });
        }
    }

    console.log("prodData makeoffer", prodData)

    return (
        <div className="whitebg">
            {loader ? (
                <Loader message={"Please wait..."} />
            ) : null}
            <div className="container">
                {prodData && <div className="main-page">
                    <div className="nav-content">
                        <Link to="/" className="home-link">
                            Home
                        </Link>
                        <span className="page-name">/ Add to Cart</span>
                    </div>
                    <div className="checkout-caption">
                        <h2>Add to Cart</h2>
                    </div>
                    <div className="checkout-wrap new-checkout-wrapper">
                        <div className="checkout-right">
                            <div className='summary-section checkout-summary'>
                                <div className='checkout-cardwrap'>
                                    <div className='cart-product-main-wrap'>
                                        <div className='cart-wrp-main'>


                                            <div className="cart-product cart-product-wrapper">
                                                <div className="img-cart-product">
                                                    <Link className="product-img" to="/">
                                                        <img src={prodData && prodData.data[0] && prodData.data[0].default_image} alt="product" />
                                                    </Link>
                                                </div>
                                                <div className="cart-seller-name">
                                                    <Link to={`/user-profile/${prodData && prodData.data[0] && prodData.data[0].userData[0] && prodData.data[0].userData[0]._id}`} className='user-prof-link'>
                                                        <h2>{prodData && prodData.data[0] && prodData.data[0].userData[0] && prodData.data[0].userData[0].companyName}</h2>
                                                    </Link>
                                                    <h3>{prodData && prodData.data[0] && prodData.data[0].productName}&nbsp;  {prodData && prodData.data[0] && `(${prodData.data[0].productGrade})`}</h3>
                                                    {/* <div className="qty-cart-wrp">
                                                        <input type="number" className="form-control" placeholder="Enter Quantity" value="1" />
                                                        <span className="unit-qty">mt</span>
                                                    </div>
                                                    <span style={{ color: "red" }}></span> */}
                                                </div>
                                            </div>


                                            <div className='cart-page-address' style={{ padding: "0" }}>
                                                <div className="login-description w-100 login-description-form-wrap address-field-wrap upload-document-wrapper place-order-address">
                                                    <form>
                                                        <div className="address-field-new-wrap">
                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Quantity (mt)*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Enter Quantity"
                                                                            value={qty.value}
                                                                            onChange={handleQty}
                                                                        />
                                                                    </div>
                                                                    {qtyError ? <p className='error'>Please enter quantity</p> : null}
                                                                    {qtyMoqError ? <p className='error'>You cannot enter quantity less than minimum order quantity</p> : null}
                                                                </div>
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Price (INR)*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Enter Price"
                                                                            value={price.value}
                                                                            onChange={handlePrice}
                                                                        />
                                                                    </div>
                                                                    {priceError ? <p className='error'>Please enter price</p> : null}
                                                                </div>
                                                            </div>


                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Size (mm)*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Enter Size"
                                                                            value={size}
                                                                            onChange={(e) => { setSize(e.target.value); setSizeError(false) }}
                                                                        />
                                                                    </div>
                                                                    {sizeError ? <p className='error'>Please enter size</p> : null}
                                                                </div>
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Delivery*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <select
                                                                            className='make-offer-select-tag'
                                                                            value={delivery}
                                                                            onChange={(e) => { setDelivery(e.target.value); setDeliveryError(false) }}
                                                                        >
                                                                            {dFList.map((item, index) => {
                                                                                return <option key={index} value={item.name}>{item.name}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    {deliveryError ? <p className='error'>Please select delivery</p> : null}
                                                                </div>
                                                            </div>

                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap'>
                                                                    <label className="enter-mobile-label">
                                                                        Delivery Window*
                                                                    </label>
                                                                    <div className="form-input" style={{ position: "relative" }}>
                                                                        <input
                                                                            type="date"
                                                                            // placeholder="Enter Size"
                                                                            value={deliveryFrom}
                                                                            onChange={(e) => { setDeliveryFrom(e.target.value); setDeliveryFromError(false) }}
                                                                        />
                                                                    </div>
                                                                    {deliveryFromError ? <p className='error'>Please select delivery date</p> : null}
                                                                </div>
                                                                <div className='input-form-wrap select-affilation-profile address-name-wrap' style={{ display: "flex", alignItems: "flex-end" }}>
                                                                    <div className="form-input" style={{ width: "100%", position: "relative" }}>
                                                                        <input
                                                                            type="date"
                                                                            // placeholder="Enter Size"
                                                                            value={deliveryTo}
                                                                            onChange={(e) => { setDeliveryTo(e.target.value); setDeliveryToError(false) }}
                                                                        />
                                                                    </div>
                                                                    {deliveryToError ? <p className='error'>Please select delivery date</p> : null}
                                                                </div>
                                                            </div>



                                                            <div className="names-wrapper form-block-wrap">
                                                                <div className="input-form-wrap select-affilation-profile address-name-wrap">
                                                                    <label className="enter-mobile-label">
                                                                        Select payment terms*
                                                                    </label>
                                                                    <div className="select-main-wrapper">
                                                                        <select className='make-offer-select-tag' value={payTerms} onChange={(e) => { setPayTerms(e.target.value); setPayTermsError(false) }}>
                                                                            {payOptions.map((item, index) => {
                                                                                return <option key={index} value={item.name}>{item.name}</option>
                                                                            })}
                                                                        </select>
                                                                        {/* <i className="icon-arrow-down"></i> */}
                                                                    </div>
                                                                    {payTermsError ? <p className='error'>Please select payment terms</p> : null}
                                                                </div>
                                                                {/* <div className="form-block-wrap input-form-wrap select-affilation-profile address-name-wrap" style={{ marginBottom: "0" }}>
                                                                    <label className="enter-mobile-label">
                                                                        Packaging*
                                                                    </label>
                                                                    <div className="form-input">
                                                                        <div className="input-form-wrap select-affilation-profile form-block-wrap" style={{ marginBottom: "0" }}>
                                                                            <div className="form-intput select-affilation-wrap complete-profile-select" style={{ marginBottom: "0" }}>
                                                                                <div className="select-main-wrapper make-offer-multi-select">
                                                                                    <Multiselect
                                                                                        id="state"
                                                                                        options={[
                                                                                            {
                                                                                                name: "40 kg bag"
                                                                                            },
                                                                                            {
                                                                                                name: "50 kg bag"
                                                                                            },
                                                                                            {
                                                                                                name: "1 MT Jumbo bag"
                                                                                            }
                                                                                        ]} // Options to display in the dropdown
                                                                                        selectedValues={packaging} // Preselected value to persist in dropdown
                                                                                        onSelect={(e) => { setPackaging(e); setPackagingError(false) }} // Function will trigger on select event
                                                                                        onRemove={(e) => { setPackaging(e); setPackagingError(false) }} // Function will trigger on remove event
                                                                                        displayValue="name" // Property name to display in the dropdown options
                                                                                    />
                                                                                    <i className="icon-arrow-down"></i>
                                                                                </div>
                                                                                {packagingError ? <p className='error'>Please select packaging</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */} 
                                                            </div>

                                                            <div className="form-block-wrap">
                                                                <label className="enter-mobile-label">
                                                                    Comment Box
                                                                </label>
                                                                <div className="form-input">
                                                                    <textarea maxLength={"350"} onChange={(e) => { setCommentBox(e.target.value) }} placeholder='Enter comment'></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>



                                            {/* <div className="current-price" style={{ marginTop: "40px" }}>Product Specifications</div>
                                            <div className="product-tble-wrp product-table-seller" style={{ marginTop: "10px" }}>
                                                <table>
                                                    {prodData.data[0].spec && Object.keys(prodData.data[0].spec).map(function (
                                                        key,
                                                        index
                                                    ) {
                                                        return Object.keys(prodData.data[0].spec).length %
                                                            2 ===
                                                            1 ? (
                                                            Object.keys(prodData.data[0].spec).length - 1 ===
                                                                index &&
                                                                Object.keys(prodData.data[0].spec).length > 2 ? (
                                                                <>
                                                                    {prodData.data[0].spec[key] !== "% " ? (
                                                                        <>
                                                                            <tr>
                                                                                <th>
                                                                                    {key.charAt(0).toUpperCase() +
                                                                                        key.slice(1)}
                                                                                </th>
                                                                                <th>{prodData.data[0].spec[key]} </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th
                                                                                    style={{
                                                                                        color: "transparent",
                                                                                        borderRightColor: "transparent",
                                                                                    }}
                                                                                >
                                                                                    T
                                                                                </th>
                                                                                <th
                                                                                    style={{
                                                                                        color: "transparent",
                                                                                        borderLeftColor: "transparent",
                                                                                    }}
                                                                                >
                                                                                    0
                                                                                </th>
                                                                            </tr>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {prodData.data[0].spec[key] !== "% " ? (
                                                                        <tr>
                                                                            <th>
                                                                                {key.charAt(0).toUpperCase() +
                                                                                    key.slice(1)}
                                                                            </th>
                                                                            <th>{prodData.data[0].spec[key]} </th>
                                                                        </tr>
                                                                    ) : null}
                                                                </>
                                                            )
                                                        ) : (
                                                            <>
                                                                {prodData.data[0].spec[key] !== "% " ? (
                                                                    <tr>
                                                                        <th>
                                                                            {key.charAt(0).toUpperCase() +
                                                                                key.slice(1)}
                                                                        </th>
                                                                        <th>{prodData.data[0].spec[key]} </th>
                                                                    </tr>
                                                                ) : null}
                                                            </>
                                                        );
                                                    })}
                                                </table>
                                            </div>
                                            <div className="cart-product-text brand-text-wrp">
                                                <div className="cart-item-detail">
                                                    <div className="price">
                                                        <div className="current-price">Name:</div>
                                                        <div className="new-price">{prodData.data[0].productName}</div>
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">Price:</div>
                                                        <div className="new-price">
                                                            ₹{prodData.data[0].productPrice.$numberDecimal}/mt
                                                        </div>
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">Type:</div>
                                                        {prodData.data[0].categoryData[0] ? (
                                                            <div className="new-price">
                                                                {prodData.data[0].categoryData[0].name}
                                                            </div>
                                                        ) : (
                                                            <div className="new-price">
                                                                {prodData.data[0].categoryData.name}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">Size:</div>
                                                        <div className="new-price">
                                                            {prodData.data[0].productSize} mm{" "}
                                                            {prodData.data[0].process !== undefined
                                                                ? prodData.data[0].process
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">Available Qty (in mt):</div>
                                                        <div className="new-price">
                                                            {prodData.data[0].productWeight ? prodData.data[0].productWeight : ""}
                                                        </div>
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">M.O.Q (in mt):</div>
                                                        <div className="new-price">{prodData.data[0].minOrderQty}</div>
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">Size Tolerance:</div>
                                                        <div className="new-price">
                                                            {prodData.data[0].productSizeTolerance}%
                                                        </div>
                                                    </div>
                                                    {prodData.data[0].specificationTolerance ? (
                                                        <div className="price">
                                                            <div className="current-price">Specification Tolerance:</div>
                                                            <div className="new-price">
                                                                {prodData.data[0].specificationTolerance}%
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <div className="price">
                                                        <div className="current-price delivery-window">
                                                            Delivery Window:
                                                        </div>
                                                        <div className="new-price">
                                                            {moment(prodData.data[0].deliveryDateFrom).format("Do MMM")}{" "}
                                                            - {moment(prodData.data[0].deliveryDateTo).format("Do MMM")}
                                                        </div>
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">Delivery:</div>
                                                        <div className="new-price">
                                                            {prodData.data[0].deliveryType
                                                                ? prodData.data[0].cityData &&
                                                                prodData.data[0].deliveryType +
                                                                " " +
                                                                prodData.data[0].cityData[0].cityName
                                                                : prodData.data[0].cityData &&
                                                                prodData.data[0].cityData[0].cityName}
                                                        </div>
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">Payment Terms:</div>
                                                        <div className="new-price">{prodData.data[0].paymentType}</div>
                                                    </div>
                                                    <div className="price">
                                                        <div className="current-price">Gst:</div>
                                                        <div className="new-price">
                                                            {prodData.data[0].gst.$numberDecimal + "%"}
                                                        </div>
                                                    </div>
                                                    <>
                                                        {prodData.data[0].loadingCharge ? (
                                                            <div className="price">
                                                                <div className="current-price">Loading Charges</div>
                                                                <div className="new-price">
                                                                    ₹{prodData.data[0].loadingCharge.$numberDecimal}/mt
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </>
                                                    <>
                                                        {prodData.data[0].packaging.length > 0 ? (
                                                            <div className="price">
                                                                <div className="current-price">Packaging</div>
                                                                {
                                                                    prodData.data[0].packaging.map((item, index) => {
                                                                        return <div className="new-price" key={index}>
                                                                            {item}
                                                                        </div>
                                                                    })
                                                                }

                                                            </div>
                                                        ) : null}
                                                    </>
                                                    <>
                                                        {prodData.data[0].insuranceCharge ? (
                                                            <div className="price">
                                                                <div className="current-price">Insurance charges</div>
                                                                <div className="new-price">
                                                                    ₹{prodData.data[0].insuranceCharge.$numberDecimal}/mt
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-btn-wrap" style={{ marginTop: "0" }}>
                                <div className="payment-btn">
                                    <button className="proceed-payment" type='button' onClick={handleMakeOffer}>
                                        Submit Buying Offer
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-left ">
                            <div className="head-wrap checkout-wrap">
                                <span className="checkout-headings">Delivery Location</span>
                            </div>
                            {addressList && addressList.data && <ShippingAddressMakeOffer
                                addressList={addressList.data}
                                cartData={
                                    []
                                }
                                updateShippingCost={(a, b, val) => { setAddressId(val) }}
                                checkLocalOrNot={() => { return true }}
                                getAddressHandle={getAddressHandle}
                            />}
                            {/* <div className='shipping-itemwrap shipping-remade checkout-payment-shipping-details'>
                                {
                                    addressList && addressList.data ? addressList.data.map((item, index)=>{
                                        return <div className="shipping-inner shipping-remade" key={index}>
                                        <div className="ship-radio shipping_radio_btn">
                                            <input type="radio" className="radio" value="646f4435d6a225733a95c6d5" />
                                            <div className="radio-circle"></div>
                                        </div>
                                        <div className="address-left-checkout-page">
                                            <div className="desc-txt payment-desc-text">
                                                <div className="ship-address-wrapper">
                                                    <span className="ship-add-name">New Buyer</span>
                                                    <span className="ship-add-number">5325325235</span></div>
                                                <div className="address-checkout shipping-address shipping-checkout shipping-check-wrapper">
                                                    <i className="location-icon"></i>
                                                    <div className="ship-wrapper-main">
                                                        <span className="ship-add">f, </span>
                                                        <span className="ship-add">bdb, </span>
                                                        <span className="ship-add">df, </span>
                                                        <span className="ship-add">434353, </span>
                                                        <span className="ship-add">df,</span>
                                                        <span className="ship-add">Jammu &amp; Kashmir</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="delete-address-checkout">
                                            <i className="fas fa-trash" style={{ color: "rgb(237, 152, 41)" }}></i>
                                        </button>
                                        <button className="edit-address-checkout">
                                            <i className="fas fa-edit" style={{ color: "rgb(237, 152, 41)" }}></i>
                                        </button>
                                    </div>
                                    }):null
                                }
                            </div> */}
                            <AddressAdd getAddressHandle={getAddressHandle} />
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    )
}

export default MakeOfferNew