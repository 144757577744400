import React, { useEffect } from "react";
import Header from "../Header";
import { withRouter } from "react-router-dom";
import SeconderyHeader from "../MainPage/seconderyHeader";
import { Authorization } from "../../utils/Authorization";
import { getActiveStatus, logout } from "../../store/actions/common";
import { useDispatch } from "react-redux";

const HeaderMaster = (props) => {
  let dispatch = useDispatch();
  const loginInfo = Authorization();
  var profileCompleted = localStorage.getItem("isProfileCompleted");
  var profileApproved = localStorage.getItem("approved");
  const loc = {
    coords: props.coords,
    isGeolocationAvailable: props.isGeolocationAvailable,
    isGeolocationEnabled: props.isGeolocationEnabled,
    positionError: props.positionError,
  };
  useEffect(() => {
    if (loginInfo.status == true) {
      getActiveStatus({}, (status) => {
        if (status.length > 0) {
          if (status[0].status == "Active") {
          } else if (status[0].status == "Inactive") {
            //logout()(dispatch)
            //alert("wgdyty")
          } else {
          }
        } else {
          //logoutHandler();
        }
      })(dispatch);
    }
  }, [loginInfo]);
  return (
    <>
      {window.location.pathname == "/" ||
      loginInfo.status == false ||
      ((profileCompleted == "false" || profileCompleted == "true") &&
        loginInfo.status == true &&
        profileApproved !== "accept") ? (
          window.location.pathname == "/process-orders" ? null : 
        <SeconderyHeader />
      ) : (
        <Header browser_location={loc} />
      )}
    </>
  );
};
export default withRouter(HeaderMaster);
