import React from "react";
import { withRouter } from "react-router-dom";
import { getCategory } from "../../../store/actions/productListing";
import { connect } from "react-redux";

const BrandList = (props) => {

    return (
        <>
            {props.brands && props.brands.map((item) => {
                return (
                    <>
                        <span key={Math.random()}>{item.label}</span><br />
                    </>
                )
            })}

        </>
    )
}


const mapStateToProps = (state, ownProps) => ({
    brands: state.productListingInfo.brands
});

const mapDispatchToProps = (dispatch) => {
    return {


    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandList));
