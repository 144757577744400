import React, { useState, useEffect } from "react";
import Toast from "light-toast";
import { axiosInstance } from './../../utils/Service';
import { apiUrl } from './../../utils/urlEndpoints';
import { parse } from "query-string";
import { useToasts } from "react-toast-notifications";
import Loader from "../Loader";

const ConfermPass = (props) => {
  const { addToast } = useToasts();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showP1, setShowP1] = useState(false);
  const [showP2, setShowP2] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");



  const updatePassword = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return setShowErr(true)
    }
    const query = parse(props.location.search)
    setShowLoader(true);
    setShowMessage("Please wait...")

    const payload = {
      password,
      confirmPassword: password
    }
    axiosInstance.post(apiUrl.auth.resetPasswordafterForgot + "/" + query.token, payload)
      .then(response => {
        if (response.data.status === "success") {
          setShowLoader(false);
          addToast(response.data.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          setTimeout(() => {
            window.location.href = "/?tab=login";
          }, 4000);
        }
        else {
          addToast("failed to reset password", {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
          setShowLoader(false);
        }
      })
      .catch(err => {
        addToast("Your password reset link has been expired", {
          appearance: "error",
          autoDismissTimeout: 2500,
        })
        Toast.hide()
      })
  };


  return (
    <form onSubmit={(e) => updatePassword(e)}>
      {showLoader ? <Loader message={showMessage} /> : null}
      <div className="input-form cnf-pass-wrap">
        <div className="cnf-inputs">
          <label className="label-text">Password</label>
          <div className="cnf-input">
            <input
              required
              minLength={6}
              maxLength={15}
              type={showP1 ? "text" : "password"}
              name="password"
              className="form-control mail-input"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i onClick={() => setShowP1(!showP1)}
              className={`toggle-password fa fa-fw  ${showP1 ? " fa-eye" : " fa-eye-slash"
                }`} />

          </div>
        </div>
        <div className="cnf-inputs">
          <label className="label-text">Confirm Password</label>
          <div className="cnf-input">
            <input
              type={showP2 ? "text" : "password"}
              required
              minLength={6}
              maxLength={15}
              name="confirmPassword"
              className="form-control mail-input"
              placeholder="Confirm-Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <i onClick={() => setShowP2(!showP2)}
              className={`toggle-password fa fa-fw  ${showP2 ? " fa-eye" : " fa-eye-slash"
                }`} />
          </div>
          {
            showErr && confirmPassword !== password && <span className="error">Password and Confirm Password doesn't match </span>
          }
        </div>
        <div className="cnf-btn">
          <button
            className="btn-signin register-email mail-button"
            type="submit"
          >
            Set Password <i className="icon-mailarrow"></i>
          </button>
        </div>
      </div>
    </form>
  );
};
export default ConfermPass;
