import React, { useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { timeFunction, getColor } from "./function";
import { isEmpty } from "lodash";

const NotificationCard = ({ data, deleteNotifi, index, read }) => {
  const history = useHistory();
  let location = useLocation();

  const showImage = (data) => {
    if (data.title === "Message Received") {
      return data.sender_data.length > 0
        ? data.sender_data[0].user_image
        : "/assets/images/avtar.png";
    }
    //   else if(data.title === "Brand Request"){
    //     return data.receiver_data[0].user_image ?
    //       data.receiver_data[0].user_image
    //       : "/assets/images/avtar.png"

    // }
    else if (data.title === "Order Placed") {
      if (data.orderType === "Buy") {
        return data.order_data.length > 0 &&
          data.order_data[0].product_detail.default_image
          ? data.order_data[0].product_detail.default_image
          : "/assets/images/avtar.png";
      } else {
        return data.order_data.length > 0 &&
          data.order_data[0].product_detail.default_image
          ? data.order_data[0].product_detail.default_image
          : "/assets/images/avtar.png";
      }
    } else {
      return !isEmpty(data.product_data)
        ? data.product_data.default_image
        : "/assets/images/avtar.png";
    }
  };

  const redirect = (data) => {
    if (data.title === "Message Received") {
      return history.push({
        pathname: "/chats/all",
        state: {
          chatPayload: {
            sender_id:
              data.sender_data.length > 0 ? data.sender_data[0]._id : null,
            product_id: data.product_data._id,
          },
        },
      });
    } else if (data.title === "Order Shipped") {
      const payload = {
        orderId: data.order_id,
        productId: data.order_data[0].product_detail._id,
      };
      return history.push({
        pathname: "/profile/order-detail",
        state: { payload },
      });
      // return history.push(`/order-detail?orderId=${data.order_id}&productId=${data.order_data[0].product_detail._id}`)
    } else if (data.title === "Order Placed") {
      if (data.orderType == "Sold") {
        var payload = {
          orderId: data.order_data[0].order_id,
          type: "Pending",
          view: "seller",
        };
        return history.push({
          pathname: `/profile/seller-order/order-detail/${data.order_data[0].order_id}/Pending`,
          state: { payload, path: location.pathname },
        });
      } else {
        var payload = {
          orderId: data.order_id,
          productId: data.order_data[0].product_detail._id,
        };

        return history.push({
          pathname: `/profile/myorder/order-detail/${data.order_id}/${data.order_data[0].order_status}`,
          state: { payload, path: location.pathname },
        });
      }
    } else if (
      data.title === "Make an Offer" ||
      data.title === "Product Add by your following seller" ||
      data.title === "Like Your Product" ||
      data.title === "Add Product" ||
      data.title === "Product Approval" ||
      data.title === "Like Your Product"
    ) {
      return history.push(
        `/product/${data.product_data.categoryData[0].name}/${data.product_data._id}/?cat=${data.product_data.categoryData[0].name}`
      );
    } else if (
      data.title === "Update Product Request" ||
      data.title === "Report Your Product"
    ) {
      return history.push(
        `/product/${data.product_data.categoryData[0].name}/${data.product_data._id}/?cat=${data.product_data.categoryData[0].name}`
      );
    } else if (data.notification_type === "OfferCreate") {
      return history.push({
        pathname: `/profile/offered-product/offer-detail`,
        state: { payload: { id: data.productId, oid: data.offerId } },
      });
    } else if (data.notification_type === "CounteredOffer") {
      return history.push({
        pathname: `/profile/my-offer/offer-detail/${data.offerId}/Pending`,
        state: { payload: { id: data.offerId } },
      });
    } else if (data.notification_type === "OfferAccepted") {
      return history.push({
        pathname: `/profile/offered-product/offer-detail`,
        state: { payload: { id: data.productId, oid: data.offerId } },
      });
    } else if (data.notification_type === "OfferPaymentLink") {
      return window.open(data.paymentLink, "_blank");
    } else if (data.notification_type === "OfferRejectedBySeller") {
      return history.push({
        pathname: `/profile/my-offer/offer-detail/${data.offerId}/Pending`,
        state: { payload: { id: data.offerId } },
      });
    } else if (data.notification_type === "OfferRejectedByBuyer") {
      return history.push({
        pathname: `/profile/offered-product/offer-detail`,
        state: { payload: { id: data.productId, oid: data.offerId } },
      });
    } else return null;
  };

  return (
    <div
      className={
        "notification-card contacts noti-listing" +
        (data.read_recipt ? "" : " gray")
      }
      onMouseEnter={() => (!data.read_recipt ? read(data._id, index) : {})}
    >
      {/* <div className="close-bar">
        <i
          className="fas fa-times-circle"
          onClick={() => deleteNotifi(data._id, index)}
        />
      </div> */}
      <div onClick={() => redirect(data)} style={{ cursor: "pointer" }}>
        <img src={showImage(data)} alt={"alt"} className="notification-img" />
        <div>
          <strong className="title" style={getColor(data.title)}>
            {data.title}
          </strong>
          &nbsp;&nbsp;
          <small>{timeFunction(new Date(data.created_at))}</small>
          {data.title === "Message Received" ? (
            <strong className="name">{` New message from ${
              data.sender_data.length > 0 ? data.sender_data[0].full_name : null
            }`}</strong>
          ) : null}
          {/* <div dangerouslySetInnerHTML={{__html: data.message}} ></div> */}
          <div className="block-tab">
            <span
              className="msg"
              dangerouslySetInnerHTML={{ __html: data.message }}
            ></span>
            {data.notificationId && data.notificationId == "2" ? (
              <Link className="link-new" to="/profile/complete_profile">
                <span style={{ fontSize: "14px" }}>Complete your profile</span>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotificationCard;
