import React from 'react'

const Policy = () => {
    return (
        <div className='privacy-policy'>
            <div className='container-main'>
                <h2>Privacy Policy</h2>
                <p>We completely understand the responsibility of protecting Your privacy. We designed this Privacy Policy to tell You about the ALLFERRO TRADEWIZE PRIVATE LIMITED (“Company”) practices regarding collection, use, and disclosure of information that You may provide for using Our Services/accessing the Platform. By using/accessing the Platform and by providing any information to Us through the Platform, You agree to the terms of this Privacy Policy.</p>

                <p>
                    This Privacy Policy of ALLFERRO TRADEWIZE PRIVATE LIMITED (Privacy Policy”) will govern Your use of the Platform and its Services. By accessing, using or signing up on the Platform or any other Services and features, You are accepting the practices described in this Privacy Policy.
                </p>

                <p>
                    In this Privacy Policy, meanings as ascribed to the terminologies in the Terms of Use shall be applicable.
                </p>

                <div className='privacy-content-wrap'>
                    <h4>Revisions to Privacy Policy:</h4>
                    <p>We reserve the right to update and revise this Privacy Policy at any time. You can determine if this Privacy Policy has been revised since Your last visit by referring to the “Effective Date of the current Privacy Policy” date at the top of this page. You should therefore review this Privacy Policy regularly to ensure that You are aware of its terms and amendments. Your use of the Platform constitutes Your acceptance of the terms of the Privacy Policy as amended or revised by Us.</p>
                    <h4>
                        General:
                    </h4>
                    <p>This Privacy Policy covers Our treatment of Information that the Company collects and receives, including Information related to Your past use of the Platform. Technology and security policies, rules and other precautions are used to shield Your Information from unlawful intruders, unauthorized access, destruction and accidental loss. Our Privacy Policy is designed to assist You in understanding how We collect, use and safeguard the Information You provide to Us and to assist You in making informed decisions when using Our Platform.</p>



                    <h4>Information collected by Us</h4>
                    <p>Our primary goal of collecting Your Information is to provide You a safe, efficient, smooth and customized experience. This allows the Company to provide Services and features that most likely meet Your needs, and to customize Our Platform to make Your experience safer and easier.</p>
                    <ul className='accordion-list-type'>
                        <p>When You visit the Platform, You may provide us with Your</p>
                        <li>Personal Information: Establishment name, Supplier or Buyer details, mobile number, address, and content which You knowingly disclose and is collected on an individual basis.

                        </li>

                        <li>Location information: For the services such as transportation of Products We collect location data of the vechiles through GPS, wifi, or cellular. We use this information to provide location tracking services.</li>

                        <li>Sensitive Financial Information: Sensitive finanacial data or information of a person means such personal information which consists of information relating to passwords, other payment instrument details, and national identifiers including but not limited to: Aadhaar card, passport number, PAN, etc.</li>

                        <li>
                            Automatic Information: We receive and store certain information such as Your location, which automatically becomes accessible to Us by virtue of You accessing the Platform on Your device. Additionally, like many other Platforms, We use Cookies and obtain certain types of information when Your device accesses the Platform or advertisements and other content served by or on behalf of the Platform.

                            <ol className='new-list'>
                                <li>
                                    Traffic monitoring;
                                </li>
                                <li>
                                    IP address from which You access the Platform;
                                </li>
                                <li>
                                    Type of device and operating system used to access the Platform;
                                </li>
                                <li>
                                    Date and time of Your access to the Platform;
                                </li>
                                <li>
                                    Pages that You visit.
                                </li>
                            </ol>
                        </li>

                        <li>
                            Stored Information: We store all data that is shared by You or by a third party on Your behalf such as image files, documents etc. either at the time of accessing the Platform or on information provided by You through social media platforms such as but not limited to WhatsApp and Instagram or at a later stage.
                        </li>
                        <li>
                            Transaction Information: We store all data related to transactions on our Platform including but not limited to price, quanitity of product sold and terms of the transaction between the Buyer and Seller .
                        </li>

                        <p>
                            Personal Information, Automatic Information and Stored Information shall individually be referred to as “Personal Information”, “Location Information” “Sensitive Financial
                            Information” “Automatic Information”, “Stored Information” “Transaction Information” and collectively as “Information”.
                        </p>

                        <p>
                        Information does not refer to any content that You may share with Us for the purposes of publishing on Our Platform. Such content shall be governed by Our Terms of Use.
                        </p>

                        <p>The User on using of the Platform and the Services provides its consent for the Company to access the Sensitive Financial Information of the User. If a User does not wish to provide consent for usage of its sensitive financial data or information or later withdraws the consent, the Company shall have the right not to provide services or to withdraw the services for which the information was sought from the User.</p>

                    </ul>
                    <h4>Use of Information:</h4>
                    <ol>
                        <li>We use Your Information for understanding users in such a way as to provide better Services of the Platform</li>
                        <li>
                        We use Your Information to customize Your experience; detect and protect Us against error, fraud and other criminal activity, enforce Our terms and conditions, and as otherwise described to You at the time of collection;

                        </li>

                        <li>
                        To send You communication, as per Your notification settings, on any updates or developments pertaining to the Platform or Your use of the Platform;
                        </li>

                        <li>
                        To provide You with technical support, including Platform updates; and
                        </li>

                        <li>
                        To verify payment information.
                        </li>

                        <li>
                        To create and provide reports, insights for Users based on the information as part of the services on the Platform. .
                        </li>
                    </ol>

                    <h4>Types of information not allowed on the Platform</h4>
                    <ul>
                        <li>
                        Nudity or other sexually suggestive content;
                        </li>
                        <li>
                        Hate speech;
                        </li>
                        <li>
                        Credible threats or direct attacks on an individual or group;
                        </li>
                        <li>
                        Content that contains self-harm or excessive violence;
                        </li>
                        <li>
                        Content that is deemed illegal by the governing laws;
                        </li>
                        <li>
                        Fake or impostor profiles; and
                        </li>
                        <li>
                        Spam.
                        </li>
                    </ul>
                    <h4>
                    Information Sharing Policy
                    </h4>
                    <ol>
                        <li>
                        Generally, the Company will not disclose personally identifiable information that We collect through Your use of the Platform to unaffiliated third parties. We reserve the right, however, to provide such information to Our affiliates, third party contractors, employees, contractors, agents, and designees to the extent necessary to enable them to perform certain Platform related Services (such as improving Platform related Services and features, or maintenance Services) on Our behalf.
                        </li>
                        <li>
                        We also reserve the right to disclose such information to any third party if We believe that We are required to do so for any or all of the following reasons: (i) by law; (ii) to comply with legal processes or governmental requests; (iii) to prevent, investigate, detect, or prosecute criminal offenses or attacks on the technical integrity of the Platform or Our network; and/or (iv) to protect the rights, property, or safety of or Our employees, the users of the Platform, the public, or You.
                        </li>
                    </ol>
                    <h4>Changes of business ownership and control:</h4>
                    <ol>
                        <li>
                        The Company may, from time to time, expand or reduce Our business and this may involve the sale and/or the transfer of control of all or part of the Company. Information provided will, where it is relevant to any part of Our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use the Information for the purposes for which it was originally given to Us.
                        </li>

                        <li>
                        We may also disclose Information to a prospective purchaser of Our business or any part of it.
                        </li>

                        <li>
                        In the above instances, we will take steps to ensure that Your privacy is protected.
                        </li>
                    </ol>
                    <h4>
                    Data retention:
                    </h4>
                    <ol>
                        <li>
                        Unless a longer retention period is required or permitted by law, we will only hold Your Information on Our systems for the period necessary to fulfil the purposes outlined in this Privacy Policy.
                        </li>
                        <li>
                        Even if We delete Your Information, it may persist on backup or archival media for legal, tax or regulatory purposes.
                        </li>
                    </ol>
                    <h4>
                    Technical and functional management of the Platform:
                    </h4>
                    <p>
                    When You use Our Platform, We may also process technical data such as Your IP address, Device ID or MAC address, and information about the manufacturer, model, and operating system of Your mobile device. We use this data to enable us to deliver the functionalities of the Platform, resolve technical difficulties, and provide You with the correct (prevent the transmission of spam, viruses and/or unlawful, defamatory, obscene or otherwise undesirable material or content) and most up to date version of the Platform.
                    </p>

                    <h4>
                    Security:
                    </h4>
                    <p>
                    The Platform takes Your security seriously and We take precautions to keep Your Information secure. We have put in place appropriate physical, electronic and managerial procedures to safeguard the information that We collect. However, due to the open communication nature of the internet, We cannot guarantee that communications between You and the Company, or information stored on Our Platform, will be free from unauthorized access by third parties. Any transmission of information by You to Our Platform is at Your own risk
                    </p>

                    <h4>
                    Legal Disclaimer:
                    </h4>

                    <p>
                    We reserve the right to disclose Your personally identifiable information as required by law and when we believe that disclosure is necessary to protect Our rights and/or to comply with a judicial proceeding, court order or legal process served on Our Platform. We may be required to share the aforesaid information with government authorities and agencies for the purposes of verification of identity or for prevention, detection, investigation including cyber incidents, prosecution and punishment of offences. You agree and consent for the Company to disclose Your Information, if so required under the applicable law.
                    </p>
                    <h4>
                    A Special Note about Children:
                    </h4>
                    <p>
                    The Services under the Platform are not directed towards children under 18 (eighteen). They are not allowed to register with or use the service or provide personal data. If We discover that We have collected personal information from a child under 18, We will delete that information at the earliest.
                    </p>

                    <h4>
                    Administrator Access:
                    </h4>

                    <p>
                    Company will have the ability to access all Customer’s End User Accounts (Buyer or Seller Accounts), including the ability to access, perform action on User’s behalf (including but not limited to, product listing, order execution and order tracking), monitor, use, modify, withhold,  or disclose any data available to End Users associated with their User accounts.
                    </p>


                    <h4>
                    Governing Law:
                    </h4>
                    <p>
                    The laws of India shall govern this Privacy Policy, regardless of Your location. You hereby expressly consent to exclusive jurisdiction and venue located in Raipur, India, for all matters or disputes arising out of or relating to the Terms of Use or Your access or use of the Platform.
                    ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE TERMS OF USE OR YOUR USE OF THE PLATFORM MUST BE BROUGHT BY YOU WITHIN ONE (1) MONTH OF THE DATE SUCH CAUSE OF ACTION FIRST AROSE, OR SUCH CAUSE OF ACTION IS FOREVER WAIVED BY YOU. EACH CAUSE OF ACTION SHALL BE ADJUDICATED INDIVIDUALLY, AND YOU AGREE NOT TO COMBINE YOUR CLAIM WITH THE CLAIM OF ANY THIRD PARTY.
                    </p>

                    <h4>
                    Grievance Redressal:
                    </h4>
                    <p>You may contact the us with any enquiry, complaints or concerns by writing to the Grievance

Officer at <a href='mailto:contact@metalsbuy.com'>contact@metalsbuy.com</a> </p>
                </div>
            </div>
        </div>
    )
}

export default Policy