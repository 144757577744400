import React, { useEffect, useState } from "react";
import "./ProductDetails.css";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { RatingStar } from "./productReview";
import { apiUrl } from "../../utils/urlEndpoints";
import { axiosInstance } from "../../utils/Service";
import { followerdata, followingdata, followStatus, unfollowStatus } from "../../store/actions/productDetails";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Toast from "light-toast";
import swal from 'sweetalert';
import Loader from "../Loader";
import { useToasts } from "react-toast-notifications";



const SellerInfo = (props) => {
  const [userId] = useState(localStorage.getItem("userId"));
  const [show, setshow] = useState(false);
  const [showing, setshowing] = useState(false);
  const [followers, setfollowers] = useState([]);
  const [followersCount, setfollowersCount] = useState(0);
  const [followingCount, setfollowingCount] = useState(0);
  const [following, setfollowing] = useState(0);
  const [isfollowing, setisfollowing] = useState(false);
  const [loginToken, setloginToken] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  let [year, setYear] = useState("");
  const [dply, setDply] = useState(true);
  const { addToast } = useToasts();


  useEffect(() => {
    const token = localStorage.getItem("token");
    setloginToken(token);
    if (!isEmpty(props.data) && !isEmpty(props.data.userDetail)) {
      const payload = { sellerId: props.data.userDetail[0]._id };
      axiosInstance
        .post(apiUrl.profile.sellerProfile, payload)
        .then((res) => {
          let data = res.data.data[0].sellerData[0].memberFrom[0];
          setYear(data);
        });
    }
    getFollowerFollowingDetail();
  }, [props.data]);


  function getFollowerFollowingDetail() {
    if (props.data.userDetail !== undefined) {
      var sellerId = props.data.userDetail[0]._id;
      const payload = {
        sellerId: sellerId,
        followType: "followers"
      }
      props.followerdata(payload, (status) => {
        if (status) {
          setfollowers(status.data.followers)
          setfollowersCount(status.data.followers.length)
          setisfollowing(status.isFollowing)
        }
      });


      const payload_ing = {
        sellerId: sellerId,
        followType: "following"
      }
      props.followingdata(payload_ing, (status) => {
        if (status) {
          setfollowing(status.following);
          setfollowingCount(status.following.length);
        }
      })
    }
  }

  function showFollower() {
    setshow(true);
  }

  function showFollowing() {
    setshowing(true)
  }

  function hideFollower() {
    setshow(false);
    setshowing(false);
  }

  function followStatus() {
    setShowLoader(true);
    setLoaderMessage("Following...");
    var sellerId = props.data.userDetail[0]._id;
    const payload = {
      sellerId: sellerId
    }
    props.followStatus(payload, (status) => {
      if (status) {
        setShowLoader(false);
        setisfollowing(status.isFollowing)
        getFollowerFollowingDetail();
      }
      else {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    })
  }

  function unfollowStatus() {
    setShowLoader(true);
    setLoaderMessage("Unfollowing...");
    var sellerId = props.data.userDetail[0]._id;
    const payload = {
      sellerId: sellerId
    }
    props.unfollowStatus(payload, (status) => {
      if (status) {
        setisfollowing(status.isFollowing);
        getFollowerFollowingDetail();
        setShowLoader(false);
      }
      else {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    })


  }



  return !isEmpty(props.data) ? (
    <div className="seller-wrap">
      {showLoader ? <Loader message={loaderMessage} /> : null}
      {/* <div className="about-seller ">
        <h4> About the seller </h4>
        <div className="about-seller-text" style={{ display: "flex" }}>
          <div className="seller-prof-img seller-proof-img">
            <img
              src={
                props.data.userDetail[0].image
                  ? props.data.userDetail[0].image
                  : "/assets/images/profile-img.png"
              }
              alt="seller1234"
            />
          </div>
          <div className="tittle-wrap">
            <h4 className="info-title">
              <Link
                to={{
                  pathname: `/seller/${props.data.userDetail[0]._id}`,
                  state: { id: props.data.userDetail[0]._id },
                }}
                className="seller-name"
              >
                {props.data.userDetail[0].name}
              </Link>
            </h4>
           
            <div className="confirmed">
              <span>Confirmed</span>
            </div>
            {props.data.all_records <= 1 ? (
              <div className="sell-items">
                <span>
                  <strong> {props.data.all_records}</strong> Item listed
                </span>
                {props.data.all_sales <= 1 ? (<span>
                  <strong> {props.data.all_sales}</strong> Sale
                </span>) : (<span>
                  <strong> {props.data.all_sales}</strong> Sales
                </span>)}

              </div>) :

              (<div className="sell-items">
                <span>
                  <strong> {props.data.all_records}</strong> Items listed
                </span>
                {props.data.all_sales <= 1 ? (<span>
                  <strong> {props.data.all_sales}</strong> Sale
                </span>) : (<span>
                  <strong> {props.data.all_sales}</strong> Sales
                </span>)}
              </div>
              )
            }
            <div className="rating-review-wrap ratings-wrapper seller-review">
              <RatingStar
                value={props.data.average_rating ? props.data.average_rating : 0}
              />
              <span className="review-count">
                <b>{props.data.all_reviews}</b> Reviews
              </span>
            </div>
          </div>
        </div>
       
      </div> */}
      <div className="msg-seller-wrap" style={{ display: "flex", flexDirection: "column" }}>
      </div>
    </div >
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    getLoginUser: state.userProfile.userProfileBanner.userdata

  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    followerdata: (payload, completion) => dispatch(followerdata(payload, completion)),
    followingdata: (payload, completion) => dispatch(followingdata(payload, completion)),
    followStatus: (payload, completion) => dispatch(followStatus(payload, completion)),
    unfollowStatus: (payload, completion) => dispatch(unfollowStatus(payload, completion))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SellerInfo)
);
