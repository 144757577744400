import React, { useState, useEffect } from "react";
// import "../../Profile.css";
import { useToasts } from "react-toast-notifications";
import { rfqRoutes } from "../profileRoutes";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  Link,
  useParams,
} from "react-router-dom";
import PageNotFound from "../../../../pageNotFound";
import Loader from "../../../Loader";
import RfqProduct from "./RfqProduct";
import { useSelector } from "react-redux";
import RfqDetail from "../../RfqDetail";
import RfqCounterOffersDetail from "../../RfqDetail/RfqCounterOfferDetails";

const RfqListing = () => {
  const userData = useSelector(store => store.userProfile.userProfileBanner.userdata);
  const userDataMain = useSelector(store => store.userProfile.userProfileBanner);
  const [notEligible, setNotEligible] = useState(false)
  const [innerLoader, setInnerLoader] = useState(false);
  const location = useLocation();
  const { addToast } = useToasts();
  const param = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  useEffect(() => {
    // setShowLoader(true);
    setShowMessage("Please wait...");
    window.scrollTo(0, 0);
  }, [location.state]);


  // useEffect(() => {
  //   let paramArr = location.pathname.split("/");
  //   if (paramArr.length > 4) {
  //     props.history.push("/not-found");
  //   }
  // }, []);

  const setLoaderState = () => {
    setShowLoader(false);
  };

  const tabRender = () => (
    <Switch>
      <Route
        path={`/profile/rfq-offers/offer-detail/:prodId/:prodType`}
        exact
        render={(prev) => (
          <RfqDetail />
        )}
      />
      <Route
        path={`/profile/rfq-offers/active`}
        exact
        render={(prev) => (
          <RfqProduct
            {...prev}
            name="Active"
            setLoaderState={setLoaderState}
            setShowLoader={setShowLoader}
          />
        )}
      />
      <Route
        path="/profile/rfq-offers/expired"
        render={(prev) => (
          <RfqProduct
            {...prev}
            name="Expired"
            setLoaderState={setLoaderState}
            setShowLoader={setShowLoader}
          />
        )}
      />
      <Route
        path="/profile/rfq-offers/offer-detail/counter-offers-details/:prodId/:sellerId"
        exact
        render={(prev) => (
          <RfqCounterOffersDetail/>
        )}
      />
      <Redirect from="/profile/rfq-offers" to="/profile/rfq-offers/active" />

      <Route component={PageNotFound} path="*" />
    </Switch>
  );

  return (
    <div style={{ position: "relative" }}>
      {showLoader ? <Loader message={showMessage} /> : null}
      <div
        className={"blogtags-sec" + (innerLoader ? " spinner-time-div" : "")}
      >
        {(location.pathname === "/profile/rfq-offers/active" || location.pathname === "/profile/rfq-offers/expired") && (
          <div className="tags-wrap">
            <div className="container">
              <div className="tags 45">
                {rfqRoutes.map((data, indx) => (
                  <Link
                    key={indx}
                    to={{
                      pathname: data.prefix + data.pathname,
                      state: data.state,
                    }}
                    className={
                      data.pathname ===
                        location.pathname.replace("/profile/rfq-offers/", "/")
                        ? "active-tab"
                        : ""
                    }
                  >
                    {data.name}
                  </Link>
                ))}
                {/* {userDataMain.isRfq} */}
                {userData && userData.userType === "Buyer" ?
                  true ? <Link to="/make-rfq-offer" className="send-rfq">+ Send request</Link> : <button onClick={() => setNotEligible(true)} className="send-rfq">+ Send request</button>
                  : null}
              </div>
            </div>
          </div>
        )}
        {
          false ? <div className='cart-page-address edit-popup-model not-eligible-popup'>
            <div className='model-behind-panel' onClick={() => setNotEligible(false)}></div>
            <div style={{height:"269px", width:'525px', overflow:"initial"}} className='login-description w-100 login-description-form-wrap address-field-wrap upload-document-wrapper place-order-address edit-popup-model-content'>
              <div className='edit-address-header'>
                <h3></h3>
                <button onClick={() => setNotEligible(false)}><i className='fas fa-times'></i></button>
              </div>
              <p>You are not eligible for this feature</p>
              <div className='navigation-back' style={{ flexDirection: "column" }}>
                <button type='button' className='next-btn' onClick={() => setNotEligible(false)}>Ok</button>
              </div>
            </div>
          </div> : null
        }
        <div className="container">{tabRender()}</div>
      </div>
    </div>
  );
};

export default RfqListing;
