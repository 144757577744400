import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from "react";

export const colorPalettes = [
  {
    name: "Anthracite",
    code: "#383E42",
  },
  {
    name: "Beige",
    code: "#F5F5DC",
  },
  {
    name: "Black",
    code: "#000000",
  },
  {
    name: "Blue",
    code: "#0000FF",
  },
  {
    name: "Brown",
    code: "#A52A2A",
  },
  {
    name: "Burgundy",
    code: "#800020",
  },
  {
    name: "Camel",
    code: "#C19A6B",
  },
  {
    name: "Ecru",
    code: "#C2B280",
  },
  {
    name: "Gold",
    code: "#FFD700",
  },
  {
    name: "Green",
    code: "#00FF00",
  },
  {
    name: "Grey",
    code: "#808080",
  },
  {
    name: "Khaki",
    code: "#F0E68C",
  },
  {
    name: "Metallic",
    code: "#AAA9AD",
  },
  {
    name: "Navy",
    code: "#000080",
  },
  {
    name: "Orange",
    code: "#FFA500",
  },
  {
    name: "Pink",
    code: "#FFC0CB",
  },
  {
    name: "White",
    code: "#FFFFFF",
  },
  {
    name: "Purple",
    code: "#800080",
  },
  {
    name: "Red",
    code: "#0000FF",
  },
  {
    name: "Silver",
    code: "#C0C0C0",
  },
  {
    name: "Turquoise",
    code: "#40E0D0",
  },
  {
    name: "Yellow",
    code: "#FFFF00",
  },
];

export const calculateDiscount = (originalPrice, sellingPrice) => {
  const result = ((originalPrice - sellingPrice) / originalPrice) * 100;
  return result % 1 === 0 ? result : result.toFixed();
};

// "&#8377;";
export const RupeesIcon = `₹`;

export const priceWithSymbol = (number) =>
  new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(
    number
  );



export const isMobile = () => (window.innerWidth <= 500) && (window.innerHeight <= 800)

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds";
};

export const SpinnerLoader = (props) => (
  <div className={"spinner-container " + (props.Style ? props.Style : "")}>
    <Loader
      type="TailSpin"
      color="#5e6df2"
      height={70}
      width={70}
    // timeout={props.time ? props.time : 3000} //3 secs
    />
  </div>
);

export const SoldSpinnerLoader = (props) => (
  <div className={"spinner-container " + (props.Style ? props.Style : "")}>
    <Loader
      type="TailSpin"
      color="black"
      height={70}
      width={70}
    />
    <label>
      <strong>
        Please Wait ...
      </strong>
    </label>
  </div>
);
export const ButtonLoader = (props) => (
  <div className="spinner-button">
    <Loader
      type="Bars"
      color="#5e6df2"
      height={props.height ? props.height : 40}
      width={props.width ? props.width : 70}
    />
    Loading
  </div>
);
export const apiElements = [
  "brand",
  "category",
  "subcategory",
  "price",
  "tagId",
  "block_name",
  "id",
  "condition",
  "shipping",
  "delivery",
  "sortby",
];


export const digitFormat = (n) => {
  if (n < 1e3) {
    return n;
  }
  if (n >= 1e3 && n < 1e6) {
    return +(n / 1e3).toFixed(1) + "K";
  }
  if (n >= 1e6 && n < 1e9) {
    return +(n / 1e6).toFixed(1) + "M";
  }
  if (n >= 1e9 && n < 1e12) {
    return +(n / 1e9).toFixed(1) + "B";
  }
  if (n >= 1e12) {
    return +(n / 1e12).toFixed(1) + "T";
  }
};
