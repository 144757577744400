import { isEmpty, get } from 'lodash';
import React, { useState, useEffect } from 'react';
import MakeAnOffer from './makeOffer';
import { useToasts } from "react-toast-notifications";
import { SpinnerLoader } from "../../../../utils/constants";
import moment from 'moment';
import { useLocation, Link, useHistory } from "react-router-dom";
import PriceReset from './priceReset';

const MyLiker = (props) => {
    const [makeOfferPopup, openPopupMakeOffer] = useState(false)
    const [priceResetPopup, setPricePopup] = useState(false)
    const [customerId, setCustomerId] = useState("")
    const [loader, setLoader] = useState(true)
    const [coupon, setCoupon] = useState({})
    const { addToast } = useToasts();
    const location = useLocation();
    const history = useHistory();
    const [ids, setIds] = useState([]);
    const [showcouponformulti, setShowcouponformulti] = useState(false)


    const generateCoupon = (e) => {

        const payload = {
            ...e,
            productId: location.search.split("=")[1],
            likerId: ids,
        }
        // return
        if (!isEmpty(coupon)) {
            payload.couponId = coupon.couponData[0]._id
            props.updateCouponCode(payload, (status) => {
                if (status) {
                    addToast(status, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                    setTimeout(() => {
                        resetState();
                    }, 2000);

                    props.openLikerPage(location.search.split("=")[1])
                }
                else {
                    addToast("Failed", {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                    setTimeout(() => {
                        resetState();
                    }, 2000);

                }
            })
        }
        else {
            props.sendCouponCode(payload, (status) => {
                if (status) {
                    addToast(status, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                    resetState();
                    props.openLikerPage(location.search.split("=")[1])
                }
                else {
                    addToast("Failed", {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                    resetState();
                }
            })
        }
        let checkboxesAll1 = document.getElementById("selectAll");
        checkboxesAll1.checked = false;
        let checkboxesAll = document.querySelectorAll("#cartBox");
        checkboxesAll.forEach(item => {
            item.checked = false;
        });
    }

    const createCouponHandler = (id) => {
        let newState = [...ids];
        newState.push(id)
        openPopupMakeOffer(true)
        setIds(newState);
    }


    const resetState = () => {
        openPopupMakeOffer(false)
        setIds([])
        setCoupon({});
    }

    useEffect(() => {
        props.openLikerPage(location.search.split("=")[1])
        setTimeout(() => setLoader(false), 1000);
    }, [])



    const handleSelectAll = e => {
        let newState = [...ids];
        let checkboxesAll = document.querySelectorAll("#cartBox");
        if (e.target.checked) {
            checkboxesAll.forEach(item => {
                item.checked = true;
                newState.push(item.value);
            });
        } else {
            checkboxesAll.forEach(item => {
                item.checked = false;
                newState = [];
            });
        }
        setIds(newState)
    };



    const handleClick = e => {
        let newState = [...ids];
        if (e.target.checked) {
            newState.push(e.target.value);
        } else {
            newState = newState.filter(item => item !== e.target.value);
        }
        let checkboxesAll = document.getElementById("selectAll");
        if (props.likerData.data.length === newState.length) {
            checkboxesAll.checked = true;
        } else {
            checkboxesAll.checked = false;
        }
        setIds(newState);
    };


    return (
        <div style={{ position: "relative" }}>
            {/* {loader ? <SpinnerLoader Style="loader-style" /> : null} */}
            <div className={loader ? " spinner-time-div" : ""}>
                {
                    makeOfferPopup &&
                    <MakeAnOffer
                        closePopup={() => {
                            openPopupMakeOffer(false);
                            setIds([]);
                            setCoupon({});
                        }}
                        sendCouponCode={generateCoupon}
                        prevData={coupon}
                    />
                }

                {
                    !isEmpty(props.likerData) ?
                        <div>
                            <div className="table-values table-value-wrapper"
                                style={{
                                    display: "flex", flexDirection: "row", justifyContent: "space-between",
                                }}>
                                {/* <h5 className="component-heading">
                                    Product Likes {props.likerData.totalData}
                                </h5> */}
                                {
                                    ids.length > 1 ?
                                        <div className="btn-value" style={{ width: "auto" }}>
                                            <button className="create-btn"
                                                onClick={() => {
                                                    openPopupMakeOffer(true)
                                                }}>
                                                Create Coupon
                                            </button>
                                        </div>
                                        : null
                                }
                                {/* <div className="btn-value" style={{ width: "auto" }}>
                                    <button className="create-btn"
                                        onClick={() => { history.push('/sell-product/' + location.search.split("=")[1]) }}>
                                        Reset Price
                                    </button>
                                </div> */}
                            </div>
                            <div className="product-liker listing-table-value my-listing-table-wrap">
                                <div className="like-box">
                                    <div className="table-head">
                                        <div className='check-wrapper'>
                                            <input
                                                type="checkbox"
                                                name="topping"
                                                id="selectAll"
                                                onClick={handleSelectAll}
                                            />
                                        </div>
                                        <div className="image-heading">Image
                                        </div>
                                        <div className="name-heading">Name/Email ID</div>
                                        <div className="redeem-heading">Used offer</div>
                                        <div className="percent-heading">Amount</div>
                                        <div className="valid-heading">Valid from</div>
                                        <div className="btn-heading">Action</div>
                                    </div>


                                    {
                                        props.likerData.data.map((data, indx) => {
                                            const couponData = data.couponData[0]
                                            return (
                                                <div className="table-content" key={indx}>
                                                    <div className="values-wrap">

                                                        <div className="table-values">
                                                            {
                                                                data.couponData.length === 0 ?
                                                                    // couponData !== [] ?
                                                                    <div className='check-wrapper'>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="topping"
                                                                            id="cartBox"
                                                                            value={data.userId}
                                                                            onClick={handleClick}
                                                                        />
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className="image-value">
                                                                <img src={
                                                                    data.userImage ?
                                                                        data.userImage :
                                                                        "/assets/images/avtar.png"
                                                                }
                                                                    alt={data.userName}
                                                                />
                                                            </div>
                                                            <div className="name-value">
                                                                <strong>{data.userName}</strong>
                                                                <span className="text">{data.userEmail}</span>
                                                                {
                                                                    data.userMobile &&
                                                                    <span className="text">{data.userMobile} </span>
                                                                }
                                                            </div>
                                                            <div className="redeem-value">
                                                                <div className="d-none">Used offer</div>
                                                                {couponData ?
                                                                    couponData.couponUsed ? "Yes" : "No"
                                                                    : "--"
                                                                }
                                                            </div>
                                                            <div className="percent-value">
                                                                <div className="d-none">Amount</div>
                                                                {
                                                                    couponData ? `${couponData.discountPercentage}%` : "--"
                                                                }
                                                            </div>
                                                            <div className="valid-value">
                                                                <div className="d-none">Valid from</div>
                                                                {
                                                                    couponData ?
                                                                        <>
                                                                            <div>
                                                                                {
                                                                                    moment(couponData.from_date).calendar({
                                                                                        sameDay: 'DD/MM/YYYY h:mm a',
                                                                                        nextDay: 'DD/MM/YYYY h:mm a',
                                                                                        nextWeek: 'DD/MM/YYYY h:mm a',
                                                                                        lastDay: 'DD/MM/YYYY h:mm a',
                                                                                        lastWeek: 'DD/MM/YYYY h:mm a',
                                                                                        sameElse: 'DD/MM/YYYY h:mm a'
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <div className="text">to</div>
                                                                            <div>
                                                                                {
                                                                                    moment(couponData.to_date).calendar({
                                                                                        sameDay: 'DD/MM/YYYY h:mm a',
                                                                                        nextDay: 'DD/MM/YYYY h:mm a',
                                                                                        nextWeek: 'DD/MM/YYYY h:mm a',
                                                                                        lastDay: 'DD/MM/YYYY h:mm a',
                                                                                        lastWeek: 'DD/MM/YYYY h:mm a',
                                                                                        sameElse: 'DD/MM/YYYY h:mm a'
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        "--"
                                                                }
                                                            </div>
                                                            <div className="btn-value">
                                                                {couponData ?
                                                                    <button className="create-btn"
                                                                        onClick={() => {
                                                                            setCoupon(data);
                                                                            setIds([data.userId]);
                                                                            openPopupMakeOffer(true);
                                                                        }}>
                                                                        Edit
                                                                    </button> :
                                                                    <button className="create-btn"
                                                                        onClick={() => createCouponHandler(data.userId)}>
                                                                        Create
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <h5>
                                <button onClick={props.back}>
                                    <i className="fas fa-long-arrow-alt-left" style={{ "fontSize": "22px" }} />
                                </button>
                                &emsp;Products Like
                            </h5>
                        </div>
                }
            </div>
        </div >
    )
}
export default MyLiker;
