import React from "react";
import { addWishList, deleteWishList } from "../../store/actions/Wishlist";
import { Authorization } from "../../utils/Authorization";
import { setLoginPopupStatus } from "../../store/actions/common";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getTotalUnreadMsgAndWishlistItem } from "../../store/actions/common";

class WishList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

  }

  addWishList = (pid) => {
    let checkLoginStatus = Authorization();
    if (checkLoginStatus.status) {
      const payload = { productId: pid };
      this.props.addWishList(payload, (status) => {
        if (status) {
          setTimeout(() => {
            this.props.getTotalUnreadMsgAndWishlistItem(callback => {

            });
          }, 1000);
          this.props.addToast("Added to wishlist", { appearance: "success" });
        }
      });

      // setTimeout(()=>window.location.reload(),1000)
    } else {
      this.props.setLoginPopupStatus({ status: true, screenToShow: "login" });
    }
  };
  removeWishList = (wishlist_id) => {
    this.props.deleteFromWishList({ wishlistId: wishlist_id });
    this.props.addToast("Removed from wishlist", { appearance: "success" });
  };
  render() {
    return this.props.wishlistId !== "" &&
      this.props.wishlistId !== undefined ? (
      <span
        className="wish"
        onClick={() =>
          this.removeWishList(this.props.wishlistId, this.props.index)
        }
      >
        <i className="icon-wishlist red-heart"></i>
      </span>
    ) : (
      <span className="wish" onClick={() => this.addWishList(this.props.pid)}>
        <i className="icon-wishlist"></i>
      </span>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addWishList: (payload, status) => dispatch(addWishList(payload, status)),
    deleteFromWishList: (payload, status) =>
      dispatch(deleteWishList(payload, status)),
    setLoginPopupStatus: (data) => dispatch(setLoginPopupStatus(data)),
    getTotalUnreadMsgAndWishlistItem: (payload) => dispatch(getTotalUnreadMsgAndWishlistItem(payload)),
  };
};

export default withToast(connect(null, mapDispatchToProps)(WishList));

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}