import React from 'react';
const SomethingWrong = (props) => {
    return (
        <div className="wrong-msg">
            <div className="wrong-image">
                <img src="\assets\images\something-went-wrong.png" alt="img" />
            </div>
            Something Went Wrong
        </div>
    )
}

export default SomethingWrong;