import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
import BuyerTestimonials from '../../Dashboard/BuyerTestimonials'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const Buyer = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
          live: true, // Enables the live mode to detect changes dynamically
        });
        wow.init();
      }, []);

    return (
        <div className="main-buyer">
            <div className="advantages-buyer">
                <img src="../../../assets/images/advantage-buyer.jpg" alt="advantages-img" />
                <div className="container-main">
                <h3>ADVANTAGES TO BUYERS</h3>
                </div>
            </div>
            <div className="insights-block">
                <div className="container-main">
                    <Accordion>
                        <div className="accordion-left">
                        <AccordionItem className="wow animate__animated animate__fadeInUp">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/insights1.png" alt="insights-img" />
                                <strong>REAL-TIME INSIGHTS</strong>
                            </div>

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Access real-time pricing and ferroalloy availability data,
enabling you to stay ahead in a rapidly changing market.
Make informed decisions based on current market
conditions.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/buyers3.png" alt="insights-img" />
                                <strong>QUALITY ASSURANCE</strong>
                            </div>

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Rest assured with every purchase, as it comes with a
Product Test Certificate. This ensures that the product
you acquire meets the specified quality standards,
minimizing the risk of getting inferior quality products.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/buyers3.png" alt="insights-img" />
                                <strong>VERIFIED SELLERS</strong>
                            </div>

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Shop with peace of mind, knowing that all sellers on our
platform undergo rigorous vetting processes. This
guarantees a marketplace composed of reliable and
trustworthy sellers.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/buyers7.png" alt="insights-img" />
                                <strong>DEDICATED SUPPORT</strong>
                            </div>

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Experience exceptional customer support that guides
you through every step of the buying process. From initial
inquiries to delivery confirmation, our support team is
there to assist you. We follow a set framework to resolve
any dispute between the parties.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                        </div>
                        <div className="accordion-right">
                        <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                            <img src="../../../assets/images/buyers2.png" alt="insights-img" />
                                <strong>BEST RATES GUARANTEED</strong>
                            </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Benefit from competitive rates ofered by reputable
sellers. Negotiate for favorable deals with confidence,
knowing you are getting the best value for your
investment.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/buyers4.png" alt="insights-img" />
                                <strong>ON-TIME DELIVERY</strong>
                            </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Stay updated about your consignment with our
advanced order tracking system. Receive timely updates
(through real-time notifications) from the moment your
order is dispatched until it reaches its destination.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            <div className="insights-img">
                                <img src="../../../assets/images/buyers6.png" alt="insights-img" />
                                <strong>SEAMLESS ORDER TRACKING</strong>
                            </div>

                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Keep a tab on your orders in real-time. Receive live
updates and enjoy a smooth, hassle-free buying
experience with convenient order tracking features.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                        </div>
                    </Accordion>
                </div>
            </div>
            <BuyerTestimonials />

            
        </div>
    )
}

export default Buyer