import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
import {Link} from "react-router-dom";

const ProductPageNew = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
          live: true, // Enables the live mode to detect changes dynamically
        });
        wow.init();
      }, []);

  return (
    <div className="products-wrap">
                <div className="container-main">
                <h2><span className='product-head'><strong>PRODUCTS</strong></span></h2>
                <div className="mainproduct-wrap">
                    <div className="products-left wow animate__animated animate__fadeInUp ">
                        <img src="../../../assets/images/product-img3.jpg" alt="product-img" />
                    </div>
                    <div className="products-right wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
                        <span className="products-name">
                        Silico Manganese
                        </span>
                        <p>Silico Manganese, a crucial alloy in metallurgy, combines silicon,
manganese, and iron to enhance steel production. With its
metallic gray appearance, it's recognized for its versatility. Silico
Manganese mainly comprises silicon (Si) and manganese (Mn),
with variable content (Si: 12%-35%, Mn: 55%-80%) tailored for
specific industrial needs. Its strategic blend of silicon and
manganese plays a vital role in improving steel alloys and
enhancing corrosion resistance and durability. For top-quality
Silico Manganese products, trust in our alloys tailored to
metallurgical industry needs, ofering reliability, durability, and
consistency in steel production processes.</p>
                       
                    </div>
                </div>
                <div className="mainproduct-wrap">
                    <div className="products-left wow animate__animated animate__fadeInUp " data-wow-delay="0.6s">
                        <img src="../../../assets/images/product-img5.jpg" alt="product-img" />
                    </div>
                    <div className="products-right wow animate__animated animate__fadeInUp " data-wow-delay="0.4s">
                        <span className="products-name">
                        Ferro Manganese
                        </span>
                        <p>Ferro Manganese is a potent alloy composed of iron and
manganese. This alloy acts as a strength booster for metals,
enhancing their toughness and resilience. Crucial in metallurgy,
Ferro Manganese improves the properties of steel and other
alloys, making them ideal for diverse industrial applications. With
a typical composition of 70-80% manganese and 20-30% iron, it
brings strength to the material. We supply Ferro Manganese to
various industries, particularly in steelmaking, where it enhances
hardness, corrosion resistance, and wear resistance, resulting in
stronger, more reliable products for construction, machinery, and
vehicles. Choose Ferro Manganese for robust and enduring
materials.</p>
                       
                    </div>
                </div>
                <div className="mainproduct-wrap">
                    <div className="products-left wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
                        <img src="../../../assets/images/product-img3.jpg" alt="product-img" />
                    </div>
                    <div className="products-right wow animate__animated animate__fadeInUp " data-wow-delay="0.4s">
                        <span className="products-name">
                        Ferro Silicon
                        </span>
                        <p>Ferro Silicon, a versatile alloy combining iron and silicon with
silicon content ranging from 15% to 90%, is vital for creating robust
and high-performance materials. With various formulations
available (15%, 45%, 75%, and 90% silicon content), Ferro Silicon is
tailored to meet specific needs. Comprising iron (Fe) and silicon
(Si), along with trace amounts of elements like aluminium (Al) and
calcium (Ca), it's indispensable for crafting durable alloys.
Whether in steel production, alloy manufacturing, or hydrogen
generation, Ferro Silicon enhances metal properties, adding
strength, corrosion resistance, and adaptability. Count on Ferro
Silicon for precision and reliability in achieving tougher and more
resilient alloys. Elevate your metals with Ferro Silicon's strength.</p>
                       
                    </div>
                </div>
                <div className="mainproduct-wrap">
                    <div className="products-left wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
                        <img src="../../../assets/images/product-img1.jpg" alt="product-img" />
                    </div>
                    <div className="products-right wow animate__animated animate__fadeInUp " data-wow-delay="0.4s">
                        <span className="products-name">
                        Ferro Chrome
                        </span>
                        <p>Ferro Chrome, derived from ‘ferro’ for iron and ‘chrome’ for
chromium, is a crucial alloy in the metallurgical industry. This
silvery-grey alloy ofers various compositions, including High
Carbon Ferro Chrome (HCFeCr) and Low Carbon Ferro Chrome
(LCFeCr). HCFeCr, with higher carbon content, finds its place in
stainless steel production, while LCFeCr, with less carbon, is
instrumental in special steel and alloy manufacturing. Comprising
iron, chromium, and trace amounts of carbon and silicon, Ferro
Chrome is a cornerstone in the steel and alloy production sector,
appreciated for its uniformity and adaptability to diverse
industrial applications.</p>
                       
                    </div>
                </div>
                <div className="mainproduct-wrap">
                    <div className="products-left wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
                        <img src="../../../assets/images/product-img4.jpg" alt="product-img" />
                    </div>
                    <div className="products-right wow animate__animated animate__fadeInUp " data-wow-delay="0.4s">
                        <span className="products-name">
                        Ferro Manganese Slag
                        </span>
                        <p>Ferro Manganese Slag, a valuable byproduct in the metal industry
available through Metalsbuy, is formed during the production of
Ferro Manganese alloys crucial for strengthening steel.
Comprising key elements like manganese, silicon, iron, aluminium,
and trace amounts of calcium, this slag boasts a versatile
chemical composition that enhances various industrial
applications. It contributes to steel quality and durability,
strengthens construction materials, aids soil conditioning in
agriculture, and plays a vital role in environmental remediation
and wastewater treatment. In summary, Ferro Manganese Slag is
a multifaceted resource with diverse industrial uses, underlining its
significance in both enhancing materials and supporting
environmental sustainability.</p>
                        
                    </div>
                </div>
                <div className="mainproduct-wrap">
                    <div className="products-left wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
                        <img src="../../../assets/images/mangenes.png" alt="product-img" />
                    </div>
                    <div className="products-right wow animate__animated animate__fadeInUp " data-wow-delay="0.4s">
                        <span className="products-name">
                        Manganese Ore
                        </span>
                        <p>Manganese Ore is used for production of Manganese based Ferro Alloys such as Silico Manganese and Ferro Manganese. Manganese Alloys are an essential input in steel making and are one of the most important metals in steel production. Manganese Ore is available in three main grades, low grade (below 35% Mn), medium grade (35-46%Mn) and high grade (above 46% Mn). Metalsbuy deals in all grades of Imported Manganese Ore.</p>
                        
                    </div>
                </div>
                </div>
            </div>
  );
};

export default ProductPageNew;
