import React, { useState, useEffect, useRef } from "react";
import { payment, confirmPayment,updateStatus} from "../../store/actions/makePayment";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Loader from "../Loader";

const MakePayment = (props) => {
  const [image, setImage] = useState([]);
  const [price, setPrice] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [recieptList, setREcieptList] = useState([]);
  const inputFileRef = useRef(null);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const onImageChange = (event) => {
    let merge = [...event.target.files, ...image];
    console.log("merge", merge);
    setImage(merge);
  };

  useEffect(() => {
    setREcieptList(props.orderData.paymentReceipts);
  }, [props.orderData.paymentReceipts]);

  const handleSubmit = () => {
    if (price == "" || image.length == 0) {
      addToast("Please enter some price and upload atleast one reciept", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
      return false;
    }
    setShowLoader(true);
    const payload = {
      orderId: props.orderData._id,
      amount: price,
    };

    const formData = new FormData();
    for (let item in payload) {
      formData.append(item, payload[item]);
    }
    for (let a = 0; a < image.length; a++) {
      formData.append("receipts", image[a]);
    }

    dispatch(
      payment(formData, (status) => {
        if (status.status) {
         dispatch(updateStatus("Awaiting Payment Confirmation"));
          props.load();
          setShowLoader(false);
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          setImage([]);
          if (inputFileRef.current) {
            inputFileRef.current.value = null;
          }
        } else {
          setShowLoader(false);
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })
    );
  };

  const handlePrice = (e) => {
    setPrice(e.target.value);
  };

  const handleConfirmation = (e, data) => {
    setShowLoader(true);
    const payload = {
      url: data.url,
      orderId: props.orderData._id,
      amount: data.amount.$numberDecimal,
    };
    dispatch(
      confirmPayment(payload, (status) => {
        if (status.status == "success") {
          setShowLoader(false);
          props.load();
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        } else {
          setShowLoader(false);
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })
    );
  };
  return (
    <>
      <div className="order-left order-delivery-location">
        {showLoader ? <Loader /> : null}
        <div className="order-left-top">
          <span className="order-title make-payment">
            {props.userType == "Buyer" ? `Payment Details` : `Payment Status`}
          </span>
          <div className="address-block-main">
            {props.userType == "Buyer" ? (
              <div class="makepaymentmain make-payment-new-wrp">
                <div className="amount-new-wrapper">
                  <div class="paymentfield payprice payment-price">
                    <label>Amount</label>
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      onChange={(e) => handlePrice(e)}
                    />
                  </div>
                  <div class="paymentfield payreceipt">
                    <label>Attach Receipt</label>
                    <div class="inputfile">
                      <div class="file-image">
                        <img
                          src="/assets/images/choose-img-icon.svg"
                          alt="icon"
                        />
                      </div>
                      <input
                        type="file"
                        name="myImage"
                        style={{ display: "block" }}
                        onChange={onImageChange}
                        ref={inputFileRef}
                        multiple
                      />
                    </div>
                  </div>
                </div>
                {image.length > 0 ? (
                  <div class="viewgallery view-uploded-img-gallery">
                    <h5>Preview</h5>
                    <div className="preview-img-wrap">
                      {image.map((item) => {
                       
                        return (
                          <div className="img-view upload-img">
                            <img
                              src={
                                (item.type === "image/jpeg" || item.type === "image/png")
                                  ? URL.createObjectURL(item)
                                  : "/assets/images/pdfIcon.png"
                              } alt=""
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                <div class="submitpay payment-submit">
                  <button
                    class="common-btn"
                    onClick={handleSubmit}
                    disabled={false}
                  >
                    Submit
                  </button>{" "}
                </div>
                {
                  props.orderData.product_detail.categoryName == "Coil" ?
                    <div class="paymentfield payreceipt" style={{}}>
                      <label style={{ fontWeight: "bold" }}>EMD Amount : {props.orderData.order_item_qty * 1000}</label>
                    </div> : null
                }
              </div>
            ) : (
              <>
                {recieptList.length > 0 ? null : (
                  <span>Please wait for the receipt</span>
                )}
              </>
            )}
            <div class="paymentgallery pay-gallery-new">
              <span className="order-title">
                {recieptList.length > 0 ? `Reciept` : ``}
              </span>
              <div className="payable-amount-wrapper new-payment-amt">
                {recieptList.map((item) => {
                  console.log("receipt",item)
                  return (
                    <div className="pay-box">
                      <a
                        href={item.url}
                        target="_blank"
                        className="pay-box-img"
                      >
                        <img src={item.url} />
                     
                      </a>
                      <div className="content-pay">
                        <label>
                          <strong>Amount:</strong>
                        </label>
                        <p>
                          &#8377; {item.amount && item.amount.$numberDecimal}
                        </p>
                      </div>
                      {props.userType == "Seller" ? (
                        <>
                          <div className="confirm-pay-wrap">
                            {item.confirmed ? (
                              <span className="confirmed-payment">
                                Confirmed Payment
                              </span>
                            ) : (
                              <button
                                onClick={(e) => handleConfirmation(e, item)}
                                className="payment-confirm-btn"
                              >
                                Confirm
                              </button>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="confirm-pay-wrap">
                          {item.confirmed ? (
                            <span className="confirmed-payment">
                              Confirmed Payment
                            </span>
                          ) : null}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakePayment;
