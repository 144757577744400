import React from "react";
import ListingProduct from "./listingProduct";
import { SpinnerLoader } from "../../../../utils/constants";
import { useLocation, Link, Switch, Route, Redirect } from "react-router-dom";
import "../../Profile.css";
import { connect } from "react-redux";
import {
  reListProd,
  removeFromReList,
  getSoldProductBySeller,
} from "../../../../store/actions/profile";
import { sellerOrderRoutes } from "../profileRoutes";
import OrderDetails from "../../../OrderDetails";
import { get } from "lodash";
// import { verifyProfile,getUserAddedProduct ,getproductList } from '../../../store/actions/profile';

const Selling = (props) => {
  const [innerLoader, setInnerLoader] = React.useState(false);
  const location = useLocation();

  const [searchOrder, setSearchOrder] = React.useState("")


  React.useEffect(() => {
    if (Object.keys(props.soldProduct).length > 0) {
      // setTimeout(() => setInnerLoader(false), 1500);
    }
  }, [props.soldProduct]);

  const getOrderData = (payload) => {
    // setInnerLoader(true);
    props.getSoldProduct(payload);
  };


  const tabRender = () => (
    <Switch>
      <Route
        path={`/profile/seller-order/order-detail/:prodId/:prodType`}
        exact
        render={(prev) => {
          return <OrderDetails {...prev} />;
        }}
      />

      {sellerOrderRoutes.map((data, key) => {
        if (data.prefix === "/profile/seller-order") {
          return (
            <Route
              path={data.prefix + data.pathname}
              render={(prev) => (
                <ListingProduct
                  myProduct={
                    props.soldProduct ? props.soldProduct[data.state.tab] : {}
                  }
                  type={data.state.tab}
                  userId={props.userId}
                  loadMore={getOrderData}
                  reListProd={props.reListProd}
                  removeFromReList={props.removeFromReList}
                  {...prev}
                />
              )}
            />
          );
        }
      })}
      <Redirect
        from="/profile/seller-order"
        to="/profile/seller-order/pending"
      />
    </Switch>
  );



  const handleSearch = () => {
    getOrderData({
      page: 1,
      type:
        location.pathname === "/profile/seller-order/pending" ? "Pending"
          : location.pathname === "/profile/seller-order/processed" ? "Processed"
            : location.pathname === "/profile/seller-order/completed" ? "Completed"
              : location.pathname === "/profile/seller-order/cancelled" ? "Cancelled" : "Pending",
      searchBy: searchOrder
    }, "")
  }

  return (
    <div style={{ position: "relative" }}>
      {/* {innerLoader ? <SpinnerLoader Style="loader-style" /> : null} */}
      <div
        className={"blogtags-sec" + (innerLoader ? " spinner-time-div" : "")}
      >
        {(location.pathname === "/profile/seller-order/pending"
          || location.pathname === "/profile/seller-order/processed"
          || location.pathname === "/profile/seller-order/completed"
          || location.pathname === "/profile/seller-order/cancelled") && (
            <div className="tags-wrap">
              <div className="container">
                <div className="tags 77 order-tabs">
                  {sellerOrderRoutes.map((data, indx) => (
                    <Link
                      key={indx}
                      to={{
                        pathname: data.prefix + data.pathname,
                        state: data.state,
                      }}
                      className={
                        data.pathname ===
                          location.pathname.replace("/profile/seller-order/", "/")
                          ? "active-tab"
                          : ""
                      }
                    >
                      {data.name}
                    </Link>
                  ))}
                  <div className="search-order">
                  {(props && props.location && props.location.state && props.location.state.tab && props.soldProduct && props.soldProduct[props.location.state.tab] && props.soldProduct[props.location.state.tab].csv) ?
                      <a href={props.soldProduct[props.location.state.tab].csv} className="download-orders" title="Download orders">
                        <i className="fas fa-download"></i>
                      </a>:
                      (props.location.pathname==="/profile/seller-order/pending" && props && props.soldProduct && props.soldProduct.Pending && props.soldProduct.Pending.csv) ? <a href={props.soldProduct.Pending.csv} className="download-orders" title="Download orders">
                      <i className="fas fa-download"></i>
                    </a>:null}
                    <input className="search-order-input" type="text" placeholder="Search order...." onChange={(e) => setSearchOrder(e.target.value)} />
                    <button className="search-btn-mob top-search-btn" onClick={handleSearch}>
                      <i className="search-btn-icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        <div className="container">{tabRender()}</div>
      </div>
    </div>
  );
};

// export default Selling;

const mapStateToProps = (state, ownProps) => {
  return {
    soldProduct: state.userProfile.soldByMe,
    userId: state.userInfo.loggedUserInfo.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reListProd: (payload, callback) => dispatch(reListProd(payload, callback)),
    removeFromReList: (payload, callback) =>
      dispatch(removeFromReList(payload, callback)),
    getSoldProduct: (payload, cllback) =>
      dispatch(getSoldProductBySeller(payload, cllback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Selling);
