import React, { Component } from "react";
import DashboardTopSection from "./DashboardTopSection";
import MyDashboard from "./DashBoard";
import SalesReport from "./SalesReport";
import "../Profile.css";
import { connect } from "react-redux";
import {
  verifyProfile,
  showSalesReportTable,
  downloadSalesReport,
  getProfileBannerInfo,
  getUserPerformancData,
  downloadReport
} from "../../../store/actions/profile";
import Toast from "light-toast";
import { SpinnerLoader } from "../../../utils/constants";
import { Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import WithdrawAmount from "./WithDrawAmount";
import WithdrawHistory from "./TransectionHistory"
import FooterTransactionHistory from "./SalesReport/Footer";
import TransactionAndFooter from "./SalesReport/TransactionAndFooter";
import TransactionHistory from "./SalesReport/indexForTransaction";
import Loader from "../../Loader";

class SellerDashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // pass this in link this.props.location.state.tab if open other tab
      selectedPage:
        this.props.location &&
          this.props.location.state &&
          this.props.location.state.tab
          ? this.props.location.state.tab
          : "MyDashboard",
      // : "Yourshop", //default open this //"Yourshop"
      page: 1,
      spinnerLoader: false,
      showLoader: false,
      loaderMessage: ""
    };
  }

  tabRender = () => (
    <Switch>
      <Route
        path='/profile/seller/dashboard'
        exact
        render={(prev) => {
          return (
            <MyDashboard
              profileInfo={this.props.userContactInfo}
              userPerformance={this.props.userPerformance}
              statisticsFilter={this.statisticsFilter}
              performanceFilter={this.performanceFilter}
              changePage={this.changePage}
              {...prev}
            />
          )
        }
        }
      />
      <Route
        path='/profile/seller/seles-report'
        exact
        render={(prev) => {
          return (
            <SalesReport
              salesReports={this.props.salesReports}
              downloadReport={this.downloadReport}
              profileInfo={this.props.userContactInfo}
              {...prev}
            />
          )
        }
        }
      />
      <Route
        path='/profile/seller/withdraw'
        exact
        render={(prev) => {
          return (
            <WithdrawAmount

              {...prev}
            />

          )
        }
        }
      />
      <Route
        path='/profile/seller/transaction-history'
        exact
        render={(prev) => {
          return (
            <WithdrawHistory
              TransactionHistory={this.props.TransactionHistory}
              downloadReport={this.downloadReport}
              transactionReportDownload={this.transactionReportDownload}
              profileInfo={this.props.userContactInfo}
              {...prev}
            />
          )
        }
        }
      />


      <Redirect from="/profile/seller" to="/profile/seller/dashboard" />
    </Switch>
  );

  componentDidMount() {
    this.setState({ showLoader: true, loaderMessage: "Please wait..." });
    window.scrollTo(0, 0);
    const userId = localStorage.getItem("userId");

    this.props.showSalesReportTable();
    this.props.getUserPerformanceData({
      userId: userId,
    });
    setTimeout(() => this.setState({ showLoader: false }), 1500);
  }

  statisticsFilter = (filterKey) => {
    this.props.filterStatisticsData({
      sortBy: filterKey,
    });
  };

  performanceFilter = (filterKey) => {
    this.props.getUserPerformanceData({
      userId: this.props.userId,
      sortBy: filterKey,
    });
  };

  downloadReport = (data) => {
    this.props.downloadSalesReport(
      { ...data },
      (callBack) => {

        var link = document.createElement("a");
        link.href = callBack;
        link.download = callBack.substr(callBack.lastIndexOf("/") + 1);
        link.click();
      }
    );
  };

  transactionReportDownload = (data) => {
    this.props.downloadReport(
      { ...data },
      (callBack) => {

        var link = document.createElement("a");
        link.href = callBack;
        link.download = callBack.substr(callBack.lastIndexOf("/") + 1);
        link.click();
      }
    );
  };

  changePage = (e) => {
    this.setState({ selectedPage: e });
  };

  verifyAccount = () => {
    const payload = {
      user_id: this.props.userId,
      message: "Please verify my account",
    };
    this.props.verifyAccount(payload, (status) => {
      if (status) {
        Toast.success("Request Sent", 1000);
      }
    });
  };

  render() {
    const { spinnerLoader } = this.state;
    // const { downlaod_app_banner } = this.props;
    return (
      <div className={!this.props.location.pathname.includes('/withdraw') ? "seller-dash" : "seller-dash-withdraw"}>
        {this.state.showLoader ? <Loader message={this.state.loaderMessage} /> : null}
        <div
          className={
            "profile-wrapper manage-profile profile-mydashboard-wrapper" +
            (spinnerLoader ? " spinner-time-div" : "")
          }
        >
          {
            !this.props.location.pathname.includes('/withdraw') &&
            <DashboardTopSection
              profileInfo={this.props.userContactInfo}
              userId={this.props.userId}
              props={this.props}
              verifyAccount={this.verifyAccount}
              userVerifyMsg={this.props.userVerifyMsg}
            />
          }
          <div className="container">{this.tabRender()}</div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.userInfo.loggedUserInfo.userId,
    myProduct: state.userProfile.userAddedProduct,
    orderlist: state.userProfile.orderlist,
    userContactInfo: state.userProfile.userProfileBanner,
    userVerifyMsg: state.userProfile.userVerifyMsg,
    salesReports: state.userProfile.salesReport,
    userPerformance: state.userProfile.userPerformanceStats,
    // downlaod_app_banner: state.cart.static_block_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyAccount: (payload, status) =>
      dispatch(verifyProfile(payload, status)),
    showSalesReportTable: () => dispatch(showSalesReportTable()),
    downloadSalesReport: (payload, callBack) =>
      downloadSalesReport(payload, callBack),
    downloadReport: (payload, callBack) =>
      downloadReport(payload, callBack),
    filterStatisticsData: (payload) => dispatch(getProfileBannerInfo(payload)),
    getUserPerformanceData: (payload) =>
      dispatch(getUserPerformancData(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SellerDashBoard);
