import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { getCountry, sendOTPHandler, verifyGSTNumber, getSeller, verifyAccountNumber, verifyPanNumber, verifyOTP, sendOtpAadhar, verifyAadharOTP, submitSellerRegistration, updateSellerRegistration, getBankDetail } from '../../store/actions/sellerRegisterationAction'
import { useToasts } from "react-toast-notifications";
import ReactCodeInput from 'react-verification-code-input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { isValid, roundToNearestMinutes } from 'date-fns';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Toast from "light-toast";
import { verifyProfile } from "../../store/actions/profile";
import Loader from '../Loader';



function AccVerifiedPopup(props) {
  let dispatch = useDispatch();
  const [countrydata, setCountryData] = useState([])
  const [stateDataArr, setStateData] = useState({})
  const [shopImg, setShopImg] = useState(null)
  const [isshopError, setIsshopError] = useState(false);

  const [chequeImg, setChequeImg] = useState(null)
  const [ischequeError, setIschequeError] = useState(false);
  const [gstPdf, setGstPdf] = useState(null)
  const [isgstpdf, setIsgstpdf] = useState(false);

  const [adharNo, setAdharNo] = useState("");
  const [mob, setMob] = useState("");
  const [checkMob, setCheckMob] = useState("");
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [MobileValidation, setMobileValidation] = useState(false);
  const [emptyMobileValid, setemptyMobileValid] = useState(false);
  const [isVerifyPan, setIsVerifyPan] = useState(false);




  const [number, setnumber] = useState("");
  const [numberError, setnumberError] = useState(false);

  const [streetnumber, setstreetnumber] = useState("");
  const [streetnumberError, setstreetnumberError] = useState(false);

  const [landmark, setlandmark] = useState("");
  const [landmarkError, setlandmarkError] = useState(false);

  const [zipcode, setzipcode] = useState("");
  const [zipcodeError, setzipcodeError] = useState(false);
  const [validZipcodeError, setValidZipcodeError] = useState(false);

  const [country, setcountry] = useState("");
  const [countryError, setcountryError] = useState(false);

  const [state, setstate] = useState("");
  const [stateError, setstateError] = useState(false);

  const [city, setcity] = useState("");
  const [cityError, setcityError] = useState(false);

  const [address, setaddress] = useState("");
  const [addressError, setaddressError] = useState(false);

  const [gstNo, setGstNo] = useState("");
  const [gstNoError, setgstNoError] = useState(false);

  const [panNo, setPanNo] = useState("");
  const [panNoError, setpanNoError] = useState(false);

  const [shopName, setshopName] = useState("");
  const [shopNameError, setshopNameError] = useState(false);

  const [adhaar, setadhaar] = useState("");
  const [adhaarError, setadhaarError] = useState(false);

  const [gstfile, setgstfile] = useState("");
  const [gstfileError, setgstfileError] = useState(false);

  const [bankName, setbankName] = useState("");
  const [bankNameError, setbankNameError] = useState(false);

  const [accountNumber, setaccountNumber] = useState("");
  const [accountNumberError, setaccountNumberError] = useState(false);
  const [validaccountNumberError, setvalidAccountNumberError] = useState(false);

  const [confirmaccountNumber, setconfirmaccountNumber] = useState("");
  const [confirmaccountNumberError, setconfirmaccountNumberError] = useState(false);

  const [ifsc, setifsc] = useState("");
  const [ifscError, setifscError] = useState(false);
  const [ifscValidError, setifscValidError] = useState(false);

  const [accountHolder, setaccountHolder] = useState("");
  const [accountHolderError, setaccountHolderError] = useState(false);

  const [password, setpassword] = useState("");
  const [passwordError, setpasswordError] = useState(false);

  const [confirmPassword, setconfirmPassword] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);

  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const [MatchAccountNumber, setMatchAccountNumber] = useState(false);
  const [MatchPassword, setMatchPassword] = useState(false);
  const [ResendOtp, setResendOtp] = useState(false);
  const [ClientId, setClientId] = useState("");
  const [AadharVerify, setAadharVerify] = useState(false);
  const [adharValidation, setadharValidation] = useState(false);
  const [profileComponent, setProfileComponent] = useState(null);
  const { addToast } = useToasts();

  const [selectedGender, setSelectedGender] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [seletectedShopImage, setSeletectedShopImage] = useState("");
  const [selectedGstPdf, setSelectedGstPdf] = useState("");
  const [selectedCheqeImage, setSelectedCheqeImage] = useState("");
  const [validGstNumber, setValidGstNumber] = useState(false);
  const [panValidError, setPanValidError] = useState("");
  const [validAdhar, setValidAdhar] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [disableEmail, setDisableEmail] = useState(false);
  const [verifiedMob, setVerifiedMob] = useState("");


  const [phoneVerified, setPhoneVerified] = useState(false);
  const [aadhaarVerified, setAadhaarVerify] = useState(false);

  const [panVerified, setPanVerified] = useState(false);

  const [isGstVerified, setIsGstVerified] = useState(false);
  const [isPanVerified, setIsPanVerified] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [isVerifyGst, setIsVerifyGst] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");



  useEffect(() => {

    props.getBankDetail({}, (status) => {

      setBankList(status);
    });

    setProfileComponent(null);
    const getToken = localStorage.getItem("token");
    if (props.location.pathname == "/sellerRegistration" && getToken !== null) {
      props.history.push("/");
    }

    if (props.location.pathname === props.path) {
      setProfileComponent(true);
      setDisableEmail(true);
      getSeller(callback => {

        setSelectedStartDate(callback[0].dob);
        setMob(callback[0].mobile);
        setCheckMob(callback[0].mobile);
        setnumber(callback[0].addressData.flat_no);
        setstreetnumber(callback[0].addressData.street);
        setlandmark(callback[0].addressData.landmark);
        setzipcode(callback[0].addressData.postcode);
        setcity(callback[0].addressData.city);
        setshopName(callback[0].companyName);
        setadhaar(callback[0].aadhar_number);
        setGstNo(callback[0].gst);
        setbankName(callback[0] && callback[0].bankData && callback[0].bankData.bankName);
        setaccountNumber(callback[0] && callback[0].bankData && callback[0].bankData.accountNo);
        setconfirmaccountNumber(callback[0] && callback[0].bankData && callback[0].bankData.accountNo);
        setifsc(callback[0] && callback[0].bankData && callback[0].bankData.ifsc);
        setaccountHolder(callback[0] && callback[0].bankData && callback[0].bankData.accountHolderName);
        setpassword(callback[0].Password);
        setSelectedGender(callback[0].gender);
        setSelectedAddress(callback[0].type);
        setSelectedState(callback[0] && callback[0].addressData && callback[0].addressData.selectedStateData && callback[0].addressData.selectedStateData._id);
        setSeletectedShopImage(callback[0].shop_image);
        setShopImg(callback[0].shop_image);
        setSelectedGstPdf(callback[0].gstFile);
        setgstfile(callback[0].gstFile);
        setSelectedCheqeImage(callback[0].cheque);
        setChequeImg(callback[0].cheque)
        setPanNo(callback[0].pan);
        setstate(callback[0] && callback[0].addressData && callback[0].addressData.selectedStateData && callback[0].addressData.selectedStateData._id);
        setconfirmPassword("confirmPassword");
        setpassword("password");

        setTerms(true);

      })(dispatch)
    }
    getCountry(callback => {
      let newState = [...callback];
      setCountryData(newState);
      setcountry(newState[0]._id)
      setStateData(newState[0]);
      setaddress("Warehouse");
    })(dispatch)

  }, [props.path, props.getUserDetail])




  const checkValidNumber = (e) => {
    if (e.target.value.length === 15) {
      Toast.loading("Please Wait...");
      const payload = {
        gstId: gstNo
      }
      props.verifyGSTNumber(payload, (status) => {
        if (status.code == 500) {
          setIsVerifyGst(false);
          Toast.hide();
          setIsGstVerified(false);
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        } else if (status.code == 200) {
          setIsVerifyGst(true);
          Toast.hide();
          setIsGstVerified(true);

          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        }

        else {

        }
      })
    }
  }


  const checkValidPanNumber = (e) => {
    if (e.target.value.length === 10) {
      Toast.loading("Pan Veryfing...");
      const payload = {
        panNumber: panNo
      }
      props.verifyPanNumber(payload, (status) => {
        if (status.code === 200) {
          setIsPanVerified(true);
          setIsVerifyPan(true);
          Toast.hide();
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        } else if (status.code === 500) {
          setIsVerifyPan(false);
          setIsPanVerified(false);
          Toast.hide();
          if (status.message == "Internal server error") {
            var message = "Invalid Pan Number"
          }
          addToast(message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })
    }
  }


  const checkValidAccountNumber = (e) => {
    if (e.target.value.length > 1) {
      //Toast.loading("Account Number Veryfing...");
      const payload = {
        accountNumber: accountNumber,
        ifsc: ifsc
      }
      props.verifyAccountNumber(payload, (status) => {
        if (status.code === 200) {
          //Toast.hide();
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        } else if (status.code === 500) {
          //Toast.hide();
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })
    }
  }


  const verifyOtpAadhar = (val) => {
    setShowLoader(true);
    setShowMessage("Verifying...");
    const payload = {
      clientId: ClientId,
      otp: val,
      mobile: 7895154317,
      aadharNumber: adhaar
    }
    props.verifyAadharOTP(payload, (status) => {
      if (status.status === "success") {
        setShowLoader(false);
        setResendOtp(false);
        setAadharVerify(true);
        setAadhaarVerify(false);
        addToast(status.message, {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
      } else {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    })
  }






  const sendOtpAadhar = () => {
    setShowLoader(true);
    setShowMessage("Sending...");
    const payload = {
      aadharNumber: adhaar
    }
    props.sendOtpAadhar(payload, (status) => {

      if (status.type == "Timeout, Please try again") {
        setShowLoader(false);
        addToast("Please try again", {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      } else {
        if (status.data && status.data.message_code === "success") {
          setShowLoader(false);
          setResendOtp(true);
          addToast(status.data.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          //setResendOtp(true);
          setClientId(status.data.data.client_id);
          setadharValidation(false);

        } else if (status.data && status.data.message === "Request failed with status code 422" || status.status === "invalid") {
          setShowLoader(false);
          addToast("please try again or Check your aadhaar number", {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
        else {
          setShowLoader(false);
          addToast(status.data[0].msg, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      }
    })
  }



  const flatnumberhandler = (e) => {
    setnumber(e.target.value);
    setnumberError(false);
  }

  const streetnumberhandler = (e) => {
    setstreetnumber(e.target.value);
    setstreetnumberError(false);
  }

  const landmarkhandler = (e) => {
    setlandmark(e.target.value);
    setlandmarkError(false);
  }

  const zipcodehandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value.length < 6) {
        setValidZipcodeError(true);
        setzipcodeError(false)
        setzipcode(e.target.value);
      } else {
        setValidZipcodeError(false);
        setzipcode(e.target.value);
        setzipcodeError(false);
      }
    }
  }

  const countryhandler = (e) => {
    setcountry(e.target.value);
    setcountryError(false);
  }

  const statehandler = (e) => {
    setstate(e.target.value);
    setstateError(false);
  }

  const cityhandler = (e) => {
    const re = /^[A-Za-z]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setcity(e.target.value);
      setcityError(false);
    }

  }

  const addresshandler = (e) => {
    setaddress(e.target.value);
    setaddressError(false);
  }

  const fileHandler = (e) => {
    setSeletectedShopImage("");
    setShopImg(e.target.files[0])
    setIschequeError(false)
  }

  const chequeHandler = (e) => {
    setSelectedCheqeImage("");
    setChequeImg(e.target.files[0])
    setIsshopError(false)
  }


  const filegstHandler = (e) => {
    setSelectedGstPdf("");
    setgstfile(e.target.files[0]);
    setgstfileError(false);
  }
  const shopnamehandler = (e) => {
    if (!(/[^A-Za-z\d\s]/.test(e.target.value))) {
      setshopName(e.target.value);
      setshopNameError(false);
    }

  }

  const gstnumberhandler = (e) => {

    if (e.target.value.length < 15) {
      if (!(/[^A-Za-z\d]/.test(e.target.value))) {
        setValidGstNumber(true);
        setGstNo(e.target.value);
        setgstNoError(false);
      }

    } else {
      if (!(/[^A-Za-z\d]/.test(e.target.value))) {
        setValidGstNumber(false);
        setGstNo(e.target.value);
        setgstNoError(false);
      }

    }

  }

  const pannumberhandler = (e) => {

    if (e.target.value.length < 10) {
      if (!(/[^A-Za-z\d]/.test(e.target.value))) {
        setPanValidError(true);
        setPanNo(e.target.value);
        setpanNoError(false);
      }

    } else {
      if (!(/[^A-Za-z\d]/.test(e.target.value))) {
        setPanValidError(false);
        setPanNo(e.target.value);
        setpanNoError(false);
      }

    }




  }



  const handleTerms = (e) => {
    setTerms(e.target.checked);
    setTermsError(false);
  }

  const adhaarhandler = (e) => {
    setResendOtp(false);
    setAadharVerify(false);
    setAadhaarVerify(false);
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value.length < 12) {

        setValidAdhar(true);
        setAadhaarVerify(false);
        setadhaar(e.target.value);
        setadhaarError(false);
      } else {
        setValidAdhar(false);
        setadhaar(e.target.value);
        setadhaarError(false);
      }

    }

  }

  const banknamehandler = (e) => {

    setbankName(e.target.value);
    setbankNameError(false);
  }

  const accountnumberhandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value.length < 9 || e.target.value.length > 18) {
        setvalidAccountNumberError(true);
        setaccountNumber(e.target.value);
        setaccountNumberError(false);
      } else {
        setvalidAccountNumberError(false);
        setaccountNumber(e.target.value);
        setaccountNumberError(false);
      }

    }

  }

  const confirmaccounthandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value == accountNumber) {
        setconfirmaccountNumber(e.target.value);
        setconfirmaccountNumberError(false);
        setMatchAccountNumber(false);
      }
      else {
        setconfirmaccountNumberError(false);
        setMatchAccountNumber(true);
        setconfirmaccountNumber(e.target.value);
      }
    }

  }

  const ifschandler = (e) => {
    if (e.target.value.length < 11) {
      if (!(/[^A-Za-z\d]/.test(e.target.value))) {
        setifscValidError(true);
        setifsc(e.target.value);
        setifscError(false);
      }

    } else {
      if (!(/[^A-Za-z\d]/.test(e.target.value))) {
        setifscValidError(false);
        setifsc(e.target.value);
        setifscError(false);
      }

    }


  }

  const accountholdernamehandler = (e) => {
    const re = /^[A-Za-z\s]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setaccountHolder(e.target.value);
      setaccountHolderError(false);
    }

  }

  const passwordhandler = (e) => {
    if (e.target.value.length < 6) {
      setValidPassword(true);
      setpassword(e.target.value);
      setpasswordError(false);
    } else {
      setValidPassword(false);
      setpassword(e.target.value);
      setpasswordError(false);
    }

  }

  const confirmPasswordhandler = (e) => {
    if (e.target.value == password) {
      setconfirmPassword(e.target.value);
      setconfirmPasswordError(false);
      setMatchPassword(false);
    } else {
      setconfirmPassword(e.target.value);
      setconfirmPasswordError(false);
      setMatchPassword(true);
    }
  }


  function isFormValid() {
    let formIsValid = true;

    if (number == "") {
      setnumberError(true);
      document.getElementById("fnumber").focus();
    } else if (streetnumber == "") {
      setstreetnumberError(true);
      document.getElementById("street").focus();
    }
    // else if (landmark == "") {
    //     setlandmarkError(true);
    //     document.getElementById("landmark").focus();
    // } 
    else if (zipcode == "") {
      setzipcodeError(true);
      setValidZipcodeError(false);
      document.getElementById("zip").focus();
    }
    else if (zipcode.length < 6) {
      setzipcodeError(false);
      setValidZipcodeError(true);
      document.getElementById("zip").focus();
    }
    //  else if (country == "") {
    //     setcountryError(true);
    //     document.getElementById("country").focus();
    // } 
    else if (state == "") {
      setstateError(true);
      document.getElementById("state").focus();
    } else if (city == "") {
      setcityError(true);
      document.getElementById("state").focus();
    }
    //  else if (address == "") {
    //     setaddressError(true);
    //     document.getElementById("address").focus();
    // }
    // else if (shopImg == null) {
    //     setIsshopError(true);
    //     document.getElementById("myFile").focus();
    // }
    else if (shopName == "") {
      setshopNameError(true);
      document.getElementById("shopname").focus();
    }
    else if (adhaar == "") {
      setadhaarError(true);
      setValidAdhar(false);
      document.getElementById("aadhar").focus();
    }
    else if (AadharVerify === false) {
      setAadhaarVerify(true);
      setValidAdhar(false);
      addToast("Please verify Aadhaar first", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
      document.getElementById("aadhar").focus();
    }
    else if (gstNo == "") {
      setgstNoError(true);
      setValidGstNumber(false);

      document.getElementById("gst").focus();
    } else if (gstNo.length < 15) {
      setgstNoError(false);
      setValidGstNumber(true);

      document.getElementById("gst").focus();
    }
    else if (isGstVerified === false) {
      addToast("Please verify GST first", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
      setValidGstNumber(false);
      setgstNoError(false);
      document.getElementById("gst").focus();
    }
    else if (panNo == "") {
      setpanNoError(true);
      setPanValidError(false);
      document.getElementById("pan").focus();
    } else if (panNo.length < 10) {
      setpanNoError(false);
      setPanValidError(true);
      document.getElementById("pan").focus();
    }
    else if (isPanVerified == false) {
      addToast("Please verify PAN first", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
      setpanNoError(false);
      setPanValidError(false);
      document.getElementById("pan").focus();
    }
    else if (gstfile == "") {
      setgstfileError(true);
      document.getElementById("gstpdf").focus();
    }
    else if (bankName == "") {
      setbankNameError(true);
      document.getElementById("bankname").focus();
    } else if (accountNumber == "") {
      setaccountNumberError(true);
      setvalidAccountNumberError(false);
      document.getElementById("accountnumber").focus();
    } else if (accountNumber.length < 9) {
      setaccountNumberError(false);
      setvalidAccountNumberError(true);
      document.getElementById("accountnumber").focus();
    }
    else if (confirmaccountNumber == "") {
      setconfirmaccountNumberError(true);
      setMatchAccountNumber(false);
      document.getElementById("confirmac").focus();
    } else if (MatchAccountNumber == true) {
      setconfirmaccountNumberError(false);
      setMatchAccountNumber(true);
      document.getElementById("confirmac").focus();
    }
    else if (ifsc == "") {
      setifscError(true);
      setifscValidError(false);
      document.getElementById("ifsc").focus();
    } else if (ifsc.length < 11) {
      setifscError(false);
      setifscValidError(true);
      document.getElementById("ifsc").focus();
    }
    else if (accountHolder == "") {
      setaccountHolderError(true);
      document.getElementById("acholder").focus();
    } else if (terms == false) {
      setTermsError(true);
      document.getElementById("term").focus();
    }
    else {
      return true;
    }
  }

  const submit = (e) => {

    e.preventDefault();
    if (isFormValid()) {
      setShowLoader(true);
      setShowMessage("Please wait ...");
      const payload = {
        flatNumber: number,
        streetNumber: streetnumber,
        landmark: landmark,
        zipCode: zipcode,
        country: country,
        state: state,
        city: city,
        gst: gstNo,
        companyName: shopName,
        aadharNumber: adhaar,
        bankName: bankName,
        accountNumber: accountNumber,
        reAccountNumber: confirmaccountNumber,
        ifscCode: ifsc,
        accountHolderName: accountHolder,
        type: address.charAt(0).toUpperCase() + address.slice(1),
        pan: panNo,
      }
      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item])
      }
      formdata.append('cheque', chequeImg);
      formdata.append('seller_image', shopImg);
      formdata.append('pdf', gstfile);


      props.verifyAccount(formdata, (status) => {

        if (status.status === "success") {
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          setShowLoader(false);
          setTimeout(() => {
            props.history.push("/");;
          }, 2000);
        } else {
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
          setShowLoader(false);
        }
      })


    }
  }

  return (

    <div className='without-login with-login'>
      {showLoader ? <Loader message={showMessage} /> : null}
      <div className='container'>
        <div className="register-popup seller-register">
          <h4>Seller Registration</h4>
        </div>
        <form onSubmit={(e) => submit(e)}>
          <div className='login-details'>
          </div>
          <div className='login-details'>
            <h3>Address</h3>
            <div className='login-description'>
              <div className='form-intput'>
                <label>flat number<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <input type="text" placeholder='Enter your flat number' onChange={(e) => flatnumberhandler(e)} id="fnumber" disabled={profileComponent} value={number} maxlength="100" />
                {numberError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter flat number"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label>street number<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <input type="text" placeholder='Enter your street number' onChange={(e) => streetnumberhandler(e)} id="street" disabled={profileComponent} value={streetnumber} maxlength="100" />
                {streetnumberError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter street number"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label>landmark</label>
                <input type="text" placeholder='Enter your landmark' onChange={(e) => landmarkhandler(e)} id="landmark" disabled={profileComponent} value={landmark} maxlength="100" />

              </div>
              <div className='form-intput'>
                <label>Pin code<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <input type="text" placeholder='Enter your pin code' onChange={(e) => zipcodehandler(e)} id="zip" value={zipcode} disabled={profileComponent} maxLength="6" />
                {zipcodeError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter pin code"}
                  </span> : null
                }

                {validZipcodeError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter 6 digit valid pin code"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label>country</label>
                <select onChange={(e) => countryhandler(e)} disabled={profileComponent}>
                  <option value="a" disabled>Please select country</option>
                  {/* <option value="b" selected>India</option> */}
                  {
                    countrydata.map(item => <option value={item._id} selected>{item.name}</option>)
                  }
                </select>

              </div>

              <div className='form-intput'>
                <label>state<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>

                <select onChange={(e) => statehandler(e)} id="state" disabled={profileComponent}>
                  <option value={""}>Please select state</option>
                  {
                    stateDataArr && stateDataArr.stateData && stateDataArr.stateData.map(item => <option value={item._id} selected={selectedState == item._id ? true : false}>{item.name}</option>)
                  }
                </select>
                {stateError ?
                  <span className="error">
                    {profileComponent ? null : "Please select state"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label>city<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <input type="text" placeholder='Enter your city' onChange={(e) => cityhandler(e)} id="city" disabled={profileComponent} value={city} maxlength="100" />
                {cityError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter city"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label>Address Type</label>
                <select onChange={(e) => addresshandler(e)} id="address" disabled={profileComponent}>
                  <option value="" disabled>Please select address type</option>
                  <option value="home" selected={selectedAddress == "Home" ? true : false}>Home</option>
                  <option value="work" selected={selectedAddress == "Office" ? true : false}>Office</option>
                  {profileComponent === null ? <option value="others" selected>Warehouse</option> : <option value="others" selected={selectedAddress == "Warehouse" ? true : false}>Warehouse</option>}

                </select>

              </div>
            </div>
          </div>
          <div className='login-details'>
            <h3>Business details</h3>
            <div className='login-description'>
              <div className='form-intput'>
                <label>shop name<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <input type="text" placeholder='Enter your shop name' onChange={(e) => shopnamehandler(e)} id="shopname" disabled={profileComponent} value={shopName} maxLength="100" />
                {shopNameError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter shop name"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label>Aadhaar number<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <div className="phone-number-field adhar-field">
                  <input
                    type="text"
                    placeholder='Enter your aadhaar number'
                    name="aadharNumber"
                    onChange={(e) => adhaarhandler(e)}
                    id="aadhar"
                    maxlength="12"
                    value={adhaar}
                    disabled={profileComponent}
                  />

                  <>
                    {!profileComponent ?
                      <>
                        {adhaar == "" ? null :
                          <>
                            {AadharVerify ? <span style={{ color: "green" }} className="verified-text">Verified</span> :
                              <span onClick={sendOtpAadhar} className="send-otp">{ResendOtp ? "Resend OTP" : "Get OTP"}</span>}
                          </>
                        }

                      </> : null
                    }

                  </>
                </div>
                {adhaarError ? <span className="error">
                  {profileComponent ? null : "Please enter aadhaar number"}
                </span> : null}
                {validAdhar ? <span className="error">
                  {profileComponent ? null : "Please enter 12 digit valid aadhaar number"}
                </span> : null}

                {ResendOtp ?
                  <div className='form-intput'>
                    <label>Enter OTP:<sup className="highlighted">*</sup></label>
                    <div className='phone-number-field111'>
                      <ReactCodeInput fields={6} onComplete={verifyOtpAadhar} />
                    </div>
                  </div> : null}
              </div>

              <div className='form-intput'>
                <label>GST number<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <div className='input_wrapper'>
                  <input
                    type="text"
                    name="gstNumber"
                    placeholder='Enter your GST number'
                    onChange={(e) => gstnumberhandler(e)}
                    onBlur={checkValidNumber}
                    id="gst"
                    disabled={profileComponent}
                    value={gstNo}
                    maxLength="15"
                  />
                  {
                    !profileComponent ?
                      gstNo == "" ? null :
                        isVerifyGst ?
                          <span style={{ color: "green" }} className="input-verified">Verified</span>
                          : null
                      : null
                  }
                </div>
                {gstNoError ? <span className="error">{profileComponent ? null : "Please enter GST number"}</span> : null}
                {validGstNumber ? <span className="error">{profileComponent ? null : "Please enter 15 digit valid GST number"}</span> : null}

              </div>
              <div className='form-intput'>
                <label>PAN number<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <div className='input_wrapper'>
                  <input
                    type="text"
                    name="panNumber"
                    placeholder='Enter your PAN number'
                    onChange={(e) => pannumberhandler(e)}
                    onBlur={(e) => checkValidPanNumber(e)}
                    id="pan"
                    disabled={profileComponent}
                    value={panNo}
                    maxLength="10"
                  />
                  {
                    !profileComponent ?
                      panNo == "" ? null :
                        isVerifyPan ?
                          <span style={{ color: "green" }} className="input-verified">Verified</span>
                          : null

                      : null
                  }
                </div>
                {panNoError ? <span className="error">{profileComponent ? null : "Please enter pan number"}</span> : null}
                {panValidError ? <span className="error">{profileComponent ? null : "Please enter 10 digit valid pan number"}</span> : null}

              </div>

              <div className='form-intput shop-gst-wrap  gst-form-input-wrapper'>
                <div className='form-intput gst-form-input-wrapper'>
                  <label>GST PDF<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                  <div className='image_valid'><span className='note-text'>Note:</span> Please select only PDF file (eg: .pdf)</div>

                  <div className="file-inputs gst-file-input"><input type="file" variant="outlined" className="file-in" name="upload" accept="application/pdf" required="" onChange={(e) => filegstHandler(e)} id="gstpdf" disabled={profileComponent} /><i className="fas fa-file-pdf"></i> &nbsp; &nbsp;

                    {selectedGstPdf ? <div className="gst-file-name gst-file-wrap ">{selectedGstPdf}</div> : <div className="gst-file-name gst-file-wrap ">{gstfile && gstfile.name}</div>}
                  </div>
                  {/* {selectedGstPdf ? <iframe src={selectedGstPdf} width="100%" height="280px">
                                    </iframe> : <iframe src={gstfile && gstfile.name} width="100%" height="300px">
                                    </iframe>

                                    } */}

                  {
                    gstfileError && <span className="error">{profileComponent ? null : "Please choose gst pdf"}</span>
                  }
                </div>
                <div className='form-intput shop-form-input-block'>
                  <label>shop image</label>
                  <div className='image_valid'><span className='note-text'>Note:</span> Please select only image file (eg: .png, .jpeg, .jpg)</div>

                  <div className="file-inputs img-file-input">
                    <input type="file" name="myImage" className="file-in" id="myFile" onChange={(e) => fileHandler(e)} disabled={profileComponent} accept=".png, .jpeg, .jpg" /><i className="fas fa-solid fa-upload"></i> &nbsp;&nbsp;
                    {seletectedShopImage ? <div className="gst-file-name  gst-file-wrap">{seletectedShopImage}</div> : <div className="gst-file-name  gst-file-wrap">{shopImg && shopImg.name}</div>}
                  </div>


                </div>
                <div className='seller-img-input'>
                  {seletectedShopImage ?

                    <div className="seller-img-wrap">
                      <img alt="not found" src={seletectedShopImage} />
                    </div>
                    :
                    <div className="seller-img-wrap">
                      {shopImg === null || shopImg === "" || typeof shopImg === 'undefined' ? null : <><img alt="not found" src={URL.createObjectURL(shopImg)}
                      /><br />
                        {
                          !profileComponent ? <button onClick={() => setShopImg(null)} className="seller-img-wrapper-remove-btn">Remove</button> : null
                        }


                      </>
                      }
                      {/* <img alt="not found" src={shopImg ? URL.createObjectURL(shopImg) : null} /> */}

                    </div>

                  }
                </div>
              </div>













            </div >
          </div >
          <div className='login-details'>
            <h3>Bank details</h3>
            <div className='login-description'>
              <div className='form-intput'>
                <label>Bank name<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                {/* <input type="text" placeholder='Enter your bank name' onChange={(e) => banknamehandler(e)} id="bankname" disabled={profileComponent} value={bankName} maxlength="100" />
                {bankNameError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter bank name"}
                  </span> : null
                } */}

                <select onChange={(e) => banknamehandler(e)} id="bankname" disabled={profileComponent}>

                  <option value={""}>Please select bank</option>
                  {
                    bankList.map(item => <option value={item.bankName} selected={bankName == item.bankName ? true : false}>{item.bankName}</option>)
                  }
                </select>
                {bankNameError ?
                  <span className="error">
                    {profileComponent ? null : "Please select bank name"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label> account number<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <input type={profileComponent ? "text" : "password"} placeholder='Enter your account number' onChange={(e) => accountnumberhandler(e)} id="accountnumber" disabled={profileComponent} value={accountNumber} maxLength="18" />
                {accountNumberError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter account number"}
                  </span> : null
                }
                {validaccountNumberError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter valid account number"}
                  </span> : null
                }
              </div>
              {!profileComponent ?
                <div className='form-intput'>
                  <label>Confirm account number<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                  <input type="text" placeholder='Confirm account number' onChange={(e) => confirmaccounthandler(e)} id="confirmac" disabled={profileComponent} value={confirmaccountNumber} maxLength="18" minLength="9" />
                  {confirmaccountNumberError ?
                    <span className="error">
                      {profileComponent ? null : "Please enter confirm account number"}
                    </span> : null
                  }
                  {MatchAccountNumber ?

                    <span className="error">
                      {profileComponent ? null : "Account Number and Confirm Account Number does not match"}
                    </span> : null}
                </div> : null
              }

              <div className='form-intput'>
                <label> IFSC<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <input type="text" placeholder='Enter your IFSC' onChange={(e) => ifschandler(e)} id="ifsc" onBlur={checkValidAccountNumber} maxlength="11" value={ifsc} disabled={profileComponent} />
                {ifscError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter IFSC code"}
                  </span> : null
                }
                {ifscValidError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter 11 digit valid IFSC code"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label>account holder name<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                <input type="text" placeholder='Enter account holder name' onChange={(e) => accountholdernamehandler(e)} id="acholder" disabled={profileComponent} value={accountHolder} maxlength="100" />
                {accountHolderError ?
                  <span className="error">
                    {profileComponent ? null : "Please enter account holder name"}
                  </span> : null
                }
              </div>
              <div className='form-intput'>
                <label>cheque Image</label>
                <div className="file-inputs">
                  <input type="file" name="myImage" className="file-in" id="cheque" onChange={(e) => chequeHandler(e)} disabled={profileComponent} accept=".png, .jpeg, .jpg" /><i className="fas fa-solid fa-upload"></i> &nbsp;&nbsp;
                  {selectedCheqeImage ? <div className="gst-file-name gst-file-wrapper ggg">{selectedCheqeImage}</div> : <div className="gst-file-name gst-file-wrapper">{chequeImg && chequeImg.name}</div>}
                </div>
                <div className='image_valid img-valiation-wrap'> <span className='note-text'>Note:</span> Please select only image file (eg: .png, .jpeg, .jpg)</div>



                {/* {chequeImg && (
                                        <div className="seller-img-wrap">
                                            <img alt="not found" src={profileComponent === null ? URL.createObjectURL(chequeImg) : chequeImg} />
                                            <br />
                                            {profileComponent === null ? <button onClick={() => setChequeImg(null)}>Remove</button> : null}

                                        </div>
                                    )} */}

                {selectedCheqeImage ?

                  <div className="seller-img-wrap">
                    <img alt="not found" src={selectedCheqeImage} />
                  </div>
                  :
                  <div className="seller-img-wrap">
                    {chequeImg === null || chequeImg === "" || typeof chequeImg === 'undefined' ? null : <>
                      <img alt="not found" src={chequeImg ? URL.createObjectURL(chequeImg) : null} /><br />
                      {!profileComponent ? <button onClick={() => setChequeImg(null)}>Remove</button> : null}
                    </>
                    }

                  </div>

                }




              </div>
            </div>

          </div>



          <>
            {profileComponent === null ?
              <div className="cond sell-policy-wrap">
                <input type="checkbox" onChange={(e) => handleTerms(e)} id="term" /> I agree to <Link target="_blank" to={{ pathname: "/page/terms", param: "Term & Condition" }} className="policy-link-wrap">Terms & Conditions</Link> and  <Link target="_blank" to={{ pathname: "/policy", param: "Privacy" }} className="policy-link-wrap">Privacy Policy</Link>.<br /><br />
                {termsError ? <span className="error">{profileComponent ? null : "You must accept our Terms & Conditions and Privacy Policy"}</span> : null}

              </div> : null
            }
            {profileComponent === null ?
              <div className="registration-btn">
                <input className="btn shop-now" type="submit" value={"Submit"} />
              </div> : profileComponent === false ?
                <div className="registration-btn">
                  <input className="btn shop-now" type="submit" value={"Update"} />
                </div> : null
            }

          </>



        </form >
      </div >
    </div >

  )

}



const mapStateToProps = (state, ownProps) => ({
  getUserDetail: state.userProfile.userProfileBanner

});

const mapDispatchToProps = (dispatch) => {
  return {
    sendOtpAadhar: (payload, completion) => dispatch(sendOtpAadhar(payload, completion)),
    verifyAadharOTP: (payload, completion) => dispatch(verifyAadharOTP(payload, completion)),
    submitSellerRegistration: (payload, completion) => dispatch(submitSellerRegistration(payload, completion)),
    verifyPanNumber: (payload, completion) => dispatch(verifyPanNumber(payload, completion)),
    verifyAccountNumber: (payload, completion) => dispatch(verifyAccountNumber(payload, completion)),
    verifyGSTNumber: (payload, completion) => dispatch(verifyGSTNumber(payload, completion)),
    updateSellerRegistration: (payload, completion) => dispatch(updateSellerRegistration(payload, completion)),
    verifyAccount: (payload, status) => dispatch(verifyProfile(payload, status)),
    getBankDetail: (payload, completion) => dispatch(getBankDetail(payload, completion)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccVerifiedPopup));
