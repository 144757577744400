import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";
const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;






export const updateAddress = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .put(apiUrl.userAddress.updateUserAddress, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const applyCouponCode = (payload, completion) => { //on Specific product
  return (dispatch) => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(apiUrl.placeOrder.couponCodeVerify, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};


export const applyProductCoupon = (payload, completion) => {
  return () => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(apiUrl.placeOrder.couponCodeVerify, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {

        if (err.response) {
          completion(err.response.data);
        }

      });
  };
};

export const applyavailableCoupon = (payload, completion) => {
  return () => {
    const token = localStorage.getItem("token");
    return axiosInstance
      .post(apiUrl.couponcode.availablecoupon, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        completion(response.data);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

// export const placeOrder = (payload, completion) => {
//   const token = localStorage.getItem("token");
//   return (dispatch) => {
//     return axiosInstance
//       .post(apiUrl.placeOrder.request, payload, {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       })
//       .then((response) => {
//         // if (response.data.response) {
//         //   const payload = {
//         //     amount: response.data.response.amount,
//         //     id: response.data.response.id,
//         //     currency: response.data.response.currency
//         //   }
//         //   completion({ status: true, ...payload });
//         // }
//         // else completion({ status: false, response });
//         completion(response.data)
//       })
//       .catch((err) => {
//         alert("v")
//         completion({ status: false });
//       });
//   };
// };


export const placeOrder = (payload, completion) => {
  const url = apiUrl.placeOrder.request;
  return (dispatch) => {
      return axiosInstance.post(url, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
          .then((response) => {
              completion(response.data)
          })
          .catch((e) => {
              if (e.response) {
                  completion(e.response.data)
              }

          });
  };
};

export const checkorderSuccess = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .get(
        "order/success" + payload,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        // completion(true)
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const checkorderCancel = (payload, completion) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .get(
        "order/cancel" + payload,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        // completion(true)
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const getCoupons = (payload, completion) => {

  const token = localStorage.getItem("token");
  return (dispatch) => {

    return axiosInstance
      .post(apiUrl.placeOrder.getCouponsEndPoint, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {

        completion(response.data);
      })
      .catch((err) => {

        completion({ status: false });
      });
  };
};


export const getuserAddress = (callback) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    return axiosInstance
      .post(
        apiUrl.userAddress.getUserAddress,
        // { userId: userId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        callback(response.data.data)
      })
      .catch((err) => { });
  };
};



// Checkout Page