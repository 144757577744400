import React from "react";
import { Link } from "react-router-dom";
const OrderProceedSuccess = (props) => {
  const changePage = () => {
    window.location.href = "/profile/myorder/pending";
  };

  return (
    <div className="order-process-success">
      <div className="container-main">
        <div className="order-wrap">
          <i className="icon-order-success"></i>
          <span className="order-id-wrap">
            Order <span className="order-id"> #2451-12489</span>{" "}
          </span>
          <h2>Thank you for your Order</h2>
        </div>
        <div className="order-process-detail">
          {/* <h3>Your Order is confirmed</h3> */}
          {/* <p>
            We will start processing your order right away. You will receive an
            email confirmation shortly on{" "}
            <a href="mailto:andrew@gmail.com" className="mail">
              andrew@gmail.com
            </a>
            . If your your email hasn’t arrived within 2 minutes, Please check
            your spam folder.
          </p> */}
          { (props.location.state.data[0].categoryData[0].name!=='Coil')?  
          <p>Your order has been received and is awaiting confirmation from the seller. Please check the order status again after some time for updates.</p>:
          <p>
            Congratulations, your order is confirmed. Please pay <span style={{fontWeight:"bold"}}>₹{1000 * props.location.state.productqty}</span> to the <span style={{fontWeight:"bold"}}>{props.location.state.data[0].userData[0].companyName}</span> You must make the payment before <span style={{fontWeight:"bold"}}>11:59</span> PM today and upload proof of payment&nbsp; <Link>
          <button onClick={changePage}>
          here
          </button>
        </Link> for the seller to process this order. Please use the bank account details below for the payment.
          </p>
        }
        </div>
        {
          props.location.state.data[0].categoryData[0].name=='Coil'  && props.location.state.data[0].bankData[0] &&
            props.location.state.data[0].bankData[0].bankName &&
            props.location.state.data[0].bankData[0].ifsc ? (
            <div className="order-process-detail">
              <div className="container order-container">
                <div className="order-top order-top-wrapper order-delivery-wrap">
                  <div className="shipping-module-wrp shipping-module-wrp-new">
                    <h2>Seller Bank Details</h2>
                    <div
                      style={{ display: "flex", marginBottom: "10px" }}
                      className="user-detail"
                    >
                      <label
                        style={{
                          marginBottom: "0",
                          fontWeight: "700",
                          color: "#000",
                          marginRight: "9px",
                          lineHeight: "1.3",
                        }}
                      >
                        Bank Name:
                      </label>
                      <p>{props.location.state.data[0].bankData[0].bankName}</p>
                    </div>
                    <div
                      style={{ display: "flex", marginBottom: "10px" }}
                      className="user-detail"
                    >
                      <label
                        style={{
                          marginBottom: "0",
                          fontWeight: "700",
                          color: "#000",
                          marginRight: "9px",
                          lineHeight: "1.3",
                        }}
                      >
                        Account Holder Name:
                      </label>
                      <p>
                        {props.location.state.data[0].bankData[0].accountHolderName}
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", marginBottom: "10px" }}
                      className="user-detail"
                    >
                      <label
                        style={{
                          marginBottom: "0",
                          fontWeight: "700",
                          color: "#000",
                          marginRight: "9px",
                          lineHeight: "1.3",
                        }}
                      >
                        Account Number:
                      </label>
                      <p>{props.location.state.data[0].bankData[0].accountNo}</p>
                    </div>
                    <div
                      style={{ display: "flex", marginBottom: "10px" }}
                      className="user-detail"
                    >
                      <label
                        style={{
                          marginBottom: "0",
                          fontWeight: "700",
                          color: "#000",
                          marginRight: "9px",
                          lineHeight: "1.3",
                        }}
                      >
                        IFSC Code:
                      </label>
                      <p>{props.location.state.data[0].bankData[0].ifsc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        <Link className="offer-btn-wrapper thankyou-page">
          <button className="accept-offer-btn" onClick={changePage}>
            View Order
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OrderProceedSuccess;
