import { axiosInstance } from "../../utils/Service";
import { apiUrl } from "../../utils/urlEndpoints";
export const shippingModule = (payload, complete) => {
    const token = (localStorage.getItem("token")? localStorage.getItem("token"):localStorage.getItem("tokenUser"));
    return (dispatch) => {
      return axiosInstance
        .post(apiUrl.shippingModule, payload, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          complete(response.data)
        })
        .catch((err) => {
          complete(err.response.data)
  
        });
    };
  };