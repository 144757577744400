import React from 'react'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <>
      <section className="blog-page-wrap">
        <div className='container-main'>
          <div className='article-wrapper'>
            <div className='card-block'>
              <Link to="/" className='blog-logo'>
                <img src="/assets/images/blog-img-event.jpg" alt="Shop Image" />
              </Link>
              <div className='card-text'>
                <span className='date'>December 19, 2023
                </span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry, Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className='card-block'>
              <Link to="/" className='blog-logo'>
                <img src="/assets/images/article-img.jpg" alt="Shop Image" />
              </Link>
              <div className='card-text'>
                <span className='date'>December 19, 2023
                </span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry, Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className='card-block'>
              <Link to="/" className='blog-logo'>
                <img src="/assets/images/blog-img-event.jpg" alt="Shop Image" />
              </Link>
              <div className='card-text'>
                <span className='date'>December 19, 2023
                </span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry, Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className='card-block'>
              <Link to="/" className='blog-logo'>
                <img src="/assets/images/metal-1.jpg" alt="Shop Image" />
              </Link>
              <div className='card-text'>
                <span className='date'>December 19, 2023
                </span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry, Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className='card-block'>
              <Link to="/" className='blog-logo'>
                <img src="/assets/images/article-img.jpg" alt="Shop Image" />
              </Link>
              <div className='card-text'>
                <span className='date'>December 19, 2023
                </span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className='card-block'>
              <Link to="/" className='blog-logo'>
                <img src="/assets/images/blog-img-event.jpg" alt="Shop Image" />
              </Link>
              <div className='card-text'>
                <span className='date'>December 19, 2023
                </span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className='card-block'>
              <Link to="/" className='blog-logo'>
                <img src="/assets/images/article-img.jpg" alt="Shop Image" />
              </Link>
              <div className='card-text'>
                <span className='date'>December 19, 2023
                </span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
            <div className='card-block'>
              <Link to="/" className='blog-logo'>
                <img src="/assets/images/blog-img-event.jpg" alt="Shop Image" />
              </Link>
              <div className='card-text'>
                <span className='date'>December 19, 2023
                </span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Blog