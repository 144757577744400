import React, { useState, useEffect } from "react";
import "../../PrivacyPolicy/PrivacyPolicy.css";
import { useLocation } from "react-router-dom";
import CareerHeader from "./CareerHeader";
import CurrentOpening from "./CurrentOpening";
import JobDesc from "./JobDesc";



const Career = () => {
  const location = useLocation();
  const [jd, setJd] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.search) {
      setJd(location.search);
    }
  }, [location.search]);



  return (
    <div className="career-section">
      <CareerHeader />
      {jd ? <JobDesc /> : <CurrentOpening />}
    </div>
  );
};

export default Career;
