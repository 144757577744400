import React, { useEffect } from "react";
import { Redirect,NavLink } from "react-router-dom";
import {
  setLoginPopupStatus,
  logout,
  getActiveStatus,
} from "../../store/actions/common";
import { Authorization } from "../../utils/Authorization";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./MainPage.css";
import { useDispatch } from "react-redux";
import Ticker from "react-ticker";
const SeconderyHeader = () => {
  let dispatch = useDispatch();
  var profileCompleted = localStorage.getItem("isProfileCompleted");
  var profileApproved = localStorage.getItem("approved");
  var rejectedReason = localStorage.getItem("reason");
  const loginInfo = Authorization();
  const [activeClass, setActiveClass] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [activeStatus, setActiveStatus] = useState("");

  if (loginInfo.status == true && window.location.pathname == "/") {
    return <Redirect to="/main" />;
  }
  if (loginInfo.status == false) {
    // TODO document why this block is empty
    document.body.classList.add('header-bg-new');
  }
  if (loginInfo.status == false && window.location.pathname == "/") {
    // TODO document why this block is empty
    document.body.classList.remove('header-bg-new');
  }
  const [menu, setMenu] = useState(false);
  const openMenu = () => {
    setMenu(!menu);
  };
  const registerOpen = (screenToShow) => {
    dispatch(
      setLoginPopupStatus({
        status: true,
        screenToShow: screenToShow,
        type: "user",
      })
    );
  };

  const logoutHandler = () => {
    logout()(dispatch);
  };
  const handleActive = (e, item) => {
    setActiveStatus(item);
    setActiveClass(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("ShippingIdToken");
    localStorage.removeItem("incomplete");
    localStorage.removeItem("isProfileCompleted");
    localStorage.removeItem("approved");
    localStorage.removeItem("pendinModal");
    localStorage.removeItem("userType");
    localStorage.removeItem("childType");
    localStorage.removeItem("subId");
    localStorage.removeItem("tokenUser");
    window.location = "/"
  }

  // useEffect(() => {
  //     if (loginInfo.status == true) {
  //         getActiveStatus({}, (status) => {
  //             if (status.length > 0 ? status[0].status == "Active" : null) {
  //             } else {
  //                 //logoutHandler();
  //             }
  //         })(dispatch)
  //     }
  // }, [])

  return (
    <>
      <section className="header custom-primary-header">
        <div className="primary-header">
          <div className="container-main">
            <Link to="/" class="logo" onClick={() => setActiveClass(false)}>
              <img
                src="/assets/images/metal-buy-logoo.png"
                alt="logo"
                title="logo"
              />
            </Link>
            <div className={menu ? "navbar-wrapper active" : "navbar-wrapper"}>
              <nav className="navbar">
                <ul className="nav-menu">
                  <li
                    className={
                      activeClass && activeStatus == "about"
                        ? "list_active"
                        : ""
                    }
                    onClick={(e) => handleActive(e, "about")}
                  >
                    <NavLink activeClassName="active" to="/page/aboutUs">About Us</NavLink>
                  </li>

                  {/* <li
                    className={
                      activeClass && activeStatus == "contact"
                        ? "list_active"
                        : ""
                    }
                    onClick={(e) => handleActive(e, "contact")}
                  >
                    <Link to="/contactUs">Contact Us</Link>
                  </li>
 */}
                  {/* <li className={activeClass && activeStatus == "faq" ? "list_active" : ""} onClick={(e) => handleActive(e, "faq")}>
                                        <Link to="/faq">FAQ</Link>
                                    </li> */}

                  <li
                    className={
                      activeClass && activeStatus == "product"
                        ? "list_active"
                        : ""
                    }
                    onClick={(e) => handleActive(e, "product")}
                  >
                    <NavLink activeClassName="active" to="/product-list">Products</NavLink>
                  </li>
                  <li
                    className={
                      activeClass && activeStatus == "buyer"
                        ? "list_active"
                        : ""
                    }
                    onClick={(e) => handleActive(e, "buyer")}
                  >
                    <NavLink activeClassName="active" to="/buyer">Buyers</NavLink>
                  </li>
                  <li
                    className={
                      activeClass && activeStatus == "seller"
                        ? "list_active"
                        : ""
                    }
                    onClick={(e) => handleActive(e, "seller")}
                  >
                    <NavLink activeClassName="active" to="/seller">Sellers</NavLink>
                  </li>
                </ul>
              </nav>

              {(profileCompleted == "false" || profileCompleted == "true") &&
              loginInfo.status == true &&
              profileApproved !== "accept" ? (
              <div className="signup-login-wrapper">
                <button
                  className="login-btn main-btn"
                  onClick={() => logoutHandler()}
                >
                  Logout /{" "}
                </button>
                <Link to="/complete_profile_now">
                  <button className="sign-up-btn main-btn"> My Account</button>
                </Link>
              </div>
            ) : (
              <div className="signup-login-wrapper">
                {localStorage.getItem("childType") === "order" ? <div className="child-user-header">
                  <Link to="/process-orders" className="login-btn main-btn order-child">Orders</Link>
                  <button
                    className="login-btn main-btn logout-child-user"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div> : <button
                  className="login-btn main-btn"
                  onClick={() => registerOpen("login")}
                >
                  Login
                </button>}
                {/* <button className="sign-up-btn main-btn" onClick={() => registerOpen("sign-up")}>Sign Up</button> */}
              </div>
            )}

            </div>

          

            <button class="mob-menu" onClick={openMenu}>
              <span class="menu-bar"></span>
              <span class="menu-bar"></span>
              <span class="menu-bar"></span>
            </button>
          </div>
        </div>
        {/* <div className='header-msg'>
                    <div className='news-header'>
                        <div className='latest-news'>
                            <div className='ltl-news'>
                                <div className='news-content-wrap'>
                                    <div className='news-logo'>
                                        <img src="/assets/images/news-icon.svg" alt="news-logo" />
                                    </div>
                                    <h3>Latest news :</h3>
                                </div>
                            </div>
                        </div>
                        <div className='container-main'>
                            <div className='ticker-wrapper'>
                                <Ticker>
                                    {({ index }) => (
                                        <>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.Egestas odio nisl, diam, a pellentesque faucibus egestas.Habitant varius a sit tincidunt fames congue elementum et.
                                            </p>
                                        </>
                                    )}
                                </Ticker>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div>
          {loginInfo.status == true &&
            profileApproved == "pending" &&
            profileCompleted == "true" ? (
            <div className="wait-for-approve">
              <span className="approve-text">
                *Reason for rejection: {rejectedReason}*
              </span>
            </div>
          ) : loginInfo.status == true &&
            profileApproved == "NA" &&
            profileCompleted == "false" ? (
            <div className="wait-for-approve">
              <span className="approve-text">
                *Reason for rejection: {rejectedReason}*
              </span>
            </div>
          ) : loginInfo.status == true &&
            profileApproved == "NA" &&
            profileCompleted == "false" ? (
            <div className="wait-for-approve">
              <span className="approve-text">
                *Please complete your profile*
              </span>
            </div>
          ) : loginInfo.status == true &&
            profileApproved == null &&
            profileCompleted == "true" ? (
            <div className="wait-for-approve">
              <span className="approve-text">
                *Please complete your profile*
              </span>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};
export default SeconderyHeader;
