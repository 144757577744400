import React, { useState } from "react";
import { getShipping } from "../../store/actions/productDetails";
import { useDispatch } from "react-redux";
import Loader from "../Loader/index";

const ShippingBuyer = (props) => {
  const [driverName, setDriverName] = useState("");
  const [lotSize, setLotSize] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [showErrorName, setShowErrorName] = useState(false);
  const [showErrorNumber, setShowErrorNumber] = useState(false);
  const [pickupDate, setPickupDate] = useState("");
  const [showErrorDate, setShowErrorDate] = useState(false);
  const [showErrorDriverNumber, setShowErrorDriverNumber] = useState(false);
  const [showErrorImage, setShowErrorImage] = useState(false);
  const [showErrorLotSize, setShowErrorLotSize] = useState(false);
  const [showErrorFile, setShowErrorFile] = useState(false);
  const [showErrorUrl, setShowErrorUr] = useState(false);
  const [img, setImg] = useState("");
  const [fileAttach, setFileAttach] = useState({});
  const [imageAttach, setImageAttach] = useState({});
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [inputFields, setInputFields] = useState([{ vehicle: "" }]);

  const [show, setShow] = useState(true);

  const imgHandler = (e, index) => {
    setImageAttach({ ...imageAttach, [index]: e.target.files[0] });
    let src_ = URL.createObjectURL(e.target.files[0]);
    setImg(src_);
    setShowErrorImage(false);
  };
  const fileHandler = (e, index) => {
    setFileAttach({ ...fileAttach, [index]: e.target.files[0] });
    let src_ = URL.createObjectURL(e.target.files[0]);
    setImg(src_);
    setShowErrorFile(false);
  };

  const fileTypes = [
    "Lorry Receipt",
    "GST invoice",
    "Weighment slip",
    "Material Test Certificate",
    "Ewaybill",
  ];

  const handleRemoveSingle = () => {
    setShow(false);
  };

  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        title: "",
        description: "",
        location: "",
      },
    ]);
  };

  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleDriverName = (e) => {
    setDriverName(e.target.value);
    setShowErrorName(false);
  };

  const handleLotSize = (e) => {
    setLotSize(e.target.value);
    setShowErrorLotSize(false);
  };

  const handleDriverNumber = (e) => {
    setDriverNumber(e.target.value);
    setShowErrorDriverNumber(false);
  };

  const handleVehicleNumber = (e) => {
    setVehicleNumber(e.target.value);
    setShowErrorNumber(false);
  };

  const handlePickupDate = (e) => {
    setPickupDate(e.target.value);
    setShowErrorDate(false);
  };

  const handleSubmitInitial = (e) => {
    e.preventDefault();
    if (lotSize == "") {
      setShowErrorLotSize(true);
      return false;
    }
    if (pickupDate == "") {
      setShowErrorDate(true);
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression);

    if (lotSize == "") {
      setShowErrorLotSize(true);
      return false;
    }
    if (driverName == "") {
      setShowErrorName(true);
      return false;
    }
    if (driverNumber == "") {
      setShowErrorDriverNumber(true);
      return;
    }
    if (vehicleNumber == "") {
      setShowErrorNumber(true);
      return;
    }
    if (pickupDate == "") {
      setShowErrorDate(true);
      return;
    }

    if (Object.keys(fileAttach).length < 5) {
      setShowErrorFile(true);
      return;
    } else if (Object.keys(imageAttach).length < 5) {
      setShowErrorImage(true);
      return;
    } else {
      setShowLoader(true);
      setShowMessage("Please wait...");
      let payload = {
        driverName: driverName,
        vehicleNumber: vehicleNumber,
        message: message,
        url: url,
        orderId: props.orderData._id,
      };
      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item]);
      }
      formdata.append("docs", fileAttach);
      formdata.append("images", imageAttach);
      // dispatch(
      //   getShipping(formdata, (callback) => {
      //     props.load();
      //     setShowErrorName(false);
      //     setShowErrorNumber(false);
      //     setShowErrorImage(false);
      //     setShowErrorUr(false);
      //     setShowLoader(false);
      //   })
      // );
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 2);
  const minDate = tomorrow.toISOString().split("T")[0];

  return (
    <>
      {show &&
        props.orderData.isShipping === true &&
        props.userType === "Buyer" ? (
        <div className="shipping-module-wrp">
          <div className="form-output-wrapper">
            <div className="value-wrap">
              <span className="name">vehicle Name : </span>
              <span className="value">
                {props.orderData.shippingData.vehicleName}
              </span>
            </div>

            <div className="value-wrap">
              <span className="name">vehicle Number : </span>
              <span className="value">
                {props.orderData.shippingData.vehicleNumber}
              </span>
            </div>

            <div className="value-wrap">
              <span className="name">Message : </span>
              <span className="value">
                {props.orderData.shippingData.message}
              </span>
            </div>

            <div className="value-wrap">
              <span className="name">Url : </span>
              <span className="value">{props.orderData.shippingData.url}</span>
            </div>

            <div className="value-wrap">
              <span className="name">Receipt : </span>
              <span className="value">
                <div className="reciept-block-img">
                  <img
                    src={props&&props.orderData&&props.orderData.shippingData&&props.orderData.shippingData.docs&&props.orderData.shippingData.docs[0]&&props.orderData.shippingData.docs[0].url}
                    alt="receipt-img"
                  />
                </div>
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ShippingBuyer;
