import React, { useEffect, useState } from "react";
import Card from "./Card";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { parse } from "query-string";
import { withRouter } from "react-router-dom";
import { ButtonLoader } from "../../utils/constants";
import { isEmpty } from '../../utils/commonFunctions'
import ListingPagination from "../ListingPagination/ListingPagination";
import {useLocation} from "react-router-dom";


const ProductScreen = ({ filter_data, location, loadMoreProd, clearAll, getWishCount }) => {
  let query = parse(location.search);


  const [state, setState] = useState({
    page: 1,
    selectedAttr: [],
    filter_data: {},
    newFilter: {},
    buttonLoader: false,
  })
const locationQuery = useLocation();
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage, setRecordPerPage] = useState(10);
const [currentRecords, setCurrentRecords] = useState({});
const [nPages, setNPages] = useState(0);



  const findIsApplied = () => {
    let findIsAppliedFilter = Object.keys(query).filter(
      item => query[item] !== "" &&
        item !== "block_name" &&
        item !== "lat" &&
        item !== "long" &&
        item !== "subcategory" &&
        item !== "searchValue" &&
        item !== "zip" &&
        item !== "shipping" &&
        item !== "radius" &&
        item !== "deliveryZipCode" &&
        item !== "productId" &&
        item !== "categoryId" &&
        item !== "range" &&
        item !== "category" &&
        item !== "id" &&
        item !== "tagId" &&
        item !== "sortby" &&
        item !== "deliveryZipCode" &&
        item !== "type" &&
        item !== "result" &&
        item !== "productId" &&
        item !== "range"
    )
    return findIsAppliedFilter
  }

  useEffect(()=>{
    if(filter_data.data != undefined){
     const indexOfLastRecord = currentPage * recordsPerPage;
     const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
     const currentRecords = filter_data.data.slice(indexOfFirstRecord, indexOfLastRecord);
     setCurrentRecords(currentRecords);
     const nPages = Math.ceil(filter_data.data.length / recordsPerPage);
     setNPages(nPages);
    }
  },[filter_data, currentPage])
  
  useEffect(()=>{
     setCurrentPage(1);
  }, locationQuery.state && locationQuery.state.categorie)



  return (
    <>
      <div className={"plp-wrap plp-right-wrapper"}>

        {
          findIsApplied().length > 0 && (
            <div className="attr-bar-wrap">
              <div className="category-filter-tag" onClick={() => clearAll()}>
                <i className="fa fa-times" aria-hidden="true"></i>
                Clear all
              </div>
            </div>
          )
        }


        <div className={filter_data.TotalProducts === 0 ? "no-product-wrap" : "plp-product-screen plp-product-screen-wrap"}>
          {!isEmpty(filter_data) ?
          <>
          {
          Object.keys(currentRecords).length != 0 ? currentRecords.map((item, i) =>
              <Card
                key={i}
                {...item}
                query={query}
                getWishCount={getWishCount}

              /> 
            ) : null}
          </>
            
            : (
              <React.Fragment>
                {[...Array(5)].map(() => (
                  <SkeletonLoader
                    width="31%"
                    height="25em"
                    style={{ margin: "1%" }}
                  />
                ))}
              </React.Fragment>
            )}
          {filter_data.TotalProducts === 0 ? (
            <div className="plp-loadMore-div no-product-wrapper">
              <div className="no-product">
                <img src="/assets/images/no-product.png" alt="no-found" />
              </div>
              <h4>Sorry, no product found...</h4>
            </div>
          ) : null}
        </div>

        {filter_data.data != undefined ? 
      <>
      {filter_data.data.length <= 10 ? 
      null : 
      <ListingPagination
      nPages = { nPages }
      currentPage = { currentPage } 
      setCurrentPage = { setCurrentPage }
      />
    }
      </>  : null
      }
       
      </div>
    </>
  );
}


export default withRouter(ProductScreen);
