import React from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

function SortBy({ stateData }) {
  let param = useParams();
  let location = useLocation();
  let history = useHistory();

  const handleSorted = (e) => {
    let obj;
    if (Object.keys(stateData).length != 0) {
      stateData.sortby = e.target.value;
      stateData.category = param.category;
      obj = stateData;
    } else if ("categorie" in location.state) {
      stateData.sortby = e.target.value;
      stateData.category = param.category;
      obj = stateData;
    } else {
      obj = {
        category: param.category,
        price: [],
        quantity: [],
        for: [],
        paymentType: [],
        seller: [],
        deliveredRegion: "",
        deliveredCity: [],
        deliveredBy: "",
        sortby: e.target.value,
        product_data: [],
        rating: [],
      };
    }
    //const productList = [...productData, e.target.value];
    history.push({
      pathname: `/listing/${param.category}`,
      state: obj,
    });
  };

  return (
    <>
      <select onChange={(e) => handleSorted(e)}>
        <option>Select</option>
        <option value="Sort-By-Lowest-Price-First">
          Sort By Lowest Price First
        </option>
        <option value="Sort-By-Highest-Price-First">
          Sort By Highest Price First
        </option>
        <option value="Sort-By-Newest-Price-First">Sort By Newest First</option>
      </select>
    </>
  );
}

export default SortBy;
