import React, { useState } from "react";

const ListingPagination = (props) => {
  const [activeData, setActive] = useState(false);
  const [indexData, setIndexData] = useState(0);
  const pageNumbers = [...Array(props.nPages + 1).keys()].slice(1);
  const handlePrevious = () => {
    if (props.currentPage !== 1) props.setCurrentPage(props.currentPage - 1);
  };

  const handleNext = () => {
    if (props.currentPage !== props.nPages)
      props.setCurrentPage(props.currentPage + 1);
  };

  const numberHandle = (item, index) => {
    setIndexData(index);
    props.setCurrentPage(item);
    setActive(true);
  };
  return (
    <div className="listing-pagination">
      <div class="b-pagination-outer">
        <ul className="border-pagination">
          <li>
            <a class="prev-list" onClick={() => handlePrevious()}>
              Previous
            </a>
          </li>
          {pageNumbers.map((item, index) => {
            return (
              <li>
                <a
                  onClick={() => numberHandle(item, index)}
                  className={`list-item ${
                    indexData == index ? "active-list" : ""
                  }`}
                >
                  {item}
                </a>
              </li>
            );
          })}

          <li>
            <a className="next-list" onClick={() => handleNext()}>
              Next
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ListingPagination;
