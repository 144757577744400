import React, { Component } from "react";
import Slider from "react-slick";
import { withRouter, Link } from "react-router-dom";
import Card from "../Dashboard/Card";
import { isEmpty, get } from "lodash";


class SimilarProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      productData: [],
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    const wishlist = props.wishlist;
    const propsLength = get(props, ["relatedProduct", "data", "length"], 0);
    const stateLength = state.productData.length;
    if (
      propsLength === stateLength &&
      stateLength !== 0 &&
      wishlist.length === 0
    ) {
      return { ...state };
    }
    const ProductDataArr = get(props, ["relatedProduct", "data"], []);
    const structuredProductData = ProductDataArr.map((item) => {
      let wishlist_id = "";
      wishlist.length !== 0 &&
        wishlist.map((data) => {
          if (data.product_id === item._id) {
            wishlist_id = data.wishlist_id;
          }
        });

      return {
        ...item.Price,
        quantity: item.Quantity,
        categorySlug: item.categoryData.slug,
        categoryName: item.categoryData.name,
        brandSlug: item.brandData.slug,
        brandName: item.brandData.name,
        title: item.Title,
        deletedAt: item.deletedAt,
        Moderate: item.Moderate,
        mainImage: `${item.default_image}`,
        extra_img: (item.Image[1] && item.Image[1].original) || null,
        id: item._id,
        is_Auction: item.is_Auction,
        auctions_data: item.auctions_data,
        wishlistIcon: true,
        wishlist_id: wishlist_id,
        delievery_charge: item.delievery_charge,
      };
    });
    return {
      ...state,
      productData: structuredProductData,
    };
  };

  render() {
    const { block_name, heading, relatedProduct } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      afterChange: (current) => this.setState({ current: current }),
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return !isEmpty(relatedProduct) &&
      relatedProduct &&
      relatedProduct.length > 0 ? (
      <div className="arrival-costumes bbb">
        {
          this.props.details &&
          <div className="arrival-caption arrival-costumes-wrapper similar-product-wrap">
            <h2>{heading}</h2>
            {
              block_name === "Similar product" ? (
                // <Link
                //   to={`/product-listing-page/block?result=${block_name}&productId=${this.props.details.data[0]._id}&categoryId=${this.props.details.data[0].categoryData[0]._id}&range=${this.props.details.data[0].Price.current_store_price}`}
                //   className="shop-now view-all-btn-wrap"
                // >
                //   View all
                // </Link>
                <></>
              ) : block_name === "More result" ? (
                // <Link
                //   to={`/product-listing-page/block?result=${block_name}&productId=${relatedProduct.productId}&seller=${relatedProduct.userId}`}
                //   className="shop-now view-all-btn-wrap"
                // >
                //   View all
                // </Link>
                <></>
              ) : null
            }
          </div>
        }

        <div className="popular-costumes popular-costume-slider">
          <Slider {...settings} ref={(c) => (this.slider = c)} className="similar-items">
            {relatedProduct.slice(0, 20).map((data, index) => (
              <Card key={index} {...data} />
            ))}
          </Slider>
        </div>
      </div>
    ) : null;
  }
}

export default withRouter(SimilarProduct);
