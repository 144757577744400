import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./cart.css";
import { getCart } from "../../store/actions/cartAction";
import CartData from "./CartData";
import Loader from "../Loader";


const CartContainer = () => {
  let dispatch = useDispatch();
  let cartData = useSelector(store => store.cart.getcart);
  let static_block = useSelector(store => store.cart.static_block_data);


  const [showLoader, setShowLoader] = useState(false);



  useEffect(() => {
    setShowLoader(true);
    getCart()(dispatch)
    setTimeout(() => setShowLoader(false), 1500)
  }, [])

  return (
    <div className="whitebg">
      {showLoader ? <Loader message="Please Wait..." /> : null}
      <div className="container">
        {cartData && cartData.length !== 0 ? (
          <div className="cart-caption cart-wrap">
            <h2> {cartData.length} item in your cart </h2>
            {/* <h6>Taxes may apply for certain state </h6> */}
          </div>
        ) : null}

        <CartData
          cartData={cartData}
        />
      </div>
    </div>
  )
}
export default CartContainer;