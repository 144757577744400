import React, { useEffect } from "react";
import "../../ProductDetail/ProductDetails.css";
import { connect } from "react-redux";
import { checkorderCancel } from "../../../store/actions/checkout";

const OrderCancel = (props) => {


  useEffect(() => {
    const userId = localStorage.getItem("userId");
    let query = props.location.search + "&customer_id=" + userId;
    props.checkorderCancel(query, (data) => {

    });
  }, []);

  const handlePopup = () => {
    props.history.push("/checkout");
  };


  return (
    <div className="make-offer-popup custom-popup">
      <div className="offer-content">
        <button
          className="modal-closeBtn"
          onClick={() => handlePopup()}
          type="button"
        >
          <i className="fal fa-times-circle"></i>
        </button>
        <div className="offer-title">
          The Transaction has failed and your order could not be processed.
          Please try again !!
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkorderCancel: (payload, data) =>
      dispatch(checkorderCancel(payload, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCancel);
