import React from "react";
import Slider from "react-slick";

function Testimonial(props) {
  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="client-testimonial-wrap">
        <div className="container-main">
          <h3>Client’s Testimonials</h3>
          <div className="testimonial-slide">
            <div className="testimonial-wrapper">
              <Slider {...settings}>
                {Object.keys(props.data).length !== 0
                  ? props.data.testimonials.map((item) => {
                      var cleanText = item.content.replace(
                        /<\/?[^>]+(>|$)/g,
                        ""
                      );
                      return (
                        <div className="testimonial-box">
                          <div className="user-type-wrap">
                            <img src={item.image} alt="user-type" />
                          </div>
                          <h4>{item.name}</h4>
                          <span className="client-company">
                            ABC Pvt. Ltd., Seller{" "}
                          </span>
                          <p>{cleanText}</p>
                        </div>
                      );
                    })
                  : null}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
