import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
const Timeline = () => {
	useEffect(() => {
		const wow = new WOW.WOW({
		  live: true, // Enables the live mode to detect changes dynamically
		});
		wow.init();
	  }, []);

  return (
	<div className='timeline-wrap'>
		<div className="container">
			<span className="timeline-head">timeline</span>
			<div className="show-timeline">
				<div className="timeline-first">
					<div className="timeline-right wow animate__animated animate__fadeInUp " data-wow-delay="0.10s">
						<span className="timeline-show">
							<img src="../../../assets/images/timeline-show1.png" alt="timeline-show" />
						</span>
						<span className='timeline-deatils'>Company incorporated</span>
					</div>
					<div className="timeline-left wow animate__animated animate__fadeInDown " data-wow-delay="0.5s">
						<span className="timeline-month">FEBRUARY</span>
						<span className="timeline-year"><strong>2022</strong></span>
					</div>
				</div>

				<div className="timeline-first">
					<div className="timeline-right wow animate__animated animate__fadeInDown " data-wow-delay="0.20s">
						<span className="timeline-show">
							<img src="../../../assets/images/timeline-show2.png" alt="timeline-show" />
						</span>
						<span className='timeline-deatils'>Beta site launched</span>
					</div>
					<div className="timeline-left wow animate__animated animate__fadeInUp " data-wow-delay="0.15s">
						<span className="timeline-month">JUNE</span>
						<span className="timeline-year"><strong>2023</strong></span>
					</div>
				</div>

				<div className="timeline-first">
					<div className="timeline-right wow animate__animated animate__fadeInUp " data-wow-delay="0.30s">
						<span className="timeline-show">
							<img src="../../../assets/images/timeline-show3.png" alt="timeline-show" />
						</span>
						<span className='timeline-deatils'>Crossed over 1000 ton/month transactions</span>
					</div>
					<div className="timeline-left wow animate__animated animate__fadeInDown " data-wow-delay="0.25s">
						<span className="timeline-month">AUGUST</span>
						<span className="timeline-year"><strong>2023</strong></span>
					</div>
				</div>

				<div className="timeline-first">
					<div className="timeline-right wow animate__animated animate__fadeInDown " data-wow-delay="0.40s">
						<span className="timeline-show">
							<img src="../../../assets/images/timeline-show4.png" alt="timeline-show" />
						</span>
						<span className='timeline-deatils'>Website launch</span>
					</div>
					<div className="timeline-left wow animate__animated animate__fadeInUp " data-wow-delay="0.35s">
						<span className="timeline-month">NOVEMBER</span>
						<span className="timeline-year"><strong>2023</strong></span>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Timeline