import {
  SET_CATEGORY,
  SET_BRAND,
  SET_SUBCATEGORY,
  SET_PRODUCT_LIST,
  SET_ATTRIBUTE_FOR_PRODUCT_LISTING,
  SET_PRODUCT_LIST_WISHLIST,
} from "./../actionsTypes";

const initialState = {
  categories: [],
  brands: [],
  subcat: [],
  productList: {},
  attributeList: {},
};

const setAttrList = (state, payload) => {
  return {
    ...state,
    attributeList: {
      ...state.attributeList,
      [payload.name]: payload.data,
    },
  };
};
const setProduct = (state, payload) => {



  if (payload.page === 1) {
    return { ...state, productList: payload };
  } else {
    return {
      ...state,
      productList: {
        ...state.productList,
        page: payload.page,
        data: [...state.productList.data, ...payload.data],
      },
    };
  }
};

const setWishlist = (state, payload) => {
  let sState = { ...state };
  let newState = { ...state.productList };
  newState = newState.data.map(item => {
    if (item._id === payload.productId) {
      return { ...item, wishlist: !item.wishlist }
    } else {
      return item;
    }
  })
  sState.productList.data = newState;
  return { ...state, ...sState }
}

const productListingInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY:
      return { ...state, categories: action.payload };
    case SET_SUBCATEGORY:
      return { ...state, subcat: action.payload };
    case SET_BRAND:
      return { ...state, brands: action.payload };
    case SET_PRODUCT_LIST:
      return setProduct(state, action.payload);
    case SET_ATTRIBUTE_FOR_PRODUCT_LISTING:
      return setAttrList(state, action.payload);
    case SET_PRODUCT_LIST_WISHLIST:
      return setWishlist(state, action.payload);

    default:
      return state;
  }
};
export default productListingInfo;
