// export const API_URL = 'http://103.82.221.20:6060';
//export const API_URL = 'http://45.65.41.90:6060'; //temp
//export const API_URL = 'http://10.0.4.26:6089'; // new API Url
// export const API_URL = "https://metalsbuy-api.uatsparxit.com"; // staging url login->azarseller
export const API_URL = "https://api.metalsbuy.com"; // staging url login->azarseller
//export const API_URL = 'http://45.65.41.90:6089'; // development url
// export const API_URL = "https://metalsbuy-api.devsparxit.com";
export const API_PREFIX = "/api/v1/";
export const API_PREFIX_AUTH = "/api/v1/auth/";
export const API_PREFIX_SHIPPING = "/api/"; //Shipping Prefix
export const map_api_key = "AIzaSyD67LS8acDfx3sKM5HimzFP_MQdsMM9gNk";
