import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";
import {
  SET_DISCOVER_TREND_DATA,
  SET_DASHBOARD_BLOCK_DATA,
  SET_BANNER_IMAGE_DATA,
  SET_DASHBOARD_PRODUCT_DATA,
  SAVE_TESTIMONIAL,
} from "./../actionsTypes";
const token = localStorage.getItem("token");
const userId = localStorage.getItem('userId');
export const getHomeBrands = (payload) => {
  //this give Home Page Brands List  data
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.homepage.getProduct, { ...payload, })//userId
      .then((response) => {
        dispatch(setTrendsData(response.data));
      });
  };
};

export const getHomePage = (payload, completion) => {
  ///this give home page component  data not product data
  return (dispatch) => {
    return axiosInstance.get(apiUrl.homepage.all).then((response) => {

      if (response.data.status) {
        dispatch(setDashboardBlock(response.data));
        completion(response.data);
      } else {
        completion({ status: false });
      }
    });
  };
};

export const getBannerImg = (payload) => {
  // for banner image
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.homepage.banner, payload)
      .then((response) => {

        dispatch(setBannerImage(response.data));
      });
  };
};

export const getDashboardCategoryProd = (payload, completion) => {

  // for category product
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.homepage.getProduct, { ...payload }) //{block_name:"",type:type[0],id:""}/userId
      .then((response) => {
        if (["static-block", "review"].includes(payload.block_name)) {
          dispatch(setDashboardProduct({ ...response.data, blockID: payload.id }));
        }
        else {
          let newData = response.data.data.map(i => { //remove $numberDecimal
            if (typeof i.Price.current_store_price === 'object' && i.Price.current_store_price !== null) {
              return {
                ...i,
                Price: {
                  current_store_price: i.Price.current_store_price.$numberDecimal,
                  sell_price: i.Price.sell_price.$numberDecimal
                }
              }
            }
            else return i
          })
          dispatch(setDashboardProduct({ ...response.data, data: newData, blockID: payload.id }));
        }

      });
  };
};

axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
const setTrendsData = (data) => {
  return {
    type: SET_DISCOVER_TREND_DATA,
    payload: data,
  };
};

const setDashboardBlock = (data) => {
  //main component data
  return {
    type: SET_DASHBOARD_BLOCK_DATA,
    payload: data,
  };
};
const setBannerImage = (data) => {
  return {
    type: SET_BANNER_IMAGE_DATA,
    payload: data,
  };
};

//#### set data inside every component on dashboard based on component id ####
const setDashboardProduct = (data) => {
  return {
    type: SET_DASHBOARD_PRODUCT_DATA,
    payload: data,
  };
};
//##################

export const getAllBrand = (callback) => {
  return (dispatch) => {
    return axiosInstance.get(apiUrl.brand.brand1).then((response) => {
      callback(response.data);
    });
  };
};

export const getTestimonial = () => {
  return (dispatch) => {
    return axiosInstance.get(apiUrl.testimonial.api).then((response) => {
      dispatch(setTestimonial(response.data.data));
    });
  };
};

const setTestimonial = (data) => {
  return {
    type: SAVE_TESTIMONIAL,
    payload: data,
  };
};

///################## MetalsBuy ####################
