import React from 'react'
import ReactStars from "react-rating-stars-component";


const SellerRatingFilter = ({ starValue, handleRating, index }) => {

    return (

        <>
            {/* <input type="checkbox" value={starValue.name} onChange={(e) => handleRating(e)} />
            <span class="checkmark1"></span> */}
            <label class="check-wrp">
                <div className='star-rating filter-star-rate'>
                    <div className='star-rating-img'>
                        <ReactStars
                            count={5}
                            size={24}
                            value={index+1}
                            color1= '#b1afaf'
                            activeColor= '#F5C139'
                            edit={false}
                            
                        />
                    </div>
                    <span className='star-count'>
                        {starValue.name} Star
                    </span>
                </div>
                <input type="checkbox" value={starValue.name} onChange={(e) => handleRating(e)} />
                <span class="checkmark1"></span>
            </label>
        </>
    )
}

export default SellerRatingFilter