import { combineReducers } from "redux";

import userInfo from "./user";
import generalInfo from "./general";
import sellItemInfo from "./sellItem.js";
import dashboardInfo from "./dashboard";
import productListingInfo from "./productListing";
import searchItemInfo from "./searchItem";
import productDetails from "./productDetails";
import userProfile from "./profile";
import cart from "./cart";
import blogData from "./blog";
import Wishlist from "./Wishlist";
import myCouponRedu from "./myCouponRedu";
import cms_data from "./cms_data";
import chatModule from "./chatModule";
import headerRedu from './headerRedu'
import linkInfo from './careerReducer'
import makeofferReducer from './makeofferReducer'
import userDataDEtail from "./userDataREducer";
import paymentReducer from "./paymentReducer.js";


const reducer = combineReducers({
  linkInfo,
  headerRedu,
  userDataDEtail,
  generalInfo: generalInfo,
  userInfo: userInfo,
  sellItemInfo: sellItemInfo,
  dashboardInfo: dashboardInfo,
  productListingInfo: productListingInfo,
  searchItemInfo: searchItemInfo,
  productDetails: productDetails,
  userProfile: userProfile,
  cart: cart,
  blogData: blogData,
  Wishlist: Wishlist,
  couponList: myCouponRedu,
  getCMS_data: cms_data,
  chatModule: chatModule,
  makeofferReducer,
  paymentReducer
});

export default reducer;
