import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";
import {
  SET_CATEGORY,
  SET_BRAND,
  SET_PRODUCT_LIST,
  SET_ATTRIBUTE_FOR_PRODUCT_LISTING,
} from "./../actionsTypes";


const userId = localStorage.getItem("userId");
const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;



export const getProductListInDeliverTo = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.homepage.productList, payload)
      .then((response) => {
        completion(response.data)
      })
      .catch((err) => {
        completion(err.response && err.response.data)
      });
  };
};




export const getCategory = (query) => {
  const categoryUrl = apiUrl.category.category;
  return (dispatch) => {
    return axiosInstance.get(categoryUrl).then((response) => {
      if (response.data.data.length > 0) {
        response.data.data = response.data.data.map((i) => ({
          ...i,
          hover_active: false,
          hover_active2: false,
        }));
      }
      dispatch(setCategory(response.data.data));
    });
  };
};

export const saveSearch = (param) => {
  axiosInstance.post(apiUrl.header.saveSearch, param);
};

export const getBrands = (query) => {
  const categoryUrl = apiUrl.brand.brand;
  return (dispatch) => {
    return axiosInstance.get(categoryUrl).then((response) => {
      const brand = (response && response.data && response.data.data) || [];
      const arr = brand.map((element) => {
        return { value: element, label: element.name };
      });

      dispatch(setBrand(arr)); // for SellAn Item Page
    });
  };
};



export const getProductListCat = (payload, callback)=>{
     const url = apiUrl.productDetail.getProductList;
     return (dispatch)=>{
        return axiosInstance.post(url, payload).then((response)=>{
          callback(response.data)
        }).catch(()=>{
          callback({})
        })
     }

}

export const getProductList = (payload, completion) => {
  return (dispatch) => {
    let url;
    if (payload.tagId) {
      url = "brands/getProductsBybrandId";
      // payload.userId = userId;
    } else if (payload.block_name) {
      url = "homepage/home_page_data";
      // payload.userId = userId;
    } else if (payload.result === "More result") {
      url = apiUrl.productDetail.moreProductFromThisSeller;
      // payload.userId = payload.seller;
      delete payload.result;
      delete payload.seller;
    } else if (payload.result === "Similar product") {
      url = apiUrl.productDetail.similarProduct;
      delete payload.result;
    } else if (payload.category && payload.category[0] === "Popular") {
      url = apiUrl.header.popular;
      // payload.userId = userId;
      delete payload.category;
      delete payload.product_data
    } else {
      url = apiUrl.homepage.productList;
      // payload.userId = userId;
    }
    if (payload.category && payload.category[0] === "Local") {
      delete payload.category;
      delete payload.product_data
    }



    return axiosInstance
      .post(url, payload)
      .then((response) => {
        dispatch(setProductList(response.data));
        completion(true)
      })
      .catch((err) => {


        dispatch(setProductList({ data: [], page: 1 }));
        completion(false)
      });
  };
};





export const getAllAttributesForProductListing = (payload, queryParam) => {
  let queryState = {};
  const active = false;
  const isActive = false;
  Object.keys(queryParam).map((key) => {
    return (queryState[key] = queryParam[key].split("_"));
  });

  const url = apiUrl.attribute.getAttributeByCategory;
  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        //##############  brand ######################
        if (response.data.brand) {
          let brand = response.data.brand.map((item) => ({
            ...item,
            isActive1: false,
          }));
          response.data.brand = { ...brand, active1: true };
        }
        //$$$$$$$$$$$$$$$$  brand END $$$$$$$$$$$$$$$$$$
        //######### condition #####
        if (response.data.condition) {
          let condition = response.data.condition.map((item) => ({
            ...item,
            isActive1: false,
          }));
          response.data.condition = { ...condition, active1: true };
        }
        //$$$$$$$$$$$$$$$$ condition end //$$$$$$$$$$$$$$$$
        //######### delivery #####
        if (response.data.delivery) {
          let delivery = response.data.delivery.map((item) => ({
            ...item,
            isActive1: false,
          }));
          response.data.delivery = { ...delivery, active1: false };
        }
        //$$$$$$$$$$$$$$$$ delivery end //$$$$$$$$$$$$$$$$
        //######### shipping #####
        if (response.data.shipping) {
          let shipping = response.data.shipping.map((item) => ({
            ...item,
            isActive1: false,
          }));
          response.data.shipping = { ...shipping, active1: true };
        }
        //$$$$$$$$$$$$$$$$ shipping end //$$$$$$$$$$$$$$$$
        //##############  category ######################
        if (response.data.category) {
          let category = response.data.category.map((item) => {
            let Subcategory = item.Subcategory.map((subcat, i) => ({
              ...subcat,
              isActive1: false,
            }));
            return { ...item, Subcategory };
          });

          response.data.category = {
            0: category,
            // 1: response.data.all_category,
            active1: true,
          };
          // response.data.category = [...category, response.data.all_category];
        }
        //$$$$$$$$$$$$$$$$$$$ category END $$$$$$$$$$$$$$$$$$$
        //$$$$$$$$$$$$$$$$$$$$  related search $$$$$$$$$$$$
        if (response.data.related_search) {
          if (response.data.related_search.length > 0) {
            response.data.related_search = {
              active1: false,
              ...response.data.related_search,
            };
          } else response.data.related_search = {};
        }
        //####################  reatd search end   ###########
        //##############  attribute_list ######################

        if (response.data.attr_name) {
          let attribute_list = response.data.attr_name.map((key, id) => {
            let sub_attr = response.data.attr_value[id].map((element) => ({
              value: element,
              isActive1: false,
            }));
            return { [key]: sub_attr, active1: false };
          });

          response.data.attribute_list = attribute_list;
        }

        //##############  attribute_list END ######################
        //##############  price   #################################
        if (response.data.price) {
          const priceRadio = [
            { min: 0, max: 1000, isActive1: false },
            { min: 1000, max: 2000, isActive1: false },
            { min: 2000, max: 3000, isActive1: false },
            { min: 3000, max: 4000, isActive1: false },
            { min: 4000, max: response.data.price.maximum, isActive1: false },
          ];
          response.data.price = { ...priceRadio, active1: false };
        }

        response.data.priceState = {
          min: 0,
          max: 0,
          isActive1: false,
        };
        // ############# price end ##########################

        // if (queryParam.category !== "demo") {
        dispatch(
          setAttributesForProductListing({
            data: response.data,
            name: payload.block_data
              ? payload.block_data.Id
              : payload.brandId
                ? response.data.brand[0].name
                : payload.all
                  ? payload.seller
                  : queryParam.result
                    ? payload.categoryId //for similar prod
                    : queryState.category[0],
          })
        );
        // } else {
        //   dispatch(
        //     setAttributesForProductListing({
        //       data: response.data
        //     })
        //   );
        // }

      })
      .catch((e) => {

      });
  };
};


export const getAllFilterData = (payload, queryParam, callback) => {
  let queryState = {};
  const active = false;
  const isActive = false;
  Object.keys(queryParam).map((key) => {
    return (queryState[key] = queryParam[key].split("_"));
  });

  const url = apiUrl.attribute.getAttributeByCategory;
  return (dispatch) => {
    return axiosInstance
      .post(url, payload)
      .then((response) => {
        if (response.data.message === "Please pass proper paramteres" || response.data.message === "No data found") {
          callback({})
        } else {
          callback(response.data)
        }

      })
      .catch((e) => callback({}));
  };
};



const setProductList = (payload) => {
  return {
    type: SET_PRODUCT_LIST,
    payload: payload,
  };
};

const setAttributesForProductListing = (payload) => {
  return {
    type: SET_ATTRIBUTE_FOR_PRODUCT_LISTING,
    payload: payload,
  };
};

const setCategory = (data) => {
  return {
    type: SET_CATEGORY,
    payload: data,
  };
};

// const setSubcategory = (data) => {
//     return {
//         type: SET_SUBCATEGORY,
//         payload: data,
//     }
// }

const setBrand = (data) => {
  return {
    type: SET_BRAND,
    payload: data,
  };
};
