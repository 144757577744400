import * as types from '../actionsTypes';



const initialState = {
    offerlist_buyer: {},
    offerlist_seller: {}
};

const makeofferReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OFFERLIST_BUYER:
            return { ...state, offerlist_buyer: action.payload };
        case types.OFFERLIST_SELLER:
            return { ...state, offerlist_seller: action.payload };
        default:
            return state;
    }
};
export default makeofferReducer;
