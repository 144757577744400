import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'
const WhyMetalbuy = () => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: true, // Enables the live mode to detect changes dynamically
    });
    wow.init();
  }, []);

  return (
    <div className="why-us">
      <div className="container">
        <div className="img-wrap wow animate__animated animate__fadeInUp ">
          <img
            src="/assets/images/why-metalsbuy.png"
            alt="img"
          />

        </div>

        <div className="why-us-info">
          <div className="list-wrap wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
            <ul className="list">
              <li>
                <strong>The Power of Automation:</strong> Embrace the future
                with our cutting-edge technology platform. Automation enables
                data based decisions, increases efficiency and optimises human
                resources
              </li>
              <li>
                <strong> Real-time Data at Your Fingertips:</strong> Stay ahead
                of the curve with access to real-time prices, product
                availability, and order tracking.
              </li>
              <li>
                <strong>Unparalleled Transparency:</strong> Know exactly what
                you're getting into. Metalsbuy thrives on transparency, ensuring
                you're always in the know.
              </li>
            </ul>
          </div>
          <div className="list-wrapping wow animate__animated animate__fadeInUp " data-wow-delay="0.3s">
          <p><span>The future of Ferro Alloys Procurement is here, and it's at Metalsbuy.</span> Stay ahead of the curve with access to real-time prices, product availability, and order tracking.</p>
          <p> <strong className=""> EXPERIENCE THE FUTURE OF BUYING AND SELLING OF FERRO ALLOYS FIRSTHAND </strong></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyMetalbuy;
