import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Toast from "light-toast";
import { RupeesIcon } from "../../utils/constants";
import { getOfferPriceList, makeOffer } from '../../store/actions/productDetails';
import { priceWithSymbol } from '../../utils/constants';
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { getuserAddress } from "../../store/actions/checkout"
import { Shipping_Cost } from "../../store/actions/cartAction";
import swal from 'sweetalert';
import { useToasts } from "react-toast-notifications";
import Loader from "../Loader";



const MakeOffer = (props) => {

  let dispatch = useDispatch()
  const [disabled, setDisabled] = React.useState(true);
  const [price, setPrice] = React.useState("");
  const [priceList, setPriceList] = React.useState({})
  const [addList, setAddrList] = useState([]);
  const [addrId, setAddrId] = useState(null);
  const [shippingCost, setShippingCost] = useState(0);
  const [handleCheck, setHandleCheck] = useState(false);
  const [checkId, setCheckId] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const { addToast } = useToasts();



  const handlerChange = (val) => {
    if (val >= priceList.bidPriceLimit.minimum && val <= priceList.bidPriceLimit.maximum) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setPrice(val);
    setHandleCheck(false);
  };


  const handlerclick = (e) => {
    e.preventDefault();
    let payload = {
      productId: props.id,
      sellerId: props.sellerId,
      price,
      addressId: addrId,
      shippingCost
    }
    if (addrId) {
      setShowLoader(true);
      setShowMessage("PLease wait...")
      makeOffer(payload, callback => {
        setTimeout(() => {
          if (callback) {
            setShowLoader(false);
            addToast("Your offer has been created successfully", {
              appearance: "success",
              autoDismissTimeout: 2000,
            });
            props.getproductDetail()
          } else {
            addToast("We have already received your offer request", {
              appearance: "success",
              autoDismissTimeout: 2000,
            });
          }
          props.handleCloseMakeOffer()
        }, 1000)
      })(dispatch)
    } else if (addList.length === 0) {
      addToast("Please add atleast one address", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
    } else {
      addToast("Please select a address", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
    }
  };


  const addressChange = (e) => {

    if (price !== "") {
      setShowLoader(true);
      setShowMessage("Please wait...");
      let payload = {
        productIdArr: [
          {
            productId: props.id, quantity: 1
          }
        ],
        addressId: e.target.value,
        offerdPrice: price
      }
      Shipping_Cost(payload, callback => {
        if (callback.status === "success") {
          setShippingCost(callback.data[0].shippingCost);
          setShowLoader(false);
          setAddrId(e.target.value)
          setHandleCheck(false);
          setCheckId(e.target.value);
        } else {
          setShowLoader(false);
          addToast(callback.message, {
            appearance: "error",
            autoDismissTimeout: 2000,
          });
        }
      })(dispatch);
    } else {
      setHandleCheck(true);
    }

  }


  useEffect(() => {
    getOfferPriceList({ productId: props.id }, (status) => {
      setPriceList(status.data);
    })
    getuserAddress(callback => {
      setAddrList(callback)
    })(dispatch)
  }, []);



  return (
    <div className="make-offer-popup offered-popup-wrap offer-popup-wrap">
      {showLoader ? <Loader message={showMessage} /> : null}
      <div className="offer-content">
        <button
          className="modal-closeBtn modal-close-btn-wrp"
          onClick={() => props.handleCloseMakeOffer()}
          type="button"
        >
          {/* <span className="closeBtn-crossLeft"></span>
          <span className="closeBtn-crossRight"></span> */}
          <i className="fal fa-times-circle"></i>
        </button>
        <div className="offer-title">Make an offer</div>
        <div>

        </div>
        <p className="offer-desc">
          Make an offer to the seller at which you want to buy this
          item.
        </p>
        <div className="offer-info">
          <img className="offer-img" src={props.img} alt="" />
          <p className="offer-txt offer-txts">
            {props.name}
          </p>
          <span className="item-name">
            MC Silico Manganese 60/14
          </span>
          <p className="offer-txt">
            Current price :
            <span className="current-price-tag">{" "}
              {props.price && props.price.sell_price ?
                priceWithSymbol(props.price.sell_price)
                : priceWithSymbol(props.price && props.price.current_store_price)
              }
            </span>
          </p>
        </div>
        <div className="offer-price">
          <form onSubmit={handlerclick}>
            <div className="offer-input-box">
              <label>Enter Your Offer Price</label>
              <div className="offer-input">
                <span className="offer-sign">{RupeesIcon}</span>
                <div className="input-group">
                  <input
                    required
                    type="text"
                    placeholder="Your offer"
                    min="1"
                    max="10"
                    value={price}
                    onChange={(e) => {
                      let checkDigit = new RegExp(/^\d*\.?\d*$/);
                      if (checkDigit.test(e.target.value)) {
                        handlerChange(e.target.value);
                      }
                    }}
                  />
                </div>
              </div><br />
              {handleCheck ? <span className="enter-offer-price">Please enter offer price first.</span> : null}
              <p className="range-label">Please enter the amount between
                {
                  !isEmpty(priceList) &&
                  <span>
                    {" "}
                    {(priceList.bidPriceLimit.minimum)}
                    {" "}
                    to
                    {" "}
                    {(priceList.bidPriceLimit.maximum)}
                  </span>
                }
              </p>
            </div>
            {addList.length > 0 ?
              <div className="delivery_wrapper select-content">
                <h3>Select delivery address</h3>
              </div>
              : null}
            <div className="delivery_wrapper select-delivery-wrap">
              {
                addList.map(item =>
                  <div className="select-content-metal">
                    <div className="radio-btn-metal">
                      <div className="radio-custom-metal">
                        <input
                          type="radio"
                          value={item._id}
                          name="addradio"
                          className="radio"
                          onChange={addressChange}
                          // checked={checkId === item._id && !handleCheck ? true : false}
                          checked
                        />
                        <div className="radio-circle">
                        </div>
                      </div>
                      <div className="desc-txt">
                        <div className="address-checkout">
                          <span className="ship-add">{item.flat_no},{" "}</span>
                          <span className="ship-add">{item.street},{" "}</span>
                          {item.landmark !== "" && <span className="ship-add">{item.landmark},{" "}</span>}
                          <span className="ship-add">{item.postcode},{" "}</span>
                          <span className="ship-add">{item.city},{" "}</span>
                          <span className="ship-add">{item.state[0].name}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            {
              shippingCost !== 0 &&
              <p className="range-label shipping-label">Shipping fee will be {RupeesIcon + shippingCost} on the selected address </p>
            }
            <button className={`${disabled ? "offer-btn-diable" : "offer-inserted-btn"} `} type="submit" disabled={disabled}>
              Send Offer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default withRouter(MakeOffer);
