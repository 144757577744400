import {
  SET_SHOW_LOGIN_POPUP_VALUE,
  SET_LOGGED_USER_INFO,
  SET_LOGIN_USER_ERROR,
  SET_REDIRECT_TO_PRIVATE_ROUTE,
  SET_REDIRECT_TO_NEXTPAGE,
  SET_SIGNUP_USER_ERROR,
  SET_IP_ADDRESS,
  SET_USER_LOC,
  SAVE_NOTIFICATION_IN_STORE,
  UPDATE_NOTIFICATION_LIST,
  SET_NETWORK_STATUS,
  SET_HEADER_COUNTS,
  SET_HEADER_NOTIFICATION,
  INCREASE_WISHLIST_COUNT,
  DECREASE_WISHLIST_COUNT,
  GET_JOB_DETAIL,
  SET_HOME_PAGE_DATA,
  SET_USER_DATA,
} from "./../actionsTypes";
import { axiosInstance } from "./../../utils/Service";
import { Authorization } from "./../../utils/Authorization";
import { apiUrl } from "./../../utils/urlEndpoints";

import { getUserAddress } from "./profile";
import { addCart } from "./cartAction";
import { getWishList } from "./Wishlist";
import { get, filter as loFilter, sum } from "lodash";
import swal from "sweetalert";

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const checkUserActiveOrNot = () => {
  return axiosInstance
    .get(apiUrl.auth.CheckUserStatus, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((result) => {})
    .catch((err) => {
      if (err.response && err.response.data.status === "error") {
        logout();
      }
    });
};

export const setLoginPopupStatus = (data) => {
  return {
    type: SET_SHOW_LOGIN_POPUP_VALUE,
    payload: data,
  };
};

export const openLoginScreen = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOW_LOGIN_POPUP_VALUE,
    payload: data,
  });
};

export const setLoggedUserInfo = (loggedUserInfo) => {
  return {
    type: SET_LOGGED_USER_INFO,
    payload: loggedUserInfo,
  };
};

export const setNetworkStatus = (netWorkStatus) => {
  return {
    type: SET_NETWORK_STATUS,
    payload: netWorkStatus,
  };
};

export const logout = (status) => {
  // cleareWishlist()
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("ShippingIdToken");
  localStorage.removeItem("incomplete");
  localStorage.removeItem("isProfileCompleted");
  localStorage.removeItem("approved");
  localStorage.removeItem("pendinModal");
  localStorage.removeItem("userType");
  localStorage.removeItem("childType");
  localStorage.removeItem("tokenUser");
  localStorage.removeItem("subId");

  // return setLoggedUserInfo({
  //     isLoggedIn: false,
  //     email: '',
  //     userId: '',
  //     userName: ''
  // })
  window.location.href = "/";
  // window.location.reload();
};

export const showLoginErr = (data) => {
  return {
    type: SET_LOGIN_USER_ERROR,
    payload: data,
  };
};

export const showSignupErr = (data) => {
  return {
    type: SET_SIGNUP_USER_ERROR,
    payload: data,
  };
};

export const getIpAddress = () => {
  return async (dispatch) => {
    try {
      // const res = await fetch("https://api.ipify.org?format=json");
      const res = await fetch("http://ip.jsontest.com/");
      const json = await res.json();
      await dispatch(setIpAddredd(json));
    } catch (err) {}
  };
};

const setIpAddredd = (data) => {
  return {
    type: SET_IP_ADDRESS,
    payload: data,
  };
};

export const getUseLocation = (myLat, myLon, mapApi) => {
  return (dispatch) => {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        myLat +
        "," +
        myLon +
        "&key=" +
        mapApi
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let streetAdddr = responseJson.results
          .filter(
            (a) =>
              a.types.includes("street_address") ||
              a.types.includes("administrative_area_level_2")
          )[0]
          .address_components.filter(
            (x) =>
              x.types.includes("sublocality_level_2") ||
              x.types.includes("administrative_area_level_2")
          )[0].short_name;
        let fullAddress = responseJson.results.filter(
          (a) =>
            a.types.includes("street_address") ||
            a.types.includes("administrative_area_level_2")
        )[0].address_components;
        let pinCode = [];
        responseJson.results // for getting pin in all array
          .filter(
            (a) =>
              a.types.includes("postal_code") ||
              a.types.includes("street_address") ||
              a.types.includes("administrative_area_level_2")
          )
          .filter((b) =>
            b.address_components.map((c) => {
              if (c.types.includes("postal_code")) {
                pinCode.push(c.short_name);
              }
            })
          );
        // let pinCode = responseJson.results.filter(a => a.types.includes("street_address"))[0].address_components
        //   .filter(x => x.types.includes("postal_code"))[0].short_name
        dispatch(setUseLocation({ streetAdddr, fullAddress, pinCode }));
      });
  };
};

// export const getCityByZip = (zip, mapApi) => {
//   return (dispatch) => {
//     fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + zip + '&sensor=' + true + '&key=' + mapApi)
//       .then(res => res.json())
//       .then(resJson => {
//         if (resJson.results.length > 0) {
//           let cityArr = resJson.results[0].address_components.filter(o => o.types.includes("political")).map(i => i.short_name)
//
//           dispatch({
//             type: SET_USER_LOC,
//             payload: { cityArr, userInputZip: zip },
//           });
//         }

//       })

//   };
// };

export const getCityByZip = (zip, getErrorMessage) => {
  return (dispatch) => {
    fetch("https://api.postalpincode.in/pincode/" + zip)
      .then((res) => res.json())
      .then((resJson) => {
        let cityArr = [];
        if (resJson[0].Status == "Success") {
          getErrorMessage({
            message: resJson[0].Message,
            status: resJson[0].Status,
          });
          for (let a = 0; a < resJson[0].PostOffice.length; a++) {
            cityArr.push(resJson[0].PostOffice[a].Block);
          }
          dispatch({
            type: SET_USER_LOC,
            payload: { cityArr, userInputZip: zip },
          });
        } else {
          getErrorMessage({
            message: "No location found for this pin code",
            status: resJson[0].Status,
          });
          dispatch({
            type: SET_USER_LOC,
            payload: { cityArr, userInputZip: zip },
          });
        }
      });
  };
};

const setUseLocation = (data) => {
  return {
    type: SET_USER_LOC,
    payload: data,
  };
};

export const setToken = (payload) => {
  const loginInfo = Authorization(payload.data.token);
  if (loginInfo.status) {
    localStorage.setItem("userId", loginInfo.payload.userId);
    return setLoggedUserInfo({
      isLoggedIn: loginInfo.status,
      email: loginInfo.payload.email,
      userId: loginInfo.payload.userId,
      userName: loginInfo.payload.name.split(" ")[0],
    });
  } else {
    return setLoggedUserInfo({
      isLoggedIn: loginInfo.status,
    });
  }
};

export const loginRequest = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.login, payload)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if(response.data.permissionData.isSuper_user===false){
            localStorage.setItem("subId", response.data.permissionData.sub_user_id)
          }
          if(response.data.permissionData.isSuper_user || response.data.permissionData.permissionType===1){
            if(response.data.permissionData.permissionType===1){
              localStorage.setItem("childType","all")
            }
            else{
              localStorage.removeItem("childType")
            }
            dispatch(setToken(response));
            localStorage.removeItem("tokenUser")
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("incomplete", response.data.isIncomplete);
            let cart = JSON.parse(localStorage.getItem("bazar_cart"));
            cart &&
              cart.forEach((item) => {
                let cartArr = [];
                let payload1 = {
                  product_id: item.productId,
                  save_for_later: false,
                  sellerId: item.sellerId[0]._id,
                  user_id: localStorage.getItem("userId"),
                  isLocalProduct: item.isLocalProduct,
                };
                cartArr.push(payload1);
                addCart({ cart: cartArr }, (callback) => {
                  if (callback.status === "error") {
                    swal(callback.message);
                    return false;
                  }
                })(dispatch);
              });
            setTimeout(() => {
              localStorage.removeItem("bazar_cart");
              dispatch(setToken(response));
              completion({
                status: true,
                isIncomplete: response.data.isIncomplete,
                data: response.data,
              });
            }, 1000);
          }
          else{
            localStorage.setItem("tokenUser", response.data.token);
            if(response.data.permissionData.isSuper_user===false){
              if(response.data.permissionData.permissionType===0){
                window.location = "/process-orders"
                localStorage.setItem("childType","order")
              }
              else{
                window.location.reload()
                localStorage.setItem("childType","all")
              }
            }
          }
        } else {
          completion({ status: false });
        }
      })
      .catch((err) => {
        const obj = get(err, ["response", "data"], false);
        if (obj.token) {
          completion({ status: false, ...obj });
        } else {
          let data = {
            errData: obj.error ? obj.error : obj.message,
            status: true,
          };
          completion({
            status: false,
            data,
            userId: err.response && err.response.data,
          });
          dispatch(showLoginErr(data));
        }
        // else completion({ status: false });
      });
  };
};

export const signUpRequest = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.signup, payload)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          completion({
            status: true,
            res: response.data,
            statucCode: response.status,
          });
        } else {
          completion({ status: false });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.userId) {
          completion({ status: true, res: err.response.data });
        } else {
          let data = {
            errData: err.response && err.response.data.Message,
            status: true,
          };
          completion({
            status: true,
            res: err.response && err.response.data,
            statucCode: err.response.data && err.response.data.status,
          });
          dispatch(showSignupErr(data));
        }
      });
  };
};

export const sellerRegisterationRequest = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.sellerRegisterationRequest, payload)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          completion({
            status: true,
            res: response.data,
            statucCode: response.status,
          });
        } else {
          completion({ status: false });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.userId) {
          completion({ status: true, res: err.response.data });
        } else {
          let data = {
            errData: err.response && err.response.data.Message,
            status: true,
          };
          completion({
            status: true,
            res: err.response && err.response.data,
            statucCode: err.response.data && err.response.data.status,
          });
          dispatch(showSignupErr(data));
        }
      });
  };
};

export const joinWaitList = (paylaod, callback) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.joinWait, paylaod)
      .then((response) => {
        callback(response.data);
      })
      .catch((err) => {});
  };
};

export const signUpUpdate = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.update, payload)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          completion({
            status: true,
            res: response.data,
            statucCode: response.status,
          });
        } else {
          completion({ status: false });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.userId) {
          completion({ status: true, res: err.response.data });
        } else {
          let data = {
            errData: err.response && err.response.data.Message,
            status: true,
          };
          completion({
            status: true,
            res: err.response && err.response.data,
            statucCode: err.response.data && err.response.data.status,
          });
          dispatch(showSignupErr(data));
        }
      });
  };
};

// const customHeaders = {
//   'method': 'POST',
// };

export const getToken = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.GetToken, payload)
      .then((result) => {
        completion({
          status: true,
          res: result.data,
          statucCode: result.status,
        });
      })
      .catch((err) => {});
  };
};

export const newsletterSubscribe = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.newsLetter, payload)
      .then((result) => {
        completion(result.data);
      })
      .catch((err) => {});
  };
};

export const OTPVerify = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.OTPVerify, payload, {
        headers: {
          Authorization: "Bearer " + payload.token,
        },
      })
      .then((response) => {
        let istokened = response.data.hasOwnProperty("token");
        if (istokened) {
          dispatch(setToken(response));
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("incomplete", response.data.isIncomplete);
          let cart = JSON.parse(localStorage.getItem("bazar_cart"));
          cart &&
            cart.forEach((item) => {
              let cartArr = [];
              let payload1 = {
                product_id: item.productId,
                save_for_later: false,
                sellerId: item.sellerId[0]._id,
                user_id: localStorage.getItem("userId"),
                isLocalProduct: item.isLocalProduct,
              };
              cartArr.push(payload1);
              addCart({ cart: cartArr }, (callback) => {
                if (callback.status === "error") {
                  swal(callback.message);
                  return false;
                }
              })(dispatch);
            });
          setTimeout(() => {
            localStorage.removeItem("bazar_cart");
            dispatch(setToken(response));
            completion({
              status: true,
              isIncomplete: response.data.isIncomplete,
              data: response.data,
            });
          }, 1000);
        } else {
          completion({ data: response.data, status: true });
        }
      })
      .catch((err) => {
        completion({ status: false, data: err.response.data });
      });
  };
};

export const OTPVerifyEmail = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.OTPVerifyEmail, payload, {
        headers: {
          Authorization: "Bearer " + payload.token,
        },
      })
      .then((response) => {
        let istokened = response.data.hasOwnProperty("token");
        if (istokened) {
          dispatch(setToken(response));

          localStorage.setItem("token", response.data.token);
          localStorage.setItem("incomplete", response.data.isIncomplete);
          let cart = JSON.parse(localStorage.getItem("bazar_cart"));
          cart &&
            cart.forEach((item) => {
              let cartArr = [];
              let payload1 = {
                product_id: item.productId,
                save_for_later: false,
                sellerId: item.sellerId[0]._id,
                user_id: localStorage.getItem("userId"),
                isLocalProduct: item.isLocalProduct,
              };
              cartArr.push(payload1);
              addCart({ cart: cartArr }, (callback) => {
                if (callback.status === "error") {
                  swal(callback.message);
                  return false;
                }
              })(dispatch);
            });
          setTimeout(() => {
            localStorage.removeItem("bazar_cart");
            dispatch(setToken(response));
            completion({
              status: true,
              isIncomplete: response.data.isIncomplete,
              data: response.data,
            });
          }, 1000);
        } else {
          completion({ data: response.data, status: true });
        }
      })
      .catch((err) => {
        completion({ status: false, data: err.response.data });
      });
  };
};

export const OTPLoginVerify = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.OTPLoginVerify, payload, {
        headers: {
          Authorization: "Bearer " + payload.token,
        },
      })
      .then((response) => {
        dispatch(setToken(response));

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("incomplete", response.data.isIncomplete);
        let cart = JSON.parse(localStorage.getItem("bazar_cart"));
        cart &&
          cart.forEach((item) => {
            let cartArr = [];
            let payload1 = {
              product_id: item.productId,
              save_for_later: false,
              sellerId: item.sellerId[0]._id,
              user_id: localStorage.getItem("userId"),
              isLocalProduct: item.isLocalProduct,
            };
            cartArr.push(payload1);
            addCart({ cart: cartArr }, (callback) => {
              if (callback.status === "error") {
                swal(callback.message);
                return false;
              }
            })(dispatch);
          });
        setTimeout(() => {
          localStorage.removeItem("bazar_cart");
          dispatch(setToken(response));
          completion({
            status: true,
            isIncomplete: response.data.isIncomplete,
            data: response.data,
          });
        }, 1000);

        //completion({ data: response.data, status: true })
      })
      .catch((err) => {
        completion({ status: false, data: err.response.data });
      });
  };
};

export const signupByMobile = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.mobileSignin, payload, {
        headers: {
          Authorization: "Bearer " + payload.token,
        },
      })
      .then((response) => {
        dispatch(setToken(response));

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("incomplete", response.data.isIncomplete);
        let cart = JSON.parse(localStorage.getItem("bazar_cart"));
        cart &&
          cart.forEach((item) => {
            let cartArr = [];
            let payload1 = {
              product_id: item.productId,
              save_for_later: false,
              sellerId: item.sellerId[0]._id,
              user_id: localStorage.getItem("userId"),
              isLocalProduct: item.isLocalProduct,
            };
            cartArr.push(payload1);
            addCart({ cart: cartArr }, (callback) => {
              if (callback.status === "error") {
                swal(callback.message);
                return false;
              }
            })(dispatch);
          });
        setTimeout(() => {
          localStorage.removeItem("bazar_cart");
          dispatch(setToken(response));
          completion({
            status: true,
            isIncomplete: response.data.isIncomplete,
            data: response.data,
          });
        }, 1000);

        //completion({ data: response.data, status: true })
      })
      .catch((err) => {
        completion({ status: false, data: err.response.data });
      });
  };
};

export const resendSignOTP = (payload, callback) => {
  return axiosInstance
    .post(apiUrl.auth.resendMobileOTP, payload, {
      headers: {
        Authorization: "Bearer " + payload.token,
      },
    })
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {});
};

export const resendMobileOTP = (payload, callback) => {
  return axiosInstance
    .post(apiUrl.auth.resendMobileOTP, payload)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {});
};

export const resendEmailLink = (payload, callback) => {
  return axiosInstance
    .post(apiUrl.auth.resendEmailLink, payload, {
      headers: {
        Authorization: "Bearer " + payload.token,
      },
    })
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {});
};

export const setRedirectToPrivateRoute = (data) => {
  return {
    type: SET_REDIRECT_TO_PRIVATE_ROUTE,
    payload: data,
  };
};

export const setRedirectToNextPage = (data) => {
  return {
    type: SET_REDIRECT_TO_NEXTPAGE,
    payload: data,
  };
};

export const socialLogin = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.socialLogin, payload)
      .then((response) => {
        dispatch(setToken(response));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.userId);
        dispatch(addtocart(response));
        dispatch(getWishList());
        dispatch(getUserAddress());
        completion(true);
      })
      .catch((err) => {
        completion(false);
      });
  };
};

export const forgotPassword = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.forgotpassword, { email: payload })
      .then((response) => {
        if (response.status === 200) {
          completion(response.data);
        } else {
          completion(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          completion(err.response.data);
        }
      });
  };
};

export const forgotPasswordOtp = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.forgotpasswordotp, payload, {
        headers: {
          Authorization: "Bearer " + payload.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          completion(response.data);
        } else {
          completion(false);
        }
      })
      .catch((err) => {
        completion({
          status: err.response.data.status,
          message: err.response.data.message,
        });
      });
  };
};

export const resendOTP = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(
        apiUrl.auth.resend,
        {},
        {
          headers: {
            Authorization: "Bearer " + payload.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          completion(response.data);
        } else {
          completion(response.data);
        }
      })
      .catch((err) => {
        completion({
          status: err.response.data.status,
          message: err.response.data.message,
        });
      });
  };
};

export const setPassword = (payload, headToken, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.auth.setPassword + headToken, payload, {
        headers: {
          Authorization: "Bearer " + payload.token,
        },
      })
      .then((response) => {
        if (response.data.code === 200) {
          completion(response.data);
        } else {
          completion(response.data);
        }
      })
      .catch((err) => {
        // completion({ status: err.response.data.status, message: err.response.data.message });
      });
  };
};

export const addtocart = () => {
  return (dispatch) => {
    if (localStorage.getItem("cart") === null) {
      var cartData = {};
    } else {
      cartData = JSON.parse(localStorage.getItem("cart"));
      dispatch(addCart(cartData, "", (callback) => {}));
      localStorage.removeItem("cart");
    }
  };
};

export const getnotification = (payload, completion) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        apiUrl.auth.notification,
        payload
      );

      dispatch(saveNotificationList(response.data));
    } catch (err) {}
  };
};

const saveNotificationList = (data) => {
  return {
    type: SAVE_NOTIFICATION_IN_STORE,
    payload: data,
  };
};

export const readnotification = async (payload, completion) => {
  try {
    const response = await axiosInstance.post(
      apiUrl.auth.readnotification,
      payload
    );
    completion(true);
  } catch (err) {}
};

// export const totalUnreadNotification = async (payload, completion) => {
//   try {
//     const response = await axiosInstance.post(
//       apiUrl.auth.totalUnreadNotification
//     );
//     completion(response.data.total_notification_data);
//   } catch (err) {
//     completion(0);
//   }
// };

export const totalUnreadNotification = (payload, completion) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        apiUrl.auth.totalUnreadNotification
      );

      dispatch({
        type: SET_HEADER_NOTIFICATION,
        payload: response.data,
      });
      completion(response.data);
    } catch (e) {
      completion({});
    }
  };
};

export const getTotalUnreadMsgAndWishlistItem = (callback) => {
  const token = localStorage.getItem("tokenUser") ? localStorage.getItem("tokenUser"): localStorage.getItem("token")
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        apiUrl.auth.getTotalUnreadMsgAndWishlistItem,{
          headers:{
              Authorization:"Bearer "+token
          }}
      );
      dispatch({
        type: SET_HEADER_COUNTS,
        payload: response.data.data,
      });
      callback(response.data.data);
    } catch (e) {
      callback({});
    }
  };
};

export const getSellerDEtail = (callback) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(apiUrl.auth.getSEller);
      dispatch({
        type: SET_USER_DATA,
        payload: response.data.data,
      });
      callback(response.data);
    } catch (e) {
      callback({
        status: "error",
        message: "Something went wrong, PLease try again",
      });
    }
  };
};

export const getActiveStatus = (payload, completion) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(apiUrl.auth.aciveInactive, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      completion(response.data.data);
    } catch (e) {
      completion({});
    }
  };
};

export const getHomePageDataWithoutLogin = (callback) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(apiUrl.auth.introPageData);
      dispatch({
        type: SET_HOME_PAGE_DATA,
        payload: response.data.data,
      });
      callback(response.data.data);
    } catch (e) {
      // callback({})
    }
  };
};

export const getHomePageDataWithLogin = (payload, callback) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(apiUrl.auth.homePageData);
      dispatch({
        type: SET_HOME_PAGE_DATA,
        payload: response.data.data,
      });
      callback(response.data);
    } catch (e) {
      // callback({})
    }
  };
};

export const increaseWishListCount = () => (dispatch) => {
  dispatch({
    type: INCREASE_WISHLIST_COUNT,
  });
};

export const decreaseWishListCount = () => (dispatch) => {
  dispatch({
    type: DECREASE_WISHLIST_COUNT,
  });
};

export const deleteNotification = async (payload, completion) => {
  try {
    const response = await axiosInstance.post(
      apiUrl.auth.deleteNotification,
      payload
    );
    completion(true);
  } catch (err) {
    completion(false);
  }
};

////////////// Career Page api /////////////

export const searchCareerDetail = (payload, callback) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        apiUrl.careerDetailUrl.careerDetail
      );

      callback(response.data);
    } catch (e) {}
  };
};

export const getExperienceData = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.careerDetailUrl.experienceDetail, payload)
      .then((result) => {
        completion({ status: true, res: result.data });
      })
      .catch((err) => {});
  };
};

export const getJobDetail = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.careerDetailUrl.jobDetail, payload)
      .then((result) => {
        // dispatch({
        //   type: GET_JOB_DETAIL,
        //   payload: result.data
        // })
        completion({ status: true, res: result.data });
      })
      .catch((err) => {});
  };
};

export const applyJob = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance
      .post(apiUrl.careerDetailUrl.applyJob, payload)
      .then((result) => {
        completion({ status: true, res: result.data });
      })
      .catch((err) => {});
  };
};

export const updateNotificationList = (data) => {
  return {
    type: UPDATE_NOTIFICATION_LIST,
    payload: data,
  };
};
