import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from "react-router-dom";
import { getOffersListSeller } from '../../../../store/actions/makeofferAction';
import ProductCard from '../Myoffer/ProductCard';
import Index from '../Myoffer';
import { ButtonLoader } from "../../../../utils/constants";
import { isEmpty } from '../../../../utils/commonFunctions';


const OfferProduct = ({ name, setLoaderState }) => {
    let dispatch = useDispatch();
    let params = useParams();
    let history = useHistory();
    const [innerLoader, setInnerLoader] = useState(false);
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const offerProd_seller = useSelector(store => store.makeofferReducer.offerlist_seller);



    useEffect(() => {
        if (offerProd_seller.status === "success") {
            setLoaderState()
        }
    }, [offerProd_seller])

    useEffect(() => {
        getList(name);
    }, [name])

    const getList = (url) => {
        let payload = {
            offerStatusType: url,
            page: 1
        }
        getOffersListSeller(payload)(dispatch)
    }



    const loadMore = () => {
        setButtonLoader(true);
        setTimeout(() => setButtonLoader(false), 700);
    }




    return (
        <div className="plp-product-screen">
            {/* <SearchFilter
                    loadSearchedData={loadSearchedData}
                    getDefaultValue={getDefaultValue}
                /> */}

            {
                offerProd_seller.data &&
                <div className="listing-content offered-content">
                    <div className="listing-head">
                        <div className="image-title my-listing-title"></div>
                        <div className="price-title my-listing-title">Name/Price</div>
                        <div className="qty-title my-listing-title">Total offers</div>
                        <div className="nav-title my-listing-title">Action</div>
                    </div>
                    <div className="listing-values">
                        {
                            offerProd_seller.data.map(data =>
                                <ProductCard
                                    item={data}
                                    type="offered"
                                    name={name}
                                />
                            )
                        }
                    </div>
                </div>
            }
            {offerProd_seller.data &&
                offerProd_seller.data.length === 0 && (
                    <div className="product-head">
                        <div className="product-img">
                            <img src="\assets\images\no-product.png" alt="img"></img>
                        </div>
                        <h6>No product found</h6>
                    </div>
                )
            }

            {
                offerProd_seller.data &&
                    offerProd_seller.totalLength > offerProd_seller.data.length ? (
                    <div className="plp-loadMore-div">
                        <button className="shop-now" onClick={loadMore}>
                            {buttonLoader ? <ButtonLoader content={"Loading"} /> : "Load more"}
                        </button>
                    </div>
                ) : null
            }

        </div>
    )
}

export default OfferProduct;