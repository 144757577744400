import React from 'react';
import { connect } from "react-redux";
import { getReferal } from '../../store/actions/myReferal'

class index extends React.Component {

    state = {
        data: {},
        referalCode: "",
    }

    componentDidMount = () => {
        let userId = localStorage.getItem('userId')
        this.props.getReferal(userId, res => {
            this.setState({ data: res })

        })
    }

    copyCode = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
    }

    getDate = (created_at) => {
        let newDate = created_at.split("T")
        let date = new Date(newDate[0]);
        let getmonth = date.getMonth()
        let month = date.toLocaleString('default', { month: 'short' });
        let dob = newDate[0].split("-")
        let date1 = `${dob[2]} ${month} ${dob[0]}`
        return date1
    }

    render() {

        return (
            <div className="referal-wrap">
                <h1>Referals</h1>
                <div className="code-wrap">

                    <div className="referral-code">
                        <strong>Your Referal code</strong>
                        <div className="share-code">
                            <input
                                ref={(textarea) => this.textArea = textarea}
                                value={this.state.data.data && this.state.data.data[0].refferal_code}
                                className="referral-codewrap"
                            />
                            {
                                //<span className="referral-codewrap">{this.state.data && this.state.data.refferal_code}</span>    
                            }

                            <button onClick={this.copyCode}>Copy</button>
                        </div>

                    </div>
                    <div className="total-referral referral-code">
                        <strong>Total Referal</strong>
                        <div className="share-code">
                            <span className="referral-codewrap">{this.state.data.referal_data && this.state.data.referal_data.length}</span>
                        </div>
                    </div>
                </div>
                <div className="reffered-user">
                    <h2>Refered Users</h2>
                    <ul>
                        {
                            this.state.data.referal_data &&
                            this.state.data.referal_data.map((item, i) =>
                                <li className="reffered-userinfo">
                                    <div className="user-wrap">
                                        <figure>
                                            {
                                                item.user_image ?
                                                    <img src={item.user_image} alt="avtar" />
                                                    : <img src="assets/images/avtar.png" alt="avtar" />
                                            }
                                        </figure>
                                        <span>{item.full_name}</span>
                                    </div>
                                    <span className="referral-date">{this.getDate(item.createdAt)}</span>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mybids: state.myBids,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReferal: (payload, res) => dispatch(getReferal(payload, res)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(index)