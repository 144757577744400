import React from "react";
import { connect } from "react-redux";
import {
  createCoupon,
  getCoupon,
  removeCoupon,
  getProductWithCoupon,
} from "../../store/actions/myCoupon";
import { getUserAddedProduct } from "../../store/actions/profile";
import "./Coupon.css";
import swal from 'sweetalert';


class index extends React.Component {
  state = {
    couponCode: "",
    discount: "",
    expiry: "",
    product_list: [],
    tab: {
      apply: true,
      list: false,
    },
    searchKey: "",
    sellerProd: [],
  };

  componentDidMount() {
    const userId = localStorage.getItem("userId");
    this.props.getCoupon({ sellerId: userId });
    this.props.getUserAddedProduct();
  }
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkSelectedProd = (e) => {
    let product_list = [...this.state.product_list];
    if (e.target.checked) {
      product_list.push(e.target.value);
      this.setState({ product_list: product_list });
    } else {
      let allArray = product_list.filter((item) => item !== e.target.value);
      this.setState({ product_list: allArray });
    }
  };

  changeTab = (name) => {
    this.setState({ tab: { [name]: !this.state[name] } });
  };

  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.product_list.length === 0) {
      swal("Please select product")
      return false;
    }
    const userId = localStorage.getItem("userId");
    let payload = {
      coupon_code: this.state.couponCode.toLowerCase(),
      product_list: this.state.product_list,
      discount_amount: this.state.discount,
      sellerId: userId,
      expirey: this.state.expiry,
    };

    this.props.createCoupon(payload);
    let element = document.querySelector(".coupon-wrapper");
    element.scrollIntoView();
    let newData = this.state.sellerProd.product.filter(
      (item) => !this.state.product_list.includes(item._id)
    );
    this.setState({
      couponCode: "",
      discount: "",
      expiry: "",
      product_list: [],
      sellerProd: { product: newData },
    });
  };

  getExpiryDate = (expiry) => {
    let newDate = expiry.split("T");
    let date = new Date(newDate[0]);
    let getmonth = date.getMonth();
    let month = date.toLocaleString("default", { month: "short" });
    let dob = newDate[0].split("-");
    let validDate = `Valid till ${dob[2]} ${month}`;
    return validDate;
  };

  removeProduct = (coupponid, prodId) => {
    this.props.removeCoupon({ coupponid, prodId });
    setTimeout(() => {
      this.props.getUserAddedProduct();
    }, 500);
  };

  componentDidUpdate(PrevProps) {
    if (PrevProps.myProduct != this.props.myProduct) {
      this.setState({ sellerProd: this.props.myProduct });
    }
  }

  render() {
    const { tab, sellerProd } = this.state;
    return (
      <div className="coupon-wrapper">
        <ul className="coupon-tab">
          <li
            className={tab.apply ? "active" : null}
            onClick={() => this.changeTab("apply")}
          >
            Add new coupon
          </li>
          <li
            className={tab.list ? "active" : null}
            onClick={() => this.changeTab("list")}
          >
            Added coupon list
          </li>
        </ul>
        {tab.apply && (
          <div className="coupon-tabcontent">
            <form onSubmit={this.submitHandler}>
              <ul className="coupon-infobtn">
                <li>
                  <label>Add new coupon</label>
                  <input
                    required
                    name="couponCode"
                    value={this.state.couponCode}
                    onChange={this.onChangeHandler}
                    type="text"
                  ></input>
                </li>
                <li>
                  <label>Added coupon list</label>
                  <div className="input-wrap">
                    <input
                      required
                      name="discount"
                      value={this.state.discount}
                      onChange={this.onChangeHandler}
                      type="number"
                    ></input>
                    <span className="discount-label">%</span>
                  </div>
                </li>
                <li>
                  <label>Expiry</label>
                  <input
                    min={new Date().toISOString().split("T")[0]}
                    required
                    name="expiry"
                    value={this.state.expiry}
                    onChange={this.onChangeHandler}
                    type="date"
                    pattern="\d{4}-\d{2}-\d{2}"
                  />
                </li>
              </ul>
              <div className="responsive-table">
                <table
                  className="bids-wrapper coupons-wrapper"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <tr className="bid-headings">
                    <th className="product-images">Product image</th>
                    <th className="product-descriptions">Product name</th>
                    <th className="product-prices">Price</th>
                    <th className="product-checkmarks">Action</th>
                  </tr>
                  {sellerProd &&
                    sellerProd.product &&
                    sellerProd.product.map((item, i) => {
                      if (!item.isCoupon) {
                        return (
                          <tr>
                            <td className="bid-wrap">
                              <img src={item.default_image} alt="avtar"></img>
                            </td>
                            <td>{item.Title}</td>
                            <td>{item.Price.current_store_price}</td>
                            <td>
                              <label className="checkbox-wrap">
                                <input
                                  onChange={this.checkSelectedProd}
                                  value={item._id}
                                  type="checkbox"
                                ></input>
                                <span className="checkmark"></span>
                              </label>
                            </td>
                          </tr>
                        );
                      }
                    })}
                </table>
              </div>
              <button className="coupon-btn">Save</button>
            </form>
          </div>
        )}

        {tab.list && (
          <div className="coupon-listcontent coupon-tabcontent">
            <div className="product-searchwrap">
              <form>
                <div className="global-search">
                  <input
                    type="text"
                    onChange={(e) =>
                      this.setState({ searchKey: e.target.value })
                    }
                    placeholder="Search by coupon code"
                  ></input>
                  <i className="icon-search"></i>
                </div>
              </form>
            </div>
            <div className="responsive-table">
              <table
                className="bids-wrapper coupons-wrapper"
                cellPadding="0"
                cellSpacing="0"
              >
                <tr className="bid-headings">
                  <th>Product image</th>
                  <th>Product name</th>
                  <th>Coupon code</th>
                  <th>Discount</th>
                  <th>Expiry</th>
                  <th>action</th>
                </tr>
                {
                  // tab.list &&
                  this.props.couponList &&
                  this.props.couponList.map((item) => {
                    return item.data.map((item1) => {
                      if (
                        item1.coupon_code
                          .toLowerCase()
                          .includes(this.state.searchKey.toLowerCase())
                      ) {
                        if (item1.products_data[0]) {
                          return (
                            <tr>
                              <td className="bid-wrap">
                                <img
                                  src={item1.products_data[0].default_image}
                                  alt="avtar"
                                ></img>
                              </td>
                              <td>{item1.products_data[0].Title}</td>
                              <td>{item1.coupon_code}</td>
                              <td>{item1.discount_amount}% off</td>
                              <td>{this.getExpiryDate(item1.expirey)}</td>
                              <td
                                className="remove-product"
                                onClick={() =>
                                  this.removeProduct(
                                    item._id,
                                    item1.products_data[0]._id
                                  )
                                }
                              >
                                x
                              </td>
                            </tr>
                          );
                        }
                      }
                    });
                  })
                }
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    myProduct: state.userProfile.userAddedProduct,
    couponList: state.couponList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCoupon: (payload) => dispatch(createCoupon(payload)),
    getCoupon: (payload) => dispatch(getCoupon(payload)),
    removeCoupon: (payload) => dispatch(removeCoupon(payload)),
    getUserAddedProduct: () => dispatch(getUserAddedProduct()),
    getProductWithCoupon: (payload) => dispatch(getProductWithCoupon(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
