
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCountry, getBazaarList, sendOTPHandler, verifyGSTNumber, getSeller, verifyAccountNumber, verifyPanNumber, verifyOTP, sendOtpAadhar, verifyAadharOTP, submitSellerRegistration, updateSellerRegistration, getBankDetail } from '../../store/actions/sellerRegisterationAction'
import { useToasts } from "react-toast-notifications";
import ReactCodeInput from 'react-verification-code-input';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import Toast from "light-toast";
import Loader from '../Loader';
import { getProfileBannerInfo } from '../../store/actions/profile';
import CreatableSelect from 'react-select/creatable'
import "../global_modal.css"
import ProgressBar from "@ramonak/react-progress-bar";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'



function SellerRegistrationForm(props) {
    const params = useParams();
    let dispatch = useDispatch();
    const [countrydata, setCountryData] = useState([])
    const [stateDataArr, setStateData] = useState({})
    const [shopImg, setShopImg] = useState(null)
    const [isshopError, setIsshopError] = useState(false);

    const [chequeImg, setChequeImg] = useState(null)
    const [ischequeError, setIschequeError] = useState(false);
    const [gstPdf, setGstPdf] = useState(null)
    const [isgstpdf, setIsgstpdf] = useState(false);

    const [adharNo, setAdharNo] = useState("");
    const [mob, setMob] = useState("");
    const [checkMob, setCheckMob] = useState("");
    const [isSendOtp, setIsSendOtp] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [MobileValidation, setMobileValidation] = useState(false);
    const [emptyMobileValid, setemptyMobileValid] = useState(false);


    const [firstName, setfirstName] = useState("");
    const [firstNameError, setfirstNameError] = useState(false);

    const [lastName, setlastName] = useState("");
    const [lastNameError, setlastNameError] = useState(false);

    const [number, setnumber] = useState("");
    const [numberError, setnumberError] = useState(false);

    const [streetnumber, setstreetnumber] = useState("");
    const [streetnumberError, setstreetnumberError] = useState(false);

    // const [landmark, setlandmark] = useState("");
    const [manufactur, setManufactur] = useState([]);
    // const [landmarkError, setlandmarkError] = useState(false);
    const [manufacturError, setManufacturError] = useState(false);


    const [zipcodeError, setzipcodeError] = useState(false);
    const [validZipcodeError, setValidZipcodeError] = useState(false);

    const [country, setcountry] = useState("");
    const [countryError, setcountryError] = useState(false);


    const [stateError, setstateError] = useState(false);



    const [cityError, setcityError] = useState(false);

    const [address, setaddress] = useState("");
    const [addressError, setaddressError] = useState(false);

    const [gstNo, setGstNo] = useState("");
    const [gstNoError, setgstNoError] = useState(false);
    const [YOBusiness, setYOBusiness] = useState("");
    const [affiliation, setAffiliation] = useState("");
    const [memberShipNo, setMemberShipNo] = useState("");
    const [agregate, setAgregate] = useState("");
    const [interestBuy, setInterestBuy] = useState([]);

    const [contactPerson, setContactPerson] = useState("");
    const [designation, setDesignation] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [whatsapp, setWhatsapp] = useState("");

    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [zipcode, setzipcode] = useState("");

    const [website, setWebsite] = useState("");
    const [facebook, setFacebook] = useState("");
    const [google, setGoogle] = useState("");
    const [linkedIn, setLinkedIn] = useState("");

    const [selectedImagePan, setSelectedImagePan] = useState(null);
    const [selectedImageAdhaar, setSelectedImageAdhaar] = useState(null);
    const [selectedImageGst, setSelectedImageGst] = useState(null);

    const [processBar, setProcessBar] = useState(0);
    const [invalidEmail, setInvalidEmail] = useState(false);


    const [panNo, setPanNo] = useState("");
    const [panNoError, setpanNoError] = useState(false);

    const [shopName, setshopName] = useState("");
    const [shopNameError, setshopNameError] = useState(false);

    const [adhaar, setadhaar] = useState("");
    const [adhaarError, setadhaarError] = useState(false);

    const [gstfile, setgstfile] = useState("");
    const [gstfileError, setgstfileError] = useState(false);

    const [bankName, setbankName] = useState("");
    const [bankNameError, setbankNameError] = useState(false);

    const [accountNumber, setaccountNumber] = useState("");
    const [accountNumberError, setaccountNumberError] = useState(false);
    const [validaccountNumberError, setvalidAccountNumberError] = useState(false);

    const [confirmaccountNumber, setconfirmaccountNumber] = useState("");
    const [confirmaccountNumberError, setconfirmaccountNumberError] = useState(false);

    const [ifsc, setifsc] = useState("");
    const [ifscError, setifscError] = useState(false);
    const [ifscValidError, setifscValidError] = useState(false);

    const [accountHolder, setaccountHolder] = useState("");
    const [accountHolderError, setaccountHolderError] = useState(false);

    const [password, setpassword] = useState("");
    const [passwordError, setpasswordError] = useState(false);

    const [confirmPassword, setconfirmPassword] = useState("");
    const [confirmPasswordError, setconfirmPasswordError] = useState(false);

    const [terms, setTerms] = useState(false);
    const [termsError, setTermsError] = useState(false);

    const [MatchAccountNumber, setMatchAccountNumber] = useState(false);
    const [MatchPassword, setMatchPassword] = useState(false);
    const [ResendOtp, setResendOtp] = useState(false);
    const [ClientId, setClientId] = useState("");
    const [AadharVerify, setAadharVerify] = useState(false);
    const [adharValidation, setadharValidation] = useState(false);
    const [profileComponent, setProfileComponent] = useState(null);
    const { addToast } = useToasts();


    const [selectedState, setSelectedState] = useState("");
    const [selectedAddress, setSelectedAddress] = useState("");
    const [seletectedShopImage, setSeletectedShopImage] = useState("");
    const [selectedGstPdf, setSelectedGstPdf] = useState("");
    const [selectedCheqeImage, setSelectedCheqeImage] = useState("");
    const [validGstNumber, setValidGstNumber] = useState(false);
    const [panValidError, setPanValidError] = useState("");
    const [validAdhar, setValidAdhar] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [disableEmail, setDisableEmail] = useState(false);
    const [verifiedMob, setVerifiedMob] = useState("");


    const [phoneVerified, setPhoneVerified] = useState(false);
    const [aadhaarVerified, setAadhaarVerify] = useState(false);

    const [panVerified, setPanVerified] = useState(false);

    const [isGstVerified, setIsGstVerified] = useState(false);
    const [isPanVerified, setIsPanVerified] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [isVerifyGst, setIsVerifyGst] = useState(false);
    const [isVerifyPan, setIsVerifyPan] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [passDigits, setPassDigits] = useState(false);
    const [confPassDigits, setConfPassDigits] = useState(false);
    const [selectedValueBazar, setSelectedValueBazar] = useState(null);
    const [searchedDex, setSearchDex] = useState([]);
    const [options, setOptions] = useState([]);
    const [BazarList, setBazarList] = useState([]);

    const [addressSection, setAddressSection] = useState(true);
    const [businessSection, setBusinessSection] = useState(false);
    const [bankSection, setBankSection] = useState(false);
    const [urlSection, setUrlSection] = useState(false);
    const [documentSection, setDocumentSection] = useState(false);
    const [bazarError, setBazarError] = useState(false);
    const [previousNext, setPreviousNext] = useState(true);
    const [getMarketId, setMarketId] = useState("");




    useEffect(() => {
        document.getElementById('id01').style.display = 'block'
        props.getBankDetail({}, (status) => {
            setBankList(status);
        });
        setProfileComponent(null);
        const getToken = localStorage.getItem("token");
        if (props.location.pathname === props.path) {
            setAddressSection(true);
            setBusinessSection(true);
            setBankSection(true);
            setProfileComponent(true);
            setDisableEmail(true);
            setPreviousNext(false)
            getSeller(callback => {
                setCheckMob(callback[0].mobile);
                setnumber(callback[0].addressData.flat_no);
                setstreetnumber(callback[0].addressData.street);

                setzipcode(callback[0].addressData.postcode);
                setcity(callback[0].addressData.city);
                setshopName(callback[0].companyName);
                setadhaar(callback[0].aadhar_number);

                setbankName(callback[0] && callback[0].bankData && callback[0].bankData.bankName);
                setaccountNumber(callback[0] && callback[0].bankData && callback[0].bankData.accountNo);
                setconfirmaccountNumber(callback[0] && callback[0].bankData && callback[0].bankData.accountNo);
                setifsc(callback[0] && callback[0].bankData && callback[0].bankData.ifsc);
                setaccountHolder(callback[0] && callback[0].bankData && callback[0].bankData.accountHolderName);
                setpassword(callback[0].Password);
                if (options.length > 0) {
                    setSelectedValueBazar(options.filter(option => option.value === callback[0].marketId));
                }
                setSelectedAddress(callback[0].type);
                setSelectedState(callback[0] && callback[0].addressData && callback[0].addressData.selectedStateData && callback[0].addressData.selectedStateData._id);
                setSeletectedShopImage(callback[0].shop_image);
                setShopImg(callback[0].shop_image);
                setSelectedGstPdf(callback[0].gstFile);
                setgstfile(callback[0].gstFile);
                setSelectedCheqeImage(callback[0].cheque);
                setChequeImg(callback[0].cheque)
                setPanNo(callback[0].pan);
                setstate(callback[0] && callback[0].addressData && callback[0].addressData.selectedStateData && callback[0].addressData.selectedStateData._id);
                setconfirmPassword("confirmPassword");
                setpassword("password");

                setTerms(true);

            })(dispatch)
        }
        getCountry(callback => {
            let newState = [...callback];
            setCountryData(newState);
            setcountry(newState[0]._id)
            setStateData(newState[0]);
            setaddress("Warehouse");
        })(dispatch)

    }, [props.path, options])


    useEffect(() => {
        if (props.location.state && props.location.state.state == "login") {

        }
        if (props.location.pathname == "/profile/businessProfile") {

        }
        if (props.location.pathname == "/sellerRegistration/footer" && (props.adminStatus && props.adminStatus.isSeller)) {
            props.history.push("/sell-product");
            return
        }
        if (props.adminStatus && props.adminStatus.sellerCreatedByAdmin) {
            localStorage.setItem("incomplete", props.adminStatus.sellerProfileCreatedByAdminCompleted)
        }
    }, [props.adminStatus])


    const checkValidNumber = (e) => {
        if (e.target.value.length === 15) {
            setShowLoader(true);
            setShowMessage("GST Verifying...")
            const payload = {
                gstId: gstNo
            }
            props.verifyGSTNumber(payload, (status) => {
                if (status.code == 500) {
                    setIsVerifyGst(false);
                    setShowLoader(false);
                    setIsGstVerified(false);
                    addToast(status.message, {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                } else if (status.code == 200) {
                    setIsVerifyGst(true);
                    setShowLoader(false);
                    setIsGstVerified(true);
                    addToast(status.message, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                }
            })
        }
    }


    const checkValidPanNumber = (e) => {
        if (e.target.value.length === 10) {
            setShowLoader(true);
            setShowMessage("Pan Veryfing...");
            const payload = {
                panNumber: panNo
            }
            props.verifyPanNumber(payload, (status) => {
                if (status.code === 200) {
                    setIsVerifyPan(true);
                    setIsPanVerified(true);

                    setShowLoader(false);
                    addToast(status.message, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                } else if (status.code === 500) {
                    setIsVerifyPan(false);
                    setIsPanVerified(false);
                    setShowLoader(false);
                    if (status.message == "Internal server error") {
                        var message = "Invalid PAN Number"
                    }
                    addToast(message, {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                }
            })
        }
    }

    useEffect(() => {
        // getBazaarList({}, (status) => {
        //     if (status.status == "success") {
        //         for (let i = 0; i < status.data.length; i++) {
        //             BazarList.push({ label: status.data[i].name, value: status.data[i]._id })
        //         }
        //         setOptions(BazarList);
        //     } else {
        //     }

        // })(dispatch);
    }, [])

    const getSelectedValue = (newValue) => {
        setSelectedValueBazar(newValue);
        setBazarError(false);
        setProfileComponent(null)
    };

    const handleInputChange = (e) => {
        if (e) {
            // getBazaarList({ search: e }, (status) => {
            //     if (status.status == "success") {
            //         setSearchDex([])
            //         for (let i = 0; i < status.data.length; i++) {
            //             searchedDex.push({ label: status.data[i].name, value: status.data[i]._id })
            //         }
            //         setOptions(searchedDex);
            //     } else {
            //     }

            // })(dispatch);
        }
    }




    const showDigits = (value) => {
        if (value === "password") {
            setPassDigits(!passDigits);
        } else if (value === "confirmpassword") {
            setConfPassDigits(!confPassDigits);
        }

    }


    const checkValidAccountNumber = (e) => {
        if (e.target.value.length > 1) {
            const payload = {
                accountNumber: accountNumber,
                ifsc: ifsc
            }
            props.verifyAccountNumber(payload, (status) => {
                if (status.code === 200) {
                    addToast(status.message, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                } else if (status.code === 500) {
                    addToast(status.message, {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                }
            })
        }
    }

    const sendOTP = () => {
        if (mob.length === 10) {
            setShowLoader(true);
            setShowMessage("Please wait...");
            sendOTPHandler({ mobile: mob }, callback => {
                if (callback.status == "success") {
                    setShowLoader(false);
                    setIsSendOtp(true);
                    addToast(callback.message, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                } else {
                    setShowLoader(false);
                    addToast(callback.Message, {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                }
            })(dispatch)
        }

    }


    const verifyOtp = (val) => {
        setShowLoader(true);
        setShowMessage("Please wait...")
        verifyOTP({ otp: val }, callback => {
            if (callback) {
                setShowLoader(false);
                setIsSendOtp(false)
                setIsVerify(true);
                setPhoneVerified(false);
                addToast("Phone number verified", {
                    appearance: "success",
                    autoDismissTimeout: 2500,
                });
                setVerifiedMob(mob);
            } else {
                setShowLoader(false);
                addToast("This OTP not valid.", {
                    appearance: "error",
                    autoDismissTimeout: 2500,
                });
            }
        })(dispatch)

    }

    const verifyOtpAadhar = (val) => {
        setShowLoader(true);
        setShowMessage("Verifying...");
        const payload = {
            clientId: ClientId,
            otp: val,
            mobile: 7895154317,
            aadharNumber: adhaar
        }
        props.verifyAadharOTP(payload, (status) => {

            if (status.status === "success") {
                setShowLoader(false);
                setResendOtp(false);
                setAadharVerify(true);
                setAadhaarVerify(false);
                addToast(status.message, {
                    appearance: "success",
                    autoDismissTimeout: 2500,
                });
            } else {
                setShowLoader(false);
                addToast(status.message, {
                    appearance: "error",
                    autoDismissTimeout: 2500,
                });
            }
        })
    }






    const sendOtpAadhar = () => {
        setShowLoader(true);
        setShowMessage("Sending...");

        const payload = {
            aadharNumber: adhaar
        }
        props.sendOtpAadhar(payload, (status) => {

            if (status.type == "Timeout, Please try again") {
                setShowLoader(false);
                addToast("Please try again", {
                    appearance: "error",
                    autoDismissTimeout: 2500,
                });
            } else {
                if (status.data && status.data.message_code === "success") {
                    setShowLoader(false);
                    setResendOtp(true);
                    addToast(status.data.message, {
                        appearance: "success",
                        autoDismissTimeout: 2500,
                    });
                    setClientId(status.data.data.client_id);
                    setadharValidation(false);

                } else if (status.data && status.data.message === "Request failed with status code 422" || status.status === "invalid") {
                    setShowLoader(false);
                    addToast("please try again or Check your aadhaar number", {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                }
                else {
                    setShowLoader(false);
                    addToast(status.data[0].msg, {
                        appearance: "error",
                        autoDismissTimeout: 2500,
                    });
                }
            }
        })

    }


    const flatnumberhandler = (e) => {
        setnumber(e.target.value);
        setnumberError(false);
    }

    const streetnumberhandler = (e) => {
        setstreetnumber(e.target.value);
        setstreetnumberError(false);
    }

    const manufactureHandler = (e) => {
        setManufactur(e);
        setManufacturError(false);
    }


    const countryhandler = (e) => {
        setcountry(e.target.value);
        setcountryError(false);
    }





    const addresshandler = (e) => {
        setaddress(e.target.value);
        setaddressError(false);
    }

    const fileHandler = (e) => {
        setSeletectedShopImage("");
        setShopImg(e.target.files[0])
        setIschequeError(false)
    }

    const chequeHandler = (e) => {
        setSelectedCheqeImage("");
        setChequeImg(e.target.files[0])
        setIsshopError(false)
    }


    const filegstHandler = (e) => {
        setSelectedGstPdf("");
        setgstfile(e.target.files[0]);
        setgstfileError(false);
    }


    const shopnamehandler = (e) => {
        if (!(/[^A-Za-z\d\s]/.test(e.target.value))) {
            setshopName(e.target.value);
            setshopNameError(false);
        }

    }

    //////////////////// onchange Handlers ////////////////////////

    const getGstNumber = (e) => {
        setGstNo(e.target.value);
        setgstNoError(false);
    }

    const Businesshandler = (e) => {
        setYOBusiness(e.target.value);
    }
    const affiliationshandler = (e) => {
        setAffiliation(e.target.value);
    }

    const Membershiphandler = (e) => {
        setMemberShipNo(e.target.value);
    }

    const agregateHandler = (e) => {
        setAgregate(e.target.value);
    }

    const buyInterestHandler = (e) => {
        setInterestBuy(e);
    }

    const CPersonHandler = (e) => {
        setContactPerson(e.target.value);
    }

    const designationhandler = (e) => {
        setDesignation(e.target.value);
    }

    const emailhandler = (e) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (filter.test(e.target.value)) {
            setEmail(e.target.value);
            setInvalidEmail(false);
        } else {
            setEmail(e.target.value);
            setInvalidEmail(true);
        }


    }

    const phonehandler = (e) => {
        setPhone(e.target.value);
    }

    const whatsapphandler = (e) => {
        setWhatsapp(e.target.value);
    }

    const addressOnehandler = (e) => {
        setAddressOne(e.target.value);
    }

    const addressTwohandler = (e) => {
        setAddressTwo(e.target.value);
    }

    const cityhandler = (e) => {
        setcity(e.target.value);
    }

    const statehandler = (e) => {
        setstate(e.target.value);
        setstateError(false);
    }


    const zipcodehandler = (e) => {
        setzipcode(e.target.value);
    }

    const Websitehandler = (e) => {
        setWebsite(e.target.value);
    }

    const facebookhandler = (e) => {
        setFacebook(e.target.value);
    }

    const googlehandler = (e) => {
        setGoogle(e.target.value);
    }

    const linkdinhandler = (e) => {
        setLinkedIn(e.target.value);
    }

    const panhandler = (e) => {
        setSelectedImagePan(e.target.files[0])
    }

    const adhaarhandler = (e) => {
        setSelectedImageAdhaar(e.target.files[0]);
    }

    const gstHandler = (e) => {
        setSelectedImageGst(e.target.files[0]);
    }

    const handleWatsappCheck = (e) => {
        if (e.target.checked) {
            setWhatsapp(phone);
        } else {
            setWhatsapp("");
        }
    }

    const pannumberhandler = (e) => {
        if (e.target.value.length < 10) {
            if (!(/[^A-Za-z\d]/.test(e.target.value))) {
                setPanValidError(true);
                setPanNo(e.target.value);
                setpanNoError(false);
            }

        } else {
            if (!(/[^A-Za-z\d]/.test(e.target.value))) {
                setPanValidError(false);
                setPanNo(e.target.value);
                setpanNoError(false);
            }

        }
    }



    const handleTerms = (e) => {
        setTerms(e.target.checked);
        setTermsError(false);
    }



    const banknamehandler = (e) => {
        setbankName(e.target.value);
        setbankNameError(false);
    }

    const accountnumberhandler = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            if (e.target.value.length < 9 || e.target.value.length > 18) {
                setvalidAccountNumberError(true);
                setaccountNumber(e.target.value);
                setaccountNumberError(false);
            } else {
                setvalidAccountNumberError(false);
                setaccountNumber(e.target.value);
                setaccountNumberError(false);
            }
        }
    }

    const confirmaccounthandler = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            if (e.target.value == accountNumber) {
                setconfirmaccountNumber(e.target.value);
                setconfirmaccountNumberError(false);
                setMatchAccountNumber(false);
            }
            else {
                setconfirmaccountNumberError(false);
                setMatchAccountNumber(true);
                setconfirmaccountNumber(e.target.value);
            }
        }

    }

    const ifschandler = (e) => {
        if (e.target.value.length < 11) {
            if (!(/[^A-Za-z\d]/.test(e.target.value))) {
                setifscValidError(true);
                setifsc(e.target.value);
                setifscError(false);
            }

        } else {
            if (!(/[^A-Za-z\d]/.test(e.target.value))) {
                setifscValidError(false);
                setifsc(e.target.value);
                setifscError(false);
            }

        }


    }

    const accountholdernamehandler = (e) => {
        const re = /^[A-Za-z\s]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setaccountHolder(e.target.value);
            setaccountHolderError(false);
        }

    }





    function editMode() {
        setAadharVerify(true);
        setIsVerify(true);
        setProfileComponent(!profileComponent);
        setIsGstVerified(true);
        setIsPanVerified(true);
        setDisableEmail(true);
        setIsVerifyPan(true);
        setIsVerifyGst(true);

        getSeller(callback => {
            setCheckMob(callback[0].mobile);
            setnumber(callback[0].addressData.flat_no);
            setstreetnumber(callback[0].addressData.street);
            setzipcode(callback[0].addressData.postcode);
            setcity(callback[0].addressData.city);
            setshopName(callback[0].companyName);
            setadhaar(callback[0].aadhar_number);
            setbankName(callback[0] && callback[0].bankData && callback[0].bankData.bankName);
            setaccountNumber(callback[0] && callback[0].bankData && callback[0].bankData.accountNo);
            setconfirmaccountNumber(callback[0] && callback[0].bankData && callback[0].bankData.accountNo);
            setifsc(callback[0] && callback[0].bankData && callback[0].bankData.ifsc);
            setaccountHolder(callback[0] && callback[0].bankData && callback[0].bankData.accountHolderName);
            setpassword(callback[0].Password);
            setSelectedAddress(callback[0].type);
            setSelectedState(callback && callback[0].addressData && callback[0].addressData.selectedStateData && callback[0].addressData.selectedStateData._id);
            setSeletectedShopImage(callback[0].shop_image);
            setShopImg(callback[0].shop_image);
            setSelectedGstPdf(callback[0].gstFile);
            setgstfile(callback[0].gstFile);
            setSelectedCheqeImage(callback[0].cheque);
            setChequeImg(callback[0].cheque)
            setPanNo(callback[0].pan);
            setstate(callback && callback[0].addressData && callback[0].addressData.selectedStateData && callback[0].addressData.selectedStateData._id);

            setTerms(true);
        })(dispatch)
    }

    function isAddressValid() {
        if (manufactur == "" || manufactur == null) {
            setManufacturError(true);
        } else if (gstNo == "") {
            setgstNoError(true);
            document.getElementById("gst").focus();
        }
        else {
            return true
        }
    }

    function isBusinessValid() {
        if (invalidEmail == true) {
            document.getElementById("emailFocus").focus();
        }
        else {
            return true
        }
    }

    function isBankValid() {
        if (state == "") {
            setstateError(true);
            //setbankNameError(true);
            //document.getElementById("bankname").focus();
        }
        // else if (accountNumber == "" || accountNumber == null) {
        //     setaccountNumberError(true);
        //     setvalidAccountNumberError(false);
        //     document.getElementById("accountnumber").focus();
        // } else if (confirmaccountNumber == "" || confirmaccountNumber == null) {
        //     setconfirmaccountNumberError(true);
        //     setMatchAccountNumber(false);
        //     document.getElementById("confirmac").focus();
        // } else if (ifsc == "" || ifsc == null) {
        //     setifscError(true);
        //     setifscValidError(false);
        //     document.getElementById("ifsc").focus();
        // } else if (ifsc && ifsc.length < 11) {
        //     setifscError(false);
        //     setifscValidError(true);
        //     document.getElementById("ifsc").focus();
        // }
        // else if (accountHolder == "" || accountHolder == null) {
        //     setaccountHolderError(true);
        //     document.getElementById("acholder").focus();
        // }

        // else if (terms == false) {
        //     setTermsError(true);
        //     document.getElementById("term").focus();
        // }
        else {
            return true;
        }
    }

    function isUrlValid() {
        return true;
    }

    const submit = (e) => {
        e.preventDefault();
        if (isBankValid()) {
            setShowLoader(true);
            setShowMessage("Please wait...")
            const payload = {
                MailingAddressSameAsPermanentAddress: true,
                addressLine2: addressTwo,
                addressLine1: addressOne,
                interstInBuying: interestBuy,
                productManufacturing: manufactur,
                gstNumber: gstNo,
                businessYears: YOBusiness,
                membershipNumber: memberShipNo,
                zipCode: zipcode,
                monthlyNeed: agregate,
                state: state,
                city: city,
                Affiliations: affiliation,
                designation: designation,
                bankName: "hdfc",
                isWhatsappNumberSameAsMobile: false,
                accountNumber: "123412341234",
                ifsc: "HDFC0000000",
                accountHolderName: "victor",
                whatsappNumber: whatsapp,
                websiteLink: website,
                otherLink: google,
                facebookLink: facebook,
                linkedinLink: linkedIn,
                shopImage: "/home/spxlpt189/Pictures/Screenshots/Screenshot from 2022-10-04 15-22-01.png"
            }
            let formdata = new FormData();
            for (let item in payload) {
                formdata.append(item, payload[item])
            }
            formdata.append('PANImages', selectedImagePan);
            formdata.append('seller_image', shopImg);
            formdata.append('aadharImages', selectedImageAdhaar);
            formdata.append('gstFile', selectedImageGst);

            if (profileComponent === null) {
                props.submitSellerRegistration(formdata, (status) => {
                    if (status.status === "success") {
                        setProcessBar(100);
                        addToast("form submitted successfully", {
                            appearance: "success",
                            autoDismissTimeout: 2500,
                        });
                        setShowLoader(false);
                        props.handleForm();
                        window.location.reload();
                    } else {
                        addToast(status.message, {
                            appearance: "error",
                            autoDismissTimeout: 2500,
                        });
                        setShowLoader(false);
                    }
                })

            } else if (profileComponent === false) {
                props.updateSellerRegistration(formdata, (status) => {

                    if (status) {
                        if (localStorage.getItem("incomplete") == "false") {
                            addToast("Form updated successfully, Please wait for Admin Approval", {
                                appearance: "success",
                                autoDismissTimeout: 2500,
                            });
                            setShowLoader(false);
                            setProfileComponent(true);
                            window.scrollTo(0, 0);
                        } else {
                            addToast(status.message, {
                                appearance: "success",
                                autoDismissTimeout: 2500,
                            });
                            setShowLoader(false);
                            setProfileComponent(true);
                            window.scrollTo(0, 0);
                        }

                    } else {
                        setShowLoader(false);
                    }
                })
            } else {

            }
        }
    }


    const nextValidation = () => {
        if (addressSection) {
            if (isAddressValid()) {
                setProcessBar(20);
                setAddressSection(false);
                setBusinessSection(true);
                setBankSection(false);
                setUrlSection(false);
                setDocumentSection(false);
            }
        }
        else if (businessSection) {
            if (isBusinessValid()) {
                setProcessBar(40);
                setAddressSection(false);
                setBusinessSection(false);
                setBankSection(true);
                setUrlSection(false);
                setDocumentSection(false);
            }
        }
        else if (bankSection) {
            if (isBankValid()) {
                setProcessBar(60);
                setAddressSection(false);
                setBusinessSection(false);
                setBankSection(false);
                setUrlSection(true);
                setDocumentSection(false);
            }
        } else if (urlSection) {
            if (isUrlValid()) {
                setProcessBar(80);
                setAddressSection(false);
                setBusinessSection(false);
                setBankSection(false);
                setUrlSection(false);
                setDocumentSection(true);
            }
        }
    }

    const handlePrevious = () => {
        if (businessSection) {
            setAddressSection(true);
            setBusinessSection(false);
            setBankSection(false);
            setUrlSection(false);
            setDocumentSection(false);
        } else if (bankSection) {
            setAddressSection(false);
            setBusinessSection(true);
            setBankSection(false);
            setUrlSection(false);
            setDocumentSection(false);
        } else if (urlSection) {
            setAddressSection(false);
            setBusinessSection(false);
            setBankSection(true);
            setUrlSection(false);
            setDocumentSection(false);
        } else if (documentSection) {
            setAddressSection(false);
            setBusinessSection(false);
            setBankSection(false);
            setUrlSection(true);
            setDocumentSection(false);
        }
    }

    const closeModal = () => {
        props.handleForm();
        //localStorage.setItem("isProfileCompleted", true);
    }

    return (
        <>


            <div class="w3-container">
                <div id="id01" class="w3-modal seller-registration-form-modal">
                    <div class="w3-modal-content seller-registration-modal-content">
                        <div class="w3-container seller-registration-container">
                            <div className='seller-only without-login with-login business-profile'>
                                {showLoader ? <Loader message={showMessage} /> : null}
                                <div className='container'>
                                    <form onSubmit={(e) => submit(e)}>

                                        {addressSection ?
                                            <div className='login-details seller-login-details-wrap'>
                                                {profileComponent === null ? null : <><h3>Personal details</h3> <span className="edit-form header-sell" onClick={editMode}>{profileComponent === true ? <i class="fas fa-user-edit"></i> : null}{profileComponent === true ? "EDIT" : "BACK"}</span></>}
                                                <div className='registration-form-one-header'>
                                                    <div className='registration-form-head'>
                                                        {/* <span className='welcome-msz'>Welcome to the </span> */}
                                                        <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link>
                                                    </div>
                                                    <span onClick={closeModal} class="skip-btn">Skip</span>
                                                </div>
                                                <div className='complete-profile-wrap'>
                                                    <span className='login-msz-wrap'>Let’s complete your profile now</span>
                                                    <div className='step-count-registration'>
                                                        <span className='step-number'>Step 1</span>
                                                        <span className='total-step'>Out of 5</span>
                                                    </div>
                                                </div>
                                                <div className='process-bar-wrapper'>
                                                    <div className='progress-bar-wrapper'>
                                                        <ProgressBar completed={processBar} className="process-bar" />
                                                    </div>
                                                    <span className='completed-process'>20% complete</span>
                                                </div>
                                                <div className='login-description login-description-form-wrap'>
                                                    <div className='form-intput'>
                                                        <label>GST No.</label>
                                                        <div className='input_wrapper'>
                                                            <input
                                                                type="text"
                                                                name="gstNumber"
                                                                placeholder='Enter your GST number'
                                                                onChange={(e) => getGstNumber(e)}
                                                                id="gst"
                                                                disabled={profileComponent}
                                                                value={gstNo}
                                                                maxLength="15"
                                                            />
                                                            {gstNoError ?
                                                                <span className="error">
                                                                    Please enter GST no.
                                                                </span> : null
                                                            }
                                                        </div>

                                                    </div>

                                                    <div className='form-intput'>
                                                        <label>Year Established</label>
                                                        <input type="text" placeholder='Enter years' onChange={(e) => Businesshandler(e)} id="fnumber" disabled={profileComponent} value={YOBusiness} maxlength="100" />
                                                    </div>


                                                    <div className='form-intput select-affilation-wrap'>
                                                        <label>Affiliations & Assocations</label>
                                                        <div className='select-main-wrapper'>
                                                            <select onChange={(e) => affiliationshandler(e)} disabled={profileComponent}>
                                                                <option value="ab" clas>Select</option>
                                                                <option value="aq">Affiliations1</option>
                                                                <option value="aq">Affiliations2</option>
                                                                <option value="aq">Affiliations3</option>
                                                                <option value="aq">Affiliations5</option>
                                                                {/* {
                                                                countrydata.map(item => <option key={Math.random()} value={item._id} selected>{item.name}</option>)
                                                            } */}
                                                            </select>
                                                            <i className='icon-arrow-down'></i>
                                                        </div>
                                                    </div>

                                                    <div className='form-intput'>
                                                        <label>Enter Membership No.</label>
                                                        <input type="text" placeholder='Enter membership number' onChange={(e) => Membershiphandler(e)} id="street" disabled={profileComponent} value={memberShipNo} maxlength="100" />
                                                    </div>
                                                    <div className='add-new-wrapper'>
                                                        <button className='add-btn'><i className='icon-add'></i></button>
                                                        <span className='add-new'>Add New</span>
                                                    </div>
                                                    <div className='form-intput'>
                                                        <label>Aggregate Monthly Need  (in mt)</label>
                                                        <input type="text" placeholder='Enter aggregate' onChange={(e) => agregateHandler(e)} value={agregate} id="aggregate" disabled={profileComponent} maxlength="100" />
                                                    </div>

                                                    <div className='form-intput'>
                                                        <label>Products Manufacturing<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>
                                                        <TagsInput value={manufactur} onChange={(e) => manufactureHandler(e)} id="manufacture"
                                                            InputProps={{
                                                                inputProps: {
                                                                    placeholder: 'Enter product'
                                                                },
                                                            }}
                                                        />
                                                        {/* <input type="text" placeholder='Enter product' id="manufacture" onChange={(e) => manufactureHandler(e)} disabled={profileComponent} value={manufactur} maxlength="100" /> */}
                                                        {manufacturError ?
                                                            <span className="error">
                                                                {profileComponent ? null : "Please enter product"}
                                                            </span> : null
                                                        }
                                                    </div>

                                                    <div className='form-intput'>
                                                        <label>Interested in Buying</label>
                                                        <TagsInput value={interestBuy} onChange={(e) => buyInterestHandler(e)} id="landmark"
                                                            InputProps={{
                                                                inputProps: {
                                                                    placeholder: 'Enter product'
                                                                },
                                                            }}
                                                        />
                                                        {/* <input type="text" placeholder='Enter product' id="landmark" onChange={(e) => buyInterestHandler(e)} value={interestBuy} disabled={profileComponent} maxlength="100" required /> */}
                                                    </div>
                                                    {previousNext ?
                                                        <div className="registration-btn registration">
                                                            <input className="btn shop-now" type="button" value={"Next"} onClick={nextValidation} />
                                                        </div> : null

                                                    }

                                                </div>

                                            </div> : null
                                        }

                                        {businessSection ?
                                            <div className='login-details seller-login-details-wrap'>
                                                <div className='registration-form-one-header'>
                                                    <div className='registration-form-head'>
                                                        {/* <span className='welcome-msz'>Welcome to the </span> */}
                                                        <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link>
                                                    </div>
                                                    <span onClick={closeModal} class="skip-btn">Skip</span>
                                                </div>
                                                <div className='complete-profile-wrap'>
                                                    <span className='login-msz-wrap'>Fill your Contact Details</span>
                                                    <div className='step-count-registration'>
                                                        <span className='step-number'>Step 2</span>
                                                        <span className='total-step'>Out of 5</span>
                                                    </div>
                                                </div>
                                                <div className='process-bar-wrapper'>
                                                    <div className='progress-bar-wrapper'>
                                                        <ProgressBar completed={processBar} className="process-bar" />
                                                    </div>
                                                    <span className='completed-process'>40% complete</span>
                                                </div>
                                                <div className='login-description login-description-form-wrap'>
                                                    <div className='form-intput'>
                                                        <label>Contact Person</label>
                                                        <input type="text" placeholder='Enter name' onChange={(e) => CPersonHandler(e)} id="shopname" disabled={profileComponent} value={contactPerson} maxlength="50" />
                                                    </div>

                                                    <div className='form-intput'>
                                                        <label>Designation</label>
                                                        <div className="phone-number-field adhar-field">
                                                            <input
                                                                type="text"
                                                                placeholder='Enter Designation here'
                                                                name="aadharNumber"
                                                                onChange={(e) => designationhandler(e)}
                                                                id="aadhar"
                                                                maxlength="12"
                                                                value={designation}
                                                                disabled={profileComponent}
                                                            />
                                                        </div>




                                                    </div>

                                                    <div className='form-intput'>
                                                        <label>Email</label>
                                                        <input type="text" placeholder='Jnutting@yopmail.com' onChange={(e) => emailhandler(e)} id="emailFocus" disabled={profileComponent} value={email} maxlength="50" />
                                                        {invalidEmail ? <span>Please enter a valid email</span> : null}


                                                    </div>

                                                    <div className='form-intput'>
                                                        <label>Phone No.</label>
                                                        <input type="text" placeholder='Enter phone no.' onChange={(e) => phonehandler(e)} id="shopname" disabled={profileComponent} value={phone} maxlength="50" />

                                                    </div>

                                                    <div className='form-intput'>
                                                        <div className='wtsaap-number-wrap'>
                                                            <label>whatsapp No.</label>
                                                            <div className="radio-box">
                                                                <div className='radio-wrap'>
                                                                    <input type="checkbox" id="same" onChange={(e) => handleWatsappCheck(e)} />
                                                                    <span className='custom'></span>
                                                                </div>
                                                                <label for="same" className='label-same'>Same As Above</label>
                                                            </div>
                                                        </div>
                                                        <input type="text" placeholder='Enter Phone no. here' onChange={(e) => whatsapphandler(e)} id="shopname" disabled={profileComponent} value={whatsapp} maxlength="50" />
                                                    </div>
                                                    {previousNext ?
                                                        <div className="registration-btn registration-btn-wrpper">
                                                            <input className="btn shop-now go-back" type="button" value={"Go Back"} onClick={handlePrevious} />
                                                            <input className="btn shop-now next-btn" type="button" value={"Next"} onClick={nextValidation} />
                                                        </div> : null

                                                    }
                                                </div>

                                            </div > : null
                                        }

                                        {bankSection ?
                                            <div className='login-details seller-login-details-wrap'>
                                                <div className='registration-form-one-header'>
                                                    <div className='registration-form-head'>
                                                        {/* <span className='welcome-msz'>Welcome to the </span> */}
                                                        <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link>
                                                    </div>
                                                    <span onClick={closeModal} class="skip-btn">Skip</span>
                                                </div>
                                                <div className='complete-profile-wrap'>
                                                    <span className='login-msz-wrap'>Fill your Address</span>
                                                    <div className='step-count-registration'>
                                                        <span className='step-number'>Step 3</span>
                                                        <span className='total-step'>Out of 5</span>
                                                    </div>
                                                </div>
                                                <div className='process-bar-wrapper'>
                                                    <div className='progress-bar-wrapper'>
                                                        <ProgressBar completed={processBar} className="process-bar" />
                                                    </div>
                                                    <span className='completed-process'>60% complete</span>
                                                </div>
                                                <div className='login-description login-description-form-wrap '>
                                                    <div className='address-field-wrapper'>
                                                        <div className='addres-head-wrap'>
                                                            <h4>Manufacturing Plant Address</h4>
                                                        </div>
                                                        <div className='form-intput'>
                                                            <label>Address line 1</label>
                                                            <input type="text" placeholder='Type here' onChange={(e) => addressOnehandler(e)} id="shopname" disabled={profileComponent} value={addressOne} maxlength="50" />

                                                        </div>

                                                        <div className='form-intput'>
                                                            <label>Address line 2</label>
                                                            <input type="text" placeholder='Type here' onChange={(e) => addressTwohandler(e)} id="shopname" disabled={profileComponent} value={addressTwo} maxlength="50" />

                                                        </div>

                                                        <div className='form-intput'>
                                                            <label>city</label>
                                                            <input type="text" placeholder='Enter city name here' onChange={(e) => cityhandler(e)} id="city" disabled={profileComponent} value={city} maxlength="100" />
                                                        </div>

                                                        {/* <div className='form-intput'>
                                                        <label>state</label>
                                                        <select onChange={(e) => statehandler(e)} id="state" disabled={profileComponent}>
                                                            <option value={""}>Please select state</option>
                                                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                            
                                                        </select>
                                                        
                                                    </div> */}

                                                        <div className='form-intput select-affilation-wrap'>
                                                            <label>state<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>

                                                            <div className='select-main-wrapper'>
                                                                <select onChange={(e) => statehandler(e)} id="state" disabled={profileComponent}>
                                                                    <option value={""}>Select</option>
                                                                    {
                                                                        stateDataArr && stateDataArr.stateData && stateDataArr.stateData.map(item => <option value={item._id} selected={selectedState == item._id ? true : false}>{item.name}</option>)
                                                                    }
                                                                </select>
                                                                <i className='icon-arrow-down'></i>
                                                            </div>
                                                            {stateError ?
                                                                <span className="error">
                                                                    {profileComponent ? null : "Please select state"}
                                                                </span> : null
                                                            }
                                                        </div>

                                                        <div className='form-intput'>
                                                            <label>Pin code</label>
                                                            <input type="text" placeholder='Enter pincode here' onChange={(e) => zipcodehandler(e)} id="zip" value={zipcode} disabled={profileComponent} maxLength="6" />

                                                        </div>

                                                    </div>
                                                    <div className='address-field-wrapper'>
                                                        <div className='addres-head-wrap'>
                                                            <div className='wtsaap-number-wrap mail-addres-wrap'>
                                                                <label className='mail-label'>Mailling Address</label>
                                                                <div className="radio-box">
                                                                    <div className='radio-wrap'>
                                                                        <input type="checkbox" id="same" />
                                                                        <span className='custom'></span>
                                                                    </div>
                                                                    <label for="same" className='label-same'>Same As Above</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='form-intput'>
                                                            <label>Address line 1</label>
                                                            <input type="text" placeholder='Type here' onChange={(e) => addressOnehandler(e)} id="shopname" disabled={profileComponent} value={addressOne} maxlength="50" />

                                                        </div>

                                                        <div className='form-intput'>
                                                            <label>Address line 2</label>
                                                            <input type="text" placeholder='Type here' onChange={(e) => addressTwohandler(e)} id="shopname" disabled={profileComponent} value={addressTwo} maxlength="50" />

                                                        </div>

                                                        <div className='form-intput'>
                                                            <label>city</label>
                                                            <input type="text" placeholder='Enter city name here' onChange={(e) => cityhandler(e)} id="city" disabled={profileComponent} value={city} maxlength="100" />
                                                        </div>

                                                        {/* <div className='form-intput'>
                                                        <label>state</label>
                                                        <select onChange={(e) => statehandler(e)} id="state" disabled={profileComponent}>
                                                            <option value={""}>Please select state</option>
                                                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                            
                                                        </select>
                                                        
                                                    </div> */}

                                                        <div className='form-intput select-affilation-wrap'>
                                                            <label>state<sup className="highlighted">{!profileComponent ? "*" : null}</sup></label>

                                                            <div className='select-main-wrapper'>
                                                                <select onChange={(e) => statehandler(e)} id="state" disabled={profileComponent}>
                                                                    <option value={""}>Select</option>
                                                                    {
                                                                        stateDataArr && stateDataArr.stateData && stateDataArr.stateData.map(item => <option value={item._id} selected={selectedState == item._id ? true : false}>{item.name}</option>)
                                                                    }
                                                                </select>
                                                                <i className='icon-arrow-down'></i>
                                                            </div>
                                                            {stateError ?
                                                                <span className="error">
                                                                    {profileComponent ? null : "Please select state"}
                                                                </span> : null
                                                            }
                                                        </div>

                                                        <div className='form-intput'>
                                                            <label>Pin code</label>
                                                            <input type="text" placeholder='Enter pincode here' onChange={(e) => zipcodehandler(e)} id="zip" value={zipcode} disabled={profileComponent} maxLength="6" />

                                                        </div>

                                                    </div>
                                                    <>
                                                        {previousNext ?
                                                            <div className="registration-btn registration-btn-wrpper">
                                                                <input className="btn shop-now go-back" type="button" value={"Go Back"} onClick={handlePrevious} />
                                                                <input className="btn shop-now next-btn" type="button" value={"Next"} onClick={nextValidation} />
                                                            </div> : null

                                                        }
                                                    </>

                                                </div>
                                            </div> : null
                                        }

                                        {urlSection ?
                                            <div className='login-details seller-login-details-wrap'>

                                                <div className='registration-form-one-header'>
                                                    <div className='registration-form-head'>
                                                        {/* <span className='welcome-msz'>Welcome to the </span> */}
                                                        <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link>
                                                    </div>
                                                    <span onClick={closeModal} class="skip-btn">Skip</span>
                                                </div>
                                                <div className='complete-profile-wrap'>
                                                    <span className='login-msz-wrap'>Fill your Address</span>
                                                    <div className='step-count-registration'>
                                                        <span className='step-number'>Step 4</span>
                                                        <span className='total-step'>Out of 5</span>
                                                    </div>
                                                </div>
                                                <div className='process-bar-wrapper'>
                                                    <div className='progress-bar-wrapper'>
                                                        <ProgressBar completed={processBar} className="process-bar" />
                                                    </div>
                                                    <span className='completed-process'>80% complete</span>
                                                </div>
                                                <div className='login-description login-description-form-wrap '>
                                                    <div className='form-intput'>
                                                        <label>Website</label>
                                                        <input type="text" placeholder='Enter website link here' onChange={(e) => Websitehandler(e)} id="shopname" disabled={profileComponent} value={website} maxlength="50" />

                                                    </div>
                                                    <div className='form-intput'>
                                                        <label>Facebook</label>
                                                        <input type="text" placeholder='Enter link here' onChange={(e) => facebookhandler(e)} id="shopname" disabled={profileComponent} value={facebook} maxlength="50" />

                                                    </div>
                                                    <div className='form-intput'>
                                                        <label>Google</label>
                                                        <input type="text" placeholder='Enter link here' onChange={(e) => googlehandler(e)} id="city" disabled={profileComponent} value={google} maxlength="100" />

                                                    </div>
                                                    <div className='form-intput'>
                                                        <label>Linkedin</label>
                                                        <input type="text" placeholder='Enter link here' onChange={(e) => linkdinhandler(e)} id="city" disabled={linkedIn} value={linkedIn} maxlength="100" />

                                                    </div>

                                                    <>
                                                        {previousNext ?
                                                            <div className="registration-btn registration-btn-wrpper">
                                                                <input className="btn shop-now go-back" type="button" value={"Go Back"} onClick={handlePrevious} />
                                                                <input className="btn shop-now next-btn" type="button" value={"Next"} onClick={nextValidation} />
                                                            </div> : null

                                                        }
                                                    </>
                                                </div>
                                            </div> : null
                                        }

                                        {documentSection ?
                                            <div className='login-details upload-document-wrapper seller-login-details-wrap'>
                                                <div className='registration-form-one-header'>
                                                    <div className='registration-form-head'>
                                                        {/* <span className='welcome-msz'>Welcome to the </span> */}
                                                        <Link to="/" className='logo-wrap'><img src="/assets/images/metal-buy-logoo.png" alt="logo" /></Link>
                                                    </div>
                                                    <span onClick={closeModal} class="skip-btn">Skip</span>
                                                </div>
                                                <div className='complete-profile-wrap'>
                                                    <span className='login-msz-wrap'>Upload your Documents</span>
                                                    <div className='step-count-registration'>
                                                        <span className='step-number'>Step 5</span>
                                                        <span className='total-step'>Out of 5</span>
                                                    </div>
                                                </div>
                                                <div className='process-bar-wrapper'>
                                                    <div className='progress-bar-wrapper'>
                                                        <ProgressBar completed={processBar} className="process-bar" />
                                                    </div>
                                                    <span className='completed-process'>100% complete</span>
                                                </div>
                                                <div className='login-description description-new-wrap upload-desc-wrapper'>
                                                    <div className='main-img-wrap'>
                                                        <div className='form-intput input-upload-form'>
                                                            {selectedImagePan && <button onClick={() => setSelectedImagePan(null)} className="close-img-btn"><i className='close-img-icon'></i></button>}
                                                            <div className='select-edit-wrap'>
                                                                {selectedImagePan && (
                                                                    <div className='select-img-upload'>
                                                                        <img alt="not fount" src={URL.createObjectURL(selectedImagePan)} />
                                                                    </div>
                                                                )}
                                                                {!selectedImagePan &&
                                                                    <div className='input-file-upload-wrap'>
                                                                        <div className='input-upload'>
                                                                            <div className='file-image'>
                                                                                <img src="/assets/images/choose-img-icon.svg" alt='icon' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    className='file-upload-input'
                                                                    name="myImage"
                                                                    onChange={(event) => {
                                                                        panhandler(event)
                                                                    }}
                                                                />
                                                            </div>
                                                            <label class="upload-label">Pan Card</label>
                                                        </div>

                                                        <div className='form-intput input-upload-form'>
                                                            {selectedImageAdhaar && <button onClick={() => setSelectedImageAdhaar(null)} className="close-img-btn"><i className='close-img-icon'></i></button>}
                                                            <div className='select-edit-wrap'>
                                                                {selectedImageAdhaar && (
                                                                    <div className='select-img-upload'>
                                                                        <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImageAdhaar)} />
                                                                    </div>
                                                                )}

                                                                {!selectedImageAdhaar &&
                                                                    <div className='input-file-upload-wrap'>
                                                                        <div className='input-upload'>
                                                                            <div className='file-image'>
                                                                                <img src="/assets/images/choose-img-icon.svg" alt='icon' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    name="myImage"
                                                                    onChange={(event) => {
                                                                        adhaarhandler(event)
                                                                    }}
                                                                />
                                                            </div>
                                                            <label className='upload-label'>Aadhaar Card</label>
                                                        </div>

                                                        <div className='form-intput input-upload-form'>
                                                            {selectedImageGst && <button onClick={() => setSelectedImageGst(null)} className="close-img-btn"><i className='close-img-icon'></i></button>}
                                                            <div className='select-edit-wrap'>
                                                                {selectedImageGst && (
                                                                    <div className='select-img-upload'>
                                                                        <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImageGst)} />
                                                                    </div>
                                                                )}

                                                                {!selectedImageGst &&
                                                                    <div className='input-file-upload-wrap'>
                                                                        <div className='input-upload'>
                                                                            <div className='file-image'>
                                                                                <img src="/assets/images/choose-img-icon.svg" alt='icon' />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    name="myImage"
                                                                    onChange={(event) => {
                                                                        gstHandler(event)
                                                                    }}
                                                                />
                                                            </div>
                                                            <label className='upload-label'>
                                                                GST Registration Certificate
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span className='img-warning'>
                                                        *file should be in png, jpg or jpeg format and maximum 2 mb in size
                                                    </span>
                                                    <div>
                                                        {
                                                            profileComponent === null ?
                                                                <div className="registration-btn registration-btn-wrpper">
                                                                    {previousNext ? <input className="btn shop-now go-back" type="button" value={"Go Back"} onClick={handlePrevious} /> :
                                                                        null
                                                                    }

                                                                    <input className="btn shop-now next-btn" type="submit" value={"Submit"} />
                                                                </div> : profileComponent === false ?
                                                                    <div className="registration-btn">
                                                                        {previousNext ? <input className="btn shop-now go-back" type="button" value={"Previous"} onClick={handlePrevious} /> :
                                                                            null
                                                                        }
                                                                        <input className="btn shop-now next-btn" type="submit" value={"Update"} />
                                                                    </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div> : null
                                        }






                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}



const mapStateToProps = (state, ownProps) => ({
    adminStatus: state.userProfile.userProfileBanner.userdata
});

const mapDispatchToProps = (dispatch) => {
    return {
        sendOtpAadhar: (payload, completion) => dispatch(sendOtpAadhar(payload, completion)),
        verifyAadharOTP: (payload, completion) => dispatch(verifyAadharOTP(payload, completion)),
        submitSellerRegistration: (payload, completion) => dispatch(submitSellerRegistration(payload, completion)),
        verifyPanNumber: (payload, completion) => dispatch(verifyPanNumber(payload, completion)),
        verifyAccountNumber: (payload, completion) => dispatch(verifyAccountNumber(payload, completion)),
        verifyGSTNumber: (payload, completion) => dispatch(verifyGSTNumber(payload, completion)),
        updateSellerRegistration: (payload, completion) => dispatch(updateSellerRegistration(payload, completion)),
        getBankDetail: (payload, completion) => dispatch(getBankDetail(payload, completion)),
        getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SellerRegistrationForm));