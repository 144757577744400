import React from "react";
import { useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getTotalUnreadMsgAndWishlistItem } from "../../../store/actions/common";

const NotifyMessage = (props) => {
  const [totalItemInWishlist, setTotalItemInWishlist] = useState();
  const [totalUnreadMessage, setTotalUnreadMessage] = useState();
  const [getProfileStatus, setProfileStatus] = useState()
  let headercount = useSelector(store => store.headerRedu)

  let dispatch = useDispatch()


  useEffect(() => {
    let status = localStorage.getItem("approved");
    setProfileStatus(status);
    getTotalUnreadMsgAndWishlistItem(callback => {
      setTotalItemInWishlist(callback.totalItemInWishlist);
      setTotalUnreadMessage(callback.totalUnreadMessage);
    })(dispatch)
  }, [])


  return (
    <>
      <div className="download-app hide-metal">

        <Link
          to={{
            pathname: "/profile/wishList",
            // state: { tab: "wishList" },
          }}
        >

          <span className="header-text">
            {
              headercount.totalItemInWishlist !== 0 ?
                <span className="unread-wishlist">{headercount.totalItemInWishlist} </span>
                : null
            }

            <i className="fas fa-heart"></i>
          </span>
        </Link>
      </div>
      <div className="download-app notification-img">
        {
          getProfileStatus == "pending" ? null :
            <Link
              to={{
                pathname: "/profile/notification",
                // state: { tab: "Notification" },
              }}
              className="wish-list msg-number"

              data-count={headercount.totalNotificationCount == 0 ? null : headercount.totalNotificationCount <= 99 ? headercount.totalNotificationCount : "99+"}
            >
              <span className="header-text">
                {headercount.totalNotificationCount == null ? 
              null :   <span className="unread-wishlist">{headercount.totalNotificationCount} </span>
              }
                
                <i className="notification-icon"></i>
              </span>
            </Link>
        }

      </div>

      <div className="download-app  hide-metal">

        <span className="header-text">
          {
            headercount.totalUnreadMessage !== 0 ?
              <span className="unread-msg">{headercount.totalUnreadMessage}</span>
              : null

          }

          <i
            className={`fa-comment-alt
                  ${props.messageMenu ? " far" : " fas"}`}
            onClick={props.setMessageMenu}
          />

        </span>
      </div>
    </>
  );
};

// export default NotifyMessage;
const mapStateToProps = (state, ownProps) => ({


});

const mapDispatchToProps = dispatch => {
  return {
    getTotalUnreadMsgAndWishlistItem: (payload) => dispatch(getTotalUnreadMsgAndWishlistItem(payload)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotifyMessage));