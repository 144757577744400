import {CREATE_COUPON,GET_COUPON,REMOVE_COUPON} from '../actionsTypes';


function setCreateCoupon(state, payload) {
	let newState = [...state];
	newState.push(payload)
    return newState
}

function setRemoveCoupon(state, payload) {
	let newState = [...state];
	let newData = newState.filter(item=>item._id!==payload)
    return newData
}



const Mybids = (state = [], action) => {
    switch (action.type) {
        case GET_COUPON :
            return action.payload;
         case CREATE_COUPON :
            return setCreateCoupon(state,action.payload);
         case REMOVE_COUPON :
            return setRemoveCoupon(state,action.payload);
        default:
            return state;
    }
}
export default Mybids;