import moment from 'moment'
import React from 'react'

const ShippingBuyerLots = (props) => {
    return (
        <div class="order-top order-top-wrapper order-delivery-wrap lot-section" style={{ marginBottom: "0" }}>
            <div class="shipping-module-wrp" style={{ marginBottom: "5px" }}>

                <div class="remove-form-shipping-wrap buyer-side-remove">
                    <h4>Lot {props.item.lot_number}</h4>
                </div>

                <div className={`full-form-block separate-form-shipping buyer-separate-form-shipping ${props.item.driver_name?"with-separator":""}`}>
                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <label
                            style={{
                                marginBottom: "0",
                                fontWeight: "700",
                                color: "#000",
                                marginRight: "9px",
                                lineHeight: "1.3",
                            }}
                        >
                            Lot size:
                        </label>
                        <p>{props.item.lot_size}mt</p>
                    </div>
                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <label
                            style={{
                                marginBottom: "0",
                                fontWeight: "700",
                                color: "#000",
                                marginRight: "9px",
                                lineHeight: "1.3",
                            }}
                        >
                            Pickup date:
                        </label>
                        <p>
                            {/* {props.item.pickup_date} */}
                            {moment(props.item.pickup_date).calendar({
                            sameDay: "DD/MM/YYYY",
                            nextDay: "DD/MM/YYYY",
                            nextWeek: "DD/MM/YYYY",
                            lastDay: "DD/MM/YYYY",
                            lastWeek: "DD/MM/YYYY",
                            sameElse: "DD/MM/YYYY",
                          })}
                        </p>
                    </div>
                </div>
                {props.item.driver_name && <div className="full-form-block separate-form-shipping">
                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <label
                            style={{
                                marginBottom: "0",
                                fontWeight: "700",
                                color: "#000",
                                marginRight: "9px",
                                lineHeight: "1.3",
                            }}
                        >
                            Driver Name:
                        </label>
                        <p>{props.item.driver_name}</p>
                    </div>
                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <label
                            style={{
                                marginBottom: "0",
                                fontWeight: "700",
                                color: "#000",
                                marginRight: "9px",
                                lineHeight: "1.3",
                            }}
                        >
                            Driver Number:
                        </label>
                        <p>{props.item.driver_number}</p>
                    </div>
                </div>}

                {props.item.vehicle_number && <div className="full-form-block separate-form-shipping">
                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <label
                            style={{
                                marginBottom: "0",
                                fontWeight: "700",
                                color: "#000",
                                marginRight: "9px",
                                lineHeight: "1.3",
                            }}
                        >
                            Vehicle Number:
                        </label>
                        <p>{props.item.vehicle_number}</p>
                    </div>
                    <div className='tracking-buyer-url' style={{ display: "flex", marginBottom: "15px" }}>
                        <label
                            style={{
                                marginBottom: "0",
                                fontWeight: "700",
                                color: "#000",
                                marginRight: "9px",
                                lineHeight: "1.3",
                            }}
                        >
                            Tracking URL:
                        </label>
                        <a href={props.item.tracking_url} target='_blank' className='vehicle-tracking-link' style={{color:"#ed9829"}}>{props.item.tracking_url}</a>
                    </div>
                </div>}
            </div>
            {props.item.mediaImages && <div className="order-top order-top-wrapper file-img-inputs module-with-img" style={{ justifyContent: "flex-start" }}>
                <div className="full-form-block separate-form-shipping receipt-wrp" style={{
                    width: "100%"
                }}>
                    <label
                        style={{
                            marginBottom: "0",
                            fontWeight: "700",
                            color: "#000",
                            marginRight: "9px",
                            lineHeight: "1.3",
                            width: "100%",
                            fontSize: "16px"
                        }}
                    >
                        Loading images
                    </label>
                </div>

                {
                    props.item && props.item.mediaImages && props.item.mediaImages.map((element, index) => {
                        return <div className="shipping-module-wrp">
                            <a href={element.mediaData} target="_blank" className="uploaded-img-view" style={{ padding: "8px" }}>
                                <img
                                    src={element.mediaData}
                                    alt={"Images"}
                                />
                            </a>
                        </div>
                    })
                }
            </div>}
            {props.item.mediaImages && <div className="order-top order-top-wrapper order-delivery-wrap" style={{ width: "100%", paddingBottom: "10px", marginBottom: "16px" }}>
                <div className="shipping-module-wrp" style={{ marginBottom: "0" }}>
                    <div className="full-form-block separate-form-shipping">
                        <div style={{ display: "flex", width: "100%" }}>
                            <label
                                style={{
                                    marginBottom: "0",
                                    fontWeight: "700",
                                    color: "#000",
                                    marginRight: "9px",
                                    lineHeight: "1.3",
                                }}
                            >
                                Additional notes:
                            </label>
                            <p>
                                {props.item.additionalComment}
                            </p>
                        </div>
                    </div>
                </div>
            </div>}
            {props.item.mediaImages && <div className="order-top order-top-wrapper file-img-inputs module-with-img new-order-list-wrap" style={{ marginBottom: "0", justifyContent: "flex-start" }}>
                <div className="full-form-block separate-form-shipping receipt-wrp" style={{
                    width: "100%"
                }}>
                    <label
                        style={{
                            marginBottom: "0",
                            fontWeight: "700",
                            color: "#000",
                            marginRight: "9px",
                            lineHeight: "1.3",
                            width: "100%",
                            fontSize: "16px"
                        }}
                    >
                        Receipts
                    </label>
                </div>
                <div className="shipping-module-wrp">
                    <a target="_blank" href={props.item["lorry_receipt"]} className="btn" style={{ padding: "9px 23px", color: "rgb(87,87,87)" }}>
                        Lorry Receipt <i className="fas fa-download"></i>
                    </a>
                </div>

                <div className="shipping-module-wrp">
                    <a target="_blank" href={props.item["gst_invoice"]} className="btn" style={{ padding: "9px 23px", color: "rgb(87,87,87)" }}>
                        GST invoice <i className="fas fa-download"></i>
                    </a>
                </div>

                <div className="shipping-module-wrp">
                    <a target="_blank" href={props.item["weightment_slip"]} className="btn" style={{ padding: "9px 23px", color: "rgb(87,87,87)" }}>
                        Weighment slip <i className="fas fa-download"></i>
                    </a>
                </div>

                <div className="shipping-module-wrp">
                    <a target="_blank" href={props.item["material_test_certificate"]} className="btn" style={{ padding: "9px 23px", color: "rgb(87,87,87)" }}>
                        Material Test Certificate <i className="fas fa-download"></i>
                    </a>
                </div>

                <div className="shipping-module-wrp">
                    <a target="_blank" href={props.item["eway_bill"]} className="btn" style={{ padding: "9px 23px", color: "rgb(87,87,87)" }}>
                        Ewaybill <i className="fas fa-download"></i>
                    </a>
                </div>
            </div>}
        </div>
    )
}

export default ShippingBuyerLots