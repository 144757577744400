import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { decode } from 'js-base64';
import Toast from "light-toast";
import { parse } from 'query-string';
import { offerPayment } from '../../../../store/actions/makeofferAction';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import Loader from '../../../Loader';
import { wait } from '@testing-library/react';






const PaymentPage = () => {
    let location = useLocation();
    let dispatch = useDispatch()
    const [overlayFail, setOverlayFail] = useState(false);
    const [overlaySuccess, setOverlaySuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showMessage, setShowMessage] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }


    const displayRazorpay = async (data, razorSubmit) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            swal("Razorpay SDK failed to load. Are you online?")
            return
        }
        const options = {
            key: 'rzp_test_KakF4Dy4HjXa1U',// 'PRODUCTION_KEY',
            currency: data.currency,
            amount: data.amount.toString(),
            order_id: data.order_id,
            name: 'Bazaar',
            description: 'Make secure payment',
            image: '/assets/images/logo.png',
            handler: (razorpayRes) => razorSubmit(razorpayRes),
            "modal": {
                "ondismiss": function () {
                    setOverlayFail(false);
                    setOverlaySuccess(false);
                    window.location.href = "/profile/my-offer/pending";
                }
            },
            prefill: {
                name: "name",
                email: 'example@mail.com',
                phone_number: '9899999999'
            }
        }

        const paymentObject = new window.Razorpay(options)

        paymentObject.open()
    }



    const offerPaymentHandler = () => {
        let query = parse(location.search)
        setShowLoader(true);
        setShowMessage("Please wait...");
        offerPayment(encodeURIComponent(query.id), callback => {
            if (callback.status === "Success") {

                setOverlayFail(false);
                setOverlaySuccess(true);
                setShowLoader(false);
                displayRazorpay(callback, (success) => {
                    swal("Your payment has been successful");
                    window.location.href = "/profile/my-offer/accepted";
                });
            } else {

                setShowLoader(false);
                setOverlayFail(true);
                setOverlaySuccess(false);
                setErrorMessage(callback.message);
                //Toast.fail(callback.message, 1000)
            }
        })(dispatch)
    }

    useEffect(() => {
        offerPaymentHandler()
    }, [])


    return (
        <div className="greybg payment-greybg-wrap">
            {showLoader ? <Loader message={showMessage} /> : null}
            <div className="container body_overlay">
                <div className={"main-page"}>
                    {/* <div className="nav-content">
                        <Link to="/" className="home-link">Bazaar</Link>
                        <span className="page-name">/ Offer-Payment</span>
                    </div> */}
                    {overlayFail ?
                        <div className="body_overlay unsuccessfull-payment">
                            <div className='payment-failed-wrapper '>
                                <div className='payment-failed-text'>
                                    {/* <i class="fas fa-times-circle"></i> */}
                                    <h4>{errorMessage}</h4>
                                    <Link to="/profile/my-offer/pending" className='return-order-page'>Return to order</Link>
                                </div>
                            </div>
                        </div> : null
                    }
                    {overlaySuccess ?
                        <div className="body_overlay_closed">

                        </div> : null
                    }

                    {/* <div>
                        <button onClick={offerPaymentHandler}>Payment now</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}


export default PaymentPage;