import React from "react";
import { Link } from "react-router-dom";
import { parse, stringify } from "query-string";
import { withRouter } from "react-router-dom";
import LocationEditPopup from "../../PopupFolder/locationEditpopup";
import { isEmpty } from "../../utils/commonFunctions";



const sortByOptions = [
  { value: "Sort-by-newest-first", label: "Sort by newest first" },
  { value: "Sort-by-lowest-price-first", label: "Sort by lowest price first" },
  {
    value: "Sort-by-highest-price-first",
    label: "Sort by highest price first",
  },
];

class NewInHeader extends React.Component {
  constructor(props) {
    super(props);
    let query = {
      ...parse(this.props.location.search),
    };
    if (query.sortby === "Sort-by-best-match") {
      this.state = {
        selectValue: {
          value: "Sort-by-best-match",
          label: "Sort by best match",
        },
      };
    } else if (query.sortby === "Sort-by-newest-first") {
      this.state = {
        selectValue: {
          value: "Sort-by-newest-first",
          label: "Sort by newest first",
        },
      };
    } else if (query.sortby === "Sort-by-lowest-price-first") {
      this.state = {
        selectValue: {
          value: "Sort-by-lowest-price-first",
          label: "Sort by lowest price first",
        },
      };
    } else if (query.sortby === "Sort-by-highest-price-first") {
      this.state = {
        selectValue: {
          value: "Sort-by-highest-price-first",
          label: "Sort by highest price first",
        },
      };
    }
    if (query.sortby === undefined) {
      this.state = {
        selectValue: {
          value: "==========",
          label: "==========",
        },
      };
    }
    this.state = {
      selectValue: "",
      editLocation: false,
      matches: window.matchMedia("(min-width: 768px)").matches
    };
  }

  componentDidUpdate(PrevProps) {
    let query = {
      ...parse(this.props.location.search),
    };
    if (!query.sortby && this.props !== PrevProps) {
      this.setState({ selectValue: "" });
    }
  }

  handleOnchange = (sortBy) => {
    let query = {
      ...parse(this.props.location.search),
    };
    query.sortby = sortBy;
    let url = stringify(query).replace(/%23/g, "#");
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: url,
    });
    this.setState({
      selectValue: {
        value: sortBy,
        label: sortBy.split("-").join(" "),
      },
    });
  };


  navigationBack = () => {
    return (
      <div>
        {this.props.match.params.name !== "block" ? (
          <div className="breadcrumb-wrap">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Metalsbuy</Link>
              </li>
              <li>{this.props.match.params.name}</li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  };


  localSearchEdit = (searchQ) => {
    this.setState({ editLocation: false }, () =>
      this.props.history.push(
        `/product-listing-page/${"Local"}?${searchQ}`
      )
    );
  }


  handleFilter = () => {
    this.props.smallScreenFilter();
  }

  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
  }


  changeHandler = () => {
    this.setState({ editLocation: true })
  }

  useCurrentLoc = () => {
    this.setState({ editLocation: false })
    this.props.useCurrentLoc()
  }

  applyCurrentLocation = (radius) => {
    this.setState({ editLocation: false })
    setTimeout(() => {
      this.props.applyCurrentLocation(radius)
    }, 200)

  }


  render() {
    let query = { ...parse(this.props.location.search) };
    return (
      <React.Fragment>
        <div className={`plp-header product-listhead ${this.state.editLocation ? "local-popup" : null}`}>
          {this.props.type !== "sellerProfile" && this.navigationBack()}
          <>

            {Object.keys(this.props.filterData).length > 0 ?
              <div className="cat-head category-head-wrap category-wrap">
                {
                  this.props.type !== "sellerProfile" &&
                  <h2>
                    {
                      this.props.match.params.name === "block"
                        ? "Search results"
                        : this.props.match.params.name === "Deliver-to" ?
                          null : `${this.props.match.params.name}`
                    }
                    {
                      this.props.prodLength === 1 ?
                        <span className="result-number">({this.props.prodLength} result)</span>
                        : this.props.prodLength > 1 ?
                          <span className="result-number" >({this.props.prodLength} results)</span>
                          : <span>(0 result)</span>
                    }
                  </h2>
                }
                {!(
                  Object.keys(query).length === 1 &&
                  Object.keys(query)[0] === "category" &&
                  this.props.prodLength === 0
                ) ? (
                  <div className="plp-filters">
                    <div className="sort-drop">
                      <select
                        className="my_class new-filter my-new-filter"
                        onChange={(e) => this.handleOnchange(e.target.value)}
                      >
                        {sortByOptions.map((i, j) => (
                          <option
                            value={i.value}
                            key={j}
                            selected={
                              i.value === this.state.selectValue.value
                                ? true
                                : false
                            }
                          >
                            {i.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div> : null
            }
          </>


          {Object.keys(this.props.filterData).length > 0 ?
            <>
              {!(
                Object.keys(query).length === 1 &&
                Object.keys(query)[0] === "category" &&
                this.props.prodLength === 0 &&
                this.props.noProduct === true
              ) ? (
                <div className="cat-head-wrap category-filter-head category-wrap-head header-category-filter">
                  {
                    query.category === "Local" ?
                      <div className="change-location-wrapper">
                        <button onClick={this.changeHandler}>
                          Change <i className="fas fa-map-marker-edit"></i> {query.zip ? this.props.userLocation.cityArr && this.props.userLocation.cityArr[0] : this.props.userLocation.streetAdddr}{query.zip ? ", " + query.zip : ""}</button>
                      </div>
                      : null
                  }


                  {this.state.matches ?
                    <>
                      {
                        !isEmpty(this.props.filterData) && <h2 className="filter-by"> {!isEmpty(this.props.filterData) && "Filter by"} <i className="fas fa-filter"></i></h2>
                      }
                    </> :

                    <>
                      {
                        !isEmpty(this.props.filterData) && <h2 onClick={this.handleFilter}> {!isEmpty(this.props.filterData) && "Filter by"} <i className="fas fa-filter"></i></h2>
                      }
                    </>
                  }



                  {
                    !isEmpty(this.props.filterData) &&
                    <div className="plp-filters">
                      {
                        !isEmpty(this.props.filterData) &&
                        <div className="sort-drop">
                          <img src="\assets\images\sort.png" alt="img" />
                          <select
                            className="my_class new-filter my-new-filter"
                            onChange={(e) => this.handleOnchange(e.target.value)}
                          >
                            {sortByOptions.map((i, j) => (
                              <option
                                value={i.value}
                                key={j}
                                selected={
                                  i.value === this.state.selectValue.value
                                    ? true
                                    : false
                                }
                              >
                                {i.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      }
                    </div>
                  }
                </div>

              ) : null}
            </> : null
          }



          {this.state.editLocation ? (
            <LocationEditPopup
              useCurrentLoc={this.useCurrentLoc}
              closePopup={() => this.setState({ editLocation: false })}
              contShopping={(searchQuery) => this.localSearchEdit(searchQuery)}
              applyCurrentLocation={this.applyCurrentLocation}
            />
          ) : null}

        </div>
      </React.Fragment >
    );
  }
}

export default withRouter(NewInHeader);
