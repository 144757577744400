import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getAllReviewOnProduct,
  addUserReview,
} from "../../store/actions/profile";
import { useParams } from "react-router-dom";
import Toast from "light-toast";

import {
  addReviewOnProduct,
  editReviewOnProduct,
  deleteReviewOnProduct,
} from "../../store/actions/profile";

import { isEmpty } from "lodash";
import ReviewCard from "../Profile/MainProfile/Review/reviewCard";
import { ButtonLoader, SpinnerLoader } from "../../utils/constants";
import { ReviewPoup, DeletePopup } from "../OrderDetails/index";

const ProductReviewList = (props) => {
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [deletePopup, setdeletePopup] = useState(false);

  const [reviewData, setReviewData] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [heading, setHeading] = useState("");

  const param = useParams();

  useEffect(() => {
    props.getProductReview(
      {
        productId: param.id,
        page: 1,
      },
      (status) => {}
    );
  }, [param.id]);

  useEffect(() => {
    setSpinnerLoader(true);
    setTimeout(function () {
      setSpinnerLoader(false);
    }, 1500);
  }, []);

  const setReviewDataToUpdate = (data) => {
    setRating(data.rating ? data.rating : 0);
    setComment(data.review_comment ? data.review_comment : "");
    setHeading(data.heading ? data.heading : "");
  };

  const submitReview = (editId) => {
    if (
      comment.trim().length < 1 ||
      rating === 0 ||
      heading.trim().length < 1
    ) {
      return Toast.fail("Please fill all the fields and select rating", 2000);
    } else {
      Toast.loading("Submitting...");
      const payload = {
        product_id: reviewData.ProductId,
        seller_id: reviewData.SellerId,
        user_id: reviewData.UserId,
        rating,
        comment,
        heading,
        review_from: "Web",
      };
      editId
        ? props.editReview({ ...payload, id: editId }, (status) => {
            if (status)
              Toast.success("Review Added ", 1000, () => {
                props.getProductReview({
                  productId: param.id,
                  page: 1,
                });
                setReviewPopup(false);
              });
            else Toast.fail("Failed", 1000, () => setReviewPopup(false));
          })
        : props.addReview(payload, (status) => {
            if (status)
              Toast.success("Review Added ", 1000, () => {
                props.getProductReview({
                  productId: param.id,
                  page: 1,
                });
                setReviewPopup(false);
              });
            else Toast.fail("Failed", 1000, () => setReviewPopup(false));
          });
    }
  };

  const deleteReview = () => {
    Toast.loading("Deleting...");
    props.deleteReview({ id: reviewData._id }, (status) => {
      if (status)
        Toast.success("Delete ", 1000, () => {
          props.getProductReview({
            productId: param.id,
            page: 1,
          });
          setdeletePopup(false);
        });
      else Toast.fail("Failed", 1000, () => setdeletePopup(false));
    });
  };

  return (
    <div className="review-page" style={{ position: "relative" }}>
      {spinnerLoader ? <SpinnerLoader /> : null}
      <div className={spinnerLoader ? " spinner-time-div" : ""}>
        {!isEmpty(props.reviews) && props.reviews.data.length > 0 ? (
          props.reviews.data.map((data, indx) => (
            <div className="review-list" key={Math.random()}>
              <ReviewCard
                {...data}
                editRev={(state, data) => {
                  setReviewDataToUpdate(data);
                  setReviewData(data);
                  setReviewPopup(state);
                }}
                deleteRev={(state, data) => {
                  setReviewDataToUpdate(data);
                  setReviewData(data);
                  setdeletePopup(state);
                }}
              />
            </div>
          ))
        ) : (
          <div className="product-head">
            <div className="product-img">
              <img src="\assets\images\no-product.png" alt="img" />
            </div>
            <h6> No Review On this Product </h6>
            <meta
              http-equiv="refresh"
              content="3;url=/profile/myorder/completed"
            />
          </div>
        )}

        {!isEmpty(props.reviews) &&
        props.reviews.data.length < props.reviews.TotalProducts ? (
          <div className="plp-loadMore-div">
            <button className="shop-now">
              {buttonLoader ? (
                <ButtonLoader content={"Loading"} />
              ) : (
                "Load more"
              )}
            </button>
          </div>
        ) : null}

        {reviewPopup ? (
          <ReviewPoup
            onClose={() => setReviewPopup(false)}
            {...reviewData}
            submit={submitReview}
            newRating={rating}
            setRating={setRating}
            newComment={comment}
            setComment={setComment}
            heading={heading}
            setHeading={setHeading}
          />
        ) : null}

        {deletePopup ? (
          <DeletePopup
            onClose={() => setdeletePopup(false)}
            delete={deleteReview}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    reviews: state.productDetails.productReview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductReview: (payload, status) =>
      dispatch(getAllReviewOnProduct(payload, status)),
    addReview: (payload, status) =>
      dispatch(addReviewOnProduct(payload, status)),
    editReview: (payload, status) =>
      dispatch(editReviewOnProduct(payload, status)),
    deleteReview: (payload, status) =>
      dispatch(deleteReviewOnProduct(payload, status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewList);
