import { get } from "lodash";
import React from "react";
import { RatingStar } from "../../../ProductDetail/productReview";


const ReviewHead = (props) => {
  return (
    <div>
      <div className="message avg-rating">
        <h2 className="rewiew-head">{props.type === "Seller_review" ? "Reviews on my products" : "Rating And Review"}</h2>
        {props.type === "Seller_review" ?
          <>
            <div className="rating-pic-wrapper">
              <div className="rating-pic">
                <img style={{ width: "100%", height: "100%", "object-fit": "cover" }}
                  src={get(props, ['data', 'sellerData', 'user_image']) ?
                    props.data.sellerData.user_image
                    : '/assets/images/avtar.png'}
                  alt="seller" />
              </div>
              <div className="rating-stars">
                <RatingStar value={props.data.AverageRating} />
              </div>

              {props.data.AverageRating > 0 ? (
                <>
                  <div className="total-rates">
                    <strong>{props.data.AverageRating}/5</strong>
                    <span>Based on {props.data.totalReview} rating </span>
                    <h5>Average Rating as per All Seller's Review</h5>
                  </div>
                </>
              ) : (
                <h6 className="text-center">No Rating</h6>
              )
              }
            </div>
          </>
          : null
        }
      </div>
    </div >
  );
};
export default ReviewHead;
