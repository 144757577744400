import React, { Component } from "react";
import "../Profile.css";
import { connect } from "react-redux";
import { Switch, Route, } from "react-router-dom";

import {
  getUserAddress,
  updateContactDetails,
  getProfileBannerInfo
} from "../../../store/actions/profile";
import { logout, } from "../../../store/actions/common";
import Toast from "light-toast";
import { SpinnerLoader } from "../../../utils/constants";
import LeftPanel from "./leftPanel";
import { useToasts } from "react-toast-notifications";
import { profileRoutes } from './profileRoutes';
import BasicInfo from "./basicInfo";
import EditBasicInfo from "./EditProfile/editBasicInfo";
import OrderDetails from "../../OrderDetails";
import EditPasswordInfo from './EditProfile/editPassword';
import swal from 'sweetalert';
import Loader from "../../Loader";
import OrderProcessDetail from "../../OrderProcessDetail/OrderProcessDetail";
import completeProfile from "../Complete Profile/completeProfile";

const switchRoutes = (
  <Switch>
    {profileRoutes.map((prop, key) => {
      if (prop.prefix === "/profile") {
        return (
          <Route
            path={prop.prefix + prop.pathname}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Route
      path={"/profile/edit"}
      component={EditBasicInfo}
    />
    <Route
      path={"/profile/change-password"}
      component={EditPasswordInfo}
    />
    <Route
      path={"/profile"}
      component={BasicInfo}
    />
  </Switch>
);



class MainProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      spinnerLoader: false,
      innerLoader: false,
      "showHideSidenav": "prof-nav hidden",
      showIcon: true,
      showLoader: false,
      loaderMessage: ""
    };
  }



  toggleSidenav() {
    var css = (this.state.showHideSidenav === "prof-nav hidden") ? "prof-nav show" : "prof-nav hidden";
    this.setState({ "showHideSidenav": css });
    if (css == "prof-nav show") {
      this.setState({ showIcon: false })
    } else {
      this.setState({ showIcon: true })
    }

  }


  componentDidMount() {
    this.setState({ showLoader: true, loaderMessage: "Please wait..." })
    window.scrollTo(0, 0);
    const userId = localStorage.getItem("userId");
    this.props.getUserAddress({}, (status) => {

    });
    setTimeout(() => this.setState({ showLoader: false }), 1500);
    this.setState({ userId });
  }

  uploadImage = (e) => {
    if(e.target&&e.target.files  && e.target.files[0]){
      if (Math.round(e.target.files[0].size / 1024) > 3036) {
        swal("Selected Image is Grater than 3 MB, please choose image under 3 MB");
      } else {
        this.setState({ showLoader: true, loaderMessage: "Image Uploading..." })
  
        let payload = new FormData();
        payload.append("userId", this.props.userId);
        payload.append("user_image", e.target.files[0]);
        this.props.updateContactDetails(payload, (status) => {
  
          if (status.status) {
            this.setState({ showLoader: false })
            this.props.addToast("Image updated successfully", {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            this.props.getProfileBannerInfo();
          } else {
            this.setState({ showLoader: false })
            this.props.addToast(status.message, {
              appearance: "error",
              autoDismissTimeout: 4000,
            });
          }
        });
      }
    }
  };

  render() {
    const { spinnerLoader } = this.state;
    const { downlaod_app_banner } = this.props;
    return (
      <div className="edit-prof edit-proof-wrapper">

        <div className="container">
          {this.state.showLoader ? <Loader message={this.state.loaderMessage} /> : null}
          <div
            className={
              "profile-wrapper manage-profile profile-main-div my-listing-profile my-profile-new" +
              (spinnerLoader ? " spinner-time-div" : "")
            }
          >
            <div className={this.state.showHideSidenav}>
              <LeftPanel
                userId={this.props.userId}
                changePage={this.changePage}
                profileInfo={this.props.userContactInfo}
                logout={this.props.logout}
                uploadImage={this.uploadImage}
                userPic={this.props.userContact}
              />
            </div>
            {/* Route Right Tab */}
            <div className="right-prof-sec right-prof-sec profile-right-content">
              <div className="container">
                {switchRoutes}
                {/* <OrderProcessDetail /> */}
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.userInfo.loggedUserInfo.userId,
    userContactInfo: state.userProfile.userProfileBanner,
    userVerifyMsg: state.userProfile.userVerifyMsg,
    userContact: state.userInfo.userContactInfo,
    downlaod_app_banner: state.cart.static_block_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserAddress: (payload, completion) => dispatch(getUserAddress(payload, completion)),
    logout: () => dispatch(logout()),
    updateContactDetails: (payload, completion) =>
      dispatch(updateContactDetails(payload, completion)),
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
  };
};
export default withToast(
  connect(mapStateToProps, mapDispatchToProps)(MainProfile)
);

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}
