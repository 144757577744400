import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import "react-calendar/dist/Calendar.css";
import { connect } from "react-redux";
import Toast from "light-toast";
import { updateContactDetails, sender_receiver, getProfileBannerInfo } from "../../../../store/actions/profile";
import { isEmpty } from "lodash";
import { useToasts } from "react-toast-notifications";
import { SpinnerLoader } from "../../../../utils/constants";
import { Link, useHistory } from "react-router-dom";
import { getCountry } from "../../../../store/actions/sellItem";
import { sendOTPHandler, verifyOTP } from "../../../../store/actions/sellerRegisterationAction";
import ReactCodeInput from 'react-verification-code-input';
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../Loader";


const EditBasicInfo = (props) => {

  let dispatch = useDispatch()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState([]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [innerLoader, setInnerLoader] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();
  const [flat, setFlat] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [landmark, setLandmark] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [isVerify, setIsVerify] = useState(false);
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [isNumberVerified, setIsNumberVerified] = useState(false);
  const [getGender, setGetGender] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  useEffect(() => {

    getCountry()(dispatch)
    setMobile("");
  }, [])


  useEffect(() => {
    if (props.addressList.length > 0 && props.addressList) {
      setFlat(props.addressList[0].flat_no);
      setStreet(props.addressList[0].street);
      setCity(props.addressList[0].city);
      setLandmark(props.addressList[0].landmark);
      setPostcode(props.addressList[0].postcode);
      setStateId(props.addressList[0].state[0]._id);
      setCountry(props.addressList[0].country[0]._id);

      setTimeout(() => {
        let newState = props.countryData;
        newState = newState.filter(item => item._id === props.addressList[0].country[0]._id)
        setState(newState)
      }, 1000)

    }
    getFieldValue();

    if (!isEmpty(props.addressList)) {
      var addressList = [];
      props.addressList.map((data) => {
        let addresss = "";
        if (data.flat_no) addresss = `${addresss.concat(data.flat_no)}${" "}`;
        if (data.street) addresss = `${addresss.concat(data.street)}${" "}`;
        if (data.landmark) addresss = `${addresss.concat(data.landmark)}${" "}`;
        if (data.city) addresss = `${addresss.concat(data.city)}${" "}`;
        if (data.postcode) addresss = `${addresss.concat(data.postcode)}${" "}`;
        if (data.state[0])
          addresss = `${addresss.concat(data.state[0].name)}${" "}`;
        if (data.country[0])
          addresss = `${addresss.concat(data.country[0].name)}${" "}`;
        addressList.push(addresss);
      });
      setAddress(addressList);
    }

  }, [props.profileInfo, props.addressList, props.countryData]
  );

  function getFieldValue() {
    if (!isEmpty(props.profileInfo) && !isEmpty(props.profileInfo.userdata)) {
      setShowLoader(true);
      setShowMessage("Please wait...")
      setFirstName(props.profileInfo.userdata.first_name);
      setLastName(props.profileInfo.userdata.last_name);
      setEmail(props.profileInfo.userdata.email);
      setSelectedGender(props.profileInfo.userdata.gender)
      setMobile(
        new RegExp(/^\d*$/).test(props.profileInfo.userdata.mobile)
          ? props.profileInfo.userdata.mobile
          : ""
      );
      setIsVerify(true);
      setTimeout(() => setShowLoader(false), 1500);
    }
  }

  const updateContactDetails = (e) => {
    e.preventDefault();
    if (isVerify === false) {
      return addToast("Please verify mobile number first", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
    }

    setShowLoader(true);
    setShowMessage("Please wait...")
    if (currentPassword || newPassword || confirmPassword) {
      if (!currentPassword) notify("Please Enter Current password", "error");
      else if (!newPassword) notify("Please Enter New password", "error");
      else if (!confirmPassword)
        notify("Please Enter Confirm password", "error");
      else if (newPassword !== confirmPassword)
        notify("New password and Confirm password does not matched", "error");

      else {
        const payload = {
          userId: props.profileInfo.userdata._id,
          first_name: firstName,
          last_name: lastName,
          currentPassword,
          newPassword,
          confirmPassword,
          mobile,
        };
        props.updateContactDetails(payload, (status) => {
          if (status.status) {
            setShowLoader(false);
            addToast("Contact details updated successfully", {
              appearance: "success",
              autoDismissTimeout: 2000,
            });
            props.getProfileBannerInfo();
            history.push("/profile");
          } else if (status.message) {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2000,
            });
          }
        });
      }
    } else {
      const payload = {
        // userId: props.profileInfo.userdata._id,
        // first_name: firstName,
        // last_name: lastName,
        // date_of_birth: moment(dob).format('L'),
        // mobile,
        // address,
        // flat,
        // street,
        // city,
        // landmark,
        // postcode,
        // country,
        // state,
        first_name: firstName,
        last_name: lastName,
        mobile: mobile
      };
      props.updateContactDetails(payload, (status) => {

        if (status.status === "time out") {
          setShowLoader(false);
          notify(status.message, "error", 3500);
        }
        else {
          setShowLoader(false);
          addToast("Profile updated successfully", {
            appearance: "success",
            autoDismissTimeout: 2000,
          });
          props.getProfileBannerInfo();
          getFieldValue();
          history.push("/profile");
        }
      });
    }

  };


  const notify = (info, type, time) => {
    return addToast(info, {
      appearance: type,
      autoDismissTimeout: time ? time : 2500,
    });
  };



  const enterPhone = (e) => {

    if (props.profileInfo.userdata.mobile.toString() === e.target.value) {
      setIsVerify(true);
    } else {
      setIsVerify(false);
    }

    setIsSendOtp(false);
    let temp = e.target.value;
    let checkDigit = new RegExp(/^\d*$/);
    if (checkDigit.test(temp)) {
      setMobile(temp);
    }
  };



  function handleCountrySelected(e) {
    let newState = [...props.countryData];
    newState = newState.filter(item => item._id === e.target.value)
    setCountry(e.target.value)
    setState(newState)
  }
  const handleState = (e) => {
    //  setState(e.target.value)
  }


  const sendOTP = () => {
    if (mobile.length === 10) {
      setShowLoader(true);
      setShowMessage("Sending")
      sendOTPHandler({ mobile: mobile }, callback => {
        if (callback.status == "success") {
          setShowLoader(false);
          addToast(callback.message, {
            appearance: "success",
            autoDismissTimeout: 2000,
          });
          setIsSendOtp(true);
        } else {
          setIsSendOtp(false);
          setShowLoader(false);
          addToast(callback.Message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })(dispatch)
    } else {
      addToast("Please enter valid mobile number", {
        appearance: "error",
        autoDismissTimeout: 2000,
      });
    }

  }


  const verifyOtp = (val) => {
    setShowLoader(true);
    setShowMessage("Verifying...")
    verifyOTP({ otp: val }, callback => {
      if (callback) {
        setShowLoader(false);
        setIsSendOtp(false);
        setIsVerify(true);
        addToast("Phone number verified", {
          appearance: "success",
          autoDismissTimeout: 2000,
        });
        setIsNumberVerified(true);
      } else {
        setIsNumberVerified(false);
        setShowLoader(false);
        setIsVerify(false);
        addToast("This OTP not valid.", {
          appearance: "error",
          autoDismissTimeout: 2000,
        });
      }
    })(dispatch)

  }

  const dobhandler = (e) => {

    setDob(e);
  }
  return (
    <div style={{ position: "relative" }}>

      {showLoader ? <Loader message={showMessage} /> : null}
      <div style={{ opacity: innerLoader ? "0" : "1" }}>
        <form autoComplete="off" className="detail-from" onSubmit={(e) => updateContactDetails(e)}>
          <h4 className="form-title">My profile</h4>
          <div className="group-wrap edit-profile edit-profile-details">
            <div className="form-input">
              <span>First Name</span>
              <input
                type="text"
                required
                minLength={3}
                maxLength={50}
                placeholder="First name*"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="form-input">
              <span>Last Name</span>
              <input
                type="text"
                required
                minLength={3}
                maxLength={50}
                placeholder="Last name*"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="form-input">
              <span>Email Address</span>
              <input
                type="email"
                required
                placeholder="Email Address*"
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-input edit-form-input">
              <span>Mobile Number</span>
              <div className="mobile-number-wrapper number-profile-mobile">
                {/* <span class="number-code">+91</span> */}
                <input
                  type="text"
                  value="+91"
                  disabled
                  className="number-code"
                />
                <input
                  type="text"
                  // required
                  minLength={10}
                  maxLength={10}
                  placeholder="Mobile Number"
                  value={mobile}
                  onChange={(e) => enterPhone(e)}
                />
              </div>
              {mobile == "" ? null :
                <>
                  {
                    !isVerify ?
                      <span className="correct-text send-otp profile-otp" onClick={sendOTP}>{isSendOtp ? "Resend OTP" : "Send OTP"}</span>
                      : <span className="correct-text send-otp profile-otp" style={{ color: "green" }}>Verified</span>
                  }
                </>
              }
              {isSendOtp ?
                <div className='form-intput'>
                  <span>Enter OTP<sup className="highlighted">*</sup></span>
                  <div className='phone-number-field111'>
                    <ReactCodeInput fields={6} onComplete={verifyOtp} />
                  </div>
                </div> : null}
            </div>
          </div>
          <div className="flex-row">
            <button type="submit"
              className="header-sell">
              save details
            </button>

            <Link to='/profile'
              className="header-sell">
              Back
            </Link>
          </div>
        </form>
      </div >
    </div >
  );
};

const mapStateToProps = (state, ownProps) => {

  return {
    profileInfo: state.userProfile.userProfileBanner,
    addressList: state.userProfile.userAddress,
    userId: state.userInfo.loggedUserInfo.userId,
    countryData: state.sellItemInfo.countryList

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
    updateContactDetails: (payload, completion) =>
      dispatch(updateContactDetails(payload, completion)),
    sender_receiver: (payload, completion) =>
      dispatch(sender_receiver(payload, completion))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBasicInfo);
