import React, { useState } from "react";
import ProfileCard from "../ProfileCard";
import { useToasts } from "react-toast-notifications";
import { useLocation, } from "react-router-dom";
import { SpinnerLoader, ButtonLoader } from "../../../../utils/constants";
import { getWishList, removeWishlist, deleteWishList } from "../../../../store/actions/Wishlist";
import { getTotalUnreadMsgAndWishlistItem, decreaseWishListCount } from "../../../../store/actions/common";
import { addCart, getCartSellerArray } from "../../../../store/actions/cartAction";
import { connect, useDispatch } from "react-redux";
import { getCart, removeAllProdFromCart } from "../../../../store/actions/cartAction";
import Alert from '../../../ProductDetail/Alert'
import Toast from "light-toast";
import { isEmpty } from "lodash";
import swal from 'sweetalert';
import Loader from "../../../Loader";



const WishList = (props) => {
    let dispatch = useDispatch();

    const [innerLoader, setInnerLoader] = React.useState(false);
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const [Seller] = React.useState(localStorage.getItem("userId"));
    const [isOtherSeller, setIsOtherSeller] = React.useState(false);
    const [addCartProduct, setAddCartProduct] = React.useState([]);
    const [wishId, setWishId] = React.useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showMessage, setShowMessage] = useState("");


    const location = useLocation();
    const { addToast } = useToasts();

    React.useEffect(() => {
        setShowLoader(true);
        setShowMessage("Please wait...")
        props.getWishList();
        setTimeout(() => {
            setShowLoader(false);
        }, 1000);
        window.scrollTo(0, 0);
    }, [location.state])

    const loadMore = () => {
        setButtonLoader(true);
        props.getWishList();
        setTimeout(() => {
            setButtonLoader(false);
        }, 700);
    };
    const handleAddCart = (val) => {
        let userId = localStorage.getItem("userId");
        let data = [{
            product_id: val._id,
            save_for_later: false,
            sellerId: val.User_id,
            user_id: userId
        }];
        setAddCartProduct(data)
        setWishId(val.wishlist_id)
        getCartSellerArray(userId, (e) => {
            if (e.length === 0 || e.includes(val.User_id)) {
                props.addCart({ cart: data }, (callback) => {
                    if (callback) {
                        props.removeWishlist({ wishlistId: val.wishlist_id })
                        addToast("Added to Cart", { appearance: "success" });
                        props.getCart(userId);
                        decreaseWishListCount()(dispatch)
                    } else {
                        addToast("Can't add items from different sellers", { appearance: "warning" });
                    }
                });
            }
            else {
                setIsOtherSeller(true)
            }
        })
    };

    const deleteWish = (data) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this product from wishlist?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    props.deleteFromWishList({ wishlistId: data });
                    decreaseWishListCount()(dispatch)
                } else {

                }
                // swal("Poof! Your imaginary file has been deleted!", {
                //     icon: "success",
                // });
            });

    }

    const removeCartAndSaveProdCart = () => {
        Toast.loading("Loading...");

        removeAllProdFromCart(callback => {
            props.removeWishlist({ wishlistId: wishId });
            props.addCart({ cart: addCartProduct }, (callback1) => {

                setIsOtherSeller(false);
                setWishId("")
                if (callback1) {
                    Toast.success("Added to Cart", 1000);
                } else {
                    Toast.fail("Something wrong.", 1000);
                }
            });

        })(dispatch)

    }

    const closeAlert = () => {
        setIsOtherSeller(false)
    }



    return (
        <div className="wishlist-section" style={{ position: "relative" }}>
            <h2>Wishlist</h2>

            {showLoader ? <Loader message={showMessage} /> : null}

            {
                isOtherSeller &&
                <Alert
                    removeCartAndSaveProdCart={removeCartAndSaveProdCart}
                    closeAlert={closeAlert}
                />
            }

            <div className="plp-product-screen" style={{ opacity: innerLoader ? "0" : "1" }}>
                {!isEmpty(props.wishlist) ?
                    props.wishlist.data.length !== 0 &&
                    props.wishlist.data.map((data, i) => {
                        if (data.product_data) {
                            return (
                                <ProfileCard
                                    {...data.product_data}
                                    current_store_price={
                                        data.product_data.Price.current_store_price
                                    }
                                    sell_price={data.product_data.Price.sell_price}
                                    wishlist_id={data.wishlist_id}
                                    imagpath={data.image_path}
                                    categorydata={data.categorydata[0]}
                                    auctionData={data.auctionData}
                                    handleAddCart={handleAddCart}
                                    deleteWish={deleteWish}
                                />
                            );
                        } else {
                            return null;
                        }
                    })
                    : null
                }


                {!isEmpty(props.wishlist) &&
                    props.wishlist.data.length === 12 &&
                    props.wishlist.data.length < props.wishlist.totalLenght

                    ? (
                        <div className="plp-loadMore-div">
                            <button className="shop-now" onClick={loadMore}>
                                {buttonLoader ? (
                                    <ButtonLoader content={"Loading"} />
                                ) : (
                                    "Load more"
                                )}
                            </button>
                        </div>
                    ) : !isEmpty(props.wishlist) && props.wishlist.totalLenght ? (<div></div>) : null}
            </div>
            {!isEmpty(props.wishlist) && props.wishlist.data.length === 0 ? (
                <div className="product-head">
                    <div className="product-img">
                        <img src="\assets\images\no-product.png" alt="img"></img>
                    </div>
                    <h6> No Product in your wishlist</h6>
                </div>
            ) : null}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.loggedUserInfo.userId,
        wishlist: state.Wishlist.wishlist,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getCart: (payload) => dispatch(getCart(payload)),
        getWishList: () => dispatch(getWishList()),
        addCart: (data, id, callback) => dispatch(addCart(data, id, callback)),
        removeWishlist: (payload) => dispatch(removeWishlist(payload)),
        deleteFromWishList: (payload, status) =>
            dispatch(deleteWishList(payload, status)),
        getTotalUnreadMsgAndWishlistItem: (payload) => dispatch(getTotalUnreadMsgAndWishlistItem(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(WishList);
