import React from "react";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { getDashboardCategoryProd } from "../../store/actions/dashboard";
import { Link } from "react-router-dom";

class StaticBlock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  // componentDidMount() {
  //   const { id, block_name } = this.props.data;
  //   this.props.getProduct({ block_name, id }, (status) => { });
  // }




  render() {

    return (
      <>
        {/* <section className="get-app-section">
          <div className="white-block">

          </div>
          <div className="container">
            <div className="main-wrapper-get-app">
              <div className="ltl-app-block">
                <h2>Download the App</h2>
                <ul className="app-list-wrapper">
                  <li>
                    <a href="#">list</a>
                  </li>
                  <li>
                    <a href="#">share</a>
                  </li>
                  <li>
                    <a href="#">earn</a>
                  </li>
                </ul>
                <Link to="/app_store" className="app-store-btn-wrap">
                  <div className="app-store-img">
                    <img src="/assets/images/apple-image.png" alt="upload" />
                  </div>
                  <span className="app-store-text">AppStore</span>
                </Link>
                <Link to="/google_play" className="google-play-btn-wrap">
                  <div className="google-store-img">
                    <img src="/assets/images/google-play-image.png" alt="upload" />
                  </div>
                  <span className="google-play-store-text">Google play</span>
                </Link>
              </div>
              <div className="rtl-app-block">
                <div className="right-mobile-img">
                  <img src="/assets/images/get-app-right-img.png" alt="upload" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  let id = ownProps.data.id;
  return {
    allData: state.dashboardInfo,
    staticData: state.dashboardInfo[id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: (payload, completion) => dispatch(getDashboardCategoryProd(payload, completion)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StaticBlock);
