import React, { useEffect } from "react";
import WOW from 'wowjs';
import 'animate.css'

  function Counter() {
    useEffect(() => {
      const wow = new WOW.WOW({
        live: true, // Enables the live mode to detect changes dynamically
      });
      wow.init();
    }, []);
  return (
    <div className="counter">
      <div className="container-main">
        <div className="counter-wrapper wow animate__animated animate__fadeInUp ">
          <div className="count">
            <h4>300+</h4>
            <h5>Buyers</h5>
          </div>
          <div className="count">
            <h4>50+</h4>
            <h5>Manufacturers</h5>
          </div>
          <div className="count">
            <h4>150+</h4>
            <h5>Logistic Partners</h5>
          </div>
          <div className="count">
            <h4>600+</h4>
            <h5>Deliveries</h5>
          </div>
          <div className="count">
            <h4>200 Cr+</h4>
            <h5>GMV</h5>
          </div>
        </div>
        <div className="counter-details">
          <div className="inner-content wow animate__animated animate__fadeInUp " data-wow-delay="0.1s">
            <p>
              Metalsbuy is India's first digital platform for buying and selling
              ferro alloys. It is located in the heart of the country's
              industrial landscape, Raipur. With over three decades of hands-on
              expertise, we're not just industry experts; we're pioneers in this
              industry, with a vision of transforming the landscape with the use
              of cutting-edge technology.

            </p>
            <p>Transparency is at the core of our
              mission. We understand the problems that have long plagued the
              ferro alloys market, and we're here to solve them. Metalsbuy has
              emerged as the leader in the ferro alloys industry, bringing
              innovation and efficiency to the</p>
          </div>
          <div className="inner-content wow animate__animated animate__fadeInUp " data-wow-delay="0.2s">
            <p>
              forefront. We're not just another online platform; We are a
              one-stop solution for price discovery, real-time product
              availability, supply chain bottleneck resolution, order tracking,
              and insights for the Iron and Steel Industry. Our commitment to
              transparency ensures that every transaction is conducted with
              openness and trust, giving you unparalleled confidence in your
              purchase decisions.
            </p>
            <p> Metalsbuy, where transparency meets digital
              innovation. Our mission is clear: to make buying and selling of
              ferro alloys more efficient, convenient, and data-driven.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
