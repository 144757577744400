import { SAVE_CMS_DATA, SAVE_FOOTER_SECTION } from "./../actionsTypes";
import { axiosInstance } from "./../../utils/Service";
import { apiUrl } from "./../../utils/urlEndpoints";

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

export const get_cms_data = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(apiUrl.footerHeading.api, payload);
      dispatch(saveCMSData({ [payload.slug]: response.data.cms_data }));
    } catch (e) {
      dispatch(saveCMSData({ [payload.slug]: null }));
    }
  };
};

const saveCMSData = (data) => {
  return {
    type: SAVE_CMS_DATA,
    payload: data,
  };
};

export const getFooterSection = (callback) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(apiUrl.footerHeading.section);
      callback(response.data.data)
      dispatch(saveFooter(response.data));
    } catch (e) {

    }
  };
};

const saveFooter = (section) => {
  return {
    type: SAVE_FOOTER_SECTION,
    payload: section,
  };
};

export const getContactReason = async (callBack) => {
  try {
    const response = await axiosInstance.get(apiUrl.contactUs.getContactReasons);
    callBack(response.data)
  } catch (e) {
    callBack({ data: [] })
  }
};

export const submitContactForm = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance.post(apiUrl.contactUs.sendForm, payload)
      .then((response) => {
        completion(response.data)
      })
      .catch((e) => {

        if (e.code === "ECONNABORTED") {
          completion({ status: "error", type: "Timeout, Please try again" });
        } else if (e.status === undefined) {
          completion({ status: "invalid", type: "invalid aadhaar number" });
        }

      });
  };
}

export const get_cms_data_new = (payload, completion) => {
  return (dispatch) => {
    return axiosInstance.post(apiUrl.auth.get_cms_data_new, payload).then((response) => {
      completion(response.data);
    }).catch((err) => {
    })
  }
}