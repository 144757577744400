import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Toast from "light-toast";
import { useToasts } from "react-toast-notifications";
import { getBankList } from "../../../../store/actions/profile";
import { useHistory } from "react-router-dom";
import { apiUrl } from "../../../../utils/urlEndpoints";
import { axiosInstance } from "../../../../utils/Service";
import { get } from "lodash";
import FooterTransactionHistory from "../SalesReport/Footer";
import TransactionDateSelect from "../SalesReport/TransactionDateSelect";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { priceWithSymbol } from "../../../../utils/constants";
import { timeFunction } from "../../MainProfile/Notification/function";
import moment from "moment";
import Loader from "../../../Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const WithdrawHistory = (props) => {
  const [userId] = React.useState(localStorage.getItem("userId"));
  const history = useHistory();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startCalendar, setStartCalendar] = React.useState(false);
  const [endCalendar, setEndCalendar] = React.useState(false);
  const [responseWalletData, setResponseWalletData] = React.useState({
    Wallet_Amount: "",
  });

  const [response, setResponse] = React.useState([]);
  const { addToast } = useToasts();
  const [showLoader, setShowLoader] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState("");
  ////////////////// API for wallet Amount
  React.useEffect(() => {
    setShowLoader(true);
    setLoaderMessage("Please wait...")
    const article = { sellerId: userId };
    axiosInstance
      .post(apiUrl.profile.sellerWalletDetails, article)
      .then((res) => {

        let wallet_detail_of_seller_data = res.data.walletData;
        if (wallet_detail_of_seller_data) {
          setResponseWalletData({
            Wallet_Amount: wallet_detail_of_seller_data.totalAmount.$numberDecimal,
          });
        }

      });
    setTimeout(() => {
      setShowLoader(false);
    }, 1500);
  }, []);

  /////////////////// Api for data table withdraw history
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    // POST request using axios inside useEffect React hook
    if (startDate == "" || endDate == "") {
      var article = {

      };
    } else {
      var article = {
        start_day: startDate,
        last_day: endDate,
        //,bankAccount,page
      };
    }

    axiosInstance
      .post(apiUrl.profile.transaction_history, article, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        const withdrawl_detail_of_seller = res.data.data;
        for (var i = 0; i < withdrawl_detail_of_seller.length; i++) {
          setResponse(withdrawl_detail_of_seller);

        }
      });
  }, []);

  return (
    <div className="transaction-tab">
      {showLoader ? <Loader message={loaderMessage} /> : null}
      <div className="withdraw-panel">
        <h4>Withdraw Balance</h4>
        <p>
          The money will be in your bank{" "}
          <span className="italic-text">account 3-4 business days</span>,
          depending on your bank.
        </p>
        <div className="balance-block">
          <div className="balance-number">
            {priceWithSymbol(responseWalletData.Wallet_Amount)}
          </div>
          <h6>Your Balance</h6>
        </div>
      </div>
      <div className="custom-reports custom-transaction-reports">
        <h5>Transaction Reports</h5>
        <span>
          Create your Custom Transaction report by selecting a date range{" "}
        </span>
        <span>
          This will generate a downloadable CSV that can be used for text
          filling
        </span>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (
            new Date(startDate).getTime() === new Date(endDate).getTime()
          ) {
            addToast("Both Dates should not be equal", {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
          } else {
            addToast("Downloading", {
              appearance: "info",
              autoDismissTimeout: 2500,
            });
            props.transactionReportDownload({
              start_day: startDate,
              last_day: endDate,
              page: "1"
            });
          }
        }}
        method="posts"
      >
        <div className="cal-wrap" style={{}}>
          <div className="cal-fld">
            <span>Start Date*</span>
            <div className="popCalendar">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                maxDate={(new Date())}
                placeholderText="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                required
              />
            </div>
          </div>
          <div className="cal-fld">
            <span>End Date</span>
            <div className="popCalendar">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                minDate={startDate}
                openToDate={startDate}
                placeholderText="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "30px" }} className="generate-report-btn">
          <button type="submit" className="report-downlaod-btn">
            Generate Report
          </button>
        </div>
      </form>
      <h4>Transaction Details</h4>
      <div className="transaction-tables">
        <div className="withdraw-table width">
          <div className="withdraw-head">
            {/* <div className="withdraw-main"></div> */}
            <div className="withdraw-id">Request ID</div>
            <div className="req-date">Requested Date</div>
            <div className="withdraw-date">Accept Date</div>
            <div className="withdraw-amount">Amount</div>
            <div className="withdraw-left">Remaining Balance</div>
            <div className="withdraw-status">Status</div>
          </div>
          <div className="values-wrap">
            {response.map((d, i) => {
              const accountNumber = d.bank.accountNumber;
              const accLen = d.bank.accountNumber.toString().length;
              return (
                <div className="withdraw-values" key={i}>
                  <div className="withdraw-main">
                    {/* <div className="transaction-logo">
                      <i className="fas fa-arrow-alt-circle-down"></i>
                    </div> */}
                    <div className="withdraw-name">
                      {/* <div className="bank-info">
                        {d.bank.bankName}
                        <br />
                        {accountNumber.toString().substring(0, 3) +
                          "*".repeat(accLen - 7) +
                          accountNumber.toString().substring(accLen - 4, accLen)}
                      </div> */}

                      <div className="withdraw-id">{d.requestId}</div>
                    </div>
                  </div>

                  <div className="req-date withdraw-date">
                    {
                      moment(new Date(d.requestDate)).calendar({
                        sameDay: 'DD/MM/YYYY h:mm a',
                        nextDay: 'DD/MM/YYYY h:mm a',
                        nextWeek: 'DD/MM/YYYY h:mm a',
                        lastDay: 'DD/MM/YYYY h:mm a',
                        lastWeek: 'DD/MM/YYYY h:mm a',
                        sameElse: 'DD/MM/YYYY h:mm a'
                      })
                    }
                  </div>
                  {
                    d.receivedDateOfAmount === "" ?
                      <span>----</span>
                      :

                      <div className="withdraw-date">
                        {d.receivedDateOfAmount
                          ? moment(new Date(d.receivedDateOfAmount)).calendar({
                            sameDay: 'DD/MM/YYYY h:mm a',
                            nextDay: 'DD/MM/YYYY h:mm a',
                            nextWeek: 'DD/MM/YYYY h:mm a',
                            lastDay: 'DD/MM/YYYY h:mm a',
                            lastWeek: 'DD/MM/YYYY h:mm a',
                            sameElse: 'DD/MM/YYYY h:mm a'
                          })
                          : <span>----</span>}
                      </div>
                  }
                  <div className="withdraw-amount">
                    {priceWithSymbol(parseFloat(d.requestAmount.$numberDecimal).toFixed(2))}
                  </div>
                  <div className="withdraw-left">
                    {priceWithSymbol(parseFloat(d.balanceAmount.$numberDecimal).toFixed(2))}
                  </div>
                  <div className="withdraw-status ">{d.status}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className="Sales-page">
        <CustomReport
          downloadReport={props.downloadReport}
          createDate={get(props.profileInfo, ["userdata", "createdAt"], false)}
        />
      </div>
     edit
      */}

      <br />
      <div className="Sales-page transaction">
        <div className="custom-sales">
          <div className="related-articles releted-tble-wrapper">
            <h2>Related Articles</h2>
            <a href="/profile">How do i access my Gross Transaction Report</a>
          </div>
        </div>
        {/* <TransactionDateSelect
        downloadReport={props.downloadReport}
        createDate={get(props.profileInfo, ["userdata", "createdAt"], false)}
      /> */}
      </div>
      <div className="rel-art releted-tble-wrapper">
        <h4>Related Articles</h4>
        <div className="related-article-table releted-tab-wrap">
          <table style={{ width: "100%" }} id="article-tbl">
            <tr>
              <th>Document Name</th>
              <th>Tax Year</th>
              <th>Action</th>
            </tr>
            <tr>
              <td>2021_sales_report.csv</td>
              <td>2021</td>
              <td>
                <button>
                  <i className="fas fa-file-download"></i>Download
                </button>
              </td>
            </tr>
            {/* {props.data.map((item, i) => (
            <tr key={i}>
              <td>{item.name}</td>
              <td>{item.year}</td>
              <td>
                <button
                  onClick={() => {
                    addToast("Downloading", {
                      appearance: "info",
                      autoDismissTimeout: 2500,
                    });
                    props.downloadReport({ year: item.year });
                  }}
                >
                  <i className="fas fa-file-download"></i>Download
                </button>
              </td>
            </tr>
          ))} */}
          </table>
        </div>
      </div>
      {/* <FooterTransactionHistory
        data={props.salesReports}
        downloadReport={props.downloadReport}
      /> */}
    </div>
  );
};
export default WithdrawHistory;
