import React from 'react';
import Header from '../Header';
import { withRouter } from "react-router-dom";
import { Authorization } from "../../utils/Authorization";
import SeconderyFooter from '../MainPage/SecondaryFooter';
// import Footer from '../Footer';
import PrimaryFooter from '../MainPage/PrimaryFooter';

const FooterMaster = (props) => {
    const loginInfo = Authorization();
    const loc = {
        coords: props.coords,
        isGeolocationAvailable: props.isGeolocationAvailable,
        isGeolocationEnabled: props.isGeolocationEnabled,
        positionError: props.positionError,
    };
    return (
        <>
            {window.location.pathname == "/" || loginInfo.status == false ?
                <SeconderyFooter /> : <SeconderyFooter />}
        </>
    );
}
export default withRouter(FooterMaster);